import axios from 'axios';
import swal from 'sweetalert';

 export interface postDeleteSalesPromotionPayloadInterface {
    [index: number]: string;
}

export const postDeleteSalesPromotion = async (payload:postDeleteSalesPromotionPayloadInterface) => {
        let url: any = `${process.env.REACT_APP_BASE_URL}/salesPromotion/deleteSalesPromotion`;
        const requestOptions: any = {
            headers: {
                "Content-type": "application/json",
            },
        }
        let postDeleteSalesPromotion:any;
        try{
            postDeleteSalesPromotion = await axios.post(url, payload, requestOptions)
             if (postDeleteSalesPromotion?.error) {
                swal({
                    text:"Deletion Failed!",
                    icon: "error",
                  })
            }
            return postDeleteSalesPromotion?.data
        }catch (error) {
            console.error(error);
            swal({
                text: "Failed",
                icon: "error"
            });
            throw error;
            return postDeleteSalesPromotion?.data
        }    
}