// import { toAbsoluteUrl } from "../../../_helpers";

export function ScrollTop() {
  return (
    <div id="kt_scrolltop" className="scrolltop">
      <span className="svg-icon">
        <i className="fas fa-arrow-up"></i>
      </span>{" "}
    </div>
  );
}
