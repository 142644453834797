export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const USER_SAVE = "USER_SAVE";
export const TOKEN_SAVE = "TOKEN_SAVE";
export const AGENCY_LIST = "AGENCY_LIST";
export const AGENCY_SEARCHDATA = "AGENCY_SEARCHDATA";
export const AGENT_SEARCHDATA = "AGENT_SEARCHDATA";
export const AGENT_LIST = "AGENT_LIST";
export const SALES_SEARCHDATA = "SALES_SEARCHDATA";
export const SALE_LIST = "SALE_LIST";
export const AllCONTRACT_LIST = "AllCONTRACT_LIST";
export const LASTEVL_KEY = "LASTEVL_KEY";
export const UPDATE_TAXESONFEES = "UPDATE_TAXESONFEES";
export const SEARCH_FORM_DATA = "SEARCH_FORM_DATA"











