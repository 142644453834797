import React, { forwardRef, ChangeEvent } from "react";
import { format } from 'date-fns';
import { Label } from './Label';

interface DatepickerProps {
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value: Date | string; // Adjusted to accept Date type for value
  divClass?: string;
  labelvisible?: boolean;
  labelName?: string;
  labelClassName?: string;
  min?: string; // Assuming min is in string format for date input
  dateFormat?: string; // New prop for date format
}

export const Datepicker = forwardRef<HTMLInputElement, DatepickerProps>(({
  disabled = false,
  onChange = () => {},
  className = '',
  value,
  divClass = "",
  labelvisible = false,
  labelName = "",
  labelClassName = "",
  min = "",
  dateFormat = 'mm/dd/yyyy' // Default format
}, ref) => {
  // Format date value if it's a Date object
  const formattedValue = typeof value === 'string' ? value : format(new Date(value), dateFormat);

  // Placeholder text
  const placeholderText = dateFormat.replace('yyyy', 'yyyy').replace('MM', 'mm').replace('dd', 'dd');

  return (
    <div className={divClass}>
      {labelvisible ? <Label className={labelClassName}>{labelName}</Label> : null}
        <input
          value={formattedValue}
          className={className}
          disabled={disabled}
          onChange={onChange}
          // type='date'
          ref={ref}
          min={min}
          type="date"
          id="date"
        />

    </div>
  );
});
