//@ts-nocheck
import { React ,useState} from 'react'
import { useSelector } from "react-redux";
import { TripType } from '../bookingConfirmation/Utils/Constant';

import FlightDetailsTab from './FlightDetailsTab';
import BaggageTab from './BaggageTab';
import PenaltiesTab from './PenaltiesTab'

import {
  Tabs,
  Tab,
} from "react-bootstrap";
import FlightDetailsTabMultiCity from './FlightDetailsTabMultiCity';

function Details(props) {
  
  const[tabKey,setTabKey]= useState("FlightDetails")
  
  const fareId = props.fareId
  
  
  // const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const tripTypeFromApi = useSelector((state) => state.searchForm.tripTypeFromApi);

const handleSelect = (k) => {
  setTabKey(k)
}


  return (
    <>

      <Tabs defaultActiveKey="FlightDetails" id="flightdetails" className='flight_tabs' onSelect={(k) =>handleSelect(k)}>
        <Tab eventKey="FlightDetails" title="Flight Details" className="tabs  b-0">

          <Tabs defaultActiveKey="inner-tabs-0" id="inner-flightdetail" className='inner-flight_tabs'>
            <Tab eventKey="inner-tabs-0" className="tabs  b-0">



              {tripTypeFromApi === TripType.MULTICITY ? <FlightDetailsTabMultiCity data={props.flightdata} paxFare={props.paxFare}/> : <FlightDetailsTab data={props.flightdata} paxFare={props.paxFare}/>}


            </Tab>
          </Tabs>
        </Tab>

        <Tab eventKey="Baggage" title="Baggage" className="tabs b-0 baggage-tabs px-0">

          <Tabs defaultActiveKey="inner-tabs-0" id="inner-Baggage" className='inner-flight_tabs'>
            <Tab eventKey="inner-tabs-0" className="tabs  b-0">
              {tabKey === "Baggage" &&
              <BaggageTab uiClass='col-md-12 p-0' baggagefares={props.baggagefares} flightName={props.flightName} flightDetails={props.flightdata} />
               }
            </Tab>
            <Tab eventKey="inner-tabs-0-1" className="tabs  b-0">
            </Tab>
            <Tab eventKey="inner-tabs-0-2" className="tabs  b-0">
            </Tab>
          </Tabs>

        </Tab>
        {props.fareId && <Tab eventKey="Penalties" title="Penalties" className="tabs flight_tabsPenalties  b-0 px-0">
          <Tabs defaultActiveKey="inner-tabs-0" id="inner-seat" className='inner-flight_tabs'>
            <Tab eventKey="inner-tabs-0" className="tabs  b-0">
            {tabKey === "Penalties" &&
              <PenaltiesTab uiClass='col-md-12 p-0' flightName={props.flightName} flightDetails={props.flightdata} fareId={fareId} from={props.from}/>
              }
              </Tab>
            <Tab eventKey="inner-tabs-0-1" className="tabs  b-0">
            </Tab>
            <Tab eventKey="inner-tabs-0-2" className="tabs  b-0">
            </Tab>
          </Tabs>
        </Tab>}
        {props.recordLocator && <Tab eventKey="Penalties" title="Penalties" className="tabs flight_tabsPenalties  b-0 px-0">
          <Tabs defaultActiveKey="inner-tabs-0" id="inner-seat" className='inner-flight_tabs'>
            <Tab eventKey="inner-tabs-0" className="tabs  b-0">
            {tabKey === "Penalties" &&
              <PenaltiesTab uiClass='col-md-12 p-0' flightName={props.flightName} flightDetails={props.flightdata} recordLocator={props.recordLocator} from={props.from}/>
              }
              </Tab>
            <Tab eventKey="inner-tabs-0-1" className="tabs  b-0">
            </Tab>
            <Tab eventKey="inner-tabs-0-2" className="tabs  b-0">
            </Tab>
          </Tabs>
        </Tab>}



      </Tabs>
    </>
  )
}

export default Details;
