import { useEffect, useState } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { string } from "yup";
import {  Alert } from "react-bootstrap";

export default function RoutingsTariff(props) {
    const [editFlag, setEditFlag] = useState(false);
    const [jsonData, setJsonData] = useState({});
    const [alert, setAlert] = useState({ variant: 'success', show: false, message: '' });
    const [routingData, setRoutingData] = useState({});

    useEffect(() => {
        setRoutingData(props.routingData)
    }, [])
    // ONCHANGE VALUES FUNCTIONS
    const editOncahgeFunction = (e, type) => {
        let charValues = jsonData
        setJsonData({ ...charValues, [type]: e.target.value })
    }

    const selectEdit = () => {
        setEditFlag(true)
        setJsonData(props.routingData)
        setSkipRoutingCheck(props.routingData.skipRoutingCheck)
        setDisallowedAirports(props.routingData.disallowedAirports)
        setOld_tarifRoutings(props.routingData.old_tarifRoutings)

    }

    const [disallowedAirports ,setDisallowedAirports] = useState('')
    const [skipRoutingCheck ,setSkipRoutingCheck] = useState('')
    const [old_tarifRoutings ,setOld_tarifRoutings] = useState('')


    //    FUNCTION TO UPDATE ROUTING DATA
    const updateRoutingData = () => {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,

            },
            body: JSON.stringify({
                disallowedAirports: [disallowedAirports?.toString()],
                skipRoutingCheck:skipRoutingCheck,
                old_tarifRoutings:old_tarifRoutings
            })
        }
        fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${props.tariffId}`, requestOptions)
            .then(response => response.json())
            // console.log("response",response)
            .then(result => {
                setRoutingData(result)
                setAlert({ variant: 'success', show: true, message: 'Tariff updated   successfully.' });
                setEditFlag(false)
            })
            .catch(error => console.error("Error: ", error))
        setEditFlag(false)

    }

    return (
        <div className="card cm-card">
            <div className="card-body">
            <Alert
                variant={alert.variant}
                show={alert.show}
                onClose={() => setAlert({ ...alert, show: false })}
                dismissible
            >
            {alert.message}
            </Alert>
            {props.routingData.source !== undefined &&
                <div className="cm-card-edit">
                    {editFlag ?
                        <>
                            <button className="cm-secondary-btn mr-2" type="button" onClick={(e) => setEditFlag(false)}>Cancel</button>
                            <button className="cm-primary-btn" onClick={() => updateRoutingData()}> Save</button>
                        </>
                        :
                        <i style={{ cursor: 'pointer', color: '#0465ac' }} onClick={(e) => selectEdit(true)} class="far fa-edit"></i>
                    }
                </div>
                }
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <div className="cm-col">
                            <label className="cm-head-label">Airport not allowed </label>
                            {!editFlag ?
                                <>
                                       <input
                                            className="form-control cm-input"
                                            value={routingData.disallowedAirports}
                                            disabled
                                        />
                                </>
                                :
                                <input
                                    className="form-control cm-input"
                                    onChange={(e) => setDisallowedAirports(e.target.value)}
                                    value={disallowedAirports}
                                />
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="cm-col mt-5">
                            <div className="cm-checkbox-inline">
                                {editFlag ?
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={skipRoutingCheck}
                                                color="primary"
                                                onChange={(e) => setSkipRoutingCheck(!skipRoutingCheck)}
                                            />
                                        }
                                        label="No Routing validation By startrek"
                                    />
                                    :
                                    <FormControlLabel
                                        disabled
                                        control={
                                            <Checkbox
                                                checked={routingData && routingData.skipRoutingCheck}
                                                color="primary"

                                            />
                                        }
                                        label="No Routing validation By startrek"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <div className="cm-col">
                            <label className="cm-head-label">Routing field (used when routing is validated by startrek) </label>
                            {!editFlag ?
                                <>
                                      <input
                                            className="form-control cm-input"
                                            disabled
                                            value={routingData.old_tarifRoutings}
                                        />
                                    
                                </>
                                :
                                <input
                                    className="form-control cm-input"
                                    onChange={(e) => setOld_tarifRoutings(e.target.value)}
                                    value={old_tarifRoutings}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
