import { useContext, useEffect, useState } from "react";
import Table from '@material-ui/core/Table';
import { Link, useHistory } from "react-router-dom";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import authContext from "../../context/auth-context";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from "react-redux";
import AgentSearchData from '../../appStore/actions/AgentSearchData';

export default function Agentlist(props: any) {
    const userInfo = useSelector((state:any) => state.userInfo);
    const loggedUsername = userInfo?.userData?.username
    const { orgId } = useContext(authContext);
    // const [agentData , setAgentData] = useState<any>([])
    const [loading, setLoading] = useState(true);
    const [searchFirstName, setSearchFirstName] = useState<any>('');
    const [mobile, setMobile] = useState<any>('');
    const [userID, setUserID] = useState<any>('');
    const [noDataFlag, SetNoDataFlag] = useState<any>(false)
    const [isLoading, setIsLoading] = useState<any>(false)
    const [exUserId, setExUserId] = useState<any>('')
    const [firstRender, setFirstRender] = useState<any>(false)

    const { agentListData } = useContext(authContext);
    const { updateAgentListData } = useContext(authContext);

    const history = useHistory();
    const dispatch = useDispatch();
    let searchDataAgent:any = useSelector((state:any) => state.agentSearchData); 

    const checkRender = () => {
        setFirstRender(true)
    }

    // PAGINATION VARIABLE
    const [isLast, setIsLast] = useState(false);
    const [isFirst, setIsFirst] = useState(false);
    const [counter, setCounter] = useState<any>(0);
    const [pk, setPk] = useState<any>('');
    const [lastArry, setLastArry] = useState<any>([])
    const [priviousLastEvaluatedKey, setPriviousLastEvaluatedKey] = useState<any>("");
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>({})
    const [agencyId, setAgencyId] = useState<any>('')
    const [agencyDataFOrSearch ,setAgencyDataFOrSearch ] = useState<any>()
    const [agencyData, setAgencyData] = useState<any>([]);

     

    // FIRST API CALL 
    useEffect(() => {
        setIsLoading(true)
        setSearchFirstName(searchDataAgent.agentSearchData.searchFirstName)
        setMobile(searchDataAgent.agentSearchData.mobile)
        setUserID(searchDataAgent.agentSearchData.userID)
        setAgencyDataFOrSearch(searchDataAgent.agentSearchData?.AgencyList)
        setAgencyId(searchDataAgent?.agentSearchData?.AgencyList?.travelBusinessesId)
        if(searchDataAgent?.agentSearchData?.AgencyList !== null){
            setAgencyData([searchDataAgent?.agentSearchData?.AgencyList])

        }
        if (props.match.params.flag === "false") {
            let corrId = `agent-users_external_all-read-`+ loggedUsername +"_"+ orgId +"-" + Date.now()

            fetch(`${process.env.REACT_APP_BASE_URL}/users/external/all?orgId=${orgId}`, {
                method: 'POST',
                headers: {
                    correlationid:corrId
                  }
            })
                .then(response => response.json())
                .then(result => {
                    updateAgentListData(result && result.Items)
                    //  setAgentData(result.Items)
                    setPk(result?.LastEvaluatedKey?.pk)

                    setIsLoading(true)
                    setLastEvaluatedKey(result.LastEvaluatedKey)
                }
                )

                .catch(error => console.error("Error: ", error))
                .finally(() => setLoading(false));
        }
    }, [orgId]);

    const getAutoSuggestAgency = (value: any) => {
        let filterValue = value?.replace(/-/g, "")

        if (value.length > 1) {
            fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${orgId}&adAgencyCode=${filterValue}`, {
                method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.json())
                // console.log("response",response)
                .then(result => {
                    result.map((data: any) => {
                        data['seacrhVariable'] = data.adAgencyCode + '-' + data.searchTravelBusinessesName + '-' + data.adNetworkId
                    })
                    result.message === "No records found." ? setAgencyData([]) : setAgencyData(result)
                })
                .catch(error => console.error("Error: ", error))
        }
    }
    const agencyFocusData = (value: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/all?orgId=${orgId}`, {
          method: 'POST',
          redirect: 'follow',
        })
          .then((response) => response.json())
          .then((result) => {
            result.Items.forEach((data: any) => {
                data['seacrhVariable'] = data.adAgencyCode + '-' + data.searchTravelBusinessesName + '-' + data.adNetworkId
            })
            result.message === "No records found." ? setAgencyData([]) : setAgencyData(result.Items)
          })
          .catch((error) => console.error('Error: ', error));
      };

    //AUTO Search API CALL   
    async function autoSearchApiCall() {
        setIsLoading(false)
        setAgencyId(agencyDataFOrSearch && agencyDataFOrSearch?.travelBusinessesId)
        var strUrl = `/users/external/search?orgId=${orgId}`

        if (searchFirstName !== '') {
            let url
            url = `&name=${searchFirstName}`
            strUrl = strUrl + url
        }

        if (userID !== '') {
            let url1
            url1 = `&userId=${userID}`
            strUrl = strUrl + url1
        }

        if (mobile !== '') {
            let url2
            url2 = `&contactNumber=${mobile}`
            strUrl = strUrl + url2
        }

        if ((agencyId !== '') && (agencyId !== null)  && (agencyDataFOrSearch?.travelBusinessesId !== undefined)) {
            let url3
            url3 = `&travelBusinessesId=${agencyDataFOrSearch?.travelBusinessesId}`
            strUrl = strUrl + url3
        }
        if(agencyDataFOrSearch !== null){
            agencyDataFOrSearch['seacrhVariable'] =  agencyDataFOrSearch?.adAgencyCode + '-' + agencyDataFOrSearch?.searchTravelBusinessesName + '-' + agencyDataFOrSearch?.adNetworkId
        }
        console.log("agencyDataFOrSearch",agencyDataFOrSearch)
        if(agencyDataFOrSearch?.seacrhVariable === 'undefined-undefined-undefined'){
            agencyDataFOrSearch['seacrhVariable'] = ""
        }
        dispatch(AgentSearchData({
            userID:userID,
            mobile:mobile,
            searchFirstName:searchFirstName,
            AgencyList:agencyDataFOrSearch ,
            
          }));


        await fetch(`${process.env.REACT_APP_BASE_URL}${strUrl}`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                if (result.message === undefined) {
                    updateAgentListData(result)

                    // setAgentData(result)
                    SetNoDataFlag(false)
                    setIsLoading(true)
                }
                else {
                    // setAgentData([])
                    updateAgentListData([])
                    SetNoDataFlag(true)
                    setIsLoading(true)

                }
            })
            .catch(error => console.error("Error: ", error))

    }

    //DELETE API CALL 
    async function deleteApiCall() {
        setIsLoading(false)
        await fetch(`${process.env.REACT_APP_BASE_URL}/users/external?userId=${exUserId}&orgId=${orgId}`, {
            method: 'DELETE',
        })
            .then(response => response.json())
            .then(result => {
                setOpen(false);
                setIsLoading(true)
                clearSearch()

                fetch(`${process.env.REACT_APP_BASE_URL}/users/external/all?orgId=${orgId}`, {
                    method: 'POST',
                    redirect: 'follow'
                })
                    .then(response => response.json())
                    .then(result => {
                        updateAgentListData(result.Items)
                        //  setAgentData(result.Items)

                        setIsLoading(true)
                        setLastEvaluatedKey(result.LastEvaluatedKey)
                    }
                    )

                    .catch(error => console.error("Error: ", error))
                    .finally(() => setLoading(false));

            })
            .catch(error => console.error("Error: ", error))
    }

    // Function TO Clear Search 
    const clearSearch = () => {
        setAgencyId('')
        setAgencyData([])
        setSearchFirstName('')
        setMobile('')
        setUserID('')
        dispatch(AgentSearchData({
            userID:'',
            mobile:'',
            searchFirstName:'',
            AgencyList:[]
          }));
    }

    const [open, setOpen] = useState(false);
    const handleClickOpen = (value: any) => {
        setExUserId(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // REDIRECT TO UPDATE
    const redirectToupdate = (value: any) => {
        history.push(`/agentprofile/update/${value}`);
    }


    //PAGINATION FUNCTION
    const paginationNext = async () => {
        setIsLoading(false);

        setIsFirst(false)
        setPriviousLastEvaluatedKey(lastEvaluatedKey)
        let url: any = `${process.env.REACT_APP_BASE_URL}/users/external/all?orgId=${orgId}`;

        let requestbody = {
            lastEvaluatedKey: {
                sk: lastEvaluatedKey?.sk,
                pk: lastEvaluatedKey?.pk,
            },
        };

        const requestOptions = {
            method: "POST",

            headers: {
                "Content-type": "application/json; charset=UTF-8",

                "Access-Control-Allow-Origin": "*",

                "Access-Control-Allow-Credentials": true,

                "cache-control": "no-cache",
            },

            body: JSON.stringify(requestbody),
        };

        const agentData = await getApiResponse(url, requestOptions);

        if (agentData) {
            // setAgentData(agentData.Items)
            updateAgentListData(agentData.Items)

            setIsLoading(true)
            setLastEvaluatedKey(agentData.LastEvaluatedKey);
            if (agentData.LastEvaluatedKey === undefined) {
                setIsLast(true)
            } else {
                setLastEvalatuedValue(agentData.LastEvaluatedKey);
                setIsLast(false)

            }

        }
    };


    async function getApiResponse(url: any, requestOptions: any) {
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            return null;
        } else {
            const data = await response.json();

            return data;
        }
    }


    //PAGINATION FUNCTION
    const paginationPrevious = async () => {
        setIsLast(false)
        setIsLoading(false);

        if (counter === 1) {
            setIsLoading(true)
            fetch(`${process.env.REACT_APP_BASE_URL}/users/external/all?orgId=${orgId}`, {
                method: 'POST',
            })
                .then(response => response.json())
                .then(result => {
                    updateAgentListData(result.Items)
                    //  setAgentData(result.Items)
                    updateAgentListData(result.Items)
                    setIsLoading(true)
                    setLastEvaluatedKey(result.LastEvaluatedKey);
                    setPk(result?.LastEvaluatedKey?.pk)
                    setIsLoading(true)
                    setIsFirst(true)
                    setLastArry([])
                }
                )

                .catch(error => console.error("Error: ", error))
                .finally(() => setLoading(false));

        } else {
            let url: any = `${process.env.REACT_APP_BASE_URL}/users/external/all?orgId=${orgId}`
            let requestbody = {

                lastEvaluatedKey: {
                    sk: isLast ? lastArry[counter - 2] : lastArry[counter - 1],
                    pk: pk,
                },
            };

            const requestOptionsData = {
                method: "POST",

                headers: {
                    "Content-type": "application/json; charset=UTF-8",

                    "Access-Control-Allow-Origin": "*",

                    "Access-Control-Allow-Credentials": true,

                    "cache-control": "no-cache",
                },

                body: JSON.stringify(requestbody),
            };

            const agentData = await getApiResponseForPri(url, requestOptionsData);

            if (agentData) {
                // setAgentData(agentData.Items)
                setIsLoading(true)
                setLastEvaluatedKey(agentData.LastEvaluatedKey);
                updateAgentListData(agentData.Items)

            }
        }
    };

    //PAGINATION FUNCTION
    async function getApiResponseForPri(url: any, requestOptionsData: any) {
        const response = await fetch(url, requestOptionsData);

        if (!response.ok) {
            return null;
        } else {
            const data = await response.json();

            return data;
        }
    }

    // PUSHED IN LASTEVALUATED KEY
    const setLastEvalatuedValue = (value: any) => {
        let newArry = lastArry
        if (newArry.includes(value.sk)) {
            newArry = newArry.filter((id: any) => id.sk !== value.sk);
        } else {
            newArry.push(value.sk);
        }
        setLastArry(newArry)

    }


    // NEXT CLICK
    const nextClick = () => {
        setCounter(counter + 1)
        paginationNext()
    }

    // NEXT CLICK
    const priviousClick = () => {
        setIsLast(false)
        paginationPrevious()

        setCounter(counter - 1)

    }
   
    const getSplitValues = (str = "") => {
        const strLength =  str.split(" - ");
        const lastStr = strLength.splice(strLength.length - 1, 1)
        let completeString:any = {};
        completeString["key1"] = strLength.join(" - ");
        completeString["key2"] = lastStr;
        return completeString;
      }
      const formatOptionLabel = (label:any) => {
        return<div data-as={label}>{getSplitValues(label).key1} <span style={{opacity: 0, position: "absolute", left:0}}>{getSplitValues(label).key2}</span></div>
      }
    return (
        <>
            <div className="card cm-card">
                <div className="card-header">
                    <div className="cm-card-head-btn">
                        <h4 className="cm-card-head">Agent Profile Management</h4>
                        <Link to="/create" className="cm-yellow-btn">
                            Create
                        </Link>
                    </div>
                </div>
                <div className="card-body">
                    <div className="col-12">
                        <p className="cm-head-2">Search Fields</p>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-6 cm-col">
                            <label className="cm-head-label">User Id</label>
                            <input
                                className="form-control cm-input"
                                placeholder="Enter user Id"
                                onChange={(e) => setUserID(e.target.value)}
                                value={userID}
                            />

                        </div> */}
                        <div className="col-md-6 cm-col">
                            <label className="cm-head-label">Username</label>
                            <input
                                className="form-control cm-input"
                                placeholder="Enter username"
                                onChange={(e) => setSearchFirstName(e.target.value)}
                                value={searchFirstName}
                            />
                        </div>
                        <div className="col-md-6 cm-col">
                            <label className="cm-head-label">Agency</label>
                            <Autocomplete
                                id="size-small-outlined"
                                size="small"
                                freeSolo
                                options={agencyData}
                                style={{ background: 'white' }}
                                onChange={(event, value) => setAgencyDataFOrSearch(value)}
                                getOptionLabel={(option: any) => option?.seacrhVariable}
                                
                                // getOptionLabel = {formatOptionLabel}
                                
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined"
                                        // label="Agency" 
                                        value={agencyDataFOrSearch}
                                        onChange={(e) => getAutoSuggestAgency(e.target.value)}
                                        placeholder="Select Agency"
                                        onFocus={(e: any) => agencyFocusData(e.target.value)}
                                    
                                    />
                                )}
                            />
                        </div>

                    </div>

                    <div className="row">
                   
                        <div className="col-md-6 cm-col">
                            <label className="cm-head-label">Mobile </label>
                            <input
                                className="form-control cm-input"
                                placeholder="Enter mobile number"
                                onChange={(e) => setMobile(e.target.value)}
                                value={mobile}
                            />
                        </div>
                    </div>
                    <div className="col-12 cm-col text-right">
                        <button
                            className="cm-primary-btn"
                            onClick={() => autoSearchApiCall()}
                        >
                            Search
                            <div className="ld ld-ring ld-spin"></div>
                        </button>
                        <button
                            className="cm-secondary-btn ml-3"
                            type="reset"
                            onClick={(e) => clearSearch()}
                        >
                            Clear
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {!noDataFlag &&
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Username</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>User Id </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Mobile</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Email Address</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Profile Creation Date</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {isLoading ?
                                    <>
                                        <TableBody>
                                            {agentListData && agentListData.map((agent: any) => {
                                                return (
                                                    <>
                                                        {Object.keys(agent).includes("adAgencyCode") &&

                                                            <TableRow >
                                                                <TableCell component="th" scope="row">{agent && agent.username && agent.username} </TableCell>
                                                                <TableCell component="th" scope="row">{agent && agent.userId && agent.userId} </TableCell>
                                                                <TableCell component="th" scope="row"> {agent && agent.contactNumber && agent.contactNumber} </TableCell>
                                                                <TableCell component="th" scope="row">{agent && agent.email && agent.email} </TableCell>
                                                                <TableCell component="th" scope="row"></TableCell>
                                                                {/* <TableCell component="th" scope="row">{ agent &&  agent.creationDate && agent.creationDate} </TableCell> */}
                                                                <TableCell component="th" scope="row">
                                                                    <div className="cm-edidel-btns">
                                                                        <button className="cm-view-btn mr-2" onClick={(e) => redirectToupdate(agent.userId && agent.userId)}>
                                                                            <i className="fas fa-eye"></i>
                                                                        </button>
                                                                        <button className="cm-edit-del" onClick={() => handleClickOpen(agent.userId && agent.userId)}>
                                                                            <i className="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    </>
                                                )
                                            })}

                                        </TableBody>


                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                            className="agent-del-popup"
                                        >
                                            <div className="agent-del-popup-body">
                                                <label>Are you sure you want to delete the profile?</label>
                                                <button className="cm-primary-btn mr-3" onClick={(e) => deleteApiCall()}>Yes</button>
                                                <button className="cm-secondary-btn" onClick={handleClose}>No</button>
                                            </div>
                                        </Dialog>
                                    </>
                                    :
                                    <div style={{ justifyContent: 'center' }}>
                                        <span className="badge badge-light mt-3 ml-3 mb-3"><span className="text-danger">Loading ...</span> </span>
                                    </div>

                                }
                            </Table>
                        </TableContainer >
                    }
                    {noDataFlag &&
                        <div className="alert alert-info mt-2" role="alert">
                            No Results found for the selected criteria.
                        </div>
                    }

                    { noDataFlag ? null :<div className="d-flex justify-content-between mt-3">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className={counter === 0 ? "page-item disabled" : "page-item "}>
                                    <a className="page-link" tabIndex={-1}
                                        onClick={() => priviousClick()}

                                    >
                                        Previous
                                    </a>
                                </li>
                            </ul>
                        </nav>

                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className={isLast ? "page-item  disabled" : "page-item "}>
                                    <a
                                        className="page-link"
                                        onClick={() => nextClick()}
                                        tabIndex={-1}
                                    >
                                        Next

                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>}
                </div>
            </div>
        </>
    )
}
