import React, { useState } from 'react'
import { Checkbox, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch ,useSelector } from "react-redux";
import { putBulkUpdate } from '../thunks/putBulkUpdate';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { setSearchAllV2 } from "../../contractManagement/AppStore/ActionCreator/commonDataAction";

const BulkUpdate = () => {
    const dispatch = useDispatch()
    const [origins, setOrigins] = useState("");
    const [destinations, setDestinations] = useState("");
    const [marketingAirline, setMarketingAirline] = useState("");
    const [mappedOpeartingAirlines, setMappedOpeartingAirlines] = useState("");
    const [maximumFeeCCAmoutByCarrier, setMaximumFeeCCAmoutByCarrier] = useState("");
    const [maximumFeeCCBycarrierType, setMaximumFeeCCBycarrierType] = useState("");
    const [note, setNote] = useState("");
    const [ccFeesForTBMerchant, setCcFeesForTBMerchant] = useState("");
    const [landFare, setLandFare] = useState(null);
    const [cruiseFare, setCruiseFare] = useState(null);
    const [cabinType, setCabinType] = useState([]);
    const [tripType, setTripType] = useState("");
    const [isBrandedFare, setIsBrandedFare] = useState("");
    const [distribution, setDistribution] = useState([])
    const [formsOfPayment, setFormsOfPayment] = useState([]);

    // Class related fields

    const [brandCode, setBrandCode] = useState("")
    const [classCodes, setClassCodes] = useState("")
    const [category, setCategory] = useState("")
    const [contractType, setContractType] = useState("")
    const [ticketingFee, setTicketingFee] = useState("")
    const [classNote, setClassNote] = useState("")
    const [minmaxStay, setMinmaxStay] = useState("")
    const [classLastTicketingDate, setClassLastTicketingDate] = useState(null)

    //Class Rules
    const [ageRestrictionsInfantAge, setAgeRestrictionsInfantAge] = useState("")
    const [ageRestrictionsInfantAgeApplicableOn, setAgeRestrictionsInfantAgeApplicableOn] = useState("")
    const [ageRestrictionsChildAge, setAgeRestrictionsChildAge] = useState("")
    const [juniorAgeFrom, setJuniorAgeFrom] = useState(0)
    const [juniorAgeTo, setJuniorAgeTo] = useState(0)
    const [ageRestrictionsNoTicketingForInfant, setAgeRestrictionsNoTicketingForInfant] = useState(false)
    const [osi, setOsi] = useState("")
    const [permitBranchPNR, setpermitBranchPNR] = useState(false)

    //Class Ticket By / Changes
    const [optionRulesDepartureWindow, setOptionRulesDepartureWindow] = useState("")
    const [optionRulesDaysAfterReservation, setOptionRulesDaysAfterReservation] = useState("")
    const [optionRulesDaysBeforeDeparture, setOptionRulesDaysBeforeDeparture] = useState("")
    const [ticketingRulesDepartureWindow, setTicketingRulesDepartureWindow] = useState("")
    const [ticketingRulesDaysAfterReservation, setTicketingRulesDaysAfterReservation] = useState("")
    const [ticketingRulesDaysBeforeDeparture, setTicketingRulesDaysBeforeDeparture] = useState("")

    //Class Routing
    const [classRoutings, setClassRoutings] = useState("")

    //Class Price at Sabre
    const [disableAutoTicketing, setDisableAutoTicketing] = useState(null)
    const [disablePriceUnitConcept , setDisablePriceUnitConcept ] = useState(null)
    const [issueAsBulk , setIssueAsBulk ] = useState(false)
    const [fareType, setFareType] = useState("")
    const [accountCodeVIT, setAccountCodeVIT] = useState("")
    const [accountCodeSKY, setAccountCodeSKY] = useState("")
    const [accountCodeDFW, setaccountCodeDFW] = useState("")
    const [accountCodeFUN, setaccountCodeFUN] = useState("")
    const [accountCodeTC, setaccountCodeTC] = useState("")
    const [ticketDesignatorVIT, setTicketDesignatorVIT] = useState("")
    const [ticketDesignatorSKY, setTicketDesignatorSKY] = useState("")
    const [ticketDesignatorDFW, setTicketDesignatorDFW] = useState("")
    const [ticketDesignatorTC, setTicketDesignatorTC] = useState("")
    const [ticketDesignatorFUN, setTicketDesignatorFUN] = useState("")

    //Class Remarks
    const [remarksVITENG, setRemarksVITENG] = useState("")
    const [remarksVITFR, setRemarksVITFR] = useState("")
    const [remarksSKYENG, setRemarksSKYENG] = useState("")
    const [remarksSKYFR, setRemarksSKYFR] = useState("")
    const [remarksFUNENG, setRemarksFUNENG] = useState("")
    const [remarksFUNFR, setRemarksFUNFR] = useState("")
    const [remarksTCENG, setRemarksTCENG] = useState("")
    const [remarksTCFR, setRemarksTCFR] = useState("")
    const [remarksDFWENG, setRemarksDFWENG] = useState("")
    const [remarksDFWFR, setRemarksDFWFR] = useState("")

    //Prix
    const [validFrom, setValidFrom] = useState(null)
    const [validTo, setValidTo] = useState(null)
    const [prixLastReturnDate, setPrixLastReturnDate] = useState(null)
    const [prixLastTicketingDate, setPrixLastTicketingDate] = useState(null)
    const [prixNote, setPrixNote] = useState("")

    //Prix Price
    const [adultCommissionOnYQ, setAdultCommissionOnYQ] = useState("")
    const [childCommissionOnYQ, setChildCommissionOnYQ] = useState("")
    const [enfantCommissionOnYQ, setEnfantCommissionOnYQ] = useState("")
    const [markup, setmarkup] = useState("")
    const [childMarkup, setChildMarkUp] = useState("")
    const [infantMarkUp, setInfantMarkUp] = useState("")
    const [adultPassBackCommissionOnYQ, setadultPassBackCommissionOnYQ] = useState("")
    const [childPassBackCommissionOnYQ, setChildPassBackCommissionOnYQ] = useState("")
    const [enfantPassBackCommissionOnYQ, setEnfantPassBackCommissionOnYQ] = useState("")
    const [adultMinSelling, setAdultMinSelling] = useState("")
    const [childMinSelling, setChildMinSelling] = useState("")
    const [infantMinSelling, setInfantMinSelling] = useState("")

    //Prix Navcan
    const [adultCommissionOnYQNav, setAdultCommissionOnYQNav] = useState("")
    const [childCommissionOnYQNav, setChildCommissionOnYQNav] = useState("")
    const [enfantCommissionOnYQNav, setEnfantCommissionOnYQNav] = useState("")
    const [adultPassBackCommissionOnYQNav, setAdultPassBackCommissionOnYQNav] = useState("")
    const [childPassBackCommissionOnYQNav, setChildPassBackCommissionOnYQNav] = useState("")
    const [enfantPassBackCommissionOnYQNav, setEnfantPassBackCommissionOnYQNav] = useState("")

    //Prix Exceptions


    //Prix Emissions
    const [endorsementVIT, setEndorsementVIT] = useState("")
    const [endorsementSKY, setEndorsementSKY] = useState("")
    const [endorsementDFW, setEndorsementDFW] = useState("")
    const [endorsementFUN, setEndorsementFUN] = useState("")
    const [endorsementTC, setEndorsementTC] = useState("")
    

    //Additional
    const blackoutDate = {"fromDate":"","toDate":"","aller":undefined,"retour":undefined,"exceptionType":""}
    const [blackoutDatesRepetative, setBlackoutDatesRepetative] = useState([{...blackoutDate}])

    const blackoutDay = {"day":"","aller":undefined,"retour":undefined,"exceptionType":""}
    const [blackoutDaysRepetative, setBlackoutDaysRepetative] = useState([{...blackoutDay}])

    const blackoutAirline = {"flightNumberFrom":"","flightNumberTo":"","carrierCode":"","aller":undefined,"retour":undefined,"exceptionType":""}
    const [blackoutAirlinesRepetative, setBlackoutAirlinesRepetative] = useState([{...blackoutAirline}])

    const tourCode = {
        "tourCodes": "",
        "marketCode": "",
        "passengerType": ""
    }

    const checkBoxMappings = {
        'Business': 'Business',
        'Economy': 'Economy',
        'Premium Business': 'PremiumBusiness',
        'Premium Economy': 'PremiumEconomy',
        'First Class': 'First',
        'Cheque': 'CHEQUE',
        'Credit Card': 'CREDITCARD',
        'Credit Internal': 'CREDITINTERNAL',
        'Sky Link': 'Sky Link',
        'Intair Transit': 'Intair Transit',
        'Fun Sun': 'Fun Sun',
        'DFW': 'DFW',
        'Sunquest': 'Sunquest',
        'Network': 'Network'
    }

    const businessCabin = ['Business', 'PremiumBusiness', 'First']
    const economyCabin = ['Economy', 'PremiumEconomy']

    const [tourCodeRepetative, setTourCodeRepetative] = useState([{...tourCode}])

    const onChangeForMultiple = (value, index, key, initialArray, setArray) => {
        let temp = [...initialArray]
        Object(temp[index])[key] = value
        setArray([...temp])
    }

  const checkBoxObject = useSelector((state) => state.commonDataReducer.checkBoxObject);
  const history = useHistory();


    const CheckBoxTypeSelector = ({checkBoxTypes, mainLabel, setMethod, useStateVariable=[]}) => {
        // Function to handle checkbox changes
        const handleCheckboxChange = (e, cabinType) => {
            if (useStateVariable.includes(cabinType)) {
                // If already selected, remove from the array
                setMethod(useStateVariable.filter((type) => type !== cabinType));
            } else {
                // If not selected, add to the array
                setMethod([...useStateVariable, cabinType]);
            }
        };

        return (
            <div className="col-md-3 cm-col">
                <label className="cm-head-label">{mainLabel}</label>

                <div className="row">
                    {checkBoxTypes.map((checkBox, index) => (
                        <div key={index} className="col-md-6">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={useStateVariable.includes(checkBoxMappings[checkBox])}
                                        onChange={(e) => handleCheckboxChange(e,checkBoxMappings[checkBox])}
                                        disabled={mainLabel == "Cabin Type" ? useStateVariable?.length ? businessCabin.includes(useStateVariable[0]) ? economyCabin.includes(checkBoxMappings[checkBox]) : businessCabin?.includes(checkBoxMappings[checkBox])  : false : false}
                                    />
                                }
                                label={checkBox}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const saveHandle = async () => {
        let tarifData = {
            "origins" : origins != "" ? origins.split(",").map(function (value) {return value.trim();}) : undefined,
            "destinations" : destinations != "" ? destinations.split(",").map(function (value) {return value.trim();}) : undefined,
            "marketingAirline" : marketingAirline != "" ? marketingAirline.split(",").map(function (value) {return value.trim();}) : undefined,
            "mappedOpeartingAirlines" : mappedOpeartingAirlines != "" ? mappedOpeartingAirlines.split(",").map(function (value) {return value.trim();}) : undefined,
            "maximumFeeCCBycarrierType" : maximumFeeCCBycarrierType != "" ? maximumFeeCCBycarrierType : undefined,
            "maximumFeeCCAmoutByCarrier" : checkForEmptyAndConvertToNumber(maximumFeeCCAmoutByCarrier) ,
            "note" : note != "" ? note : undefined,
            "ccFeesForTBMerchant" : checkForEmptyAndConvertToNumber(ccFeesForTBMerchant) ,
            "landFare" : landFare ? landFare : landFare == null ? undefined : 0,
            "cruiseFare" : cruiseFare ? cruiseFare : cruiseFare == null ? undefined : 0,
            "cabinType" : cabinType?.length ? cabinType : undefined,
            "tripType" : tripType != "" ? tripType : undefined,
            "isBrandedFare" : isBrandedFare != "" ? isBrandedFare : undefined,
            "DFW": distribution.includes('DFW') ? true : undefined,
            "funSun" : distribution.includes('Fun Sun') ? true : undefined,
            "intairTransit" : distribution.includes('Intair Transit') ? true : undefined,
            "skyLink" : distribution.includes('Sky Link') ? true : undefined,
            "sunquest" : distribution.includes('Sunquest') ? true : undefined,
            "network" : distribution.includes('Network') ? true : undefined,
            "formsOfPayment" : formsOfPayment?.length ? formsOfPayment : undefined
        }

        let classData = {
            "brandCode":brandCode != "" ? brandCode : undefined,
            "classCodes": classCodes != "" ? classCodes.split(",").map(function (value) {return value.trim();}) : undefined,
            "category": category != "" ? category : undefined,
            "contractType": contractType != "" ? contractType : undefined,
            "ticketingFee": checkForEmptyAndConvertToNumber(ticketingFee) ,
            "classNote": classNote != "" ? classNote : undefined,
            "minmaxStay": minmaxStay != "" ? minmaxStay : undefined,
            "classLastTicketingDate": classLastTicketingDate ? classLastTicketingDate : undefined,
            "ageRestrictions" :(ageRestrictionsChildAge || ageRestrictionsInfantAge || ageRestrictionsInfantAgeApplicableOn) ? {"childAge":ageRestrictionsChildAge != "" ? Number(ageRestrictionsChildAge) : undefined,"infantAge": ageRestrictionsInfantAge != "" ? Number(ageRestrictionsInfantAge) : undefined,"infantAgeApplicableOn": ageRestrictionsInfantAgeApplicableOn != "" ? ageRestrictionsInfantAgeApplicableOn : undefined, "noTicketingForInfant":false} : undefined, 
            "juniorAgeFrom": juniorAgeFrom ? juniorAgeFrom : undefined,
            "juniorAgeTo" : juniorAgeTo ? juniorAgeTo : undefined,
            "osi" : osi != "" ? osi : undefined,
            "permitBranchPNR" : permitBranchPNR ? permitBranchPNR : undefined,
            "ticketingRules " : (optionRulesDepartureWindow || optionRulesDaysAfterReservation || optionRulesDaysBeforeDeparture || ticketingRulesDepartureWindow || ticketingRulesDaysAfterReservation || ticketingRulesDaysBeforeDeparture) ?  {"optionDates":[{"departureWindow":optionRulesDepartureWindow != "" ? optionRulesDepartureWindow : undefined,"daysAfterReservation":optionRulesDaysAfterReservation != "" ? optionRulesDaysAfterReservation : undefined,"daysBeforeDeparture":optionRulesDaysBeforeDeparture != "" ? optionRulesDaysBeforeDeparture : undefined}],"ticketDates":[{"departureWindow":ticketingRulesDepartureWindow != "" ? ticketingRulesDepartureWindow : undefined,"daysAfterReservation":ticketingRulesDaysAfterReservation != "" ? ticketingRulesDaysAfterReservation : undefined,"daysBeforeDeparture":ticketingRulesDaysBeforeDeparture != "" ? ticketingRulesDaysBeforeDeparture : undefined}]} : undefined,
            "classRoutings" : classRoutings != "" ? classRoutings : undefined,
            "disableAutoTicketing" : disableAutoTicketing !=null ? disableAutoTicketing : undefined,
            "disablePriceUnitConcept" : disablePriceUnitConcept !=null ? disablePriceUnitConcept : undefined,
            "issueAsBulk" : issueAsBulk ? issueAsBulk : undefined,
            "fareType" : fareType != "" ? fareType : undefined,
            "accountCode" : (accountCodeVIT || accountCodeTC || accountCodeSKY || accountCodeFUN || accountCodeDFW) ? {"DFW":accountCodeDFW != "" ? accountCodeDFW : undefined,"FUN":accountCodeFUN != "" ? accountCodeFUN : undefined,"SKY":accountCodeSKY != "" ? accountCodeSKY : undefined,"TC":accountCodeTC != "" ? accountCodeTC : undefined,"VIT":accountCodeVIT != "" ? accountCodeVIT : undefined} : undefined,
            "ticketDesignator": (ticketDesignatorVIT || ticketDesignatorSKY || ticketDesignatorDFW || ticketDesignatorTC || ticketDesignatorFUN) ? {"DFW":ticketDesignatorDFW != "" ? ticketDesignatorDFW : undefined,"FUN":ticketDesignatorFUN != "" ? ticketDesignatorFUN : undefined,"SKY":ticketDesignatorSKY != "" ? ticketDesignatorSKY : undefined,"TC":ticketDesignatorTC != "" ? ticketDesignatorTC : undefined,"VIT":ticketDesignatorVIT != "" ? ticketDesignatorVIT : undefined} : undefined,
            "remarks" : (remarksVITFR || remarksVITENG || remarksTCFR || remarksTCENG || remarksSKYFR || remarksSKYENG || remarksFUNFR || remarksFUNENG || remarksDFWFR || remarksDFWENG ) ? {"DFW":{"english":remarksDFWENG != "" ? remarksDFWENG : undefined,"french":remarksDFWFR != "" ? remarksDFWFR : undefined},"FUN":{"english":remarksFUNENG != "" ? remarksFUNENG : undefined,"french":remarksFUNFR != "" ? remarksFUNFR : undefined},"SKY":{"english":remarksSKYENG != "" ? remarksSKYENG : undefined,"french":remarksSKYFR != "" ? remarksSKYFR : undefined },"TC":{"english":remarksTCENG != "" ? remarksTCENG : undefined,"french":remarksTCFR != "" ? remarksTCFR : undefined},"VIT":{"english":remarksVITENG != "" ? remarksVITENG : undefined,"french":remarksVITFR != "" ? remarksVITFR : undefined }} : undefined,
        }

        let prixData ={
            "validFrom" : validFrom ? validFrom : undefined,
            "validTo" : validTo ? validTo : undefined,
            "prixLastReturnDate" : prixLastReturnDate ? prixLastReturnDate : undefined,
            "prixLastTicketingDate" : prixLastTicketingDate ? prixLastTicketingDate : undefined,
            "prixNote" : prixNote != "" ? prixNote : undefined,
            "adultCommissionOnYQ" : checkForEmptyAndConvertToNumber(adultCommissionOnYQ), 
            "childCommissionOnYQ" : checkForEmptyAndConvertToNumber(childCommissionOnYQ) ,
            "enfantCommissionOnYQ" : checkForEmptyAndConvertToNumber(enfantCommissionOnYQ) ,
            "markup" : markup != "" ? markup : undefined,
            "childMarkUp" : childMarkup != "" ? childMarkup : undefined,
            "infantMarkUp" : infantMarkUp != "" ? infantMarkUp : undefined,
            "adultPassBackCommissionOnYQ" : checkForEmptyAndConvertToNumber(adultPassBackCommissionOnYQ) ,
            "childPassBackCommissionOnYQ" : checkForEmptyAndConvertToNumber(childPassBackCommissionOnYQ) ,
            "enfantPassBackCommissionOnYQ" : checkForEmptyAndConvertToNumber(enfantPassBackCommissionOnYQ) ,
            "adultCommissionOnYQNav" : checkForEmptyAndConvertToNumber(adultCommissionOnYQNav) ,
            "childCommissionOnYQNav" : checkForEmptyAndConvertToNumber(childCommissionOnYQNav) ,
            "enfantCommissionOnYQNav" : checkForEmptyAndConvertToNumber(enfantCommissionOnYQNav) ,
            "adultPassBackCommissionOnYQNav" : checkForEmptyAndConvertToNumber(adultPassBackCommissionOnYQNav) ,
            "childPassBackCommissionOnYQNav" : checkForEmptyAndConvertToNumber(childPassBackCommissionOnYQNav) ,
            "enfantPassBackCommissionOnYQNav" : checkForEmptyAndConvertToNumber(enfantPassBackCommissionOnYQNav) ,
            "blackOutDates" : emptyArrayOfObjects(blackoutDatesRepetative) ? undefined : blackoutDatesRepetative,
            "blackOutDays" : emptyArrayOfObjects(blackoutDaysRepetative) ? undefined : blackoutDaysRepetative,
            "blackOutCarrier" : emptyArrayOfObjects(blackoutAirlinesRepetative) ? undefined : blackoutAirlinesRepetative,
            "endorsement" : (endorsementTC || endorsementFUN || endorsementDFW || endorsementSKY || endorsementVIT) ? {"TC": endorsementTC != "" ? endorsementTC : undefined,"DFW": endorsementDFW != "" ? endorsementDFW : undefined,"FUN": endorsementFUN != "" ? endorsementFUN : undefined,"SKY": endorsementSKY != "" ? endorsementSKY : undefined,"VIT": endorsementVIT != "" ? endorsementVIT : undefined} : undefined,
            "tourCode" : emptyArrayOfObjects(tourCodeRepetative) ? undefined : tourCodeRepetative
        }
        const payload = {
            "tarifKeys": (checkBoxObject?.tarifs?.length && !emptyObject(tarifData)) ? checkBoxObject?.tarifs : undefined,
            "tarifData" : (checkBoxObject?.tarifs?.length && !emptyObject(tarifData)) ? tarifData : undefined,
            "classKeys": (checkBoxObject?.classes?.length && !emptyObject(classData)) ? checkBoxObject?.classes : undefined,
            "classData" : (checkBoxObject?.classes?.length && !emptyObject(classData)) ? classData: undefined,
            "prixKeys" : (checkBoxObject?.prixs?.length && !emptyObject(prixData)) ? checkBoxObject?.prixs : undefined,
            "prixData" : (checkBoxObject?.prixs?.length && !emptyObject(prixData)) ? prixData : undefined
        }

        const bulkUpdateResponse = await putBulkUpdate(payload)
        if(bulkUpdateResponse?.data?.message != "Bulk update was successful"){
            swal({
              text:bulkUpdateResponse?.data?.message,
              icon: "error",
              button: "Close",
            })
        }else{
            swal({
                text:bulkUpdateResponse?.data?.message,
                icon: "success",
                button: "Close",
              })
              dispatch(setSearchAllV2([]))
              history.push(`/Newcontractlist`)
        }
    }

    const emptyObject = (data) => {
        let allFildsAreEmpty = true;
        Object.keys(data).map((key) => {
            if (data[key] != undefined) {
                allFildsAreEmpty = false;
            }
        })
        return allFildsAreEmpty
    }

    const emptyArrayOfObjects = (data) => {
        let allFildsAreEmpty = true;
        for(let index = 0; index < data.length; index++){
            Object.keys(data[index]).map((key) => {
                if (data[index][key]) {
                    allFildsAreEmpty = false;
                }
            })
        }
        return allFildsAreEmpty
    }

    const originDestinationValidtion = (length) => {
        if(length > 3){
            swal({
                text:"Only three characters accepted",
                icon: "error",
                button: "Close",
              })
            return false
        }
        return true
    }

    const specialCharacterCheck = (data) => {
        return /[-’/`~!#*$@_%+=.^&(){}[\]|;:”<>?\\]/g.test(data)
    }

    const numericalCharacterCheck = (data) => {
        return /[0-9]/g.test(data)
    }

    const checkECharacter = (value) => {
       return !(String(value).toUpperCase()).includes('E')
    }

    const checkForEmptyAndConvertToNumber = (data) =>{
        if(data) return Number(data)
    }

    return (
        <>
             <div className="cm-head-2 mb-8">
                    <div className="d-flex justify-content-between">
                        <span className="cm__Title">Bulk Edit</span>
                        <span></span>
                    </div>
                </div>
            <div className="card cm-card">
                <div className="card-body">
                    {/* >>>>>>>>>>>>>>>>>>>>> tariff Search >>>>>>>>>>>>>>>>>>>>>. */}
                    <div className="cm-head-2">
                        <div className="d-flex justify-content-between">
                            <span className="">Tariff </span>
                            <div className="cm-head-checkbox d-flex">
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* ORIGIN AND DESTINATION SEARCH SECTION */}
                        <div className="row mt-4">
                            <div className="col-md-6 cm-col">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="cm-head-label">Origin</label>
                                        <input
                                            className="form-control cm-input"
                                            placeholder="Enter Origin"
                                            value={origins}
                                            onChange={(e) => {!specialCharacterCheck(String(e?.target?.value).toUpperCase()) && originDestinationValidtion(e?.target?.value.length) && setOrigins(String(e?.target?.value).toUpperCase())}}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="cm-head-label">Destination</label>
                                        <input
                                            className="form-control cm-input"
                                            placeholder="Enter Destination"
                                            value={destinations}
                                            onChange={(e) => {!specialCharacterCheck(String(e?.target?.value).toUpperCase()) &&  originDestinationValidtion(e?.target?.value.length) && setDestinations(String(e?.target?.value).toUpperCase())}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 cm-col">

                                <div className="row">
                                    <div className="col-md-6 ">

                                        <label className="cm-head-label">Marketing Airlines</label>
                                        <input
                                            className="form-control cm-input"
                                            placeholder="Enter Marketing Airlines"
                                            value={marketingAirline}
                                            onChange={(e) => !specialCharacterCheck(String(e?.target?.value).toUpperCase()) && setMarketingAirline(String(e?.target?.value).toUpperCase())}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="cm-head-label">Operating Airlines</label>
                                        <input
                                            className="form-control cm-input"
                                            placeholder="Enter Operating Airlines"
                                            value={mappedOpeartingAirlines}
                                            onChange={(e) => !specialCharacterCheck(String(e?.target?.value).toUpperCase()) && setMappedOpeartingAirlines(String(e?.target?.value).toUpperCase())}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Max. Authorised Amount </label>
                                <div className="row">
                                    <div className="col-md-4 cm-col">
                                        <div className="cm-radio-inline ml-0">
                                            <FormControlLabel control={<Radio color="primary" checked={maximumFeeCCBycarrierType == 'Amount'} onChange={()=>setMaximumFeeCCBycarrierType('Amount')}/>} label="($)"
                                            />
                                            <FormControlLabel control={<Radio color="primary" checked={maximumFeeCCBycarrierType == 'Percentage'} onChange={()=>setMaximumFeeCCBycarrierType('Percentage')}/>} label="(%)"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-8 cm-col">
                                        <div className="cm-radio-inline">
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Amount"
                                                type='number'
                                                value={maximumFeeCCAmoutByCarrier}
                                                onChange={(e) => checkECharacter(e?.target?.value) && setMaximumFeeCCAmoutByCarrier(e?.target?.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <div className="col-md-9 cm-col">
                                    <label className="cm-head-label">Tariff Note</label>
                                    <input
                                        className="form-control cm-input"
                                        placeholder="Enter Tariff Note"
                                        value={note}
                                        onChange={(e) => setNote(e?.target?.value)}
                                    />
                                </div>

                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Credit Card Processing Fees</label>
                                <div className="row">
                                    <div className="col-md-9 cm-col">
                                        <input
                                            className="form-control cm-input"
                                            placeholder="Enter Fees"
                                            type='number'
                                            value={ccFeesForTBMerchant}
                                            onChange={(e) => checkECharacter(e?.target?.value) && setCcFeesForTBMerchant(e?.target?.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Contract Type</label>
                                <div className="cm-radio-inline">
                                    <FormControlLabel className='mr-2' control={<Radio color="primary" checked={Boolean(landFare)} onChange={(e) => {if(e.target.checked){setLandFare(1);setCruiseFare(0)}}}/>} label="Land" />
                                    <FormControlLabel  className='mr-2'  control={<Radio color="primary" checked={Boolean(cruiseFare)} onChange={(e) => {if(e.target.checked){setCruiseFare(1);setLandFare(0)}}}/>} label="Cruise" />
                                    <FormControlLabel className='mr-0' control={<Radio color="primary" checked={landFare != null && cruiseFare != null && !Boolean(landFare) && !Boolean(cruiseFare)} onChange={(e)=>{if(e.target.checked){setLandFare(0);setCruiseFare(0);}}} />} label="Air Only" />
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <CheckBoxTypeSelector checkBoxTypes={[
                                'Business',
                                'Economy',
                                'Premium Business',
                                'Premium Economy',
                                'First Class',
                            ]} mainLabel={"Cabin Type"} setMethod={setCabinType} useStateVariable={cabinType}/>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Trip Type</label>
                                <div className="cm-radio-inline">
                                    {/* <RadioGroup name={`radioGroupBulkUpdate-${Math.random()}`}> */}
                                    <FormControlLabel control={<Radio color="primary" checked={tripType === "OW" ? true : false} onChange={(e) => setTripType("OW")} />} label="One Way"
                                    />
                                    <FormControlLabel control={<Radio color="primary" checked={tripType === "-OW" ? true : false} onChange={(e) => setTripType("-OW")} />} label="Not One Way"
                                    />{" "}
                                </div>
                                <label className="cm-head-label mt-3">Branded Fare</label>
                                <div className="cm-radio-inline">
                                    <FormControlLabel control={<Radio color="primary" checked={isBrandedFare == "1" ? true : false} onChange={(e) => {if(e?.target?.checked){ setIsBrandedFare("1")}}} />} label="Yes"
                                    />
                                    <FormControlLabel control={<Radio color="primary" checked={isBrandedFare == "0" ? true : false} onChange={(e) => {if(e?.target?.checked){ setIsBrandedFare("0")}}} />} label="No"
                                    />{" "}
                                </div>
                            </div>
                                <CheckBoxTypeSelector checkBoxTypes={[
                                'Sky Link',
                                'Intair Transit',
                                'Fun Sun',
                                'DFW',
                                'Sunquest',
                                'Network'
                            ]} mainLabel={"Distribution"} useStateVariable={distribution} setMethod={setDistribution}/>
                            <CheckBoxTypeSelector checkBoxTypes={[
                                'Cheque',
                                'Credit Card',
                                'Credit Internal'
                            ]} mainLabel={"FOP Characteristics"} setMethod={setFormsOfPayment} useStateVariable={formsOfPayment}/>
                        </div>
                    </div>

                    <div className="row headerCollap">
                        <div className="col-6">
                            <p className="headerTitle">Class</p>
                        </div>
                        <div className="col-6 text-right" >
                            <i className="fas fa-caret-up" aria-hidden="true"></i>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Brand Code </label>
                                <input
                                    className="form-control cm-input"
                                    placeholder="Enter Brand Id"
                                    value={brandCode}
                                    onChange={(e)=>{!specialCharacterCheck(String(e?.target?.value).toUpperCase()) && setBrandCode(String(e.target.value).toUpperCase())}}
                                />
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label"> Class of Service </label>
                                <input
                                    className="form-control cm-input"
                                    placeholder="Enter Classe"
                                    value={classCodes}
                                    onChange={(e)=>{!specialCharacterCheck(String(e?.target?.value).toUpperCase()) && setClassCodes(String(e.target.value).toUpperCase())}}
                                />
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Category</label>
                                <input
                                    className="form-control cm-input"
                                    placeholder="Enter Category"
                                    type="text"
                                    value={category}
                                    onChange={(e)=>{!specialCharacterCheck(String(e?.target?.value).toUpperCase()) && setCategory(String(e.target.value).toUpperCase())}}
                                />
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Fare Type</label>
                                <div className="cm-radio-inline">
                                    <FormControlLabel control={<Radio color="primary"  checked={contractType === "Published" ? true : false} onChange={(e) => setContractType("Published")}  />} label="(%) Published"
                                    />
                                    <FormControlLabel control={<Radio color="primary"  checked={contractType === "Net" ? true : false} onChange={(e) => setContractType("Net")}  />} label="($) Net"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Ticketing Fee</label>
                                <input
                                type='number'
                                    className="form-control cm-input"
                                    placeholder="Enter Ticketing Fee"
                                    value={ticketingFee}
                                    onChange={(e)=>{checkECharacter(e?.target?.value) && setTicketingFee(e.target.value)}}
                                />
                            </div>

                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Class Note</label>
                                <input
                                    className="form-control cm-input"
                                    placeholder="Enter note"
                                    type="text"
                                    value={classNote}
                                    onChange={(e)=>{setClassNote(String(e.target.value))}}
                                />
                            </div>
                            <div className="col-md-3 cm-col">
                                <div className="row">
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Min/Max Stay </label>
                                        <input
                                            className="form-control cm-input"
                                            placeholder="Enter Min "
                                            value={minmaxStay}
                                            onChange={(e) => { setMinmaxStay(String(e.target.value)) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Class EDR Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' value={classLastTicketingDate} onChange={(date) => { setClassLastTicketingDate(moment.utc(date).format("YYYY-MM-DD")) }} format="yyyy-MM-dd"/>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className="cm-head-2">
                            <div className="d-flex justify-content-between">
                                <span className="">Rules</span>
                                <div className="cm-head-checkbox d-flex">
                                </div>
                            </div>

                        </div>
                            <label className="cm-head-label mt-2">AGE</label>
                        <div className="row ">

                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Infant Below</label>
                                <input
                                    className="form-control cm-input"
                                    placeholder="Enter Age"
                                    value={ageRestrictionsInfantAge}
                                    type='number'
                                    onChange={(e)=> checkECharacter(e?.target?.value) && setAgeRestrictionsInfantAge(e.target.value)}
                                />
                            </div>

                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Applicable On</label>
                                <FormControlLabel control={<Radio color="primary" checked={ageRestrictionsInfantAgeApplicableOn === "DEPARTURE" ? true : false} onChange={(e) => setAgeRestrictionsInfantAgeApplicableOn("DEPARTURE")} />} label="Departure"
                                />
                                <FormControlLabel control={<Radio color="primary" checked={ageRestrictionsInfantAgeApplicableOn === "ARRIVAL" ? true : false} onChange={(e) => setAgeRestrictionsInfantAgeApplicableOn("ARRIVAL")} />} label="Return"
                                />{" "}
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Child Below </label>
                                <input
                                    type='number'
                                    className="form-control cm-input"
                                    placeholder="Enter Age"
                                    value={ageRestrictionsChildAge}
                                    onChange={(e)=> checkECharacter(e?.target?.value) && setAgeRestrictionsChildAge(e.target.value)}
                                />

                            </div>
                            <div className="col-md-3 cm-col">

                                <div className="cm-col">
                                    <label className="cm-head-label">Junior between</label>
                                    <div className="d-flex ">
                                        <div className='classCount mr-2'>
                                            <button className='classIncress' onClick={()=>{if(juniorAgeFrom > 0){setJuniorAgeFrom(juniorAgeFrom-1)}}}>-</button>
                                            <input className="classCountinput" type="text" value={juniorAgeFrom} disabled/>
                                            <button className='classDecress' onClick={()=>{if(juniorAgeFrom < 100){setJuniorAgeFrom(juniorAgeFrom+1)}}}>+</button>
                                        </div>
                                        <div className='classCount'>
                                            <button className='classIncress' onClick={()=>{if(juniorAgeTo > 0){setJuniorAgeTo(juniorAgeTo-1)}}}>-</button>
                                            <input className="classCountinput" type="text" value={juniorAgeTo} disabled/>
                                            <button className='classDecress'onClick={()=>{if(juniorAgeTo < 100){setJuniorAgeTo(juniorAgeTo+1)}}}>+</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">OSI </label>
                                <input className="form-control cm-input" placeholder="Enter OSI" value={osi} onChange={(e)=>!specialCharacterCheck(String(e?.target?.value)) && setOsi(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Branch files</label>
                                <FormControlLabel control={<Checkbox color="primary" value={permitBranchPNR} onChange={()=> setpermitBranchPNR(!permitBranchPNR)} />} label="Branch files not permitted" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <label className="cm-head-label">Notes for age</label>
                                <label className="cm-head-label">  Infant etkt not allowed with some codeshared flights or interligne carriers.Fees and adv purchase apply for paperticket. Bebe Etkt non permis avec certains vols partages ou interlignes. Frais et delais d achat pour demander un billet papier.</label>

                            </div>

                        </div>
                        <div className="cm-head-2">
                            <div className="d-flex justify-content-between">
                                <span className="">Ticket By / Changes</span>
                                <div className="cm-head-checkbox d-flex">
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-3"> OPTION DATE</div>
                            <div className="col-md-3">
                                <label className="cm-head-label">Departure Window </label>
                                <input className="form-control cm-input" type='number' placeholder="Enter Departure Window" value={optionRulesDepartureWindow} onChange={(e)=>checkECharacter(e?.target?.value) && setOptionRulesDepartureWindow(e.target.value)}/>
                            </div>
                            <div className="col-md-3">
                                <label className="cm-head-label">Days After Reservation </label>
                                <input className="form-control cm-input" type='number' placeholder="Enter Days After Reservation" value={optionRulesDaysAfterReservation} onChange={(e)=> checkECharacter(e?.target?.value) && setOptionRulesDaysAfterReservation(e.target.value)}/>
                            </div>
                            <div className="col-md-3">
                                <label className="cm-head-label">Days Before Departure </label>
                                <input className="form-control cm-input" type='number' placeholder="Days Before Departure" value={optionRulesDaysBeforeDeparture} onChange={(e)=> checkECharacter(e?.target?.value) && setOptionRulesDaysBeforeDeparture(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-3"> TICKET BY DATE</div>
                            <div className="col-md-3">
                                <label className="cm-head-label">Departure Window </label>
                                <input className="form-control cm-input" type='number' placeholder="Enter Departure Window" value={ticketingRulesDepartureWindow} onChange={(e)=> checkECharacter(e?.target?.value) && setTicketingRulesDepartureWindow(e.target.value)}/>
                            </div>
                            <div className="col-md-3">
                                <label className="cm-head-label">Days After Reservation </label>
                                <input className="form-control cm-input" type='number' placeholder="Enter Days After Reservation" value={ticketingRulesDaysAfterReservation} onChange={(e)=> checkECharacter(e?.target?.value) && setTicketingRulesDaysAfterReservation(e.target.value)}/>
                            </div>
                            <div className="col-md-3">
                                <label className="cm-head-label">Days Before Departure </label>
                                <input className="form-control cm-input" type='number' placeholder="Days Before Departure" value={ticketingRulesDaysBeforeDeparture} onChange={(e)=> checkECharacter(e?.target?.value) && setTicketingRulesDaysBeforeDeparture(e.target.value)}/>
                            </div>
                        </div>

                        <div className="cm-head-2">
                            <div className="d-flex justify-content-between">
                                <span className="">Routings</span>
                                <div className="cm-head-checkbox d-flex">
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Routing Info Source </label>
                                <input className="form-control cm-input" placeholder="Enter Routing info source" value={classRoutings} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setClassRoutings(e.target.value)}/>
                            </div>
                        </div>

                        <div className="cm-head-2">
                            <div className="d-flex justify-content-between">
                                <span className="">Price At Sabre</span>
                                <div className="cm-head-checkbox d-flex">
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Disable auto-ticketing</label>
                                <FormControlLabel control={<Radio color="primary" checked={disableAutoTicketing == true ? true : false } onChange={()=>setDisableAutoTicketing(true)}/>} label="True" />
                                <FormControlLabel control={<Radio color="primary" checked={disableAutoTicketing == false ? true : false} onChange={()=>setDisableAutoTicketing(false)}/>} label="False" />
                            </div>

                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Disable Price Unit Concept</label>
                                <FormControlLabel control={<Radio color="primary" checked={disablePriceUnitConcept == true ? true : false} onChange={()=>setDisablePriceUnitConcept(true)}/>} label="True" />
                                <FormControlLabel control={<Radio color="primary" checked={disablePriceUnitConcept == false ? true : false} onChange={()=>setDisablePriceUnitConcept(false)}/>} label="False" />
                            </div>

                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label"> </label>
                                <FormControlLabel control={<Checkbox color="primary" checked={issueAsBulk} onChange={()=>setIssueAsBulk(!issueAsBulk)}/>} label="Issue ticket as bulk only" />
                            </div>

                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Pax Type</label>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name={`SearchFareByradioGroup-${Math.random()}`}
                                    value={fareType}
                                    onChange={({ target }) => setFareType(target.value)}
                                >
                                    <FormControlLabel control={<Radio color="primary" value={'ADT public'}/>} label="ADT Public" />
                                    <FormControlLabel control={<Radio color="primary" value={'ADT prive'}/>} label="ADT Private" />
                                    <FormControlLabel control={<Radio color="primary" value={'JCB'}/>} label="JCB" />
                                    <FormControlLabel control={<Radio color="primary" value={'PFA'}/>} label="PFA" />
                                    <FormControlLabel control={<Radio color="primary" value={'ITX'}/>} label="ITX" />
                                </RadioGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12 cm-col">
                                <label className="cm-head-label">ACCOUNT CODE</label>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">VIT</label>
                                <input className="form-control cm-input" placeholder="" value={accountCodeVIT} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setAccountCodeVIT(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">SKY</label>
                                <input className="form-control cm-input" placeholder="" value={accountCodeSKY} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setAccountCodeSKY(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">DFW</label>
                                <input className="form-control cm-input" placeholder="" value={accountCodeDFW} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setaccountCodeDFW(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">FUN</label>
                                <input className="form-control cm-input" placeholder="" value={accountCodeFUN} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setaccountCodeFUN(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">TC</label>
                                <input className="form-control cm-input" placeholder="" value={accountCodeTC} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setaccountCodeTC(e.target.value)}/>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-md-12 cm-col">
                                <label className="cm-head-label">TICKET DESIGNATOR</label>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">VIT</label>
                                <input className="form-control cm-input" placeholder="" value={ticketDesignatorVIT} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setTicketDesignatorVIT(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">SKY</label>
                                <input className="form-control cm-input" placeholder="" value={ticketDesignatorSKY} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setTicketDesignatorSKY(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">DFW</label>
                                <input className="form-control cm-input" placeholder="" value={ticketDesignatorDFW} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setTicketDesignatorDFW(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">FUN</label>
                                <input className="form-control cm-input" placeholder="" value={ticketDesignatorFUN} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setTicketDesignatorFUN(e.target.value)}/>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">TC</label>
                                <input className="form-control cm-input" placeholder="" value={ticketDesignatorTC} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setTicketDesignatorTC(e.target.value)}/>
                            </div>
                        </div>

                        <div className="cm-head-2">
                            <div className="d-flex justify-content-between">
                                <span className="">Remarks</span>
                                <div className="cm-head-checkbox d-flex">
                                </div>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-md-6 cm-col">
                                <label className="cm-head-label">INTAIR</label>
                                <div className='row mt-4'>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">French</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksVITFR} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksVITFR(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">English</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksVITENG} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksVITENG(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 cm-col">
                                <label className="cm-head-label">SKYLINK</label>
                                <div className='row mt-4'>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">French</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksSKYFR} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksSKYFR(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">English</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksSKYENG} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksSKYENG(e.target.value)}/>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='row'>
                            <div className="col-md-6 cm-col">
                                <label className="cm-head-label">FUN SUN</label>
                                <div className='row mt-4'>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">French</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksFUNFR} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksFUNFR(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">English</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksFUNENG} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksFUNENG(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 cm-col">
                                <label className="cm-head-label">DFW</label>
                                <div className='row mt-4'>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">French</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksDFWFR} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksDFWFR(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">English</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksDFWENG} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksDFWENG(e.target.value)}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row '>
                            <div className="col-md-6 cm-col">
                                <label className="cm-head-label">TC</label>
                                <div className='row mt-4'>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">French</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksTCFR} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksTCFR(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">English</label>
                                        <input className="form-control cm-input" placeholder="" value={remarksTCENG} onChange={(e)=> !specialCharacterCheck(String(e?.target?.value)) && setRemarksTCENG(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row headerCollap">
                        <div className="col-12">
                            <p className="headerTitle">Prix</p>
                        </div>
                    </div>

                    <div>
                        <div className="row align-items-end">
                            <div className="col-md-6 cm-col">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="cm-head-label">Valid From</label>
                                        {/* <input type="date" className="form-control cm-input-date" /> */}
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker disablePast className='BulkUpdate__DatePicker' value={validFrom} onChange={(date) => { setValidFrom(moment .utc(date).format("YYYY-MM-DD")) }} />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="cm-head-label">Valid To</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker className='BulkUpdate__DatePicker' minDate={validFrom} value={validTo} onChange={(date) => { setValidTo(moment .utc(date).format("YYYY-MM-DD")) }} />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Last Return Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' value={prixLastReturnDate} onChange={(date) => { setPrixLastReturnDate(moment .utc(date).format("YYYY-MM-DD"))}} />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Last Ticketing Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' value={prixLastTicketingDate} onChange={(date) => { setPrixLastTicketingDate(moment .utc(date).format("YYYY-MM-DD"))}} />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Prix Note</label>
                                <input className="form-control cm-input" placeholder="Enter Prix note" value={prixNote} onChange={({target})=>setPrixNote(target.value)}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 cm-col">
                                <label className="cm-head-label">Prix Note Price</label>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">OUR COMMISSION </label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Adult</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={adultCommissionOnYQ} onChange={({target})=> checkECharacter(target?.value) && setAdultCommissionOnYQ(target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Child</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={childCommissionOnYQ} onChange={({target})=> checkECharacter(target?.value) && setChildCommissionOnYQ(target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">OUR MARKUP</label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Adult</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={markup} onChange={({target})=> checkECharacter(target?.value) && setmarkup(target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Child</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={childMarkup} onChange={({target})=> checkECharacter(target?.value) && setChildMarkUp(target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">AGENCY COMMISSION</label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Adult</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={adultPassBackCommissionOnYQ} onChange={({target})=> checkECharacter(target?.value) && setadultPassBackCommissionOnYQ(target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Child</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={childPassBackCommissionOnYQ} onChange={({target})=> checkECharacter(target?.value) && setChildPassBackCommissionOnYQ(target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label"> MIN SELL</label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Adult</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={adultMinSelling} onChange={(e)=> checkECharacter(e?.target?.value) && setAdultMinSelling(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Child</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={childMinSelling} onChange={(e)=> checkECharacter(e?.target?.value) && setChildMinSelling(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Infant</label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">

                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={enfantCommissionOnYQ} onChange={({target})=> checkECharacter(target?.value) && setEnfantCommissionOnYQ(target.value)}/>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Infant</label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">

                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={infantMarkUp} onChange={({target})=> checkECharacter(target?.value) && setInfantMarkUp(target.value)}/>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Infant</label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">

                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={enfantPassBackCommissionOnYQ} onChange={({target})=> checkECharacter(target?.value) && setEnfantPassBackCommissionOnYQ(target.value)}/>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Infant</label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={infantMinSelling} onChange={(e)=> checkECharacter(e?.target?.value) && setInfantMinSelling(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 cm-col">
                                <label className="cm-head-label">Navcan (Surcharges)</label>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">OUR COMMISSION </label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Adult</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={adultCommissionOnYQNav} onChange={({target})=> checkECharacter(target?.value) && setAdultCommissionOnYQNav(target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Child</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={childCommissionOnYQNav} onChange={({target})=> checkECharacter(target?.value) && setChildCommissionOnYQNav(target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label"> </label>
                                <div className="row  mt-2">
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Infant</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={enfantCommissionOnYQNav} onChange={({target})=> checkECharacter(target?.value) && setEnfantCommissionOnYQNav(target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">AGENCY COMMISSION</label>
                                <div className="row">
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Adult</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={adultPassBackCommissionOnYQNav} onChange={({target})=> checkECharacter(target?.value) && setAdultPassBackCommissionOnYQNav(target.value)}/>
                                    </div>
                                    <div className="col-md-6 cm-col">
                                        <label className="cm-head-label">Child</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={childPassBackCommissionOnYQNav} onChange={({target})=> checkECharacter(target?.value) && setChildPassBackCommissionOnYQNav(target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">  </label>
                                <div className="row mt-2">
                                    <div className="col-md-6 cm-col ">
                                        <label className="cm-head-label">Infant</label>
                                        <input className="form-control cm-input" placeholder="Enter %" type='number' value={enfantPassBackCommissionOnYQNav} onChange={({target})=> checkECharacter(target?.value) && setEnfantPassBackCommissionOnYQNav(target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cm-head-2">
                            <div className="d-flex justify-content-between">
                                <span className="">Exceptions </span>
                                <div className="cm-head-checkbox d-flex">
                                </div>

                            </div>
                        </div>
                        {blackoutDatesRepetative.map((blackout,index)=>{return(<div className="row">
                            <div className="col-md-12 cm-col">
                                <label className="cm-head-label">Blackout Dates</label>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Start Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' disablePast value={blackout.fromDate?.length ? blackout.fromDate : null} onChange={(date)=>onChangeForMultiple(moment .utc(date).format("YYYY-MM-DD"), index,'fromDate',blackoutDatesRepetative, setBlackoutDatesRepetative)} />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">End Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' minDate={blackout.fromDate} value={blackout.toDate?.length ? blackout.toDate : null} onChange={(date)=>onChangeForMultiple(moment .utc(date).format("YYYY-MM-DD"), index,'toDate', blackoutDatesRepetative, setBlackoutDatesRepetative)} />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label"></label>
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackout.aller)} onChange={(e)=>onChangeForMultiple(Number(e.target.checked),index,'aller', blackoutDatesRepetative, setBlackoutDatesRepetative)}/>} label="Departure" />
                                    </div>
                                    <div className="col-md-6 ">
                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackout.retour)} onChange={(e)=>onChangeForMultiple(Number(e.target.checked),index,'retour', blackoutDatesRepetative, setBlackoutDatesRepetative)}/>} label="Return" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 cm-col">
                                <label className="cm-head-label">Exception Type</label>
                                <div className="row">
                                    <div className="col-md-8 ">
                                        <Select
                                            className='BulkUpdate__DatePicker'
                                            value={blackout.exceptionType}
                                            onChange={(e)=>onChangeForMultiple(e.target.value,index,'exceptionType', blackoutDatesRepetative, setBlackoutDatesRepetative)}
                                        >
                                            <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                            <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                            <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="col-md-4 pl-0">
                               
                                        <div className='setD-flex'>
                                            <Button className="BulkUpdate__removeBtn " onClick={()=>{if(blackoutDatesRepetative.length > 1){let temp = [...blackoutDatesRepetative]; temp.splice(index, 1);setBlackoutDatesRepetative([...temp]);}}}><i className="fas fa-trash-alt"></i></Button>
                                            <Button className='BulkUpdate__AddBtn px-0' onClick={()=>{let temp = [...blackoutDatesRepetative]; temp.push({...blackoutDate});setBlackoutDatesRepetative([...temp]);}}><i className="fas fa-plus-circle"></i></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)})}
                    </div>


                </div>
            </div >

            {blackoutDaysRepetative.map((day, index)=>{return(<div className="card cm-card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 cm-col">
                            <label className="cm-head-label">Blackout Days</label>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Days</label>
                            <input className="form-control cm-input" placeholder="" value={day.day} onChange={(e)=> !numericalCharacterCheck(String(e?.target?.value)) && !specialCharacterCheck(String(e?.target?.value)) && onChangeForMultiple(String(e.target.value).toUpperCase(), index,'day', blackoutDaysRepetative, setBlackoutDaysRepetative)}/>
                        </div>

                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label"></label>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <FormControlLabel control={<Checkbox color="primary" checked={Boolean(day?.aller)} onChange={(e)=>onChangeForMultiple(Number(e.target.checked),index,'aller', blackoutDaysRepetative, setBlackoutDaysRepetative)}/>} label="Departure" />
                                </div>
                                <div className="col-md-6 ">
                                    <FormControlLabel control={<Checkbox color="primary" checked={Boolean(day?.retour)} onChange={(e)=>onChangeForMultiple(Number(e.target.checked),index,'retour', blackoutDaysRepetative, setBlackoutDaysRepetative)}/>} label="Return" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Exception Type</label>
                            <div className="row">
                                <div className="col-md-8 ">
                                    <Select className='BulkUpdate__DatePicker'
                                     value={day.exceptionType}
                                     onChange={(e)=>onChangeForMultiple(e.target.value,index,'exceptionType', blackoutDaysRepetative, setBlackoutDaysRepetative)}
                                     >
                                        <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                        <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                        <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                    </Select>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3 cm-col">
                            <div className="row">
                                <div className="col-md-8 ">
                                </div>
                                <div className="col-md-4 pl-0">

                                    <div className='setD-flex mt-5'>
                                    <Button className="BulkUpdate__removeBtn " onClick={()=>{if(blackoutDaysRepetative.length > 1){let temp = [...blackoutDaysRepetative]; temp.splice(index, 1);setBlackoutDaysRepetative([...temp]);}}}><i className="fas fa-trash-alt"></i></Button>
                                    <Button className='BulkUpdate__AddBtn px-0' onClick={() => { let temp = [...blackoutDaysRepetative]; temp.push({ ...blackoutDay }); setBlackoutDaysRepetative([...temp]); }}><i className="fas fa-plus-circle"></i></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)})}

            {blackoutAirlinesRepetative.map((airline, index)=>{return(<div className="card cm-card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 cm-col">
                            <label className="cm-head-label">Blackout Airlines</label>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Carrier Code</label>
                            <input className="form-control cm-input" placeholder="" value={airline.carrierCode} onChange={(e)=>onChangeForMultiple(String(e.target.value).toUpperCase(),index,'carrierCode', blackoutAirlinesRepetative, setBlackoutAirlinesRepetative)}/>
                        </div>
                        <div className="col-md-3 cm-col">
                            <div className="row">
                                <div className="col-md-6 ">
                                    <label className="cm-head-label">From</label>
                                    <input className="form-control cm-input" placeholder=" " value={airline.flightNumberFrom} onChange={(e)=>onChangeForMultiple(String(e.target.value),index,'flightNumberFrom', blackoutAirlinesRepetative, setBlackoutAirlinesRepetative)}/>
                                </div>
                                <div className="col-md-6 ">
                                    <label className="cm-head-label">to</label>
                                    <input className="form-control cm-input" placeholder=" " value={airline.flightNumberTo} onChange={(e)=>onChangeForMultiple(String(e.target.value),index,'flightNumberTo', blackoutAirlinesRepetative, setBlackoutAirlinesRepetative)}/>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label"></label>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <FormControlLabel control={<Checkbox color="primary" checked={Boolean(airline.aller)} onChange={(e)=>onChangeForMultiple(Number(e.target.checked),index,'aller', blackoutAirlinesRepetative, setBlackoutAirlinesRepetative)}/>} label="Departure" />
                                </div>
                                <div className="col-md-6 ">
                                    <FormControlLabel control={<Checkbox color="primary" checked={Boolean(airline.retour)} onChange={(e)=>onChangeForMultiple(Number(e.target.checked),index,'retour', blackoutAirlinesRepetative, setBlackoutAirlinesRepetative)}/>} label="Return" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 cm-col">
                            <div className="row">
                                <div className="col-md-8 ">
                                    <label className="cm-head-label">Exception Type</label>
                                    <Select className='BulkUpdate__DatePicker'
                                    value={airline.exceptionType}
                                    onChange={(e)=>onChangeForMultiple(e.target.value,index,'exceptionType', blackoutAirlinesRepetative, setBlackoutAirlinesRepetative)}
                                    >
                                        <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                        <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                        <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                    </Select>
                                </div>
                                <div className="col-md-4 pl-0 mt-5">
                                    <div className='setD-flex'>
                                    <Button className="BulkUpdate__removeBtn " onClick={()=>{if(blackoutAirlinesRepetative.length > 1){let temp = [...blackoutAirlinesRepetative]; temp.splice(index, 1);setBlackoutAirlinesRepetative([...temp]);}}}><i className="fas fa-trash-alt"></i></Button>
                                    <Button className='BulkUpdate__AddBtn px-0' onClick={() => { let temp = [...blackoutAirlinesRepetative]; temp.push({ ...blackoutAirline }); setBlackoutAirlinesRepetative([...temp]); }}><i className="fas fa-plus-circle"></i></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)})}

            <div className="card cm-card">
                <div className="card-body">
                    <div className="cm-head-2">
                        <div className="d-flex justify-content-between">
                            <span className="">Emission </span>
                            <div className="cm-head-checkbox d-flex">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 cm-col">
                            <label className="cm-head-label">Endorsement</label>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">VIT</label>
                            <input className="form-control cm-input" placeholder="" value={endorsementVIT} onChange={(e)=>setEndorsementVIT(e.target.value)}/>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">SKY</label>
                            <input className="form-control cm-input" placeholder="" value={endorsementSKY} onChange={(e)=>setEndorsementSKY(e.target.value)}/>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">DFW</label>
                            <input className="form-control cm-input" placeholder="" value={endorsementDFW} onChange={(e)=>setEndorsementDFW(e.target.value)}/>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">FUN</label>
                            <input className="form-control cm-input" placeholder="" value={endorsementFUN} onChange={(e)=>setEndorsementFUN(e.target.value)}/>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">TC</label>
                            <input className="form-control cm-input" placeholder="" value={endorsementTC} onChange={(e)=>setEndorsementTC(e.target.value)}/>
                        </div>
                    </div>

                </div>
            </div>

            {tourCodeRepetative.map((tourcodeVal, index)=>{return(<div className="card cm-card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 cm-col">
                            <label className="cm-head-label">Tour Code</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Office</label>
                            <div className="row">
                                <div className="col-md-6 cm-col">

                                    <input className="form-control cm-input" placeholder=" " value={tourcodeVal?.marketCode} onChange={(e)=>onChangeForMultiple(e.target.value, index, "marketCode", tourCodeRepetative, setTourCodeRepetative)}/>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Adult</label>
                            <div className="row">
                                <div className="col-md-6 cm-col">

                                    <input className="form-control cm-input" placeholder=" " disabled={(tourCodeRepetative[index]?.passengerType == "CHILD" || tourCodeRepetative[index]?.passengerType == "INFANT")} value={tourcodeVal?.passengerType == "ADULT" ? tourcodeVal?.tourCodes : ""} onChange={(e)=>{onChangeForMultiple(e.target.value, index, "tourCodes", tourCodeRepetative, setTourCodeRepetative);{onChangeForMultiple("ADULT", index, "passengerType", tourCodeRepetative, setTourCodeRepetative)}}}/>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Child</label>
                            <div className="row">
                                <div className="col-md-6 cm-col">

                                    <input className="form-control cm-input" placeholder=" " value={tourcodeVal?.passengerType == "CHILD" ? tourcodeVal?.tourCodes : ""} disabled={(tourCodeRepetative[index]?.passengerType == "ADULT" || tourCodeRepetative[index]?.passengerType == "INFANT")} onChange={(e)=>{onChangeForMultiple(e.target.value, index, "tourCodes", tourCodeRepetative, setTourCodeRepetative);{onChangeForMultiple("CHILD", index, "passengerType", tourCodeRepetative, setTourCodeRepetative)}}}/>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Infant</label>
                            <div className="row">
                                <div className="col-md-6 cm-col">
                                    <input className="form-control cm-input" placeholder=" " value={tourcodeVal?.passengerType == "INFANT" ? tourcodeVal?.tourCodes : ""} disabled={(tourCodeRepetative[index]?.passengerType == "CHILD" || tourCodeRepetative[index]?.passengerType == "ADULT")} onChange={(e)=>{onChangeForMultiple(e.target.value, index, "tourCodes", tourCodeRepetative, setTourCodeRepetative);{onChangeForMultiple("INFANT", index, "passengerType", tourCodeRepetative, setTourCodeRepetative)}}}/>
                                </div>
                                <div className="col-md-6 cm-col ">

                                    <div className='setD-flex'>
                                    <Button className="BulkUpdate__removeBtn " onClick={()=>{if(tourCodeRepetative.length > 1){let temp = [...tourCodeRepetative]; temp.splice(index, 1);setTourCodeRepetative([...temp]);}}}><i className="fas fa-trash-alt"></i></Button>
                                    <Button className='BulkUpdate__AddBtn px-0' onClick={() => { let temp = [...tourCodeRepetative]; temp.push({ ...tourCode }); setTourCodeRepetative([...temp]); }}><i className="fas fa-plus-circle"></i></Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>)})}

            <div className="card cm-card">
                <div className="card-body">
                    <div className="row cm-col">
                        <div className="col-md-12 cm-col text-right">
                            <button className="cm-secondary-btn mr-2 " onClick={()=>history.push('/newcontractlist')}>Cancel</button>
                            <button className='cm-primary-btn' onClick={saveHandle}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BulkUpdate