import {
    USER_SAVE,
  } from "../constant/types";
  
  const intialState = {
      userData:{},
  };
  
  export const userReducers = (state = intialState, action) => {
    switch (action.type) {
      case USER_SAVE:
        return {
          ...state,
          userData: action.payload,
        };
      default:
        return state;
    }
  };
  export default userReducers;