import axios from 'axios';
import swal from 'sweetalert';

export const getOneTarif = async (tarifKey: any) => {
        let url: any = process.env.REACT_APP_CONTRACT_URL + `/tariffsV2/${tarifKey}`;
        const requestOptions: any = {
            headers: {
                "Content-type": "application/json",
            },
        }
        const getOneTarifDetails:any = await axios.get(url, requestOptions)

        if (getOneTarifDetails === null) {
            swal({
                text:"Fetching Tarif Details Failed",
                icon: "error",
              })
        }
        return getOneTarifDetails
    
}