
export default function TicketBy(props) {
    // const [editFlag ,setEditFlag] = useState(false);
    const editFlag = false
    return (
        <div className="card cm-card">
            <div className="card-body">
            <div className="cm-card-edit">
            </div>
            <div className="row">
                <div className="col-md-6 cm-col">
                    <label className="cm-head-label">OPTIONS</label>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <td>Departure Window</td>
                            <td>Days After Reservation</td>
                            <td>Days Before Departure</td>
                        </tr>
                        </thead>
                        {editFlag ?
                         <tbody>
                    {props.classData && props.classData.optionDates.map((option,index)=>{
                        return(
                            <tr key={index}>
                                <td><input className="form-control cm-input" placeholder="" /></td>
                                <td><input className="form-control cm-input" placeholder="" /></td>
                                <td><input className="form-control cm-input" placeholder="" /></td>
                            </tr>
                                )
                            })}
                          
                     </tbody>
                     :
                        <tbody>
                            {props.classData && props.classData.optionDates.map((option,index)=>{
                                return(
                                    <tr>
                                    <td>{option.departureWindow == null && "-"} {option.departureWindow}</td>
                                    <td>{option.daysAfterReservation == null && "-"}{option.daysAfterReservation}</td>
                                    <td>{option.daysBeforeDeparture == null && "-"}{option.daysBeforeDeparture}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                        }
                    </table>
                </div>
                <div className="col-md-6 cm-col">
                    <label className="cm-head-label">TKT-By</label>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <td>Departure Window</td>
                            <td>Days After Reservation</td>
                            <td>Days Before Departure</td>
                        </tr>
                        </thead>
                        {editFlag ?
                         <tbody>
                    {props.classData && props.classData.ticketDates.map((tktBy,index)=>{
                        return(
                            <tr key={index}>
                                <td><input className="form-control cm-input" placeholder="" /></td>
                                <td><input className="form-control cm-input" placeholder="" /></td>
                                <td><input className="form-control cm-input" placeholder="" /></td>
                            </tr>
                              )
                            })}
                     </tbody>
                     :
                        <tbody>
                            {props.classData && props.classData.ticketDates.map((tktBy,index)=>{
                                return(
                                    <tr>
                                    <td>{tktBy.departureWindow == null && "-"} {tktBy.departureWindow}</td>
                                    <td>{tktBy.daysAfterReservation == null && "-"} {tktBy.daysAfterReservation}</td>
                                    <td>{tktBy.daysBeforeDeparture == null && "-"} {tktBy.daysBeforeDeparture}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                        }
                    </table>
                </div>
            </div>

            
                    
                <div className="row cm-col">
                    <div className="col-12">
                        <label className="cm-head-label">Options Maximum</label>
                    </div>
                    <div className="col-md-2">
                        <label className="cm-head-label">VIT</label>
                        {editFlag ?
                        <input className="form-control cm-input" placeholder="" />
                        :
                        <>
                        {props.classData && (props.classData.maxOptionVIT == null || '') &&
                        <input className="form-control cm-input"  disabled/>
                            }
                            {props.classData && props.classData.maxOptionVIT}
                        </>
                        }
                    </div>
                    <div className="col-md-2">
                        <label className="cm-head-label">SKY</label>
                        {editFlag ?
                        <input className="form-control cm-input" placeholder="" />
                    :
                        <>
                        {props.classData && (props.classData.maxOptionSKY == null || '') &&
                            <input className="form-control cm-input"  disabled/>
                        }
                            {props.classData && props.classData.maxOptionSKY}
                        </>
                    }
                    </div>
                    <div className="col-md-2">
                        <label className="cm-head-label">FUN</label>
                        {editFlag ?
                            <input className="form-control cm-input" placeholder="" />
                        :
                            <>
                                {props.classData && (props.classData.maxOptionFUN == null || '') &&
                                <input className="form-control cm-input"  disabled/>
                                }
                                {props.classData && props.classData.maxOptionFUN}
                            </>
                        }
                    </div>
                    <div className="col-md-2">
                        <label className="cm-head-label">TC</label>
                        {editFlag ?
                            <input className="form-control cm-input" placeholder="" />
                        :
                            <>
                                {props.classData && (props.classData.maxOptionTC == null || '') &&
                                <input className="form-control cm-input"  disabled/>
                                }
                                {props.classData && props.classData.maxOptionTC}
                            </>
                        }
                    </div>
                    <div className="col-md-2">
                        <label className="cm-head-label">DFW</label>
                        {editFlag ?
                            <input className="form-control cm-input" placeholder="" />
                        :
                        <>
                            {props.classData && (props.classData.maxOptionDFW == null || '') &&
                                <input className="form-control cm-input"  disabled/>
                            }
                            {props.classData && props.classData.maxOptionDFW}
                        
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
