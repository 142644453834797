//@ts-nocheck
import React, { useEffect, useState } from 'react';
import Bookingpnrlist from '././Bookingpnrlist';
import calender from "../bookingConfirmation/Images/calender.svg";
import { useSelector, useDispatch } from "react-redux";
import {setFlightTypeCheck} from '../bookingConfirmation/ActionCreator/searchForm';
import { getLastTwelveMonths, formattedDate, getLastSixMonths, changeDateFormat, endTimeDate, getLastFourWeeks, getLastSevenDays } from "../bookingConfirmation/Utils/CommenFunction"
import { getMyFileSearchByPNR, getMyFiles } from "../bookingConfirmation/thunks/getMyFiles"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import Collapse from 'react-bootstrap/Collapse'
import { CSVLink, CSVDownload } from "react-csv";
import ReactExport from "react-export-excel";
import { Select } from '@material-ui/core';
import Workbook from 'react-excel-workbook'
import { Tabs, Tab } from "react-bootstrap";
import {toast} from 'react-toastify';
import moment from 'moment';
import { setMyFilesData } from '../bookingConfirmation/ActionCreator/myFiles';

export default function Searchbooking(props) {
  const flightType1 = useSelector((state) => state.searchForm.flightType);
  const myFiles = useSelector((state) => state.myFile.myFilesData);
  const [myFilesData, setMyFiles] = useState([])
  const [selectOption, setSelectOption] = useState("Last 4 Weeks")
  const [datePeriod, setDatePeriod] = useState({ from: formattedDate(new Date()), to: formattedDate(new Date()) })
  const [filterOption, setFilterOption] = useState("booking")
  const [isFilterChnaged, setFilterChanges] = useState(false)
  const [customDate, setCustomDate] = useState({ from: formattedDate(new Date()), to: formattedDate(new Date()) })
  const [open, setOpen] = useState(false)
  const [openD, setOpenD] = useState(false)
  const [bookingCode, setStatusCode] = useState("All")
  const dispatch = useDispatch()
  const orgId = useSelector((state) => state.agentProfile.orgId);
  const agencyId = useSelector((state) => state.agentProfile.agencyId);
  const agencyGroupId = useSelector((state) => state.agentProfile.agencyGroupId);
  const [isResult, setResult] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flightType, setFlightType] = useState("CAD") 
  let backupOrgId = localStorage.getItem("orgId");
  const [searchText,setSearchData] = useState("")
  const [tabName, setTabName] = useState("allFiles");
  const [initialSearch, setInitialSearch] = useState(true);
  const sortByBookingDate = () => {
    if(tabName!="search_by"){
    setMyFiles([])
    setResult(false)
    setFilterOption("booking")
    if(selectOption === "Last 4 Weeks"){
      let dateObj = getLastFourWeeks()
      setDatePeriod(dateObj)
      getFilteredData("booking", dateObj.from, dateObj.to)
    }else{
      getFilteredData("booking", datePeriod.from, datePeriod.to)
    }
  }

  }

  const sortByDepartureDate = () => {
    setMyFiles([])
    setResult(false)
    setFilterOption("departure")
    if(selectOption === "Last 4 Weeks"){
      let dateObj = getLastFourWeeks()
      setDatePeriod(dateObj)
      getFilteredData("departure", dateObj.from, dateObj.to)
    }else{
    getFilteredData("departure", datePeriod.from, datePeriod.to)
    }

  }

  const handleSelect = (e) => {
    setSelectOption(e)
    setMyFiles([])
    setResult(false)
    let dateObj = {}
    setOpen(false)
    if (e === "Today") {
      let date = formattedDate(new Date())
      dateObj.from = date
      dateObj.to = date
      setDatePeriod(dateObj)
      getFilteredData(filterOption, date, date,flightType)
    }

    if (e === "Yesterday") {
      var currentDate = new Date();
      var yesterdayDate = currentDate.setDate(currentDate.getDate() - 1);
      let date = formattedDate(new Date(yesterdayDate))
      dateObj.from = date
      dateObj.to = date
      setDatePeriod(dateObj)
      getFilteredData(filterOption, dateObj.from, dateObj.to ,flightType)
    }
    if (e === "Last 7 Days") {
      dateObj = getLastSevenDays()
      setDatePeriod(dateObj)
      getFilteredData(filterOption, dateObj.from, dateObj.to ,flightType)
    }

    if (e === "Last 4 Weeks") {
      dateObj = getLastFourWeeks()
      setDatePeriod(dateObj)
      getFilteredData(filterOption, dateObj.from, dateObj.to,flightType)
    }
    if (e === "Last 6 Months") {
      dateObj = getLastSixMonths()
      setDatePeriod(dateObj)
      getFilteredData(filterOption, dateObj.from, dateObj.to,flightType)
    }
    if (e === "Last 12 Months") {
      dateObj = getLastTwelveMonths()
      setDatePeriod(dateObj)
      getFilteredData(filterOption, dateObj.from, dateObj.to,flightType)
    }
    if (e === "customDate") {
      setCustomDate({ from: changeDateFormat(new Date(), "dashed"), to: changeDateFormat(new Date(), "dashed") })     
    }

  }

  const getFilteredData = async (option, fromDate, toDate,flightType) => {
    
    let from = option ? option + "FromDate" : filterOption + "FromDate"
    let to = option ? option + "ToDate" : filterOption + "ToDate"
    let requestPayload = {}
    setIsLoading(true)

    requestPayload = {
      organizationId: orgId !== "" ? orgId : backupOrgId,
      [from]: fromDate,
      [to]: toDate //endTimeDate(new Date(toDate)),
    }



    let myfilesResult = await dispatch(getMyFiles(requestPayload ,flightType))
    if (myfilesResult) {
      let data = myfilesResult.bookings;
      let myfilesResults = {}
      if (bookingCode !== 'All') {
        let bookings = data.filter(list => list.bookingStatusDetail.code == bookingCode);
        myfilesResults.bookings = bookings;
      } else {
        myfilesResults = myfilesResult
      }
      setIsLoading(false)

      if (option == "departure") {
        setMyFiles(myfilesResults && myfilesResults?.bookings && myfilesResults.bookings.sort((a, b) => { return new Date(b.departureDate) - new Date(a.departureDate) }))
        setResult(true)
      }
      if (option == "booking") {
        setMyFiles(myfilesResults && myfilesResults?.bookings && myfilesResults.bookings.sort((a, b) => { return new Date(b.bookingDate) - new Date(a.bookingDate) }))
        setResult(true)
      }


    }

  }


  const handleDateChange = (date, key) => {
    setSelectOption("customDate")
    let obj = { ...customDate, [key]: changeDateFormat(date, "dashed") }
    setDatePeriod(obj)
    setCustomDate({ ...customDate, [key]: changeDateFormat(date, "dashed") })
  }

  const openCollapse = () => {
    setOpen(!open)
  }

  const openCollapseForD = () => {
    setOpenD(!openD)
  }

  useEffect(() => {
    setMyFiles(myFiles)
  }, [myFiles])

  const getCustomDateData = (option, customDate) => {

    let formatedFromDate = formattedDate(new Date(customDate.from))
    let formatedToDate = formattedDate(new Date(customDate.to))
    let dateObj = {}
    dateObj.from = formatedFromDate
    dateObj.to = formatedToDate
    setDatePeriod(dateObj)
    getFilteredData(option, formatedFromDate, formatedToDate,flightType)
  }
  const setBookingCode = (code) => {
    setStatusCode(code)
  }

  useEffect(() => {
    setMyFiles([])
    if (props.keyName === "sortby") {
      sortByBookingDate("CAD")
    }
  }, [props.keyName])


  const csvData = [
    ["Booking Date", "PNR", "Booking Status", "Trip Type", "Cabin Type", "Total Sell Amount", "Loyalty Points"],

  ];

  myFilesData.map((data, i) => {
    let mysortdata = data.bookingStatusDetail.code
    if (bookingCode == 'All') {
      let cabinType = data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin == undefined ? "" : data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin

      let arry = [data.bookingDate, data.bookingDetails.recordLocator, data.bookingStatusDetail.description
        , data.tripType, cabinType, data?.bookingDetails?.itineraryFare?.totalAmount?.amount, data?.promotion?.loyaltyPoints]
      csvData.push(arry)
    }

    if (mysortdata === bookingCode) {
      let cabinType = data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin == undefined ? "" : data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin

      let arry = [data.bookingDate, data?.bookingDetails?.recordLocator, data?.bookingStatusDetail?.description
        , data.tripType, cabinType, data?.bookingDetails?.itineraryFare?.totalAmount?.amount, data?.promotion?.loyaltyPoints]
      csvData.push(arry)
    }
  })



  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const excelArry = []
  myFilesData.map((data, i) => {
    let mysortdata1 = data.bookingStatusDetail.code
    if (bookingCode == 'All') {
      let cabinType = data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin == undefined ?
        " " : data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin
      let dataObj = {
        bookingDate: data?.bookingDate,
        recordLocator: data?.bookingDetails?.recordLocator,
        description: data?.bookingStatusDetail?.description,
        tripType: data?.tripType,
        cabinType: cabinType,
        amount: data?.bookingDetails?.itineraryFare?.totalAmount?.amount,
        loyaltyPoints: data?.promotion?.loyaltyPoints
      }
      excelArry.push(dataObj)
    }
    if (mysortdata1 === bookingCode) {
      let cabinType = data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin == undefined ?
        " " : data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin
      let dataObj = {
        bookingDate: data?.bookingDate,
        recordLocator: data?.bookingDetails?.recordLocator,
        description: data?.bookingStatusDetail?.description,
        tripType: data?.tripType,
        cabinType: cabinType,
        amount: data?.bookingDetails?.itineraryFare?.totalAmount?.amount,
        loyaltyPoints: data?.promotion?.loyaltyPoints
      }
      excelArry.push(dataObj)
    }
  })

  const setFilghtTypeFunction = (value) => {
    sortByBookingDate(value)
    setFlightType(value)
    dispatch(setMyFilesData([]))
  }

    const setSeachText=(e)=>{
      setSearchData(e.target.value)
    }

    const getSearchResult=async()=>{
      setInitialSearch(false)
      let str =""
      if(searchText.length===0){
        //toast.error(`Please the PNR.`, { position: toast.POSITION.TOP_CENTER, autoClose: true });
         console.log("Please enter PNR");
        
      }
      let requestPayload = {
          "recordLocator":String(searchText).trim() 
      }
    
      setIsLoading(true)
      let myfilesResult = await dispatch(getMyFileSearchByPNR(requestPayload,flightType))
    
      if(myfilesResult=="error" && searchOption == "agentName"){
        ToastMessages("Request has timed out, please use Additional Filter to narrow down search results")
      }
      if(myfilesResult){
        setMyFiles(myfilesResult.bookings && myfilesResult.bookings.length===0?[]:myfilesResult.bookings)
      }
      setIsLoading(false)
    }

    const handleKeypress = e => {
      if (e.charCode === 13) {
        getSearchResult();
      }
    }  

      const getTabName = (k) => {
        setTabName(k);
        if(k=="search_by"){
          setMyFiles([])
          setIsLoading(false)
          setInitialSearch(true)
        }
      };  

  return (
    <>
      <div className=" searchbyinput" style={{ position: "relative" }}>
        {/* <div className="pnrstatus">
          <div className="form-check">
            <input className="form-check-input" type="radio" name="bookingState" checked={filterOption === "booking" && "checked"} id="inlineRadio5" value="booking" onChange={sortByBookingDate} />
            <label className="form-check-label" for="inlineRadio5"  >By Reservation Dates</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="bookingState" id="inlineRadio6" checked={filterOption === "departure" && "checked"} value="departure" onChange={sortByDepartureDate} />
            <label className="form-check-label" for="inlineRadio6" >By Departure Dates</label>
          </div>

        </div> */}
        
        <Tabs defaultActiveKey="allFiles" id="uncontrolled-tab-example" className="firsttabmy"
          onSelect={getTabName}>
            <Tab eventKey="allFiles" title={"All Files"}>
              <div style={{marginTop:"10px", padding:"15px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
              <div className="col-md-6 cm-col" >
            {/* <label className="cm-head-label">Select</label> */}
            <div className="cm-react-radio-inline">
            <label >
                <input
                  type="radio"
                  value={flightType}
                  onChange={() => {setFilghtTypeFunction("CAD")}}
                  checked = {flightType == "CAD"}
                />
                CAD
              </label>
            <label>
                <input
                  type="radio"
                  value={flightType}
                  onChange={() => {setFilghtTypeFunction("USD")}}
                  checked = {flightType == "USD"}
                />
                USD
              </label>
            </div>
          </div>
        <div className="datewiseview">
          <div className="bd-pi-formcol pi-formcol-2 mf-filter-row" >

            <>
            
              <span className="mf-filter-label">Show Bookings Done</span>
              <button
                onClick={openCollapse}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="mf-filter-btn"
              > <img src={calender} />
                {/* {selectOption} */}
                {selectOption === "customDate" ? "Custom Date" : selectOption}
              </button>
              <Collapse in={open}>
                <div id="example-collapse-text" className="mf-filter-collapse">

                  <div onClick={() => handleSelect("customDate")}><span className="dropditem">Custom Date</span></div>
                  <div onClick={() => handleSelect("Today")} ><span className="dropditem">Today</span></div>
                  <div onClick={() => handleSelect("Yesterday")} ><span className="dropditem">Yesterday</span></div>
                  <div onClick={() => handleSelect("Last 7 Days")} ><span className="dropditem">Last 7 Days</span></div>
                  <div onClick={() => handleSelect("Last 4 Weeks")} ><span className="dropditem">Last 4 Weeks</span></div>
                  <div onClick={() => handleSelect("Last 6 Months")} ><span className="dropditem">Last 6 Months</span></div>
                  <div onClick={() => handleSelect("Last 12 Months")} ><span className="dropditem">Last 12 Months</span></div>
                </div>
              </Collapse>
            </>
          </div>


          

            <>
            {selectOption === "customDate" && <div eventKey="customDate">
              <p className="dropditemp">
                <div className="bd-pi-input pi-input-date ">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      id="birth_date"
                      required
                      label="From"
                      margin="normal"
                      format="MM/dd/yyyy"
                      name="from"
                      value={customDate && customDate.from}
                      onChange={date => handleDateChange(date, "from")}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="bd-pi-input pi-input-date">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      id="birth_date"
                      required
                      label="To"
                      margin="normal"
                      format="MM/dd/yyyy"
                      name="to"
                      value={customDate && customDate.to}
                      onChange={date => handleDateChange(date, "to")}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="bd-pi-input pi-input-date ">
                  <button type="button" className="btn viewbuttonmyfile" onClick={() => getCustomDateData(filterOption, customDate)}>View</button>
                </div>
              </p>
            </div>} </>

            <div className=" pnrbooking" style={{ display: "flex", alignItems: "center", pointerEvents: myFilesData.length === 0 ? "none" : '' }}>

            <div className='customizeTab'>  <CSVLink filename={`${moment().format('MMMM_Do_YYYY_h_mm')}`} data={csvData}>CSV</CSVLink> </div>
            <div className='customizeTab'>
              <Workbook filename={`${moment().format('MMMM_Do_YYYY_h_mm')}.xlsx`} element={<span>Excel</span>}>
                <Workbook.Sheet data={excelArry} name="Sheet A">
                  <Workbook.Column label="Booking Date" value="bookingDate" />
                  <Workbook.Column label="PNR" value="recordLocator" />
                  <Workbook.Column label="Booking Status" value="description" />
                  <Workbook.Column label="Trip Type" value="tripType" />
                  <Workbook.Column label="Cabin Type" value="cabinType" />
                  <Workbook.Column label="Total Sell Amount" value="amount" />
                  <Workbook.Column label="Loyalty Point" value="layaltyPoint" />
                </Workbook.Sheet>
              </Workbook> </div>
          
            </div>
          </div> 
          </div>
            </Tab>
          <Tab eventKey="search_by" title={"Search By"}>
            <div style={{ marginTop: "10px", padding: "15px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
            <div className="col-md-6 cm-col" style={{paddingBottom:"10px"}}>
            {/* <label className="cm-head-label">Select</label> */}
            <div className="cm-react-radio-inline">
            <label >
                <input
                  type="radio"
                  value={flightType}
                  onChange={() => {setFilghtTypeFunction("CAD")}}
                  checked = {flightType == "CAD"}
                />
                CAD
              </label>
            <label>
                <input
                  type="radio"
                  value={flightType}
                  onChange={() => {setFilghtTypeFunction("USD")}}
                  checked = {flightType == "USD"}
                />
                USD
              </label>
            </div>
          </div>
              <div className="datewiseview inputpnr">
                <label style={{padding:"7px"}} for="inlineRadio1"><strong>{'PNR'}</strong></label>
                <input type="text" placeholder=" " id="pnr" name="searchText" onChange={setSeachText} onKeyPress={handleKeypress} />
                <button type="button" className="btn viewbuttonmyfile" onClick={getSearchResult}>{'Search'}</button>
              </div>
            </div>
          </Tab>
          </Tabs>
        

        </div>



      <Bookingpnrlist myFilesData={myFilesData} filter={tabName=="allFiles"?"sortBy":"search_by"} filterOption={filterOption} date={datePeriod} bookingStatusCode={(k) => setBookingCode(k)} isResult={isResult} isLoading={isLoading} flightType={flightType} initialSearch={initialSearch}/>

    </>
  )
      
      }
