import React, { useState, useEffect, useContext } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Alert } from 'react-bootstrap';

import { useHistory} from "react-router-dom";


export default function Createclass(props) {
  const history = useHistory();

  const [triffData, setTariffData] = useState({});
  const [tariffId, setTariffId] = useState(props.match.params.tariffId)

//   STATE DATA
const [via ,setVia] = useState('')
const [isDisabled ,setIsDisabled] = useState(false)
const [validFrom ,setValidFrom] = useState('')

const [alert, setAlert] = useState({ variant: 'success', show: false, message: '' });

const [clasKey ,setClasKey ] = useState('')
const [clasTarifKey ,setClasTarifKey ] = useState('')
const [classCodes ,setClassCodes ] = useState('')
const [brandCode ,setBrandCode ] = useState('')
const [category ,setCategory ] = useState('')
const [minmaxStay ,setMinmaxStay ] = useState('')
const [contractType ,setContractType ] = useState('Net')
const [juniorAgeTo ,setJuniorAgeTo ] = useState('')
const [juniorAgeFrom ,setJuniorAgeForm ] = useState('')
const [clasPhaseIVnet ,setClasPhaseIVnet ] = useState(false)
const [ticketingFee ,setTicketingFee ] = useState('')
const [classNote ,setClassNote ] = useState('')
const [classLastTicketingDate ,setClassLastTicketingDate ] = useState('')
const [note ,setNote ] = useState('')
const [childAge ,setChildAge ] = useState(0)
const [infantAge ,setInfantAge ] = useState(0)
const [isApplicableOn ,setIsApplicableOn ] = useState('')
const [noTicketingForInfant ,setNoTicketingForInfant ] = useState(false)

const [permitGKPNR ,setPermitGKPNR ] = useState(false)


const [maxOptionVIT ,setMaxOptionVIT ] = useState(0)
const [maxOptionSKY ,setMaxOptionSKY ] = useState(0)
const [maxOptionFUN ,setMaxOptionFUN ] = useState(0)
const [maxOptionDFW ,setMaxOptionDFW ] = useState(0)
const [maxOptionTC ,setMaxOptionTC ] = useState(0)
const [osi ,setOsi ] = useState('')
const [emission ,setEmission ] = useState('')
const [fareType ,setFareType ] = useState('')
const [disablePriceUnitConcept ,setDisablePriceUnitConcept ] = useState(false)

const [VIT ,setVIT ] = useState('')
const [SKY ,setSKY ] = useState('')
const [FUN ,setFUN ] = useState('')
const [DFW ,setDFW ] = useState('')
const [TC ,setTC ] = useState('')
const [issueAsBulk ,setIssueAsBulk ] = useState(false)
const [validateTotal ,setValidateTotal ] = useState(false)


const [disallowedAirports ,setDisallowedAirports ] = useState('')
const [classCodeDesc ,setClassCodeDesc ] = useState('')


const [vitfrench ,setVitfrench ] = useState('')
const [vitEnglish ,setVitEnglish ] = useState('')
const [skyfrench ,setSkyfrench ] = useState('')
const [skyEnglish ,setSkyEnglish ] = useState('')
const [funfrench ,setFunfrench ] = useState('')
const [funEnglish ,setFunEnglish ] = useState('')
const [dfwfrench ,setDfwfrench ] = useState('')
const [dfwEnglish ,setDfwEnglish ] = useState('')
const [tcfrench ,setTcfrench ] = useState('')
const [tcEnglish ,setTcEnglish ] = useState('')
const [remarksFr ,setRemarksFr ] = useState('')
const [remarksEr ,setRemarksEr ] = useState('')
const [classRoutings ,setClassRoutings ] = useState('')
const [otherClassOfServices ,setOtherClassOfServices ] = useState('')



const [optionDates ,setOptionDates ] = useState([{
  "departureWindow": 0,
  "daysAfterReservation": 0,
  "daysBeforeDeparture": 0
}])
const [ticketDates ,setTicketDates ] = useState([{
  "departureWindow": 0,
  "daysAfterReservation": 0,
  "daysBeforeDeparture": 0
}])
const [flagTest ,setFlagTest ] = useState(false)


// FOR OPTION DATE DATA
  const AddOptionsDate = () =>{
    setFlagTest(!flagTest)
    optionDates.push({
      "departureWindow": 0,
      "daysAfterReservation": 0,
      "daysBeforeDeparture": 0
    })
    setOptionDates(optionDates)
  }
  
  const removeOptionDate = (index) => {
    optionDates.splice(0,1)
    setOptionDates(optionDates)
    setFlagTest(!flagTest)
  }

  const onChangeDateData = (e ,index ,key ) =>{
    setFlagTest(!flagTest)
    optionDates[index][key] = e.target.value
    setOptionDates(optionDates)
    console.log("optionDates",optionDates)

  }

  // FOR OPTION TICKET DATA
  const AddOptionsTicket = () =>{
    setFlagTest(!flagTest)
    ticketDates.push({
      "departureWindow": "",
      "daysAfterReservation": "",
      "daysBeforeDeparture": ""
    })
    setTicketDates(ticketDates)
  }

  const removeTicketDate = (index) => {
    ticketDates.splice(0,1)
    setTicketDates(ticketDates)
    setFlagTest(!flagTest)
  }

  const onChangeTicketData = (e ,index ,key ) =>{
    setFlagTest(!flagTest)
    ticketDates[index][key] = e.target.value
    setTicketDates(ticketDates)

  }

  const [clasKeyError ,setClasKeyError] = useState("")
  const [clasTarifKeyError ,setClasTarifKeyError] = useState("")
  const [brandCodeError ,setBrandCodeError] = useState("")
  const [classCodesError ,setClassCodesError] = useState("")
  const [minmaxStayError ,setMinmaxStayError] = useState("")
  const [ticketingFeeError ,setTicketingFeeError] = useState("")
  const [classNoteError ,setClassNoteError] = useState("")
  const [classLastTicketingDateError ,setClassLastTicketingDateError] = useState("")
  const [categoryError ,setCategoryError] = useState("")
  const [fareTypeError ,setFareTypeError] = useState("")
  const [classCodeDescError ,setClassCodeDescError] = useState("")

  const [isApplicableOnError ,setIsApplicableOnError ] = useState('')



  // VALIDATION FUNCTION
  const validateClass = () =>{
    setClassCodeDescError('')
    setClasKeyError('')
    setFareTypeError('')
    setCategoryError('')
    setClassLastTicketingDateError('')
    setClassNoteError('')
    setTicketingFeeError('')
    setMinmaxStayError('')
    setClassCodesError('')
    setBrandCodeError('')
    setClasTarifKeyError('')
    setClasKeyError('')
    setIsApplicableOnError('')
    let isValid = true 
    if(clasKey === ''){
      isValid = false
      setClasKeyError("Please Enter value.")
    }

    if(clasTarifKey === ''){
      isValid = false
      setClasTarifKeyError("Please Enter value.")
    }
    
    if(brandCode === ''){
      isValid = false
      setBrandCodeError("Please Enter value.")
    }

    if(classCodes === ''){
      isValid = false
      setClassCodesError("Please Enter value.")
    }

    if(minmaxStay === ''){
      isValid = false
      setMinmaxStayError("Please Enter value.")
    }

    if(ticketingFee === ''){
      isValid = false
      setTicketingFeeError("Please Enter value.")
    }

    if(classNote === ''){
      isValid = false
      setClassNoteError("Please Enter value.")
    }
    if(classLastTicketingDate === ''){
      isValid = false
      setClassLastTicketingDateError("Please Enter value.")
    }
    if(category === ''){
      isValid = false
      setCategoryError("Please Enter value.")
    }

    if(fareType === ''){
      isValid = false
      setFareTypeError("Please Enter value.")
    }

    if(classCodeDesc === ''){
      isValid = false
      setClassCodeDescError("Please Enter value.")
    }
    if(isApplicableOn === ''){
      isValid = false
      setIsApplicableOnError("Please Enter value.")
    }

    if(isValid){
      Submit()
    }
  }
  

  const redirectToClasses = (value) => {
    history.push(`/classes/${value.cid}/${value.tid}`);
}
const [isDisabledButton ,setIsDisabledButton] = useState(false)
    const Submit = () => {
      //FINIAL API DATA BODY
      setIsDisabledButton(true)
      const jsonBody = {
            "isDisabled": isDisabled,
            "clasKey": clasKey,
            "clasTarifKey": clasTarifKey,
            "brandCode": brandCode,
            "classCodes": [
                classCodes
            ],
            "minmaxStay": minmaxStay,
            "contractType": contractType,
            "clasPhaseIVnet": clasPhaseIVnet,
            "ticketingFee": ticketingFee,
            "classNote": classNote,
            "classLastTicketingDate": classLastTicketingDate,
            "category": category,
            "juniorAgeTo": juniorAgeTo,
            "juniorAgeFrom": juniorAgeFrom,
            "ageRule": {
                "note": note,
                "ageRestrictions": {
                    "childAge": childAge,
                    "infantAge": infantAge,
                    "isApplicableOn": isApplicableOn,
                    "noTicketingForInfant": noTicketingForInfant
                }
            },
            "ticketingRules": {
                "optionDates":optionDates,
                "ticketDates":ticketDates,
                "maxOptionVIT": maxOptionVIT,
                "maxOptionSKY": maxOptionSKY,
                "maxOptionFUN": maxOptionFUN,
                "maxOptionDFW": maxOptionDFW,
                "maxOptionTC": maxOptionTC
            },
            "otherDetails": {
                "permitBranchPNR": false,
                "permitGKPNR": permitGKPNR,
                "osi": osi,
                "bookingEmail": [
                    "test@test.com"
                ]
            },
            "routing": {
                "disallowedAirports": [
                  disallowedAirports
                ]
            },
            "pricingDetails": {
                "issueAsBulk": false,
                "disableAutoTicketing": false,
                "emission": emission,
                "fareType": fareType,
                "disablePriceUnitConcept": disablePriceUnitConcept,
                "accountCode": {
                    "VIT": VIT,
                    "SKY": SKY,
                    "FUN": FUN,
                    "DFW": DFW,
                    "TC": TC
                },
                "validateTotal": validateTotal,
                "promoClasCodeVIT": "",
                "promoClasCodeSKY": "",
                "WP": false,
                "WPNCB": false
            },
            "remarks": {
                "VIT": {
                    "french": vitfrench,
                    "english": vitEnglish
                },
                "SKY": {
                    "french": skyfrench,
                    "english": skyEnglish
                },
                "FUN": {
                    "french": funfrench,
                    "english": funEnglish
                },
                "DFW": {
                    "french": dfwfrench,
                    "english": dfwEnglish
                },
                "TC": {
                    "french": tcfrench,
                    "english": tcEnglish
                }
            },
            "classCodeDesc": classCodeDesc,
            "otherClassOfServices": otherClassOfServices,
            "remarksEn": remarksEr,
            "remarksFr": remarksFr,
            "classRoutings": classRoutings
        }
            
      
        jsonBody.juniorAgeTo === "" && delete jsonBody.juniorAgeTo
        jsonBody.juniorAgeFrom === "" && delete jsonBody.juniorAgeFrom
        





      const requestOptions = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
  
        },
        body: JSON.stringify(jsonBody)
      }
      fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}/class`, requestOptions)
        .then(response => response.json())
        // console.log("response",response)
        .then(result => {
          console.log('result',result)
          if (result.status !== undefined) {
            setAlert({ variant: 'danger', show: true, message: result.message });
            setIsDisabledButton(false)

          } else {
            setAlert({ variant: 'success', show: true, message: "Successfully Created ." });
            redirectToClasses(result)
            setIsDisabledButton(false)

          }
  
        })
        .catch(error => console.error("Error: ", error))
        setIsDisabledButton(false)

    }

      // GET CONTRACT /TRIFF LIST API CALL 
  useEffect(() => {
    fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}`, {
      method: 'GET',
    })
      .then(response => response.json())
      // .then(result => console.log("result,",result))
      // .then(result => result.map(data=>{
      //     data['triffId'] = data.PK + "_" + data.SK
      //   })
      .then(result => setTariffData(result))
      .catch(error => console.error("Error: ", error))
  }, []);
    let logoLowerCase = triffData.carrier && triffData.carrier.toLowerCase();
  let flightLogo = `${process.env.REACT_APP_FLIGHT_LOGO_URL}/${logoLowerCase}.png`

  const backtoContract = () => {
    history.push(`/contractmanagement/${tariffId}`);
    }
  return (
    <div>
      <div className="cm-h1-head">
        <h1>Create Class</h1>
          <span onClick={()=>backtoContract()} style={{ cursor: 'pointer' }} className="text-primary" > <span > <i className="fas fa-chevron-left mr-1 icon-cog "> </i></span> Back  </span>
      </div>

      <Alert
        variant={alert.variant}
        show={alert.show}
        onClose={() => setAlert({ ...alert, show: false })}
        dismissible
      >
        {alert.message}
      </Alert>
      <div className="cm-head">
            <div className="cm-head-list">
              <span className="fligh-logo"><img src={flightLogo} alt="logo" height="30" width="30" /> {triffData.carrier}</span>
              <span> <strong>Tariff:</strong> {triffData.tarifKey}</span>
              <span><strong>{triffData.isBrandedFare === "1" ? "Branded Fare" : "Non-Branded Fare"}</strong></span>
              <span><strong>VIA:</strong> {triffData && triffData.via} </span>
              {/* <span><strong>Fare Type:</strong> {triffData.contractType}</span>          */}

            </div>
          

          </div>
      <div className="card cm-card">
        <div className="card-body">
          <div>
            <h6 style={{ fontWeight: 'bold' }}>Class</h6>
            <hr />
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Class Key <span className="text-danger"> * </span></label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={clasKey}
                    placeholder="Enter Class key"
                    onChange={(e) => setClasKey(e.target.value)}
                  />
                  <span className="text-danger">{clasKeyError}</span>
                </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Class Tariff Key <span className="text-danger"> * </span></label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={clasTarifKey}
                    onChange={(e) => setClasTarifKey(e.target.value)}
                  />
                  <span className="text-danger">{clasTarifKeyError}</span>
                </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Class Codes <span className="text-danger"> * </span></label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={classCodes}
                    onChange={(e) => setClassCodes(e.target.value)}
                  />
                  <span className="text-danger">{classCodesError}</span>
                </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">brand Code <span className="text-danger"> * </span></label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={brandCode}
                    onChange={(e) => setBrandCode(e.target.value)}
                  />
                  <span className="text-danger">{brandCodeError}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label"> Category <span className="text-danger"> * </span> </label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                  <span className="text-danger">{categoryError}</span>
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Minmax Stay <span className="text-danger"> * </span></label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={minmaxStay}
                    onChange={(e) => setMinmaxStay(e.target.value)}
                  />
                 <span className="text-danger">{minmaxStayError}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Ticketing Fee<span className="text-danger"> * </span></label>
                <input
                    className="form-control cm-input"
                    type="number"
                    value={ticketingFee}
                    onChange={(e) => setTicketingFee(e.target.value)}
                  />
                    <span className="text-danger">{ticketingFeeError}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Class Note<span className="text-danger"> * </span> </label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={classNote}
                    onChange={(e) => setClassNote(e.target.value)}
                  />
                 <span className="text-danger">{classNoteError}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label"> Class Code Desc <span className="text-danger"> * </span> </label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={classCodeDesc}
                    onChange={(e) => setClassCodeDesc(e.target.value)}
                  />
                  <span className="text-danger">{classCodeDescError }</span>
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Other Class Of Services</label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={otherClassOfServices}
                    onChange={(e) => setOtherClassOfServices(e.target.value)}
                  />
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">English Remarks </label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={remarksEr}
                    onChange={(e) => setRemarksEr(e.target.value)}
                  />
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">French Remarks</label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={remarksFr}
                    onChange={(e) => setRemarksFr(e.target.value)}
                  />
              </div>
            </div>
           
          </div>
        </div>

        <div className="card-body">
          <div className="row">
          <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Class Routings </label>
                <input
                    className="form-control cm-input"
                    type="text"
                    value={classRoutings}
                    onChange={(e) => setClassRoutings(e.target.value)}
                  />
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label"> Disabled <span className="text-danger"> * </span></label>
                <div className="cm-radio-inline">
                    <FormControlLabel 
                        control={<Radio color="primary" />} 
                        label="True" 
                        checked={isDisabled}
                        onChange = {()=>setIsDisabled(true)}
                    />  
                    <FormControlLabel 
                        control={<Radio color="primary" />} 
                        label=" False" 
                        checked={isDisabled === false}
                        onChange = {()=>setIsDisabled(false)}
                    /> 
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Contract Type <span className="text-danger"> * </span></label>
                <div className="cm-radio-inline">
                    <FormControlLabel 
                        control={<Radio color="primary" />} 
                        label="Published" 
                        onChange={()=> setContractType("Published")}
                        checked={contractType === "Published" ? true :false}
                    />  
                    <FormControlLabel 
                        control={<Radio color="primary" />} 
                        label=" Net" 
                        onChange={()=> setContractType("Net")}
                        checked={contractType === 'Net' ? true :false}
                    /> 
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Class PhaseIV Net <span className="text-danger"> * </span></label>
                <div className="cm-radio-inline">
                    <FormControlLabel 
                        control={<Radio color="primary" />} 
                        label="True" 
                        checked={clasPhaseIVnet}
                        onChange={()=>setClasPhaseIVnet(true)}
                    />  
                    <FormControlLabel 
                        control={<Radio color="primary" />} 
                        label=" False" 
                        onChange={()=>setClasPhaseIVnet(false)}
                        checked={clasPhaseIVnet === false}
                    /> 
                </div>
              </div>
            </div>
         
          </div>

        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Junior Age Form </label>
                <input
                    className="form-control cm-input"
                    type="number"
                    value={juniorAgeFrom}
                    onChange={(e) => setJuniorAgeForm(e.target.value)}
                  />
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Junior Age To</label>
                <input
                    className="form-control cm-input"
                    type="number"
                    value={juniorAgeTo}
                    onChange={(e) => setJuniorAgeTo(e.target.value)}
                  />
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Last Ticketing Date <span className="text-danger"> * </span></label>
                <input
                  className="form-control cm-input"
                  type="date"
                  value={classLastTicketingDate}
                  onChange={(e)=>setClassLastTicketingDate(e.target.value)}
                 
                />
                <span className="text-danger">{classLastTicketingDateError}</span>
              </div>
            </div>
         
          </div>
        </div>

        {/* RULE  */}
        <div className="card-body">
          <div>
            <h6 style={{ fontWeight: 'bold' }}>Rule</h6>
            <hr />
          </div>
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Note</label>
                <input
                  className="form-control cm-input"
                  value={note}
                  onChange={()=>setNote()}

                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
              <label className="cm-head-label">Child Age</label>
                <input
                  className="form-control cm-input"
                  value={childAge}
                  type="number"
                  onChange={(e) => setChildAge(e.target.value)}

                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
              <label className="cm-head-label">Infant Age</label>
                <input
                  className="form-control cm-input"
                  value={infantAge}
                  type="number"
                  onChange={(e) => setInfantAge(e.target.value)}

                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
              <label className="cm-head-label">Applicable On <span className="text-danger"> * </span></label>
              <div className="cm-radio-inline">
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label="Arrival"
                                        onChange={()=>setIsApplicableOn("ARRIVAL")}
                                        value="ARRIVAL"
                                         checked={isApplicableOn === "ARRIVAL" ? true : false}
                                    />
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label="Return"
                                        onChange={()=>setIsApplicableOn("RETURN")}
                                        value="RETURN"
                                         checked={isApplicableOn === "RETURN" ? true : false}      
                                        />
                                </div>
                                <span className="text-danger">{isApplicableOnError}</span>
              </div>
            </div>
           
          </div>
          <div className="row align-items-center">
          <div className="col-md-3">
              <div className="cm-col">
              <label className="cm-head-label">OSI</label>
                <input
                  className="form-control cm-input"
                  type=""
                  value={osi}
                  onChange={(e) => setOsi(e.target.value)}
                />
            </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
              {/* <label className="cm-head-label">No Ticketing For Infant</label> */}
              <div className="cm-checkbox-inline">
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                         checked={permitGKPNR	 ? true : false}
                                            color="primary"
                                        />
                                        }
                                        label="Permit GK PNR"
                                        onChange={()=>setPermitGKPNR(!permitGKPNR)}
                                    />
                                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
              {/* <label className="cm-head-label">No Ticketing For Infant</label> */}
              <div className="cm-checkbox-inline">
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                           checked={noTicketingForInfant	 ? true : false}
                                            color="primary"
                                        />
                                        }
                                        onChange={()=>setNoTicketingForInfant(!noTicketingForInfant)}
                                        label="No ticket for infant"
                                    />
                                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TICKETING RULE */}
        <div className="card-body">
          <div>
            <h6 style={{ fontWeight: 'bold' }}>Ticketing Rules</h6>
            <hr />
          </div>

          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">Option Dates</label>
                <table className="table table-bordered">
                        <thead>
                        <tr>
                            <td>Departure Window</td>
                            <td>Days After Reservation</td>
                            <td>Days Before Departure</td>
                            <td></td>
                        </tr>
                        </thead>
                         <tbody>
                        
                    {optionDates.map((option,index)=>{
                        return(
                            <tr key={index}>
                                <td><input onChange={(e)=>onChangeDateData(e,index,"departureWindow")} className="form-control cm-input" type="number"  value = {option.departureWindow} /></td>
                                <td><input onChange={(e)=>onChangeDateData(e,index,"daysAfterReservation")} className="form-control cm-input" type="number"   value = {option.daysAfterReservation} /></td>
                                <td><input onChange={(e)=>onChangeDateData(e,index,"daysBeforeDeparture")}  className="form-control cm-input" type="number"   value = {option.daysBeforeDeparture} /></td>
                                <td>
                                <div className="cm-edidel-btns ">
                                  <button className="cm-view-btn mr-2" onClick={() => AddOptionsDate()} title="Click to Add Extra Email" >
                                          <i className="fas fa-plus"></i>
                                    </button>
                                    {index !== 0 &&
                                    <button 
                                    className="cm-edit-del mr-2"         
                                    onClick={() => removeOptionDate(index)}
                                    >
                                  <i className="fas fa-minus"></i>
                                </button>
                                  }
                                </div>
                                </td>
                            </tr>
                                )
                            })}
                          
                     </tbody>
                    
                    </table>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">Ticket Dates</label>
                <table className="table table-bordered">
                        <thead>
                        <tr>
                            <td>Departure Window</td>
                            <td>Days After Reservation</td>
                            <td>Days Before Departure</td>
                            <td></td>
                        </tr>
                        </thead>
                         <tbody>
                        
                    {ticketDates.map((option,index)=>{
                        return(
                            <tr key={index}>
                                <td><input type="number"  onChange={(e)=>onChangeTicketData(e,index,"departureWindow")} className="form-control cm-input"  value = {option.departureWindow} /></td>
                                <td><input type="number"  onChange={(e)=>onChangeTicketData(e,index,"daysAfterReservation")} className="form-control cm-input"  value = {option.daysAfterReservation} /></td>
                                <td><input type="number"  onChange={(e)=>onChangeTicketData(e,index,"daysBeforeDeparture")} className="form-control cm-input"  value = {option.daysBeforeDeparture} /></td>
                                <td>
                                <div className="cm-edidel-btns ">
                                  <button className="cm-view-btn mr-2" onClick={() => AddOptionsTicket()} title="Click to Add Extra Email" >
                                          <i className="fas fa-plus"></i>
                                    </button>
                                    {index !== 0 &&
                                    <button 
                                    className="cm-edit-del mr-2"         
                                    onClick={() => removeTicketDate(index)}
                                    >
                                  <i className="fas fa-minus"></i>
                                </button>
                                  }
                                </div>
                                </td>
                            </tr>
                                )
                            })}
                          
                     </tbody>
                    
                    </table>
              </div>
            </div>
          </div>

          <label className="cm-head-label text-muted">Options Maximum</label>
          <div className="row align-items-center">
            <div className="col-md-2">
              <div className="cm-col">
                <label className="cm-head-label">VIT</label>
                <input
                  className="form-control cm-input"
                  value={maxOptionVIT}
                  onChange={(e) => setMaxOptionVIT(e.target.value)}

                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="cm-col">
                <label className="cm-head-label">SKY</label>
                <input
                  className="form-control cm-input"
                  value={maxOptionSKY}
                  onChange={(e) => setMaxOptionSKY(e.target.value)}

                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="cm-col">
                <label className="cm-head-label">FUN</label>
                <input
                  className="form-control cm-input"
                  value={maxOptionFUN}
                  onChange={(e) => setMaxOptionFUN(e.target.value)}

                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="cm-col">
                <label className="cm-head-label">TC</label>
                <input
                  className="form-control cm-input"
                  value={maxOptionTC}
                  onChange={(e) => setMaxOptionTC(e.target.value)}

                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="cm-col">
                <label className="cm-head-label">DFW</label>
                <input
                  className="form-control cm-input"
                  value={maxOptionDFW}
                  onChange={(e) => setMaxOptionDFW(e.target.value)}

                />
              </div>
            </div>
          </div>
        </div>

         {/* PRICE AT SABARE */}
         <div className="card-body">
          <div>
            <h6 style={{ fontWeight: 'bold' }}>Price At Sabre</h6>
            <hr />
          </div>

          <div className="row align-items-center">
                    <div className="col-md-6 cm-col">
                        <label className="cm-head-label">Ticket issuance condition</label>
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary"  />} 
                                    label="PhaseIV" 
                                    checked={emission === "PHASEIV" ? true :false}
                                    onChange={()=>setEmission("PHASEIV")}
                                />  
                                <FormControlLabel 
                                    control={<Radio color="primary"  />} 
                                    label=" PQ" 
                                     checked={emission === 'PQ' ? true :false}
                                     onChange={()=>setEmission("PQ")}

                                 /> 
                                 <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label=" PQ CAT 35" 
                                 checked={emission === 'PQ-CAT35'? true :false}
                                 onChange={()=>setEmission("PQ-CAT35")}

                                 /> 
                                    
                            </div>

                    </div>
                    <div className="col-md-6 cm-col">
                        <div className="cm-checkbox-inline">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                    checked={issueAsBulk ? true : false}
                                        color="primary"
                                    />
                                    }
                                    onChange={()=>setIssueAsBulk(!issueAsBulk)}
                                    label="Issue ticket as bulk only"
                                />
                            
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 cm-col">
                        <label className="cm-head-label">Search Fare By <span className="text-danger"> * </span></label>
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ADT Public" 
                                    checked={fareType === "ADT public" ? true : false}
                                    onChange={()=>setFareType("ADT public")}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ADT Private" 
                                    checked={fareType === "ADT prive" ? true :false}
                                    onChange={()=>setFareType("ADT prive")}

                                />
                             
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="JCB" 
                                    checked={fareType === "JCB" ? true : false}
                                    onChange={()=>setFareType("JCB")}

                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="PFA" 
                                    checked={fareType === "PFA" ? true : false}
                                    onChange={()=>setFareType("PFA")}

                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ITX" 
                                    checked={fareType === "ITX" ? true : false}
                                    onChange={()=>setFareType("ITX")}

                                />
                            </div>
                                    <span className="text-danger">{fareTypeError}</span>
                    </div>
                    <div className="col-md-6 cm-col">
                        <label className="cm-head-label">Inter-Island</label>
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="Sans NB" 
                                    checked={disablePriceUnitConcept ? true : false}
                                    onChange = {()=>setDisablePriceUnitConcept(!disablePriceUnitConcept)}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="Total Sabre Ok"                  
                                    checked={validateTotal ? true : false}
                                    onChange = {()=>setValidateTotal(!validateTotal)}

                                />
                
                            </div>
                        
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">Account Code</label>
                        <div className="row">
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">VIT</label>
                                    <>
                                    <input className="form-control cm-input" placeholder="Enter Value" 
                                    onChange={(e)=>setVIT(e.target.value)} 
                                    value={VIT}
                                    />
                                    </>
                                
                            </div>
                            
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">SKY</label>
                               
                                    <>
                                        <input className="form-control cm-input" 
                                        placeholder="Enter Value"
                                        onChange={(e)=>setSKY(e.target.value)} 
                                        value={SKY}
                                        />
                                    </>
                                
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">DFW</label>
                               
                                    <>
                                        <input className="form-control cm-input"
                                         placeholder="Enter Value"
                                        onChange={(e)=>setDFW(e.target.value)} 
                                        value={DFW}
                                        />
                                    </>
                               
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">FUN</label>
                                
                                    <>
                                    <input className="form-control cm-input"
                                     placeholder="Enter Value"
                                     value={FUN}
                                     onChange={(e)=>setFUN(e.target.value)} 

                                     />
                                    </>
                                
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">TC</label>
                                    <>
                                        <input className="form-control cm-input" 
                                          placeholder="Enter Value"
                                          value={TC}
                                          onChange={(e)=>setTC(e.target.value)}
                                        />
                                    </>
                              
                            </div>
                                
                        </div>                                
                    </div>
                </div>
        </div>

        <div className="card-body">
          <div>
            <h6 style={{ fontWeight: 'bold' }}>Routing</h6>
            <hr />
          </div>
            <div className="row align-items-center">
                <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Disallowed Airports</label>
                    <input
                        className="form-control cm-input"
                        type="text"
                        value={disallowedAirports}
                        onChange={(e) => setDisallowedAirports(e.target.value)}
                     />
                </div>
            </div>
          </div>

        {/* REMARKS */}
          <div className="card-body">
          <div>
            <h6 style={{ fontWeight: 'bold' }}>REMARKS</h6>
            <hr />
          </div>
          <div className="row ">
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">INTAIR</label>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input"
                                value={vitfrench}
                                onChange={(e)=>setVitfrench(e.target.value)}
                                />
                                
                            
                            </div>
                            <div className="col-md-6">
                        
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input"
                                 value={vitEnglish}
                                 onChange={(e)=>setVitEnglish(e.target.value)}
                                />
                                
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">SKYLINK</label>
                        <div className="row">
                            <div className="col-md-6">
                        
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input"
                                  value={skyfrench}
                                  onChange={(e)=>setSkyfrench(e.target.value)}
                                />
                            
                            </div>
                            <div className="col-md-6">
                    
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input" 
                                 value={skyEnglish}
                                 onChange={(e)=>setSkyEnglish(e.target.value)}
                                />
                          
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">FUN SUN</label>
                        <div className="row">
                            <div className="col-md-6">
                          
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input"
                                 value={funfrench}
                                 onChange={(e)=>setFunfrench(e.target.value)}
                                />
                             
                            </div>
                            <div className="col-md-6">
                        
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input" 
                                  value={funEnglish}
                                  onChange={(e)=>setFunEnglish(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">DFW</label>
                        <div className="row">
                            <div className="col-md-6">
                           
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input" 
                                   value={dfwfrench}
                                   onChange={(e)=>setDfwfrench(e.target.value)}
                                />
                           
                            </div>
                            <div className="col-md-6">
                         
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input"
                                 value={dfwEnglish}
                                 onChange={(e)=>setDfwEnglish(e.target.value)}
                                />
                             
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">TC</label>
                        <div className="row">
                            <div className="col-md-6">
                         
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input" 
                                    value={tcfrench}
                                    onChange={(e)=>setTcfrench(e.target.value)}
                                />
                           
                            </div>
                            <div className="col-md-6">
                           
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input"
                                    value={tcEnglish}
                                    onChange={(e)=>setTcEnglish(e.target.value)}
                                />
                          
                            </div>
                        </div>
                    </div>

                </div>

        </div>
        <div className="mt-5 cm-col text-right">
              <button disabled={isDisabledButton} className="cm-primary-btn" onClick={() => validateClass()}>Submit</button>
            </div>
      </div>
    </div>
  )
}
