import { useContext, useEffect, useState  } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
export default function Routings(props) {
    const [editFlag ,setEditFlag] = useState(false);
    const [jsonData ,setJsonData] = useState({});
    const [routingData ,setRoutingData] = useState({});



    const selectEdit = () => {
        setEditFlag(true)
        setJsonData(props.classData)
        setRoutingData(props.classData.routing)
    }


    return (
        <div className="card cm-card">
            <div className="card-body">
            <div className="cm-card-edit">
                {/* {editFlag ?
                    <>
                        <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                        <button className="cm-primary-btn" onClick={(e)=>setEditFlag(false)}> Save</button>
                    </>
                :
                    <i onClick={(e)=>selectEdit(true)} class="far fa-edit"></i>
                } */}
            </div>
            <div className="row ">
                <div className="col-md-6">
                    <div className="cm-col">
                        <label className="cm-head-label">Airports Prohibited</label>
                        {!editFlag ?
                            <div className="cm-content">
                                {props.classData.routing && props.classData.routing.disallowedAirports.includes(null)  &&
                                    <input className="form-control cm-input" disabled />
                                }
                                {props.classData.routing && Object.values(props.classData.routing.disallowedAirports) }
                            </div>
                        :
                            <input 
                            className="form-control cm-input" 
                            
                            />
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="cm-col">
                        <label className="cm-head-label">Routing Info Source</label>
                        {!editFlag ?
                            <div className="cm-content">
                            {props.classData && props.classData.classCodeDesc == null &&
                                <input  className="form-control cm-input" disabled/>
                            }
                            {props.classData && props.classData.classCodeDesc  }
                            </div>
                        :
                            <input className="form-control cm-input" />
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="cm-col">
                        <label className="cm-head-label">Routing</label>
                        {!editFlag ?
                            <div className="cm-content">
                                {props.classData && props.classData.classRoutings  }
                                {props.classData && (props.classData.classRoutings ==  null) &&
                                <input
                                    className="form-control cm-input"
                                    disabled
                                /> 
                                }
                            </div>
                        :
                            <input className="form-control cm-input"/>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="cm-col">
                        <label className="cm-head-label">Class of services of interline Carrier</label>
                        {!editFlag ?
                            <div className="cm-content">
                                {props.classData && props.classData.otherClassOfServices  }
                                {props.classData && (props.classData.otherClassOfServices ==  null) &&
                                <input
                                    className="form-control cm-input"
                                    disabled
                                /> 
                                }
                            </div>
                        :
                            <input className="form-control cm-input" />
                        }
                    </div>
                </div>
            </div>

        </div>
    </div>
    )
}
