import {
    LASTEVL_KEY,
  } from "../constant/types";
  
  // LOGIN ACTION
  export const  lastEvlKeyAction = (agency:any) => ({
      
    type: LASTEVL_KEY,
    payload: agency,
  }
  
  );
 export default lastEvlKeyAction;
