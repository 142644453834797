import React, { forwardRef } from "react";
import { FormControlLabel, Checkbox} from '@material-ui/core';


export const CheckBox = forwardRef(({color='primary', checked=false, onChange=()=>{}, disabled=false, label="CheckBox"}:any, ref) => {
    return(
        <>
            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={color}
                                        checked={checked}
                                        onChange={onChange}
                                        disabled={disabled}
                                        {...ref}
                                    />
                                }
                                {...ref}
                                label={label}
                            />

        </>
    )
})