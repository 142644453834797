import axios from 'axios';
import swal from 'sweetalert';

export const getcontracts = async (payload: any) => {
        let url: any = process.env.REACT_APP_CONTRACT_URL + `/searchAllV2`;
        const requestOptions: any = {
            headers: {
                "Content-type": "application/json",
            },
        }
        const getContracts:any = await axios.post(url, payload,requestOptions)

        if (getContracts === null) {
            swal({
                text:"Search Failed",
                icon: "error",
              })
        }
        return getContracts?.data;
    
}