import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup, Checkbox, FormGroup, TextField } from "@mui/material";
import SunDeleteConfrm from "./SunDeleteConfirm";
import icon_filter from '../../_theme/_assets/images/icon_filter.svg';
import { useDispatch, useSelector } from "react-redux";
import { getAllSearchCriteria } from "../bookingConfirmation/thunks/getAllSearchCriteria";
import { deleteSearchCriteria } from "../bookingConfirmation/thunks/deleteSearchCriteria";
import moment from "moment";
import { createSearchCriteria } from "../bookingConfirmation/thunks/createSearchCriteria";
import { updateSearchCriteria } from "../bookingConfirmation/thunks/updateSearchCriteria";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { changeDateFormat } from "../bookingConfirmation/Utils/CommenFunction";

export const SunquestAdminList = () => {

    const dispatch = useDispatch();
    const [days, setDays] = useState({
        MONDAY: false,
        TUESDAY: false,
        WEDNESDAY: false,
        THURSDAY: false,
        FRIDAY: false,
        SATURDAY: false,
        SUNDAY: false,
    });
    const [daysShow, setDaysShow] = React.useState(false)
    const [addNew, setAddNew] = React.useState(false)
    const [fieldEdit, setfieldEdit] = React.useState(false)
    const [searchCiteria, setSearchCiteria] = useState([])
    const [id, setId] = useState("")
    const [origin, setOrigin] = useState("")
    const [destination, setDestination] = useState("")
    const [departureStart, setDepartureStart] = useState("")
    const [departureEnd, setDepartureEnd] = useState("")
    const [maxStay, setMaxStay] = useState(1)
    const [repeats, setRepeats] = useState<any>([])
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState(String(moment(new Date()).format("DD-MMM-YYYY")));
    const [endDate, setEndDate] = useState(String(moment(new Date()).format("DD-MMM-YYYY")));

    const correlationId = useSelector((state:any) => state.searchForm.uuid);

    const handleSetDays = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDays({ ...days, [event.target.name]: event.target.checked });
    };
    const handleDaysShow = (event: React.ChangeEvent<HTMLInputElement>) => {
        (event.target as HTMLInputElement).value === 'selectedDays' ? setDaysShow(true) : setDaysShow(false);
        setRepeats([])
        if((event.target as HTMLInputElement).value != "selectedDays" && (!repeats.includes("NEVER") || !repeats.includes("EVERYDAY"))){
            let temp:any = []
            temp.push((event.target as HTMLInputElement).value)
            setRepeats([...temp])
        }
        if((event.target as HTMLInputElement)?.value === 'NEVER' || (event.target as HTMLInputElement)?.value === 'EVERYDAY'){
            setDays(
                {
                    MONDAY: false,
                    TUESDAY: false,
                    WEDNESDAY: false,
                    THURSDAY: false,
                    FRIDAY: false,
                    SATURDAY: false,
                    SUNDAY: false,
                }
            )
        }
    };
    const handleAddNew = () => {
        setAddNew(!addNew);
        resetLocalState();
    }
    const handleAEdit = (search:any) => {
        setId(search?.id)
        setfieldEdit(!fieldEdit);
        setOrigin(search?.origin);
        setDestination(search?.destination);
        setMaxStay(search?.maxStay);
        setRepeats(search?.repeats)
        if(search?.repeats?.includes("MONDAY") || search?.repeats?.includes("TUESDAY") || search?.repeats?.includes("WEDNESDAY") || search?.repeats?.includes("THURSDAY") || search?.repeats?.includes("FRIDAY") || search?.repeats?.includes("SATURDAY") || search?.repeats?.includes("SUNDAY")){
            setDays(
                {
                    MONDAY: search?.repeats?.includes("MONDAY"),
                    TUESDAY: search?.repeats?.includes("TUESDAY"),
                    WEDNESDAY: search?.repeats?.includes("WEDNESDAY"),
                    THURSDAY: search?.repeats?.includes("THURSDAY"),
                    FRIDAY: search?.repeats?.includes("FRIDAY"),
                    SATURDAY: search?.repeats?.includes("SATURDAY"),
                    SUNDAY: search?.repeats?.includes("SUNDAY"),
                }
            )
        }
        setDepartureStart(search?.deptStarts)
        setDepartureEnd(search?.deptEnds)
    }

    const exitEditmMode = () => {
        setId("")
        setfieldEdit(!fieldEdit);
    }

    const getSearcgCiteriaList = async()=>{
        let allSearchCriteria:any = await dispatch(getAllSearchCriteria())
        setSearchCiteria(allSearchCriteria)
        // if(searchCiteria?.length){
            setLoading(false)
        // }
    }
    useEffect(()=>{
        getSearcgCiteriaList()
        setfieldEdit(false);
        resetLocalState();
    },[])

    const deleteRecord = async(id:any) => {
        let deleteOp:any = await deleteSearchCriteria(id, correlationId)
        if(deleteOp){
            getSearcgCiteriaList()
        }else{
            // toast error that deletion operation failed
        }
    }

    const setOriginHandle = (val:any) => {
        setOrigin(val)
    }
    const setDestinationHandle = (val:any) =>{
        setDestination(val)
    }

    const setDepartureStartDateHandle = (date:any) => {
        setDepartureStart(String(moment(new Date(date)).format("DD-MMM-YYYY")))
    }

    const setDepartureEndDateHandle = (date:any) => {
        setDepartureEnd(String(moment(new Date(date)).format("DD-MMM-YYYY")))
    }

    const setMaxStayHandle = (val:any) => {
        setMaxStay(Number(val))
    }

    const setRepeatsHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        let temp:any = [...repeats]
        if(temp.includes((event.target as HTMLInputElement).name)){
            temp.splice(temp.indexOf((event.target as HTMLInputElement).name), 1)
        }else{
            temp.push((event.target as HTMLInputElement).name)
        }
        setRepeats([...temp])
    }

    const createSearchCiteria = async () => {
        const payload =
        {
            "origin": origin,
            "destination": destination,
            "maxStay": maxStay,
            "repeats": repeats,
            "deptStarts": startDate,
            "deptEnds": endDate
        }
        const createOperation:any =  await createSearchCriteria(payload, correlationId)
        if(createOperation?.data?.id && createOperation?.data?.message == "Success"){
            getSearcgCiteriaList()
            resetLocalState()
        }
        else{
            // toast error msg
        }
        setAddNew(!addNew);
    }

    const updateSearchCri = (search:any) => {
        setLoading(true)
        const payload =
        {
            "origin": origin,
            "destination": destination,
            "maxStay": maxStay,
            "repeats": repeats,
            "deptStarts": departureStart,
            "deptEnds": departureEnd
        }
        
        let updateOperation:any = updateSearchCriteria(payload, search?.id, correlationId)
        if(updateOperation){
            setTimeout(() => {
                getSearcgCiteriaList()
              }, 1000);
            
            resetLocalState();
            setId("")
            setfieldEdit(!fieldEdit);
        }
    }

    const resetLocalState = () => {
        setOrigin("");
        setDestination("");
        setMaxStay(1);
        setRepeats([""])
        setDepartureStart("")
        setDepartureEnd("")
        setDays(
            {
                MONDAY: false,
                TUESDAY: false,
                WEDNESDAY: false,
                THURSDAY: false,
                FRIDAY: false,
                SATURDAY: false,
                SUNDAY: false,
            }
        )
    }

    return (
        <>
            <div className="sunquestAdminList bgNone">
                <div className="card cm-card bgNone">
                    <div className="card-header cm-card-head-btn  bgNone">
                        <div>
                            <button className="cm-secondary-btn mr-3 btn-sort"> <i className="fas fa-exchange-alt"></i>Sort by</button>
                            <button className="cm-secondary-btn filterBtn"> <img src={icon_filter}/>Filter</button>
                        </div>
                        <button className="cm-yellow-btn" onClick={handleAddNew}>Add New</button>
                    </div>
                    <hr className="m-0" />
                    {addNew &&
                        <div className="bgWhite">
                            <div className="table-responsive-sm">
                                <table role="table" className="table">
                                    <thead>
                                        <tr>
                                            <th  ><i className="far fa-circle" aria-hidden="true"></i>Origin</th>
                                            <th  ><i className="fas fa-map-marker-alt" aria-hidden="true"></i>Destination</th>
                                            <th  ><i className="far fa-calendar-alt" aria-hidden="true"></i>Departure Starts</th>
                                            <th  ><i className="far fa-calendar-alt" aria-hidden="true"></i>Departure Ends</th>
                                            <th  ><i className="fas fa-home" aria-hidden="true"></i>Max Stay</th>
                                            <th  ><i className="fas fa-retweet" aria-hidden="true"></i>Repeat</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr className="bgWhite">
                                            <td className="Origin">  <TextField type="text" variant="outlined" className="form-control" value={origin} onChange={(e)=>setOriginHandle(String(e.target.value).toUpperCase())}/></td>
                                            <td className="Origin">  <TextField type="text" variant="outlined" className="form-control" value={destination} onChange={(e)=>setDestinationHandle(String(e.target.value).toUpperCase())} /></td>
                                            <td >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        value={startDate}
                                                        onChange={(date: any) => setStartDate(String(moment(new Date(date)).format("DD-MMM-YYYY")))}
                                                        className="form-control"
                                                        format="dd MMM yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>                                                
                                                {/* <DatePicker id="date" className="form-control" onChange={(e)=>setDepartureStartDateHandle(e.target.value)}/> */}
                                            </td>
                                            <td >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        value={endDate}
                                                        onChange={(date: any) => setEndDate(String(moment(new Date(date)).format("DD-MMM-YYYY")))}
                                                        className="form-control"
                                                        format="dd MMM yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {/* <DatePicker id="date2" className="form-control" onChange={(e)=>setDepartureEndDateHandle(e.target.value)}/> */}
                                            </td>
                                            <td >
                                                <select className="form-control" onChange={(e)=>setMaxStayHandle(e.target.value)}>
                                                    {[...Array(10)].map((_, index) => (
                                                        <option key={index + 1} value={index + 1}>{index + 1}</option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td >

                                                <RadioGroup aria-label="position" className="sun__radioBtn" name="position" defaultValue="Never" onChange={handleDaysShow}>
                                                    <FormControlLabel value="NEVER" control={<Radio color="primary" />} label="Never" />
                                                    <FormControlLabel value="EVERYDAY" control={<Radio color="primary" />} label="Everyday" />
                                                    <FormControlLabel value="selectedDays" control={<Radio color="primary" />} label="Selected Days" />
                                                </RadioGroup>
                                                {daysShow &&
                                                    <FormGroup className="sun__checkBox" style={{"marginLeft":"1.6rem"}} onChange={setRepeatsHandle}>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={days.MONDAY} onChange={handleSetDays} name="MONDAY" color="primary" />} label="Monday" />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={days.TUESDAY} onChange={handleSetDays} name="TUESDAY" color="primary" />} label="Tuesday" />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={days.WEDNESDAY} onChange={handleSetDays} name="WEDNESDAY" color="primary" />} label="Wednesday" />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={days.THURSDAY} onChange={handleSetDays} name="THURSDAY" color="primary" />} label="Thursday" />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={days.FRIDAY} onChange={handleSetDays} name="FRIDAY" color="primary" />} label="Friday" />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={days.SATURDAY} onChange={handleSetDays} name="SATURDAY" color="primary" />} label="Saturday" />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={days.SUNDAY} onChange={handleSetDays} name="SUNDAY" color="primary" />} label="Sunday" />
                                                    </FormGroup>
                                                }
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan={6} className="text-center">
                                                <button className="cm-secondary-btn mr-2 startBtn" onClick={createSearchCiteria}><i className="fa fa-save"></i> Save</button>
                                                <button className="cm-secondary-btn cancelBtn" onClick={handleAddNew}><i className="far fa-window-close" ></i>  Cancel</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <div className="table-responsive-sm">
                        <table role="table" className="table">
                            <thead>
                                <tr  >
                                    <th className="Origin"><i className="far fa-circle" aria-hidden="true"></i>Origin</th>
                                    <th  ><i className="fas fa-map-marker-alt" aria-hidden="true"></i>Destination</th>
                                    <th  ><i className="far fa-calendar-alt" aria-hidden="true"></i>Departure Starts</th>
                                    <th  ><i className="far fa-calendar-alt" aria-hidden="true"></i>Departure Ends</th>
                                    <th  ><i className="fas fa-home" aria-hidden="true"></i>Max Stay</th>
                                    <th  ><i className="fas fa-retweet" aria-hidden="true"></i>Repeat</th>
                                </tr>
                            </thead>
                            {loading?
                            <div style={{"marginLeft":"1rem"}}>LOADING...</div>:
                            <>{searchCiteria.map((search:any)=>{ return(
                                    <tr className="bgWhite">
                                    <td>{id == search?.id ? <TextField id={search?.id} type="text" variant="outlined" className="form-control" value={origin} onChange={(e)=>setOriginHandle(e?.target?.value)}/> : search?.origin}</td>
                                    <td >{id == search?.id ? <TextField id={search?.id} type="text" variant="outlined" className="form-control" value={destination} onChange={(e)=>setDestinationHandle(e?.target?.value)}/> : search?.destination}</td>
                                    <td >{id == search?.id ?  
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                value={departureStart}
                                                onChange={(date: any) => setDepartureStartDateHandle(date)}
                                                className="form-control"
                                                format="dd MMM yyyy"
                                            />
                                        </MuiPickersUtilsProvider>
                                    // <DatePicker id={search?.id}  value={departureStart} className="form-control" onChange={(date)=>setDepartureStartDateHandle(date)}/> 
                                    : search?.deptStarts}</td>
                                    <td >{id == search?.id ? 
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                value={departureEnd}
                                                onChange={(date: any) => setDepartureEndDateHandle(date)}
                                                className="form-control"
                                                format="dd MMM yyyy"
                                            />
                                        </MuiPickersUtilsProvider>
                                    
                                    // <DatePicker id={search?.id} value={departureEnd } className="form-control" onChange={(date=>setDepartureEndDateHandle(date))}/> 
                                    : search?.deptEnds}</td>
                                    <td >
                                        {id == search?.id ?
                                            <>
                                                <select id={search?.id} className="form-control" value={maxStay} onChange={(e) => setMaxStayHandle(e.target.value)}>
                                                    {[...Array(10)].map((_, index) => (
                                                        <option key={index + 1} value={index + 1}>{index + 1}</option>
                                                    ))}
                                                </select>
                                            </>
                                        : search?.maxStay}
                                    </td>
                                    <td >
                                        {id != search?.id ? <>{search?.repeats.map((repeat:any)=>{return(<div>{repeat}</div>);})}</>:<>
                                        <RadioGroup id={search?.id} aria-label="position" className="sun__radioBtn" name="position" onChange={handleDaysShow} defaultValue={search?.id == undefined ? repeats.length == 0 ? "Never" : (repeats.length == 6)? "end": "selectedDays" : search?.repeats.length == 1 ? String(search?.repeats[0]).toUpperCase() : "selectedDays"}>
                                            <FormControlLabel id={search?.id} value="NEVER" control={<Radio color="primary" />} label="Never" disabled={id != search?.id}/>
                                            <FormControlLabel id={search?.id} value="EVERYDAY" control={<Radio color="primary" />} label="Everday" disabled={id != search?.id}/>
                                            <FormControlLabel id={search?.id} value="selectedDays" control={<Radio color="primary" />} label="Selected Days" disabled={id != search?.id}/>
                                        </RadioGroup>
                                            {(daysShow || Object.values(days).includes(true)) &&
                                            <FormGroup id={search?.id} className="sun__checkBox"  style={{"marginLeft":"1.6rem"}} onChange={setRepeatsHandle} >
                                                <FormControlLabel
                                                    control={<Checkbox id={search?.id} checked={days.MONDAY} onChange={handleSetDays} name="MONDAY" color="primary" />} label="Monday" />
                                                <FormControlLabel
                                                    control={<Checkbox id={search?.id} checked={days.TUESDAY} onChange={handleSetDays} name="TUESDAY" color="primary" />} label="Tuesday"/>
                                                <FormControlLabel
                                                    control={<Checkbox id={search?.id} checked={days.WEDNESDAY} onChange={handleSetDays} name="WEDNESDAY" color="primary" />} label="Wednesday"/>
                                                <FormControlLabel
                                                    control={<Checkbox id={search?.id} checked={days.THURSDAY} onChange={handleSetDays} name="THURSDAY" color="primary" />} label="Thursday"/>
                                                <FormControlLabel
                                                    control={<Checkbox id={search?.id} checked={days.FRIDAY} onChange={handleSetDays} name="FRIDAY" color="primary" />} label="Friday" />
                                                <FormControlLabel
                                                    control={<Checkbox id={search?.id} checked={days.SATURDAY} onChange={handleSetDays} name="SATURDAY" color="primary" />} label="Saturday" />
                                                <FormControlLabel
                                                    control={<Checkbox id={search?.id} checked={days.SUNDAY} onChange={handleSetDays} name="SUNDAY" color="primary" />} label="Sunday" />
                                            </FormGroup>
                                            }</>
                                    }
                                    </td>
                                    <td className="edit-border">
                                        <div className="cm-edidel-btns">
                                            {id == search?.id ?
                                                <>
                                                    <button id={search?.id} className="saveBtn mr-2" onClick={()=>updateSearchCri(search)}><i className="fa fa-save"></i></button>
                                                    <button id={search?.id} className="closeBtn"  onClick={exitEditmMode}><i className="fas fa-times-circle"></i></button>
                                                </>:
                                                <>
                                                    <button className="cm-edit-btn mr-2" onClick={()=>handleAEdit(search)} id={search?.id}>
                                                        <i className="fas fa-edit"></i>
                                                    </button>
                                                    <SunDeleteConfrm deleteRecord={deleteRecord} id={search?.id}/>
                                                </>
                                                 
                                            }
                                        </div>
                                    </td>
                                </tr>)
                                })}</>}
                        </table>
                    </div>

                </div>
            </div>

        </>
    )
}