import { useState, useContext,useEffect } from "react";
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import authContext from '../../../context/auth-context'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSelector ,useDispatch } from "react-redux";
import allContractSearch from "../../../appStore/actions/allContractSearch";
import lastEvlKeyAction from '../../../appStore/actions/lastEvlKeyAction';
import { getCommonDataForTariff } from "../../../pages/bookingConfirmation/thunks/getCommonDataForTariff";
import { setBrandedFareTariff, setBrandIdClassTariff, setCabinTypeTariff, setCarrierTariff, setCategoryClassTariff, setClassClassTariff, setClassKeyClassTariff, setCruiseFareTypeTariff, setDescClassTariff, setEDRLastTicketingDatePrix, setEDRStartClassTariff, setEDRTicketingTariff, setERDEndClassTariff, setFareTypeClassTariff, setKeyTariff, setLandFareTypeTariff, setLastReturnDatePrix, setNoteClassTariff, setNotePrix, setPrixKeyPrix, setTripTypeTariff, setValidityFromPrix, setValidityPrixToPrix, setViaTariff } from "../../AppStore/ActionCreator/commonDataAction";

export default function AllSearch() {

  const dispatch = useDispatch();
  let allContractData = useSelector((state) => state.allContractData); 
 

  // dispatch(allContractSearch([]))
  // dispatch(lastEvlKeyAction([{key:"1"}]))
  const [lastEvlKey, setLastEvlKey] = useState([])
  const [previousLastEvlKey, setPreviousLastEvlKey] = useState('')



  const [selectedClassIndex, setSelectedClassIndex] = useState('')
  const [showClass, setShowClass] = useState(false)

  const [selectedPrixIndex, setSelectedPrixIndex] = useState('')
  const [showPrix, setShowPrix] = useState(false)
  

  const selectClassFunction = (index) => {
    setSelectedClassIndex(index)
    setShowClass(!showClass)
  }

  const selectPrixFunction = (index) => {
    setSelectedPrixIndex(index)
    setShowPrix(!showPrix)
  }


  // VARIABLE FOR STORE LIST
  // const [serchFor, setSerchFor] = useState("tariff");
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();

  // SEARCH PARAMETER
  const [carrier, setCarrier] = useState("");
  const [tarifKey, setTarifKey] = useState("");
  const [via, setVia] = useState("");
  const [passengerType, setPassengerType] = useState("");
  const [isBrandedFare, setIsBrandedFare] = useState("");

  const [tripType, setTripType] = useState("");
  const [cabinType, setCabinType] = useState("");
  const [cruiseFare, setCruiseFare] = useState("");
  const [landFare, setLandFare] = useState("");
  const [originType, setOriginType] = useState("");
  const [originValue, setoriginValue] = useState("");

  const [destinationType, setDestinationType] = useState("");
  const [destinationValue, setDestinationValue] = useState("");
  const [noDataFlag, SetNoDataFlag] = useState(false)

  // CLASS SEARCH
  const [brandId, setBrandId] = useState("");
  const [category, setCategory] = useState("");
  const [clasCodeDesc, setClasCodeDesc] = useState("");
  const [note, setNote] = useState("");
  const [contractType, setContractType] = useState("");
  const [classCodes, setClassCodes] = useState("");
  const [lastTicketingDate, setLastTicketingDate] = useState("");
  const [classKey, setClassKey] = useState("");

  // PRIX SEARCH
  const [prixnote, setPrixNote] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [lastReturnDate, setLastReturnDate] = useState("");
  const [lastTicketingDatePrix, setLastTicketingDatePrix] = useState("");
  const [prixKey, setPrixKey] = useState("");

  // PAGINATION PARAMETER
  const [isLast, setIsLast] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [counter, setCounter] = useState(0);
  const [lastArry, setLastArry] = useState([])
  const [instialFirstVal, setInstialFirstVal] = useState(100)
  const [instialSecondVal, setInstialSecondVal] = useState(200)
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);



  // const { orgId } = useContext(authContext);
  const [searchUrl, setSearchUrl] = useState('');
  const [pagiToken, setPagiToken] = useState('');


  const [classLastTicketingDateFrom, setClassLastTicketingDateFrom] = useState('')
  const [classLastTicketingDateTo, setClassLastTicketingDateTo] = useState('')
  const [classData, setClassData] = useState([])
  const [allprixData, setAllprixData] = useState([])

  const [allSearchData, setallSearchData] = useState([])

  
  //FUNCTION FOR REDIRECT TO CREATE CONTRACT PAGE
  const  createContrat = () =>{
    history.push(`/Create-contract`);
  }
  const [commonData , setCommonData] = useState([])

  //Tariff Search
  const dataTariff = useSelector((state) => state.commonDataReducer.commonDataTarrif);
  const carrierTariff = useSelector((state) => state.commonDataReducer.carrierTariff);
  const keyTariff = useSelector((state) => state.commonDataReducer.keyTariff);
  const viaTariff = useSelector((state) =>  state.commonDataReducer.viaTariff);
  const cabinTypeTariff = useSelector((state) => state.commonDataReducer.cabinTypeTariff);
  const tripTypeTariff = useSelector((state) => state.commonDataReducer.tripTypeTariff);
  const landFareTariff = useSelector((state) => state.commonDataReducer.landFareTariff);
  const cruiseFareTariff = useSelector((state) => state.commonDataReducer.cruiseFareTariff);
  const brandedFareTariff = useSelector((state) => state.commonDataReducer.brandedFareTariff);
  
  //Class Search
  const brandIdClassTariff = useSelector((state) => state.commonDataReducer.brandIdClassTariff);
  const classeClassTariff = useSelector((state) => state.commonDataReducer.classeClassTariff);
  const edrLastTickitingTariff = useSelector((state) => state.commonDataReducer.edrLastTickitingTariff);
  const categoryClassTariff = useSelector((state) => state.commonDataReducer.categoryClassTariff);
  const descClassTariff = useSelector((state) => state.commonDataReducer.descClassTariff);
  const classKeyClassTariff = useSelector((state) => state.commonDataReducer.classKeyClassTariff);
  const noteClassTariff = useSelector((state) => state.commonDataReducer.noteClassTariff);
  const fareTypeClassTariff = useSelector((state) => state.commonDataReducer.fareTypeClassTariff);
  const edrStartDateTarrif = useSelector((state) => state.commonDataReducer.edrStartDateTarrif);
  const edrLastDateTariff = useSelector((state) => state.commonDataReducer.edrLastDateTariff);
  
  //Prix Search
  const validityFromPrixTariff = useSelector((state) => state.commonDataReducer.validityFromPrixTariff);
  const validityToPrixTariff = useSelector((state) => state.commonDataReducer.validityToPrixTariff);
  const lastReturnDatePrixTariff = useSelector((state) => state.commonDataReducer.lastReturnDatePrixTariff);
  const edrLastTickitingDatePrixTariff = useSelector((state) => state.commonDataReducer.edrLastTickitingDatePrixTariff);
  const prixKeyPrixTariff = useSelector((state) => state.commonDataReducer.prixKeyPrixTariff);
  const notePrixTariff = useSelector((state) => state.commonDataReducer.notePrixTariff);
  const edrStartDatePrixTariff = useSelector((state) => state.commonDataReducer.edrStartDatePrixTariff);
  const edrEndDatePrixTariff = useSelector((state) => state.commonDataReducer.edrEndDatePrixTariff);



  useEffect(()=>{
    if(commonData.length>0)
    dispatch(getCommonDataForTariff(commonData))
  },[commonData])

  useEffect(() => {
    //Tariff Search
    setTarifKey(keyTariff)
    setCarrier(carrierTariff)
    setVia(viaTariff)
    setCabinType(cabinTypeTariff)
    setTripType(tripTypeTariff)
    setLandFare(landFareTariff)
    setCruiseFare(cruiseFareTariff)
    setIsBrandedFare(brandedFareTariff)
    
    //Class Search
    setBrandId(brandIdClassTariff)
    setClassCodes(classeClassTariff)
    setLastTicketingDate(edrLastTickitingTariff)
    setCategory(categoryClassTariff)
    setClasCodeDesc(descClassTariff)
    setClassKey(classKeyClassTariff)
    setNote(noteClassTariff)
    setContractType(fareTypeClassTariff)
    setClassLastTicketingDateFrom(edrStartDateTarrif)
    setClassLastTicketingDateTo(edrLastDateTariff)

    //Prix Search
    setValidFrom(validityFromPrixTariff)
    setValidTo(validityToPrixTariff)
    setLastReturnDate(lastReturnDatePrixTariff)
    setLastTicketingDatePrix(edrLastTickitingDatePrixTariff)
    setPrixKey(prixKeyPrixTariff)
    setPrixNote(notePrixTariff)


  },[])
 
   const [isLastEvlKeyPresent , setIsLastEvlKeyPresent] = useState(false)
  // SEARCH API CALL FUNCTION 
  const tariffSearchApiCall = () => {
    setIsLastEvlKeyPresent(false)
    setLastArry([])
    setallSearchData([])
    setClassData([])
    setAllprixData([])
    setCounter(0)
    setTotalPage(0)
    setPage(1)
    setInstialFirstVal(100)
    setInstialSecondVal(200)

    //Tariff Search
    dispatch(setKeyTariff(tarifKey))
    dispatch(setCarrierTariff(carrier))
    dispatch(setViaTariff(via))
    dispatch(setCabinTypeTariff(cabinType))
    dispatch(setTripTypeTariff(tripType))
    dispatch(setLandFareTypeTariff(landFare))
    dispatch(setCruiseFareTypeTariff(cruiseFare))
    dispatch(setBrandedFareTariff(isBrandedFare))

    //Class Search
    dispatch(setBrandIdClassTariff(brandId))
    dispatch(setClassClassTariff(classCodes))
    dispatch(setEDRTicketingTariff(lastTicketingDate))
    dispatch(setCategoryClassTariff(category))
    dispatch(setDescClassTariff(clasCodeDesc))
    dispatch(setNoteClassTariff(note))
    dispatch(setClassKeyClassTariff(classKey))
    dispatch(setFareTypeClassTariff(contractType))
    dispatch(setEDRStartClassTariff(classLastTicketingDateFrom))
    dispatch(setERDEndClassTariff(classLastTicketingDateTo))

    //Prix Search
    dispatch(setValidityFromPrix(validFrom))
    dispatch(setValidityPrixToPrix(validTo))
    dispatch(setLastReturnDatePrix(lastReturnDate))
    dispatch(setEDRLastTicketingDatePrix(lastTicketingDatePrix))
    dispatch(setPrixKeyPrix(prixKey))
    dispatch(setNotePrix(prixnote))

    let strUrl = 'searchAll?'
    setIsLoading(true)
    if (carrier !== "") {
      let carrierValue

      if (strUrl.length < 11) {
        carrierValue = "carrier=" + carrier
      } else {
        carrierValue = "&carrier=" + carrier
      }
      strUrl = strUrl + carrierValue
    }

    if (tripType !== "") {
      let tripTypeValue
      if (strUrl.length < 11) {
        tripTypeValue = "tripType=" + tripType
      } else {
        tripTypeValue = "&tripType=" + tripType
      }
      strUrl = strUrl + tripTypeValue
    }

    if (cabinType !== "") {
      let cabinTypeValue
      if (strUrl.length < 11) {
        cabinTypeValue = "cabinType=" + cabinType
      } else {
        cabinTypeValue = "&cabinType=" + cabinType
      }
      strUrl = strUrl + cabinTypeValue
    }

    if (tarifKey !== "") {
      let tarifKeyValue
      if (strUrl.length < 11) {
        tarifKeyValue = "tarifKey=" + tarifKey
      } else {
        tarifKeyValue = "&tarifKey=" + tarifKey
      }
      strUrl = strUrl + tarifKeyValue
    }

    if (via !== "") {
      let viaValue
      if (strUrl.length < 11) {
        viaValue = "via=" + via

      } else {
        viaValue = "&via=" + via
      }
      strUrl = strUrl + viaValue
    }

    if (classLastTicketingDateFrom !== "") {
      let classLastTicketingDateFromVar
      if (strUrl.length < 11) {
        classLastTicketingDateFromVar = "classLastTicketingDateFrom=" + classLastTicketingDateFrom
      } else {
        classLastTicketingDateFromVar = "&classLastTicketingDateFrom=" + classLastTicketingDateFrom
      }
      strUrl = strUrl + classLastTicketingDateFromVar
    }

    if (classLastTicketingDateTo !== "") {
      let classLastTicketingDateTovar
      if (strUrl.length < 11) {
        classLastTicketingDateTovar = "classLastTicketingDateTo=" + classLastTicketingDateTo
      } else {
        classLastTicketingDateTovar = "&classLastTicketingDateTo=" + classLastTicketingDateTo
      }
      strUrl = strUrl + classLastTicketingDateTovar
    }


    if (isBrandedFare !== "") {
      let isBrandedFareValue
      let brandedFareData
      if (isBrandedFare === "1") {
        brandedFareData = true
      }
      if (isBrandedFare === "0") {
        brandedFareData = false
      }


      if (strUrl.length < 13) {
        isBrandedFareValue = "isBrandedFare=" + brandedFareData
      } else {
        isBrandedFareValue = "&isBrandedFare=" + brandedFareData
      }
      strUrl = strUrl + isBrandedFareValue
    }

    if (originType !== "") {
      let originValueValue
      if (strUrl.length < 11) {
        originValueValue = originType + "=" + originValue
      } else {
        originValueValue = "&" + originType + "=" + originValue
      }
      strUrl = strUrl + originValueValue
    }

    if (destinationType !== "") {
      let destinationValueValue
      if (strUrl.length < 11) {
        destinationValueValue = destinationType + "=" + destinationValue
      } else {
        destinationValueValue = "&" + destinationType + "=" + destinationValue
      }
      strUrl = strUrl + destinationValueValue
    }

    if (cruiseFare !== "") {
      if (cruiseFare) {
        let cruiseFareValue
        if (strUrl.length < 11) {
          cruiseFareValue = "cruiseFare=1"
        } else {
          cruiseFareValue = "&cruiseFare=1"
        }
        strUrl = strUrl + cruiseFareValue
      } else {
        let cruiseFareValue
        if (strUrl.length < 13) {
          cruiseFareValue = "cruiseFare=0"

        } else {
          cruiseFareValue = "&cruiseFare=0"
        }
        strUrl = strUrl + cruiseFareValue
      }
    }

    if (landFare !== "") {
      if (landFare) {
        let landFareValue
        if (strUrl.length < 11) {
          landFareValue = "landFare=1"
        } else {
          landFareValue = "&landFare=1"
        }

        strUrl = strUrl + landFareValue
      } else {
        let landFareValue
        if (strUrl.length < 13) {
          landFareValue = "landFare=0"

        } else {
          landFareValue = "&landFare=0"

        }
        strUrl = strUrl + landFareValue
      }
    }

    if (brandId !== "") {
      let brandIdValue
      if (strUrl.length < 11) {
        brandIdValue = "brandCode=" + brandId
      } else {
        brandIdValue = "&brandCode=" + brandId
      }
      strUrl = strUrl + brandIdValue
    }

    if (category !== "") {
      let categoryValue
      if (strUrl.length < 11) {
        categoryValue = "category=" + category
      } else {
        categoryValue = "&category=" + category
      }
      strUrl = strUrl + categoryValue
    }

    if (clasCodeDesc !== "") {
      let categoryValue
      if (strUrl.length < 11) {
        categoryValue = "classCodeDesc=" + clasCodeDesc
      } else {
        categoryValue = "&classCodeDesc=" + clasCodeDesc
      }
      strUrl = strUrl + categoryValue
    }

    if (note !== "") {
      let noteValue
      if (strUrl.length < 11) {
        noteValue = "classNote=" + note
      } else {
        noteValue = "&classNote=" + note
      }
      strUrl = strUrl + noteValue
    }

    if (contractType !== "") {
      let contractTypeValue
      if (strUrl.length < 11) {
        contractTypeValue = "contractType=" + contractType
      } else {
        contractTypeValue = "&contractType=" + contractType
      }
      strUrl = strUrl + contractTypeValue
    }

    if (classLastTicketingDateFrom !== "") {
      let classLastTicketingDateFromVar
      if (strUrl.length < 11) {
        classLastTicketingDateFromVar = "classLastTicketingDateFrom=" + classLastTicketingDateFrom
      } else {
        classLastTicketingDateFromVar = "&classLastTicketingDateFrom=" + classLastTicketingDateFrom
      }
      strUrl = strUrl + classLastTicketingDateFromVar
    }

    if (classLastTicketingDateTo !== "") {
      let classLastTicketingDateTovar
      if (strUrl.length < 11) {
        classLastTicketingDateTovar = "classLastTicketingDateTo=" + classLastTicketingDateTo
      } else {
        classLastTicketingDateTovar = "&classLastTicketingDateTo=" + classLastTicketingDateTo
      }
      strUrl = strUrl + classLastTicketingDateTovar
    }

    if (classCodes !== "") {
      let classCodesValue
      if (strUrl.length < 11) {
        classCodesValue = "classCodes=" + classCodes
      } else {
        classCodesValue = "&classCodes=" + classCodes
      }
      strUrl = strUrl + classCodesValue
    }

    if (lastTicketingDate !== "") {
      let lastTicketingDateValue
      if (strUrl.length < 11) {
        lastTicketingDateValue = "lastTicketingDate=" + lastTicketingDate
      } else {
        lastTicketingDateValue = "&lastTicketingDate=" + lastTicketingDate
      }
      strUrl = strUrl + lastTicketingDateValue
    }

    if (classKey !== "") {
      let classKeyValue
      if (strUrl.length < 11) {
        classKeyValue = "clasKey=" + classKey
      } else {
        classKeyValue = "&clasKey=" + classKey
      }
      strUrl = strUrl + classKeyValue
    }


    if (prixnote !== "") {
      let noteValue
      if (strUrl.length < 11) {
        noteValue = "prixNote=" + prixnote
      } else {
        noteValue = "&prixNote=" + prixnote
      }
      strUrl = strUrl + noteValue
    }

    if (validFrom !== "") {
      let validFromValue
      if (strUrl.length < 11) {
        validFromValue = "validFrom=" + validFrom
      } else {
        validFromValue = "&validFrom=" + validFrom
      }
      strUrl = strUrl + validFromValue
    }

    if (validTo !== "") {
      let validToValue
      if (strUrl.length < 11) {
        validToValue = "validTo=" + validTo
      } else {
        validToValue = "&validTo=" + validTo
      }
      strUrl = strUrl + validToValue
    }

    if (lastReturnDate !== "") {
      let lastReturnDateValue
      if (strUrl.length < 11) {
        lastReturnDateValue = "prixLastReturnDate=" + lastReturnDate
      } else {
        lastReturnDateValue = "&prixLastReturnDate=" + lastReturnDate
      }
      strUrl = strUrl + lastReturnDateValue
    }

    if (lastTicketingDate !== "") {
      let lastTicketingDateValue
      if (strUrl.length < 11) {
        lastTicketingDateValue = "prixLastTicketingDate=" + lastTicketingDate
      } else {
        lastTicketingDateValue = "&prixLastTicketingDate=" + lastTicketingDate
      }
      strUrl = strUrl + lastTicketingDateValue
    }

    if (prixKey !== "") {
      let prixKeyValue
      if (strUrl.length < 11) {
        prixKeyValue = "prixKey=" + prixKey
      } else {
        prixKeyValue = "&prixKey=" + prixKey
      }
      strUrl = strUrl + prixKeyValue
    }

    setSearchUrl(strUrl)
    if (strUrl.length > 10) {
      fetch(`${process.env.REACT_APP_CONTRACT_URL}/${strUrl}`, {
        method: 'POST',
      })
        .then(response => response.json())
        .then(result => {
          if (result.Items.length === 0) {
            SetNoDataFlag(true)
          } else {
            SetNoDataFlag(false)
          }
          if(result?.LastEvaluatedKey == undefined){
            setIsLastEvlKeyPresent(true)
          }
          setPreviousLastEvlKey(result?.LastEvaluatedKey)
          setPagiToken(result?.token)
          
          setallSearchData(result?.Items)
          let data = result?.Items?.slice(0 , 25)     
          setCommonData(data)
          
          setTotalPage((result.Items.length / 25 ) + 2 )

        
            setIsLoading(false)
        })
        .catch(error => console.error("Error: ", error))
    } 
  }

  // FUNCTION FOR CLEAR SEARCH FROM VALUE
  const clearSearch = () => {
    setallSearchData([])
    setClassData([])
    setAllprixData([])
    SetNoDataFlag(false)
    setIsLoading(false)
    setTotalPage(0)
    setPage(1)
    setInstialFirstVal(100)
    setInstialSecondVal(200)
    setCarrier("");
    setTarifKey("")
    setVia("")
    setPassengerType("")
    setIsBrandedFare("")
    setTripType("")
    setCabinType("")
    setCruiseFare("")
    setLandFare("")
    setOriginType("")
    setoriginValue("")
    setDestinationType("")
    setDestinationValue("")
    setClassLastTicketingDateFrom('')
    setClassLastTicketingDateTo('')
    setPrixNote("");
    setValidFrom("");
    setValidTo("");
    setLastReturnDate("");
    setLastTicketingDate("")
    setPrixKey("")
    setBrandId("");
    setCategory("");
    setClasCodeDesc("");
    setNote("");
    setContractType("");
    setClassCodes("");
    setLastTicketingDate("");
    setClassKey("");
    setClassLastTicketingDateFrom('')
    setClassLastTicketingDateTo('')

    //Tariff Search
    dispatch(setKeyTariff(""))
    dispatch(setCarrierTariff(""))
    dispatch(setViaTariff(""))
    dispatch(setCabinTypeTariff(""))
    dispatch(setTripTypeTariff(""))
    dispatch(setLandFareTypeTariff(0))
    dispatch(setCruiseFareTypeTariff(0))
    dispatch(setBrandedFareTariff(null))

    //Class Search
    dispatch(setBrandIdClassTariff(""))
    dispatch(setClassClassTariff(""))
    dispatch(setEDRTicketingTariff(""))
    dispatch(setCategoryClassTariff(""))
    dispatch(setDescClassTariff(""))
    dispatch(setNoteClassTariff(""))
    dispatch(setClassKeyClassTariff(""))
    dispatch(setFareTypeClassTariff(""))
    dispatch(setEDRStartClassTariff(""))
    dispatch(setERDEndClassTariff(""))

    //Prix Search
    dispatch(setValidityFromPrix(""))
    dispatch(setValidityPrixToPrix(""))
    dispatch(setLastReturnDatePrix(""))
    dispatch(setEDRLastTicketingDatePrix(""))
    dispatch(setPrixKeyPrix(""))
    dispatch(setNotePrix(""))
  }


  // FUNCTION FOR REDIRECT
  const redirectToTariff = (value) => {
    let tariffId = value.tarifId
    history.push(`/contractmanagement/${tariffId}`);
  }

  // REDIRECT TO CLASS UI
  const redeirectToClass = (value, tariffId1) => {
    const classId = value.classId
    // const tariffId = value.tarifId1
    history.push(`/classes/${classId}/${tariffId1}`);
    

  }

  // FUNCTION FOR REDIRECT
  const redirectToViewPrice = (value) => {
    const prixId = value.priceId
    const tariffId = value.tarifId
    const classId = value.classId
    history.push(`/prices/${tariffId}/${classId}/${prixId}`);
  }


  const nextClick = (value) => {
    setCounter(counter + 1)
    paginationNext(value)
    // tariffSearchApiCall
  }

  
  //PAGINATION FUNCTION
  const paginationNext = async (value) => {
    setIsLoading(true)
    setIsFirst(false)
    setIsLast(false)
    const url =
      searchUrl === '' ? `${process.env.REACT_APP_CONTRACT_URL}/searchAll`
      : `${process.env.REACT_APP_CONTRACT_URL}/${searchUrl}&token=${pagiToken}&nextResultKey=${value}`;
    
      // const url =
      // searchUrl === '' ? `http://3.94.222.174:3000/searchAll`
      // : `http://3.94.222.174:3000/${searchUrl}&token=${pagiToken}&nextResultKey=${'100_200'}`;
    
      
    let requestbody = {
      LastEvaluatedKey: previousLastEvlKey,
    };

    const requestOptions = {
      method: "POST",

      headers: {
        "Content-type": "application/json; charset=UTF-8",

        "Access-Control-Allow-Origin": "*",

        "Access-Control-Allow-Credentials": true,

        "cache-control": "no-cache",
      },

      body: JSON.stringify(requestbody),
    };

    const result = await getApiResponse(url, requestOptions);

    if (result) {
      setIsLoading(true)
      SetNoDataFlag(false)
      setIsLoading(false)
      setLastEvalatuedValue(result?.LastEvaluatedKey)
      if(result.LastEvaluatedKey === undefined){
        setIsLast(true)
    } else {
        setIsLast(false)
        setPreviousLastEvlKey(result.LastEvaluatedKey)
    }
      if (result.message === undefined) {
        let serData = allSearchData 
        serData = result?.Items
        let arryData = [...allSearchData,...serData]
        setallSearchData(arryData)

        let data = result?.Items?.slice(0 , 25)     
        setCommonData(data)
       
      
        // CALCULATE TOTAL PAGE COUNT
        let pageCount =result?.Items.length
        let count = Math.ceil((pageCount / 25))

        if(result.LastEvaluatedKey === undefined){
          setTotalPage(totalPage + count - 2)      
        }
           else {
        setTotalPage(totalPage + count)
      }
       
    
          let lstKeyArry = lastEvlKey
          let rangeArry = []
          for(let i = (totalPage - 2) ; i <= (count + (totalPage - 2)) ; i++){
            rangeArry.push(i)
            }
            lstKeyArry.push(  {
              range:rangeArry,
              value:previousLastEvlKey ,

            })
            setLastEvlKey(lstKeyArry)
            setIsLoading(false)
          } else {
            setallSearchData([])
            setIsLoading(false)
          }
    }
  };

  async function getApiResponse(url, requestOptions) {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      return null;
    } else {
      const data = await response.json();

      return data;
    }
  }

  


  // PUSHED IN LASTEVALUATED KEY
  const setLastEvalatuedValue = (value) => {
    let newArry = lastArry
    if (newArry.includes(value)) {
      newArry = newArry.filter((id) => id != value);
    } else {
      newArry.push(value);
    }
    setLastArry(newArry)
  }



  const handleChange = (event, value) => {
    
    if((totalPage / (value + 1) ) === 1 ) {
      let value = instialFirstVal+"_"+instialSecondVal
      setInstialFirstVal(instialFirstVal + 100)
      setInstialSecondVal(instialSecondVal + 100)
      if(!isLast){
        nextClick(value)
      }
     }
    setPage(value);
    let pageSetCount = (value % 4 ) 
    let sliceCount = pageSetCount === 0 ? 4 : pageSetCount
    let data = allSearchData.slice(((value -1) * 25) , (value * 25))
     setCommonData(data)
  };


  return <div>
    <div>
      <div className="card cm-card"> 
        <div className="card-body">

          {/* >>>>>>>>>>>>>>>>>>>>> tariff Search >>>>>>>>>>>>>>>>>>>>>. */}
          <div className="cm-head-2">
            <div className="d-flex justify-content-between">
              <span className="">Tariff Search</span>
              <div className="cm-head-checkbox d-flex">
              <button className="cm-primary-btn ml-3" onClick={(e) => createContrat()}> Create Tariff</button>
            </div>
            </div>
          </div>
          {/* ORIGIN AND DESTINATION SEARCH SECTION */}
          <div className="row mt-4">
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Origin</label>
              <div className="row">
                <div className="col-md-5">
                  <select
                    className="form-control cm-input-select"
                    value={originType}
                    onChange={(e) => setOriginType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value='originRegion'>Region Code</option>
                    <option value='originCitiesOrAirports'>Airport Or City Code</option>
                    <option value='originCountries'>Country Code</option>
                    <option value='originIATARegion'>IATA Region Code</option>
                  </select>
                </div>
                <div className="col-md-7">
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Origin ..."
                    value={originValue}
                    onChange={(e) => setoriginValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Destination</label>
              <div className="row">
                <div className="col-md-5">
                  <select
                    className="form-control cm-input-select"
                    value={destinationType}
                    onChange={(e) => setDestinationType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value='destinationRegion'>Region Code</option>
                    <option value='destinationCitiesOrAirports'>Airport Or City Code</option>
                    <option value='destinationCountries'>Country Code</option>
                    <option value='destinationIATAs'>IATA Region Code</option>
                  </select>
                </div>
                <div className="col-md-7">
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Destination ..."
                    value={destinationValue}
                    onChange={(e) => setDestinationValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* VIA VALIDATING CARRIER SECTION */}
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5 cm-col">
                  <label className="cm-head-label">Tarif Key</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter tarif key"
                    value={tarifKey}
                    onChange={(e) => setTarifKey(e.target.value)}

                  />
                </div>
                <div className="col-md-7 cm-col">
                  <label className="cm-head-label">Validating Carrier</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter validating carrier"
                    onChange={(e) => setCarrier(e.target.value)}
                    value={carrier}
                  />
                </div>
              </div>

            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5 cm-col">
                  <label className="cm-head-label">VIA</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter VIA"
                    onChange={(e) => setVia(e.target.value)}
                    value={via}

                  />
                </div>
              </div>
            </div>
          </div>
          {/* EDR DATE */}
          {/* <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5 cm-col">
                  <label className="cm-head-label">EDR Start Date</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter date"
                      type="date"
                      value={classLastTicketingDateFrom}
                      onChange={(e)=>setClassLastTicketingDateFrom(e.target.value)}

                    />
                  </div>
                  <div className="col-md-7 cm-col">
                  <label className="cm-head-label">EDR End Date</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter date"
                      type="date"
                      value={classLastTicketingDateTo}
                      onChange={(e)=>setClassLastTicketingDateTo(e.target.value)}
                    />
                  </div>
                </div>

              </div>
         
            </div> */}
          <div className="row">
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Cabin Type</label>
              <div className="cm-radio-inline">
                <FormControlLabel control={<Radio color="primary" />} label="Economy"
                  checked={cabinType === "Economy" ? true : false}
                  onChange={() => setCabinType("Economy")}
                />
                <FormControlLabel control={<Radio color="primary" />} label="Premimum Economy"
                  checked={cabinType === "PremiumEconomy" ? true : false}
                  onChange={() => setCabinType("PremiumEconomy")}
                  className="" />{" "}
                <FormControlLabel control={<Radio color="primary" />} label="First Class"
                  checked={cabinType === "First" ? true : false}
                  onChange={() => setCabinType("First")}
                  className="" />
                <FormControlLabel control={<Radio color="primary" />} label="Business"
                  checked={cabinType === "Business" ? true : false}
                  onChange={() => setCabinType("Business")}
                />{" "}
                <FormControlLabel control={<Radio color="primary" />} label="Premium Business"
                  checked={cabinType === "PremiumBusiness" ? true : false}
                  onChange={() => setCabinType("PremiumBusiness")}
                />{" "}
              </div>
            </div>
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Trip Type</label>
              <div className="cm-radio-inline">
                <FormControlLabel control={<Radio color="primary" />} label="One Way"
                  checked={tripType === "OneWay" ? true : false} onChange={(e) => setTripType("OneWay")}
                />
                <FormControlLabel control={<Radio color="primary" />} label="Round Trip / Open Jaw / Multi City"
                  checked={tripType === "Return" ? true : false} onChange={(e) => setTripType("Return")}
                />{" "}
              </div>
            </div>

          </div>
          {/* FARE TYPE  */}
          <div className="row ">
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Fare Types</label>
              <div className="cm-checkbox-inline">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={landFare} onChange={(e) => setLandFare(!landFare)}
                      color="primary"
                    />
                  }
                  label="Land Fare"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cruiseFare} onChange={(e) => setCruiseFare(!cruiseFare)}
                      color="primary"
                    />
                  }
                  label="Cruise Fare"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 cm-col">
                  <label className="cm-head-label">Branded Fare</label>
                  <div className="cm-radio-inline">
                    <FormControlLabel control={<Radio color="primary" />} label="Yes"
                      checked={isBrandedFare === "1" ? true : false}
                      onChange={(e) => setIsBrandedFare("1")}
                    />
                    <FormControlLabel control={<Radio color="primary" />} label="No"
                      onChange={(e) => setIsBrandedFare("0") ? true : false}
                      checked={isBrandedFare === '0'}
                    />
                  </div>
                </div>
                <div className="col-md-6 cm-col">
                  <label className="cm-head-label">Type of Passenger</label>
                  <div className="cm-radio-inline">
                    <FormControlLabel control={<Radio color="primary" />} label="Adult"
                      checked={passengerType === "ADULT" ? true : false} onChange={(e) => setPassengerType("ADULT")}
                    />
                    <FormControlLabel control={<Radio color="primary" />} label="Senior"
                      checked={passengerType === "SENIOR" ? true : false} onChange={(e) => setPassengerType("SENIOR")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <p className="cm-head-2">Class Search</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 cm-col">
              <label className="cm-head-label"> Brand id </label>
              <input
                className="form-control cm-input"
                placeholder="Enter Brand Id"
                value={brandId}
                onChange={(e) => setBrandId(e.target.value)}
              />
            </div>
            <div className="col-md-4 cm-col">
              <label className="cm-head-label"> Classe </label>
              <input
                className="form-control cm-input"
                placeholder="Enter Classe"
                value={classCodes}
                onChange={(e) => setClassCodes(e.target.value)}
              />
            </div>
            <div className="col-md-4 cm-col">
              <label className="cm-head-label">EDR  i.e last ticketing date</label>
              <input
                className="form-control cm-input-date"
                placeholder="Enter Brand Id"
                type="date"
                value={lastTicketingDate}
                onChange={(e) => setLastTicketingDate(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 cm-col">
              <label className="cm-head-label">Category </label>
              <input
                className="form-control cm-input"
                placeholder="Enter category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
            <div className="col-md-4 cm-col">
              <label className="cm-head-label">Desc</label>
              <input
                className="form-control cm-input"
                placeholder="Enter Desc"
                value={clasCodeDesc}
                onChange={(e) => setClasCodeDesc(e.target.value)}
              />
            </div>
            <div className="col-md-4 cm-col">
              <label className="cm-head-label">NOTE</label>
              <input
                className="form-control cm-input"
                placeholder="Enter note"
                type="text"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
          {/* FARE TYPE */}
          <div className="row">
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Class Key</label>
              <input
                className="form-control cm-input"
                placeholder="Enter class key"
                type="text"
                value={classKey}
                onChange={(e) => setClassKey(e.target.value)}
              />
            </div>
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Fare Type</label>
              <div className="cm-radio-inline">
                <FormControlLabel control={<Radio color="primary" />} label="(%) Published"
                  checked={contractType === 'Published' ? true : false}
                  onChange={() => setContractType('Published')}
                />
                <FormControlLabel control={<Radio color="primary" />} label="($) Net"
                  checked={contractType === 'Net' ? true : false} onChange={() => setContractType("Net")}
                />
              </div>
            </div>
          </div>
          {/* EDR DATE */}
          {/* EDR DATE */}
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5 cm-col">
                  <label className="cm-head-label">EDR Start Date</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter date"
                    type="date"
                    value={classLastTicketingDateFrom}
                    onChange={(e) => setClassLastTicketingDateFrom(e.target.value)}
                  />
                </div>
                <div className="col-md-7 cm-col">
                  <label className="cm-head-label">EDR End Date</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter date"
                    type="date"
                    value={classLastTicketingDateTo}
                    onChange={(e) => setClassLastTicketingDateTo(e.target.value)}
                  />
                </div>
              </div>

            </div>

          </div>


          <div className="row">
            <div className="col-12">
              <p className="cm-head-2">Prix Search</p>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Prix validity</label>
              <div className="row">
                <div className="col-md-6">
                  <label className="cm-head-label">From</label>
                  <input
                    type="date"
                    className="form-control cm-input-date"
                    onChange={(e) => setValidFrom(e.target.value)}
                    value={validFrom}
                    max={validTo}
                  />
                </div>
                <div className="col-md-6">
                  <label className="cm-head-label">To</label>
                  <input
                    type="date"
                    className="form-control cm-input-date"
                    onChange={(e) => setValidTo(e.target.value)}
                    value={validTo}
                    min={validFrom}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Last Return date</label>
              <input
                type="date"
                className="form-control cm-input-date"
                onChange={(e) => setLastReturnDate(e.target.value)}
                value={lastReturnDate}
              />
            </div>
            <div className="col-md-3 cm-col">
              <label className="cm-head-label">EDR i.e Last ticketing date</label>
              <input
                type="date"
                className="form-control cm-input-date"
                onChange={(e) => setLastTicketingDatePrix(e.target.value)}
                value={lastTicketingDatePrix}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Prix Key</label>
              <input
                className="form-control cm-input"
                placeholder="Enter Prix Key"
                onChange={(e) => setPrixKey(e.target.value)}
                value={prixKey}
              />
            </div>
            <div className="col-md-6 cm-col">
              <label className="cm-head-label">Note</label>
              <input
                className="form-control cm-input"
                placeholder="Enter Note"
                onChange={(e) => setPrixNote(e.target.value)}
                value={prixnote}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5 cm-col">
                  <label className="cm-head-label">EDR Start Date</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter date"
                    type="date"

                  />
                </div>
                <div className="col-md-7 cm-col">
                  <label className="cm-head-label">EDR End Date</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter date"
                    type="date"

                  />
                </div>
              </div>

            </div>

          </div>

          {/* BUTTON SECTION */}
          <div className="row cm-col">
            <div className="col-md-12 text-right">
              <button
                className="cm-secondary-btn mr-2"
                onClick={(e) => clearSearch()}
              >
                Clear
              </button>
              <button
                className="cm-primary-btn"
                onClick={(e) => tariffSearchApiCall()}
              //   disabled={isLoading}
              >
                {isLoading && <span className="mr-5 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}

                Search
              </button>

            </div>
          </div>
        </div>
      </div>


    </div>

    {
     
     dataTariff?.map((tariff, index) => {
        
        let logoLowerCase = tariff.carrier && tariff.carrier.toLowerCase();
        // let classDataVal = tariff?.classItems !== undefined  ?  tariff?.classItems : tariff !== undefined ? tariff : []
        let flightLogo = `${process.env.REACT_APP_FLIGHT_LOGO_URL}/${logoLowerCase}.png`
        return (
          <div className='card mt-3 shadow-sm p-3 mb-5 bg-white rounded' key={index}>
            <div className="row"
              onClick={(e) => redirectToTariff(tariff)}
              style={{ cursor: 'pointer' }}
            >
              <div className="col-md-4">
                <div className="tariff-table-title">
                  <div className="fare-logo">
                    <span className="fligh-logo"><img src={flightLogo} height="35" width="35" alt="flightLogo" /> {tariff.carrier}</span>
                    <span><strong>VIA:</strong> {tariff.via}</span>
                    <span><strong>{tariff.isBrandedFare === "1" ? 'Branded Fare' : 'Non-Branded Fare'}</strong></span>
                  </div>
                  <div className="note"><strong>Note:</strong> {tariff.note}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tariff-origin-dest">
                  <div className="tariff-origin-dest-row">
                    <strong>Origin: </strong>
                    {(tariff.originCountries && (tariff.originCountries.length === 0 || tariff.originCountries.includes("") || tariff.originCountries.includes(null))) ? "" : "Country -"}  {tariff.originCountries && Object.values(tariff.originCountries).join(',')}
                    {(tariff.originRegions && (tariff.originRegions.length === 0 || tariff.originRegions.includes("") || tariff.originRegions.includes(null))) ? "" : <span> {(tariff.originCountries && (tariff.originCountries.length === 0 || tariff.originCountries.includes("") || tariff.originCountries.includes(null))) ? "" : "|"}    Regions - </span>} {tariff.originRegions && Object.values(tariff.originRegions).join(',')}
                    {(tariff.originCitiesOrAirports && (tariff.originCitiesOrAirports.length === 0 || tariff.originCitiesOrAirports.includes("") || tariff.originCitiesOrAirports.includes(null))) ? "" : <span> {(tariff.originRegions && (tariff.originRegions.length === 0 || tariff.originRegions.includes("") || tariff.originRegions.includes(null))) ? "" : "|"}    Cities Or Airports - </span>}     {tariff.originCitiesOrAirports && Object.values(tariff.originCitiesOrAirports).join(',')}
                    {tariff.originIATARegion && tariff.originIATARegion != "" && <span>{(tariff.originCitiesOrAirports && (tariff.originCitiesOrAirports.length === 0 || tariff.originCitiesOrAirports.includes("") || tariff.originCitiesOrAirports.includes(null))) ? "" : "|"}  IATA - </span>}  {tariff.originIATARegion}
                  </div>

                </div>
              </div>
              <div className="col-md-4">
                <div className="tariff-origin-dest-row">
                  <strong>Destination: </strong>
                  {tariff.destinationCountries && tariff.destinationCountries.length !== 0 && " Country -"}  {Object.values(tariff.destinationCountries).join(', ')}
                  {tariff.destinationIATAs && tariff.destinationIATAs.length !== 0 && <span>{tariff.destinationCountries && tariff.destinationCountries.length !== 0 && "|"} IATA </span>}  {tariff.destinationIATAs && Object.values(tariff.destinationIATAs).join(', ')}
                  {tariff.destinationRegions && tariff.destinationRegions.length !== 0 && <span>{tariff.destinationRegions && tariff.destinationRegions.length !== 0 && <span>{tariff.destinationIATAs && tariff.destinationIATAs.length !== 0 && "|"} Regions - </span>} </span>} {tariff.destinationRegions && Object.values(tariff.destinationRegions).join(', ')}
                  {tariff.destinationCitiesOrAirports && tariff.destinationCitiesOrAirports.length !== 0 && <span>{tariff.destinationRegions && tariff.destinationRegions.length !== 0 && "|"}  Cities Or Airports - </span>}    {tariff.destinationCitiesOrAirports && Object.values(tariff.destinationCitiesOrAirports).join(', ')}
                </div>
              </div>
            </div>
            {tariff?.classItems?.length > 0 &&
              <a className="text-primary ml-2" onClick={() => selectClassFunction(index)}><b>Show Class</b></a>
            }
            {selectedClassIndex === index && showClass ?
              <div>
                {tariff.classItems.length > 0 &&
                  <div className="card shadow-sm p-3 mb-5 bg-white rounded">
                    <div className="row ml-3">
                      <div className="col-md-2 font-weight-bold">Branded fare code</div>
                      <div className="col-md-2 font-weight-bold">Class(es)</div>
                      <div className="col-md-2 font-weight-bold">Contract Type</div>
                      <div className="col-md-2 font-weight-bold">Note</div>
                      <div className="col-md-2 font-weight-bold">Category</div>
                      <div className="col-md-2 font-weight-bold">EDR</div>
                    </div>
                  </div>
                }

                {tariff?.classItems?.map((classData, classindex) => {
                  return (
                    <>
                      <div className="card shadow-sm p-3 mb-5 bg-white rounded">
                        <div className="row ml-3 " style={{ cursor: 'pointer' }}
                          onClick={(e) => redeirectToClass(classData, tariff.tarifId)}
                          key={classindex}
                        >
                          <div className="col-md-2">{classData.brandCode && classData.brandCode.includes("") ?
                            classData.classCodes
                            :
                            classData.brandCode
                          }
                          </div>
                          <div className="col-md-2">{classData.classCodes}</div>
                          <div className="col-md-2">{classData.contractType}</div>
                          <div className="col-md-2">{classData.classNote}</div>
                          <div className="col-md-2">{classData && classData.category}</div>
                          <div className="col-md-2">{classData.classLastTicketingDate && classData.classLastTicketingDate}</div>
                        </div>

                        {classData?.prix?.length > 0 &&
                          <a className="text-primary ml-2" onClick={() => selectPrixFunction(index)}><b>Show Prix</b></a>
                        }
                        {selectedPrixIndex === classindex && showPrix ?
                          <div>
                            {classData?.prix?.length > 0 &&
                              <div className="card shadow-sm p-3 mb-5 bg-white rounded">
                                <div className="row ml-3">
                                  <div className="col-md-2 font-weight-bold">From</div>
                                  <div className="col-md-2 font-weight-bold">To</div>
                                  <div className="col-md-2 font-weight-bold">Cost</div>
                                  <div className="col-md-2 font-weight-bold">Markup</div>
                                  <div className="col-md-2 font-weight-bold">Net</div>
                                </div>
                              </div>
                            }

                            {classData?.prix?.map((pricesData, prxIndex) => {
                              return (
                                <>
                                  <div className="card shadow-sm p-3 mb-5 bg-white rounded">
                                    <div className="row ml-3" key={prxIndex}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => redirectToViewPrice(pricesData)}
                                    >
                                      <div className="col-md-2">
                                        {pricesData.validFrom && pricesData.validFrom}
                                      </div>
                                      <div className="col-md-2">{pricesData.validTo}</div>
                                      <div className="col-md-2">{pricesData.fareCalculation?.commissionPercentage}</div>
                                      <div className="col-md-2">{pricesData.fareCalculation?.markup}</div>
                                      <div className="col-md-2">{pricesData.fareCalculation?.passBackCommission}</div>
                                    </div>
                                  </div>

                                </>
                              )
                            })}

                          </div>
                          :
                          ''
                        }
                      </div>

                    </>
                  )
                })}

              </div>
              :
              ''
            }

          </div>
        )
      })}
  
        {isLoading ? <div style={{justifyContent:'center'}}>
            <span className="badge badge-light mt-3 ml-3 mb-3"><span className="text-danger">Loading ...</span> </span>
        </div>:''}
        {dataTariff.length === 0 ? '' :
        isLastEvlKeyPresent ? '' :
        <Pagination 
          className= {isLast ? '': "cs-pagination"}
          count={totalPage} 
          page={page} 
          onChange={handleChange}
          size="large" 
          boundaryCount={0}
          // hideNextButton={true} 
          // showLastButton={true}
          />
        
      }
        {noDataFlag && 
      <div className="alert alert-info mt-2" role="alert">
          No Results found for the searhced criteria.
      </div>
      }
  </div>;
}
