import { useMemo } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch ,useSelector } from "react-redux";
import { useHtmlClassService } from "../../_core/Layout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
// import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
// import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
// import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
// import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
// import { QuickUserToggler } from "../extras/QuiclUserToggler";
import Dropdown from 'react-bootstrap/Dropdown'
import Logout from "../../../../appStore/actions/loginAction";
import tokensave from "../../../../appStore/actions/saveToken";

export function Topbar() {
  const uiService: any = useHtmlClassService();
  const dispatch = useDispatch();
  const userInfo = useSelector((state:any) => state.userInfo);
  const token = useSelector((state:any) => state.token);
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);
  


  const logOutFunction = async () => {
    let urlval: any = `${process.env.REACT_APP_BASE_URL}/auth/users/internal/signOut?token=${token.token}`;
    const requestOptions = {
    method: "POST",
    headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "cache-control": "no-cache",
    },
    };
    const result = await getApiResponseval(urlval, requestOptions);
    if(result){
      dispatch(Logout(false));
      dispatch(tokensave({}));
    }
    dispatch(Logout(false));

  };
  
  async function getApiResponseval(urlval: any, requestOptions: any) {
    const response = await fetch(urlval, requestOptions);
    if (!response.ok) {
    return null;
    } else {
    const data = await response.json();
    return data;
    }
  }

  return (
    <div className="topbar">
      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {/* {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

      {layoutProps.viewCartDisplay && <MyCartDropdown />} */}

      {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <i className="fas fa-th-large"></i>
              </span>
            </div>

          
          </div>
        </OverlayTrigger>
        
      )}
          <div>
              <span>
                <span>
                  <Dropdown  className="mt-4">
                    <Dropdown.Toggle className="loginIcon" id="dropdown-basic" style={{height:'40px' ,width:"40px" ,borderRadius:'50%'}}>
                      <span className="" style={{marginLeft:'-6px' ,textTransform:'capitalize'}}>{userInfo?.userData?.username?.charAt(0)}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>  {userInfo?.userData?.username} </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item  onClick={()=> logOutFunction()}>Log out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </span>
            </div>
           
      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {layoutProps.viewUserDisplay && <QuickUserToggler />} */}
    </div>
  );
}
