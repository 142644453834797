//@ts-nocheck
import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import VisaImg from './Images/visa-logo.svg';
import AmericanExpImg from './Images/americanexpress-light.svg';
import master from './Images/payment-1.svg';
import diner from './Images/dinner_club.png';

function SavedQuotesCard() {
  const priceValue = useSelector((state) => state.flightBooking.bookingItenaries);
  const contractdetails = priceValue && priceValue.applicableContract && priceValue.applicableContract
  const creditCardList = contractdetails && contractdetails.allowedCards ? contractdetails.allowedCards : ["AX", "CA", "VI", "DC"]
  return (
    <div className="bd-payment">
      {/* <div className="bd-sab-head"><h3>Form Of Payments</h3></div> */}
      <div className="bd-payment-accept">
        <span>Cards Accepted</span>
        {creditCardList && creditCardList.map((obj) => {
          return (
            <>
              {obj === "AX" && <img src={AmericanExpImg} alt="img" />
              }
              {obj === "CA" && <img src={master} alt="img" />
              }
              {obj === "DC" && <img src={diner} alt="img" />
              }
              {obj === "VI" && <img src={VisaImg} alt="img" />
              }
              {obj === "TP" && <img src={VisaImg} alt="img" />
              }
            </>

          )
        })}
      </div>
      {/* <div className="bd-payment-ameExp">
        Cards accepted with merchant fees applied.Fees Will Be Calculated
        During Payments.
      </div> */}
    </div>
  )
}
export default SavedQuotesCard;