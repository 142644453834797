// import { setPassengerData } from "../actionCreators/flightBooking";
import {
  SET_SEGMENT,
  SET_ITINARY_FARE,
  SET_PASSENGER_DATA,
  SET_PNR,
  SET_BOOKING_DETAIL,
  SET_BOOKING_PAYMENT_STATUS,
  SET_ITINARY_FARES_TYPE,
  SET_BOOKING_DATE,
  SET_BOOKING_STATUS,
  PRICE_STATUS,
  SET_PNR_CANCELLATION_STATUS,
  SET_CANCELLATION_POPUP,
  SET_PASSENGER_DETAILS_ARRAY,
  SET_BOOK_WITH_PAYMENT_FLAG,
  SET_BRANDED_ITINARY_FARE,
  SET_SEATMAP_HEADER,
  SET_PNR_PRICE,
  SET_BRANDED_STATUS,
  SET_SEARCH_STATUS,
  SET_PAYMENT_RULES,
  SET_MARKUP_STATUS,
  SET_PASSENGER_FRAUD_LIST,
  SET_FRAUD_USER_EXISTS,
  SET_FRAUD_USER_EXISTS_WITH_PAYMENT,
  SET_FRAUD_USER_FLAG,
  SET_ADULT_COUNT,
  SET_CHILDREN_COUNT,
  SET_INFANT_COUNT,
  SET_MYFILE_FLAG,
  SET_PAYMENT_RULES_FOR_CHEQUE,
  SET_CREATE_BOOKING_DATA,
  SET_PRICING_FARE_DATA,
  SET_SSR_LOADER_FLAG,
  SET_SSR_SAVE_EDIT_FLAG,
  SET_APPLY_BUTTON_CLICK_FLAG,
  SET_LAND_CRUISE_BOOKING_NUMBER,
  SET_AGENT_EMERGENCY_NUMBER,
  SET_VALIDATION_ON_LAND_CRUISE,
  SET_EQUIVALENT_FARE_VALUE,
  SET_EQUIVALENT_FARE_LOADER,
  SET_REPRICE_FARE,
  SET_REPRICE_FARE_LOADER,
  SET_FREQUENT_FLYER_DROPDOWN,
  SET_GET_PNR_RESULT,
  SET_BOOKING_STATUS_CODE,
  SET_FARE_PAYMENT_ATTEMPT, 
  SET_SEAT_PAYMENT_ATTEMPT,
  SET_FARE_PAYMENT_STATUS,
  SET_SEAT_PAYMENT_STATUS,
  SET_FOP_SELECTION,
  SET_EMAIL_LANGUAGE,
  SET_MARKUP_LOADER,
  SET_FLOW_FROM,
  SET_CALC_ERRORS,
  SET_MY_RESULT_FLAG
} from "../storeConstant";

const initialState = {
  bookingSegment: [],
  bookingItenaries: {},
  pnr: "",
  bookingDetails: {},
  paymentDetail: [],
  bookingPaymentStatus: "",
  itinenaryFaresType: "",
  bookingDate: "",
  bookingStatus: "",
  priceStatus: false,
  pnrCancellationStatus: "",
  cancellationPopUp: false,
  passengerDetailsArray: "",
  bookWithPaymentFlag: false,
  fraudUserFlag: false,
  brandedItenaries: [],
  seatmapHeader: {},
  pnrPriceData: {},
  brandedStatus: "",
  searchStatus: "",
  paymentRules:{},
  paymentRulesForCheque:{},
  markupStatus:false,
  fraudPassengerList: [],
  fraudUserExists: false,
  fraudUserExistsWithPayment: false,
  pnrInfantCount: 0,
  pnrAdultCount: 0,
  pnrChildCount: 0,
  pnrFlag: false,
  createBookingData:{},
  priceFareData:"",
  ssrLoaderFlag:false,
  ssrSaveEditFlag:true,
  applyBtnClickFlag:false,
  landCruiseBookingNumber:"",
  agentEmergencyNumber:"",
  validationOnLandCruise:true,
  equivalentFareValue:{},
  equivalentFareLoader:false,
  repriceFare:"",
  repriceFareLoader:false,
  frequentFlyerDropdown:[],
  getPnrResult:"",
  bookingStatusCode:"",
  farePaymentAttempt: false,
  seatPaymentAttempt: false,
  farePaymentStatus : false,
  seatPaymentStatus : false,
  fopSelection:[],
  emailLanguage:"English",
  markupLoader:false,
  flowFrom:"",
  calcErrors:[],
  setFilterTag:'All'

};

const FlightBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEGMENT:
      return {
        ...state,
        bookingSegment: action.payload,
      };

      case SET_MY_RESULT_FLAG:
        return {
          ...state,
          setFilterTag: action.payload,
        };
  

    case SET_ITINARY_FARE:
      return {
        ...state,
        bookingItenaries: action.payload,
      };

    case SET_PNR:
      return {
        ...state,
        pnr: action.payload,
      };

    case SET_PASSENGER_DATA:
      let newState = {
        ...state,
        bookingDetails: {
          ...state.bookingDetails,
          passengers: action.payload,
        },
      };
      return newState

    case SET_BOOKING_DETAIL:
      return {
        ...state,
        bookingDetails: {
          ...state.bookingDetails,
          merchantFeeAppliedOnTransaction: action.payload.merchantFeeAppliedOnTransaction,
          passengers: action.payload.passengers,
          segments: action.payload.segments,
          itineraryFare: action.payload.itineraryFare,
          payments: action.payload.payments,
          agencyMarkups: action.payload.agencyMarkups,
          ticketingDetails: action.payload.ticketDetails
        },
      };
    case SET_BOOKING_PAYMENT_STATUS:
      return {
        ...state,
        bookingPaymentStatus: action.payload,
      };
    case SET_ITINARY_FARES_TYPE:
      return {
        ...state,
        itinenaryFaresType: action.payload,
      };

    case SET_BOOKING_DATE:
      return {
        ...state,
        bookingDate: action.payload,
      };
    case SET_BOOKING_STATUS:
      return {
        ...state,
        bookingStatus: action.payload
      }
    case PRICE_STATUS:
      return {
        ...state,
        priceStatus: action.payload
      }
    case SET_PNR_CANCELLATION_STATUS:
      return {
        ...state,
        pnrCancellationStatus: action.payload
      }
    case SET_CANCELLATION_POPUP:
      return {
        ...state,
        cancellationPopUp: action.payload
      }
    case SET_PASSENGER_DETAILS_ARRAY:
      return {
        ...state,
        passengerDetailsArray: action.payload
      }
    case SET_BOOK_WITH_PAYMENT_FLAG:
      return {
        ...state,
        bookWithPaymentFlag: action.payload
      }
    case SET_BRANDED_ITINARY_FARE:
      return {
        ...state,
        brandedItenaries: action.payload,
      };
    case SET_SEATMAP_HEADER:
      return {
        ...state,
        seatmapHeader: action.payload,
      };
    case SET_PNR_PRICE:
      return {
        ...state,
        pnrPriceData: action.payload,
      };
    case SET_BRANDED_STATUS:
      return {
        ...state,
        brandedStatus: action.payload,
      };
    case SET_SEARCH_STATUS:
      return {
        ...state,
        searchStatus: action.payload,
      };
    case SET_PAYMENT_RULES:
      return {
        ...state,
        paymentRules: action.payload,
      };
      case SET_PAYMENT_RULES_FOR_CHEQUE:
        return {
          ...state,
          paymentRulesForCheque: action.payload,
        };
      case SET_MARKUP_STATUS:
        return {
          ...state,
          markupStatus: action.payload,
        };
      case SET_PASSENGER_FRAUD_LIST:
        return {
          ...state,
          fraudPassengerList: action.payload
        };
      case SET_FRAUD_USER_EXISTS:
        return {
          ...state,
          fraudUserExists: action.payload
        };
      case SET_FRAUD_USER_EXISTS_WITH_PAYMENT:
        return {
          ...state,
          fraudUserExistsWithPayment: action.payload
        };
      case SET_FRAUD_USER_FLAG:
        return {
          ...state,
          fraudUserFlag: action.payload
        }
        case SET_ADULT_COUNT:
          return {
            ...state,
            pnrAdultCount: action.payload
          }
    
        case SET_CHILDREN_COUNT:
          return {
            ...state,
            pnrChildCount: action.payload
          }
    
    
        case SET_INFANT_COUNT:
          return {
            ...state,
            pnrInfantCount: action.payload
          }
    
    
        case SET_MYFILE_FLAG:
          return {
            ...state,
            pnrFlag: action.payload
          }

          case SET_CREATE_BOOKING_DATA:
            return {
              ...state,
              createBookingData: action.payload
            } 

            case SET_PRICING_FARE_DATA:
              return {
                ...state,
                priceFareData: action.payload
              }
              case SET_SSR_LOADER_FLAG:
                return {
                  ...state,
                  ssrLoaderFlag: action.payload
                }
                case SET_SSR_SAVE_EDIT_FLAG:
                  return {
                    ...state,
                    ssrSaveEditFlag: action.payload
                  } 
                  case SET_APPLY_BUTTON_CLICK_FLAG:
                    return {
                      ...state,
                      applyBtnClickFlag: action.payload
                    }
                case SET_LAND_CRUISE_BOOKING_NUMBER:
                  return {
                    ...state,
                    landCruiseBookingNumber: action.payload
                  }  
                  case SET_AGENT_EMERGENCY_NUMBER:
                    return {
                      ...state,
                      agentEmergencyNumber: action.payload
                    } 
                    case SET_VALIDATION_ON_LAND_CRUISE:
                    return {
                      ...state,
                      validationOnLandCruise: action.payload
                    } 
                    case SET_EQUIVALENT_FARE_VALUE:
                      return {
                        ...state,
                        equivalentFareValue: action.payload,
                      };
                      case SET_EQUIVALENT_FARE_LOADER:
                      return {
                        ...state,
                        equivalentFareLoader: action.payload,
                      };
                      case SET_REPRICE_FARE:
                        return {
                          ...state,
                          repriceFare: action.payload,
                        };
                        case SET_REPRICE_FARE_LOADER:
                          return {
                            ...state,
                            repriceFareLoader: action.payload,
                          };
                      case SET_FREQUENT_FLYER_DROPDOWN:
                        return {
                          ...state,
                          frequentFlyerDropdown: action.payload
                        }
                        case SET_GET_PNR_RESULT:
                          return {
                            ...state,
                            getPnrResult: action.payload
                          }
                          case SET_BOOKING_STATUS_CODE:
                            return {
                              ...state,
                              bookingStatusCode: action.payload
                            }
                  case SET_FARE_PAYMENT_ATTEMPT:
                    return {
                      ...state,
                      farePaymentAttempt: action.payload
                    }
                    case SET_SEAT_PAYMENT_ATTEMPT:
                      return {
                        ...state,
                        seatPaymentAttempt: action.payload
                      }
                    case SET_FARE_PAYMENT_STATUS:
                      return {
                        ...state,
                        farePaymentStatus: action.payload
                      }
                    case SET_SEAT_PAYMENT_STATUS:
                      return {
                        ...state,
                        seatPaymentStatus: action.payload
                      }
                      case SET_FOP_SELECTION:
                      return {
                        ...state,
                        fopSelection: action.payload
                      }
                      case SET_EMAIL_LANGUAGE:
                        return {
                          ...state,
                          emailLanguage: action.payload
                        }
                        case SET_MARKUP_LOADER:
                          return {
                            ...state,
                            markupLoader: action.payload
                          }
                          case SET_FLOW_FROM:
                            return {
                              ...state,
                              flowFrom: action.payload
                            }   
                          case SET_CALC_ERRORS:
                          return {
                            ...state,
                            calcErrors : action.payload
                          }   

                
                default:
                  return state;
              }
};

export default FlightBookingReducer;
