//@ts-nocheck
import { setBookingDetails, setPassengerData, setItenaryData, setBookingDate, setBookingStatus, 
    setPnrCancellationStatus,setMarkupApplied,setAdultCount,setInfantCount,setChildrenCount,setMyfilesFlag, setSsrLoaderFlag, setPricingFareData, setLandCruiseBookingNumber, setAgentEmergencyNumber, setGetPnrResult, setEmailLanguage } from "../ActionCreator/flightBooking"
  import {
    setBookingHeader, setFlyingFrom, setFlyingTo, setFlyingFromCity, setFlyingToCity, setTripType, setBookingItinenary, setProvideId,
   setAgencyAgentFieldFlag, setPromotionData, setPaymentDetailsAddons, setUUID
  } from "../ActionCreator/searchForm"
  import { setBrandCode } from "../ActionCreator/fareOption"
  import { env } from '../appConfig'
//   import { toast } from 'react-toastify';
//   import 'react-toastify/dist/ReactToastify.css';
  import { PassengerType } from '../Utils/Constant';
  import { getEquivalentPublishFareForPnr } from "./getEquivalentPublishFareForPnr";
  import getApiResponse from "../Services/getApiResponseDemo";
  //toast.configure()
  
  
  
  export const getPnrDetails = (requestPayload, isEdit) => {
  
  
    return async (dispatch, getState) => {
      const corrId = getState().searchForm.uuid;
      const flightType = getState().searchForm.flightType;
      const flowFrom = getState().flightBooking.flowFrom;
      let url = env.REACT_APP_API_BASE_URL + `/booking/process/getBookingDetails`
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
  
          "Access-Control-Allow-Credentials": true,
          "cache-control": "no-cache",
          correlationId: corrId,
          flightType:flightType       
  
        },
        body:
          JSON.stringify(requestPayload)
      }
  
  
      const bookingResult = await getApiResponse(url, requestOptions);
           
      if (bookingResult.error) {
        if(flowFrom=='Calculator'){
          return "error";
        }else{
          //toast.error(bookingResult.error && bookingResult.error.message, { position: toast.POSITION.TOP_CENTER, autoClose: true, progressStyle: { "transform-origin": "right" } });
          dispatch(setAgencyAgentFieldFlag(true));
        }
      } else if (bookingResult) {
  
        if (isEdit) {
  
  
          dispatch(setPassengerData(bookingResult.bookingDetails && bookingResult.bookingDetails.passengers && bookingResult.bookingDetails.passengers));
          //dispatch(setBookingDetails(bookingResult.bookingDetails))
          dispatch(setSsrLoaderFlag(false))
        } else {
  
          let passengers = bookingResult.bookingDetails && bookingResult.bookingDetails.passengers
          let adultData = passengers.filter(obj => obj.paxType === PassengerType.ADULT)
        let childData = passengers.filter(obj => obj.paxType === PassengerType.CHILD)
        let infantData = passengers.filter(obj => obj.paxType === PassengerType.INFANTINLAP)
          let adultCount = adultData.length
          let childCount = childData.length
          let infantCount = infantData.length
          let segments= bookingResult.bookingDetails &&bookingResult.bookingDetails.segments && bookingResult.bookingDetails.segments[0]
          let flight = segments && Object.values(segments)
          let fareBasisDetails  = flight && flight[0] && flight[0].flights && flight[0].flights[0] && flight[0].flights[0].fareBasisDetails
          let brandData = fareBasisDetails && fareBasisDetails[0] && fareBasisDetails[0].brand
          let emailLanguage = bookingResult && bookingResult.travelAgent && bookingResult?.travelAgent?.edocumentInLanguage !== undefined ? bookingResult?.travelAgent?.edocumentInLanguage : "English";
          dispatch(setGetPnrResult(bookingResult))
          dispatch(setLandCruiseBookingNumber(bookingResult && bookingResult?.bookingDetails?.nonAirConfirmationNumber));
          dispatch(setPricingFareData(""))
          dispatch(setMyfilesFlag(true))
          dispatch(setAdultCount(adultCount))
          dispatch(setChildrenCount(childCount))
          dispatch(setInfantCount(infantCount))
          dispatch(setBookingStatus(bookingResult.bookingStatusDetail))
          dispatch(setBookingDetails(bookingResult.bookingDetails))
          dispatch(setBookingDate(bookingResult.bookingDate))
          dispatch(setItenaryData(bookingResult.bookingDetails.itineraryFare))
          dispatch(setPnrCancellationStatus(bookingResult))
          dispatch(setTripType(bookingResult.tripType))
          dispatch(setBookingItinenary(bookingResult.bookingDetails))
          dispatch(setProvideId(bookingResult && bookingResult.providerId))
          dispatch(setPromotionData(bookingResult && bookingResult.promotion));
          dispatch(setPaymentDetailsAddons(bookingResult && bookingResult.paymentsForAddOns))
          dispatch(setAgentEmergencyNumber(bookingResult && bookingResult.travelAgent && bookingResult?.travelAgent?.emergencyPhone?.number));
          dispatch(setEmailLanguage(emailLanguage))
          dispatch(
            setBrandCode({
              brandCode: brandData && brandData.code,
              brandName: brandData && brandData.brandName,
            })
          );
          dispatch(setUUID(bookingResult && bookingResult.bookingCorrelationId))
        }
        let equivalentRequest = {
          "organizationId": requestPayload?.organizationId,
          "agencyId": requestPayload?.agencyId,
          "agencyGroupId": requestPayload?.agencyGroupId,
          "userId": bookingResult?.userId,
          "recordLocator": requestPayload?.recordLocator,   
          "bookingSource": bookingResult?.providerName,
          "agentId": bookingResult?.travelAgent?.userName,
          "agentName": requestPayload?.travelAgent?.userName
      }
  
        if(flowFrom !== "Calculator"){
        dispatch(getEquivalentPublishFareForPnr(equivalentRequest))
        }
  
  
        const fareType =
          bookingResult &&
          bookingResult.bookingDetails &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare.type;
  
        const amount =
          bookingResult.bookingDetails &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare.totalAmount &&
          bookingResult.bookingDetails.itineraryFare.totalAmount.amount;;
  
        const cabin =
          bookingResult.bookingDetails &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare.paxFares &&
          bookingResult.bookingDetails.itineraryFare.paxFares[0] &&
          bookingResult.bookingDetails.itineraryFare.paxFares[0].fareBasisDetails[0] &&
          bookingResult.bookingDetails.itineraryFare.paxFares[0].fareBasisDetails[0].brand &&
          bookingResult.bookingDetails.itineraryFare.paxFares[0].fareBasisDetails[0].brand.brandName;
  
        const lastTicketableDate =
          bookingResult.bookingDetails &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare.lastTicketableDate
  
        const validatingCarrier =
          bookingResult.bookingDetails &&
          bookingResult.bookingDetails.itineraryFare &&
          bookingResult.bookingDetails.itineraryFare.platingCarrierDetails &&
          bookingResult.bookingDetails.itineraryFare.platingCarrierDetails.carrier
  
        dispatch(
          setBookingHeader({
            fareType: fareType,
            brandName: cabin,
            amount: amount,
            validatingCarrier: validatingCarrier,
            lastTicketableDate
          }))
  
        const leftSection = bookingResult.bookingDetails.segments && bookingResult.bookingDetails.segments[0];
        const ouboundFlights = leftSection && Object.values(leftSection)[0];
        const flightDetail = ouboundFlights && ouboundFlights.flights
        const flyingFromCity = ouboundFlights && ouboundFlights.flights && ouboundFlights.flights[0].departureAirportCity
        const flyingFromCityCode = ouboundFlights && ouboundFlights.flights && ouboundFlights.flights[0].departureAirport
        const flyingToCity = ouboundFlights && ouboundFlights.flights && ouboundFlights.flights[flightDetail.length - 1].arrivalAirportCity
        const flyingToCityCode = ouboundFlights && ouboundFlights.flights && ouboundFlights.flights[flightDetail.length - 1].arrivalAirport
  
        const rightSection = bookingResult.bookingDetails.segments && bookingResult.bookingDetails.segments[1];
        const inboundFlights = rightSection && Object.values(rightSection)[0];
        const flightDetailOpenJaw = inboundFlights && inboundFlights.flights
  
        const flyingFromCityOpenJaw = inboundFlights && inboundFlights.flights && inboundFlights.flights[0].departureAirportCity
        const flyingFromCityCodeOpenJaw = inboundFlights && inboundFlights.flights && inboundFlights.flights[0].departureAirport
        const flyingToCityOpenJaw = inboundFlights && inboundFlights.flights && inboundFlights.flights[flightDetailOpenJaw.length - 1].arrivalAirportCity
        const flyingToCityCodeOpenJaw = inboundFlights && inboundFlights.flights && inboundFlights.flights[flightDetailOpenJaw.length - 1].arrivalAirport
  
        dispatch(setFlyingFromCity(bookingResult.tripType === "OpenJaw" ? flyingFromCityOpenJaw : flyingFromCity));
        dispatch(setFlyingFrom(bookingResult.tripType === "OpenJaw" ? flyingFromCityCodeOpenJaw : flyingFromCityCode));
        dispatch(setFlyingToCity(bookingResult.tripType === "OpenJaw" ? flyingToCityOpenJaw : flyingToCity));
        dispatch(setFlyingTo(bookingResult.tripType === "OpenJaw" ? flyingToCityCodeOpenJaw : flyingToCityCode));
      }
      return bookingResult
    }
  }