import { useState  } from "react";


export default function MarkupCommission() {

    // const [editFlag ,setEditFlag] = useState(false);

    // const selectEdit = () => {
    //     setEditFlag(true)
    // }

    return (
        <div className="card cm-card">
            <div className="card-body">
            <div className="cm-card-edit">
            {/* {editFlag ?
                <>
                <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                <button className="cm-primary-btn" onClick={(e)=>setEditFlag(false)}> Save</button>
                </>              :
              <i onClick={(e)=>selectEdit(true)} class="far fa-edit"></i>
            } */}
          </div>

            
            <div className="cm-col">
                <label className="cm-head-label">OVERRIDE AMOUNT</label>
                <div className="row">
                    <div className="col-md-4">
                        <input 
                            className="form-control cm-input"
                        />
                    </div>
                    <div className="col-md-4">
                        <input 
                            className="form-control cm-input"
                        />
                    </div>
                </div>
            </div>

            <div className="row cm-col">
                <div className="col-md-2">
                    <label className="cm-head-label">Agent</label>
                </div>
                <div className="col-md-2">
                    <select className="form-control cm-input-select">
                        <option>Include</option>
                        <option>Exclue</option>
                    </select>
                </div>
                <div className="col-md-8">
                    <select className="form-control cm-input-select">
                        <option>Select</option>
                        <option></option>
                    </select>
                </div>
            </div>
            
            <div className="row cm-col">
                <div className="col-md-2">
                    <label className="cm-head-label">Agency</label>
                </div>
                <div className="col-md-2">
                    <select className="form-control cm-input-select">
                        <option>Include</option>
                        <option>Exclue</option>
                    </select>
                </div>
                <div className="col-md-8">
                    <select className="form-control cm-input-select">
                        <option>Select</option>
                        <option></option>
                    </select>
                </div>
            </div>
            
            <div className="row cm-col">
                <div className="col-md-2">
                    <label className="cm-head-label">Chain</label>
                </div>
                <div className="col-md-2">
                    <select className="form-control cm-input-select">
                        <option>Include</option>
                        <option>Exclue</option>
                    </select>
                </div>
                <div className="col-md-8">
                    <select className="form-control cm-input-select">
                        <option>Select</option>
                        <option></option>
                    </select>
                </div>
            </div>
            
            <div className="row cm-col">
                <div className="col-md-2">
                    <label className="cm-head-label">Branch i.e PCC</label>
                </div>
                <div className="col-md-2">
                    <select className="form-control cm-input-select">
                        <option>Include</option>
                        <option>Exclue</option>
                    </select>
                </div>
                <div className="col-md-8">
                    <select className="form-control cm-input-select">
                        <option>Select</option>
                        <option></option>
                    </select>
                </div>
            </div>
        </div>
        </div>
    )
}
