import { env } from '../appConfig'


export const getAllSearchCriteria = (flightTypeData:any = "") => {


    return async (dispatch:any, getState:any) => {

        // const tokenData = getState().initToken;
        // const token = tokenData && tokenData.token && tokenData.token.token;
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType
        const newFlightType = flightType === flightTypeData ? flightType : flightTypeData
        //let corrId = uuidv4();
        let url:any = env.FARE_CACHE_BASE_URL + `/cacheSearchCriterias/getAllSearchCriterias`;
        const requestOptions:any = {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:newFlightType 

            }
        }


        const searchCriteria =   await fetch(url, requestOptions)
           .then(res => res.json())
           .then(response => {
               const data =  response;
               console.log("data",data)
               return data;
               })
             .catch(err => {
               console.log('get all failed with exception',err)
             })
         

                    if(searchCriteria === null){
                        // get all failed
                    }
        if (searchCriteria) {
            return searchCriteria
        }else{
            return searchCriteria
        }
        
    }
}
