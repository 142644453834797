//@ts-nocheck
import React, { useEffect, useState } from 'react';
import BookingSubHead from './BookingSubHead';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from "react-redux";
import MarkupIcon from "../bookingConfirmation/Images/markup-icon.svg";
import { setAdultMarkupValue, setChildMarkupValue, setInfantMarkupValue } from "../bookingConfirmation/ActionCreator/searchForm";
import { setItenaryData, setMarkupApplied, setMarkupLoader, setPaymentRules, setPaymentRulesForCheque } from "../bookingConfirmation/ActionCreator/flightBooking"
import { getPaymentRules } from "../bookingConfirmation/thunks/getPaymentRules"
import { bookingStatus, fareTypeConstant, PassengerType } from '../bookingConfirmation/Utils/Constant';
import {msg} from "../bookingConfirmation/Utils/MessagesContainer"
import { getEquivalentPublishFare } from '../../appStore/thunks/getEquivalentPublishFare';
import Dialog from "@material-ui/core/Dialog";
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import Button from "react-bootstrap/Button";
import SpinnerLoader from '../common/spinner';
import { getSaveMarkup } from '../bookingConfirmation/thunks/getSaveMarkup';
//import { toast } from 'react-toastify';
import { getPnrDetails } from '../../appStore/thunks/getPnrDetails';

//toast.configure()
function Markup(props) {

    const dispatch = useDispatch();
    let priceValue = useSelector((state) => state.flightBooking.bookingItenaries);
    const agentProfile = useSelector((state) => state.agentProfile);
    const { orgId, agencyId, agencyGroupId} = agentProfile
    
    const paymentRules = useSelector((state) => state.flightBooking.paymentRules);
    let fareId = useSelector((state) => state.searchForm.bookingFareId);
    const markupStatus = useSelector((state) => state.flightBooking.markupStatus);
    const bookingWithQuote = useSelector((state) => state.savedQuotes.bookingWithQuote);
    let adultsMarkup = useSelector((state) => state.searchForm.adultMarkupValue);
    let childMarup = useSelector((state) => state.searchForm.childMarkupValue);
    let infantMarkup = useSelector((state) => state.searchForm.infantMarkupValue);
    const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
    const flightDetail = useSelector((state) => state.flightBooking);

    let adultsCount 
    let childCount 
    let infantCount
  
    const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
    const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
    const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
    const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
    const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
    const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
    const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
    const equivalentFareValue = useSelector((state) => state.flightBooking.equivalentFareValue);
    const markupLoader = useSelector((state) => state.flightBooking.markupLoader);
    const providerId = useSelector((state) => state.searchForm.providerId);
    const agencyDetails = useSelector((state) => state.agentProfile.agencyDetails);
    const agentDetails = useSelector((state) => state.agentProfile.agentDetails);
    const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
   
    let fareType = priceValue && priceValue.type
    let repriceQuote = useSelector((state) => state.savedQuotes.repriceQuote);
    let fareDetails = repriceQuote && repriceQuote.fareDetails
    let quoteDetail = fareDetails && fareDetails.itineraryFare
    if (bookingWithQuote) {
        priceValue = quoteDetail
        fareType = quoteDetail && quoteDetail.type
    }
    const faresPerTraveller = priceValue && priceValue.faresPerTraveller; 
    const equivalentFareLoader = useSelector((state) => state.flightBooking.equivalentFareLoader);
    
    let paxFareData = equivalentFareValue && equivalentFareValue.paxFares;
    let paxTypeCodeArray = []

    faresPerTraveller && faresPerTraveller.map(data => {
        let paxTypeCode = data && data.passengerDetails && data.passengerDetails.code
        
        if (!paxTypeCodeArray.includes(paxTypeCode)) {
            paxTypeCodeArray.push(paxTypeCode)
        }
    })

    let amountInfo= []
    let paxTypeAmount = paxFareData

        if (pnrFlag) {
            adultsCount = pnrAdultsCount
            childCount = pnrChildCount
            infantCount = pnrInfantCount
        } else {
            adultsCount = searchAdultCOunt
            childCount = searchChildCount
            infantCount = searchInfantCount
        }
       
        paxTypeAmount && paxTypeAmount.map((paxType, index) => {
            let passengerDetails = paxType.passengerDetails;
            let type = passengerDetails && passengerDetails.type;
            let quantity = passengerDetails && passengerDetails.quantity
            let totalAmount = paxType.totalAmount
            let baseAmount = paxType.baseAmount
            let supplierBaseAmount = paxType.supplierBaseAmount
            let totalTaxes = paxType.totalTaxes
            let taxes = paxType.taxes
            let cnt = quantity;
            // let paxTypeCode = paxType.passengerDetails.code
            // if (!paxTypeCodeArray.includes(paxTypeCode)) {
            //     paxTypeCodeArray.push(paxTypeCode)
            // }


            let ispreset = amountInfo.filter(obj => obj.type === type)

            if (ispreset.length === 0) {
                let passenger = {
                    type: type,
                    quantity: quantity,
                    totalAmount: totalAmount.amount,
                    baseAmount: baseAmount.amount,
                    totalTaxes: totalTaxes.amount,
                    taxes: taxes,
                    supplierBaseAmount: supplierBaseAmount && supplierBaseAmount.amount
                }
                amountInfo.push(passenger)
            }
        })

  
    if (pnrFlag) {
        adultsCount = pnrAdultsCount
        childCount = pnrChildCount
        infantCount = pnrInfantCount
    } else {
        adultsCount = searchAdultCOunt
        childCount = searchChildCount
        infantCount = searchInfantCount
    }

    let passengetCount = adultsCount + childCount + infantCount;
    const currency = priceValue && priceValue.baseAmount && priceValue.baseAmount.currency
    const contractdetails = priceValue && priceValue.applicableContract && priceValue.applicableContract.passBackCommission
    
    const recordLocator = flightDetail && flightDetail.pnr;
    let agencyMarkups = bookingData&&bookingData.agencyMarkups
    const bookingCode = flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code
    let errorMsg =  msg['114']

    let arr = []
    let passengerRef = []

    const [adultMarkupError,setAdultErrorMarup] = useState("")
    const [childMarkUpValueError,setChildErrorMarup] = useState("")
    const [infantMarkUpValueError,setInfantErrorMarup] = useState("")
    const [adultMarkup, setMarkUpValue] = useState(adultsMarkup.length>0?adultsMarkup:0.00)
    const [childMarkUpValue, setChildMarkUpValue] = useState(childMarup.length>0?childMarup:0.00)
    const [infantMarkUpValue, setInfantMarkUpValue] = useState(infantMarkup.length>0?infantMarkup:0.00)
    const [open, setOpen] = React.useState(false);
    const [isLoader, setLoder] = useState(false);
    const [saveMarkupErrorMsg,setMarkupErrorMsg] = useState("");
    
    const agencyData =
    {
      code: agencyDetails.adAgencyCode,
      name: agencyDetails.adTravelAgencyName,
      networkId: agencyDetails.adNetworkId,
      address: {
        line1: agencyDetails.adAddressLine1,
        line2: agencyDetails.adAddressLine2,
        city: {
          name: agencyDetails.adCity,
          // code: agencyDetails.adCity
        },
        state: {
          // name: agencyDetails.provinceOrState,
          code: agencyDetails.provinceOrState
        },
        country: {
          // name: agencyDetails.country,
          code: agencyDetails.country
        },
        postalCode: agencyDetails.adPostalCode,
        addressType: "Unspecified" //Unspecified,Home,Business,Office,Billing,Residential,Destination
      },
      phone: {
        number: agencyDetails.adNetworkId,
        type: "Unknown" //Unknown,Fax,Home,Business,Agency,Phone,Mobile
      },
      agencyGroupCode: agencyDetails.consortiumId
    };
  
    const travelAgent = {
        firstName: isInternalUserAutheticated ? agentDetails.firstName : agencyDetails.firstName,
        lastName: isInternalUserAutheticated ? agentDetails.lastName : agencyDetails.lastName,
        userName: isInternalUserAutheticated ? agentDetails.username : agencyDetails.username
    };
     
    for (let i = 1; i <= passengetCount; i++) {
        if (i <= adultsCount) {
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.ADULT
                },
            ];
            passengerRef.push(`${i}`)
        } else if (i > adultsCount && i <= adultsCount + childCount) {
            let j = i - adultsCount;
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.CHILD
                },
            ];
            passengerRef.push(`${i}`)
        } else {
            let j = i - (adultsCount + childCount);
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.INFANTINLAP,
                },
            ];
            passengerRef.push(`${i}`)
        }
    }

    useEffect(() => {
        if(props.from !=="confirmation"){
           // dispatch(setMarkupApplied(false))
        } else{
            let adultData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.ADULT)
            let childData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.CHILD)
            let infantdata = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.INFANTINLAP)
            let adultAmount = adultData && adultData[0] ? adultData[0].perPaxAmount &&adultData[0].perPaxAmount.amount :""
            let childAmount = childData && childData[0] ? childData[0].perPaxAmount &&childData[0].perPaxAmount.amount :""
            let infantAmount =infantdata && infantdata[0] ? infantdata[0].perPaxAmount &&infantdata[0].perPaxAmount.amount :""
          

            dispatch(setMarkupApplied(true))
            dispatch(setAdultMarkupValue(adultsMarkup?adultsMarkup:adultAmount))
            dispatch(setChildMarkupValue(childMarup?childMarup:childAmount))
            dispatch(setInfantMarkupValue(infantMarkup?infantMarkup:infantAmount))
            setMarkUpValue(adultsMarkup ? `${adultsMarkup}`: `${adultAmount}`)
            setChildMarkUpValue(childMarup?`${childMarup}`:`${childAmount}`)
            setInfantMarkUpValue(infantMarkup?`${infantMarkup}`:`${infantAmount}`)

        }
       
    }, [])

// AFTER GET BOOKING DETAILS UPDATING MARKUP DETAILS IN STORE
    useEffect(() => {
          if(agencyMarkups && agencyMarkups.length > 0){
            let adultData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.ADULT)
            let childData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.CHILD)
            let infantdata = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.INFANTINLAP)
            let adultAmount = adultData && adultData[0] ? adultData[0].perPaxAmount &&adultData[0].perPaxAmount.amount :""
            let childAmount = childData && childData[0] ? childData[0].perPaxAmount &&childData[0].perPaxAmount.amount :""
            let infantAmount =infantdata && infantdata[0] ? infantdata[0].perPaxAmount &&infantdata[0].perPaxAmount.amount :""
          
            dispatch(setAdultMarkupValue(adultAmount !== "" ? adultAmount : adultsMarkup))
            dispatch(setChildMarkupValue(childAmount !== "" ? childAmount : childMarup))
            dispatch(setInfantMarkupValue(infantAmount !== "" ? infantAmount : infantMarkup))
            setMarkUpValue(adultAmount !== "" ? `${adultAmount}`: `${adultsMarkup}`)
            setChildMarkUpValue(childAmount !== "" ?`${childAmount}`:`${childMarup}`)
            setInfantMarkUpValue(infantAmount !== "" ?`${infantAmount}`:`${infantMarkup}`)
        }
    }, [bookingData])
    

    useEffect(()=>{

        if(bookingWithQuote && (adultsMarkup !== 0 || childMarup !== 0 || infantMarkup !== 0)){

            dispatch(setMarkupApplied(true))
            dispatch(setAdultMarkupValue(adultsMarkup))
            dispatch(setChildMarkupValue(childMarup))
            dispatch(setInfantMarkupValue(infantMarkup))
            setMarkUpValue(adultsMarkup)
            setChildMarkUpValue(childMarup)
            setInfantMarkupValue(infantMarkup)
            

        }else if(props.from !=="confirmation"){
           // dispatch(setMarkupApplied(false))
        }

    },[bookingWithQuote])

    const addMarkUpValueInfo = (e) => {

        if(e.target.value < 0){
            setMarkUpValue("")
        } else{
            if(e.target.value.length > 3){
                // setMarkUpValue(adultMarkup)
                setMarkUpValue(e.target.value)
            } else {
                setMarkUpValue(e.target.value)
            }
           
            let value = e.target.value
            
           // dispatch(setAdultMarkupValue(value.trim().length>0?value:0))
            if(value.trim().length > 0){
                setAdultErrorMarup("")
            } else{
                setAdultErrorMarup(errorMsg)
            }
         }
         
    }

    const addChildMarkUpValueInfo = (e) => {

        if(e.target.value < 0){
            setChildMarkUpValue("")
        }
         else {
            if(e.target.value.length > 3){
                // setChildMarkUpValue(childMarkUpValue)
                setChildMarkUpValue(e.target.value)
            } else {
                setChildMarkUpValue(e.target.value)
            }
            let value = e.target.value
           // dispatch(setChildMarkupValue(value.trim().length>0?value:0))
            if(value.trim().length>0){
                setChildErrorMarup("")
            } else{
                setChildErrorMarup(errorMsg)
            }  

        }
       
    }

    const addInfantMarkUpValueInfo = (e) => {
       
        if(e.target.value < 0){
            setInfantMarkUpValue("")
        }
        else {
          if(e.target.value.length > 3){
            // setInfantMarkUpValue(infantMarkUpValue)
            setInfantMarkUpValue(e.target.value)
        } else {
            setInfantMarkUpValue(e.target.value)
        }
          let value = e.target.value
        //  dispatch(setInfantMarkupValue(value.trim().length>0?value:0))
          if(value.trim().length>0){
              setInfantErrorMarup("")
          } else{
              setInfantErrorMarup(errorMsg)
          }
        }  
        
    }

    const handleMarkUpSubmit = async() => {
        if(adultMarkupError !== "" || childMarkUpValueError !== "" || infantMarkUpValueError !== "") return 
        setLoder(true)
        dispatch(setMarkupLoader(true))
      
        if(adultMarkup.toString() && adultMarkup.toString().trim().length===0 && adultsCount>0){
           return setAdultErrorMarup(errorMsg)
        }
        if(childMarkUpValue.toString() && childMarkUpValue.toString().trim().length===0 && childCount>0){
            return setChildErrorMarup(errorMsg)
            
        }
        if(infantMarkUpValue.toString() && infantMarkUpValue.toString().trim().length===0 && infantCount>0){
            return setInfantErrorMarup(errorMsg)
            
        }
       
        let agencyArr = []
        if (adultsCount > 0) {
            let obj = {
                paxType: PassengerType.ADULT,
                quantity: adultsCount,
                perPaxAmount: {
                    amount: adultMarkup ? adultMarkup : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }
        if (childCount > 0) {
            let obj = {
                paxType: PassengerType.CHILD,
                quantity: childCount,
                perPaxAmount: {
                    amount: childMarkUpValue ? childMarkUpValue : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }

        if (infantCount > 0) {
            let obj = {
                paxType: PassengerType.INFANTINLAP,
                quantity: infantCount,
                perPaxAmount: {
                    amount: infantMarkUpValue ? infantMarkUpValue : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }

        // SAVE AGENCY MARKUP 
        let saveAgencySucessFlag = false
        if(props.from === "confirmation"){
            let saveMarkupReqPayload = {
                organizationId: orgId,
                agencyId: agencyId,
                agencyGroupId: agencyGroupId,
                passengers: arr,
                recordLocator: recordLocator,
                agencyMarkups: agencyArr,
               // defaultAppliedPaymentRules:defaultAppliedPaymentRules
              }
               let result =  await dispatch(getSaveMarkup(saveMarkupReqPayload));
                if(result && result.status === "Success"){
                    saveAgencySucessFlag = true;
                }
                if(result && result.status === "Failure"){
                    let errorMsg = result && result.error && result.error.message
                    setMarkupErrorMsg(errorMsg);
                    dispatch(setMarkupLoader(false))
                    dispatch(setMarkupApplied(false))
                    saveAgencySucessFlag = false;
                    //toast.error(`Failed to Add/Update Markup,Please Try Again`, { position: toast.POSITION.TOP_CENTER, autoClose: true, progressStyle: { "transform-origin": "right" } });
            }
        }else{
            saveAgencySucessFlag = true;
        }
       
       if(saveAgencySucessFlag){
        let reqPayload={}
        if(props.from==="confirmation"){
            reqPayload = {
                recordLocator: recordLocator,
                fareType: fareType,
                passengers: arr,
                agencyMarkups: agencyArr,
                payments: [{
                    passengerRefs: passengerRef,
                    type: "CreditCard"
                }]
            }

        } else{
            reqPayload = {
                fareId: fareId,
                fareType: fareType,
                passengers: arr,
                agencyMarkups: agencyArr,
                payments: [{
                    passengerRefs: passengerRef,
                    type: "CreditCard"
                }]
            }
        }
       let resultOfPaymentRulesByCard =  await dispatch(getPaymentRules(reqPayload,"CreditCard","fromMarkup"))
       let resultOfPaymentRulesByCheque;
       if(resultOfPaymentRulesByCard){
        if(props.from==="confirmation"){
            reqPayload = {
                recordLocator: recordLocator,
                fareType: fareType,
                passengers: arr,
                agencyMarkups: agencyArr,
                payments: [{
                    passengerRefs: passengerRef,
                    type: "Cheque"
                }]
            }

        } else{
            reqPayload = {
                fareId: fareId,
                fareType: fareType,
                passengers: arr,
                agencyMarkups: agencyArr,
                payments: [{
                    passengerRefs: passengerRef,
                    type: "Cheque"
                }]
            }
        }

        resultOfPaymentRulesByCheque = await dispatch(getPaymentRules(reqPayload,"Cheque","fromMarkup"))
       }

       //    let passengerWiseTransactionsDetails = resultOfPaymentRulesByCard && resultOfPaymentRulesByCard.PassengerWiseTransactionsDetails

        dispatch(setMarkupLoader(false))
        dispatch(setMarkupApplied(true))
        dispatch(setPaymentRules(resultOfPaymentRulesByCard)) 
        dispatch(setPaymentRulesForCheque(resultOfPaymentRulesByCheque))
        dispatch(setAdultMarkupValue(adultMarkup))
        dispatch(setChildMarkupValue(childMarkUpValue))
        dispatch(setInfantMarkupValue(infantMarkUpValue))
        setLoder(false)
        dispatch(setMarkupLoader(false))
        if(bookingCode === ""){
            dispatch(setAdultMarkupValue(adultMarkup))
            dispatch(setChildMarkupValue(childMarkUpValue))
            dispatch(setInfantMarkupValue(infantMarkUpValue))
            dispatch(setPaymentRules(resultOfPaymentRulesByCard)) 
            dispatch(setPaymentRulesForCheque(resultOfPaymentRulesByCheque))
        }
      }  

    }

    const editMarkup = () => {
        dispatch(setMarkupApplied(false))
    }

    const blockInvalidChar = e => {
        ["e", "E", "+", "-" , "@"].includes(e.key) && e.preventDefault()
        if(e.key==='.'){
            e.preventDefault();
        }
    }
    
    const handleEquivalentFare = () => {
        console.log("handleClick123")
        setOpen(true)
        // dispatch(getEquivalentPublishFare(fareId))
    }
    const handleEquivalentFareClose = () => {      
        setOpen(false)
        // dispatch(getEquivalentPublishFare(fareId))
    }

let totalAmount;
let paxTypeConatinsArr = ["JCB","ITX","PFA","PV"]
let paxTypeFlag = paxTypeConatinsArr.some(ai => paxTypeCodeArray.includes(ai));

    return (
        <>
        {props.show ? 
            <div>

            <div className="bd-configProfit">
                <BookingSubHead 
                title={"Add your markup amount"} 
                link={false} 
                imgSrc={MarkupIcon} 
                //addLink={props.from==="confirmation" ? (markupStatus && !markupLoader && bookingCode==="NP" && "Edit Markup"): (markupStatus && !markupLoader && "Edit Markup")} 
                //status={markupStatus && !markupLoader && "Markup Applied !"} 
                equivalentFare = {paxTypeFlag && fareType === fareTypeConstant.NET && handleEquivalentFare}
                //editMarkup={editMarkup} 
                />
                
                {markupLoader ?
                <div>
                    <div className="col-md-12 text-center mt-3">
                        <div className="firstDivs"> Waiting For Markup Update...</div>
                        {/* <SpinnerLoader /> */}
                    </div>              
                </div>  
                :

                <div className="configProfit-row">
                    {saveMarkupErrorMsg !== "" && saveMarkupErrorMsg !== undefined &&
                     <div className='payment-error-msg-wrapper'>
                        <div className="seat-udate-status">
                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span>{saveMarkupErrorMsg}</span>
                        </div>
                    </div>
                    } 
                    <div className="row space-between">
                    <div className="configProfit-col" >
                        <div className="title">Per Adult  ( X {adultsCount} )</div>
                        <div className="configProfit-input pt-3">
                            {fareType === "Published" && contractdetails && contractdetails.amountPerAdult && contractdetails.amountPerAdult.amount > 0 &&
                                // <div className="configProfit-input-label">
                                //     Current Markup<span>{contractdetails?.amountPerAdult?.amount}</span>
                                // </div>
                                <div className="bd-pi-input">
                                 <TextField
                                 id="currentMarkup"
                                 label="Current Commission"
                                 variant="outlined"
                                 value={contractdetails?.amountPerAdult?.amount}
                                 disabled={true}
                                
                             />
                             </div>
                                }

                            <div className="bd-pi-input">
                                
                                <TextField
                                    id="additionalMarkup1"
                                    label="Additional Markup"
                                    variant="outlined"
                                    value={adultMarkup}
                                    type="number"
                                    inputProps={{ min: 0}}
                                    disabled={markupStatus}
                                    error={adultMarkupError !== ""}
                                    helperText={adultMarkupError !== "" && adultMarkupError}
                                    onChange={(e) => addMarkUpValueInfo(e)}
                                    onKeyDown={blockInvalidChar}
                                    
                                />
                                <span className="configProfit-dollar">$</span>
                                {/* <span className="configProfit-plus"><i>x</i> {adultsCount} </span> */}

                            </div>
                        </div>
                    </div>


                    {childCount !== 0 &&
                        <div className="configProfit-col">
                            <div className="title">Per child ( x {childCount} )</div>
                            <div className="configProfit-input pt-3">
                                {fareType === "Published" && contractdetails && contractdetails.amountPerChild && contractdetails.amountPerChild.amount > 0 &&
                                    // <div className="configProfit-input-label">
                                    //     Current Markup<span>{contractdetails?.amountPerChild?.amount}</span>
                                    // </div>}
                                    <div className="bd-pi-input">
                                    <TextField
                                    id="currentMarkup"
                                    label="Current Commission"
                                    variant="outlined"
                                    value={contractdetails?.amountPerChild?.amount}
                                    disabled={true}
                                   
                                />
                                </div>
}
                                <div className="bd-pi-input">
                                   
                                    <TextField
                                        id="additionalMarkup2"
                                        label="Additional Markup"
                                        variant="outlined"
                                        type="number"
                                        inputProps={{ min: 0}}
                                        value={childMarkUpValue}
                                        disabled={markupStatus}
                                        error={childMarkUpValueError !== ""}
                                        helperText={childMarkUpValueError !== "" && childMarkUpValueError}
                                        onChange={(e) => addChildMarkUpValueInfo(e)}
                                        onKeyDown={blockInvalidChar}
                                    />
                                    <span className="configProfit-dollar">$</span>
                                    {/* <span className="configProfit-plus"><i>x</i> {childCount} </span> */}
                                   
                                </div>
                            </div>
                        </div>
                    }

                    
                    {infantCount !== 0 &&
                        <div className="configProfit-col ">
                            <div className="title">Per Infant ( x {infantCount} )</div>
                            <div className="configProfit-input pt-3">
                                {fareType === "Published" && contractdetails && contractdetails.amountPerInfantWithSeat && contractdetails.amountPerInfantWithSeat.amount > 0 &&
                                   
                                    <div className="bd-pi-input">
                                    <TextField
                                    id="currentMarkup"
                                    label="Current Commission"
                                    variant="outlined"
                                    value={contractdetails?.amountPerInfantWithSeat?.amount}
                                    disabled={true}
                                   
                                />
                                </div>
}
             
                                <div className="bd-pi-input">
                                    <TextField
                                        id="additionalMarkup2"
                                        label="Additional Markup"
                                        variant="outlined"
                                        type="number"
                                        inputProps={{ min: 0}}
                                        value={infantMarkUpValue}
                                        disabled={markupStatus}
                                        error={infantMarkUpValueError !== ""}
                                        helperText={infantMarkUpValueError !== "" && infantMarkUpValueError}
                                        onChange={(e) => addInfantMarkUpValueInfo(e)}
                                        onKeyDown={blockInvalidChar}
                                    />
                                    <span className="configProfit-dollar">$</span>
                                   
                                   
                                </div>
                            </div>
                        </div>
                    }

                    <div className="configProfit-col align-self-end">
                        {markupStatus ? <div />
                           : <button className="configProfit-btn" onClick={handleMarkUpSubmit}>Apply Markup</button>}


                    </div>
                    </div>

                   
                </div>
             }
            </div>
            {/* EQUIVALENT FARE BREAKDOWN */}
            <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            keepMounted
            onClose={handleEquivalentFareClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="fareType-dialog newComp"
            >{equivalentFareLoader ?
            <div className="bd-pi-contact-modal bd-alertBox fareType-table">
                <div className="head">
                <div className='fd-subHead'>
                <span>
                    Equivalent Published Fare
                </span>
                </div>
                    <button onClick={handleEquivalentFareClose}>&times;</button>
                </div>
               <div className="col-md-12 text-center mt-3">
                {/* <SpinnerLoader /> */}
                </div>
            </div>
             :           
            <div className="bd-pi-contact-modal bd-alertBox fareType-table">
                <div className="head">
                <div className='fd-subHead'>
                <span>
                    Equivalent Published Fare
                </span>
                </div>
                    <button onClick={handleEquivalentFareClose}>&times;</button>
                </div>
                <>
                {equivalentFareValue === "NoFare" ?
                 <div>
                  We are sorry,there is no equivalent published fare for this itinerary. 
                 </div>
                 :
                 <div className="bd-priceSummary-box">
                    <div className="bd-priceSummary">
                        <>
                           {amountInfo.map((data,index) => {
                            totalAmount += data.totalAmount
                            return(
                            <div key={index}>
                            <div className="bd-priceSummary-details sub-head">
                            <span>
                              Per {data.type === PassengerType.INFANTINLAP ? "Infant" : data.type}
                            </span>
                            </div>
                            <div className="bd-priceSummary-details">
                                <span className="label-text">Base Fare </span>                               
                                    <span className="label-net">
                                        ${data.type === PassengerType.INFANTINLAP && (Number(data.baseAmount)).toFixed(2)}
                                        {data.type === PassengerType.ADULT && (Number(data.baseAmount)).toFixed(2)}
                                        {data.type === PassengerType.CHILD && (Number(data.baseAmount)).toFixed(2)}
                                    </span> 
                            </div>
                            {Number(data.totalTaxes) !== 0 &&
                            <div className="bd-priceSummary-details">
                                <span className="label-text"
                                >
                                    Airline taxes & fees
                                </span>
                            <span className="label-net">$  {(Number(data.totalTaxes)).toFixed(2)}</span>
                            </div>} 
                            {/* {bookingfee !== 0 &&
                                <div className="bd-priceSummary-details">
                                    <span className="label-text"
                                    >
                                        Booking fee
                                    </span>
                                    <span className="label-value"> </span>
                                    <span className="label-net">$ {(Number(bookingfee)).toFixed(2)}</span>
                              </div>}
                            {ticketingfee !== 0 &&
                                <div className="bd-priceSummary-details">
                                    <span className="label-text"
                                    >
                                        Ticketing fee
                                    </span>
                                    <span className="label-value"> </span>
                                    <span className="label-net">$ {(Number(ticketingfee)).toFixed(2)}</span>
                                </div>} */}
                          
                            <div className="bd-priceSummary-details ft-total">
                                <span className="label-text">
                                    Total                                   
                                </span> 
                                <span className="label-net">
                                $ {" "}
                                    {(Number(data.baseAmount) + Number(data.totalTaxes)).toFixed(2)}
                                </span>
                            </div>
                            </div>
                            )

                           })}
                        </>
                       
                    </div>

                </div>
                 }
                 </>               
               

            </div>
              }
        </Dialog>
        </div>:<div/>}


            {/* END OF EQUIVALENT FARE BREAKDOWN */}
        </>
    );
}

export default Markup;