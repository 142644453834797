import { SET_BARNDED_FARE_TARIFF, SET_BRAND_ID_CLASS_TARIFF, SET_CABIN_TYPE_TARIFF, SET_CARRIER_TARIFF, SET_CATEGORY_CLASS_TARIFF, SET_CLASS_CLASS_TARIFF, SET_CLASS_KEY_CLASS_TARIFF, SET_COMMON_DATA_TARRIF, SET_CRUISE_FARE_TYPE_TARIFF, SET_DESC_CLASS_TARIFF, SET_EDR_END_CLASS_TARIFF, SET_EDR_END_DATE_PRIX, SET_EDR_LAST_TICKETING_DATE_PRIX, SET_EDR_START_CLASS_TARIFF, SET_EDR_START_DATE_PRIX, SET_EDR_TICKETING_TARIFF, SET_FARE_TYPE_CLASS_TARIFF, SET_KEY_TARIFF, SET_LAND_FARE_TYPE_TARIFF, SET_LAST_RETURN_DATE_PRIX, SET_NOTE_CLASS_TARIFF, SET_NOTE_PRIX, SET_PRIX_KEY_PRIX, SET_TRIP_TYPE_TARIFF, SET_VALIDITY_FROM_PRIX, SET_VALIDITY_TO_PRIX, SET_VIA_TARIFF, SET_CHECKBOX_OBJECT, SET_TARIF_DETAILS, SET_CLASS_DATA, SELECTED_TARIF_DATA, SET_SEARCH_ALL_V2, SET_PRIX_DETAIL } from "../../../pages/bookingConfirmation/storeConstant";

const initialState = {
    commonDataTarrif: [],
    carrierTariff: "",
    keyTariff: "",
    viaTariff: "",
    cabinTypeTariff: "",
    tripTypeTariff: "",
    landFareTariff: 0,
    cruiseFareTariff: 0,
    brandedFareTariff: null,
    
    brandIdClassTariff: "",
    classeClassTariff: "",
    edrLastTickitingTariff: "",
    categoryClassTariff: "",
    descClassTariff: "",
    noteClassTariff: "",
    classKeyClassTariff: "",
    fareTypeClassTariff: "",
    edrStartDateTarrif: "",
    edrLastDateTariff: "" ,

    validityFromPrixTariff: "",
    validityToPrixTariff: "",
    lastReturnDatePrixTariff: "",
    edrLastTickitingDatePrixTariff: "",
    prixKeyPrixTariff: "",
    notePrixTariff: "",
    edrStartDatePrixTariff: "",
    edrEndDatePrixTariff: "",
    checkBoxObject: {"tarifs":[],"classes":[],"prixs":[]},
    tarifDetail: {},
    classData:[],
    tarifData:{},
    searchAllV2Result: [],
    prixDetail: {}
}

const commonDataReducer = (state = initialState , action : any) => {

    switch(action.type) {
        case SET_COMMON_DATA_TARRIF:
            return {
                ...state,
                commonDataTarrif: action.payload
            }
        case SET_CARRIER_TARIFF:
            return {
                ...state,
                carrierTariff: action.payload
            }
        case SET_KEY_TARIFF:
            return {
                ...state,
                keyTariff: action.payload
            }
        case SET_VIA_TARIFF:
            return {
                ...state,
                viaTariff: action.payload
            }
        case SET_CABIN_TYPE_TARIFF:
            return {
                ...state,
                cabinTypeTariff: action.payload
            }
        case SET_TRIP_TYPE_TARIFF:
            return {
                ...state,
                tripTypeTariff: action.payload
            }
        case SET_LAND_FARE_TYPE_TARIFF:
            return {
                ...state,
                landFareTariff: action.payload
            }
        case SET_CRUISE_FARE_TYPE_TARIFF:
            return {
                ...state,
                cruiseFareTariff: action.payload
            }
        case SET_BARNDED_FARE_TARIFF:
            return {
                ...state,
                brandedFareTariff: action.payload
            }
        case SET_BRAND_ID_CLASS_TARIFF:
            return {
                ...state,
                brandIdClassTariff: action.payload
            }
        case SET_CLASS_CLASS_TARIFF:
            return {
                ...state,
                classeClassTariff: action.payload
            }
        case SET_EDR_TICKETING_TARIFF:
            return {
                ...state,
                edrLastTickitingTariff: action.payload
            }
        case SET_CATEGORY_CLASS_TARIFF:
            return {
                ...state,
                categoryClassTariff: action.payload
            }
        case SET_DESC_CLASS_TARIFF:
            return {
                ...state,
                descClassTariff: action.payload
            }
        case SET_NOTE_CLASS_TARIFF:
            return {
                ...state,
                noteClassTariff: action.payload
            }
        case SET_CLASS_KEY_CLASS_TARIFF:
            return {
                ...state,
                classKeyClassTariff: action.payload
            }
        case SET_FARE_TYPE_CLASS_TARIFF:
            return {
                ...state,
                fareTypeClassTariff: action.payload
            }
        case SET_EDR_START_CLASS_TARIFF:
            return {
                ...state,
                edrStartDateTarrif: action.payload
            }
        case SET_EDR_END_CLASS_TARIFF:
            return {
                ...state,
                edrLastDateTariff: action.payload
            }
        case SET_VALIDITY_FROM_PRIX:
            return {
                ...state,
                validityFromPrixTariff: action.payload
            }
        case SET_VALIDITY_TO_PRIX:
            return {
                ...state,
                validityToPrixTariff: action.payload
            }
        case SET_LAST_RETURN_DATE_PRIX:
            return {
                ...state,
                lastReturnDatePrixTariff: action.payload
            }
        case SET_EDR_LAST_TICKETING_DATE_PRIX:
            return {
                ...state,
                edrLastTickitingDatePrixTariff: action.payload
            }
        case SET_PRIX_KEY_PRIX:
            return {
                ...state,
                prixKeyPrixTariff: action.payload
            }
        case SET_NOTE_PRIX:
            return {
                ...state,
                notePrixTariff: action.payload
            }
        case SET_EDR_START_DATE_PRIX:
            return {
                ...state,
                edrStartDatePrixTariff: action.payload
            }
        case SET_EDR_END_DATE_PRIX:
            return {
                ...state,
                edrEndDatePrixTariff: action.payload
            }
        case SET_CHECKBOX_OBJECT:
            return {
                ...state,
                checkBoxObject: action.payload
            }
        case SET_TARIF_DETAILS:
            return {
                ...state,
                tarifDetail: action.payload
            }        
        case SET_CLASS_DATA:
            return {
                ...state,
                classData: action.payload
            }
        case SELECTED_TARIF_DATA:
            return {
                ...state,
                tarifData: action.payload
            }
        case SET_SEARCH_ALL_V2:
            return {
                ...state,
                searchAllV2Result: action.payload
            }  
        case SET_PRIX_DETAIL:
            return {
                ...state,
                prixDetail: action.payload
            }        
        default:
            return state    
    }
}

export default commonDataReducer;