
import {
    SET_ITINENARY_DATA, SET_LOADING_DATA, SET_ACTIVE_ITINENARY_RESULT_KEY, SET_GROUP_ITINENARY_DATA,
    SET_SEAT_MAP_DATA, SET_TIME_TAKEN, SET_ITINENARY_ROWS_KEYS, SET_DETAILED_ITINENARY_ROWS, SET_COUNT,
    SET_SEAT_MAP_DETAIL, SET_SEAT, SET_FLIGHT_DATA, SET_SEATMAP_LOADING_DATA, SET_PREV_SEAT,
    SET_EDIT_SEAT, SET_SAVE_SEAT,SET_PREPAID_SEAT_STATUS, SET_CARRIER_PAID_SEAT_DATA,SET_EDIT_SEAT_RECORD, SET_IS_PASSPORT_MANDATORY, SET_IS_EMAIL_MANDATORY,SET_IS_MOBILE_MANDATORY, SET_OPERATING_CARRIER_PAID_SEAT_DATA, SET_MARKETING_CARRIER_PAID_SEAT_DATA
} from '../storeConstant';

const initialState = {
    itinenary: [],
    loading: false,
    activeItinenaryResultKey: "",
    groupItinary: "",
    seatMap: [],
    timeTaken: "",
    itinenaryRowsKeys: [],
    detailedItinenaryRows: [],
    count: 0,
    singleSeatMap: [],
    seat: [],
    flightData: {},
    seatLoading: false,
    prevSeat: [],
    editSeat: [],
    saveSeat: [],
    prepaidSeatStatus:false,
    carrierPaidSeatData:"",
    editSeatRecord:[],
    isPassport:false,
    isEmail:true,
    isMobile:false,
    operatingCarrierData:"",
    marketingCarrierData:""
};

const ItinenaryReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ITINENARY_DATA:
            return {
                ...state,
                itinenary: action.payload
            }

        case SET_LOADING_DATA:
            return {
                ...state,
                loading: action.payload
            }

        case SET_ACTIVE_ITINENARY_RESULT_KEY:
            return {
                ...state,
                activeItinenaryResultKey: action.payload
            }

        case SET_GROUP_ITINENARY_DATA:
            return {
                ...state,
                groupItinary: action.payload
            }

        case SET_SEAT_MAP_DATA:
            return {
                ...state,
                seatMap: action.payload
            }

        case SET_TIME_TAKEN:
            return {
                ...state,
                timeTaken: action.payload
            }

        case SET_ITINENARY_ROWS_KEYS:
            return {
                ...state,
                itinenaryRowsKeys: action.payload
            }

        case SET_DETAILED_ITINENARY_ROWS:
            return {
                ...state,
                detailedItinenaryRows: action.payload
            }

        case SET_COUNT:
            return {
                ...state,
                count: action.payload
            }
        case SET_SEAT_MAP_DETAIL:
            return {
                ...state,
                singleSeatMap: action.payload
            }

        case SET_SEAT:
            return {
                ...state,
                seat: [...action.seat]
            }

        case SET_FLIGHT_DATA:
            return {
                ...state,
                flightData: action.flightData
            }

        case SET_SEATMAP_LOADING_DATA:
            return {
                ...state,
                seatLoading: action.payload
            }
        case SET_PREV_SEAT:
            return {
                ...state,
                prevSeat: action.seat
            }
        case SET_EDIT_SEAT:
            return {
                ...state,
                editSeat: action.seat
            }
       
        case SET_SAVE_SEAT:
            return {
                ...state,
                saveSeat: action.seat
            }
        case SET_PREPAID_SEAT_STATUS:
            return {
                    ...state,
                    prepaidSeatStatus: action.seat
            }
            case SET_CARRIER_PAID_SEAT_DATA:
                return {
                        ...state,
                        carrierPaidSeatData: action.seat
                } 
            case SET_EDIT_SEAT_RECORD:
                return {
                    ...state,
                    editSeatRecord: action.seat
                } 
                case SET_IS_PASSPORT_MANDATORY:
                    return {
                        ...state,
                        isPassport: action.payload
                    }
                case SET_IS_EMAIL_MANDATORY:
                    return {
                        ...state,
                        isEmail: action.payload
                    }
                case SET_IS_MOBILE_MANDATORY:
                    return {
                        ...state,
                        isMobile: action.payload
                    }
                    case SET_OPERATING_CARRIER_PAID_SEAT_DATA:
                    return {
                        ...state,
                        operatingCarrierData: action.payload
                    }
                    case SET_MARKETING_CARRIER_PAID_SEAT_DATA:
                    return {
                        ...state,
                        marketingCarrierData: action.payload
                    }
                                   
        default:
            return state;

    }
}

export default ItinenaryReducer;