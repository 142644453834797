import {
    SALES_SEARCHDATA,
      } from "../constant/types";
      
      const intialState:any = {
           salesSearchData:{
            exceptionType: '',
            affiliation: '',
            Agency: '',
            Agent: '',
            Airline: '',
            agencyCode: '',
            chainDataForChainPcc:[],
            AgencyList:{seacrhVariable:''},
            airlinesName:'',
            carrierCode:''
          }
      };
      
      export const Salessearchreducer = (state = intialState, action:any) => {
        switch (action.type) {
          case SALES_SEARCHDATA:
            return {
              ...state,
              salesSearchData: action.payload,
            };
          default:
            return state;
        }
      };
      export default Salessearchreducer;