import React, { useState, useEffect, useContext } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import authContext from "../context/auth-context";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Alert } from "react-bootstrap";
import moment from "moment";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Link, useHistory } from "react-router-dom";

export default function CreateContract(props) {
  console.log("props",props.backToTariff)
  const [alert, setAlert] = useState({
    variant: "success",
    show: false,
    message: "",
  });

  const history = useHistory()
  const [mappedOpeartingAirlines, setMappedOpeartingAirlines] = useState("");
  const [marketingAirline, setMarketingAirline] = useState("");
  const [carrier, setCarrier] = useState("");

  const [via, setVia] = useState("");
  const [note, setNote] = useState("");
  const [notesIV, setNotesIV] = useState("");
  const [tripType, setTripType] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validUpto, setValidUpto] = useState("");
  const [disallowedAirports, setDisallowedAirports] = useState("");
  const [pseudoCityCodes, setPseudoCityCodes] = useState("");
  const [tarifKey, setTarifKey] = useState("");

  const [currency, setCurrency] = useState("");
  const [isBrandedFare, setIsBrandedFare] = useState(true);

  const [passengerType, setpassengerType] = useState("");
  const [cruiseFare, setCruiseFare] = useState(false);
  const [landFare, setLandFare] = useState(false);

  const [originDataType, setOriginDataType] = useState("AirportorCityCode");
  const [originCitiesOrAirports, setOriginCitiesOrAirports] = useState("");
  const [originCountries, setOriginCountries] = useState("");
  const [originIATARegion, setOriginIATARegion] = useState("*");
  const [originRegions, setOriginRegions] = useState("");

  const [destionDataType, setDestionDataType] = useState("AirportorCityCode");
  const [destinationCitiesOrAirports, setDestinationCitiesOrAirports] =
    useState("");
  const [destinationCountries, setDestinationCountries] = useState("");
  const [destinationIATAs, setDestinationIATAs] = useState("*");
  const [destinationRegions, setDestinationRegions] = useState("");

  // CONTRACT OWENER TYPE
  const [type, setType] = useState("Organzation");
  const selectTypeFunction = (value) => {
    setType(value);
    setPccData([]);
  };

  const { orgId } = useContext(authContext);

  // PCC TYPE
  const [pccType, setPccType] = useState("Select");
  const [pccData, setPccData] = useState([]);
  const [pccArray, setPccArry] = useState([]);

  // DISTRIBUTION DATA
  const [organisationsType, setOrganisationsType] = useState("Select");

  const [chainsType, setChainsType] = useState("Select");

  const [agenciesType, setAgenciesType] = useState("Select");

  const [agentType, setAgentType] = useState("Select");

  const [bookingSourceType, setBookingSourceType] = useState("Select");

  const [bookingLocationType, setBookingLocationType] = useState("Select");

  // const [chainOrgId, setChainOrgId] = useState('');
  // const [agencyOrgId, setAgencyOrgId] = useState('');
  // const [agentOrgId, setAgentOrgId] = useState('');

  // SET ALL DATA
  const [organzationArry, setOrganzationArry] = useState("");
  const [chainArry, setChainArry] = useState("");
  const [agencyArry, setAgencyArry] = useState("");
  const [agentArry, setAgentArry] = useState("");
  const [bookingSourceArry, setBookingSourceArry] = useState("");
  const bookingSourcedata = [
    { name: "Sabre", id: "1" },
    { name: "Amadeus", id: "2" },
  ];

  // COUNTRY AND STATE VARIABLE
  const countryData = [
    { value: "USA", label: "United States" },
    { value: "CA", label: "Canada" },
    // { value: "MQ", label: "Martinique" },
    // { value: "GP", label: "Guadeloupe" },
    { value: "FR", label: "France" },
    { value: "NL", label: "Netherlands" },
    { value: "QA", label: "Qatar" },
    { value: "GB", label: "England" },
  ];

  const [countryName, setCountryName] = useState("");
  const [stateData, setStateData] = useState([]);
  const [statevalue, setStateValue] = useState("");

  // ORGANZATION API CALL
  const [organzationData, setOrganzationData] = useState([]);
  const [organzationForCOConsortium, setOrganzationForCOConsortium] =
    useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/organisations/all`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => setOrganzationData(result.Items))
      .catch((error) => console.error("Error: ", error));
  }, []);

  // FUNCTION FOR
  const selectOrganzationId = (value) => {
    value && getAutoSuggestForPccOrg(value.orgId);
  };

  // SELECT AGENCY FOR PCC
  const selectAgenctForPcc = (value) => {
    value && getAutoSuggestForPccAgency(value.travelBusinessesId);
  };

  // SELECT CONSOURTIUM FOR PCC
  const selectConsortiumForPcc = (value) => {
    value && getAutoSuggestForPccConsortium(value.consortiumId);
  };

  // FUNCTION FOR  PCC API CALL
  const getAutoSuggestForPccOrg = (value) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/pccs?orgId=${value}`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      // console.log("response",response)
      .then((result) =>
        result.message === "No records found."
          ? setPccData([{ pccOrOfficeId: "No records found." }])
          : setPccData(result)
      )
      .catch((error) => console.error("Error: ", error));
  };

  const getAutoSuggestForPccAgency = (value) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/pccs?orgId=${orgId}&travelBusinessesId=${value}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      // console.log("response",response)
      .then((result) =>
        result.message === "No records found."
          ? setPccData([{ pccOrOfficeId: "No records found." }])
          : setPccData(result)
      )
      .catch((error) => console.error("Error: ", error));
  };

  const getAutoSuggestForPccConsortium = (value) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/pccs?orgId=${orgId}&consortiumId=${value}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      // console.log("response",response)
      .then((result) =>
        result.message === "No records found."
          ? setPccData([{ pccOrOfficeId: "No records found." }])
          : setPccData(result)
      )
      .catch((error) => console.error("Error: ", error));
  };

  // FUNCTION FOR API CALL GET ATOSUGGESSTED API CALL
  const getAutoSuggestOrganzation = (value) => {
    if (value.length > 2) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/organisations/autoSuggest?name=${value}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        // console.log("response",response)
        .then((result) =>
          result.message === "No records found."
            ? setOrganzationData([{ orgName: "No records found." }])
            : setOrganzationData(result)
        )
        .catch((error) => console.error("Error: ", error));
    }
  };

  // CONSOURTIUM API CALL FOR CONTRACT OWENER
  const [consortiumData, setConsortiumData] = useState([]);
  const getAutoSuggestCosourtium = (value) => {
    if (value.length > 2) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/consortium/autoSuggest?orgId=${orgId}&name=${value}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        // console.log("response",response)
        .then((result) =>
          result.message === "No records found."
            ? setConsortiumData([{ consortiumName: "No records found." }])
            : setConsortiumData(result)
        )
        .catch((error) => console.error("Error: ", error));
    }
  };

  // CONSOURTIUM API CALL FOR DESTRIBUTION
  const getAutoSuggestCosourtiumForDisburtion = (value) => {
    if (value.length > 2) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/consortium/autoSuggest?orgId=${orgId}&name=${value}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        // console.log("response",response)
        .then((result) => {
          result.Items.length === 0
            ? setConsortiumData([{ consortiumName: "No records found." }])
            : setConsortiumData(result.Items);
        })
        .catch((error) => console.error("Error: ", error));
    }
  };

  // AGENCY API CALL FOR CONTRACT OWENER FOR DESTRIBUTION
  const [agencyData, setAgencyData] = useState([]);
  const getAutoSuggestAgency = (value) => {
    if (value.length > 2) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${orgId}&adAgencyCode=${value}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        // console.log("response",response)
        .then((result) => {
          result.Items.forEach((data) => {
            data["seacrhVariable"] =
              data.adAgencyCode + " - " + data.searchTravelBusinessesName;
          });
          result.message === "No records found."
            ? setAgencyData([])
            : setAgencyData(result.Items);
        })
        .catch((error) => console.error("Error: ", error));
    }
  };

  const getOnfocusData = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/travelBusinesses/all?orgId=${orgId}`,
      {
        method: "POST",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      // console.log("response",response)
      .then((result) => {
        result.Items.forEach((data) => {
          data["seacrhVariable"] =
            data.adAgencyCode + " - " + data.searchTravelBusinessesName;
        });
        result.message === "No records found."
          ? setAgencyData([])
          : setAgencyData(result.Items);
      })
      .catch((error) => console.error("Error: ", error));
  };

  // GET ONFOCUS FOR CONSOURTIUM
  const getOnfocusConsortiumData = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/consortium/all?orgId=${orgId}`, {
      method: "POST",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        const arr = [];
        result.Items.forEach((data) => {
          if ("consortiumName" in data) {
            arr.push(data);
          }
        });
        setConsortiumData(arr);
      })
      .catch((error) => console.error("Error: ", error));
  };

  // GET ONFOCUS FOR AGENT
  const getOnfocusAgentData = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/users/external/all?orgId=${orgId}`,
      {
        method: "POST",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setagentData(result.Items);
      })
      .catch((error) => console.error("Error: ", error));
  };

  // AGENCY API CALL FOR DESTRIBUTION
  const getAutoSuggestAgencyForDisburtion = (value) => {
    if (value.length > 2) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${orgId}&adAgencyCode=${value}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        // console.log("response",response)
        .then((result) => {
          result.Items.forEach((data) => {
            data["seacrhVariable"] =
              data.adAgencyCode + " - " + data.searchTravelBusinessesName;
          });
          result.message === "No records found."
            ? setAgencyData([])
            : setAgencyData(result.Items);
        })
        .catch((error) => console.error("Error: ", error));
    }
  };

  // AGENT  API CALL
  const [agentData, setagentData] = useState([]);

  // FUNCTION FOR AUTOSUGGEST API
  const getAutoSuggestAgent = (value) => {
    if (value.length > 2) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/users/external/autoSuggest?orgId=${orgId}&name=${value}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        // console.log("response",response)
        .then((result) => {
          result.Items.length === 0
            ? setagentData([{ username: "No records found." }])
            : setagentData(result.Items);
        })
        .catch((error) => console.error("Error: ", error));
    }
  };

  // FUNCTION FOR SELECT COUNTRY NAME AND STATE API CALL
  const selectCountryName = async (value) => {
    setCountryName(value);
    fetch(`${process.env.REACT_APP_FETCH_STATE_URL}`, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ country: value }),
    })
      .then((response) => response.json())
      // console.log("response",response)
      .then((result) => setStateData(result.data.states))
      .catch((error) => console.error("Error: ", error));
  };

  // CABIN TYPE
  const [cabinType, setCabinType] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);

  const setcabinTypeFunction = (e, value) => {
    setRenderFlag(!renderFlag);
    let newCabinType = cabinType;
    if (newCabinType.includes(value)) {
      newCabinType = newCabinType.filter((id) => id !== value);
      setCabinType(newCabinType);
    } else {
      newCabinType.push(value);
      setCabinType(newCabinType);
    }
  };

  const [distributionChannels, setDistributionChannels] = useState([]);
  // FUNCTION FOR DISTRIBUTION CHANNELS
  const setDistributionChannelsFunction = (e, value) => {
    setRenderFlag(!renderFlag);
    let newDisnuchannel = distributionChannels;
    if (newDisnuchannel.includes(value)) {
      newDisnuchannel = newDisnuchannel.filter((id) => id !== value);
      setDistributionChannels(newDisnuchannel);
    } else {
      newDisnuchannel.push(value);
      setDistributionChannels(newDisnuchannel);
    }
  };

  const [formsOfPayment, setFormsOfPayment] = useState([]);
  // FUNCTION FOR DISTRIBUTION CHANNELS
  const setFormsOfPaymentFunction = (e, value) => {
    setRenderFlag(!renderFlag);
    let newFormsOfPayment = formsOfPayment;
    if (newFormsOfPayment.includes(value)) {
      newFormsOfPayment = newFormsOfPayment.filter((id) => id !== value);
      setFormsOfPayment(newFormsOfPayment);
    } else {
      newFormsOfPayment.push(value);
      setFormsOfPayment(newFormsOfPayment);
    }
  };

  // Characteristics STATE
  const [calculationBasis, setCalculationBasis] = useState("AVERAGE");
  const [ccFeesForTBMerchant, setCcFeesForTBMerchant] = useState(0);
  const [maximumFeeCCBycarrierType, setMaximumFeeCCBycarrierType] =
    useState(false);
  const [maximumFeeCCAmoutByCarrier, setMaximumFeeCCAmoutByCarrier] =
    useState(0);

  // VALIDATION STATE
  const [carrierValid, setCarrierValid] = useState(false);
  const [viaValid, setViaValid] = useState(false);
  const [pseudoCityCodesValid, setPseudoCityCodesValid] = useState(false);
  const [tarifKeyValid, setTarifKeyValid] = useState(false);
  const [fareValid, setFareValid] = useState(false);
  const [tripTypeValid, setTripTypeValid] = useState(false);
  const [cabinTypeValid, setCabinTypeValid] = useState(false);
  const [passengerTypeValid, setPassengerTypeValid] = useState(false);
  const [originDataTypeValid, setOriginDataTypeValid] = useState(false);
  const [destionDataTypeValid, setDestionDataTypeValid] = useState(false);
  const [distributionChannelsValid, setDistributionChannelsValid] =
    useState(false);

  const [tarfSwTC, setTarfSwTC] = useState(false);
  const [tarfSwSunquest, setTarfSwSunquest] = useState(false);
  const [tarfSwDFW, setTarfSwDFW] = useState(false);
  const [tarfSwFUN, setTarfSwFUN] = useState(false);
  const [intairTransit, setIntairTransit] = useState(false);
  const [skyLink, setSkyLink] = useState(false);
  const [validFromValid , setValidFromValid] = useState(false);
  const [validUptoValid, setValidUptoValid] = useState(false);
  // FUNCTION FOR VALIDATIONS
  const validateCreateTariff = () => {
    setDistributionChannelsValid(false);
    setOriginDataTypeValid(false);
    setDestionDataTypeValid(false);
    setTripTypeValid(false);
    setPassengerTypeValid(false);
    setCabinTypeValid(false);
    setViaValid(false);
    setCarrierValid(false);
    setPseudoCityCodesValid(false);
    setTarifKeyValid(false);

    let isValid = false;
    // if (originIATARegion === '') {
    //   isValid = true;
    //   setOriginDataTypeValid(true)
    // }

    // if (destinationIATAs === '') {
    //   isValid = true;
    //   setDestionDataTypeValid(true)
    // }
    if (tripType === "") {
      isValid = true;
      setTripTypeValid(true);
    }
    if (passengerType === "") {
      isValid = true;
      setPassengerTypeValid(true);
    }
    if (cabinType.length === 0) {
      isValid = true;
      setCabinTypeValid(true);
    }
    if (via === "") {
      isValid = true;
      setViaValid(true);
    }
    if(validFrom === ""){
      isValid = true;
      setValidFromValid(true)
    }
    if(validUpto === ""){
      isValid = true;
      setValidUptoValid(true)
    }
    if (carrier === "") {
      isValid = true;
      setCarrierValid(true);
    }
    if (pseudoCityCodes === "") {
      isValid = true;
      setPseudoCityCodesValid(true);
    }
    if (tarifKey === "") {
      isValid = true;
      setTarifKeyValid(true);
    }   
    if (distributionChannels.length === 0) {
      isValid = true;
      setDistributionChannelsValid(true);
    }
    console.log("isValid", isValid);
    if (!isValid) {
      Submit();
    }
  };

  // TARIFF SUBMIT FUNCTION
  const Submit = () => {
    // Organzation data
    let organzationName = [];
    organzationArry &&
      organzationArry.forEach((name) => {
        organzationName.push(name.orgName);
      });

    // Agency data
    let agencyArryName = [];
    console.log("agencyArryName", agencyArry);
    agencyArry &&
      agencyArry.forEach((name) => {
        agencyArryName.push(name.adTravelAgencyName);
      });

    // agent data
    let agentArryName = [];
    agentArry &&
      agentArry.forEach((name) => {
        agentArryName.push(name.username);
      });

    // AGENT DATA
    let chainArryName = [];
    chainArry &&
      chainArry.forEach((name) => {
        chainArryName.push(name.consortiumName);
      });

    // BOOKING SOURCE
    let bookingSourceDataArry = [];
    bookingSourceArry &&
      bookingSourceArry.forEach((name) => {
        bookingSourceDataArry.push(name.name);
      });

    let finialoriginCitiesOrAirports = [];
    selectedAirportOrcity?.forEach((data) => {
      finialoriginCitiesOrAirports.push(data.code);
    });

    let finialoriginCitiesOrAirportsDestion = [];
    selectedAirportOrcitySDesti?.forEach((data) => {
      finialoriginCitiesOrAirportsDestion.push(data.code);
    });

    let finialoriginCountry = [];
    selectedCountryOrigin?.forEach((data) => {
      finialoriginCountry.push(data.countryCode);
    });

    let finialDestioncountry = [];
    selectedCountryDesti?.forEach((data) => {
      finialDestioncountry.push(data.countryCode);
    });

    //FINIAL API DATA BODY
    const jsonBody = {
      allowedAirlinesMapping: [
        {
          mappedOpeartingAirlines: [mappedOpeartingAirlines],
          marketingAirline: [marketingAirline],
        },
      ],

      cabinType: cabinType,
      carrier: carrier,
      cruiseFare: cruiseFare === true ? "1" : "0",
      currency: currency,
      destinationCitiesOrAirports: finialoriginCitiesOrAirportsDestion,
      destinationCountries: finialDestioncountry,
      destinationIATAs: [destinationIATAs],
      destinationRegions: [destinationRegions],
      destinations: [],
      isBrandedFare: isBrandedFare,
      isDisabled: false,
      old_tarifSwPAX: passengerType,
      passengerType: passengerType,
      landFare: landFare === true ? "1" : "0",
      note: note,
      notesIV: notesIV,
      intairTransit: intairTransit,
      skyLink: skyLink,
      funSun: tarfSwFUN,
      DFW: tarfSwDFW,
      sunquest: tarfSwSunquest,
      network: tarfSwTC,
      originCitiesOrAirports: finialoriginCitiesOrAirports,
      originCountries: finialoriginCountry,
      originIATARegion: originIATARegion,
      originRegions: [originRegions],
      disallowedAirports: [disallowedAirports],
      pseudoCityCodes: pseudoCityCodes,
      tripType: tripType,
      validFrom:validFrom,
      validUpto: validUpto,
      via: via,
      organisationType: organisationsType,
      organisations: organzationName,
      chainType: chainsType,
      chains: chainArryName,
      agencyType: agenciesType,
      agencies: agencyArryName,
      agentType: agentType,
      agents: agentArryName,
      bookingSourceType: bookingSourceType,
      bookingSources: bookingSourceDataArry,
      agencyLocationType: bookingLocationType,
      agencyLocationCountry: countryName,
      agencyLocationProvince: statevalue,
      tarifKey: tarifKey,
      tarifTimeStamp: moment().format(),
      distributionChannels: distributionChannels,
      characteristic: {
        formsOfPayment: formsOfPayment, //ENUM

        calculationBasis: calculationBasis,
        maximumFeeCCAmoutByCarrier: maximumFeeCCAmoutByCarrier,
        ccFeesForTBMerchant: ccFeesForTBMerchant,

        maximumFeeCCBycarrierType: maximumFeeCCBycarrierType,
      },
    };
    jsonBody.agencies.length === 0 && delete jsonBody.agencies;
    jsonBody.agents.length === 0 && delete jsonBody.agents;
    jsonBody.bookingSources.length === 0 && delete jsonBody.bookingSources;
    jsonBody.cabinType.length === 0 && delete jsonBody.cabinType;
    jsonBody.chains.length === 0 && delete jsonBody.chains;
    jsonBody.destinations.length === 0 && delete jsonBody.destinations;
    jsonBody.organisations.length === 0 && delete jsonBody.organisations;

    jsonBody.destinationCitiesOrAirports.includes("") &&
      delete jsonBody.destinationCitiesOrAirports;
    jsonBody.destinationIATAs.includes("") && delete jsonBody.destinationIATAs;
    jsonBody.destinationRegions.includes("") &&
      delete jsonBody.destinationRegions;
    jsonBody.disallowedAirports.includes("") &&
      delete jsonBody.disallowedAirports;
    jsonBody.originCitiesOrAirports.includes("") &&
      delete jsonBody.originCitiesOrAirports;
    jsonBody.originCountries.includes("") && delete jsonBody.originCountries;
    jsonBody.originRegions.includes("") && delete jsonBody.originRegions;

    jsonBody.agencyLocationCountry === "" &&
      delete jsonBody.agencyLocationCountry;
    jsonBody.agencyLocationProvince === "" &&
      delete jsonBody.agencyLocationProvince;
    jsonBody.agencyLocationType === "" && delete jsonBody.agencyLocationType;
    jsonBody.agencyType === "" && delete jsonBody.agencyType;
    jsonBody.agentType === "" && delete jsonBody.agentType;
    jsonBody.bookingSourceType === "" && delete jsonBody.bookingSourceType;
    jsonBody.carrier === "" && delete jsonBody.carrier;
    jsonBody.chainType === "" && delete jsonBody.chainType;
    jsonBody.currency === "" && delete jsonBody.currency;
    jsonBody.note === "" && delete jsonBody.note;
    jsonBody.notesIV === "" && delete jsonBody.notesIV;
    jsonBody.old_tarifSwPAX === "" && delete jsonBody.old_tarifSwPAX;
    jsonBody.organisationType === "" && delete jsonBody.organisationType;
    jsonBody.originIATARegion === "" && delete jsonBody.originIATARegion;
    jsonBody.pseudoCityCodes === "" && delete jsonBody.pseudoCityCodes;
    jsonBody.tarifKey === "" && delete jsonBody.tarifKey;
    jsonBody.tripType === "" && delete jsonBody.tripType;
    jsonBody.via === "" && delete jsonBody.via;
    jsonBody.validUpto === "" && delete jsonBody.validUpto;
    jsonBody.validFrom === "" && delete jsonBody.validFrom;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify(jsonBody),
    };
    fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff`, requestOptions)
      .then((response) => response.json())
      // console.log("response",response)
      .then((result) => {
        if (result.status !== undefined) {
          setAlert({ variant: "danger", show: true, message: result.message });
        } else {
          history.push(`/contractmanagement/${result.id}`)
          props.afterCreate(result.id);          
          const showMsg = setTimeout(()=>{
            setAlert({
              variant: "success",
              show: true,
              message: "Successfully Created .",
            })
            clearTimeout(showMsg)
          },2000)
          
        }
      })
      .catch((error) => console.error("Error: ", error));
  };

  // AUTOSUGGEST FUNCTION FOR SELECT AIRPORT OR CITY CODE
  const [cityOrAirportCodeData, setCityOrAirportCodeData] = useState([]);
  const [selectedAirportOrcity, setSelectedAirportOrcity] = useState([]);
  const [selectedAirportOrcitySDesti, setSelectedAirportOrcitySDesti] =
    useState([]);
  const getAutoSuggestAirportOrCityCode = (value) => {
    if (value.length > 2) {
      fetch(
        `${process.env.REACT_APP_STARTREK_URL}/citySearch/searchCityAirport/${value}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        .then((result) => {
          result.forEach((data) => {
            data["customizedLabel"] =
              data.city + " , " + data.country + " ( " + data.code + " )";
          });
          result.length == 0
            ? setCityOrAirportCodeData([])
            : setCityOrAirportCodeData(result);
        })
        .catch((error) => console.error("Error: ", error));
    }
  };

  // AUTOSUGGEST FUNCTION FOR COUNTRY CODE
  const [citycountryData, setCitycountryData] = useState([]);
  const [selectedCountryOrigin, setSelectedCountryOrigin] = useState([]);
  const [selectedCountryDesti, setSelectedCountryDesti] = useState([]);

  const getAutoSuggestCountry = (value) => {
    if (value.length > 1) {
      fetch(`${process.env.REACT_APP_STATIC_CONTENT}/countries/${value}`, {
        method: "GET",
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          result.forEach((data) => {
            data["customizedLabel"] =
              data.countryName + " ( " + data.countryCode + " )";
          });
          result.length == 0
            ? setCitycountryData([])
            : setCitycountryData(result);
        })
        .catch((error) => console.error("Error: ", error));
    }
  };

  //
  const selectOriginTypeFunction = (value) => {
    setOriginDataType(value);
    setSelectedAirportOrcity([]);
    setOriginRegions("");
    setSelectedCountryOrigin([]);
    setOriginIATARegion("*");
  };

  const selectDestinationTypeFunction = (value) => {
    setDestionDataType(value);
    setSelectedAirportOrcitySDesti([]);
    setDestinationRegions("");
    setSelectedCountryDesti([]);
    setDestinationIATAs("*");
  };

  return (
    <div>
      <div className="cm-h1-head">
        <h1>Create Tariff</h1>
        <Link to="/contractlist">
          <span style={{ cursor: "pointer" }} className="text-primary">
            {" "}
            <span>
              {" "}
              <i className="fas fa-chevron-left mr-1 icon-cog "> </i>
            </span>{" "}
            Back{" "}
          </span>
        </Link>
      </div>

      <Alert
        variant={alert.variant}
        show={alert.show}
        onClose={() => setAlert({ ...alert, show: false })}
        dismissible
      >
        {alert.message}
      </Alert>
      <div className="card cm-card">
        <div className="card-body">
          <div>
            <hr />
            <h6 style={{ fontWeight: "bold" }}>Tariff</h6>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">
                  Origin <span className="text-danger"> * </span>
                </label>
                <div className="cm-text-county with-autocomplete">
                  <select
                    onChange={(e) => selectOriginTypeFunction(e.target.value)}
                    value={originDataType}
                    className="form-control cm-input-select"
                  >
                    <option value="AirportorCityCode">AirportorCityCode</option>
                    <option value="RegionCode">RegionCode</option>
                    <option value="Country Code">Country Code</option>
                    <option value="IATA Region Code">IATA Region Code</option>
                  </select>
                  {originDataType === "AirportorCityCode" && (
                    <Autocomplete
                      multiple
                      id="size-small-outlined-multi"
                      size="small"
                      options={cityOrAirportCodeData}
                      style={{ background: "white" }}
                      onChange={(event, value) =>
                        setSelectedAirportOrcity(value)
                      }
                      getOptionLabel={(option) => option.customizedLabel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Airport or City Code"
                          //  onFocus={(e) => getOnfocusData()}
                          onChange={(e) =>
                            getAutoSuggestAirportOrCityCode(e.target.value)
                          }
                          placeholder="Select Airport or City Code"
                        />
                      )}
                    />
                  )}
                  {originDataType === "RegionCode" && (
                    <input
                      onChange={(e) => setOriginRegions(e.target.value)}
                      value={originRegions}
                      aria-label="Text input with dropdown button"
                      className="cm-input form-control"
                    />
                  )}
                  {originDataType === "Country Code" && (
                    <Autocomplete
                      multiple
                      id="size-small-outlined-multi"
                      size="small"
                      options={citycountryData}
                      style={{ background: "white" }}
                      onChange={(event, value) =>
                        setSelectedCountryOrigin(value)
                      }
                      getOptionLabel={(option) => option.customizedLabel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Country Code"
                          //  onFocus={(e) => getOnfocusData()}
                          onChange={(e) =>
                            getAutoSuggestCountry(e.target.value)
                          }
                          placeholder="Select Country Code"
                        />
                      )}
                    />
                  )}
                  {originDataType === "IATA Region Code" && (
                    <input
                      onChange={(e) => setOriginIATARegion(e.target.value)}
                      value={originIATARegion}
                      aria-label="Text input with dropdown button"
                      className="cm-input form-control"
                    />
                  )}
                </div>
                {originDataTypeValid && (
                  <span className="text-danger">
                    Please enter IATA Region code.{" "}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">
                  Destinations <span className="text-danger"> * </span>
                </label>
                <div className="cm-text-county with-autocomplete">
                  <select
                    onChange={(e) =>
                      selectDestinationTypeFunction(e.target.value)
                    }
                    value={destionDataType}
                    className="form-control cm-input-select"
                  >
                    <option value="AirportorCityCode">AirportorCityCode</option>
                    <option value="RegionCode">RegionCode</option>
                    <option value="Country Code">Country Code</option>
                    <option value="IATA Region Code">IATA Region Code</option>
                  </select>
                  {destionDataType === "AirportorCityCode" && (
                    <Autocomplete
                      multiple
                      id="size-small-outlined-multi"
                      size="small"
                      options={cityOrAirportCodeData}
                      style={{ background: "white" }}
                      onChange={(event, value) =>
                        setSelectedAirportOrcitySDesti(value)
                      }
                      getOptionLabel={(option) => option.customizedLabel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Airport or City Code"
                          //  onFocus={(e) => getOnfocusData()}
                          onChange={(e) =>
                            getAutoSuggestAirportOrCityCode(e.target.value)
                          }
                          placeholder="Select Airport or City Code"
                        />
                      )}
                    />
                  )}
                  {destionDataType === "RegionCode" && (
                    <input
                      onChange={(e) => setDestinationRegions(e.target.value)}
                      value={destinationRegions}
                      aria-label="Text input with dropdown button"
                      className="cm-input form-control"
                    />
                  )}
                  {destionDataType === "Country Code" && (
                    <Autocomplete
                      multiple
                      id="size-small-outlined-multi"
                      size="small"
                      options={citycountryData}
                      style={{ background: "white" }}
                      onChange={(event, value) =>
                        setSelectedCountryDesti(value)
                      }
                      getOptionLabel={(option) => option.customizedLabel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Country Code"
                          //  onFocus={(e) => getOnfocusData()}
                          onChange={(e) =>
                            getAutoSuggestCountry(e.target.value)
                          }
                          placeholder="Select Country Code Code"
                        />
                      )}
                    />
                  )}
                  {destionDataType === "IATA Region Code" && (
                    <input
                      onChange={(e) => setDestinationIATAs(e.target.value)}
                      value={destinationIATAs}
                      aria-label="Text input with dropdown button"
                      className="cm-input form-control"
                    />
                  )}
                </div>
                {destionDataTypeValid && (
                  <span className="text-danger">
                    Please enter IATA Region code{" "}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">
                  Trip Type <span className="text-danger"> * </span>
                </label>
                <div className="cm-checkbox-inline">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setTripType("OW")}
                        checked={tripType === "OW" ? true : false}
                        color="primary"
                      />
                    }
                    label="One Way"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setTripType("-OW")}
                        checked={tripType === "-OW" ? true : false}
                        color="primary"
                      />
                    }
                    label="Round Trip / Open Jaw / Multi City"
                  />
                </div>
                {tripTypeValid && (
                  <span className="text-danger">Please select Trip Types.</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">
                  Fare Type <span className="text-danger"> * </span>{" "}
                </label>
                <div className="cm-checkbox-block">
                  <FormControlLabel
                    control={<Checkbox checked={landFare} color="primary" />}
                    onChange={(e) => setLandFare(!landFare)}
                    label="Land Fare"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setCruiseFare(!cruiseFare)}
                        checked={cruiseFare}
                        color="primary"
                      />
                    }
                    label="Cruise Fare"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        // onChange={(e) => setCruiseFare(!cruiseFare)}
                        checked={cruiseFare === false && landFare === false}
                        color="primary"
                      />
                    }
                    label="Air Fare"
                  />
                </div>
                {fareValid && (
                  <span className="text-danger">Please Select Fare Type </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">
                  PAX <span className="text-danger"> * </span>
                </label>
                <div className="cm-radio-block">
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Senior"
                    checked={passengerType === "SENIOR"}
                    onChange={(e) => setpassengerType("SENIOR")}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Adult"
                    onChange={(e) => setpassengerType("ADULT")}
                    checked={passengerType === "ADULT"}
                  />
                </div>
                {passengerTypeValid && (
                  <span className="text-danger">
                    {" "}
                    Please Select Passenger Type.
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">
                  Cabin Type <span className="text-danger"> * </span>{" "}
                </label>
                <div className="cm-checkbox-inline">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={(e) => setcabinTypeFunction(e, "Economy")}
                        checked={cabinType.includes("Economy") ? true : false}
                        color="primary"
                      />
                    }
                    label="Economy"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setcabinTypeFunction(e, "premiumEconomy")
                        }
                        checked={
                          cabinType.includes("premiumEconomy") ? true : false
                        }
                        color="primary"
                      />
                    }
                    label="Premimum Economy"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setcabinTypeFunction(e, "first")}
                        checked={cabinType.includes("first") ? true : false}
                        color="primary"
                      />
                    }
                    label="First Class"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setcabinTypeFunction(e, "bussiness")}
                        checked={cabinType.includes("bussiness") ? true : false}
                        color="primary"
                      />
                    }
                    label="Business"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setcabinTypeFunction(e, "premiumBusiness")
                        }
                        checked={
                          cabinType.includes("premiumBusiness") ? true : false
                        }
                        color="primary"
                      />
                    }
                    label="Premium Business"
                  />
                </div>
                {cabinTypeValid && (
                  <span className="text-danger">Please selct Cabin Type.</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="cm-col">
                <label className="cm-head-label">
                  VIA <span className="text-danger"> * </span>
                </label>
                <>
                  <input
                    className="form-control cm-input"
                    type="text"
                    value={via}
                    onChange={(e) => setVia(e.target.value)}
                  />
                </>
                {viaValid && (
                  <span className="text-danger">Please enter VIA.</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="cm-col">
                <label className="cm-head-label">
                  Carrier <span className="text-danger"> * </span>
                </label>
                <>
                  <input
                    className="form-control cm-input"
                    type="text"
                    value={carrier}
                    onChange={(e) => setCarrier(e.target.value)}
                  />
                </>
                {carrierValid && (
                  <span className="text-danger">Please enter carrier.</span>
                )}
              </div>

              {/* <input 
                    className="form-control"
                    type="text"
                /> */}
            </div>
            <div className="col-md-4">
              <div className="cm-col">
                <label className="cm-head-label">Is Branded fare</label>
                <div className="cm-radio-inline">
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    onChange={(e) => setIsBrandedFare(true)}
                    checked={isBrandedFare}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    onChange={(e) => setIsBrandedFare(false)}
                    checked={!isBrandedFare}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-md-4">
                <div className="cm-col">
                <label className="cm-head-label">VIA</label>                 
                    <input 
                    className="form-control cm-input"
                    type="text"
                    value={via}
                    onChange={(e)=>setVia(e.target.value)}
                  />            
                </div>
            </div> */}
            <div className="col-md-4">
              <div className="cm-col">
                <label className="cm-head-label">Currency</label>
                <input
                  className="form-control cm-input"
                  type="text"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="cm-col">
                <label className="cm-head-label">
                  Pseudo City Codes <span className="text-danger"> * </span>
                </label>
                <input
                  className="form-control cm-input"
                  type="text"
                  value={pseudoCityCodes}
                  onChange={(e) => setPseudoCityCodes(e.target.value)}
                />
                {pseudoCityCodesValid && (
                  <span className="text-danger">
                    {" "}
                    Please Enter Pseudo City Codes.
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="cm-col">
                <label className="cm-head-label">
                  Tarif Key <span className="text-danger"> * </span>
                </label>
                <input
                  className="form-control cm-input"
                  type="text"
                  value={tarifKey}
                  onChange={(e) => setTarifKey(e.target.value)}
                />
                {tarifKeyValid && (
                  <span className="text-danger">Please Enter tariff Key.</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">Marketing Airline</label>
                <input
                  className="form-control cm-input"
                  type="text"
                  value={marketingAirline}
                  onChange={(e) => setMarketingAirline(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">Operating Airline</label>
                <input
                  value={mappedOpeartingAirlines}
                  onChange={(e) => setMappedOpeartingAirlines(e.target.value)}
                  className="form-control cm-input"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">Note Phase IV</label>
                <textarea
                  className="form-control cm-textarea"
                  type="text"
                  value={notesIV}
                  onChange={(e) => setNotesIV(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">Note</label>
                <textarea
                  className="form-control cm-textarea"
                  type="text"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">Valid From <span className="text-danger"> * </span></label>
                <input
                  className="form-control cm-input"
                  type="date"
                  value={validFrom}
                  onChange={(e) => setValidFrom(e.target.value)}
                />
              </div>
              {validFromValid && (
                  <span className="text-danger">Please select Valid From.</span>
                )}
            </div>
            <div className="col-md-6">
              <div className="cm-col">
                <label className="cm-head-label">Valid Upto <span className="text-danger"> * </span></label>
                <input
                  className="form-control cm-input"
                  type="date"
                  value={validUpto}
                  onChange={(e) => setValidUpto(e.target.value)}
                />
              </div>
              {validUptoValid && (
                  <span className="text-danger">Please select Valid Upto .</span>
                )}
            </div>
          </div>
        </div>

        <div className="row card-body">
          <div className="col-md-12">
            <div className="cm-col">
              <div className="cm-checkbox-inline">
                <FormControlLabel
                  control={<Checkbox checked={skyLink} color="primary" />}
                  label="Sky link"
                  onChange={() => setSkyLink(!skyLink)}
                />
                <FormControlLabel
                  control={<Checkbox checked={intairTransit} color="primary" />}
                  label="Intair Transit"
                  onChange={() => setIntairTransit(!intairTransit)}
                />
                <FormControlLabel
                  control={<Checkbox checked={tarfSwFUN} color="primary" />}
                  label="Fun Sun"
                  onChange={() => setTarfSwFUN(!tarfSwFUN)}
                />
                <FormControlLabel
                  control={<Checkbox checked={tarfSwDFW} color="primary" />}
                  onChange={() => setTarfSwDFW(!tarfSwDFW)}
                  label="DFW"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={tarfSwSunquest} color="primary" />
                  }
                  onChange={() => setTarfSwSunquest(!tarfSwSunquest)}
                  label="Sunquest"
                />
                <FormControlLabel
                  control={<Checkbox checked={tarfSwTC} color="primary" />}
                  onChange={() => setTarfSwTC(!tarfSwTC)}
                  label="Network"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div>
            <h6 style={{ fontWeight: "bold" }}>Routings</h6>
            <hr />
          </div>
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="cm-col">
                <label className="cm-head-label">Airport not allowed </label>
                <input
                  className="form-control cm-input"
                  value={disallowedAirports}
                  onChange={(e) => setDisallowedAirports(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="cm-col mt-5">
                <div className="cm-checkbox-inline">
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="No Routing validation By startrek"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="cm-col">
                <label className="cm-head-label">
                  Routing field (used when routing is validated by startrek){" "}
                </label>

                <input className="form-control cm-input" />
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          {/*CODE FOR CONTRACT OWNER SECTION  */}
          <div>
            <label style={{ fontWeight: "bold" }}>Contract Owner</label>
          </div>
          <div className="mt-3 px-4">
            <div className="row">
              <div className="col-md-2">
                <div className="textCenter">Contract Owner</div>
              </div>
              <div className="col-md-2">
                <FormControl required variant="outlined" size="small">
                  {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    // labelId="Contract Owner"
                    // id="Contract Owner"
                    // label="Contract Owner"
                    value={type}
                    onChange={(e) => selectTypeFunction(e.target.value)}
                  >
                    <MenuItem value={"Organzation"}>Organzation</MenuItem>
                    <MenuItem value={"Agency"}>Agency</MenuItem>
                    <MenuItem value={"Consortium"}>Consortium</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {type === "Organzation" && (
                <div className="col-md-8">
                  <Autocomplete
                    id="size-small-outlined"
                    size="small"
                    options={organzationData}
                    style={{ background: "white" }}
                    onChange={(event, value) => selectOrganzationId(value)}
                    // onChange={(event, value) => setPccMainOrgId(value.orgId)}
                    // onClick={(e)=>setOrganzationForContract(e.target.value)}
                    getOptionLabel={(option) => option.orgName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Organzation"
                        // value={organzationForContract}
                        onChange={(e) =>
                          getAutoSuggestOrganzation(e.target.value)
                        }
                        placeholder="Select Organzation"
                      />
                    )}
                  />
                </div>
              )}

              {type !== "Organzation" && (
                <>
                  <div className="col-md-4">
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      options={organzationData}
                      style={{ background: "white" }}
                      onChange={(event, value) =>
                        value == null
                          ? ""
                          : setOrganzationForCOConsortium(value.orgId)
                      }
                      // onChange={(event, value) => setOrganzationForCOConsortium(value.orgId)}
                      getOptionLabel={(option) => option.orgName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Organzation"
                          value={organzationForCOConsortium}
                          onChange={(e) =>
                            getAutoSuggestOrganzation(e.target.value)
                          }
                          placeholder="Select Organzation"
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-4">
                    {type === "Agency" && (
                      <>
                        <Autocomplete
                          disabled={
                            organzationForCOConsortium === "" ? true : false
                          }
                          id="size-small-outlined"
                          size="small"
                          options={agencyData}
                          style={{ background: "white" }}
                          onChange={(event, value) => selectAgenctForPcc(value)}
                          // onClick={(e)=>setOrganzationForContract(e.target.value)}
                          getOptionLabel={(option) => option.seacrhVariable}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Agency"
                              value={organzationForCOConsortium}
                              onChange={(e) =>
                                getAutoSuggestAgency(e.target.value)
                              }
                              placeholder="Select Agency"
                              onFocus={(e) => getOnfocusData()}
                            />
                          )}
                        />
                      </>
                    )}
                    {type === "Consortium" && (
                      <>
                        <Autocomplete
                          disabled={
                            organzationForCOConsortium === "" ? true : false
                          }
                          id="size-small-outlined"
                          size="small"
                          options={consortiumData}
                          style={{ background: "white" }}
                          onChange={(event, value) =>
                            selectConsortiumForPcc(value)
                          }
                          // onClick={(e)=>setOrganzationForContract(e.target.value)}
                          getOptionLabel={(option) =>
                            option.searchConsortiumName
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Consortium"
                              value={organzationForCOConsortium}
                              onFocus={(e) => getOnfocusConsortiumData()}
                              onChange={(e) =>
                                getAutoSuggestCosourtium(e.target.value)
                              }
                              placeholder="Select Consortium"
                            />
                          )}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="mt-3 px-4">
            <div className="row">
              <div className="col-md-2">
                <div className="textCenter">PCC</div>
              </div>
              <div className="col-md-2">
                <FormControl required variant="outlined" size="small">
                  {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    // labelId="Contract Owner"
                    // id="Contract Owner"
                    // label="Contract Owner"
                    value={pccType}
                    onChange={(e) => setPccType(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"INCLUDE"}>Include</MenuItem>
                    <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-8">
                <Autocomplete
                  size="small"
                  multiple
                  style={{ background: "white" }}
                  id="tags-outlined"
                  options={pccData}
                  onChange={(event, value) => setPccArry(value)}
                  getOptionLabel={(option) => option.pccOrOfficeId}
                  // defaultValue={[pccData[0]]}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select PCC"
                      placeholder="Click Select pcc"
                      // onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                    />
                  )}
                />
                {pccArray.length !== 0 && (
                  <div>
                    <TableContainer component={Paper} className="mt-4">
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                              PCC Name
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              PCC Source
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              PCC Currency
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pccArray.map((pcc, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{pcc.pccOrOfficeId}</TableCell>
                                <TableCell>{pcc.agencyGds}</TableCell>
                                <TableCell>
                                  {pcc.defaultCurrencyForPCCOrOID}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/*CODE FOR DISTRIBUTION SECTION  */}
          <div className="mt-5">
            <hr />
            <label style={{ fontWeight: "bold" }}>Distribution</label>
          </div>
          <div className="px-4">
            <div className="row mt-3">
              <div className="col-md-2">
                <div className="textCenter">Organzation</div>
              </div>
              <div className="col-md-2">
                <FormControl required variant="outlined" size="small">
                  {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    // labelId="Contract Owner"
                    // id="Contract Owner"
                    // label="Contract Owner"
                    value={organisationsType}
                    onChange={(e) => setOrganisationsType(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"INCLUDE"}>Include</MenuItem>
                    <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-8">
                <Autocomplete
                  multiple
                  id="size-small-outlined-multi"
                  size="small"
                  options={organzationData}
                  style={{ background: "white" }}
                  onChange={(event, value) => setOrganzationArry(value)}
                  getOptionLabel={(option) => option.orgName}
                  // defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Organzation"
                      onChange={(e) =>
                        getAutoSuggestOrganzation(e.target.value)
                      }
                      placeholder="Select Organzation"
                    />
                  )}
                />
              </div>
            </div>

            {/* CHAIN */}
            <div className="row mt-3">
              <div className="col-md-2">
                <div className="textCenter">Chain</div>
              </div>
              <div className="col-md-2">
                <FormControl required variant="outlined" size="small">
                  {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    value={chainsType}
                    onChange={(e) => setChainsType(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"INCLUDE"}>Include</MenuItem>
                    <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-8">
                <Autocomplete
                  multiple
                  id="size-small-outlined-multi"
                  size="small"
                  options={consortiumData}
                  style={{ background: "white" }}
                  onChange={(event, value) => setChainArry(value)}
                  getOptionLabel={(option) => option?.consortiumName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Chain"
                      onFocus={(e) => getOnfocusConsortiumData()}
                      onChange={(e) =>
                        getAutoSuggestCosourtiumForDisburtion(e.target.value)
                      }
                      placeholder="Select Chain"
                    />
                  )}
                />
              </div>
            </div>
            {/* AGENCY */}
            <div className="row mt-3">
              <div className="col-md-2">
                <div className="textCenter">Agency</div>
              </div>
              <div className="col-md-2">
                <FormControl required variant="outlined" size="small">
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    value={agenciesType}
                    onChange={(e) => setAgenciesType(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"INCLUDE"}>Include</MenuItem>
                    <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-8">
                <Autocomplete
                  multiple
                  id="size-small-outlined-multi"
                  size="small"
                  options={agencyData}
                  style={{ background: "white" }}
                  onChange={(event, value) => setAgencyArry(value)}
                  getOptionLabel={(option) => option.seacrhVariable}
                  // defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Agency"
                      onFocus={(e) => getOnfocusData()}
                      onChange={(e) =>
                        getAutoSuggestAgencyForDisburtion(e.target.value)
                      }
                      placeholder="Select Agency"
                    />
                  )}
                />
              </div>
            </div>

            {/* AGENT */}
            <div className="row mt-3">
              <div className="col-md-2">
                <div className="textCenter">Agent</div>
              </div>
              <div className="col-md-2">
                <FormControl required variant="outlined" size="small">
                  {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    // labelId="Contract Owner"
                    // id="Contract Owner"
                    // label="Contract Owner"
                    value={agentType}
                    onChange={(e) => setAgentType(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"INCLUDE"}>Include</MenuItem>
                    <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-8">
                <Autocomplete
                  multiple
                  id="size-small-outlined-multi"
                  size="small"
                  options={agentData}
                  style={{ background: "white" }}
                  onChange={(event, value) => setAgentArry(value)}
                  getOptionLabel={(option) => option.username}
                  // defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Agent"
                      onFocus={(e) => getOnfocusAgentData()}
                      onChange={(e) => getAutoSuggestAgent(e.target.value)}
                      placeholder="Select Agent"
                    />
                  )}
                />
              </div>
            </div>

            {/* BOOKING SOURCE */}
            <div className="row mt-3">
              <div className="col-md-2">
                <div className="textCenter">Booking Source</div>
              </div>
              <div className="col-md-2">
                <FormControl required variant="outlined" size="small">
                  {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    value={bookingSourceType}
                    onChange={(e) => setBookingSourceType(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"INCLUDE"}>Include</MenuItem>
                    <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-8">
                <Autocomplete
                  multiple
                  id="size-small-outlined-multi"
                  size="small"
                  options={bookingSourcedata}
                  style={{ background: "white" }}
                  onChange={(event, value) => setBookingSourceArry(value)}
                  getOptionLabel={(option) => option.name}
                  // defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Booking Source"
                      onChange={(e) => getAutoSuggestAgent(e.target.value)}
                      placeholder="Select Booking Source"
                    />
                  )}
                />
              </div>
            </div>
            {/* AGENCY LOCATION */}
            <div className="row mt-3">
              <div className="col-md-2">
                <div className="textCenter">Agency Location</div>
              </div>
              <div className="col-md-2">
                <FormControl required variant="outlined" size="small">
                  {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    // labelId="Contract Owner"
                    // id="Contract Owner"
                    // label="Contract Owner"
                    value={bookingLocationType}
                    onChange={(e) => setBookingLocationType(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"INCLUDE"}>Include</MenuItem>
                    <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4">
                <FormControl required variant="outlined" fullWidth size="small">
                  <InputLabel id="title">Select Country</InputLabel>
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    labelId="Select Country"
                    id="Select Country"
                    label="Select Country"
                    onChange={(e) => selectCountryName(e.target.value)}
                  >
                    <MenuItem value={""}>Select Country</MenuItem>
                    {countryData.map((country, index) => {
                      return (
                        <MenuItem value={country.label}>
                          {country.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4">
                <FormControl required variant="outlined" fullWidth size="small">
                  <InputLabel id="title">Choose Province</InputLabel>
                  <Select
                    fullWidth
                    style={{ background: "white" }}
                    labelId="Choose Province"
                    id="Choose Province"
                    label="Choose Province"
                    onChange={(e) => setStateValue(e.target.value)}
                  >
                    <MenuItem value={""}>Choose Province</MenuItem>
                    {stateData.map((state, index) => {
                      return (
                        <MenuItem value={state.name}>{state.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <div className="card-body">
                <div>
                  <hr />
                  <h6 style={{ fontWeight: "bold" }}>Characteristics</h6>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="cm-col">
                      <label className="cm-head-label">
                        FOP allowed (by Airline) for Ticket issuance
                      </label>
                      <div className="cm-checkbox-inline">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                setFormsOfPaymentFunction(e, "CREDITCARD")
                              }
                              checked={
                                formsOfPayment.includes("CREDITCARD")
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Credit Card"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                setFormsOfPaymentFunction(e, "CHEQUE")
                              }
                              checked={
                                formsOfPayment.includes("CHEQUE") ? true : false
                              }
                            />
                          }
                          label="Cheque"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                setFormsOfPaymentFunction(e, "CREDITINTERNAL")
                              }
                              checked={
                                formsOfPayment.includes("CREDITINTERNAL")
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Credit Internal"
                        />
                      </div>
                      {/* <span style={{ padding: '10px', background: 'aliceblue' }}>

                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              color="primary"
                            />
                          }
                          label="Use UATP Card instead"
                        />
                      </span> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="cm-col">
                      <label className="cm-head-label">
                        Maximum Authorized amount on Airline as Merchant{" "}
                      </label>
                      <div className="cm-radio-inline">
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="gender"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              color="primary"
                              value="true"
                              control={
                                <Radio
                                  color="primary"
                                  onChange={() =>
                                    setMaximumFeeCCBycarrierType(false)
                                  }
                                  checked={!maximumFeeCCBycarrierType}
                                />
                              }
                              label="($)"
                            />
                            <FormControlLabel
                              color="primary"
                              value="false"
                              control={<Radio color="primary" />}
                              label="(%)"
                              onChange={() =>
                                setMaximumFeeCCBycarrierType(true)
                              }
                              checked={maximumFeeCCBycarrierType}
                            />
                          </RadioGroup>
                        </FormControl>

                        <label>
                          <input
                            type="text"
                            className="form-control cm-input cm-amount-field"
                            placeholder="Enter Amount"
                            value={maximumFeeCCAmoutByCarrier}
                            onChange={(e) =>
                              setMaximumFeeCCAmoutByCarrier(e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="cm-col">
                      <label className="cm-head-label">
                        COMBINATION <span className="text-danger"> * </span>{" "}
                      </label>
                      <div className="cm-radio-inline">
                        <>
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Average"
                            checked={calculationBasis === "AVERAGE"}
                            onChange={(e) => setCalculationBasis("AVERAGE")}
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Lowest"
                            checked={calculationBasis === "MINIMUM"}
                            onChange={(e) => setCalculationBasis("MINIMUM")}
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Highest"
                            checked={calculationBasis === "MAXIMUM"}
                            onChange={(e) => setCalculationBasis("MAXIMUM")}
                          />
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="cm-col">
                      <label className="cm-head-label">
                        Credit Card Processing Fees
                      </label>
                      <>
                        <input
                          className="form-control cm-input cm-amount-field"
                          value={ccFeesForTBMerchant}
                          onChange={(e) =>
                            setCcFeesForTBMerchant(e.target.value)
                          }
                          placeholder="Enter value"
                        />
                      </>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="cm-col">
                      <label className="cm-head-label">
                        Can not be distributed{" "}
                        <span className="text-danger"> * </span>
                      </label>
                      <div className="cm-checkbox-inline">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                setDistributionChannelsFunction(e, "INTERNAL")
                              }
                              checked={
                                distributionChannels.includes("INTERNAL")
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Internal Users"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={(e) =>
                                setDistributionChannelsFunction(e, "EXTERNAL")
                              }
                              checked={
                                distributionChannels.includes("EXTERNAL")
                                  ? true
                                  : false
                              }
                              color="primary"
                            />
                          }
                          label="External Users"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                setDistributionChannelsFunction(e, "XML-B2C")
                              }
                              checked={
                                distributionChannels.includes("XML-B2C")
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="XML-B2C"
                        />
                      </div>
                      {distributionChannelsValid && (
                        <span className="text-danger">Please select .</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 cm-col text-right">
              <button
                className="cm-primary-btn"
                onClick={(e) => validateCreateTariff()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
