//@ts-nocheck
import React, { useState } from "react";
import bags from "../bookingConfirmation/Images/bag-icon.svg";
import seats from "../bookingConfirmation/Images/seat-icon.svg"
import BookingSubHead from "./BookingSubHead";
import { useSelector } from "react-redux";
import moment from "moment";
import { timeConvert } from "../bookingConfirmation/Utils/convertmintohours";
import { useDispatch } from "react-redux";
import { getPenaltiesData } from "../bookingConfirmation/thunks/getPenaltiesData";
import { getPenaltiesDataForPNR } from '../bookingConfirmation/thunks/getPenaltiesDataForPNR';
import DetailsTab from "../bookingConfirmation/detailsTab";
import { BookingFrom, BookingHead, BookingStatusCode, FlightLogo, TripType } from "../bookingConfirmation/Utils/Constant";
import { diffrenceInDatesCheck, getFlightLogo,splitFromCapital } from "../bookingConfirmation/Utils/CommenFunction";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function BookingItineraryOneway(props) {
  const dispatch = useDispatch();
  const [flightDIndex, setFlightDIndex] = useState(null);
  const [seatMapIndex, setSeatMapIndex] = useState(null);
  const [setHideSeatMap] = useState();
  const [HideFlight, setHideFlight] = useState(false);
  const itinenaryData = props.itinenary;
  const bookingHeader = useSelector((state) => state.searchForm.bookingHeader);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const cabinValue = useSelector((state) => state.searchForm.cabin);

  let adultsCount 
  let childCount 
  let infantCount

  const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
  const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
  const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
  const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
  const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
  const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
  const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
  const bookingItineriesData = useSelector((state) => state.flightBooking.bookingItenaries);
  const paxFares = bookingItineriesData && bookingItineriesData.paxFares
  const fareBasisDetails =  paxFares && paxFares[0] && paxFares[0].fareBasisDetails
  const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);
  let pnrStatus = bookingDetails && bookingDetails.bookingStatusDetail && bookingDetails.bookingStatusDetail.code

  if (pnrFlag) {
    adultsCount = pnrAdultsCount
    childCount = pnrChildCount
    infantCount = pnrInfantCount
  } else {
    adultsCount = searchAdultCOunt
    childCount = searchChildCount
    infantCount = searchInfantCount
  }
  
  const brandCode = useSelector((state) => state.fareOptions.brandCode)
  const flyingFromCity = useSelector(
    (state) => state.searchForm.flyingFromCity
  );
  const flyingToCity = useSelector((state) => state.searchForm.flyingToCity);
  const flyingFrom = useSelector((state) => state.searchForm.flyingFrom);
  const flyingTo = useSelector((state) => state.searchForm.flyingTo);

  

  var flightIds = [];
  var flightsName = [];
  var baggageData = [];
  let cabin;
  let baggage;
  let baggageInWeight;
  let totalSeat;
  let fareSegment =[]
  // let date = bookingHeader.ticketingTimeLimit;
  let fareId = props.fareId;
  


  if (itinenaryData && itinenaryData.hasOwnProperty("segments") ) {

    fareSegment = itinenaryData.segments 
  }else{
    fareSegment = itinenaryData
    
  }
       
  const detailsTab = fareSegment && fareSegment[0] && Object.values(fareSegment[0]);
  const layover = fareSegment && fareSegment[0] && Object.values(fareSegment[0]) && Object.values(fareSegment[0])[0]?.layovers;


  const handleFlightDToggle = (i) => {
    setHideFlight(!HideFlight);

    if (flightDIndex == i) {
      setFlightDIndex(null);
    }
    setFlightDIndex(i);
    if(props.fareId) {
      dispatch(getPenaltiesData(props.fareId));
    }
    else if(props.recordLocator) {
      dispatch(getPenaltiesDataForPNR(props.recordLocator))
    }
  };

  return (
    <>
      {(props.fromBookingConfirmation || props.from === BookingFrom.CONFIRMATION) ? (
        <div className="bd-itinerary-box">
          {/* {!props.isCalcultor &&  <BookingItinerayInfo bookingHeader={bookingHeader} from={props.from}/>} */}

          <div className="bd-itinery-detail">
            <div className="col-12 bd-itinery-box-wrapper">
              <div className="bd-itinery-box">
                {fareSegment && fareSegment.map((data, index) => {
                  let layoverTime = 0;
                  let flightsArray = Object.values(data)[0].flights;
                  let classOfService1 = Object.values(data)[0].classOfService
                  cabin = Object.values(data)[0].cabin;
                  baggage = Object.values(data)[0].baggage;
                  baggageInWeight = Object.values(data)[0].weight;
                  totalSeat = Object.values(data)[0].seatsRemaining;

                  return (
                    <div key={index}>
                      <div className="bd-itinery-box-head">
                        <div>
                          <strong>Outbound</strong>
                          {flyingFromCity} ({flyingFrom}) - {flyingToCity} (
                          {flyingTo}) | {cabinValue && splitFromCapital(cabinValue)}
                        </div>
                        <div className="bd-ib-total">
                          {layover && layover.map((value) => {
                            layoverTime += value.durationMinutes;
                          })}
                          {Object.values(data)[0].durationMinutes !== undefined && <>  
                          Total Duration :
                          {timeConvert(
                            Object.values(data)[0].durationMinutes
                          )}
                          </>}
                        </div>
                      </div>
                      {flightsArray.map((flight, index) => {

                        let arrivalDate = flight.arrivalDateTime
                        let dapartureDate = flight.departureDateTime
                        let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);

                        flightIds.push(flight.id);
                        baggageData.push(flight.baggageDetails);
                        var name =
                          flight.departureAirport + "-" + flight.arrivalAirport;
                        flightsName.push(name);


                        let carrierCode = flight && flight.carrier
                        let flightLogo = getFlightLogo(carrierCode)

                        let flightClassOfservice = fareBasisDetails && fareBasisDetails.filter(obj=>obj.flightId===flight.id)
                        let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
                        console.log('flight',flight)

                        return (
                          <div key={flight.id}>
                            <div className="bd-itinery-item" key={index}>
                            {flight && flight.statusDetail && flight.statusDetail.code === "UN" ?
                                <div className="title">
                                  <img src={flightLogo} alt="flightLogo" />
                                  {flight.carrier + " " + flight.number +" " + (classOfService?classOfService:"")} <span className="cancelled_flight"> - Cancelled</span>
                                </div>
                                :
                                <div className="title">
                                   <img src={flightLogo} alt="flightLogo" />
                                   <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={
                                <Popover className="seatView-popover">
                                    <div className="seatView-popover-row seatView-popover-amount">                      
                                    <span className="airPort_name">{flight.airlineName}</span>
                                  </div>
                                </Popover>
                                }
                            >                              
                            <span className="bd-carrier-Name">{flight.carrier}</span>
                                
                          </OverlayTrigger>
                            {flight.number} {classOfService}
                                 </div>
                            }

                              <div className="flight-from-to">
                                <div className="flight-from-to-city">
                                  <div className="city">
                                    <strong>{flight.departureAirport}</strong>{" "}
                                    {moment.utc(flight.departureDateTime).format(
                                      "HH:mm"
                                    )}
                                  </div>
                                  <span className="date">
                                    {moment.utc(flight.departureDateTime).format(
                                      "ddd MMM DD, YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="flight-from-to-divider"></div>
                                <div className="flight-from-to-city">
                                  <div className="city">
                                    <strong>{flight.arrivalAirport}</strong>
                                    {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                                    {/* {moment.utc(flight.arrivalDateTime).format(
                                      "HH:mm"
                                    )} */}
                                  </div>
                                  <span className="date">
                                    {moment.utc(flight.arrivalDateTime).format(
                                      "ddd MMM DD, YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              {(pnrStatus === BookingStatusCode.NTC || pnrStatus === BookingStatusCode.TC)? 

                                <div className="time-from-to">
                                  <span className="tktonholdFailed"> cancelled</span>
                                </div>
                                :
                                <div className="time-from-to">
                                {timeConvert(flight.durationMin)}
                                </div>
                              }

                             
                            </div>
                            {layover && layover[index] ? (
                              <div className="bd-itinery-layover">
                                <span>
                                  {timeConvert(layover[index].durationMinutes) +
                                    " Layover in " + flight.arrivalAirport}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                <div className="bd-itinery-btns">
                  <div className="seatBag">
                    {totalSeat && <span>
                      <img src={seats} alt="seat" />
                      {totalSeat}
                    </span>}

                    {(baggageInWeight || baggage) &&
                      <span>
                        <img src={bags} alt="seat" />
                        {baggageInWeight > baggage ? " " + baggageInWeight + " Kg" : baggage}
                      </span>
                    }
                  </div>
                  {pnrStatus !== BookingStatusCode.NTC && pnrStatus !== BookingStatusCode.TC &&
                  <div>
                    <button
                      onClick={() => {
                        handleFlightDToggle(1);
                      }}
                    >
                      Flight Details
                    </button>
                  </div>
                  }
                </div>

                <div className={HideFlight ? "" : "flight_tabs_none"}>
                  <DetailsTab
                    flightdata={detailsTab}
                    baggagefares={baggageData}
                    flightName={flightsName}
                    fareId={fareId}
                    recordLocator={props.recordLocator}
                    paxFare={paxFares}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bd-itinerary-sec">
          <BookingSubHead
            title="Itinerary"
          />
          <div className="one_line">
            <ul className="bd-trip-info">
              <li>{tripTypeValue === TripType.ONEWAY && "One Way"}</li>
              <li>{cabinValue && splitFromCapital(cabinValue)} Class</li>
              <li>
                {adultsCount} Adult {childCount} Child {infantCount} Infant
              </li>

            </ul>
            <p>{brandCode.brandName} </p>
          </div>

          <div className="bd-itinerary-box">
            {/* <BookingItinerayInfo bookingHeader={bookingHeader} /> */}
            <div className="bd-itinery-detail">
              <div className="col-12 bd-itinery-box-wrapper">
                <div className="bd-itinery-box">
                  {fareSegment && fareSegment.map((data, index) => {
                    let layoverTime = 0;
                    let flightsArray = Object.values(data)[0].flights;
                    cabin = Object.values(data)[0].cabin;
                    baggage = Object.values(data)[0].baggage;
                    baggageInWeight = Object.values(data)[0].weight;
                    totalSeat = Object.values(data)[0].seatsRemaining;
                    let classOfService1 =  Object.values(data)[0].classOfService
                    return (
                      <div key={index}>
                        <div className="bd-itinery-box-head">
                          <div>
                            <strong>Outbound</strong>
                            {flyingFromCity} ({flyingFrom}) - {flyingToCity} (
                            {flyingTo}) | {cabinValue && splitFromCapital(cabinValue)}
                          </div>
                          <div className="bd-ib-total">
                            {layover && layover.map((value) => {
                              layoverTime += value.durationMinutes;
                            })}
                            Total Duration :
                            {timeConvert(
                              Object.values(data)[0].durationMinutes

                            )}
                          </div>
                        </div>
                        {flightsArray.map((flight, index) => {

                          let arrivalDate = flight.arrivalDateTime
                          let dapartureDate = flight.departureDateTime
                          let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);

                          flightIds.push(flight.id);
                          baggageData.push(flight.baggageDetails);
                          var name =
                            flight.departureAirport +
                            "-" +
                            flight.arrivalAirport;
                          flightsName.push(name);


                          let carrierCode = flight && flight.carrier
                          let flightLogo = getFlightLogo(carrierCode)

                          let flightClassOfservice = classOfService1 && classOfService1.filter(obj=>obj.flightId===flight.id)
                          let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
                          console.log("flight",flight)
                          return (
                            <div key={flight.id}>
                              <div className="bd-itinery-item">
                                <div className="title">
                                  <img src={flightLogo} alt="flightLogo" />
                                  <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={
                                <Popover className="seatView-popover">
                                    <div className="seatView-popover-row seatView-popover-amount">                      
                                    <span className="airPort_name">{flight.airlineName}</span>
                                  </div>
                                </Popover>
                                }
                            >                              
                                <span className="bd-carrier-Name">{flight.carrier}</span>
                                
                        </OverlayTrigger>

                       {flight.number} {classOfService}
                                </div>

                                <div className="flight-from-to">
                                  <div className="flight-from-to-city">
                                    <div className="city">
                                      <strong>{flight.departureAirport}</strong>{" "}
                                      {moment.utc(flight.departureDateTime).format(
                                        "HH:mm"
                                      )}
                                    </div>
                                    <span className="date">
                                      {moment.utc(flight.departureDateTime).format(
                                        "ddd MMM DD, YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div className="flight-from-to-divider"></div>
                                  <div className="flight-from-to-city">
                                    <div className="city">
                                      <strong>{flight.arrivalAirport}</strong>
                                      {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                                      {/* {moment.utc(flight.arrivalDateTime).format(
                                        "HH:mm"
                                      )} */}
                                    </div>
                                    <span className="date">
                                      {moment.utc(flight.arrivalDateTime).format(
                                        "ddd MMM DD, YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className="time-from-to">
                                  {timeConvert(flight.durationMin)}
                                </div>
                              </div>
                              {layover && layover[index] ? (
                                <div className="bd-itinery-layover">
                                  <span>
                                    {timeConvert(
                                      layover[index].durationMinutes
                                    ) + " Layover in " + flight.arrivalAirport}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}

                  <div className="bd-itinery-btns">
                    <div className="seatBag">
                      <span>
                        <img src={seats} alt="seat" />
                        {totalSeat}
                      </span>
                      <span>
                        <img src={bags} alt="seat" />
                        {baggageInWeight > baggage ? " " + baggageInWeight + " Kg" : baggage}
                      </span>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          handleFlightDToggle(1);
                        }}
                      >
                        Flight Details
                      </button>
                    </div>
                  </div>

                  <div className={HideFlight ? "" : "flight_tabs_none"}>
                    <DetailsTab
                      flightdata={detailsTab}
                      baggagefares={baggageData}
                      flightName={flightsName}
                      fareId={props.fareId}
                      recordLocator={props.recordLocator}
                      paxFare={paxFares}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BookingItineraryOneway;
