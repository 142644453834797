//@ts-nocheck
import { SET_FREE_TEXT_FARE_RULES, SET_STRUCTURED_FARE_RULES } from "../storeConstant";


export const setfreeTextFareRules = (value) => {
    console.log("setfreeTextFareRules",value)

    return {
        type:SET_FREE_TEXT_FARE_RULES,
        payload: value
    }
}

export const setstructuredFareRules = (value) => {

    return {
        type:SET_STRUCTURED_FARE_RULES,
        payload: value
    }
}