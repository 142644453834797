import {
    AllCONTRACT_LIST,LASTEVL_KEY
    } from "../constant/types";
    
    const intialState:any = {
         allContractList:[],
         lastEvaultedKey:[]
    };
    
    export const allContractlistRecducer = (state = intialState, action:any) => {
      switch (action.type) {
        case AllCONTRACT_LIST:
          return {
            ...state,
            allContractList: action.payload,
          };
          case LASTEVL_KEY:
            return {
              ...state,
              lastEvaultedKey: action.payload,
            };
        default:
          return state;
      }
    };
    export default allContractlistRecducer;