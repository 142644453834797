import React, { useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { Box, Checkbox, FormControlLabel, Radio, Tab, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { getcontracts } from '../../pages/bookingConfirmation/thunks/getcontracts';
import moment from 'moment';
import SpinnerLoader from '../../pages/bookingConfirmation/Utils/spinner';
import { useHistory } from 'react-router-dom';
import { getClassData } from '../thunks/getClassData';
import { setClassData, setSelectedTarifData } from '../AppStore/ActionCreator/commonDataAction';

const NewTariff = () => {

    const [disableEdit, setDisableEdit] = useState(true)
    const [classApiCallLoader, setClassApiCallLoader] = useState(false)
    const [classesArray, setClassesArray] = useState([])
    const [value, setValue] = React.useState('1');
    const tarifDetails = useSelector((state)=>state.commonDataReducer.tarifDetail)
    const dispatch = useDispatch();
    const history = useHistory();
    const [tarif, setTarif] = useState({})

    useEffect(()=>{
        setTarif({...tarifDetails})
    },[tarifDetails])

    const editAll = () => {
        setDisableEdit(!disableEdit)
    }

    const handleChange = async (event, newValue) => {
        setValue(newValue)
        if(newValue == "2"){
            setClassApiCallLoader(true)
            const classes = await getcontracts({"tarifKey": tarif?.tarifKey })
            setClassApiCallLoader(false)
            setClassesArray(classes?.tarifs[0]?.classes)
        }
        
    };

    const cancelClick = () => {
        setDisableEdit(true);
        setTarif({...tarifDetails})
    }

    useEffect(()=>{
        window. scrollTo({ top: 0, left: 0})
    },[])

    const getClasses = (classes = "") => {
        const arr = classes.split(",");
        let addEllipsis = false;
        if(arr.length > 3){
            arr.length = 3;
            addEllipsis = true;
        }
        const returnValue =  arr.join(",");
        return addEllipsis ? `${returnValue}...` : returnValue;
    }

    const openClass = async (id, tarif) => {
        let res = await getClassData(id?.clasKey)
        let tarifData = { ...id, ...tarif }
        dispatch(setSelectedTarifData(tarifData))
        dispatch(setClassData(res))
        history.push("/newClass")
    }


    return (
        <>{Object.keys(tarif).length ? <div>
            <div className="cm-head-2 mb-8">
                <div className="d-flex justify-content-between">
                <span style={{"fontSize":"1rem","color":"#0055b8","cursor":"pointer"}} onClick={()=>{history.push('/Newcontractlist')}}>{`<<- Back to Results`}</span>
                    <span className="cm__Title">Tariff Edit</span>
                    
                    {/* <div className="classTopEdit"> */}
                        {/* <button className='classEditBtn' onClick={editAll}> */}
                            {!Boolean(process.env.REACT_APP_EDIT_CREATE_CONTRACT_MANAGEMENT) ? <i className="fas fa-edit" style={{"color":"#0055b8"}} onClick={editAll}></i> : <span></span>}
                        {/* </button> */}
                    {/* </div> */}
                </div>
            </div>

            <div className="Tariff__Section">
                <div >
                    <div className="card Tariff__BorderB">
                        <div className="card-body">
                            <div className="row mt-4">
                                <div className="col-md-6 cm-col">

                                    <div className="row">
                                        <div className="col-md-6">

                                            <label className="cm-head-label">Tariff Key</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter tarif key"
                                                value={tarif?.tarifKey}
                                                disabled={disableEdit}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Validating Carrier</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter validating carrier"
                                                value={tarif?.carrier}
                                                disabled={disableEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 cm-col">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Origin</label>

                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Origin ..."
                                                value={tarif?.origins}
                                                disabled={disableEdit}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Destination</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Destination ..."
                                                value={tarif?.destinations}
                                                disabled={disableEdit}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                {/* <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">VIA</label>
                                            <input className="form-control cm-input" placeholder="Enter VIA" />
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Tariff Note</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Tariff Note"
                                            />
                                        </div>
                                    </div>

                                </div> */}

                                <div className="col-md-6">

                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Cabin Type</label>
                                            <div className="row">
                                                <div className="col-md-6 ">
                                                    <FormControlLabel control={<Checkbox color="primary" checked={tarif?.cabinType ? tarif?.cabinType.includes("Business") : false} disabled={disableEdit}/>} label="Business" />  </div>
                                                <div className="col-md-6  ">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={tarif?.cabinType ? tarif?.cabinType.includes("Economy") : false}
                                                                disabled={disableEdit}
                                                            />
                                                        }
                                                        label="Economy"
                                                    />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={tarif?.cabinType ? tarif?.cabinType.includes("PremiumBusiness") : false}
                                                                disabled={disableEdit}
                                                            />
                                                        }
                                                        label="Premium Business"
                                                    />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={tarif?.cabinType ? tarif?.cabinType.includes("PremiumEconomy") : false}
                                                                disabled={disableEdit}
                                                            />
                                                        }
                                                        label="Premium Economy"
                                                    />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={tarif?.cabinType ? tarif?.cabinType.includes("First") : false}
                                                                disabled={disableEdit}
                                                            />
                                                        }
                                                        label="First class"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Trip Type</label>
                                            <div className="cm-radio-inline">
                                                <FormControlLabel control={<Radio color="primary" checked={tarif?.tripType == "OW"} disabled={disableEdit}/>} label="One Way"
                                                />
                                                <FormControlLabel control={<Radio color="primary" checked={tarif?.tripType == "-OW"} disabled={disableEdit}/>} label="Not One Way"
                                                />{" "}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Branded Fare</label>
                                            <div className="cm-radio-inline">
                                                <FormControlLabel control={<Radio color="primary" checked={tarif?.isBrandedFare == 1} disabled={disableEdit}/>} label="Yes" />
                                                <FormControlLabel control={<Radio color="primary" checked={tarif?.isBrandedFare == 0} disabled={disableEdit}/>} label="No" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Contract Types</label>
                                            <div className="cm-checkbox-inline">
                                                <FormControlLabel control={<Checkbox checked={tarif?.landFare && tarif?.landFare == "1"} disabled={disableEdit}/>} label="Land" />
                                                <FormControlLabel control={<Checkbox color="primary" checked={(tarif?.landFare && tarif?.landFare == "0" && tarif?.cruiseFare && tarif?.cruiseFare == "0")} disabled={disableEdit}/>} label="Air Only" />
                                                <FormControlLabel control={<Checkbox color="primary" checked={tarif?.cruiseFare && tarif?.cruiseFare == "1"} disabled={disableEdit}/>} label="Cruise" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 20, borderColor: '#C9F7F5', background: '#fff' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Tariff" value="1" />
                                    <Tab label="Classes" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Marketing Airline</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.marketingAirline?.join()} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Operating Airline</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.mappedOpeartingAirlines?.join()} disabled={disableEdit}/>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Note Phase IV</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.notesIV} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Note </label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.note} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Contract Owner</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.contractOwner} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">PAX </label>
                                                    <FormControlLabel control={<Radio color="primary" checked={tarif?.passengerType == "SENIOR" ? true : false} disabled={disableEdit}/>} label="Senior" />
                                                    <FormControlLabel control={<Radio color="primary" checked={tarif?.passengerType == "ADULT" ? true : false} disabled={disableEdit}/>} label="Adult" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Characteristics</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">FOP allowed (by Airline) for <br />Ticket issuance</label>
                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" checked={tarif?.formsOfPayment && tarif?.formsOfPayment.includes("CHEQUE")} disabled={disableEdit}/>} label="Cheque" />
                                                        </div>
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" checked={tarif?.formsOfPayment && tarif?.formsOfPayment.includes("CREDITCARD")} disabled={disableEdit}/>} label="Credit Card" />
                                                        </div>
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" checked={tarif?.formsOfPayment && tarif?.formsOfPayment.includes("CREDITINTERNAL")} disabled={disableEdit}/>} label="Credit Internal" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Maximum Authorised Amount on<br /> Airline as Merchant </label>
                                                    <div className="row">
                                                        <div className="col-md-4 ">
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.maximumFeeCCBycarrierType == "Amount"}/>} label="($)" disabled={disableEdit}/>
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.maximumFeeCCBycarrierType == "Percentage"}/>} label="(%)" disabled={disableEdit}/>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <input className="form-control cm-input" placeholder="Enter Amount" value={tarif?.maximumFeeCCAmoutByCarrier}disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Combination</label>
                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.calculationBasis == "AVERAGE"} disabled={disableEdit}/>} label="Average" />
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.calculationBasis == "MAXIMUM"} disabled={disableEdit}/>} label="Maximum" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.calculationBasis == "MINIMUM"} disabled={disableEdit}/>} label="Minimum" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Credit Card Processing Fees </label>
                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <input className="form-control cm-input" type="text" placeholder="Enter Fees" value={tarif?.ccFeesForTBMerchant} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Routings</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Airport not allowed</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.disallowedAirports.join()} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Routing field </label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.routings} disabled={disableEdit}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label"></label>
                                                    <FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.skipRoutingCheck)} disabled={disableEdit}/>} label="No Routing Validation By Startrek" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card ">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Distribution</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Distribution</label>
                                                    <div className="row">
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox checked={Boolean(tarif?.skyLink)} disabled={disableEdit}/>} label="Sky Link" /></div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.intairTransit)} disabled={disableEdit}/>} label="Intair Transit" /> </div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox checked={Boolean(tarif?.funSun)} disabled={disableEdit}/>} label="Fun Sun" /></div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.DFW)} disabled={disableEdit}/>} label="DFW" /></div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.sunquest)} disabled={disableEdit}/>} label="Sunquest" /></div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.network)} disabled={disableEdit}/>} label="Network" /></div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Can not be distributed</label>
                                                    <FormControlLabel control={<Checkbox checked={tarif?.distributionChannels && tarif?.distributionChannels.includes("INTERNAL")} disabled={disableEdit}/>} label="Internal Users" />
                                                    <FormControlLabel control={<Checkbox color="primary" checked={tarif?.distributionChannels && tarif?.distributionChannels.includes("EXTERNAL")} disabled={disableEdit}/>} label="External Users" />
                                                    <FormControlLabel control={<Checkbox color="primary" checked={tarif?.distributionChannels && tarif?.distributionChannels.includes("XML-B2C")} disabled={disableEdit}/>} label="XML - B2C" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">PCC DU TARIFF</label>
                                                    <div className='row'>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">VIT</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.old_tarfPCC_VIT} disabled={disableEdit}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">FUN</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.tarifPCC_FUN} disabled={disableEdit}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">SKY</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.tarifPCC_SKY} disabled={disableEdit}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">TC</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.tarifPCC_TC} disabled={disableEdit}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">DFW</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.tarifPCC_DFW} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {disableEdit ? null : <Box className="cm-card-edit" sx={{ background: '#C9F7F5', padding: '16px', mb: '0' }}>
                                    <button className="cm-secondary-btn mr-2" type="button" onClick={cancelClick}>Cancel</button>
                                    <button className="cm-primary-btn" disabled={disableEdit}><i className='fa fa-save mr-1'></i> Save</button>
                                </Box>}
                            </TabPanel>
                            <TabPanel value="2" className='m-0 p-0'>
                                <div className="card ">
                                    <div className="card-body">

                                        <div className="d-flex justify-content-between">
                                            {!Boolean(process.env.REACT_APP_EDIT_CREATE_CONTRACT_MANAGEMENT) ? <button className="cm-yellow-btn mb-5">+ Create New Class</button> : null}
                                        </div>

                                        {classApiCallLoader?<>...Loading</>:<>{classesArray?.map((classValue)=>{return(<div>
                                            <div className="Tariff_classCard__main" style={{"cursor":"pointer"}} onClick={()=>openClass(classValue, tarif)}>
                                                <div className="Tariff_classCard__Left">
                                                    <div className="Tariff_classCard__alert">
                                                        <span>C</span>
                                                    </div>
                                                    <div className="Tariff_classCard__Right">
                                                        <div className='Tariff_classCard_classOfClass'>
                                                            <span className="Tariff_classCard_mainTitle"> Type: </span>
                                                            <span className="Tariff_classCard_mainTitle"> {classValue?.contractType}</span>
                                                        </div>
                                                        <div className='Tariff_classCard_classOfClass'>
                                                            <span className="Tariff_classCard_subTitle">  Classes: </span>
                                                            <Tooltip placement='top' title={classValue?.classCodes.join()}><span className="Tariff_classCard_mainTitle">{getClasses(classValue?.classCodes.join())}</span></Tooltip></div>
                                                        <div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle"> Brand: </span>
                                                            <span className="Tariff_classCard_mainTitle">{classValue?.brandCode}</span></div>
                                                        <div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle"> Category: </span>
                                                            <span className="Tariff_classCard_mainTitle">{classValue?.category}</span></div>
                                                        <div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle"> EDR:</span>
                                                            <span className="Tariff_classCard_mainTitle"> {moment(new Date(classValue?.classLastTicketingDate)).format("DD-MMM-yyyy")}</span>
                                                        </div><div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle">Note: </span>
                                                            <span className="Tariff_classCard_mainTitle">{classValue?.classNote}</span></div>
                                                        <div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle"> Routing SRC:</span>
                                                            <span className="Tariff_classCard_mainTitle">  {classValue?.classCodeDesc}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)})}</>}
                                    </div>
                                </div>
                            </TabPanel>
                        </TabContext>
                    </Box>



                </div>
            </div >
            </div>:null}
        </ >
    )
}

export default NewTariff