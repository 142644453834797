import {
    AGENT_SEARCHDATA,
      } from "../constant/types";
      
      const intialState:any = {
           agentSearchData:{
            userID:'',
            mobile:'',
            searchFirstName:'',
            AgencyList:{seacrhVariable:''},
            travelBusinessesId:''
          }
      };
      
      export const Agentsearchreducer = (state = intialState, action:any) => {
        switch (action.type) {
          case AGENT_SEARCHDATA:
            return {
              ...state,
              agentSearchData: action.payload,
            };
          default:
            return state;
        }
      };
      export default Agentsearchreducer;