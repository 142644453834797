import { SET_TODAYS_QUOTES, SET_YESTERDAYS_QUOTES, SET_LAST_WEEK_QUOTES, SET_ARCHEIVED_QUOTES, 
         SET_QUOTE_DETAILS, SET_REPRICE_QUOTE, SET_CLIENT_NAME, SET_QUOTE_TOKEN, SET_QUOTES_TAB_TYPE, SET_SHOW_EMAIL_QUOTE,SET_BOOKING_WITH_QUOTE } from '../storeConstant';

const initialState = {
    todaysQuotes: undefined,
    yesterdaysQuotes: undefined,
    lastWeeksQuotes: undefined,
    archeivedQuotes: undefined,
    quoteDetails: undefined,
    repriceQuote: undefined,
    clientName:"",
    quoteToken:"",
    quotesTabType:"Today",
    showEmailQuoteFlag:false,
    bookingWithQuote:false
};

const SavedQuotesReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_TODAYS_QUOTES:
            return {
                ...state,
                todaysQuotes: action.payload
            }
        case SET_YESTERDAYS_QUOTES:
            return {
                ...state,
                yesterdaysQuotes: action.payload
            }

        case SET_LAST_WEEK_QUOTES:
            return {
                ...state,
                lastWeeksQuotes: action.payload
            }

        case SET_ARCHEIVED_QUOTES:
            return {
                ...state,
                archeivedQuotes: action.payload
            }

        case SET_QUOTE_DETAILS:
            return {
                ...state,
                quoteDetails: action.payload
            }

        case SET_REPRICE_QUOTE:
            return {
                ...state,
                repriceQuote: action.payload
            }
        case SET_CLIENT_NAME:
            return {
                ...state,
                clientName: action.payload
            }   

        case SET_QUOTE_TOKEN:
            return {
                ...state,
                quoteToken: action.payload
            } 
            case SET_QUOTES_TAB_TYPE:
                return {
                    ...state,
                    quotesTabType: action.payload
                }
                case SET_SHOW_EMAIL_QUOTE:
                    return {
                        ...state,
                    showEmailQuoteFlag: action.payload
                } 
                case SET_BOOKING_WITH_QUOTE:
                    return {
                        ...state,
                    bookingWithQuote: action.payload
                }                 

        default:
            return state;

    }
}

export default SavedQuotesReducer;