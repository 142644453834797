import {  useEffect, useState } from "react";
import './distribution.css';
import TextField from '@material-ui/core/TextField';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function Distribution() {

    // CONTRACT OWENER TYPE
    const [type , setType] =  useState("Organzation");
    const selectTypeFunction = (value) =>{
        setType(value)
        setPccData([])
    }

    // PCC TYPE
    const [pccType , setPccType] =  useState("Select");
    const [pccData , setPccData] = useState([]) 
    const [pccArray ,setPccArry] = useState([]);


    // DISTRIBUTION DATA 
    const [organisationsType , setOrganisationsType] =  useState("Select");
   
    const [chainsType , setChainsType] =  useState("Select");

    const [agenciesType , setAgenciesType] =  useState("Select");

    const [agentType , setAgentType] =  useState("Select");
   
    const [bookingSourceType , setBookingSourceType] =  useState("Select");

    const [bookingLocationType , setBookingLocationType] =  useState("Select");


    const [chainOrgId , setChainOrgId] =  useState('');
    const [agencyOrgId , setAgencyOrgId] =  useState('');
    const [agentOrgId , setAgentOrgId] =  useState('');

    // SET ALL DATA
    const [organzationArry ,setOrganzationArry] = useState('')
    const [chainArry ,setChainArry] = useState('')
    const [agencyArry ,setAgencyArry] = useState('')
    const [agentArry ,setAgentArry] = useState('')
    const [bookingSourceArry ,setBookingSourceArry] = useState('');
    const bookingSourcedata = [
        {name :'Sabre' ,id:'1'},
        {name :'Amadeus',id:'2'}

    ]

    // COUNTRY AND STATE VARIABLE
   const  countryData =     [
        { value: 'USA', label: 'United States' },
        { value: 'CA', label: 'Canada' },
        // { value: "MQ", label: "Martinique" },
        // { value: "GP", label: "Guadeloupe" },
        { value: "FR", label: "France" },
        { value: "NL", label: "Netherlands" },
        { value: "QA", label: "Qatar" },
        { value: "GB", label: "England" }
      ] 
    const [countryName ,setCountryName] = useState('')
    const [stateData ,setStateData] = useState([])
    const [statevalue ,setStateValue] = useState('')

    // ORGANZATION API CALL
    const [organzationData , setOrganzationData] =  useState([]);
    const [organzationForCOConsortium , setOrganzationForCOConsortium] =  useState('');


    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/organisations/all`, {
            method: 'POST',
        })
            .then(response => response.json())
            .then(result => setOrganzationData(result.Items))
            .catch(error => console.error("Error: ", error))
    }, []);

    // FUNCTION FOR
    const selectOrganzationId = (value) =>{
        value &&
        getAutoSuggestForPccOrg(value.orgId)
    }

    // SELECT AGENCY FOR PCC
     const selectAgenctForPcc = (value) =>{
        value &&
        getAutoSuggestForPccAgency(value.travelBusinessesId)
    }

    // SELECT CONSOURTIUM FOR PCC
    const selectConsortiumForPcc = (value) =>{
        value &&
        getAutoSuggestForPccConsortium(value.consortiumId)

    }

    // FUNCTION FOR  PCC API CALL
    const getAutoSuggestForPccOrg = (value) =>{
        fetch(`${process.env.REACT_APP_BASE_URL}/pccs?orgId=${value}`, {
            method: 'GET',
            redirect: 'follow'
        })
            .then(response => response.json())
            // console.log("response",response)
            .then(result => result.message === 'No records found.' ? setPccData([{orgName:"No records found."}]) :setPccData(result) )
            .catch(error => console.error("Error: ", error))
    }

    const getAutoSuggestForPccAgency = (value) =>{
        fetch(`${process.env.REACT_APP_BASE_URL}/pccs?orgId=${organzationForCOConsortium}&travelBusinessesId=${value}`, {
            method: 'GET',
            redirect: 'follow'
        })
            .then(response => response.json())
            // console.log("response",response)
            .then(result => result.message === 'No records found.' ? setPccData([{orgName:"No records found."}]) :setPccData(result) )
            .catch(error => console.error("Error: ", error))
    }

    const getAutoSuggestForPccConsortium = (value) =>{
        fetch(`${process.env.REACT_APP_BASE_URL}/pccs?orgId=${organzationForCOConsortium}&consortiumId=${value}`, {
            method: 'GET',
            redirect: 'follow'
        })
            .then(response => response.json())
            // console.log("response",response)
            .then(result => result.message === 'No records found.' ? setPccData([{orgName:"No records found."}]) :setPccData(result) )
            .catch(error => console.error("Error: ", error))
    }


    // FUNCTION FOR API CALL GET ATOSUGGESSTED API CALL
    const getAutoSuggestOrganzation = (value) =>{
        if(value.length > 2){
            fetch(`${process.env.REACT_APP_BASE_URL}/organisations/autoSuggest?name=${value}`, {
                method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.json())
                // console.log("response",response)
                .then(result => result.message === 'No records found.' ? setOrganzationData([{orgName:"No records found."}]) :setOrganzationData(result) )
                .catch(error => console.error("Error: ", error))
    }
    }

    
    // CONSOURTIUM API CALL FOR CONTRACT OWENER
    const [consortiumData , setConsortiumData] =  useState([]);
    const getAutoSuggestCosourtium = (value) =>{
        if(value.length > 2){

        fetch(`${process.env.REACT_APP_BASE_URL}/consortium/autoSuggest?orgId=${organzationForCOConsortium}&name=${value}`, {
            method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.json())
                // console.log("response",response)
                .then(result => result.message === "No records found." ? setConsortiumData([{consortiumName:"No records found."}]) :setConsortiumData(result))
                .catch(error => console.error("Error: ", error))
    }}

     // CONSOURTIUM API CALL FOR DESTRIBUTION
    const getAutoSuggestCosourtiumForDisburtion = (value) =>{
        if(value.length > 2){

        fetch(`${process.env.REACT_APP_BASE_URL}/consortium/autoSuggest?orgId=${chainOrgId}&name=${value}`, {
            method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.json())
                // console.log("response",response)
                .then(result => result.message === "No records found." ? setConsortiumData([{consortiumName:"No records found."}]) :setConsortiumData(result))
                .catch(error => console.error("Error: ", error))
    }}

    // AGENCY API CALL FOR CONTRACT OWENER FOR DESTRIBUTION
    const [agencyData , setAgencyData] =  useState([]);
    const getAutoSuggestAgency = (value) =>{
        if(value.length > 2){

            fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${organzationForCOConsortium}&adAgencyCode=${value}`, {
                method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.json())
                // console.log("response",response)
                .then(result =>{
                    result.Items.map((data)=>{
                      data['seacrhVariable'] = data.adAgencyCode + ' - ' + data.searchTravelBusinessesName
                    })
                    result.message === "No records found." ? setAgencyData([]) : setAgencyData(result.Items)
                  })
                .catch(error => console.error("Error: ", error))
    }}

     // AGENCY API CALL FOR DESTRIBUTION
     const getAutoSuggestAgencyForDisburtion = (value) =>{
         if(value.length > 2){
             fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${agencyOrgId}&name=${value}`, {
                 method: 'GET',
                 redirect: 'follow'
             })
                 .then(response => response.json())
                 // console.log("response",response)
                 .then(result =>{
                    result.Items.map((data)=>{
                      data['seacrhVariable'] = data.adAgencyCode + ' - ' + data.searchTravelBusinessesName
                    })
                    result.message === "No records found." ? setAgencyData([]) : setAgencyData(result.Items)
                  })
                 .catch(error => console.error("Error: ", error))
            }
     }
 

    // AGENT  API CALL 
    const [agentData , setagentData] =  useState([]);
    const getAutoSuggestAgent = (value) =>{
        if(value.length > 2){

        fetch(`${process.env.REACT_APP_BASE_URL}/users/external/autoSuggest?orgId=${agentOrgId}&name=${value}`, {
            method: 'GET',
            redirect: 'follow'
        })
            .then(response => response.json())
            // console.log("response",response)
            .then(result =>result.message === "No records found." ? setagentData([{lastName:"No records found."}]) : setagentData(result))
            .catch(error => console.error("Error: ", error))
    }}

    // GET COUNTRY API CALL
    // useEffect(() => {
    //     fetch('https://countriesnow.space/api/v0.1/countries/iso', {
    //         method: 'GET',
    //     })
    //         .then(response => response.json())
    //         .then(result => setCountryData(result.data))
    //         .catch(error => console.error("Error: ", error))
    // }, []);


        
      


    // FUNCTION FOR SELECT COUNTRY NAME AND STATE API CALL
    const selectCountryName = async (value) => {
        setCountryName(value)
                fetch(`${process.env.REACT_APP_FETCH_STATE_URL}`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "country": value })
        })
            .then(response => response.json())
            // console.log("response",response)
            .then(result => setStateData(result.data.states))
            .catch(error => console.error("Error: ", error))
    }
        
    const Submit = () =>{
        // Organzation data
        let organzationName = []
        organzationArry && organzationArry.map(name=>{
            organzationName.push(name.orgName)
        })
    

        // Agency data
        let agencyArryName = []
        agencyArry && agencyArry.map(name=>{
            agencyArryName.push(name.travelBusinessesName)
        })

        // agent data
        let agentArryName = []
        agentArry && agentArry.map(name=>{
        agentArryName.push(name.username)
        })

        // agentA data
        let chainArryName = []
        chainArry && chainArry.map(name=>{
            chainArryName.push(name.consortiumName)
        })

        // BOOKING SOURCE
        let bookingSourceDataArry = []
        bookingSourceArry && bookingSourceArry.map(name=>{
            bookingSourceDataArry.push(name.name)
        })

            //FINIAL API DATA BODY
            const jsonBody = {
            "allowedAirlinesMapping": [
                {
                    "mappedOpeartingAirlines": [
                        "LFS",
                        "ABC"
                    ],
                    "marketingAirline": "CA"
                },
                {
                    "mappedOpeartingAirlines": [
                        "AA",
                        "AI"
                    ],
                    "marketingAirline": "BA"
                }
            ],
            "cabinType": [
                "economy",
                "premiumEconomy",
                "premiumBusiness"
            ],
            "carrier": "AC",
            "cruiseFare": 0,
            "currency": "CAD",
            "destinationAirports": [],
            "destinationCities": [],
            "destinationCountries": [
                "US"
            ],
            "destinationIATAs": [
                "000"
            ],
            "destinationRegions": [],
            "destinations": [
                "BUR",
                "FAT",
                "LAX",
                "MRY",
                "OAK",
                "ONT",
                "PSP",
                "SAN",
                "SBA",
                "SFO",
                "SJC",
                "SMF",
                "SNA"
            ],
            "isBrandedFare": true,
            "isDisabled": false,
            "landFare": 0,
            "note": "(empty)",
            "notesIV": "(empty)IV",
            "originAirports": [
                "YUL"
            ],
            "originCities": [
                "YMQ"
            ],
            "originCountries": [
                "CA"
            ],
            "originIATARegion": "9",
            "originRegions": [
                "QC"
            ],
            "routing": {
                "allowedAirports": [
                    "CAN",
                    "US"
                ],
                "disallowedAirports": [
                    "MEX"
                ]
            },
            "pseudoCityCodes": [],
            "tripType": "0W",
            "validUpto": "2099-12-31",
            "via": "DO",
            "organisationType": organisationsType,
            "organisations": organzationName,
            "chainType": chainsType,
            "chains":chainArryName,
            "agencyType": agenciesType,
            "agencies":agencyArryName,
            "agentType": agentType,
            "agents":agentArryName,
            "bookingSourceType": bookingSourceType,
            "bookingSources":bookingSourceDataArry,
            "agencyLocationType": bookingLocationType,
            "agencyLocationCountry": countryName,
            "agencyLocationProvince": statevalue
        }
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,

            },
            body:
                JSON.stringify(jsonBody)
        }
        fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff`, requestOptions)
        .then(response => response.json())
        // console.log("response",response)
        .then(result => console.log("result",result))
        .catch(error => console.error("Error: ", error))

    }
    

    return (
        <div className="card cm-card">
            <div className="card-body">

            {/*CODE FOR CONTRACT OWNER SECTION  */}
            <div>
                <label style={{fontWeight:'bold'}}>Contract Owner</label>
            </div>
            <div className="mt-3 px-4">
                <div className="row">
                    <div className="col-md-2">
                        <div className="textCenter">
                            Contract Owner
                        </div>
                    </div>
                    <div className="col-md-2">
                    <FormControl
                          required
                          variant="outlined"
                          size="small"
                        >
                          {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                          <Select
                          fullWidth
                          style={{background:'white'}}

                            // labelId="Contract Owner"
                            // id="Contract Owner"
                            // label="Contract Owner"
                            value={type}
                            onChange ={(e)=>selectTypeFunction(e.target.value)}
                          >
                            <MenuItem value={"Organzation"}>Organzation</MenuItem>
                            <MenuItem value={"Agency"}>Agency</MenuItem>
                            <MenuItem value={"Consortium"}>Consortium</MenuItem>

                          </Select>
                   
                        </FormControl>

                    </div>
                    {type === "Organzation" && 
                    <div className="col-md-8">
                          <Autocomplete
                            id="size-small-outlined"
                            size="small"
                            options={organzationData}
                            style={{background:'white'}}
                            onChange={(event, value) => selectOrganzationId(value)} 
                            // onChange={(event, value) => setPccMainOrgId(value.orgId)} 
                            getOptionLabel={(option) => option.orgName }
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Organzation" 
                            // value={organzationForContract}
                            onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                            placeholder="Select Organzation"
                        />         
                        )}
                />
                         
                    </div>
 
                    }
                    {type !== "Organzation" && 
                    <>
                    <div className="col-md-4">
                    <Autocomplete
                            id="size-small-outlined"
                            size="small"
                            options={organzationData}
                            style={{background:'white'}}
                            onChange={(event, value) => value === null ? '' : setOrganzationForCOConsortium(value.orgId)}                         
                            // onChange={(event, value) => setOrganzationForCOConsortium(value.orgId)}   
                            getOptionLabel={(option) => option.orgName }
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Organzation" 
                            value={organzationForCOConsortium}
                            onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                            placeholder="Select Organzation"
                        />         
                        )}
                    />
                    </div>
                    <div className="col-md-4">
                        {type === "Agency" &&
                        <>
                        <Autocomplete
                            disabled={organzationForCOConsortium === '' ? true : false}
                            id="size-small-outlined"
                            size="small"
                            options={agencyData}
                            style={{background:'white'}}
                            onChange={(event, value) => selectAgenctForPcc(value)}      
                            getOptionLabel={(option) => option.seacrhVariable }
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Agency" 
                            value={organzationForCOConsortium}
                            onChange={(e)=>getAutoSuggestAgency(e.target.value)}
                            placeholder="Select Agency"
                        />         
                        )}
                    />

                            </>
                            
                            }
                             {type === "Consortium" &&
                             <>
                        <Autocomplete
                             disabled={organzationForCOConsortium === '' ? true : false}
                            id="size-small-outlined"
                            size="small"
                            options={consortiumData}
                            style={{background:'white'}}
                            onChange={(event, value) => selectConsortiumForPcc(value)}      
                            getOptionLabel={(option) => option.consortiumName }
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Consortium" 
                            value={organzationForCOConsortium}
                            onChange={(e)=>getAutoSuggestCosourtium(e.target.value)}
                            placeholder="Select Consortium"
                        />         
                        )}
                />
                         
                          
                         </>
                            }
                        
                    </div>
                    </>
                    }
                </div>
            </div>

            <div className="mt-3 px-4">
                <div className="row">
                    <div className="col-md-2">
                        <div className="textCenter">
                            PCC
                        </div>
                    </div>
                    <div className="col-md-2">
                        <FormControl
                          required
                          variant="outlined"
                          size="small"
                        >
                          {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                          <Select
                          fullWidth
                          style={{background:'white'}}

                            // labelId="Contract Owner"
                            // id="Contract Owner"
                            // label="Contract Owner"
                                value={pccType}
                                 onChange ={(e)=>setPccType(e.target.value)}
                          >
                            <MenuItem value={"Select"}>Select</MenuItem>
                            <MenuItem value={"INCLUDE"}>Include</MenuItem>
                            <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                
                    <div className="col-md-8">
                    <Autocomplete
                            size="small"
                            multiple
                            style={{background:'white'}}
                            id="tags-outlined"
                            options={pccData}
                            onChange={(event ,value)=>setPccArry(value)}
                            getOptionLabel={(option) => option.pccOrOfficeId}
                            // defaultValue={[pccData[0]]}
                            filterSelectedOptions
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select PCC"
                                placeholder="Click Select pcc"
                                // onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                            />
                            )}
                        />
                        {pccArray.length !== 0 && 
                        <div>
                        <TableContainer component={Paper} className="mt-4">
                            <Table  size="small" aria-label="a dense table">
                                <TableHead>
                                <TableRow>
                                    <TableCell  style={{fontWeight:'bold'}}>PCC Name</TableCell>
                                    <TableCell  style={{fontWeight:'bold'}}>PCC Source</TableCell>
                                    <TableCell  style={{fontWeight:'bold'}}>PCC Currency</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pccArray.map((pcc,index)=>{
                                        return(
                                            <TableRow key={index}>
                                            <TableCell >{pcc.pccOrOfficeId}</TableCell>
                                            <TableCell >{pcc.agencyGds}</TableCell>
                                            <TableCell >{pcc.defaultCurrencyForPCCOrOID}</TableCell>
                                            </TableRow>
                                       )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </div>
                        }
                    </div>
                </div>
            </div>
            {/*CODE FOR DISTRIBUTION SECTION  */}
            <div className="mt-5">
                <hr/>
                <label style={{fontWeight:'bold'}}>Distribution</label>
            </div>
            <div className="px-4">
                <div className="row mt-3">
                    <div className="col-md-2">
                        <div className="textCenter">
                        Organzation                       
                         </div>
                    </div>
                    <div className="col-md-2">
                    <FormControl
                          required
                          variant="outlined"
                          size="small"
                        >
                          {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                          <Select
                          fullWidth
                          style={{background:'white'}}

                            // labelId="Contract Owner"
                            // id="Contract Owner"
                            // label="Contract Owner"
                                value={organisationsType}
                                 onChange ={(e)=>setOrganisationsType(e.target.value)}
                          >
                            <MenuItem value={"Select"}>Select</MenuItem>
                            <MenuItem value={"INCLUDE"}>Include</MenuItem>
                            <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-8">
                    {/* <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="large"
                        options={organzationData}
                        style={{background:'white'}}
                        getOptionLabel={(option) => option.orgName}
                        // defaultValue={[top100Films[13]]}
                        renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Organzation" 
                        onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                        placeholder="Select Organzation" />
                        )}
                    /> */}
                        <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={organzationData}
                        style={{background:'white'}}
                        onChange={(event,value)=>setOrganzationArry(value)}
                        getOptionLabel={(option) => option.orgName}
                        // defaultValue={[top100Films[13]]}
                        renderInput={(params) => (
                        <TextField {...params} variant="outlined"
                         label="Organzation" 
                         onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                        placeholder="Select Organzation" />
                        )}
                    />
                    
                    </div>
               
                </div>
               
                {/* CHAIN */}
                <div className="row mt-3">
                    <div className="col-md-2">
                        <div className="textCenter">Chain</div>
                        
                    </div>
                    <div className="col-md-2">
                    <FormControl
                          required
                          variant="outlined"
                          size="small"
                        >
                        
                          {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                          <Select
                          fullWidth
                          style={{background:'white'}}

                            // labelId="Contract Owner"
                            // id="Contract Owner"
                            // label="Contract Owner"
                             value={chainsType}
                             onChange ={(e)=>setChainsType(e.target.value)}
                          >
                            <MenuItem value={"Select"}>Select</MenuItem>
                            <MenuItem value={"INCLUDE"}>Include</MenuItem>
                            <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                        <Autocomplete
                                id="size-small-outlined"
                                size="small"
                                options={organzationData}
                                style={{background:'white'}}
                                onChange={(event, value) => value === null ? '' : setChainOrgId(value.orgId)}                          
                                getOptionLabel={(option) => option.searchOrgName }
                                renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Organzation" 
                                value={organzationForCOConsortium}
                                onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                                placeholder="Select Organzation"
                            />         
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                    <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={consortiumData}
                        style={{background:'white'}}
                        onChange={(event,value)=>setChainArry(value)}
                        getOptionLabel={(option) => option.consortiumName}
                        // defaultValue={[top100Films[13]]}
                        renderInput={(params) => (
                        <TextField {...params} variant="outlined"
                         label="Chain" 
                         onChange={(e)=>getAutoSuggestCosourtiumForDisburtion(e.target.value)}
                        placeholder="Select Chain"
                        disabled={chainOrgId === '' ? true :false}
                        />
                        )}
                    />
                    </div>
                    
    
    
                </div>   
                {/* AGENCY */}
                  <div className="row mt-3">
                    <div className="col-md-2">
                        <div className="textCenter">Agency</div>
                    </div>
                    <div className="col-md-2">
                    <FormControl
                          required
                          variant="outlined"
                          size="small"
                        >
                          {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                          <Select
                          fullWidth
                          style={{background:'white'}}
                        
                          
                            // labelId="Contract Owner"
                            // id="Contract Owner"
                            // label="Contract Owner"
                             value={agenciesType}
                             onChange ={(e)=>setAgenciesType(e.target.value)}
                          >
                            <MenuItem value={"Select"}>Select</MenuItem>
                            <MenuItem value={"INCLUDE"}>Include</MenuItem>
                            <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                        <Autocomplete
                                id="size-small-outlined"
                                size="small"
                                options={organzationData}
                                style={{background:'white'}}
                                onChange={(event, value) => value === null ? '' :setAgencyOrgId(value.orgId)}                             
                                getOptionLabel={(option) => option.searchOrgName }
                                renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Organzation" 
                                value={organzationForCOConsortium}
                                onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                                placeholder="Select Organzation"
                            />         
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                    <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={agencyData}
                        style={{background:'white'}}
                        onChange={(event,value)=>setAgencyArry(value)}

                        getOptionLabel={(option) => option.seacrhVariable}
                        // defaultValue={[top100Films[13]]}
                        renderInput={(params) => (
                        <TextField {...params} variant="outlined"
                         label="Agency" 
                         onChange={(e)=>getAutoSuggestAgencyForDisburtion(e.target.value)}
                        placeholder="Select Agency" />
                        )}
                    />
                    </div>
                </div>
                
    
    
                    {/* AGENT */}
                    <div className="row mt-3">
                    <div className="col-md-2">
                        <div className="textCenter">Agent</div>

                    </div>
                    <div className="col-md-2">
                    <FormControl
                          required
                          variant="outlined"
                          size="small"
                        >
                          {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                          <Select
                                fullWidth
                                style={{background:'white'}}
                            // labelId="Contract Owner"
                            // id="Contract Owner"
                            // label="Contract Owner"
                             value={agentType}
                             onChange ={(e)=>setAgentType(e.target.value)}
                          >
                            <MenuItem value={"Select"}>Select</MenuItem>
                            <MenuItem value={"INCLUDE"}>Include</MenuItem>
                            <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                        <Autocomplete
                            id="size-small-outlined"
                            size="small"
                            options={organzationData}
                            style={{background:'white'}}
                            onChange={(event, value) => value === null ? '' : setAgentOrgId(value.orgId)}                          
                            getOptionLabel={(option) => option.searchOrgName }
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Organzation" 
                            value={organzationForCOConsortium}
                            onChange={(e)=>getAutoSuggestOrganzation(e.target.value)}
                            placeholder="Select Organzation"
                        />         
                        )}
                    />
                    </div>
                    <div className="col-md-4">
                    <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={agentData}
                        style={{background:'white'}}
                        onChange={(event,value)=>setAgentArry(value)}
                        getOptionLabel={(option) => option.username}
                        // defaultValue={[top100Films[13]]}
                        renderInput={(params) => (
                        <TextField {...params} variant="outlined"
                         label="Agent" 
                         onChange={(e)=>getAutoSuggestAgent(e.target.value)}
                        placeholder="Select Agent" />
                        )}
                    />
                    </div>
                    
               
                </div>
            
                    {/* BOOKING SOURCE */}
                    <div className="row mt-3">
                    <div className="col-md-2">
                        <div className='textCenter' >Booking Source</div>
                        
                    </div>
                    <div className="col-md-2">
                    <FormControl
                          required
                          variant="outlined"
                          size="small"
                        >
                          {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                          <Select
                          fullWidth
                          style={{background:'white'}}
                             value={bookingSourceType}
                             onChange ={(e)=>setBookingSourceType(e.target.value)}
                          >
                            <MenuItem value={"Select"}>Select</MenuItem>
                            <MenuItem value={"INCLUDE"}>Include</MenuItem>
                            <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-8">                       
                        <Autocomplete
                            multiple
                            id="size-small-outlined-multi"
                            size="small"
                            options={bookingSourcedata}
                            style={{background:'white'}}
                            onChange={(event,value)=>setBookingSourceArry(value)}
                            getOptionLabel={(option) => option.name}
                            // defaultValue={[top100Films[13]]}
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined"
                            label="Booking Source" 
                            onChange={(e)=>getAutoSuggestAgent(e.target.value)}
                            placeholder="Select Booking Source" />
                            )}
                        />
                    </div>
                </div>
                    {/* AGENCY LOCATION */}
                    <div className="row mt-3">
                    <div className="col-md-2">
                        <div className='textCenter' >Agency Location</div>

                    </div>
                    <div className="col-md-2">
                    <FormControl
                          required
                          variant="outlined"
                          size="small"
                        >
                          {/* <InputLabel id="title">Contract Owner</InputLabel> */}
                          <Select
                          fullWidth
                          style={{background:'white'}}
                            // labelId="Contract Owner"
                            // id="Contract Owner"
                            // label="Contract Owner"
                             value={bookingLocationType}
                             onChange ={(e)=>setBookingLocationType(e.target.value)}
                          >
                            <MenuItem value={"Select"}>Select</MenuItem>
                            <MenuItem value={"INCLUDE"}>Include</MenuItem>
                            <MenuItem value={"EXCLUDE"}>Exclude</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                    <FormControl
                          required
                          variant="outlined"
                          fullWidth
                          size="small"
                        >
                          <InputLabel id="title">Select Country</InputLabel>
                          <Select
                          fullWidth
                          style={{background:'white'}}
                            labelId="Select Country"
                            id="Select Country"
                            label="Select Country"
                            onChange ={(e)=>selectCountryName(e.target.value)}
                          >
                            <MenuItem value={""}>Select Country</MenuItem>
                              {countryData.map((country,index)=>{
                                  return(
                                    <MenuItem  value={country.label}>{country.label}</MenuItem>
                                  )
                              })}
                          </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                    <FormControl
                          required
                          variant="outlined"
                          fullWidth
                          size="small"
                        >
                          <InputLabel id="title">Choose Province</InputLabel>
                          <Select
                          fullWidth
                          style={{background:'white'}}
                            labelId="Choose Province"
                            id="Choose Province"
                            label="Choose Province"
                            onChange ={(e)=>setStateValue(e.target.value)}
                          >
                            <MenuItem value={""}>Choose Province</MenuItem>
                              {stateData.map((state,index)=>{
                                  return(
                                    <MenuItem value={state.name}>{state.name}</MenuItem>
                                  )
                              })}
                          </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="mt-5 cm-col text-right">
                    <button className="cm-primary-btn" onClick={(e)=>Submit()}>Submit</button>
                </div>
            </div>
        </div>
        
        </div>
    )
}
