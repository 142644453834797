import { useEffect, useState } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import moment from "moment";
import { useHistory } from "react-router-dom";



export default function Price(props) {


  const [allPriceData, setAllPriceData] = useState([]);


  // GET GET ONE CLASSES LIST API CALL
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_CONTRACT_URL}/getAll/tariff/${props.tariffId}/class/${props.classId}/price`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Items === undefined) {
          setAllPriceData([])
        } else {
          setAllPriceData(result.Items)
        }
      })
      .catch((error) => console.error("Error: ", error));
  }, []);

  const history = useHistory();


  // FUNCTION FOR REDIRECT
  const redirectToViewPrice = (value) => {
    history.push(`/prices/${props.tariffId}/${props.classId}/${value}`);
  }


  console.log("allPriceData", allPriceData)
  return (
    <div className="cc-mui-table">
      <TableContainer component={Paper} className="mt-4">
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>From</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>To</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Our commission</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Our markup </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Note </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allPriceData.length === 0 &&
              <TableRow>
                <TableCell>
                  No records found.
                </TableCell>

              </TableRow>
            }
            {allPriceData && allPriceData.map((price, index) => {
              return (
                <TableRow key={index}
                  onClick={(e) => redirectToViewPrice(price.priceId)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell component="th" scope="row"
                    className="text-primary"
                  >

                    {moment(price.validFrom && price.validFrom).format("DD-MMM-YYYY ")}

                  </TableCell>
                  <TableCell className="text-primary"
                  >
                    {moment(price.validTo && price.validTo).format("DD-MMM-YYYY ")}

                  </TableCell>
                  <TableCell >{price?.fareCalculation?.commissionPercentage}</TableCell>
                  <TableCell >{price?.fareCalculation?.markup}</TableCell>
                  <TableCell>{price?.prixNote}</TableCell>

                </TableRow>
              )
            })}



          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
