import {  useEffect, useState } from "react";
import PriceAtSabre from "./PriceAtSabre/PriceAtSabre";
import Price from "./Prices/Price";
import Remarks from "./Remarks/Remarks";
import Routings from "./Routings/Routings";
import Rules from "./Rules/Rules";
import TicketBy from "./TicketBy/TicketBy";
import moment from "moment";
import { useHistory} from 'react-router-dom';


export default function Addclasses(props) {
  const history = useHistory();

  const [classData, setClassData] = useState({});
  const [classType, setClassType] = useState("Rules");
  const [triffData, setTriffId] = useState({});
  const [editFlag ,setEditFlag] = useState(false);


  const classId = props.match.params.SK;
  const tariffId = props.match.params.tariffId;


  // const selectEdit = () => {
  //   setEditFlag(true)
  // }
    // GET CONTRACT /TRIFF LIST API CALL
    useEffect(() => {
      fetch(
        `${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        // .then(result => console.log("result,",result))
        // .then(result => result.map(data=>{
        //     data['triffId'] = data.PK + "_" + data.SK
        //   })
        .then((result) => setTriffId(result))
        .catch((error) => console.error("Error: ", error));
    }, []);

    // GET GET ONE CLASSES LIST API CALL
    useEffect(() => {
      fetch(
        `${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}/class/${classId}`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())

        .then((result) => setClassData(result))
        .catch((error) => console.error("Error: ", error));
    }, []);

    // BACK TO TARIFF LIST 
    const backTariffFunction = () =>{
      history.push(`/contractmanagement/${tariffId}`);
    }

  let logoLowerCase = triffData.carrier && triffData.carrier.toLowerCase();
  let flightLogo = `${process.env.REACT_APP_FLIGHT_LOGO_URL}/${logoLowerCase}.png`
  return (
    <div>
        <div className="cm-head">
          <div className="cm-head-list">
            <span className="fligh-logo"><img src={flightLogo} alt="logo" height="30" width="30" /> {triffData.carrier}</span>
            <span><strong>Class:</strong> {classData.clasKey}</span>
            <span><strong>{triffData.isBrandedFare === "1" ? "Branded Fare" : "Non-Branded Fare"}</strong></span>
            <span><strong>Fare Type:</strong> {classData.contractType}</span>         
          </div>
          <div>
            <a onClick={(e)=>backTariffFunction()} className="cm-primary-btn">TARIFF</a>
          </div>
        </div>
      <div className="card cm-card">
        <div className="card-body">
          <div className="cm-card-edit">
            {/* {editFlag ?
              <>
                <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                <button className="cm-primary-btn" onClick={(e)=>setEditFlag(false)}> Save</button>
              </>
                :
              <i onClick={(e)=>selectEdit(true)} class="far fa-edit"></i>
            } */}
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="cm-col">
              <label className="cm-head-label">Origin</label>
              <div className="cm-content">
              {(triffData.originCountries && (triffData.originCountries.length === 0 || triffData.originCountries.includes("") || triffData.originCountries.includes(null))) ? "" : "Country -"}  {triffData.originCountries && Object.values(triffData.originCountries).join(',')}
                {(triffData.originRegions && (triffData.originRegions.length === 0 || triffData.originRegions.includes("") || triffData.originRegions.includes(null))) ? "" : <span> {(triffData.originCountries && (triffData.originCountries.length === 0 || triffData.originCountries.includes("") || triffData.originCountries.includes(null))) ? "" : "|"}    Regions - </span>} {triffData.originRegions && Object.values(triffData.originRegions).join(',')}
                {(triffData.originCitiesOrAirports && (triffData.originCitiesOrAirports.length === 0 || triffData.originCitiesOrAirports.includes("") || triffData.originCitiesOrAirports.includes(null))) ? "" : <span> {(triffData.originRegions && (triffData.originRegions.length === 0 || triffData.originRegions.includes("") || triffData.originRegions.includes(null))) ? "" : "|"}    Cities Or Airports - </span>}     {triffData.originCitiesOrAirports && Object.values(triffData.originCitiesOrAirports).join(',')}
                {triffData.originIATARegion && triffData.originIATARegion !== "" && <span>{(triffData.originCitiesOrAirports && (triffData.originCitiesOrAirports.length === 0 || triffData.originCitiesOrAirports.includes("") ) ) ? "" :   "|"}  IATA - </span> }  {triffData.originIATARegion}
              </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cm-col">
              <label className="cm-head-label">Destinations</label>
              <div className="cm-content">
                  {triffData &&  triffData.destinationCountries && triffData.destinationCountries.length !== 0 && " Country -"}  {triffData.destinationCountries && Object.values(triffData.destinationCountries).join(',')}
                  {triffData && triffData.destinationIATAs && triffData.destinationIATAs.length !== 0 && <span>{triffData.destinationCountries && triffData.destinationCountries.length !== 0 && "|"} IATA </span>}  {triffData.destinationIATAs && Object.values(triffData.destinationIATAs).join(',')}
                  {triffData && triffData.destinationRegions && triffData.destinationRegions.length !== 0 && <span>{triffData.destinationRegions && triffData.destinationRegions.length !== 0 && <span>{triffData.destinationIATAs && triffData.destinationIATAs.length !== 0 && "|"} Regions - </span>} </span>}    {triffData.destinationRegions && Object.values(triffData.destinationRegions).join(',')}
                  {/* {tariff.destinationCities && tariff.destinationCities.length !== 0 && "| Cities -"}   {tariff.destinationCities && Object.values(tariff.destinationCities).join(',')} */}
                  {triffData && triffData.destinationCitiesOrAirports && triffData.destinationCitiesOrAirports.length !== 0 &&<span>{triffData.destinationRegions && triffData.destinationRegions.length !== 0 && "|"}  Cities Or Airports - </span>}    {triffData.destinationCitiesOrAirports && Object.values(triffData.destinationCitiesOrAirports).join(',')}
              </div>
              </div>
            </div>
            {classData.brandCode &&
            <div className="col-md-3">
              <div className="cm-col">
              <label className="cm-head-label">Brand</label>
              <div className="cm-content">
                {classData.brandCode}
              </div>
              </div>
            </div>
            }
            <div className="col-md-2">
              <div className="cm-col">
                <label className="cm-head-label">Category</label>
                {editFlag ? 
                  <input className="form-control cm-input" />
                :
                  <div className="cm-content">
                    {classData &&  classData.category}
                  </div>
                }
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
              <label className="cm-head-label">Min / Max</label>
              { editFlag ? 
                <input className="form-control cm-input" />
                :
                <div className="cm-content">{classData.minmaxStay} </div>
              }
              </div>
            </div>
            
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Classes</label>
                <div className="cm-content">{classData.classCodes && Object.values(classData.classCodes).join(',')} </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="cm-col">
                <label className="cm-head-label">Ticketing Fee</label>
                <div className="cm-content">{classData.ticketingFee }</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">Note</label>
                <div className="cm-content">{classData.classNote} </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="cm-col">
                <label className="cm-head-label">EDR (Ticketing deadline for this contract)</label>
                { editFlag ? 
                  <input className="form-control cm-input" />
                :
                  <div className="cm-content">
                    {moment(classData && classData.classLastTicketingDate ).format("DD-MMM-YYYY")}
                  </div>
                }
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">French Description</label>
                <div className="cm-content">
                  {(classData.remarksFr ==  null) && " - "}
                  {classData.remarksFr}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cm-col">
                <label className="cm-head-label">English Description</label>
                <div className="cm-content">
                  {classData.remarksEn == null && "-"} 
                  {classData.remarksEn }
                </div>
              </div>
            </div>
          </div>

      

        </div>
      </div>

      <div className="cm-tabs">
          <span
            onClick={() => setClassType("Rules")}
            className={classType === "Rules" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Rules
          </span>
          <span
            onClick={() => setClassType("TicketBy")}
            className={classType === "TicketBy" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Ticket By / Changes
          </span>
          {/* <span
            onClick={() => setClassType("Miscellaneous")}
            className={classType == "Miscellaneous" ? "selected ml-5" : "ml-5"}
          >
            {" "}
            Miscellaneous Details{" "}
          </span> */}
          <span
            onClick={() => setClassType("Routings")}
            className={classType === "Routings" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Routings
          </span>
          <span
            onClick={() => setClassType("Price")}
            className={classType === "Price" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Price
          </span>
          <span
            onClick={() => setClassType("PriceAtSabre")}
            className={classType === "PriceAtSabre" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Price At Sabre
          </span>
          <span
            onClick={() => setClassType("Remarks")}
            className={classType === "Remarks" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Remarks
          </span>
      </div>
      <div className="cm-tab-content">
          {classType === "Rules" && <Rules classData={classData && classData} classId={classId} tariffId={tariffId} />}
          {classType === "TicketBy" && <TicketBy classData={classData.ticketingRules && classData.ticketingRules} />}
          {classType === "Routings" && <Routings classData={classData} />}
          {classType === "Remarks" && <Remarks classData={classData.remarks &&  classData.remarks} />}
          {classType === "Price" && <Price classId={classId} tariffId={tariffId} />}
          {classType === "PriceAtSabre" && <PriceAtSabre priceData={classData.pricingDetails && classData.pricingDetails} />}
      </div>
    </div>
  );
}
