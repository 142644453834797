//@ts-nocheck
import React, { useEffect, useState } from "react";
import AgentInfoIcon from "../bookingConfirmation/Images/agentInfo-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { setLandCruiseBookingNumber, setValidationOnLandCruise } from "../bookingConfirmation/ActionCreator/flightBooking";
import { updatePnr } from "../bookingConfirmation/thunks/updatePNR";
import { getPnrDetails } from "../bookingConfirmation/thunks/getPNRDetails";
// import SpinnerLoader from "../common/spinner";
// import ToastMessages from "../../utils/utility/ToastedMessage";
import { msg } from "../bookingConfirmation/Utils/MessagesContainer";

function BookingNumberForLandCruise(props) {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const bookingNumber = useSelector(
    (state) => state.flightBooking.landCruiseBookingNumber
  );
  const bookingDetail = useSelector(
    (state) => state.flightBooking.bookingItenaries
  );
  const searchForm = useSelector((state) => state.searchForm);
  const agentProfile = useSelector((state) => state.agentProfile);
  const flightDetail = useSelector((state) => state.flightBooking);
  const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);

  const validation = useSelector((state) => state.flightBooking.validationOnLandCruise);
  const[validationTest,setValidationTest] = useState(false)
  
  const { providerId } = searchForm;
  const { orgId, agencyId, agencyGroupId } = agentProfile;

  let from = props.from;
  let fareType =
    bookingDetail &&
    bookingDetail.faresPerTraveller &&
    bookingDetail.faresPerTraveller[0] &&
    bookingDetail.faresPerTraveller[0].fareType;

  const handleBookingNumber = (e) => {
    let bookingNumber = e && e.target && e.target.value;
   
    const regex = new RegExp(/^[A-Z0-9]+$/i)
    const isAlphaNumeric = regex.test(bookingNumber);
    if(bookingNumber && bookingNumber.length > 0){
      dispatch(setValidationOnLandCruise(isAlphaNumeric))
    }
    dispatch(setLandCruiseBookingNumber(bookingNumber)); 
    
  };


  return (
    <>
      <div className="bd-travelAgent-info">
        <div className="bd-sab-head">
        {fareType === "Land Net" &&
          <h3>
            <img src={AgentInfoIcon} alt="icon" /> 
            Add Your Travelbrands land component booking number
          </h3>
         }
          {fareType === "Cruise Net" &&
          <h3>
            <img src={AgentInfoIcon} alt="icon" /> 
            Add Your Travelbrands cruise component booking number
          </h3>
         }
          {from === "confirmation" && bookingDetails && bookingDetails.bookingStatusDetail && bookingDetails.bookingStatusDetail.code !== "NTC" && bookingDetails.bookingStatusDetail.code !== "CR" &&
          <>
          
          </>
         }
        </div>
        {isLoader ? (
          <div className="col-md-12 text-center mt-3">
            {/* <SpinnerLoader /> */}
          </div>
        ) : (
          <div className="bd-travelAgent-info-details">
            <div>
              <div className="bd-priceSummary">
                <div className="ft-notification">
                  <i className="fas fa-info-circle"></i>
                  {fareType === "Land Net" && (
                    <span>
                      All fees incurred by providing a fictitious booking number
                      will be entirely charged to the travel agency, including
                      debit memos, claims or legal proceeding.
                    </span>
                  )}
                  {fareType === "Cruise Net" && (
                    <span>
                      All fees incurred by providing a fictitious booking number
                      will be entirely charged to the travel agency, including
                      debit memos, claims or legal proceeding.
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group bd-pi-formcol pi-formcol-1 bd-booking-number">
              <div
                className={
                  bookingNumber !== undefined && bookingNumber?.length > 0
                    ? "bd-pi-input pi-input-date cust-floting-label active"
                    : "bd-pi-input pi-input-date cust-floting-label"
                }
              >
                <div className="bd-pi-input" style={{ width: "400px" }}>
                  <input
                    disabled={from === "confirmation" && !isEdit}
                    type="text"
                    className="form-control"
                    aria-label="pnr"
                    aria-describedby="addon-wrapping"
                    name="bookingNumber1"
                    id={"bookingNumber1"}
                    autocomplete="off"
                    value={bookingNumber}
                    //onChange={(e) => handleBookingNumber(e)}
                  />
                  {fareType === "Land Net" &&
                  <label
                    htmlFor={"bookingNumber1"}
                    className={
                      bookingNumber?.length > 0
                        ? "cust-fl-label"
                        : "cust-fl-label"
                    }
                  >
                  Travelbrands Land Component Booking Number <sup className="asterisk">*</sup>
                  </label>
                  }
                  {fareType === "Cruise Net" &&
                  <label
                    htmlFor={"bookingNumber1"}
                    className={
                      bookingNumber?.length > 0
                        ? "cust-fl-label"
                        : "cust-fl-label"
                    }
                  >
                    Travelbrands Cruise Component Booking Number <sup className="asterisk">*</sup>
                  </label>
                  }
                </div>
                
              </div>
              {!validation &&
                <div className="bd-landCruise-validation">*Special character not allowed</div>
                }
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BookingNumberForLandCruise;
