// @ts-nocheck
import React from 'react';
import { useHistory } from "react-router-dom";
import { BookingHead } from '../bookingConfirmation/Utils/Constant';
import Dialog from "@material-ui/core/Dialog";
import { useDispatch,useSelector } from 'react-redux';
import {setSearchStatus, setSsrSaveEditFlag} from "../bookingConfirmation/ActionCreator/flightBooking"
import { getEquivalentPublishFare } from '../../appStore/thunks/getEquivalentPublishFare';
import Button from "react-bootstrap/Button";
import '../myfiles/sass/main.scss';

function BookingSubHead(props) {
    const markupStatus = useSelector((state) => state.flightBooking.markupStatus);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleClick = (id) => {
        console.log("id.target.text",id.target.text,BookingHead.NEW_SEARCH,BookingHead.SEARCH_RESULTS)
        
        if (id.target.text === BookingHead.NEW_SEARCH)
            history.push("/");
        if (id.target.text === BookingHead.SEARCH_RESULTS)
        dispatch(setSearchStatus("searchTab"))
            history.push("/SearchResults");
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (status) => {
        dispatch(setSsrSaveEditFlag(true))
        setOpen(false);
        props.saveData(status)
        
    };

    const handleEquivalentClick = () => {
        props.equivalentFare()
        
    }

    return (
        <>
            <div className="bd-sab-head newComp">
                <h3>
                    {props.imgSrc ? <img src={props.imgSrc} alt="icon" /> : ""}
                    {props.title}
                    {props.status ? <span className="bd-sab-head-stauts">{props.status}</span> : ""}
                </h3>
              
                <span>
                {props.equivalentFare ? <a className="bd-sub-head-link" id={props.saveLink} onClick={handleEquivalentClick}>
                        Equivalent Published Fare
                        <span className="bdp-u-Text">
                        <span className="bf-popover-btn" onClick={handleEquivalentClick}>
                              <i className="fas fa-info-circle bd-equivalent"></i>
                        </span>
                        </span>
               </a> : ""}
                {props.isEdit  ? <a className="bd-sub-head-link mr-3"  id={props.saveLink} onClick={()=>handleClose(false)}>Cancel Details</a> : ""}
                    {props.addLink ? <a className="bd-sub-head-link" id={props.addLink} onClick={(id) => handleClick(id)}>{props.addLink}</a> : ""}
                    {props.editLink ? <a className="bd-sub-head-link" id={props.editLink} >{props.editLink}</a> : ""}
                    {props.saveLink ? <a className="bd-sub-head-link" id={props.saveLink} onClick={handleClickOpen}>{props.saveLink}</a> : ""}
                   
                </span>
            </div>

            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="bd-pi-modal"
            >
                <div className="bd-pi-modal-body">
                    <h4 className="bd-pi-modal-title">
                        Are you sure? <span>Do you want to save records?</span>
                    </h4>
                    <div className="bd-pi-modal-btn">
                        <button onClick={()=>handleClose(true)}>Yes</button>
                        <button className="btn-no" onClick={()=>handleClose(false)}>No</button>
                    </div>
                    
                </div>
            </Dialog>
        </>)
}

export default BookingSubHead;