//@ts-nocheck
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core"
import React ,{useEffect, useState } from "react"
import SavedQuotesCard from "./SavedQuotesCard";

const PayableAmountBreakUp = ({
    fareType,
    fareTypeByPnr,
    chequeRadioBtnFlag,
    chequeTotalAmount,
    cardTotalAmt,
    transactionsDetails,
    intairMerchantFee,
    openMerchantBox,
    isMerchantFee,
    ccAllowed,
    adultminimumMarkup,
    childtminimumMarkup,
    infantminimumMarkup,radioBtnShowAndHideFlag,
    fromBookingConfirmation,
    from,
    totalAmtByCreditCard,
    bookingCode,
    agencyDetails,
    paymentRules,
    selected = "Credit Card"
   }) =>{

    const [selectFop,setSelectFop] = useState(selected)
  

    const verbiageMsg = () => {
        let fareTypeValue = (fareType !== undefined && fareType !== "" ) ? fareType : fareTypeByPnr;
        
        let verbiageText;
        if(fareTypeValue === "Published" && selectFop === "Credit Card"){
          verbiageText = "Payable amount equals to Published Base Fare + Taxes and Fees + Markup Applied and Merchant Fees when applicable";
        }
        if(fareTypeValue === "Negotiated" && selectFop === "Credit Card"){
          verbiageText = "Payable amount equals to selling amount + Merchant Fees when applicable";
        }
        if(selectFop === "Cheque"){
          verbiageText = "Payable amount equals to Net Base Fare + Taxes and Fees";
        }
    
        return verbiageText
    
      }


    
    return (
        <>
          {radioBtnShowAndHideFlag &&
            <div className="bd-payment-Fop">
                <div className="by-chq-card by-cheque">
                    If Paying By
                    <div className="payType-radio"
                        style={{
                            position: "relative",
                            right: "10px",
                            top: "3px"
                        }}>
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                        >
                            <FormControlLabel
                                id="inlineRadio1"
                                onChange={({target:{value}}) => setSelectFop(value)}
                                value="Credit Card"
                                control={<Radio color="primary" />}
                                label="Credit Card"
                                checked={selectFop === "Credit Card"}
                                name="radioBtn"
                            />

                            
                                <FormControlLabel
                                    id="inlineRadio2"
                                    onChange={({target:{value}}) => setSelectFop(value)}
                                    value={"Cheque"}
                                    control={<Radio color="primary" />}
                                    label="Cheque"
                                    checked={selectFop === "Cheque"}
                                    name="radioBtn"
                                />
                            


                        </RadioGroup>
                    </div>
                </div>
            </div>
           }
            <>
            <div className="bd-payment-Fop">
                {selectFop === "Cheque" && from !== "confirmation" &&
                    <div className="by-chq-card by-cheque">
                        Payable Amount  <span>$ {chequeTotalAmount && chequeTotalAmount.toFixed(2)}</span>
                    </div>
                }
                {selectFop === "Credit Card" &&   
                <> 
                    {from !== "confirmation" &&
                    <div className="by-chq-card">
                        Payable Amount  <span>$ {cardTotalAmt && cardTotalAmt.toFixed(2)} </span>
                    </div>
                    }
                    <div className="bd-fop-box">
                        <div className="bd-fop-item tran-breakup">
                            Amount Breakdown:
                        </div>
                        {transactionsDetails && transactionsDetails.map((obj, transactionIndex) => {
                            return (

                                <div className="bd-fop-item" key={transactionIndex}>
                                    To {obj.merchant} <span>$ {obj.totalAmount && obj.totalAmount.amount.toFixed(2)}</span>
                                </div>

                            )
                        })}

                        {intairMerchantFee > 0 &&
                            <>
                                <div className="bd-fop-item bd-fop-merchant">Includes merchant fees <strong>$ {intairMerchantFee && intairMerchantFee.toFixed(2)}</strong></div>
                                {from !== "payment" && from !== "confirmation" && (adultminimumMarkup > 0 || childtminimumMarkup > 0 || infantminimumMarkup > 0 || chequeRadioBtnFlag) &&
                                <div className="bd-fop-item bd-fop-link"><a onClick={openMerchantBox}>Want to avoid fees ?</a></div>
                                 }
                                {isMerchantFee &&
                                    <>
                                        {ccAllowed ?
                                            <div className="bd-avoid-mf">
                                                {
                                                    adultminimumMarkup > 0 || childtminimumMarkup > 0 || infantminimumMarkup > 0 ?
                                                        <>
                                                            <div className="bd-fop-item">Enter an amount lower than</div>
                                                            {adultminimumMarkup > 0 && <div className="bd-fop-item">$ {adultminimumMarkup}  for Adult</div>}
                                                            {childtminimumMarkup > 0 && <div className="bd-fop-item">$ {childtminimumMarkup} for Child</div>}
                                                            {infantminimumMarkup > 0 && <div className="bd-fop-item">$ {infantminimumMarkup} for Infant</div>}
                                                        </>
                                                        :
                                                        chequeRadioBtnFlag ?
                                                            <div className="bd-fop-item">Pay using Cheque</div> :
                                                            <div />
                                                }
                                            </div>
                                            : chequeRadioBtnFlag ?
                                            <div className="bd-avoid-mf"><div className="bd-fop-item">Pay using Cheque</div></div>:
                                            <div/>

                                        }                                   
                                    </>
                                }
                            </>
                        }
                    </div>
                </> 
                }  
           </div>  
           {(cardTotalAmt !== 0  || totalAmtByCreditCard !== 0)  && verbiageMsg() &&
                <div className="bd-alertBox 123"> 
                  <i className="fa fa-exclamation-circle bd-payableAmt" aria-hidden="true"></i>
                   {verbiageMsg()}
                </div>
            } 
            </>
           {from !== "payment" && from !== "confirmation" &&
            <SavedQuotesCard />
           }
        </>
    )

}

export default PayableAmountBreakUp;