import React, { forwardRef, useContext, useState } from "react";
import { Input } from "../../components/common components/Input";
import { CheckBox } from "../../components/common components/Checkbox";
import { Radio } from "../../components/common components/Radio";
import { Select } from "../../components/common components/Select";
import { Button } from "../../components/common components/Button";
import { Datepicker } from "../../components/common components/Datepicker";
import moment from "moment";
import authContext from "../../context/auth-context";
import { CommonAutocomplete } from "../../components/common components/Autocomplete";

export const CONSTANTS = {
    "INPUT": "INPUT",
    "SELECT": "SELECT",
    "CHECKBOX": "CHECKBOX",
    "RADIO": "RADIO",
    "BUTTON": "BUTTON",
    "DATEPICKER": "DATEPICKER",
    "AUOCOMPLETE": "AUTOCOMPLETE"
}

export const FORM_INPUTS = {
    [CONSTANTS.INPUT]: Input,
    [CONSTANTS.CHECKBOX]: CheckBox,
    [CONSTANTS.SELECT]: Select,
    [CONSTANTS.RADIO]: Radio,
    [CONSTANTS.BUTTON]: Button,
    [CONSTANTS.DATEPICKER]: Datepicker,
    [CONSTANTS.AUOCOMPLETE]: CommonAutocomplete
}

export const CREATE_ELEMENT = (data:any, register:any) => {
    const { component, props, className } = data;
    
    if (!props || typeof props !== 'object' || !props.name) {
        console.error("Invalid props object or missing 'name' property.");
        return null;
    }

    const { key, name, rules } = props;
    let CustomComponent = component;

    const ForwardedComponent = forwardRef((props, ref) => (
        <CustomComponent {...props} ref={ref} {...className} />
    ));

    return (
        <div>
            <ForwardedComponent
                {...props}
                ref={register(key)} 
            />
        </div>
    );
};
