//@ts-nocheck
import { env } from '../appConfig'
import { setMyFilesData } from "../ActionCreator/myFiles"
import { setMyFileError} from "../ActionCreator/myFilesError"
// import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from "uuid";

async function getApiResponse(url, requestOptions ,dispatch) {
     await fetch(url, requestOptions)
    .then(res => res.json())
    .then(response => {
        const data =  response;
        console.log("data",data)
        return data;
        })
      .catch(err => {
        dispatch(setMyFileError(true))
      })
  }


export const getMyFiles = (requestPayload ,flightTypeData) => {


    return async (dispatch, getState) => {

        // const tokenData = getState().initToken;
        // const token = tokenData && tokenData.token && tokenData.token.token;
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType
        const newFlightType = flightType === flightTypeData ? flightType : flightTypeData
        //let corrId = uuidv4();
        let url = env.REACT_APP_API_BASE_URL + `/myFiles/getListOfAllBookingFiles`
        dispatch(setMyFileError(false))
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:newFlightType 

            },
            body:
                JSON.stringify(requestPayload)
        }


        const myFilesResult =   await fetch(url, requestOptions)
           .then(res => res.json())
           .then(response => {
               const data =  response;
               console.log("data",data)
               return data;
               })
             .catch(err => {
               dispatch(setMyFileError(true))
             })
         

                    if(myFilesResult === null){
                        dispatch(setMyFileError(true))
                    }
        if (myFilesResult&&myFilesResult.bookings&&myFilesResult.bookings.length>0) {
            dispatch(setMyFilesData(myFilesResult.bookings))
            dispatch(setMyFileError(false))
            return myFilesResult
        }else{
            return myFilesResult
        }
        
    }
}

export const getMyFileSearchByPNR = (requestPayload ,flightTypeData) => {


    return async (dispatch, getState) => {

        // const tokenData = getState().initToken;
        // const token = tokenData && tokenData.token && tokenData.token.token;
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType
        const newFlightType = flightType === flightTypeData ? flightType : flightTypeData
        //let corrId = uuidv4();
        let url = env.REACT_APP_API_CORE_BASE_URL + `/booking/b2b/GetAllBookings`
        dispatch(setMyFileError(false))
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:newFlightType ? newFlightType : "CAD"

            },
            body:
                JSON.stringify(requestPayload)
        }


        const myFilesResult =   await fetch(url, requestOptions)
           .then(res => res.json())
           .then(response => {
               const data =  response;
               console.log("data",data)
               return data;
               })
             .catch(err => {
               dispatch(setMyFileError(true))
             })
         

                    if(myFilesResult === null){
                        dispatch(setMyFileError(true))
                    }
        if (myFilesResult&&myFilesResult.bookings&&myFilesResult.bookings.length>0) {
            dispatch(setMyFilesData(myFilesResult.bookings))
            dispatch(setMyFileError(false))
            return myFilesResult
        }else{
            return myFilesResult
        }
        
    }
}