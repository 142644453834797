//@ts-nocheck
import React,{useState} from "react";
import BookingSubHead from "./BookingSubHead";
import AgentInfoIcon from "../bookingConfirmation/Images/agentInfo-icon.svg";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import { setAgentEmergencyNumber, setEmailLanguage } from "../bookingConfirmation/ActionCreator/flightBooking";
import InputMask from "react-input-mask";
import { Link} from 'react-router-dom';

function BookingTravelAgentInfo(props) {
  const dispatch = useDispatch();
  // const agentCode = useSelector((state) => state.agentProfile.agentCode);
  // const agencyName = useSelector((state) => state.agentProfile.agencyName);
  // const travelAgentName = useSelector((state) => state.agentProfile.agentName);
  // const agentEmailId = useSelector((state) => state.agentProfile.agentEmailId);
  const internalUserData = useSelector(
    (state) => state.internalUser.internalUserData
  );
  const agentEmergencyNumber = useSelector(
    (state) => state.flightBooking.agentEmergencyNumber
  );
  const emailLanguage = useSelector((state) => state.flightBooking.emailLanguage);
  const flightBookingDetails = useSelector((state) => state.flightBooking.getPnrResult);
  const [emailLang,setEmailLang] = useState("English")

  const flowFrom = props.from

  let travelAgencyDetails = flightBookingDetails.agency;
    let travelAgentDetails =  flightBookingDetails.travelAgent;
    let agencyCode = travelAgencyDetails && travelAgencyDetails.code;
    let travelAgencyName = travelAgencyDetails && travelAgencyDetails.name;

    // AGENT DETAILS
    let agentFirstName = travelAgentDetails && travelAgentDetails.firstName;
    let agentLastName =   travelAgentDetails &&  travelAgentDetails.lastName;
    let agentEmailAddress = travelAgentDetails &&  travelAgentDetails.email;
    let agentFullName = agentFirstName + " " + agentLastName;
    
    const agentCode = agencyCode;
    const agencyName = travelAgencyName;
    const travelAgentName = agentFullName;
    const agentEmailId = agentEmailAddress;
    const agentUserName = flightBookingDetails.userId;

  const handleEmergencyNumber = (e) => {
    let emergencyNumber = e && e.target && e.target.value;
    dispatch(setAgentEmergencyNumber(emergencyNumber));
  };


  const handleEmailLanguage = (value) => {
  console.log("value12345",value)
  setEmailLang(value)
  dispatch(setEmailLanguage(value))

  }
 

  return (
    <>
                {/* <Link to="/myFiles/getListOfFiles" className="text-primary"><i className="fas fa-chevron-left mr-2"></i>Back to Listing</Link> */}

      <div className="bd-travelAgent-info">
        <BookingSubHead
          title={"travel agent information"}
          addLink={false}
          imgSrc={AgentInfoIcon}
        />
        <div className="bd-travelAgent-info-details">
          <p>
            Agency Code &amp; Name{" "}
            <strong>
              {agentCode} - {agencyName}
            </strong>
          </p>
          <p>
            Travel Agent Name<strong>{agentFullName}</strong>
          </p>
          <p>
            Personal Username
            <strong>{agentUserName} </strong>
          </p>
          <p>
            Email Address<strong>{agentEmailId}</strong>
          </p>
          {flowFrom === "booking" &&
          <p>
            Emergency Contact Phone Number
            <div className="form-group bd-pi-formcol pi-formcol-1 bd-booking-number">
              <div
                className={
                  agentEmergencyNumber !== undefined &&
                  agentEmergencyNumber?.length > 0
                    ? "bd-pi-input pi-input-date cust-floting-label active"
                    : "bd-pi-input pi-input-date cust-floting-label"
                }
              >
                <div className="bd-pi-input" style={{ width: "300px" }}>
                <InputMask
                  className="contact-number"
                  mask="999-999-9999"
                  name="number"
                  onChange={(e) => handleEmergencyNumber(e)}
                  Required
                  maskChar=""
                  value={agentEmergencyNumber}
                            
                  >
                    {/* {() =>
                      <input
                        type="text"
                        className="form-control"
                        aria-label="pnr"
                        aria-describedby="addon-wrapping"
                        placeholder="Enter Emergency Phone Number"
                        name="bookingNumber"
                        id={"bookingNumber"}
                        autocomplete="off"
                        value={agentEmergencyNumber}
                        onChange={(e) => handleEmergencyNumber(e)}
                      />
                          } */}
                    {() => <TextField
                      className="contact-number"
                      // label="Phone Number"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      name="number"
                      placeholder="Emergency Contact Phone Number"
                    
                    />}
                  </InputMask>                
                </div>
              </div>
            </div>
          </p>
          }
          {flowFrom === "confirmation" && agentEmergencyNumber !== "" && agentEmergencyNumber !== undefined &&
           <p className="bd-emergency-number">
            Emergency Contact Phone Number<strong>{agentEmergencyNumber}</strong>
         </p>
          }
          <p>
            <span>Email Language</span>
            <div className="form-group" style={{marginTop: "8px",width: "164px"}}>
            <select
              className="button form-control"
              placeholder="Two"
              value={emailLanguage}
              onChange={(e) => handleEmailLanguage(e.target.value)}
              disabled={flowFrom === "confirmation"}
            >              
              <option value="English">English</option>
              <option value="French">French</option>            
            </select>
          </div>          
          </p>      
        </div>
      </div>
    </>
  );
}

export default BookingTravelAgentInfo;
