import {
  AGENCY_LIST,
  } from "../constant/types";
  
  const intialState:any = {
       agencyList:[],
       agecnySearchData:{
        agencyCode: '',
        networkId: '',
        affiliation: '',
        name: '',
        phone: '',
        postalCode: '',
        sortBy: '',
        country: '',
        provincesOrStates: '',
      }
  };
  
  export const agencyListReducer = (state = intialState, action:any) => {
    switch (action.type) {
      case AGENCY_LIST:
        return {
          ...state,
          agencyList: action.payload,
        };
      default:
        return state;
    }
  };
  export default agencyListReducer;