
export const env = {
    REACT_APP_API_BASE_URL : "https://tbi-gg-b2b.uat.tbidevelopment.ca",
    REACT_APP_STATIC_CONTENT : "https://tbi-gg-static.uat.tbidevelopment.ca",
    REACT_APP_API_CORE_BASE_URL : "https://tbi-gg-flightcore.uat.tbidevelopment.ca",
    REACT_APP_PROFILE_ORG_BASE_URL : "https://tbi-gg-profile.uat.tbidevelopment.ca", 
    REACT_APP_FRAUD_CHECK_URL : "https://tbi-gg-fraud.uat.tbidevelopment.ca",
	SKIP_PREFLIGHT_CHECK : "true",
    REACT_APP_HEADER_URL : "https://www.travelbrandsagent.com/sso/headerAir_EN.js",
    REACT_APP_FOOTER_URL : "https://www.travelbrandsagent.com/sso/footer_EN.asp",
    FARE_CACHE_BASE_URL : "https://tbi-gg-farecache.uat.tbidevelopment.ca",
    FRAUD_CHECK_BASE_URL: "https://tbi-gg-fraud.uat.tbidevelopment.ca",
    ORGANISATION_ID: "nTDopHgAED5SkaQGM6VKPG"
}