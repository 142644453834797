import { env } from '../appConfig'
import axios from 'axios';

export const updateSearchCriteria = async (payload: any, id:any, correlationId:any) => {
        let url: any = env.FARE_CACHE_BASE_URL + `/cacheSearchCriterias/updateSearchCriteria?id=${id}`;
        const requestOptions: any = {
            // method: "PUT",
            headers: {
                "Content-type": "application/json",
                correlationId: correlationId
            },
            // body: payload
        }
        const updateOperation:any = axios.put(url, payload, requestOptions)
        
        // await fetch(url, requestOptions)
        //     .then(res => res.json())
        //     .then(response => {
        //         const data = response;
        //         console.log("data", data)
        //         return data;
        //     })
        //     .catch(err => {
        //         console.log('update failed with exception', err)
        //     })

        if (updateOperation === null) {
            // CreateSearchCriteria failed
        }
        if(updateOperation?.message){
            return true
        }else{
            return false
        }
    // }
}