import { env } from '../appConfig'
import axios from 'axios';

export const createSearchCriteria = async(payload: any, correlationId:any) => {
        let url: any = env.FARE_CACHE_BASE_URL + `/cacheSearchCriterias/addSearchCriteria`;
        const requestOptions: any = {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                correlationId: correlationId
            },
            // body: payload
        }
        const createOperation = await axios.post(url, payload, requestOptions)

        if (createOperation === null) {
            // CreateSearchCriteria failed
        }
        return createOperation
}