// @ts-nocheck
import {SET_PNR,SET_PASSENGER_DATA,SET_ITINARY_FARE,SET_SEGMENT,SET_BOOKING_DETAIL, SET_BOOKING_PAYMENT_STATUS, SET_ITINARY_FARES_TYPE,
    SET_BOOKING_DATE,SET_BOOKING_STATUS,PRICE_STATUS, SET_PNR_CANCELLATION_STATUS, SET_CANCELLATION_POPUP,SET_BRANDED_ITINARY_FARE,SET_SEATMAP_HEADER, 
    SET_PASSENGER_DETAILS_ARRAY, SET_BOOK_WITH_PAYMENT_FLAG,SET_PNR_PRICE,SET_BRANDED_STATUS,SET_SEARCH_STATUS,SET_PAYMENT_RULES, SET_MARKUP_STATUS,
   SET_ADULT_COUNT,SET_CHILDREN_COUNT,SET_INFANT_COUNT,
   SET_MYFILE_FLAG,SET_MY_RESULT_FLAG,
   SET_PASSENGER_FRAUD_LIST, SET_FRAUD_USER_EXISTS, SET_FRAUD_USER_EXISTS_WITH_PAYMENT, SET_FRAUD_USER_FLAG, SET_PAYMENT_RULES_FOR_CHEQUE, SET_CREATE_BOOKING_DATA, SET_PRICING_FARE_DATA, SET_SSR_LOADER_FLAG, SET_SSR_SAVE_EDIT_FLAG,SET_APPLY_BUTTON_CLICK_FLAG, SET_LAND_CRUISE_BOOKING_NUMBER, SET_AGENT_EMERGENCY_NUMBER, SET_VALIDATION_ON_LAND_CRUISE, SET_EQUIVALENT_FARE_VALUE, SET_EQUIVALENT_FARE_LOADER, SET_REPRICE_FARE, SET_REPRICE_FARE_LOADER, SET_FREQUENT_FLYER_DROPDOWN, SET_GET_PNR_RESULT, SET_BOOKING_STATUS_CODE, SET_FARE_PAYMENT_ATTEMPT, SET_SEAT_PAYMENT_ATTEMPT, SET_FARE_PAYMENT_STATUS, SET_SEAT_PAYMENT_STATUS, SET_FOP_SELECTION, SET_EMAIL_LANGUAGE, SET_MARKUP_LOADER, SET_FLOW_FROM, SET_CALC_ERRORS } from './../storeConstant';
  
   
export const setPnr = (pnr) => {
   return {
       type: SET_PNR,
       payload: pnr
   }
}

export const setSegment = (value) => {
   return {
       type: SET_SEGMENT,
       payload: value
   }
}

export const setItenaryData = (value) => {
   
   return {
       type: SET_ITINARY_FARE,
       payload: value
   }
}

export const setPassengerData = (value) => {
   return {
       type: SET_PASSENGER_DATA,
       payload: value
   }
}

export const setBookingDetails = (value) => {
   return {
       type: SET_BOOKING_DETAIL,
       payload: value
   }
}

export const setBookingPaymentPaymentStatus = (value) => {
   return {
       type: SET_BOOKING_PAYMENT_STATUS,
       payload: value
   }
}


export const setItinaryFaresType = (value) => {
   return {
       type: SET_ITINARY_FARES_TYPE,
       payload: value
   }
}


export const setBookingDate = (value) => {
   return {
       type: SET_BOOKING_DATE,
       payload: value
   }
}

export const setBookingStatus=(value)=>{
   return{
       type:SET_BOOKING_STATUS,
       payload:value
   }
}

export const setSuccessPrice=(value)=>{
   return{
       type:PRICE_STATUS,
       payload:value
   }
}

export const setPnrCancellationStatus =(value)=>{
   return{
       type:SET_PNR_CANCELLATION_STATUS,
       payload:value
   }
}

export const setCancellationPopUp =(value)=>{
   return{
       type:SET_CANCELLATION_POPUP,
       payload:value
   }
}

export const setPassengerDetailsArray =(value)=>{
   return{
       type:SET_PASSENGER_DETAILS_ARRAY,
       payload:value
   }
}

export const setBookWithPaymentFlag =(value)=>{
   return{
       type:SET_BOOK_WITH_PAYMENT_FLAG,
       payload:value
   }
}


export const setBrandedItenaryData = (value) => {
   return {
       type: SET_BRANDED_ITINARY_FARE,
       payload: value
   }
}

export const setSeatMapHeader = (value) => {
   return {
       type: SET_SEATMAP_HEADER,
       payload: value
   }
}

export const setPnrPrice = (result) => {
   return {
       type: SET_PNR_PRICE,
       payload: result
   }
}

export const setBrandedStatus = (from) => {
   return {
       type: SET_BRANDED_STATUS,
       payload: from
   }
}

export const setSearchStatus = (from) => {
   return {
       type: SET_SEARCH_STATUS,
       payload: from
   }
}

export const setPaymentRules = (from) => {
   return {
       type: SET_PAYMENT_RULES,
       payload: from
   }
}
export const setPaymentRulesForCheque = (from) => {
   return {
       type: SET_PAYMENT_RULES_FOR_CHEQUE,
       payload: from
   }
}

export const setMarkupApplied = (from) => {
   return {
       type: SET_MARKUP_STATUS,
       payload: from
   }
}


export const setPassengerFraudList = (passenger) => {
   return {
       type: SET_PASSENGER_FRAUD_LIST,
       payload: passenger
   }
}

export const setFraudUserExists = (value) => {
   return {
       type: SET_FRAUD_USER_EXISTS,
       payload: value
   }
}

export const setFraudUserExistsWithPayment = (value) => {
   return {
       type: SET_FRAUD_USER_EXISTS_WITH_PAYMENT,
       payload: value
   }
}

export const setFraudUserFlag = (value) => {
   return {
       type: SET_FRAUD_USER_FLAG,
       payload: value
   }
}

export const setAdultCount = (adultCount) => {
   return {
       type: SET_ADULT_COUNT,
       payload: adultCount
   }
}

export const setChildrenCount = (childrenCount) => {
   return {
       type: SET_CHILDREN_COUNT,
       payload: childrenCount
   }
}

export const setInfantCount = (infantCount) => {
   return {
       type: SET_INFANT_COUNT,
       payload: infantCount
   }
}

export const setMyfilesFlag = (flag) => {
   return {
       type: SET_MYFILE_FLAG,
       payload: flag
   }
}

export const setCreateBookingData = (pnr) => {
   return {
       type: SET_CREATE_BOOKING_DATA,
       payload: pnr
   }
}

export const setPricingFareData = (pnr) => {
   return {
       type: SET_PRICING_FARE_DATA,
       payload: pnr
   }
}
export const setSsrLoaderFlag = (pnr) => {
   return {
       type: SET_SSR_LOADER_FLAG,
       payload: pnr
   }
}

export const setSsrSaveEditFlag = (pnr) => {
   return {
       type: SET_SSR_SAVE_EDIT_FLAG,
       payload: pnr
   }
}
export const setApplyButtonClickFlag = (pnr) => {
   return {
       type: SET_APPLY_BUTTON_CLICK_FLAG,
       payload: pnr
   }
}
export const setLandCruiseBookingNumber = (pnr) => {
   return {
       type: SET_LAND_CRUISE_BOOKING_NUMBER,
       payload: pnr
   }
}
export const setAgentEmergencyNumber = (pnr) => {
   return {
       type: SET_AGENT_EMERGENCY_NUMBER,
       payload: pnr
   }
}
export const setValidationOnLandCruise = (pnr) => {
   return {
       type: SET_VALIDATION_ON_LAND_CRUISE,
       payload: pnr
   }
}
export const setEquivalentFareValue = (value) => {
   return {
       type: SET_EQUIVALENT_FARE_VALUE,
       payload: value
   }
}
export const setEquivalentFareLoader = (value) => {
   return {
       type: SET_EQUIVALENT_FARE_LOADER,
       payload: value
   }
}
export const setRepriceFare = (value) => {
   return {
       type: SET_REPRICE_FARE,
       payload: value
   }
}

export const setResultFlag = (value) => {
    return {
        type: SET_MY_RESULT_FLAG,
        payload: value
    }
}

export const setRepriceFareLoader = (value) => {
   return {
       type: SET_REPRICE_FARE_LOADER,
       payload: value
   }
}

export const setFrequentFlyerDropdown = (value) => {
   return {
       type: SET_FREQUENT_FLYER_DROPDOWN,
       payload: value
   }
}

export const setGetPnrResult = (value) => {
   return {
       type: SET_GET_PNR_RESULT,
       payload: value
   }
}

export const setBookingStatusCode = (value) => {
   return {
       type: SET_BOOKING_STATUS_CODE,
       payload: value
   }
}

export const setFarePaymentAttempt = (value) => {
   return {
       type: SET_FARE_PAYMENT_ATTEMPT,
       payload: value
   }
}

export const setSeatPaymentAttempt = (value) => {
   return {
       type: SET_SEAT_PAYMENT_ATTEMPT,
       payload: value
   }
}

export const setFarePaymentStatus = (value) => {
   return {
       type: SET_FARE_PAYMENT_STATUS,
       payload: value
   }
}

export const setSeatPaymentStatus = (value) => {
   return {
       type: SET_SEAT_PAYMENT_STATUS,
       payload: value
   }
}
export const setFopSelection = (value) => {
   return {
       type: SET_FOP_SELECTION,
       payload: value
   }
}
export const setEmailLanguage = (value) => {
   return {
       type: SET_EMAIL_LANGUAGE,
       payload: value
   }
}
export const setMarkupLoader = (value) => {
   return {
       type: SET_MARKUP_LOADER,
       payload: value
   }
}

export const setFlowFrom = (value) => {
   return {
       type: SET_FLOW_FROM,
       payload: value
   }
}

export const setCalcErrors = (value) => {
   return {
       type: SET_CALC_ERRORS,
       payload: value
   }
}