import { SET_INTERNAL_USER_LOGIN, SET_INTERNAL_USER_ACCESS_TOKEN, SET_LOGGED_IN_USER_DATA } from '../storeConstant';


const initialState = {
    isInternalUserAutheticated: undefined,
    internalUserAccessToken: undefined,
    internalUserData: {}
};

const InternalUserReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_INTERNAL_USER_LOGIN:
            return {
                ...state,
                isInternalUserAutheticated: action.payload
            }
            
        case SET_INTERNAL_USER_ACCESS_TOKEN:
            return {
                ...state,
                internalUserAccessToken: action.payload
            }

        case SET_LOGGED_IN_USER_DATA:
            return {
                ...state,
                internalUserData: action.payload
            } 

        default:
            return state;

    }
}

export default InternalUserReducer;