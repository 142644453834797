//@ts-nocheck
export default async function getApiResponse(url, requestOptions) {           
    const response = await fetch(url,requestOptions);
 
     if (!response.ok) {
       return null;
      
     } else {
       const data = await response.json();
       return data;
     }
  
}