import axios from 'axios';

export const getClassData = async (value: any) => {
        let url: any = process.env.REACT_APP_CONTRACT_URL + `/classesV2/${value}`;
        const requestOptions: any = {
            headers: {
                "Content-type": "application/json",
            },
        }
        const getContracts:any = await axios.get(url)

        return getContracts?.data;
    
}