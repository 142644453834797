import { env } from '../appConfig'

export const deleteSearchCriteria = async(id:any, correlationId:any) => {


    // return async (getState:any) => {
        // const corrId = getState().searchForm.uuid;
        let url:any = env.FARE_CACHE_BASE_URL + `/cacheSearchCriterias/deleteSearchCriteria?id=${id}`;
        const requestOptions:any = {
            method: "DELETE",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "cache-control": "no-cache",
                correlationId: correlationId
            }
        }

        const deleteOperation =   await fetch(url, requestOptions)
           .then(res => res.json())
           .then(response => {
               const data =  response;
               console.log("data",data)
               return data;
               })
             .catch(err => {
                console.log('delete failed with exception',err)
             })
         

                    if(deleteOperation === null){
                        // delete operation failed
                    }
        if (deleteOperation?.message == "Success") {
            return true
        }
        else{
            return false
        }
    // }
}