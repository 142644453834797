//@ts-nocheck
import { setfreeTextFareRules,setstructuredFareRules } from '../ActionCreator/penaltiesTab';
import { Penalties, TripType } from '../Utils/Constant';
import { env } from '../appConfig'

// Api Function for Text Fare Rules

async function getApiResponsetextFareRules(url, requestOptions) {

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        return null;
    } else {
         let data = await response;
         if(data) {

             data = data.json();
             return data;
         } 

     
        return data;
    }

}

export const getPenaltiesDataForPNR = (recordLocator) => {


    return async (dispatch, getState) => {
        let group = [];
        let textFareRule=[];
        const corrId = getState().searchForm.uuid;
        const tripTypeValue = getState().searchForm.tripType;
        const penaltiesTextFareData = getState().Penalaties.freeTextFareRules;
        penaltiesTextFareData && textFareRule.push(...penaltiesTextFareData);
        const orgId = getState().agentProfile.orgId;
        const agencyId = getState().agentProfile.agencyId;
        const agencyGroupId = getState().agentProfile.agencyGroupId;
        const flightType = getState().searchForm.flightType;
        
        // Api For FareRules
       
        let url = env.REACT_APP_API_BASE_URL+`/revisedFareRules/freeText`

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials" : true,
                correlationId: corrId,
                flightType:flightType

            },
            body:
                JSON.stringify(
                    {
                        organizationId: orgId ,
                        agencyId: agencyId,
                        agencyGroupId: agencyGroupId ,
                        recordLocator: recordLocator
                    })
        }      
      


        const penaltiesResultFareRules = await getApiResponsetextFareRules(url, requestOptions);

         if(penaltiesResultFareRules && penaltiesResultFareRules.error){
            const result = {
                [recordLocator]: Penalties.STR_FARE_RULES_ERROR
              }
              textFareRule.push(result);
              dispatch(setstructuredFareRules(textFareRule));
            dispatch(setfreeTextFareRules(textFareRule));
         } else if( penaltiesResultFareRules && penaltiesResultFareRules.segments){
            if(tripTypeValue === TripType.MULTICITY){
                const result = {
                    [recordLocator]: penaltiesResultFareRules && penaltiesResultFareRules.segments
                  }
                textFareRule.push(result);
                dispatch(setfreeTextFareRules(textFareRule));
            }
            if(tripTypeValue === TripType.ONEWAY){    
                    
                const result = {
                    [recordLocator]: penaltiesResultFareRules && penaltiesResultFareRules.segments && penaltiesResultFareRules.segments[0]
                }
                textFareRule.push(result);                 
                dispatch(setfreeTextFareRules(textFareRule));
            }            

            if(tripTypeValue === TripType.ROUNDTRIP || tripTypeValue === TripType.OPENJAW){            
                const result = {
                    [recordLocator]: penaltiesResultFareRules
                  }
                textFareRule.push(result);                 
               dispatch(setfreeTextFareRules(textFareRule));
              }             
            
         }
       
       
    }
}