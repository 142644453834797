import { useContext, useEffect, useState  } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function Remarks(props) {
    const [editFlag ,setEditFlag] = useState(false);

    const selectEdit = () => {
        setEditFlag(true)
    }
    return (
        <div className="card cm-card">
            <div className="card-body">
            <div className="cm-card-edit">
                {/* {editFlag ?
                <div>
                    <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                    <button className="cm-primary-btn" onClick={(e)=>setEditFlag(false)}> Save</button>
                </div>
              :
                <i onClick={(e)=>selectEdit(true)} class="far fa-edit"></i>
            } */}
            </div>
                <div className="row ">
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">INTAIR</label>
                        <div className="row">
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input"  />
                                
                                </>
                            :
                                <>
                                <label className="cm-head-label">French - {props.classData && props.classData.VIT && props.classData.VIT.french }</label>
                                {props.classData && props.classData.VIT && props.classData.VIT.french == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                            }
                            </div>
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input"  />
                                
                                </>
                            :
                                <>
                                <label className="cm-head-label">English - {props.classData && props.classData.VIT && props.classData.VIT.english }</label>
                                {props.classData && props.classData.VIT && props.classData.VIT.english == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">SKYLINK</label>
                        <div className="row">
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input"  />
                                
                                </>
                            :
                            <>
                                <label className="cm-head-label">French - {props.classData && props.classData.SKY && props.classData.SKY.french }</label>
                                {props.classData && props.classData.SKY && props.classData.SKY.french == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                                }
                            </div>
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input"  />
                                </>
                            :
                                <>
                                <label className="cm-head-label">English - {props.classData && props.classData.SKY && props.classData.SKY.english }</label>
                                {props.classData && props.classData.SKY && props.classData.SKY.english == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">FUN SUN</label>
                        <div className="row">
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input"  />
                                </>
                            :
                                <>
                                <label className="cm-head-label">French - {props.classData && props.classData.FUN && props.classData.FUN.french }</label>
                                {props.classData && props.classData.FUN && props.classData.FUN.french == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                                }
                            </div>
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input"  />
                                </>
                            :
                                <>
                                <label className="cm-head-label">English - {props.classData && props.classData.FUN && props.classData.FUN.english }</label>
                                {props.classData && props.classData.FUN && props.classData.FUN.english == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">DFW</label>
                        <div className="row">
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input"  />
                                </>
                            :
                                <>
                                <label className="cm-head-label">French - {props.classData && props.classData.DFW && props.classData.DFW.french }</label>
                                {props.classData && props.classData.DFW && props.classData.DFW.french == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                                }
                            </div>
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input"  />
                                </>
                            :
                                <>
                                <label className="cm-head-label">English - {props.classData && props.classData.DFW && props.classData.DFW.english }</label>
                                {props.classData && props.classData.DFW && props.classData.DFW.english == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">TC</label>
                        <div className="row">
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">French - </label>
                                <input className="form-control cm-input"  />
                                </>
                            :
                                <>
                                <label className="cm-head-label">French - {props.classData && props.classData.TC && props.classData.TC.french }</label>
                                {props.classData && props.classData.TC && props.classData.TC.french == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                                }
                            </div>
                            <div className="col-md-6">
                            {editFlag ?
                                <>
                                <label className="cm-head-label">English - </label>
                                <input className="form-control cm-input"  />
                                </>
                            :
                                <>
                                <label className="cm-head-label">English - {props.classData && props.classData.TC && props.classData.TC.english }</label>
                                {props.classData && props.classData.TC && props.classData.TC.english == null &&
                                <input className="form-control cm-input" disabled />
                                }
                                </>
                        }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
// clasRemarquesFrVIT
// clasRemarquesAnVIT
// clasRemarquesFrSKY
// clasRemarquesAnSKY
// clasRemarquesFrFUN
// clasRemarquesAnFUN
// clasRemarquesAnDFW
// clasRemarquesFrDFW