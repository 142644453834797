import { SET_FREE_TEXT_FARE_RULES, SET_STRUCTURED_FARE_RULES } from "../storeConstant";

const initialState = {
    freeTextFareRules: [],
    structuredFareRules: []
};


const PenalatiesReducer = (state = initialState, action) => {

    switch (action.type) {       

        case SET_FREE_TEXT_FARE_RULES:
            return {
                ...state,
                freeTextFareRules: action.payload
            }


        case SET_STRUCTURED_FARE_RULES:
            return {
                ...state,
                structuredFareRules: action.payload
            }


        default:
            return state;
    }
}

export default PenalatiesReducer;