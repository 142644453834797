// @ts-nocheck
import React, { useState } from "react";
import bags from "../bookingConfirmation/Images/bag-icon.svg";
import seats from "../bookingConfirmation/Images/seat-icon.svg";

import ItineraryIcon from "../bookingConfirmation/Images/Itinerary-Icon.svg";
import moment from "moment";
import DetailsTab from "../bookingConfirmation/detailsTab";
import { useDispatch } from "react-redux";
import BookingSubHead from "./BookingSubHead";
import { useSelector } from "react-redux";
import { getPenaltiesData } from "../bookingConfirmation/thunks/getPenaltiesData";
import { getPenaltiesDataForPNR } from '../bookingConfirmation/thunks/getPenaltiesDataForPNR';
import { getFareOptions } from "../bookingConfirmation/thunks/getFareOption";
import { timeConvert } from "../bookingConfirmation/Utils/convertmintohours";
import { BookingFrom, BookingHead, BookingStatusCode, FlightLogo, TripType } from "../bookingConfirmation/Utils/Constant";
import { diffrenceInDatesCheck, getFlightLogo,splitFromCapital } from "../bookingConfirmation/Utils/CommenFunction";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";


function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function BookingItineryDetails(props) {
  const bookingHeader = useSelector((state) => state.searchForm.bookingHeader);
  const flyingFromCity = useSelector((state) => state.searchForm.flyingFromCity);
  const flyingToCity = useSelector((state) => state.searchForm.flyingToCity);
  const flyingFrom = useSelector((state) => state.searchForm.flyingFrom);
  const flyingTo = useSelector((state) => state.searchForm.flyingTo);
  const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);

  const openJawFlyingFromCity_B = useSelector((state) => state.searchForm.openJawFlyingFromCity_B);
  const openJawFlyingToCity_B = useSelector((state) => state.searchForm.openJawFlyingToCity_B);
  const openJawFlyingFrom_B = useSelector((state) => state.searchForm.openJawFlyingFrom_B);
  const openJawFlyingTo_B = useSelector((state) => state.searchForm.openJawFlyingTo_B);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const cabin = useSelector((state) => state.searchForm.cabin);
  
  const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
  const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
  const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
  const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
  const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
  const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
  const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)

  const bookingItineriesData = useSelector((state) => state.flightBooking.bookingItenaries);
  const paxFareDetails = useSelector((state) => state.searchForm.paxFareDetails);
  const brandCode = useSelector((state)=>state.fareOptions.brandCode)
  let paxFares = bookingItineriesData && bookingItineriesData.paxFares
  let adultsCount 
  let childCount
  let infantCount

  const dispatch = useDispatch();

  const [flightDIndex, setFlightDIndex] = useState(null);
  const [seatMapIndex, setSeatMapIndex] = useState(null);
  const [setHideSeatMap] = useState();
  const [fareOption, setFareOption] = useState();
  const [fareProps, setFareProps] = useState();
  const [HideFlight1, setHideFlight1] = useState(false);
  const [HideFlight2, setHideFlight2] = useState(false);

  const leftSection = props.itinenary && props.itinenary[0];
  const leftSectionObject = leftSection && Object.values(leftSection)[0];

  const rightSection = props.itinenary && props.itinenary[1];
  const rightSectionObject = rightSection && Object.values(rightSection)[0];

  const cabinType1 = leftSectionObject && leftSectionObject.cabin;
  const flights1 = leftSectionObject && leftSectionObject.flights;
  const classOfService1 = leftSectionObject && leftSectionObject.classOfService

  const cabinType2 = rightSectionObject && rightSectionObject.cabin;
  const flights2 = rightSectionObject && rightSectionObject.flights;
 const classOfService2 = rightSectionObject && rightSectionObject.classOfService

  const detailsTabLeft = leftSection && Object.values(leftSection);
  const detailsTabRight = rightSection && Object.values(rightSection);

  const leftSectionLayovers = leftSectionObject && leftSectionObject?.layovers;
  const leftSectionFlightDuration = leftSectionObject && leftSectionObject.durationMinutes;

  const rightSectionFlightDuration = rightSectionObject && rightSectionObject.durationMinutes;
  const rightSectionLayovers = rightSectionObject && rightSectionObject?.layovers;

  let pnrStatus = bookingDetails && bookingDetails.bookingStatusDetail && bookingDetails.bookingStatusDetail.code

  var flightIds = [];
  var flightNamesLeft = [];
  var flightNamesRight = [];
  const baggageDataLeft = [];
  const baggageDataRight = [];

  let date = bookingHeader && bookingHeader.ticketingTimeLimit;
  let baggageDepart = leftSectionObject && leftSectionObject.baggage;
  let baggageDepartInWeight = leftSectionObject && leftSectionObject.weight;
  let baggageArrive = rightSectionObject && rightSectionObject.baggage;
  let baggageArrivInWeight = rightSectionObject && rightSectionObject.weight;
  let seatsDepart = leftSectionObject && leftSectionObject.seatsRemaining;
  let seatsArrive = rightSectionObject && rightSectionObject.seatsRemaining;
  const fareBasisDetails =  paxFares && paxFares[0] && paxFares[0].fareBasisDetails
  if (pnrFlag) {
    adultsCount = pnrAdultsCount
    childCount = pnrChildCount
    infantCount = pnrInfantCount
  } else {
    adultsCount = searchAdultCOunt
    childCount = searchChildCount
    infantCount = searchInfantCount
  }

console.log("paxFares",fareBasisDetails)
  const handleFlightDToggle1 = (i) => {
    setHideFlight1(!HideFlight1);

    if (flightDIndex == i) {
      setFlightDIndex(null);
    }
    setFlightDIndex(i);
    if(props.fareId) {
      dispatch(getPenaltiesData(props.fareId));
    }
    else if(props.recordLocator) {
      dispatch(getPenaltiesDataForPNR(props.recordLocator))
    }
  };

  const handleFlightDToggle2 = (i) => {
    setHideFlight2(!HideFlight2);

    if (flightDIndex == i) {
      setFlightDIndex(null);
    }
    setFlightDIndex(i);

    if(props.fareId) {
      dispatch(getPenaltiesData(props.fareId));
    }
    else if(props.recordLocator) {
      dispatch(getPenaltiesDataForPNR(props.recordLocator))
    }
  };

  const handleSeatMap = (i) => {
    setHideSeatMap("flight_tabs_show  flightModal bg-white");

    if (seatMapIndex == i) {
      return setSeatMapIndex(null);
    }
    setSeatMapIndex(i);

  };

  const handleFareOptionToggle = (i) => {
    if (fareOption == i) {
      setFareOption(null);
    } else {
      setFareOption(i);
    }

    dispatch(getFareOptions(props.fareId));
    setFareProps(props.fareId);
  };

  let layoverTime = 0;
  let layoverTime1 = 0;
  return (
    <>
      {(props.fromBookingConfirmation || props.from === BookingFrom.CONFIRMATION)? <div className="bd-itinerary-box">
        {/* {!props.isCalcultor && <BookingItinerayInfo bookingHeader={bookingHeader} from={props.from}/> } */}
        <>
        {props.isCalcultor && (!props.itinenary || (props.itinenary && props.itinenary.length === 0) || (flyingFromCity === undefined)) ?               
        <div className="bd-itinerary-sec bd-confirmation-sec">
                      <div className="quotenote mb-3">
                        <div className="col-lg-12 quotenotediv reprice">
                          <div>
                            <span>
                              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            </span>{" "}
                          </div>
                          <div>
                            <p>
                              Unable to display the details. No flight segment data and PQ information is available in the PNR
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
        <div className="bd-itinery-detail">
          <div className="col-12 bd-itinery-box-wrapper">
            <div className="bd-itinery-box-head">
              <div className="bd-ib-head">
                <strong>Outbound</strong>
                {flyingFromCity} ({flyingFrom}) - {flyingToCity} ({flyingTo})
                | {cabin && splitFromCapital(cabin)}
              </div>
              <div className="bd-ib-total">

                {leftSectionLayovers && leftSectionLayovers.map(value => {
                  layoverTime += value.durationMinutes

                })}
                {leftSectionFlightDuration !== undefined && <> Total Duration :{timeConvert(leftSectionFlightDuration)}  </>}

              </div>
            </div>

            <div className="bd-itinery-box">
              {flights1 && flights1.map((flight, index) => {

                let arrivalDate = flight.arrivalDateTime
                let dapartureDate = flight.departureDateTime
                let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);
                
                flightIds.push(flight.id);
                baggageDataLeft.push(flight.baggageDetails);
                var name =
                  flight.departureAirport + "-" + flight.arrivalAirport;
                flightNamesLeft.push(name);
                let carrierCode = flight && flight.carrier
                let flightLogo = getFlightLogo(carrierCode)
                layoverTime = layoverTime + 1;
                let flightClassOfservice = fareBasisDetails && fareBasisDetails.filter(obj=>obj.flightId===flight.id)
               let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
               
                return (
                  <div key={flight.id}>
                    <div className="bd-itinery-item">
                      {flight && flight.statusDetail && flight.statusDetail.code === "UN" ?
                      <div className="title">
                        <img src={flightLogo} alt="flightLogo" />
                        <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
          
                                      <span className="airPort_name">{flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier}</span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number} {classOfService}
                         <span className="cancelled_flight"> - Cancelled</span>
                       </div>
                      :
                       <div className="title">
                         <img src={flightLogo} alt="flightLogo" />
                         <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
          
                                      <span className="airPort_name">{flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier} </span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number} {classOfService}
                       </div>
                    
                       }
                     
                      <div className="flight-from-to">
                        <div className="flight-from-to-city">
                          <div className="city">
                            <strong>{flight.departureAirport}</strong>{" "}
                            {moment.utc(flight.departureDateTime).format("HH:mm")}
                          </div>
                          <span className="date">
                            {moment.utc(flight.departureDateTime).format(
                              "ddd MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                        <div className="flight-from-to-divider"></div>
                        <div className="flight-from-to-city">
                          <div className="city">
                            <strong>{flight.arrivalAirport}</strong>
                            {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                            {/* {moment.utc(flight.arrivalDateTime).format("HH:mm")} */}
                          </div>
                          <span className="date">
                            {moment.utc(flight.arrivalDateTime).format(
                              "ddd MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                      {(pnrStatus === BookingStatusCode.NTC || pnrStatus === BookingStatusCode.TC) ? 
                        <div className="time-from-to">
                           <span className="tktonholdFailed"> cancelled</span>
                        </div>
                        :
                        <div className="time-from-to">
                        {timeConvert(flight.durationMin)}
                        </div>
                       }
                     
                    </div>
                    {leftSectionLayovers && leftSectionLayovers[index] ? (
                      <div className="bd-itinery-layover">
                        <span>
                          {timeConvert(
                            leftSectionLayovers[index].durationMinutes
                          ) + " Layover in " + flight.arrivalAirport}
                        </span>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              <div className="bd-itinery-btns">
                <div className="seatBag">
                  {seatsDepart && <span>
                    <img src={seats} alt="seat" />
                    {seatsDepart}
                  </span>}



                  {(baggageDepartInWeight || baggageDepart) &&
                    <span>
                      <img src={bags} alt="seat" />
                      {baggageDepartInWeight > baggageDepart ? " " + baggageDepartInWeight + " Kg" : baggageDepart}

                    </span>
                  }
                </div>
                {pnrStatus !== BookingStatusCode.NTC && pnrStatus !== BookingStatusCode.TC &&
                <div>
                  <button
                    onClick={() => {
                      handleFlightDToggle1(1);
                    }}
                  >
                    Flight Details
                  </button>
                </div>
                }
              </div>

              <div className={HideFlight1 ? "" : "flight_tabs_none"}>
                <DetailsTab
                  flightdata={detailsTabLeft}
                  baggagefares={baggageDataLeft}
                  flightName={flightNamesLeft}
                  fareId={props.fareId}
                  paxFare={paxFares}
                  from="left"
                  recordLocator={props.recordLocator}
                />
              </div>
            </div>
          </div>
          {flights2 && flights2.length > 0 && <div className="col-12 bd-itinery-box-wrapper">
            <div className="bd-itinery-box-head">

              <div className="bd-ib-head">
                <strong>Inbound</strong>
                {flyingToCity} ({flyingTo}) - {flyingFromCity} ({flyingFrom})
                | {cabin && splitFromCapital(cabin)}
              </div>


              <div className="bd-ib-total">

                {rightSectionLayovers && rightSectionLayovers.map(value => {
                  layoverTime1 += value.durationMinutes
                })}
                 {rightSectionFlightDuration !== undefined && <> Total Duration :{timeConvert(rightSectionFlightDuration)}  </>}
                
              </div>
            </div>
            <div className="bd-itinery-box">
              {flights2 && flights2.map((flight, index) => {

                let arrivalDate = flight.arrivalDateTime
                let dapartureDate = flight.departureDateTime
                let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);
                flightIds.push(flight.id);
                baggageDataRight.push(flight.baggageDetails);

                var name =
                  flight.departureAirport + "-" + flight.arrivalAirport;
                flightNamesRight.push(name);
                let carrierCode = flight && flight.carrier
                let flightLogo = getFlightLogo(carrierCode)
                layoverTime1 = layoverTime1 + 1;
                let flightClassOfservice = fareBasisDetails && fareBasisDetails.filter(obj=>obj.flightId===flight.id)
                let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
                
                return (
                  <div key={flight.id}>
                    <div className="bd-itinery-item">
                    {flight && flight.statusDetail && flight.statusDetail.code === "UN" ?
                      <div className="title">
                        <img src={flightLogo} alt="flightLogo" />
                         <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
          
                                      <span className="airPort_name">{flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier}</span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number} {classOfService}
                         <span className="cancelled_flight"> - Cancelled</span>
                       </div>
                      :
                       <div className="title">
                         <img src={flightLogo} alt="flightLogo" />
                         <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">         
                                      <span className="airPort_name">{flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier}</span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number} {classOfService}
                       </div>
                    
                       }

                      <div className="flight-from-to">
                        <div className="flight-from-to-city">
                          <div className="city">
                            <strong>{flight.departureAirport}</strong>{" "}
                            {moment.utc(flight.departureDateTime).format("HH:mm")}
                          </div>
                          <span className="date">
                            {moment.utc(flight.departureDateTime).format(
                              "ddd MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                        <div className="flight-from-to-divider"></div>
                        <div className="flight-from-to-city">
                          <div className="city">
                            <strong>{flight.arrivalAirport}</strong>{" "}
                            {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                            {/* {moment.utc(flight.arrivalDateTime).format("HH:mm")} */}
                          </div>
                          <span className="date">
                            {moment.utc(flight.arrivalDateTime).format(
                              "ddd MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                      </div>

                      {(pnrStatus === BookingStatusCode.NTC || pnrStatus === BookingStatusCode.TC) ? 
                        <div className="time-from-to">
                           <span className="tktonholdFailed"> cancelled</span>
                        </div>
                        :
                        <div className="time-from-to">
                        {timeConvert(flight.durationMin)}
                        </div>
                       }
                    </div>

                    {rightSectionLayovers && rightSectionLayovers[index] ? (
                      <div className="bd-itinery-layover">
                        <span>

                          {timeConvert(
                            rightSectionLayovers[index].durationMinutes
                          ) + " Layover in " + flight.arrivalAirport}
                        </span>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              <div className="bd-itinery-btns">
                <div className="seatBag">
                  {seatsArrive && <span>
                    <img src={seats} alt="seat" />
                    {seatsArrive}
                  </span>}


                  {(baggageArrivInWeight || baggageArrive) &&
                    <span>
                      <img src={bags} alt="seat" />
                      {baggageArrivInWeight > baggageArrive ? " " + baggageArrivInWeight + " Kg" : baggageArrive}
                    </span>
                  }
                </div>
                {pnrStatus !== BookingStatusCode.NTC && pnrStatus !== BookingStatusCode.TC &&
                <div>
                  <button
                    data-toggle="collapse"
                    data-target="#flightdetails2"
                    aria-expanded="false"
                    aria-controls="flightdetails2"
                    onClick={() => {
                      handleFlightDToggle2(2);
                    }}
                  >
                    Flight Details
                  </button>
                </div>
                }
              </div>
              <div className={HideFlight2 ? "" : "flight_tabs_none"}>
                <DetailsTab
                  flightdata={detailsTabRight}
                  baggagefares={baggageDataRight}
                  flightName={flightNamesRight}
                  fareId={props.fareId}
                  paxFare={paxFares}
                  from="right"
                  recordLocator={props.recordLocator}
                />
              </div>
            </div>
          </div>}

        </div>
      }
      </>
      </div> :
        <div className="bd-itinerary-sec">
          
          <BookingSubHead
            title={BookingHead.ITINENARY}
            imgSrc={ItineraryIcon}
          />
          <div className="one_line">
          <ul className="bd-trip-info">
            <li>
              {tripTypeValue === TripType.ROUNDTRIP ? "Round Trip" : "Open Jaw"}
            </li>
            <li>{cabin && splitFromCapital(cabin)} Class</li>
            <li>
              {adultsCount} Adult {childCount} Child {infantCount} Infant
            </li>
           
          </ul>
          <p> {brandCode.brandName}</p>
          </div>
         
          <div className="bd-itinerary-box">
            {/* <BookingItinerayInfo bookingHeader={bookingHeader} /> */}
            <div className="bd-itinery-detail">
              <div className="col-12 bd-itinery-box-wrapper">
                <div className="bd-itinery-box-head">
                  <div className="bd-ib-head">
                    <strong>Outbound</strong>
                    {flyingFromCity} ({flyingFrom}) - {flyingToCity} ({flyingTo})
                    | {cabin && splitFromCapital(cabin)}
                  </div>
                  <div className="bd-ib-total">

                    {leftSectionLayovers && leftSectionLayovers.map(value => {
                      layoverTime += value.durationMinutes

                    })}
                    Total Duration :{timeConvert(leftSectionFlightDuration)}
                  </div>
                </div>

                <div className="bd-itinery-box">
                  {flights1 && flights1.map((flight, index) => {

                    let arrivalDate = flight.arrivalDateTime
                    let dapartureDate = flight.departureDateTime
                    let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);

                    flightIds.push(flight.id);
                    baggageDataLeft.push(flight.baggageDetails);
                    var name =
                      flight.departureAirport + "-" + flight.arrivalAirport;
                    flightNamesLeft.push(name);
                    let carrierCode = flight && flight.carrier
                    let flightLogo = getFlightLogo(carrierCode)
                    layoverTime = layoverTime + 1;
                    let flightClassOfservice = classOfService1 && classOfService1.filter(obj=>obj.flightId===flight.id)
                    let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
                    
                    return (
                      <div key={flight.id}>
                        <div className="bd-itinery-item">
                          <div className="title">
                            <img src={flightLogo} alt="flightLogo" />
                            <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
                                      <span className="airPort_name">{flight.carrierName !== undefined ? flight.carrierName : flight.airlineName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier + " "} </span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number} {classOfService}
                          </div>
                          <div className="flight-from-to">
                            <div className="flight-from-to-city">
                              <div className="city">
                                <strong>{flight.departureAirport}</strong>{" "}
                                {moment.utc(flight.departureDateTime).format("HH:mm")}
                              </div>
                              <span className="date">
                                {moment.utc(flight.departureDateTime).format(
                                  "ddd MMM DD, YYYY"
                                )}
                              </span>
                            </div>
                            <div className="flight-from-to-divider"></div>
                            <div className="flight-from-to-city">
                              <div className="city">
                                <strong>{flight.arrivalAirport}</strong>
                                {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                                {/* {moment.utc(flight.arrivalDateTime).format("HH:mm")} */}
                              </div>
                              <span className="date">
                                {moment.utc(flight.arrivalDateTime).format(
                                  "ddd MMM DD, YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="time-from-to">
                            {timeConvert(flight.durationMin)}
                          </div>
                        </div>
                        {leftSectionLayovers && leftSectionLayovers[index] ? (
                          <div className="bd-itinery-layover">
                            <span>

                              {timeConvert(
                                leftSectionLayovers[index].durationMinutes
                              ) + " Layover in " + flight.arrivalAirport}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}

                  <div className="bd-itinery-btns">
                    <div className="seatBag">
                      {seatsDepart && <span>
                        <img src={seats} alt="seat" />
                        {seatsDepart}
                      </span>}
                      <span>
                        <img src={bags} alt="seat" />
                        {baggageDepartInWeight > baggageDepart ? " " + baggageDepartInWeight + " Kg" : baggageDepart}
                      </span>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          handleFlightDToggle1(1);
                        }}
                      >
                        Flight Details
                      </button>
                    </div>
                  </div>

                  <div className={HideFlight1 ? "" : "flight_tabs_none"}>
                    <DetailsTab
                      flightdata={detailsTabLeft}
                      baggagefares={baggageDataLeft}
                      flightName={flightNamesLeft}
                      fareId={props.fareId}
                      paxFare={props.from === "confirmation" ? paxFares : paxFares}
                      from="left"
                      recordLocator={props.recordLocator}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 bd-itinery-box-wrapper">
                <div className="bd-itinery-box-head">
                  {tripTypeValue === TripType.ROUNDTRIP ?
                    <div className="bd-ib-head">
                      <strong>Inbound</strong>
                      {flyingToCity} ({flyingTo}) - {flyingFromCity} ({flyingFrom})
                      | {cabin && splitFromCapital(cabin)}
                    </div>
                    :
                    <div>
                      <strong>Inbound</strong>
                      {openJawFlyingFromCity_B} ({openJawFlyingFrom_B}) - {openJawFlyingToCity_B} ({openJawFlyingTo_B})
                      | {cabin && splitFromCapital(cabin)}
                    </div>
                  }
                  <div className="bd-ib-total">

                    {rightSectionLayovers && rightSectionLayovers.map(value => {
                      layoverTime1 += value.durationMinutes
                    })}
                    Total Duration :{timeConvert(rightSectionFlightDuration)}
                  </div>
                </div>
                <div className="bd-itinery-box">
                  {flights2 && flights2.map((flight, index) => {

                    let arrivalDate = flight.arrivalDateTime
                    let dapartureDate = flight.departureDateTime
                    let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);

                    flightIds.push(flight.id);
                    baggageDataRight.push(flight.baggageDetails);

                    var name =
                      flight.departureAirport + "-" + flight.arrivalAirport;
                    flightNamesRight.push(name);
                    let carrierCode = flight && flight.carrier
                    let flightLogo = getFlightLogo(carrierCode)
                    layoverTime1 = layoverTime1 + 1;
                    let flightClassOfservice = classOfService2 && classOfService2.filter(obj=>obj.flightId===flight.id)
                    let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService

                    return (
                      <div key={flight.id}>
                        <div className="bd-itinery-item">
                          <div className="title">
                            <img src={flightLogo} alt="flightLogo" />
                            <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
          
                                      <span className="airPort_name">{flight.carrierName !== undefined ? flight.carrierName : flight.airlineName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier}</span>                                                           
                          </OverlayTrigger>                                                             
                         {flight.number} {classOfService}
                          </div>

                          <div className="flight-from-to">
                            <div className="flight-from-to-city">
                              <div className="city">
                                <strong>{flight.departureAirport}</strong>{" "}
                                {moment.utc(flight.departureDateTime).format("HH:mm")}
                              </div>
                              <span className="date">
                                {moment.utc(flight.departureDateTime).format(
                                  "ddd MMM DD, YYYY"
                                )}
                              </span>
                            </div>
                            <div className="flight-from-to-divider"></div>
                            <div className="flight-from-to-city">
                              <div className="city">
                                <strong>{flight.arrivalAirport}</strong>{" "}
                                {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                                {/* {moment.utc(flight.arrivalDateTime).format("HH:mm")} */}
                              </div>
                              <span className="date">
                                {moment.utc(flight.arrivalDateTime).format(
                                  "ddd MMM DD, YYYY"
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="time-from-to">
                            {timeConvert(flight.durationMin)}
                          </div>
                        </div>

                        {rightSectionLayovers && rightSectionLayovers[index] ? (
                          <div className="bd-itinery-layover">
                            <span>

                              {timeConvert(
                                rightSectionLayovers[index].durationMinutes
                              ) + " Layover in " + flight.arrivalAirport}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}

                  <div className="bd-itinery-btns">
                    <div className="seatBag">
                      {seatsArrive && <span>
                        <img src={seats} alt="seat" />
                        {seatsArrive}
                      </span>}


                      <span>
                        <img src={bags} alt="seat" />
                        {baggageArrivInWeight > baggageArrive ? " " + baggageArrivInWeight + " Kg" : baggageArrive}
                      </span>
                    </div>
                    <div>
                      <button
                        data-toggle="collapse"
                        data-target="#flightdetails2"
                        aria-expanded="false"
                        aria-controls="flightdetails2"
                        onClick={() => {
                          handleFlightDToggle2(2);
                        }}
                      >
                        Flight Details
                      </button>
                    </div>
                  </div>
                  <div className={HideFlight2 ? "" : "flight_tabs_none"}>
                    <DetailsTab
                      flightdata={detailsTabRight}
                      baggagefares={baggageDataRight}
                      flightName={flightNamesRight}
                      fareId={props.fareId}
                      paxFare={props.from === "confirmation" ? paxFares : paxFares}
                      from="right"
                      recordLocator={props.recordLocator}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default BookingItineryDetails;
