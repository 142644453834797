import axios from 'axios';
import swal from 'sweetalert';

 export interface fetchAllSalesPromotionPayloadInterface {
    agencyInclusion?:String,
    showAll?: Boolean, 
    fees?: Boolean,
    consortiumInclusion?: String,
    agencyLocationInclusion?: String,
    tripType?: String,
    bookingChannel?: String,
    supplier?: String,
    origin?: String,
    destination?: String,
    agentExclusion?: string
}

export const postFetchAllSalesPromotion = async (payload:fetchAllSalesPromotionPayloadInterface, setterFunction:any) => {
        let url: any = `${process.env.REACT_APP_BASE_URL}/salesPromotion/fetchAllSalesPromotion`;
        const requestOptions: any = {
            headers: {
                "Content-type": "application/json",
            },
        }
        let postFetchAllSalesPromotion:any;
        try{
             postFetchAllSalesPromotion = await axios.post(url, payload, requestOptions)
             if (postFetchAllSalesPromotion?.error) {
                swal({
                    text:"Fetching Failed!",
                    icon: "error",
                  })
            }
            return postFetchAllSalesPromotion?.data
        }catch (error) {
            console.error(error);
            setterFunction(false)
            swal({
                text: "Failed",
                icon: "error"
            });
            return []
        }    
}