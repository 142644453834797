import React, { forwardRef } from "react";
import { FormControlLabel, Radio as RadioField} from '@material-ui/core';


export const Radio = forwardRef(({color="primary", checked=false, onChange=()=>{}, label="Radio Button", disabled=false}:any, ref) => {
    return(
        <>
            <FormControlLabel control={<RadioField color={color} checked={checked} onChange={onChange} disabled={disabled}/>} label={label} {...ref}/>
        </>
    )
})