import { SET_FARE_MATRIX,SET_LOWEST_FARE_MATRIX} from '../storeConstant';

const initialState = {
   fareMatrix:undefined,
   lowestFareMatrix:[]
   
};

const FareMatrixReducer = (state=initialState , action) => {
    switch(action.type) {
        case SET_FARE_MATRIX:
            return {
                ...state,
                fareMatrix: action.payload
            }

            case SET_LOWEST_FARE_MATRIX:
                return {
                    ...state,
                lowestFareMatrix: action.payload
                }
    
        default:
            return state;
    }
}

export default FareMatrixReducer;