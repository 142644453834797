import {
  SET_TRIP_TYPE,
  SET_FLEXI_DATE,
  SET_FLYING_FROM,
  SET_FLYING_TO,
  SET_DEPART_DATE,
  SET_DEPART_TIME,
  SET_RETURN_DATE,
  SET_RETURN_TIME,
  SET_PASSENGER_ADULT,
  SET_PASSENGER_CHILDREN,
  SET_PASSENGER_INFANT,
  SET_CABIN,
  SET_AIR_CONTRACT,
  SET_QUALIFIERS,
  SET_AIRLINES,
  SET_MAX_CONNECTIONS,
  SET_CHANGES_ALLOWED,
  SET_ALLIANCES,
  SET_NEARBY_AIRPORT,
  SET_ATLEAST_FREE_BAGGAGE,
  SET_AUTOCOMPLETE_RETURN_FROM,
  SET_AUTOCOMPLETE_RETURN_TO,
  SET_AUTOCOMPLETE_FLY_FROM_B,
  SET_AUTOCOMPLETE_FLY_TO_B,
  SET_AUTOCOMPLETE_FLY_FROM_0, SET_AUTOCOMPLETE_FLY_FROM_1, SET_AUTOCOMPLETE_FLY_FROM_2, SET_AUTOCOMPLETE_FLY_FROM_3, SET_AUTOCOMPLETE_FLY_FROM_4,
  SET_AUTOCOMPLETE_FLY_TO_0, SET_AUTOCOMPLETE_FLY_TO_1, SET_AUTOCOMPLETE_FLY_TO_2, SET_AUTOCOMPLETE_FLY_TO_3, SET_AUTOCOMPLETE_FLY_TO_4,
  SET_AUTOCOMPLETE_QUOTE_FROM, SET_AUTOCOMPLETE_QUOTE_TO,
  SET_UUID,
  SET_CARRIER_OPTIONS,
  SET_INPUT_FIELD_COUNT,
  SET_OPEN_JAW_DATE_B,
  SET_OPEN_JAW_FLYING_FROM_B,
  SET_OPEN_JAW_FLYING_TO_B,
  SET_MULTICITY_FLYING_FROM_4,
  SET_MULTICITY_FLYING_TO_4,
  SET_MULTICITY_FLYING_FROMM,
  SET_MULTICITY_FLYING_TOM,
  SET_MULTICITY_FLYING_FROM_0,
  SET_MULTICITY_FLYING_TO_0,
  SET_MULTICITY_FLYING_FROM_1,
  SET_MULTICITY_FLYING_TO_1,
  SET_MULTICITY_FLYING_FROM_2,
  SET_MULTICITY_FLYING_TO_2,
  SET_MULTICITY_DATE_0,
  SET_MULTICITY_DATE_1,
  SET_MULTICITY_DATE_2,
  MULTICITY_REMOVEINPUT,
  MULTICITY_ADDINPUT,
  SET_MULTICITY_FLYING_FROM_3,
  SET_MULTICITY_FLYING_TO_3,
  SET_MULTICITY_DATE_3,
  SET_MULTICITY_DATE_4,
  SET_REQUEST_OPTIONS,
  SET_ITINENARY_INDEX,
  SET_BOOKING_ITINENARY,
  SET_BOOKING_HEADER,
  SET_BOOKING_FAREID,
  SET_FLYING_FROM_CITY,
  SET_FLYING_TO_CITY,
  SET_FLYING_FROM_VALIDATION,
  SET_FLYING_TO_VALIDATION,
  SET_DEPARTURE_DATE_VALIDATION,
  SET_RETURN_DATE_VALIDATION,
  SET_IS_ERROR_INCREMENT,
  SET_IS_ERROR_DECREMENT,
  SET_IS_ERROR_ROUNDTRIP,
  SET_IS_ERROR_ONE_WAY,
  SET_IS_ERROR_OPEN_JAW,
  SET_IS_ERROR_MULTI_CITY,
  SET_ADULT_MARKUP_VALUE,
  SET_CHILD_MARKUP_VALUE,
  SET_OPEN_JAW_FLYING_FROM_CITY_B,
  SET_OPEN_JAW_FLYING_TO_CITY_B,
  SET_MULTI_CITY_FLYING_FROM_CITY_0,
  SET_MULTI_CITY_FLYING_FROM_CITYM,
  SET_MULTI_CITY_FLYING_TO_CITYM,
  SET_MULTI_CITY_FLYING_TO_CITY_0,
  SET_MULTI_CITY_FLYING_FROM_CITY_1,
  SET_MULTI_CITY_FLYING_TO_CITY_1,
  SET_MULTI_CITY_FLYING_FROM_CITY_2,
  SET_MULTI_CITY_FLYING_TO_CITY_2,
  SET_MULTI_CITY_FLYING_TO_CITY_3,
  SET_MULTI_CITY_FLYING_FROM_CITY_3,
  SET_MULTI_CITY_FLYING_FROM_CITY_4,
  SET_MULTI_CITY_FLYING_TO_CITY_4,
  SET_MODIFY_SEARCH_VALUE,
  SET_SEGMENT_ARRAY_LENGTH,
  SET_PROVIDER_ID,
  SET_AUTOCOMPLETE_AIRLINES,
  SET_TIME_CALCULATOR,
  SET_PROMOTION_DATA,
  SET_GROUP_ITINENARY_TIME_CALCULATOR,
  SET_API_RENDER_FLAG,
  SET_INFANT_MARKUP_VALUE,
  SET_TRIP_TYPE_FROM_API,
  SET_DEPART_FROM_CITY,
  SET_DEPART_FROM_CODE,
  SET_ARRIVE_FROM_CITY,
  SET_ARRIVE_FROM_CODE,
  SET_FLYING_FROM_MODIFY_SEARCH,
  SET_FLYING_TO_MODIFY_SEARCH,
  SET_DEPART_DATE_MODIFY_SEARCH,
  SET_RETURN_DATE_MODIFY_SEARCH,
  SET_FLYING_FROM_MODIFY_SEARCH_CODE,
  SET_FLYING_TO_MODIFY_SEARCH_CODE,
  SET_SHOW_AGENCY_INFO,
  SET_ALLIANCE_OPTION,
  SET_FLYING_FROM_COUNTRY,
  SET_OPEN_JAW_FLYING_FROM_COUNTRY_B,
  SET_MULTYCITY_FLYING_FROM_COUNTRY_0,
  SET_MULTYCITY_FLYING_FROM_COUNTRY_1,
  SET_MULTYCITY_FLYING_FROM_COUNTRY_2,
  SET_MULTYCITY_FLYING_FROM_COUNTRY_3,
  SET_MULTYCITY_FLYING_FROM_COUNTRY_4,
  SET_PNR_REMARK_CITY_FRAUD_CHECK,    
  SET_SPECIFIC_AIRLINES_BLANK,
  SET_AGENCY_AGENT_FIELD_FLAG,
  SET_PAX_CONTRACT,
  SET_PAYMENT_DETAILS_ADDONS,
  SET_PAX_FARE_DETAILS,
  SET_CACHE_EXPIRED_FLAG,
  SET_CACHE_EXPIRED_DIALOG_FLAG,
  SET_AGENCY_LIST,
  SET_AGENT_LIST,
  SET_AGENT_FIELD,
  SET_AGENCY_FIELD,
  SET_FLYING_FROM_TEXT_RETURN, 
  SET_FLYING_FROM_TEXT_OJ, 
  SET_FLYING_FROM_TEXT_MULTIM,
  SET_FLYING_FROM_TEXT_MULTI0, 
  SET_FLYING_FROM_TEXT_MULTI1, 
  SET_FLYING_FROM_TEXT_MULTI2, 
  SET_FLYING_FROM_TEXT_MULTI3, 
  SET_FLYING_FROM_TEXT_MULTI4,
  SET_FLYING_TO_TEXT_RETURN,
  SET_FLYING_TO_TEXT_OJ, 
  SET_FLYING_TO_TEXT_MULTI0, 
  SET_FLYING_TO_TEXT_MULTI1, 
  SET_FLYING_TO_TEXT_MULTI2, 
  SET_FLYING_TO_TEXT_MULTI3, 
  SET_FLYING_TO_TEXT_MULTI4,
  SET_MULTICITY_TIME0,
  SET_MULTICITY_TIME1,
  SET_MULTICITY_TIME2,
  SET_MULTICITY_TIME3,
  SET_MULTICITY_TIME4,
  SET_OPENJAW_TIMEB,
  SET_CITY_FRAUD_CHECK,
  SET_CITY_FRAUD_CHECK1,  
  SET_AUTOCOMPLETE_FLY_FROMM,
  SET_AUTOCOMPLETE_FLY_TOM,
  SET_MULTYCITY_FLYING_FROM_COUNTRYM,
  SET_MULTICITY_DATEM,
  SET_MULTICITY_TIMEM,
  SET_CLEAR_FORM,
  SET_DFAULT_BRAND_DETAILS,
  SET_INITIAL_SEARCH_MULTICITY,
  SET_AUTOCOMPLETE_COUNTRY,
  SET_IS_ERROR_INFANT,
  SET_FARE_RULES_ERROR,
  SET_FLIGHT_TYPE,
  SET_URL_USD,
  SET_FLIGHT_TYPE_CHECK
} from "../storeConstant";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  isClearForm: false,
  isCityFraud: false,
  isCityFraud1: false,
  tripType: "",
  flexibleDate: false,
  flyingFrom: "",
  flyingTo: "",
  flyingFromCity: "",
  flyingFromCountry: "",
  openJawFlyingFromCountry_B: "",
  multiCityFlyingFromCountry_0: "",
  multiCityFlyingFromCountryM: "",
  multiCityFlyingFromCountry_1: "",
  multiCityFlyingFromCountry_2: "",
  multiCityFlyingFromCountry_3: "",
  multiCityFlyingFromCountry_4: "",
  flyingToCity: "",
  departDate: "",
  departTime: "",
  returnDate: "",
  returnTime: "",
  passengerAdult: 1,
  passengerChildren: 0,
  passengerInfant: 0,
  cabin: "Economy",
  airContract: ["Air"],
  qualifiers: [],
  airlines: [],
  maxConnections: 2,
  changesAllowd: "",
  alliance: "",
  nearbyAirport: false,
  atLeastFreeBaggage: false,
  autoCompleteReturnFrom: [],
  autoCompleteReturnTo: [],
  autoCompleteFlyFrom_B: [],
  autoCompleteFlyTo_B: [],
  autoCompleteFlyFromM: [],
  autoCompleteFlyToM: [],
  autoCompleteFlyTo_0: [],
  autoCompleteFlyTo_1: [],
  autoCompleteFlyTo_2: [],
  autoCompleteFlyTo_3: [],
  autoCompleteFlyTo_4: [],
  autoCompleteFlyFrom_0: [],
  autoCompleteFlyFrom_1: [],
  autoCompleteFlyFrom_2: [],
  autoCompleteFlyFrom_3: [],
  autoCompleteFlyFrom_4: [],
  autoCompleteQuoteFrom: [],
  autoCompleteQuoteTo: [],
  uuid: "",
  carrieroptions: [],
  multiCityFlightCnt: 0,
  showAgencyInfo: false,
  openJawDate_B: "",
  openJawTime_B: "",
  openJawFlyingFrom_B: "",
  openJawFlyingTo_B: "",
  multiCityFlyingFromM: "",
  multiCityFlyingFrom_4: "",
  multiCityFlyingFrom_0: "",
  multiCityFlyingFrom_1: "",
  multiCityFlyingFrom_2: "",
  multiCityFlyingFrom_3: "",
  multiCityFlyingToM: "",
  multiCityFlyingTo_4: "",
  multiCityFlyingTo_0: "",
  multiCityFlyingTo_1: "",
  multiCityFlyingTo_2: "",
  multiCityFlyingTo_3: "",
  multiCityDate_4: "",
  multiCityDate_0: "",
  multiCityDate_1: "",
  multiCityDate_2: "",
  multiCityDate_3: "",
  multiCityDateM: "",
  multiCityTimeM: "",
  multiCityTime_0: "",
  multiCityTime_1: "",
  multiCityTime_2: "",
  multiCityTime_3: "",
  multiCityTime_4: "",
  inputField: [" "],
  requestOptions: {},
  itinenaryIndex: "",
  bookingItinenary: "",
  bookingHeader: "",
  bookingFareId: "",
  flyingFromValidation: "",
  flyingToValidation: "",
  departureDateValidation: "",
  returnDateValidation: "",
  isErrorCnt: 0,
  isErrorRoundTrip: false,
  isErrorOneWay: false,
  isErrorOpenJaw: false,
  isErrorMultiCity: false,
  adultMarkupValue: 0,
  childMarkupValue: 0,
  infantMarkupValue: 0,
  openJawFlyingFromCity_B: "",
  openJawFlyingToCity_B: "",
  multiCityFlyingFromCity_0: "",
  multiCityFlyingToCity_0: "",
  multiCityFlyingFromCityM: "",
  multiCityFlyingToCityM: "",
  multiCityFlyingFromCity_1: "",
  multiCityFlyingToCity_1: "",
  multiCityFlyingFromCity_2: "",
  multiCityFlyingToCity_2: "",
  multiCityFlyingFromCity_3: "",
  multiCityFlyingToCity_3: "",
  multiCityFlyingFromCity_4: "",
  multiCityFlyingToCity_4: "",
  modifySearchValue: false,
  segmentArrayLength: "",
  providerId: "",
  autoCompleteAirlines: [],
  timeCalculator: 0,
  promotionData: "",
  grpItinenaryTimeCalculator: 0,
  apiRenderFlag: true,
  tripTypeFromApi: "",
  departFromCity:"",
  departFromCode:"",
  arriveFromCity:"",
  arriveFromCode:"",
  flyingFromModifySearch:"",
  flyingToModifySearch:"",
  departDateModifySearch:"",
  returnDateModifySearch:"",
  flyingFromModifySearchCode:"",
  flyingToModifySearchCode:"",
  allianceOption:"",
  addPNRRemarkCityFraudCheck: false,
  isSpecificAirlineBlank: false,
  agencyAgentFlag:false,
  paxContract:[],
  paymentDetailsAddons:[],
  paxFareDetails:"",
  cacheExpiredFlag: false,
  cacheExpireDialogFlag: false,
  agencyList: [],
  agentList: [],
  agencyField: "",
  agentField: "",
  flyingFromReturnText: "",
  flyingFromOJText: "",
  flyingFromMulti0Text: "",
  flyingFromMulti1Text: "",
  flyingFromMulti2Text: "",
  flyingFromMulti3Text: "",
  flyingFromMulti4Text: "",
  flyingToReturnText: "",
  flyingToOJText: "",
  flyingToMulti0Text: "",
  flyingToMulti1Text: "",
  flyingToMulti2Text: "",
  flyingToMulti3Text: "",
  flyingToMulti4Text: "",
  defaultBrandData:{},
  initialSearchForMulticity: 0,
  autoCompleteCountry:[],
  IsInfantError: false,
  fareRulesError:false,
  flightType:"CAD",
  flightUrl:'/?user=internal',
  flightTypeCheck:false
};

const searchFormReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SET_INITIAL_SEARCH_MULTICITY:
      return {
        ...state,
        initialSearchForMulticity: action.payload
      }
    case SET_CLEAR_FORM:
      return {
        ...state,
        isClearForm: action.payload
      }
    case SET_CITY_FRAUD_CHECK:
      return {
        ...state,
        isCityFraud: action.payload
      }

    case SET_CITY_FRAUD_CHECK1:
      return {
        ...state,
        isCityFraud1: action.payload
      }

    case SET_FLYING_FROM_TEXT_RETURN:
      return {
        ...state,
        flyingFromReturnText: action.payload
      }

    case SET_FLYING_FROM_TEXT_OJ:
      return {
        ...state,
        flyingFromOJText: action.payload
      }

    case SET_FLYING_FROM_TEXT_MULTI0:
      return {
        ...state,
        flyingFromMulti0Text: action.payload
      }

    case SET_FLYING_FROM_TEXT_MULTI1:
      return {
        ...state,
        flyingFromMulti1Text: action.payload
      }

    case SET_FLYING_FROM_TEXT_MULTI2:
      return {
        ...state,
        flyingFromMulti2Text: action.payload
      }

    case SET_FLYING_FROM_TEXT_MULTI3:
      return {
        ...state,
        flyingFromMulti3Text: action.payload
      }

    case SET_FLYING_FROM_TEXT_MULTI4:
      return {
        ...state,
        flyingFromMulti4Text: action.payload
      }

      case SET_FLYING_TO_TEXT_RETURN:
      return {
        ...state,
        flyingToReturnText: action.payload
      }

    case SET_FLYING_TO_TEXT_OJ:
      return {
        ...state,
        flyingToOJText: action.payload
      }

    case SET_FLYING_TO_TEXT_MULTI0:
      return {
        ...state,
        flyingToMulti0Text: action.payload
      }

    case SET_FLYING_TO_TEXT_MULTI1:
      return {
        ...state,
        flyingToMulti1Text: action.payload
      }

    case SET_FLYING_TO_TEXT_MULTI2:
      return {
        ...state,
        flyingToMulti2Text: action.payload
      }

    case SET_FLYING_TO_TEXT_MULTI3:
      return {
        ...state,
        flyingToMulti3Text: action.payload
      }

    case SET_FLYING_TO_TEXT_MULTI4:
      return {
        ...state,
        flyingToMulti4Text: action.payload
      }

    case SET_AGENCY_LIST:
      return {
        ...state,
        agencyList: action.payload
      }
    case SET_AGENT_LIST:
      return {
        ...state,
        agentList: action.payload
      }
    case SET_AGENT_FIELD:
      return {
        ...state,
        agentField: action.payload
      }
    case SET_AGENCY_FIELD:
      return {
        ...state,
        agencyField: action.payload
      }
    case SET_CACHE_EXPIRED_FLAG:
      return {
        ...state,
        cacheExpiredFlag: action.payload
      };

    case SET_CACHE_EXPIRED_DIALOG_FLAG:
      return {
        ...state,
        cacheExpireDialogFlag: action.payload
      };
      
    case SET_TRIP_TYPE:
      return {
        ...state,
        tripType: action.payload,
      };

    case SET_FLEXI_DATE:
      return {
        ...state,
        flexibleDate: action.payload,
      };

    case SET_FLYING_FROM:
      return {
        ...state,
        flyingFrom: action.payload,
      };

    case SET_FLYING_FROM_COUNTRY:
      return {
        ...state,
        flyingFromCountry: action.payload,
      };

    case SET_MULTYCITY_FLYING_FROM_COUNTRY_0:
      return {
        ...state,
        multiCityFlyingFromCountry_0: action.payload
      }

    case SET_MULTYCITY_FLYING_FROM_COUNTRYM:
      return {
        ...state,
        multiCityFlyingFromCountryM: action.payload
      }

    case SET_MULTYCITY_FLYING_FROM_COUNTRY_1:
      return {
        ...state,
        multiCityFlyingFromCountry_1: action.payload
      }
    case SET_MULTYCITY_FLYING_FROM_COUNTRY_2:
      return {
        ...state,
        multiCityFlyingFromCountry_2: action.payload
      }

    case SET_MULTYCITY_FLYING_FROM_COUNTRY_3:
      return {
        ...state,
        multiCityFlyingFromCountry_3: action.payload
      }

    case SET_MULTYCITY_FLYING_FROM_COUNTRY_4:
      return {
        ...state,
        multiCityFlyingFromCountry_4: action.payload
      }

    case SET_OPEN_JAW_FLYING_FROM_COUNTRY_B:
      return {
        ...state,
        openJawFlyingFromCountry_B: action.payload
      }

    case SET_FLYING_TO:
      return {
        ...state,
        flyingTo: action.payload,
      };

    case SET_FLYING_FROM_CITY:
      return {
        ...state,
        flyingFromCity: action.payload,
      };

    case SET_FLYING_TO_CITY:
      return {
        ...state,
        flyingToCity: action.payload,
      };

    case SET_DEPART_DATE:
      return {
        ...state,
        departDate: action.payload,
      };

    case SET_DEPART_TIME:
      return {
        ...state,
        departTime: action.payload
      }

    case SET_RETURN_TIME:
      return {
        ...state,
        returnTime: action.payload
      }

    case SET_OPENJAW_TIMEB:
      return {
        ...state,
        openJawTime_B: action.payload
      }

    case SET_MULTICITY_TIMEM:
      return {
        ...state,
        multiCityTimeM: action.payload
      }

    case SET_MULTICITY_TIME0:
      return {
        ...state,
        multiCityTime_0: action.payload
      }

    case SET_MULTICITY_TIME1:
      return {
        ...state,
        multiCityTime_1: action.payload
      }

    case SET_MULTICITY_TIME2:
      return {
        ...state,
        multiCityTime_2: action.payload
      }

    case SET_MULTICITY_TIME3:
      return {
        ...state,
        multiCityTime_3: action.payload
      }

    case SET_MULTICITY_TIME4:
      return {
        ...state,
        multiCityTime_4: action.payload
      }

    case SET_RETURN_DATE:
      return {
        ...state,
        returnDate: action.payload,
      };

    case SET_PASSENGER_ADULT:
      return {
        ...state,
        passengerAdult: action.payload,
      };

    case SET_PASSENGER_CHILDREN:
      return {
        ...state,
        passengerChildren: action.payload,
      };

    case SET_PASSENGER_INFANT:
      return {
        ...state,
        passengerInfant: action.payload,
      };

    case SET_CABIN:
      return {
        ...state,
        cabin: action.payload,
      };

    case SET_AIR_CONTRACT:
      return {
        ...state,
        airContract: action.payload,
      };

    case SET_QUALIFIERS:
      return {
        ...state,
        qualifiers: action.payload,
      };

    case SET_AIRLINES:
      return {
        ...state,
        airlines: action.payload,
      };

    case SET_SPECIFIC_AIRLINES_BLANK:
      return {
        ...state,
        isSpecificAirlineBlank: action.payload
      }

    case SET_MAX_CONNECTIONS:
      return {
        ...state,
        maxConnections: action.payload,
      };

    case SET_CHANGES_ALLOWED:
      return {
        ...state,
        changesAllowd: action.payload,
      };

    case SET_ALLIANCES:
      return {
        ...state,
        alliance: action.payload,
      };

    case SET_NEARBY_AIRPORT:
      return {
        ...state,
        nearbyAirport: action.payload,
      };

    case SET_ATLEAST_FREE_BAGGAGE:
      return {
        ...state,
        atLeastFreeBaggage: action.payload,
      };

    case SET_AUTOCOMPLETE_RETURN_FROM:
      return {
        ...state,
        autoCompleteReturnFrom: action.payload,
      };

    case SET_AUTOCOMPLETE_RETURN_TO:
      return {
        ...state,
        autoCompleteReturnTo: action.payload,
      };
  
    case SET_AUTOCOMPLETE_FLY_FROM_B:
      return {
        ...state,
        autoCompleteFlyFrom_B: action.payload,
      };

    case SET_AUTOCOMPLETE_FLY_TO_B:
      return {
        ...state,
        autoCompleteFlyTo_B: action.payload,
      };

    case SET_AUTOCOMPLETE_FLY_FROM_0:
      return {
        ...state,
        autoCompleteFlyFrom_0: action.payload,
      };

    case SET_AUTOCOMPLETE_FLY_FROMM:
      return {
        ...state,
        autoCompleteFlyFromM: action.payload,
      };
       
    case SET_AUTOCOMPLETE_FLY_TOM:
      return {
        ...state,
        autoCompleteFlyToM: action.payload,
      };
  
    case SET_AUTOCOMPLETE_FLY_TO_0:
      return {
        ...state,
        autoCompleteFlyTo_0: action.payload,
      };

    case SET_AUTOCOMPLETE_FLY_FROM_1:
      return {
        ...state,
        autoCompleteFlyFrom_1: action.payload,
      };
    
    case SET_AUTOCOMPLETE_FLY_TO_1:
      return {
        ...state,
        autoCompleteFlyTo_1: action.payload,
      };

    case SET_AUTOCOMPLETE_FLY_FROM_2:
      return {
        ...state,
        autoCompleteFlyFrom_2: action.payload,
      };
    
    case SET_AUTOCOMPLETE_FLY_TO_2:
      return {
        ...state,
        autoCompleteFlyTo_2: action.payload,
      };

    case SET_AUTOCOMPLETE_FLY_FROM_3:
      return {
        ...state,
        autoCompleteFlyFrom_3: action.payload,
      };

    case SET_AUTOCOMPLETE_FLY_FROM_4:
      return {
        ...state,
        autoCompleteFlyFrom_4: action.payload,
      };

    case SET_AUTOCOMPLETE_FLY_TO_3:
      return {
        ...state,
        autoCompleteFlyTo_3: action.payload,
      };
    
    case SET_AUTOCOMPLETE_FLY_TO_4:
      return {
        ...state,
        autoCompleteFlyTo_4: action.payload,
      };

    case SET_AUTOCOMPLETE_QUOTE_FROM:
      return {
        ...state,
        autoCompleteQuoteFrom: action.payload,
      };

    case SET_AUTOCOMPLETE_QUOTE_TO:
      return {
        ...state,
        autoCompleteQuoteTo: action.payload,
      };

    case SET_AUTOCOMPLETE_AIRLINES:
      return {
        ...state,
        autoCompleteAirlines: action.payload,
      };

    case SET_UUID:
      return {
        ...state,
        uuid: action.payload,
      };

    case SET_CARRIER_OPTIONS:
      return {
        ...state,
        carrieroptions: action.payload,
      };

    case SET_INPUT_FIELD_COUNT:
      return {
        ...state,
        multiCityFlightCnt: action.payload,
      };

    case SET_OPEN_JAW_DATE_B:
      return {
        ...state,
        openJawDate_B: action.payload,
      };
    case SET_OPEN_JAW_FLYING_FROM_B:
      return {
        ...state,
        openJawFlyingFrom_B: action.payload,
      };

    case SET_OPEN_JAW_FLYING_TO_B:
      return {
        ...state,
        openJawFlyingTo_B: action.payload,
      };

    case SET_MULTICITY_FLYING_FROM_4:
      return {
        ...state,
        multiCityFlyingFrom_4: action.payload,
      };

    case SET_MULTICITY_FLYING_TO_4:
      return {
        ...state,
        multiCityFlyingTo_4: action.payload,
      };

    case SET_MULTICITY_FLYING_FROM_0:
      return {
        ...state,
        multiCityFlyingFrom_0: action.payload,
      };

    case SET_MULTICITY_FLYING_TO_0:
      return {
        ...state,
        multiCityFlyingTo_0: action.payload,
      };

    case SET_MULTICITY_FLYING_FROMM:
      return {
        ...state,
        multiCityFlyingFromM: action.payload,
      };

    case SET_MULTICITY_FLYING_TOM:
      return {
        ...state,
        multiCityFlyingToM: action.payload,
      };

    case SET_MULTICITY_FLYING_FROM_1:
      return {
        ...state,
        multiCityFlyingFrom_1: action.payload,
      };

    case SET_MULTICITY_FLYING_TO_1:
      return {
        ...state,
        multiCityFlyingTo_1: action.payload,
      };

    case SET_MULTICITY_FLYING_FROM_2:
      return {
        ...state,
        multiCityFlyingFrom_2: action.payload,
      };

    case SET_MULTICITY_FLYING_TO_2:
      return {
        ...state,
        multiCityFlyingTo_2: action.payload,
      };

    case SET_MULTICITY_FLYING_FROM_3:
      return {
        ...state,
        multiCityFlyingFrom_3: action.payload,
      };

    case SET_MULTICITY_FLYING_TO_3:
      return {
        ...state,
        multiCityFlyingTo_3: action.payload,
      };

    case SET_MULTICITY_DATE_4:
      return {
        ...state,
        multiCityDate_4: action.payload,
      };

    case SET_MULTICITY_DATE_0:
      return {
        ...state,
        multiCityDate_0: action.payload,
      };

    case SET_MULTICITY_DATEM:
      return {
        ...state,
        multiCityDateM: action.payload,
      };

    case SET_MULTICITY_DATE_1:
      return {
        ...state,
        multiCityDate_1: action.payload,
      };

    case SET_MULTICITY_DATE_2:
      return {
        ...state,
        multiCityDate_2: action.payload,
      };

    case SET_MULTICITY_DATE_3:
      return {
        ...state,
        multiCityDate_3: action.payload,
      };

    case MULTICITY_ADDINPUT:
      return {
        ...state,
        inputField: [...state.inputField, { id: uuidv4() }],
      };

    case MULTICITY_REMOVEINPUT:
      const newArray = [...state.inputField];

      var newObj = { ...state };

      for (let i = 0; i < state.inputField.length; i++) {
        let multiCityFlyingFrom = state[`MultiCity_${i}`];
        let multiCityFlyingTo = state[`MultiCity_${i}`];
        let multiCityDate = state[`MultiCity_${i}`];
        let multiCityTime = state[`MultiCity_${i}`];

        if (i > action.payload) {
          newObj[`MultiCity_${i - 1}`] = multiCityFlyingFrom;
          newObj[`MultiCity_${i - 1}`] = multiCityFlyingTo;
          newObj[`MultiCity_${i - 1}`] = multiCityDate;
          newObj[`MultiCity_${i - 1}`] = multiCityTime;
        }
        if (i === state.inputField.length - 1) {
          newObj[`MultiCity_${state.inputField.length - 1}`] = "";
          newObj[`MultiCity_${state.inputField.length - 1}`] = "";
          newObj[`MultiCity_${state.inputField.length - 1}`] = null;
          newObj[`MultiCity_${state.inputField.length - 1}`] = "";
        }
      }

      newArray.splice(action.payload, 1);

      return {
        ...state,
        ...newObj,
        inputField: newArray,
      };

    case SET_REQUEST_OPTIONS:
      return {
        ...state,
        requestOptions: action.payload,
      };

    case SET_ITINENARY_INDEX:
      return {
        ...state,
        itinenaryIndex: action.payload,
      };

    case SET_BOOKING_ITINENARY:
      return {
        ...state,
        bookingItinenary: action.payload,
      };
      case SET_PAX_FARE_DETAILS:
      return {
        ...state,
        paxFareDetails: action.payload,
      }; 
      case SET_PAYMENT_DETAILS_ADDONS:
        return {
          ...state,
          paymentDetailsAddons: action.payload,
        };  

    case SET_BOOKING_HEADER:
      return {
        ...state,
        bookingHeader: action.payload,
      };
    case SET_BOOKING_FAREID:
      return {
        ...state,
        bookingFareId: action.payload,
      };

    case SET_FLYING_FROM_VALIDATION:
      return {
        ...state,
        flyingFromValidation: action.payload,
      };
    case SET_IS_ERROR_INCREMENT:
      var newIsErrorIncrement = state.isErrorCnt + 1;

      return {
        ...state,
        isErrorCnt: newIsErrorIncrement,
      };

    case SET_IS_ERROR_DECREMENT:
      var newIsErrorDecrement = state.isErrorCnt - 1;
      return {
        ...state,
        isErrorCnt: newIsErrorDecrement,
      };
    case SET_FLYING_TO_VALIDATION:
      return {
        ...state,
        flyingToValidation: action.payload,
      };
    case SET_DEPARTURE_DATE_VALIDATION:
      return {
        ...state,
        departureDateValidation: action.payload,
      };

    case SET_RETURN_DATE_VALIDATION:
      return {
        ...state,
        returnDateValidation: action.payload,
      };

    case SET_IS_ERROR_ROUNDTRIP:
      return {
        ...state,
        isErrorRoundTrip: action.payload,
      };

    case SET_IS_ERROR_ONE_WAY:
      return {
        ...state,
        isErrorOneWay: action.payload,
      };

    case SET_IS_ERROR_OPEN_JAW:
      return {
        ...state,
        isErrorOpenJaw: action.payload,
      };

    case SET_IS_ERROR_MULTI_CITY:
      return {
        ...state,
        isErrorMultiCity: action.payload,
      };

    case SET_ADULT_MARKUP_VALUE:
      return {
        ...state,
        adultMarkupValue: action.payload,
      };
    case SET_CHILD_MARKUP_VALUE:
      return {
        ...state,
        childMarkupValue: action.payload,
      };

    case SET_OPEN_JAW_FLYING_FROM_CITY_B:
      return {
        ...state,
        openJawFlyingFromCity_B: action.payload,
      };
    case SET_OPEN_JAW_FLYING_TO_CITY_B:
      return {
        ...state,
        openJawFlyingToCity_B: action.payload,
      };

    case SET_MULTI_CITY_FLYING_FROM_CITY_0:
      return {
        ...state,
        multiCityFlyingFromCity_0: action.payload,
      };
    case SET_MULTI_CITY_FLYING_TO_CITY_0:
      return {
        ...state,
        multiCityFlyingToCity_0: action.payload,
      };

    case SET_MULTI_CITY_FLYING_FROM_CITYM:
      return {
        ...state,
        multiCityFlyingFromCityM: action.payload,
      };
    case SET_MULTI_CITY_FLYING_TO_CITYM:
      return {
        ...state,
        multiCityFlyingToCityM: action.payload,
      };

    case SET_MULTI_CITY_FLYING_FROM_CITY_1:
      return {
        ...state,
        multiCityFlyingFromCity_1: action.payload,
      };
    case SET_MULTI_CITY_FLYING_TO_CITY_1:
      return {
        ...state,
        multiCityFlyingToCity_1: action.payload,
      };
    case SET_MULTI_CITY_FLYING_FROM_CITY_2:
      return {
        ...state,
        multiCityFlyingFromCity_2: action.payload,
      };
    case SET_MULTI_CITY_FLYING_TO_CITY_2:
      return {
        ...state,
        multiCityFlyingToCity_2: action.payload,
      };
    case SET_MULTI_CITY_FLYING_FROM_CITY_3:
      return {
        ...state,
        multiCityFlyingFromCity_3: action.payload,
      };
    case SET_MULTI_CITY_FLYING_TO_CITY_3:
      return {
        ...state,
        multiCityFlyingToCity_3: action.payload,
      };
    case SET_MULTI_CITY_FLYING_FROM_CITY_4:
      return {
        ...state,
        multiCityFlyingFromCity_4: action.payload,
      };
    case SET_MULTI_CITY_FLYING_TO_CITY_4:
      return {
        ...state,
        multiCityFlyingToCity_4: action.payload,
      };

    case SET_MODIFY_SEARCH_VALUE:
      return {
        ...state,
        modifySearchValue: action.payload,
      };
    case SET_SEGMENT_ARRAY_LENGTH:
      return {
        ...state,
        segmentArrayLength: action.payload,
      };

    case SET_PROVIDER_ID:
      return {
        ...state,
        providerId: action.payload,
      };

    case SET_TIME_CALCULATOR:
      return {
        ...state,
        timeCalculator: action.payload,
      };
    case SET_PROMOTION_DATA:
      return {
        ...state,
        promotionData: action.payload,
      };

    case SET_GROUP_ITINENARY_TIME_CALCULATOR:
      return {
        ...state,
        grpItinenaryTimeCalculator: action.payload,
      };
    case SET_API_RENDER_FLAG:
      return {
        ...state,
        apiRenderFlag: action.payload,
      };
    case SET_INFANT_MARKUP_VALUE:
      return {
        ...state,
        infantMarkupValue: action.payload,
      };

    case SET_TRIP_TYPE_FROM_API:
      return {
        ...state,
        tripTypeFromApi: action.payload,
      };

    case SET_DEPART_FROM_CITY:
      return {
        ...state,
        departFromCity: action.payload,
      }; 
    case SET_DEPART_FROM_CODE:
      return {
        ...state,
        departFromCode: action.payload,
      };
    case SET_ARRIVE_FROM_CITY:
      return {
        ...state,
        arriveFromCity: action.payload,
      }; 
      case SET_ARRIVE_FROM_CODE:
        return {
          ...state,
          arriveFromCode: action.payload,
        };
      case SET_FLYING_FROM_MODIFY_SEARCH:
        return {
          ...state,
        flyingFromModifySearch: action.payload,
      };
      case SET_FLYING_TO_MODIFY_SEARCH:
        return {
          ...state,
        flyingToModifySearch: action.payload,
      }; 
      case SET_FLYING_FROM_MODIFY_SEARCH_CODE:
        return {
          ...state,
        flyingFromModifySearchCode: action.payload,
      };
      case SET_FLYING_TO_MODIFY_SEARCH_CODE:
        return {
          ...state,
        flyingToModifySearchCode: action.payload,
      }; 
      case SET_DEPART_DATE_MODIFY_SEARCH:
        return {
          ...state,
        departDateModifySearch: action.payload,
      };
      case SET_RETURN_DATE_MODIFY_SEARCH:
        return {
          ...state,
        returnDateModifySearch: action.payload,
      }; 
      case SET_SHOW_AGENCY_INFO:
        return {
          ...state,
        showAgencyInfo: action.payload,
      }; 
      case SET_ALLIANCE_OPTION:
        return {
          ...state,
        allianceOption: action.payload,
      };   
      
      case SET_PNR_REMARK_CITY_FRAUD_CHECK:
        return {
          ...state,
          addPNRRemarkCityFraudCheck: action.payload
        }

        case SET_AGENCY_AGENT_FIELD_FLAG:
          return {
            ...state,
            agencyAgentFlag: action.payload
          } 
          case SET_PAX_CONTRACT:
            return {
              ...state,
              paxContract: action.payload,
            };
          case SET_DFAULT_BRAND_DETAILS:
            return {
              ...state,
              defaultBrandData: action.payload,
            };
            case SET_AUTOCOMPLETE_COUNTRY:
              return {
                ...state,
                autoCompleteCountry: action.payload,
              };
          case SET_IS_ERROR_INFANT:
              return {
                ...state,
                IsInfantError: action.payload.IsInfantError,
              };
          case SET_FARE_RULES_ERROR:
                return {
                  ...state,
                  fareRulesError: action.payload,
                };
          case SET_FLIGHT_TYPE:
            return {
              ...state,
              flightType: action.payload,
            };
            case SET_URL_USD:
              return {
                ...state,
                flightUrl: action.payload,
              };
              case SET_FLIGHT_TYPE_CHECK:
                return {
                  ...state,
                  flightTypeCheck: action.payload,
                };        
    default:
      return state;
  }
};

export default searchFormReducer;
