//@ts-nocheck
//import {getApiResponse} from "./../../utils/utility/getCommonApiResponse"
//import { setPaymentRules ,setMarkupApplied, setPaymentRulesForCheque} from "../../appStore/actionCreators/flightBooking"
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { env } from '../appConfig'
// toast.configure();

async function getApiResponse(url, requestOptions) {


    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        return null;

    } else {
        const data = await response.json();
        return data;
    }


}


export const getSaveMarkup = (requestbody) => {
    
    return async (dispatch, getState) => {
        
        const flightType = getState().searchForm.flightType;
        const corrId = getState().searchForm.uuid;
        let url = env.REACT_APP_API_BASE_URL + `/booking/process/saveAgencyMarkup`
       
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:flightType
            },
            body:
                JSON.stringify(
                    requestbody
                )
        }
       
        const markUpSaveValue = await getApiResponse(url, requestOptions);
       
        return markUpSaveValue
        

    }
}