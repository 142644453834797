import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import icon_remove from '../../_theme/_assets/images/icon_remove.png'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '12px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    }),
);

export default function SunDeleteConfrm(props:any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        props?.deleteRecord(props?.id)
        setOpen(false)
    }

    return (
        <div>
            <button type="button" className="cm-edit-del" onClick={handleOpen}>
                <i className="fas fa-trash"></i>
            </button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                
            >
                <Fade in={open} >
                    <div className={classes.paper}>
                        <img src={icon_remove} className="mb-3"/>
                        <h3 className="mx-3">Are You Sure?</h3>
                        <h6>Do you really want to delete this row?</h6>
                        <h6> This process cannot be undone. </h6>

                        <div className="mt-2">
                            <button style={{"border":"0","backgroundColor":"grey","color":"white"}} className="cm-secondary-btn mr-3" onClick={handleClose}><i className="fa-thin fa-xmark"></i>X  Cancel</button>
                            <button className="cm-secondary-btn  sun-edit-del " onClick={handleDelete}> <i className="fas fas fa-trash" ></i>Delete</button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}