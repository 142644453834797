
import { SET_PNR_TOKEN } from '../storeConstant';

const initialState = {
    token: ""
};

const PnrTokenReducer = (state = initialState, action) => {
   
    switch(action.type) {
        case SET_PNR_TOKEN:
            return {
                ...state,
                token: action.payload
            }

        default:
            return state;
    }
}

export default PnrTokenReducer;
