import { useContext, useEffect, useState } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function Rules(props) {
    const [editFlag, setEditFlag] = useState(false);
    const [jsonData ,setJsonData] = useState({});


    const selectEdit = () => {
        setEditFlag(true)
        setJsonData(props.classData)
    }

     // ONCHANGE VALUES FUNCTIONS
     const editOncahgeFunction = (e,type) =>{
      let charValues = jsonData
      setJsonData({... charValues.ageRule && charValues.ageRule.ageRestrictions,[type]:e.target.value})
  }
  

           // UPDATE RULES
           const updateRulesData = () =>{
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
    
                },
                body:
                    JSON.stringify(jsonData)
            }
            fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${props.tariffId}/class/${props.classId}`, requestOptions)
            .then(response => response.json())
            // console.log("response",response)
            .then(result => console.log("result",result))
            .catch(error => console.error("Error: ", error))
            setEditFlag(false)
    
        }
  
        console.log("jsonData",jsonData)
    return (
        <>
            <div className="card cm-card">
                <div className="card-body">
                    <div className="cm-edit-head">
                        <div className="head">AGE</div>
                        <div className="cm-card-edit">
                        {/* {editFlag ?
                            <div>
                                <button className="cm-secondary-btn mr-2" type="button" onClick={(e) => setEditFlag(false)}>Cancel</button>
                                <button className="cm-primary-btn" onClick={(e) => setEditFlag(false)}> Save</button>
                            </div>
                            :
                            <i onClick={(e) => selectEdit(true)} class="far fa-edit"></i>
                        } */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="cm-col">
                            <label className="cm-head-label">Infant Below</label>
                            {editFlag ?
                                <input 
                                className="form-control cm-input" 
                                placeholder="Enter value" 
                                onChange={(e)=>editOncahgeFunction(e,"infantAge")}
                                value={jsonData.ageRule && jsonData.ageRule.ageRestrictions &&  jsonData.ageRule.ageRestrictions.infantAge}
                                />
                                :
                                <div className="cm-content">
                                    {props.classData.ageRule && (props.classData.ageRule.ageRestrictions.infantAge == '' || null) &&
                                        <input className="form-control" disabled />
                                    } {props.classData.ageRule && props.classData.ageRule.ageRestrictions.infantAge}  Yrs
                                </div>
                            }
                            {/* <input className="form-control" />  */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cm-col">
                            <label className="cm-head-label">Applicable On </label>
                            {editFlag ?
                                <div className="cm-radio-inline">
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label="Departure"
                                        onChange={(e)=>editOncahgeFunction(e,"isApplicableOn")}
                                        value="ARRIVAL"
                                        checked={jsonData.ageRule && jsonData.ageRule.ageRestrictions.isApplicableOn == "ARRIVAL" ? true : false}
                                    />
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label="Return"
                                        onChange={(e)=>editOncahgeFunction(e,"isApplicableOn")}
                                        value="RETURN"
                                        checked={jsonData.ageRule && jsonData.ageRule.ageRestrictions.isApplicableOn == "RETURN" ? true : false}                                    />
                                </div>
                                :
                                <div className="cm-radio-inline">
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label="Departure"
                                        disabled
                                        checked={props.classData.ageRule && props.classData.ageRule.ageRestrictions.isApplicableOn == "DEPARTURE" ? true : false}
                                    />
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label="Return"
                                        disabled
                                        checked={props.classData.ageRule && props.classData.ageRule.ageRestrictions.isApplicableOn == "ARRIVAL" ? true : false}
                                    />
                                </div>
                            }
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cm-col">
                            {editFlag ?
                                <div className="cm-checkbox-inline">
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={props.classData.ageRule && props.classData.ageRule.ageRestrictions.noTicketingForInfant	 ? true : false}
                                            color="primary"
                                        />
                                        }
                                        label="No ticket for infant"
                                    />
                                </div>
                                :
                                <div className="cm-checkbox-inline">
                                    <FormControlLabel
                                        disabled
                                        control={
                                            <Checkbox
                                            checked={props.classData.ageRule && props.classData.ageRule.ageRestrictions.noTicketingForInfant ? true : false}
                                            color="primary"
                                            />
                                        }
                                        label="No ticket for infant"
                                    />
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card cm-card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="cm-col">
                            <label className="cm-head-label">Child Below</label>
                            {editFlag ?
                                <input className="form-control cm-input" placeholder="Enter value" />
                                :
                                <div className="cm-content">
                                    {props.classData.ageRule && (props.classData.ageRule.ageRestrictions.childAge == '' || null) &&
                                        <input className="form-control cm-input" disabled />
                                    }
                                    {props.classData.ageRule && props.classData.ageRule.ageRestrictions.childAge} Yrs
                                </div>
                            }
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cm-col">
                                <label className="cm-head-label">Junior between </label>
                                <div className="cm-content">
                                    {editFlag ?
                                        <label className="mr-2 mb-0"><input className="form-control cm-input cm-amount-field" /></label>
                                        :
                                        <strong className="mr-2">
                                            {props.classData && props.classData.juniorAgeTo} Yrs
                                        </strong>
                                    }
                                    <span>and</span>
                                    {editFlag ?
                                        <label className="ml-2 mb-0"><input className="form-control cm-input cm-amount-field" /></label>
                                        :
                                        <strong className="ml-2">{props.classData && props.classData.juniorAgeTo} Yrs</strong>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cm-col">
                                <label className="cm-head-label">Senior Above</label>
                                <input className="form-control cm-input" disabled />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="card cm-card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="cm-col">
                                <label style={{ fontWeight: 'bold' }}>Notes For Age</label>
                                {editFlag ?
                                    <input className="form-control cm-input" />
                                :
                                    <div className="cm-content">
                                        {props.classData.ageRule && props.classData.ageRule.note == '' &&
                                            <input className="form-control cm-input" disabled />
                                        }
                                        {props.classData.ageRule && props.classData.ageRule.note}

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card cm-card">
                <div className="card-body">
                <div className="row">
          <div className="col-md-3">
              <div className="cm-col">
                <label className="cm-head-label">OSI</label>
                <div className="cm-content">
                  {props.classData.otherDetails && props.classData.otherDetails.osi == null && "-"} 
                  {props.classData.otherDetails && props.classData.otherDetails.osi  }
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cm-col">
                <div className="cm-content">
                  <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      checked={props.classData.otherDetails && (props.classData.otherDetails.permitBranchPNR == true || props.classData.otherDetails.permitBranchPNR == "1")  ? true :false }
                      color="primary"
                    />
                  }
                  label="Branch files not permitted"
                />
                </div>
              </div>
            </div>
          </div>
                </div>
            </div>
        </>
    )
}
