import {
    SET_AGENT_AUTHETICATION, SET_AGENT_PROFILE, SET_ORG_ID, SET_AGENCY_ID,
    SET_AGENCY_GROUP_ID, SET_USER_ID, SET_AGENCY_LOCATION, SET_AGENT_NAME, 
    SET_AGENT_ID, SET_AGENT_TOKEN, SET_AGENT_CODE, SET_AGENCY_NAME, SET_AGENT_EMAIL_ID, 
    SET_AGENCY_DETAILS, SET_AGENT_DETAILS,AGENT_PROFILE_FEE,SET_INTERNAL_AGENT_ID
} from '../storeConstant';


const initialState = {
    isAgentAutheticated: undefined,
    agentProfile: {},
    orgId: "",
    agencyId: "",
    agencyGroupId: "",
    userId: "",
    agencyLocation: {},
    agentId: "",
    agentName: "",
    agentToken: "",
    agentCode: "",
    agencyName: "",
    agentEmailId: "",
    agencyDetails: {},
    agentDetails: {},
    agencyProfileFee: {},
    internalAgentId:""

};

const AgentProfileReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_AGENT_AUTHETICATION:
            return {
                ...state,
                isAgentAutheticated: action.payload
            }

        case SET_AGENT_PROFILE:
            return {
                ...state,
                agentProfile: action.payload
            }

        case SET_ORG_ID:
            return {
                ...state,
                orgId: action.payload
            }

        case SET_AGENCY_ID:
            return {
                ...state,
                agencyId: action.payload
            }
        case SET_AGENCY_DETAILS:
            return {
                ...state,
                agencyDetails: action.payload
            }
        case SET_AGENT_DETAILS:
            return {
                ...state,
                agentDetails: action.payload
            }

        case SET_AGENCY_GROUP_ID:
            return {
                ...state,
                agencyGroupId: action.payload
            }

        case SET_USER_ID:
            return {
                ...state,
                userId: action.payload
            }

        case SET_AGENCY_LOCATION:
            return {
                ...state,
                agencyLocation: action.payload
            }

        case SET_AGENT_ID:
            return {
                ...state,
                agentId: action.payload
            }

        case SET_AGENT_NAME:
            return {
                ...state,
                agentName: action.payload
            }
        case SET_AGENT_TOKEN:
            return {
                ...state,
                agentToken: action.payload

            }
        case SET_AGENT_CODE:
            return {
                ...state,
                agentCode: action.payload

            }
        case SET_AGENCY_NAME:
            return {
                ...state,
                agencyName: action.payload

            }
        case SET_AGENT_EMAIL_ID:
            return {
                ...state,
                agentEmailId: action.payload

            }
        case AGENT_PROFILE_FEE:

            return {
                ...state,
                agencyProfileFee: action.val,


            }
            case SET_INTERNAL_AGENT_ID:

                return {
                    ...state,
                    internalAgentId: action.payload,
    
    
                }
        default:
            return state;

    }
}

export default AgentProfileReducer;