//@ts-nocheck
import { SET_TRIP_TYPE , SET_FLEXI_DATE, SET_FLYING_FROM, SET_FLYING_TO, SET_DEPART_DATE, 
    SET_RETURN_DATE, SET_PASSENGER_ADULT, SET_PASSENGER_CHILDREN, SET_PASSENGER_INFANT,
    SET_CABIN, SET_AIR_CONTRACT, SET_QUALIFIERS, SET_AIRLINES, SET_MAX_CONNECTIONS, 
    SET_CHANGES_ALLOWED, SET_ALLIANCES, SET_NEARBY_AIRPORT, SET_ATLEAST_FREE_BAGGAGE, 
    SET_UUID, SET_CARRIER_OPTIONS, SET_INPUT_FIELD_COUNT, SET_OPEN_JAW_DATE_B, 
    SET_OPEN_JAW_FLYING_FROM_B, SET_OPEN_JAW_FLYING_TO_B, 
    SET_MULTICITY_FLYING_FROM_0, SET_MULTICITY_FLYING_TO_0, SET_MULTICITY_FLYING_FROM_1, SET_MULTICITY_FLYING_TO_1,
    SET_MULTICITY_FLYING_TO_2, SET_MULTICITY_FLYING_FROM_2, SET_MULTICITY_DATE_0, 
    SET_MULTICITY_DATE_1, SET_MULTICITY_DATE_2, MULTICITY_ADDINPUT, MULTICITY_REMOVEINPUT, SET_MULTICITY_FLYING_FROM_3, 
    SET_MULTICITY_FLYING_TO_3, SET_MULTICITY_DATE_3, SET_MULTICITY_FLYING_TO_4, SET_MULTICITY_FLYING_FROM_4, 
    SET_MULTICITY_DATE_4, SET_REQUEST_OPTIONS, SET_ITINENARY_INDEX, SET_BOOKING_ITINENARY, SET_BOOKING_HEADER, 
    SET_BOOKING_FAREID, SET_FLYING_FROM_CITY, SET_FLYING_TO_CITY, SET_FLYING_FROM_VALIDATION, SET_FLYING_TO_VALIDATION, 
    SET_DEPARTURE_DATE_VALIDATION, SET_RETURN_DATE_VALIDATION, SET_IS_ERROR_INCREMENT, SET_IS_ERROR_DECREMENT, SET_IS_ERROR_ROUNDTRIP, 
    SET_IS_ERROR_ONE_WAY, SET_IS_ERROR_OPEN_JAW, SET_IS_ERROR_MULTI_CITY, SET_ADULT_MARKUP_VALUE, SET_CHILD_MARKUP_VALUE, 
    SET_OPEN_JAW_FLYING_FROM_CITY_B, SET_OPEN_JAW_FLYING_TO_CITY_B, SET_MULTI_CITY_FLYING_FROM_CITY_0,
     SET_MULTI_CITY_FLYING_TO_CITY_0, SET_MULTI_CITY_FLYING_FROM_CITY_1, SET_MULTI_CITY_FLYING_TO_CITY_1,
      SET_MULTI_CITY_FLYING_TO_CITY_2, SET_MULTI_CITY_FLYING_FROM_CITY_2, SET_MULTI_CITY_FLYING_TO_CITY_3, 
      SET_MULTI_CITY_FLYING_FROM_CITY_3, SET_MULTI_CITY_FLYING_FROM_CITY_4, SET_MULTI_CITY_FLYING_TO_CITY_4, 
      SET_MODIFY_SEARCH_VALUE, SET_SEGMENT_ARRAY_LENGTH,SET_PROVIDER_ID, SET_AUTOCOMPLETE_AIRLINES, SET_TIME_CALCULATOR, 
      SET_GROUP_ITINENARY_TIME_CALCULATOR,SET_PROMOTION_DATA, SET_API_RENDER_FLAG,SET_INFANT_MARKUP_VALUE,SET_TRIP_TYPE_FROM_API, 
      SET_DEPART_FROM_CITY, SET_DEPART_FROM_CODE, SET_ARRIVE_FROM_CITY, SET_ARRIVE_FROM_CODE, SET_FLYING_FROM_MODIFY_SEARCH, 
      SET_FLYING_TO_MODIFY_SEARCH, SET_DEPART_DATE_MODIFY_SEARCH, SET_RETURN_DATE_MODIFY_SEARCH, SET_FLYING_FROM_MODIFY_SEARCH_CODE, 
      SET_FLYING_TO_MODIFY_SEARCH_CODE, SET_SHOW_AGENCY_INFO,SET_ALLIANCE_OPTION, SET_FLYING_FROM_COUNTRY,
      SET_OPEN_JAW_FLYING_FROM_COUNTRY_B, SET_MULTYCITY_FLYING_FROM_COUNTRY_0, SET_MULTYCITY_FLYING_FROM_COUNTRY_1,
      SET_MULTYCITY_FLYING_FROM_COUNTRY_2, SET_MULTYCITY_FLYING_FROM_COUNTRY_3, SET_MULTYCITY_FLYING_FROM_COUNTRY_4, SET_PNR_REMARK_CITY_FRAUD_CHECK,
     SET_SPECIFIC_AIRLINES_BLANK, SET_AGENCY_AGENT_FIELD_FLAG,SET_PAX_CONTRACT,SET_PAYMENT_DETAILS_ADDONS,SET_PAX_FARE_DETAILS,
     SET_CACHE_EXPIRED_FLAG, SET_AUTOCOMPLETE_RETURN_FROM, SET_AUTOCOMPLETE_RETURN_TO, SET_AUTOCOMPLETE_FLY_FROM_B, SET_AUTOCOMPLETE_FLY_TO_B,
     SET_AUTOCOMPLETE_FLY_FROM_0, SET_AUTOCOMPLETE_FLY_FROM_1, SET_AUTOCOMPLETE_FLY_FROM_2, SET_AUTOCOMPLETE_FLY_FROM_3, SET_AUTOCOMPLETE_FLY_FROM_4,
     SET_AUTOCOMPLETE_FLY_TO_0, SET_AUTOCOMPLETE_FLY_TO_1, SET_AUTOCOMPLETE_FLY_TO_2, SET_AUTOCOMPLETE_FLY_TO_3, SET_AUTOCOMPLETE_FLY_TO_4, SET_CACHE_EXPIRED_DIALOG_FLAG,
     SET_AUTOCOMPLETE_QUOTE_FROM, SET_AUTOCOMPLETE_QUOTE_TO, SET_AGENCY_LIST, SET_AGENT_LIST, SET_AGENCY_FIELD, SET_AGENT_FIELD, SET_FLYING_FROM_TEXT_RETURN, 
     SET_FLYING_FROM_TEXT_OJ, SET_FLYING_FROM_TEXT_MULTI0, SET_FLYING_FROM_TEXT_MULTI1, SET_FLYING_FROM_TEXT_MULTI2, SET_FLYING_FROM_TEXT_MULTI3, SET_FLYING_FROM_TEXT_MULTI4, 
     SET_FLYING_TO_TEXT_RETURN, SET_FLYING_TO_TEXT_OJ, SET_FLYING_TO_TEXT_MULTI0, SET_FLYING_TO_TEXT_MULTI1, SET_FLYING_TO_TEXT_MULTI2, SET_FLYING_TO_TEXT_MULTI3, SET_FLYING_TO_TEXT_MULTI4,
     SET_DEPART_TIME, SET_MULTICITY_TIME0,
     SET_MULTICITY_TIME1,
     SET_MULTICITY_TIME2,
     SET_MULTICITY_TIME3,
     SET_MULTICITY_TIME4,
     SET_OPENJAW_TIMEB,
     SET_RETURN_TIME,
     SET_CITY_FRAUD_CHECK,
     SET_CITY_FRAUD_CHECK1,
     SET_AUTOCOMPLETE_FLY_FROMM,
     SET_AUTOCOMPLETE_FLY_TOM,
     SET_MULTICITY_FLYING_TOM,
     SET_MULTICITY_FLYING_FROMM,
     SET_MULTI_CITY_FLYING_FROM_CITYM,
     SET_MULTI_CITY_FLYING_TO_CITYM,
     SET_MULTYCITY_FLYING_FROM_COUNTRYM,
     SET_MULTICITY_DATEM,
     SET_MULTICITY_TIMEM,
     SET_CLEAR_FORM,
     SET_DFAULT_BRAND_DETAILS,
     SET_INITIAL_SEARCH_MULTICITY,
     SET_AUTOCOMPLETE_COUNTRY,
     SET_IS_ERROR_INFANT,
     SET_FARE_RULES_ERROR,
     SET_FLIGHT_TYPE,
     SET_URL_USD,
     SET_FLIGHT_TYPE_CHECK
    } from '../storeConstant';

export const setInitialSearchForMulticity = (check) => {
    return {
        type: SET_INITIAL_SEARCH_MULTICITY,
        payload: check
    }
}

export const setClearFormFlag = (check) => {
    return {
        type: SET_CLEAR_FORM,
        payload: check
    }
}

export const setCityFraudCheck = (check) => {
    return {
        type: SET_CITY_FRAUD_CHECK,
        payload: check
    }
}

export const setCityFraudCheck1 = (check) => {
    return {
        type: SET_CITY_FRAUD_CHECK1,
        payload: check
    }
}

export const setFlyingFromTextReturn = (text) => {
    return {
        type: SET_FLYING_FROM_TEXT_RETURN,
        payload: text
    }
}

export const setFlyingFromTextOJ = (text) => {
    return {
        type: SET_FLYING_FROM_TEXT_OJ,
        payload: text
    }
}

export const setFlyingFromTextMulti0 = (text) => {
    return {
        type: SET_FLYING_FROM_TEXT_MULTI0,
        payload: text
    }
}

export const setFlyingFromTextMulti1 = (text) => {
    return {
        type: SET_FLYING_FROM_TEXT_MULTI1,
        payload: text
    }
}

export const setFlyingFromTextMulti2 = (text) => {
    return {
        type: SET_FLYING_FROM_TEXT_MULTI2,
        payload: text
    }
}

export const setFlyingFromTextMulti3 = (text) => {
    return {
        type: SET_FLYING_FROM_TEXT_MULTI3,
        payload: text
    }
}

export const setFlyingFromTextMulti4 = (text) => {
    return {
        type: SET_FLYING_FROM_TEXT_MULTI4,
        payload: text
    }
}

export const setFlyingToTextReturn = (text) => {
    return {
        type: SET_FLYING_TO_TEXT_RETURN,
        payload: text
    }
}

export const setFlyingToTextOJ = (text) => {
    return {
        type: SET_FLYING_TO_TEXT_OJ,
        payload: text
    }
}

export const setFlyingToTextMulti0 = (text) => {
    return {
        type: SET_FLYING_TO_TEXT_MULTI0,
        payload: text
    }
}

export const setFlyingToTextMulti1 = (text) => {
    return {
        type: SET_FLYING_TO_TEXT_MULTI1,
        payload: text
    }
}

export const setFlyingToTextMulti2 = (text) => {
    return {
        type: SET_FLYING_TO_TEXT_MULTI2,
        payload: text
    }
}

export const setFlyingToTextMulti3 = (text) => {
    return {
        type: SET_FLYING_TO_TEXT_MULTI3,
        payload: text
    }
}

export const setFlyingToTextMulti4 = (text) => {
    return {
        type: SET_FLYING_TO_TEXT_MULTI4,
        payload: text
    }
}
    
export const setAgencyList = (list) => {
    return {
        type: SET_AGENCY_LIST,
        payload: list
    }
}

export const setAgentList = (list) => {
    return {
        type: SET_AGENT_LIST,
        payload: list
    }
}

export const setAgencyField = (agency) => {
    return {
        type: SET_AGENCY_FIELD,
        payload: agency
    }
}

export const setAgentField = (agent) => {
    return {
        type: SET_AGENT_FIELD,
        payload: agent
    }
}

export const setCacheExpiredFlag = (cacheExpiredFlag) => {
    return {
        type: SET_CACHE_EXPIRED_FLAG,
        payload: cacheExpiredFlag
    }
}

export const setCacheExpireDialogFlag = (cacheExpireDialogFlag) => {
    return {
        type: SET_CACHE_EXPIRED_DIALOG_FLAG,
        payload: cacheExpireDialogFlag
    }
}
    
export const setTripType = (tripType) => {
    return {
        type: SET_TRIP_TYPE,
        payload: tripType
    }
}

export const flexiDateValue = (value) => {
    return {
        type: SET_FLEXI_DATE,
        payload: value
    }
}

export const setFlyingFrom = (flyingFrom) => {
    return {
        type: SET_FLYING_FROM,
        payload: flyingFrom
    }
}

export const setFlyingFromCountry = (flyingFromCountry) => {
    return {
        type: SET_FLYING_FROM_COUNTRY,
        payload: flyingFromCountry
    }
}

export const setFlyingTo = (flyingTo) => {
    return {
        type: SET_FLYING_TO,
        payload: flyingTo
    }
}

export const setDepartDate = (departDate) => {
    return {
        type: SET_DEPART_DATE,
        payload: departDate
    }
}

export const setDepartTime = (departTime) => {
    return {
        type: SET_DEPART_TIME,
        payload: departTime
    }
}

export const setReturnTime = (returnTime) => {
    return {
        type: SET_RETURN_TIME,
        payload: returnTime
    }
}

export const setOpenJawTimeB = (time) => {
    return {
        type: SET_OPENJAW_TIMEB,
        payload: time
    }
}

export const setMultiCityTimeM = (time) => {
    return {
        type: SET_MULTICITY_TIMEM,
        payload: time
    }
}

export const setMultiCityTime0 = (time) => {
    return {
        type: SET_MULTICITY_TIME0,
        payload: time
    }
}

export const setMultiCityTime1 = (time) => {
    return {
        type: SET_MULTICITY_TIME1,
        payload: time
    }
}

export const setMultiCityTime2 = (time) => {
    return {
        type: SET_MULTICITY_TIME2,
        payload: time
    }
}

export const setMultiCityTime3 = (time) => {
    return {
        type: SET_MULTICITY_TIME3,
        payload: time
    }
}

export const setMultiCityTime4 = (time) => {
    return {
        type: SET_MULTICITY_TIME4,
        payload: time
    }
}

export const setReturnDate = (returnDate) => {
    return {
        type: SET_RETURN_DATE,
        payload: returnDate
    }
}

export const setPassengerAdult = (adultCount) => {
    return {
        type: SET_PASSENGER_ADULT,
        payload: adultCount
    }
}

export const setPassengerChildren = (childrenCount) => {
    return {
        type: SET_PASSENGER_CHILDREN,
        payload: childrenCount
    }
}

export const setPassengerInfant = (infantCount) => {
    return {
        type: SET_PASSENGER_INFANT,
        payload: infantCount
    }
}

export const setCabin = (cabin) => {
    return {
        type: SET_CABIN,
        payload: cabin
    }
}

export const setAirContract = (airContract) => {
    return {
        type: SET_AIR_CONTRACT,
        payload: airContract
    }
}

export const setQualifiers = (qualifiers) => {
    return {
        type: SET_QUALIFIERS,
        payload: qualifiers
    }
}

export const setAirlines = (airlines) => {
    return {
        type: SET_AIRLINES,
        payload: airlines
    }
}

export const setMaxConnections = (maxConn) => {
    return {
        type: SET_MAX_CONNECTIONS,
        payload: maxConn
    }
}

export const setChangesAllowed = (changesAllowed) => {
    return {
        type: SET_CHANGES_ALLOWED,
        payload: changesAllowed
    }
}

export const setAlliance = (alliances) => {
    return {
        type: SET_ALLIANCES,
        paylaod: alliances
    }
}

export const setNearbyAirport = (nearbyAirport) => {
    return {
        type: SET_NEARBY_AIRPORT,
        payload: nearbyAirport
    }
}


export const setAtleastFreeBaggage = (freeBaggage) => {
     return {
        type: SET_ATLEAST_FREE_BAGGAGE,
        payload: freeBaggage
     }
}

export const setAutoCompleteReturnFrom = (value) => {
    return {
       type: SET_AUTOCOMPLETE_RETURN_FROM,
       payload: value
    }
}

export const setAutoCompleteReturnTo = (value) => {
    return {
       type: SET_AUTOCOMPLETE_RETURN_TO,
       payload: value
    }
} 

export const setAutoCompleteFlyFrom_B = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_FROM_B,
       payload: value
    }
}

export const setAutoCompleteFlyTo_B = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_TO_B,
       payload: value
    }
}

export const setAutoCompleteFlyFrom_0 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_FROM_0,
       payload: value
    }
}

export const setAutoCompleteFlyTo_0 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_TO_0,
       payload: value
    }
}

export const setAutoCompleteFlyFromM = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_FROMM,
       payload: value
    }
}

export const setAutoCompleteFlyToM = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_TOM,
       payload: value
    }
}

export const setAutoCompleteFlyFrom_1 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_FROM_1,
       payload: value
    }
}

export const setAutoCompleteFlyTo_1 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_TO_1,
       payload: value
    }
}

export const setAutoCompleteFlyFrom_2 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_FROM_2,
       payload: value
    }
}

export const setAutoCompleteFlyTo_2 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_TO_2,
       payload: value
    }
}

export const setAutoCompleteFlyFrom_3 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_FROM_3,
       payload: value
    }
}

export const setAutoCompleteFlyTo_3 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_TO_3,
       payload: value
    }
}

export const setAutoCompleteFlyFrom_4 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_FROM_4,
       payload: value
    }
}

export const setAutoCompleteFlyTo_4 = (value) => {
    return {
       type: SET_AUTOCOMPLETE_FLY_TO_4,
       payload: value
    }
}

export const setAutoCompleteQuoteFrom = (value) => {
    return {
       type: SET_AUTOCOMPLETE_QUOTE_FROM,
       payload: value
    }
}

export const setAutoCompleteQuoteTo = (value) => {
    return {
       type: SET_AUTOCOMPLETE_QUOTE_TO,
       payload: value
    }
}

export const setAutoCompleteAirLines = (value) => {
    return {
       type: SET_AUTOCOMPLETE_AIRLINES,
       payload: value
    }
}
    
export const setUUID = (uuid) => {
    return {
        type: SET_UUID,
        payload : uuid
    }
}


export const setCarrierOptions = (value) => {
    return {
        type: SET_CARRIER_OPTIONS,
        payload : value
    }
}
    
export const setInputFieldCount = (value) => {
    return {
       type: SET_INPUT_FIELD_COUNT,
       payload: value
    }
}


export const setOpenJawDate_B = (value) => {
    return {
        type: SET_OPEN_JAW_DATE_B,
        payload: value
    }
}


export const setOpenJawFlyingFrom_B = (value) => {
    return {
        type: SET_OPEN_JAW_FLYING_FROM_B,
        payload: value
    }
}

export const setOpenJawFlyingTo_B = (value) => {
    return {
        type: SET_OPEN_JAW_FLYING_TO_B,
        payload: value
    }
}

export const setMultiCityFlyingFrom_4 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_FROM_4,
        payload: value
    }
}

export const setMultiCityFlyingTo_4 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_TO_4,
        payload: value
    }
}

export const setMultiCityFlyingFromM = (value) => {
    return {
        type: SET_MULTICITY_FLYING_FROMM,
        payload: value
    }
}

export const setMultiCityFlyingToM = (value) => {
    return {
        type: SET_MULTICITY_FLYING_TOM,
        payload: value
    }
}

export const setMultiCityFlyingFrom_0 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_FROM_0,
        payload: value
    }
}

export const setMultiCityFlyingTo_0 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_TO_0,
        payload: value
    }
}

export const setMultiCityFlyingFrom_1 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_FROM_1,
        payload: value
    }
}

export const setMultiCityFlyingTo_1 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_TO_1,
        payload: value
    }
}

export const setMultiCityFlyingFrom_2 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_FROM_2,
        payload: value
    }
}

export const setMultiCityFlyingTo_2 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_TO_2,
        payload: value
    }
}

export const setMultiCityFlyingFrom_3 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_FROM_3,
        payload: value
    }
}

export const setMultiCityFlyingTo_3 = (value) => {
    return {
        type: SET_MULTICITY_FLYING_TO_3,
        payload: value
    }
}

export const setMultiCityDate_4 = (value) => {
    return {
        type: SET_MULTICITY_DATE_4,
        payload: value
    }
}

export const setMultiCityDateM = (value) => {
    return {
        type: SET_MULTICITY_DATEM,
        payload: value
    }
}

export const setMultiCityDate_0 = (value) => {
    return {
        type: SET_MULTICITY_DATE_0,
        payload: value
    }
}

export const setMultiCityDate_1 = (value) => {
    return {
        type: SET_MULTICITY_DATE_1,
        payload: value
    }
}

export const setMultiCityDate_2 = (value) => {
    return {
        type: SET_MULTICITY_DATE_2,
        payload: value
    }
}

export const setMultiCityDate_3 = (value) => {
    return {
        type: SET_MULTICITY_DATE_3,
        payload: value
    }
}


export const addinput = () => { 
    return {
     type:MULTICITY_ADDINPUT
    };
  };
  
  
  export const removeinput = (id) => { 
    return {
     type:MULTICITY_REMOVEINPUT,
     payload:id
    };
  };

export const setRequestOptions = (request) => {
    return {
        type: SET_REQUEST_OPTIONS,
        payload: request
    }
}

export const setItinenaryIndex = (value) => {
    return {
        type: SET_ITINENARY_INDEX,
        payload: value
    }
}

export const setBookingItinenary = (value) => {
    
    return {
        type: SET_BOOKING_ITINENARY,
        payload: value
    }
}

export const setPaxFareDetails = (value) => {
    
    return {
        type: SET_PAX_FARE_DETAILS,
        payload: value
    }
}

export const setPaymentDetailsAddons =(value)=>{
    return {
        type: SET_PAYMENT_DETAILS_ADDONS,
        payload: value
    }
}


export const setBookingHeader = (value) => {
    return {
        type: SET_BOOKING_HEADER,
        payload: value
    }
}

export const setBookingFareId = (value) => {
    return {
        type: SET_BOOKING_FAREID,
        payload: value
    }
}


export const setFlyingFromCity = (value) => {
    return {
        type: SET_FLYING_FROM_CITY,
        payload: value
    }
}

export const setFlyingToCity = (value) => {
    return {
        type: SET_FLYING_TO_CITY,
        payload: value
    }
}


export const setFlyingFromValidation = (value) => {
    return {
        type: SET_FLYING_FROM_VALIDATION,
        payload: value
    }
}

export const setFlyingToValidation = (value) => {
    return {
        type: SET_FLYING_TO_VALIDATION,
        payload: value
    }
}

export const setDepartureDateValidation = (value) => {
    return {
        type: SET_DEPARTURE_DATE_VALIDATION,
        payload: value
    }
}

export const setReturnDateValidation = (value) => {
    return {
        type: SET_RETURN_DATE_VALIDATION,
        payload: value
    }
}


export const setIsErrorIncrement = (value) => {
    return {
        type: SET_IS_ERROR_INCREMENT,
        payload: value
    }
}

export const setIsErrorDecrement = (value) => {
    return {
        type: SET_IS_ERROR_DECREMENT,
        payload: value
    }
}

export const setIsErrorRoundTrip = (value) => {
    return {
        type: SET_IS_ERROR_ROUNDTRIP,
        payload: value
    }
}

export const setIsErrorOneWayTrip = (value) => {
    return {
        type: SET_IS_ERROR_ONE_WAY,
        payload: value
    }
}

export const setIsErrorOpenJawTrip = (value) => {
    return {
        type: SET_IS_ERROR_OPEN_JAW,
        payload: value
    }
}

export const setIsErrorMultiCityTrip = (value) => {
    return {
        type: SET_IS_ERROR_MULTI_CITY,
        payload: value
    }
}

export const setAdultMarkupValue = (value) => {
    return {
        type: SET_ADULT_MARKUP_VALUE,
        payload: value
    }
}
export const setChildMarkupValue = (value) => {
    return {
        type: SET_CHILD_MARKUP_VALUE,
        payload: value
    }
}

export const setInfantMarkupValue = (value) => {
    return {
        type: SET_INFANT_MARKUP_VALUE,
        payload: value
    }
}


export const setOpenJawFlyingFromCity_B = (value) => {
    return {
        type: SET_OPEN_JAW_FLYING_FROM_CITY_B,
        payload: value
    }
}

export const setOpenJawFlyingToCity_B = (value) => {
    return {
        type: SET_OPEN_JAW_FLYING_TO_CITY_B,
        payload: value
    }
}

export const setMultiCityFlyingFromCity_0 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_FROM_CITY_0,
        payload: value
    }
}

export const setMultiCityFlyingToCity_0 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_TO_CITY_0,
        payload: value
    }
}

export const setMultiCityFlyingFromCityM = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_FROM_CITYM,
        payload: value
    }
}

export const setMultiCityFlyingToCityM = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_TO_CITYM,
        payload: value
    }
}

export const setMultiCityFlyingFromCity_1 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_FROM_CITY_1,
        payload: value
    }
}

export const setMultiCityFlyingToCity_1 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_TO_CITY_1,
        payload: value
    }
}

export const setMultiCityFlyingFromCity_2 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_FROM_CITY_2,
        payload: value
    }
}

export const setMultiCityFlyingToCity_2 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_TO_CITY_2,
        payload: value
    }
}

export const setMultiCityFlyingFromCity_3 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_FROM_CITY_3,
        payload: value
    }
}

export const setMultiCityFlyingToCity_3 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_TO_CITY_3,
        payload: value
    }
}

export const setMultiCityFlyingFromCity_4 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_FROM_CITY_4,
        payload: value
    }
}

export const setMultiCityFlyingToCity_4 = (value) => {
    return {
        type: SET_MULTI_CITY_FLYING_TO_CITY_4,
        payload: value
    }
}


export const setModifySearchValue = (value) => {
    return {
        type: SET_MODIFY_SEARCH_VALUE,
        payload: value
    }
}

export const setSegmentArrayLength = (value) => {
    return {
        type: SET_SEGMENT_ARRAY_LENGTH,
        payload: value
    }
}

export const setProvideId = (value) => {
    return {
        type: SET_PROVIDER_ID,
        payload: value
    }
}

export const setTimeCalculator = (value) => {
    return {
        type: SET_TIME_CALCULATOR,
        payload: value
    }
}

export const setPromotionData = (value) => {
    return {
        type: SET_PROMOTION_DATA,
        payload: value,
    }
}

export const setGroupItinenaryTimeCalculator = (value) => {
    return {
        type: SET_GROUP_ITINENARY_TIME_CALCULATOR,
        payload: value
    }
}

export const setApiRenderFlag = (value) => {
    return {
        type: SET_API_RENDER_FLAG,
        payload: value
    }
}

export const setTripTypeFromApi = (value) => {
    return {
        type: SET_TRIP_TYPE_FROM_API,
        payload: value
    }
}

export const setDepartFromCity = (value) => {
    return {
        type: SET_DEPART_FROM_CITY,
        payload: value
    }
}

export const setDepartFromCode = (value) => {
    return {
        type: SET_DEPART_FROM_CODE,
        payload: value
    }
}

export const setArriveFromCity = (value) => {
    return {
        type: SET_ARRIVE_FROM_CITY,
        payload: value
    }
}

export const setArriveFromCode = (value) => {
    return {
        type: SET_ARRIVE_FROM_CODE,
        payload: value
    }
}

export const setFlyingFromModifySearch = (value) => {
    return {
        type: SET_FLYING_FROM_MODIFY_SEARCH,
        payload: value
    }
}

export const setFlyingToModifySearch = (value) => {
    return {
        type: SET_FLYING_TO_MODIFY_SEARCH,
        payload: value
    }
}

export const setDepartDateModifySearch = (value) => {
    return {
        type: SET_DEPART_DATE_MODIFY_SEARCH,
        payload: value
    }
}

export const setReturnDateModifySearch = (value) => {
    return {
        type: SET_RETURN_DATE_MODIFY_SEARCH,
        payload: value
    }
}

export const setFlyingFromModifySearchCode = (value) => {
    return {
        type: SET_FLYING_FROM_MODIFY_SEARCH_CODE,
        payload: value
    }
}

export const setFlyingToModifySearchCode = (value) => {
    return {
        type: SET_FLYING_TO_MODIFY_SEARCH_CODE,
        payload: value
    }
}

export const setShowAgencyInfo = (value) => {
    return {
        type: SET_SHOW_AGENCY_INFO,
        payload: value
    }
}

export const setAllianceOption = (value) => {
    return {
        type: SET_ALLIANCE_OPTION,
        payload: value
    }
}

export const setOpenJawFlyingFromCountry_B = (value) => {
    return {
        type: SET_OPEN_JAW_FLYING_FROM_COUNTRY_B,
        payload: value
    }
}

export const setMultiCityFlyingFromCountryM = (value) => {
    return {
        type: SET_MULTYCITY_FLYING_FROM_COUNTRYM,
        payload: value
    }
}

export const setMultiCityFlyingFromCountry_4 = (value) => {
    return {
        type: SET_MULTYCITY_FLYING_FROM_COUNTRY_4,
        payload: value
    }
}

export const setMultiCityFlyingFromCountry_3 = (value) => {
    return {
        type: SET_MULTYCITY_FLYING_FROM_COUNTRY_3,
        payload: value
    }
}

export const setMultiCityFlyingFromCountry_2 = (value) => {
    return {
        type: SET_MULTYCITY_FLYING_FROM_COUNTRY_2,
        payload: value
    }
}

export const setMultiCityFlyingFromCountry_1 = (value) => {
    return {
        type: SET_MULTYCITY_FLYING_FROM_COUNTRY_1,
        payload: value
    }
}

export const setMultiCityFlyingFromCountry_0 = (value) => {
    return {
        type: SET_MULTYCITY_FLYING_FROM_COUNTRY_0,
        payload: value
    }
} 

export const setPNRRemarkCityFraudCheck = (value) => {
    return {
        type: SET_PNR_REMARK_CITY_FRAUD_CHECK,
        payload: value
    }
}

export const setSpecificAirlinesBlank = (value) => {
    return {
        type: SET_SPECIFIC_AIRLINES_BLANK,
        payload: value
    }
}

export const setAgencyAgentFieldFlag = (value) => {
    return {
        type: SET_AGENCY_AGENT_FIELD_FLAG,
        payload: value
    }
}

export const setPaxContract = (data) => {
    return {
        type: SET_PAX_CONTRACT,
        payload: data
    }
}

export const defaultBrandData = (value) => {
    
    return {
        type: SET_DFAULT_BRAND_DETAILS,
        payload: value
    }
}

export const setAutoCompleteCountry = (value) => {
    
    return {
        type: SET_AUTOCOMPLETE_COUNTRY,
        payload: value
    }
}

export const setIsInfantError = (value) => {
    return {
        type: SET_IS_ERROR_INFANT,
        payload: value
    }
}

export const setFareRulesError = (value) => {
    return {
        type: SET_FARE_RULES_ERROR,
        payload: value
    }
}

export const setFlightType= (value) => {
    return {
        type: SET_FLIGHT_TYPE,
        payload: value
    }
}

export const setUrlUsd= (value) => {
    return {
        type: SET_URL_USD,
        payload: value
    }
}

export const setFlightTypeCheck = (value) => {
    return {
        type: SET_FLIGHT_TYPE_CHECK,
        payload: value
    }
}