import { useContext,  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link , useHistory} from "react-router-dom";
import authContext from "../../context/auth-context";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {  Alert } from "react-bootstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';

export default function Createprofile() {
    const userInfo = useSelector((state:any) => state.userInfo);
    const loggedUsername = userInfo?.userData?.username
    const { orgId } = useContext(authContext);
    const [firstName ,setFirstName] = useState<any>('')
    const [lastName ,setLastName] = useState<any>('')
    const [entityStatus ,setentityStatus] = useState<any>(true)
    const [gender ,setGender] = useState<any>('Male')
    const [email ,setEmail] = useState<any>('')
    const [travelBusinessesId ,setTravelBusinessesId] = useState<any>('')
    const [contactNumber ,setContactNumber] = useState<any>('')
    const [countryCode ,setCountryCode] = useState<any>('')
    const [contactNumberAdditional ,setContactNumberAdditional] = useState<any>('')
    const [languagePreference ,setLanguagePreference] = useState<any>('EN')
    const [allowloyaltyPoints ,setAllowloyaltyPoints] = useState<any>(true)
    const [allowManagerAccess ,setAllowManagerAccess] = useState<any>(true)
    const [allowWhiteLabelWebsite ,setAllowWhiteLabelWebsite] = useState<any>(true)
    const [freeTextAirExecutiveStatus ,setFreeTextAirExecutiveStatus] = useState<any>('')
    const [transferDate ,setTransferDate] = useState<any>('')
    const [closingDate ,setClosingDate] = useState<any>('')
    const [freeTextLoyaltyPointsSpecialAgreement ,setFreeTextLoyaltyPointsSpecialAgreement] = useState<any>('')
    const [alert, setAlert] = useState({ variant: 'success', show: false, message: '' });
    // const [organzationForCOConsortium , setOrganzationForCOConsortium] =  useState('');
    const [agencyData , setAgencyData] =  useState([  ]);
    const [mobileCode , setMobileCode] =  useState('');


    // VALIDATION MESSAGE 
    const [travelBusinessesIdError , setTravelBusinessesIdError] =  useState('');
    const [firstNameError , setFirstNameError] =  useState('');
    const [lastNameError , setLastNameError] =  useState('');
    const [contactNumberError , setContactNumberError] =  useState('');
    const [emailError , setEmailError] =  useState('');
    const [contactNumberAdditionalError , setContactNumberAdditionalError] =  useState('');
    const [freeTextAirExecutiveStatusError , setFreeTextAirExecutiveStatusError] =  useState('');
    const [freeTextLoyaltyPointsSpecialAgreementError , setFreeTextLoyaltyPointsSpecialAgreementError] =  useState('');
    const history = useHistory();

    // SET EMAIL ID 
    const setEmailId = (e:any) => {
        var regex = /^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;
        if(e.target.value.match(regex)){
            setEmailError('')
        } else {
            setEmailError("Please enter valid email.")
        }
        setEmail(e.target.value)
    }

    // SET CONTACT
    const setContact = (e:any) =>{
        if(e.target.value !== undefined){
        if(e.target.value.length > 10){
        setContactNumberError("Contact Number should be 10 digit.")
        setContactNumber(e.target.value)
        } else {
            setContactNumber(e.target.value)
            setContactNumberError("")

        }
    }
     }

    // VALIDATION FOR AGENT
    const validation = () =>{
        setTravelBusinessesIdError('');
        setFirstNameError('')
        setLastNameError('')
        setContactNumberError('')
        setContactNumberAdditionalError('')
        setFreeTextLoyaltyPointsSpecialAgreementError('')
        setFreeTextAirExecutiveStatusError('')

      

        if(travelBusinessesId === ""){
            setTravelBusinessesIdError("Agency  can not be empty.")
        } else if(firstName === ''){
            setFirstNameError("First name can not be empty.")
        }  else if (lastName === ''){
            setLastNameError("Last name can not be empty.")
        }  else if (email === ''){
            setEmailError("Email can not be empty.")
        }   else if (contactNumber === ''){
            setContactNumberError("Contact Number can not be empty.")
        }   else if (contactNumberAdditional === ''){
            setContactNumberAdditionalError("Telphone Number can not be empty.")
        }   else if (freeTextAirExecutiveStatus === ''){
            setFreeTextAirExecutiveStatusError("Can not be empty.")
        }    else if (freeTextLoyaltyPointsSpecialAgreement === ''){
            setFreeTextLoyaltyPointsSpecialAgreementError("Can not be empty.")
        } else {
            onSubmit()
        }
    }

    async function onSubmit() {
        let corrId = `agent-users_external-create-`+ loggedUsername +"_"+ orgId +"-" + Date.now()

       let  jsonData = {
        "firstName": firstName,
        "lastName": lastName,
        "entityStatus": "1",
        "username":firstName ,
        // "password": "password",
        "contactNumber": mobileCode + contactNumber,
        "contactNumberAdditional": countryCode + contactNumberAdditional,
        "email": email,
    
        "languagePreference": languagePreference,
    
    
        "freeTextLogo": "This is loreum ispum",
        "freeTextAirExecutiveStatus": freeTextAirExecutiveStatus,
        "freeTextLoyaltyPointsSpecialAgreement": freeTextLoyaltyPointsSpecialAgreement,
    
        
        "travelBusinessesId": travelBusinessesId,
        "allowloyaltyPoints": allowloyaltyPoints,
    
    
        "transferDate":transferDate,
        "closingDate": closingDate,
    
        "signUpSource": "Portal",
    
        "gender": gender,
        
        "allowWhiteLabelWebsite": allowWhiteLabelWebsite,
        "allowManagerAccess": allowManagerAccess,
        
        "externalIds": [
           {
               "externalId" : "1",
                "source": "source" 
           }
        ]
    
    }
        try {
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/users/external?orgId=${orgId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    correlationid:corrId
                },
                body: JSON.stringify(jsonData),        
                })
                .then(response => response.json())
                .then(result =>{
                    swal({
                        text:"Profile Created successfully.",
                        icon: "success",
                      })
                 history.push(`/agentprofile/update/${result.userId}`);
                //setAlert({ variant: 'success', show: true, message: 'Profile Created   successfully' });
            })
          
        } catch (error) {
            console.error(error);
            swal({
                text:"Failed to create.",
                icon: "error",
              })
            //setAlert({ variant: "danger", show: true, message: "Failed to create" });
          }
        setTimeout(function(){  setAlert({ ...alert, show: false }) }, 3000);
       
    }

    const getAutoSuggestAgency = (value:any) =>{
        if(value.length > 2){
            fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${orgId}&adAgencyCode=${value}`, {
                method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.json())
                // console.log("response",response)
                .then(result =>{
                  result?.map((data:any)=>{
                    data['seacrhVariable'] = data.adAgencyCode + ' - ' + data.searchTravelBusinessesName
                  })
                  result.message === "No records found." ? setAgencyData([]) : setAgencyData(result)
                })
                .catch(error => console.error("Error: ", error))
    }}

    // GET AUTOFOCUS AGENCY
    const getonFocusAgency = () =>{
        setTravelBusinessesIdError('')
            fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/all?orgId=${orgId}`, {
                method: 'POST',
                redirect: 'follow'
            })
                .then(response => response.json())
                // console.log("response",response)
                .then(result =>{
                  result.Items.map((data:any)=>{
                    data['seacrhVariable'] = data.adAgencyCode + ' - ' + data.searchTravelBusinessesName
                  })
                  result.message === "No records found." ? setAgencyData([]) : setAgencyData(result.Items)})
                .catch(error => console.error("Error: ", error))
    }
    
    return (
        <>
            <div className="cm-h1-head">
                <h1>Agent Profile Management</h1>
                <Link to="/agentprofile/true"><i className="fas fa-chevron-left"></i>Back to Listing</Link>
            </div>
            <Alert
                variant={alert.variant}
                show={alert.show}
                onClose={() => setAlert({ ...alert, show: false })}
                dismissible
            >
            {alert.message}
            </Alert>
            <div className="card cm-card">
                <div className="card-header">
                    <div className="cm-card-head-btn">
                        <h4 className="cm-card-head">Create Profile</h4>
                    </div>
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <p className="cm-head-2">Agent Details </p>
                        </div>
                    
                        <div className="col-md-4 cm-col">
                        <Autocomplete
                            id="size-small-outlined"
                            freeSolo
                            size="small"
                            options={agencyData}
                            style={{background:'white'}}
                            onChange={(event, value) => setTravelBusinessesId(value?.travelBusinessesId)}      
                             // onClick={(e)=>setOrganzationForContract(e.target.value)}
                            getOptionLabel={(option:any) => option.seacrhVariable}
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Agency" 
                            // value={organzationForCOConsortium}
                            onFocus={()=>getonFocusAgency()}
                            onChange={(e)=>getAutoSuggestAgency(e.target.value)}
                            placeholder="Select Agency"
                        />         
                        )}
                    />
                        <span className="text-danger">{travelBusinessesIdError}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="cm-head-2">Personal Information</p>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">First Name <span className="text-danger">*</span></label>
                            <input value={firstName} onChange={(e)=>setFirstName(e.target.value)} className="form-control cm-input" placeholder="First Name" />
                            <span className="text-danger">{firstNameError}</span>

                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Last Name <span className="text-danger">*</span></label>
                            <input value={lastName} onChange={(e)=>setLastName(e.target.value)} className="form-control cm-input" placeholder="Last Name" />
                            <span className="text-danger">{lastNameError}</span>

                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Gender <span className="text-danger">*</span></label>
                            <select value={gender} onChange={(e)=>setGender(e.target.value)}  className="form-control cm-input-select">
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                            </select>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Status <span className="text-danger">*</span></label>
                            <div className="cm-radio-inline">
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Active"
                                    checked={entityStatus === '1' ? true : false}
                                    onChange={(e)=>setentityStatus("1")}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Inactive"
                                    checked={entityStatus === '0' ? true : false}
                                    onChange={(e)=>setentityStatus("0")}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Email address <span className="text-danger">*</span></label>
                            <input value={email} onChange={(e)=>setEmailId(e)} className="form-control cm-input" placeholder="Email address" />
                            <span className="text-danger">{emailError}</span>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Mobile <span className="text-danger">*</span></label>
                            <div className="input-group cm-text-county">
                                <select value={mobileCode} onChange={(e)=>setMobileCode(e.target.value)} className="form-control cm-input-select">
                                    <option value="+1">CAN(+1)</option>
                                    <option value="+91">IND(+91)</option>
                                </select>
                                <input type="text" value={contactNumber} onChange={(e)=>setContact(e)} className="form-control cm-input" placeholder="Mobile" />

                            </div>
                            <p className="text-danger">{contactNumberError}</p>

                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Additional telephone number <span className="text-danger">*</span></label>
                            <div className="input-group cm-text-county">
                                <select value={countryCode} onChange={(e)=>setCountryCode(e.target.value)} className="form-control cm-input-select">
                                    <option value="+1">CAN(+1)</option>
                                    <option value="+91">IND(+91)</option>
                                </select>
                                <input type="text" value={contactNumberAdditional} onChange={(e)=>setContactNumberAdditional(e.target.value)} aria-label="Text input with dropdown button" className="cm-input form-control" />
                            </div>
                            <p className="text-danger">{contactNumberAdditionalError}</p>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Preferred language <span className="text-danger">*</span></label>
                            <select value={languagePreference} onChange={(e)=>setLanguagePreference(e.target.value)} className="form-control cm-input-select">
                                <option value="EN">English</option>
                                <option value="FR">French </option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="cm-head-2">Assign rights to agent</p>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Loyalty points promotion on air applies</label>
                            <div className="cm-radio-inline">
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    checked={allowloyaltyPoints}
                                    onChange={(e)=>setAllowloyaltyPoints(true)}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="No"
                                    checked={allowloyaltyPoints === false}
                                    onChange={(e)=>setAllowloyaltyPoints(false)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Manager Access </label>
                            <div className="cm-radio-inline">
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    checked={allowManagerAccess}
                                    onChange={(e)=>setAllowManagerAccess(true)}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="No"
                                    checked={allowManagerAccess === false}
                                    onChange={(e)=>setAllowManagerAccess(false)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">customized white label website</label>
                            <div className="cm-radio-inline">
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    checked={allowWhiteLabelWebsite}
                                    onChange={(e)=>setAllowWhiteLabelWebsite(true)}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="No"
                                    checked={allowWhiteLabelWebsite === false}
                                    onChange={(e)=>setAllowWhiteLabelWebsite(false)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Agent Air Executive Status</label>
                            <textarea
                            value={freeTextAirExecutiveStatus}
                            onChange={(e)=>setFreeTextAirExecutiveStatus(e.target.value)}
                            className="form-control cm-textarea"
                            />
                            <span className="text-danger">{freeTextAirExecutiveStatusError}</span>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Loyatly Points Special Agreements</label>
                            <textarea
                                value={freeTextLoyaltyPointsSpecialAgreement}
                                onChange={(e)=>setFreeTextLoyaltyPointsSpecialAgreement(e.target.value)}
                                className="form-control cm-textarea"
                            />
                         <span className="text-danger">{freeTextLoyaltyPointsSpecialAgreementError}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="cm-head-2">Profile creation</p>
                        </div>
                        {/* <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Profile Creation date</label>
                            <input type="date" className="form-control cm-input-date"/>
                        </div> */}
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Profile Closing date <span className="cm-primary-text">(only if applicable)</span></label>
                            <input type="date" value={closingDate} onChange={(e)=>setClosingDate(e.target.value)} className="form-control cm-input-date"/>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Profile Transfer date <span className="cm-primary-text">(To be discussed yet)</span></label>
                            <input   value={transferDate} onChange={(e)=>setTransferDate(e.target.value)} type="date" className="form-control cm-input-date"/>
                        </div>
                        {/* <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Link to Travel Agency (Linked to only one agency)</label>
                            <input value={travelBusinessesId} onChange={(e)=>setTravelBusinessesId(e.target.value)} type="text" className="form-control cm-input"/>
                        </div> */}
                        <div className="col-12 cm-col text-left">
                        {/* <span className="text-danger"><b>{errorMeassge} </b></span> */}

                        </div>
                        <div className="col-12 cm-col text-right">
                        <Link to="/agentprofile/true"> <button  className="cm-secondary-btn mr-2" >Cancel</button></Link>
                            <button onClick={()=>validation()}  className="cm-primary-btn">Save Profile</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
