//@ts-nocheck
import React, { useState } from "react";
import bags from "../bookingConfirmation/Images/bag-icon.svg";
import seats from "../bookingConfirmation/Images/seat-icon.svg";
import BookingSubHead from "./BookingSubHead";
import { useSelector } from "react-redux";
import moment from "moment";
import { getPenaltiesData } from "../bookingConfirmation/thunks/getPenaltiesData";
import { getPenaltiesDataForPNR } from '../bookingConfirmation/thunks/getPenaltiesDataForPNR';
import { useDispatch } from "react-redux";
import DetailsTab from "../bookingConfirmation/detailsTab";
import { timeConvert } from "../bookingConfirmation/Utils/convertmintohours";
import { BookingFrom, BookingHead, FlightLogo, TripType } from "../bookingConfirmation/Utils/Constant";
import { getFlightLogo,splitFromCapital } from "../bookingConfirmation/Utils/CommenFunction";

function BookingItinenaryMulticity(props) {
  const bookingHeader = useSelector((state) => state.searchForm.bookingHeader);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const cabinValue = useSelector((state) => state.searchForm.cabin);
  let adultsCount 
  let childCount 
  let infantCount

  const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
  const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
  const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
  const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
  const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
  const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
  const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
  const bookingItineriesData = useSelector((state) => state.flightBooking.bookingItenaries);
  const paxFares = bookingItineriesData && bookingItineriesData.paxFares
  const fareBasisDetails =  paxFares && paxFares[0] && paxFares[0].fareBasisDetails

  if (pnrFlag) {
    adultsCount = pnrAdultsCount
    childCount = pnrChildCount
    infantCount = pnrInfantCount
  } else {
    adultsCount = searchAdultCOunt
    childCount = searchChildCount
    infantCount = searchInfantCount
  }
  
  const brandCode = useSelector((state)=>state.fareOptions.brandCode)
  var flyingFromArray = [];
  if (props.itinenary) {
    props.itinenary.hasOwnProperty("segments") ?
      props.itinenary.segments.map((name) => {
        Object.values(name).map((data) => {

          let flightDetail = data && data.flights;
          let flyingFromCity =
            data && data.flights && data.flights[0].departureAirportCity;
          let flyingFromCityCode =
            data && data.flights && data.flights[0].departureAirport;
          let flyingToCity =
            data &&
            data.flights &&
            data.flights[flightDetail.length - 1].arrivalAirportCity;
          let flyingToCityCode =
            data &&
            data.flights &&
            data.flights[flightDetail.length - 1].arrivalAirport;

          flyingFromArray.push({
            flyingFrom: flyingFromCity + " " + "(" + flyingFromCityCode + ")",
            flyingTo: flyingToCity + " " + "(" + flyingToCityCode + ")",
          });
        });
      }) : props.itinenary.map((name) => {
        Object.values(name).map((data) => {

          let flightDetail = data && data.flights;
          let flyingFromCity =
            data && data.flights && data.flights[0].departureAirportCity;
          let flyingFromCityCode =
            data && data.flights && data.flights[0].departureAirport;
          let flyingToCity =
            data &&
            data.flights &&
            data.flights[flightDetail.length - 1].arrivalAirportCity;
          let flyingToCityCode =
            data &&
            data.flights &&
            data.flights[flightDetail.length - 1].arrivalAirport;

          flyingFromArray.push({
            flyingFrom: flyingFromCity + " " + "(" + flyingFromCityCode + ")",
            flyingTo: flyingToCity + " " + "(" + flyingToCityCode + ")",
          });
        });
      })
  }


  const dispatch = useDispatch();
  const [flightDIndex, setFlightDIndex] = useState(null);

  const details = props.itinenary && props.itinenary.hasOwnProperty("segments")?props.itinenary &&Object.values(props.itinenary.segments):props.itinenary && Object.values(props.itinenary);

  var connections = [];
  var flightNames = [];
  var flightIds = [];
  var cabinType;
  var baggage;
  var baggageInWeight;
  var seatsRemaining;
  let date = bookingHeader.ticketingTimeLimit;

  details && details.map((name) => {
    Object.values(name).map((data) => {
      connections.push(data && data.flights && data.flights.length - 1);
      data && data.flights.map((value) => {
        var airPortName = value.departureAirport + "-" + value.arrivalAirport;
        flightNames.push(airPortName);
      });
    });
  });

  const baggageData = [];

  const handleFlightDToggle = (i) => {

    if (flightDIndex == i) {
      return setFlightDIndex(null);
    }
    setFlightDIndex(i);

    if(props.fareId) {
      dispatch(getPenaltiesData(props.fareId));
    }
    else if(props.recordLocator) {
      dispatch(getPenaltiesDataForPNR(props.recordLocator))
    }
  };

  let totalDuration;

  return (
    <>
      {(props.fromBookingConfirmation || props.from === BookingFrom.CONFIRMATION) ? (
        <div className="bd-itinerary-box">
          {/* {!props.isCalcultor && <BookingItinerayInfo bookingHeader={bookingHeader} from={props.from}/>} */}

          <div className="bd-itinery-detail">
            <div className="col-12 bd-itinery-box-wrapper">
              <div className="bd-itinery-box">
                {details && details.map((item, detailsIndex) => {
                  return (
                    <>
                      {Object.values(item).map((flight) => {
                        let layover = flight?.layovers;
                        let flights = flight.flights;
                        let layoverTime = 0;
                        cabinType = flight.cabin;
                        seatsRemaining = flight.seatsRemaining;
                        baggage = flight.baggage;
                        baggageInWeight = flight.weight;
                        let classOfService1 = flight.classOfService

                        return (
                          <>
                            <div className="bd-itinery-box-head">
                              <div>
                                <strong>Outbound</strong>
                                {
                                  flyingFromArray[detailsIndex].flyingFrom
                                } - {flyingFromArray[detailsIndex].flyingTo}|{" "}

                              </div>
                              <div className="bd-ib-total">
                                {layover && layover.map((value) => {
                                  layoverTime += value.durationMinutes;
                                })}
                                Total Duration :
                                {timeConvert(
                                  Object.values(item)[0].durationMinutes

                                )}
                              </div>
                            </div>

                            <div className="bd-itinery-multicity-list">
                              {flights.map((flight, index) => {
                                baggageData.push(flight.baggageDetails);
                                flightIds.push(flight.id);
                             
                                let carrierCode = flight && flight.carrier
                                let flightLogo = getFlightLogo(carrierCode)
                
                                layoverTime = layoverTime + 1;

                                let flightClassOfservice = fareBasisDetails && fareBasisDetails.filter(obj=>obj.flightId===flight.id)
                                let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
                                return (
                                  <>
                                    <div className="bd-itinery-item">
                                    {flight && flight.statusDetail && flight.statusDetail.code === "UN" ?
                                      <div className="title">
                                        <img src={flightLogo} alt="flightLogo" />
                                        {flight.carrier + " " + flight.number +" "+ (classOfService?classOfService:"")} <span className="cancelled_flight"> - Cancelled</span>
                                        </div>
                                          :
                                       <div className="title">
                                           <img src={flightLogo} alt="flightLogo" />
                                            {flight.carrier + " " + flight.number +" "+(classOfService?classOfService:"")}
                                       </div>
                    
                                    }

                                      <div className="flight-from-to">
                                        <div className="flight-from-to-city">
                                          <div className="city">
                                            <strong>
                                              {flight.departureAirport}
                                            </strong>{" "}
                                            {moment.utc(
                                              flight.departureDateTime
                                            ).format("HH:mm")}
                                          </div>
                                          <span className="date">
                                            {moment.utc(
                                              flight.departureDateTime
                                            ).format("ddd MMM DD, YYYY")}
                                          </span>
                                        </div>
                                        <div className="flight-from-to-divider"></div>
                                        <div className="flight-from-to-city">
                                          <div className="city">
                                            <strong>
                                              {flight.arrivalAirport}
                                            </strong>
                                            {moment.utc(
                                              flight.arrivalDateTime
                                            ).format("HH:mm")}
                                          </div>
                                          <span className="date">
                                            {moment.utc(
                                              flight.arrivalDateTime
                                            ).format("ddd MMM DD, YYYY")}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="time-from-to">
                                        {timeConvert(flight.durationMin)}
                                      </div>
                                    </div>
                                    {layover && layover[index] ? (
                                      <div className="bd-itinery-layover">
                                        <span>{flight.arrivalAirport} </span>
                                        <span>
                                          {timeConvert(
                                            layover[index].durationMinutes
                                          ) + " Layover"}
                                        </span>
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}

                              <div className="bd-itinery-btns">
                                <div className="seatBag">
                                  {seatsRemaining && <span>
                                    <img src={seats} alt="seat" />
                                    {seatsRemaining}
                                  </span>}

                                  <span>
                                    <img src={bags} alt="seat" />
                                    {baggageInWeight > baggage
                                      ? " " + baggageInWeight + " Kg"
                                      : baggage}
                                  </span>
                                </div>
                                {detailsIndex === details.length - 1 ? (
                                  <div>
                                    <button
                                      onClick={() => {
                                        handleFlightDToggle(1);
                                      }}
                                    >
                                      Flight Details
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  );
                })}

                <div
                  className={
                    flightDIndex === 1
                      ? "flight_tabs_show bg-white"
                      : "flight_tabs_none"
                  }
                >
                  <DetailsTab
                    flightdata={details}
                    baggagefares={baggageData}
                    flightName={flightNames}
                    fareId={props.fareId}
                    recordLocator={props.recordLocator}
                    paxFare={paxFares}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bd-itinerary-sec">
          <BookingSubHead
            title={BookingHead.ITINENARY}
          /><div className="one_line">
          <ul className="bd-trip-info">
            
            <li>{tripTypeValue === TripType.MULTICITY && "Multi City"}</li>
            <li>{cabinValue && splitFromCapital(cabinValue)} Class</li>
            <li>
              {adultsCount} Adult {childCount} Child {infantCount} Infant
            </li>
           
          </ul>
           <p>{brandCode.brandName} </p>
          </div>
          <div className="bd-itinerary-box">
            {/* <BookingItinerayInfo bookingHeader={bookingHeader} from={props.from}/> */}

            <div className="bd-itinery-detail">
              <div className="col-12 bd-itinery-box-wrapper">
                <div className="bd-itinery-box">
                  {details.map((item, detailsIndex) => {
                    return (
                      <>
                        {Object.values(item).map((flight) => {
                          let layover = flight?.layovers;
                          let flights = flight.flights;
                          let layoverTime = 0;
                          cabinType = flight.cabin;
                          seatsRemaining = flight.seatsRemaining;
                          baggage = flight.baggage;
                          baggageInWeight = flight.weight;
                          let classOfService1 = flight.classOfService
                          return (
                            <>
                              <div className="bd-itinery-box-head">
                                <div>
                                  <strong>Outbound</strong>
                                  {
                                    flyingFromArray && flyingFromArray[detailsIndex].flyingFrom
                                  } - {flyingFromArray && flyingFromArray[detailsIndex].flyingTo}|{" "}

                                </div>
                                <div className="bd-ib-total">
                                  {layover && layover.map((value) => {
                                    layoverTime += value.durationMinutes;
                                  })}
                                  Total Duration :
                                  {timeConvert(
                                    Object.values(item)[0].durationMinutes

                                  )}
                                </div>
                              </div>

                              <div className="bd-itinery-multicity-list">
                                {flights.map((flight, index) => {
                                  baggageData.push(flight.baggageDetails);
                                  flightIds.push(flight.id);
                               
                                  let carrierCode = flight && flight.carrier
                                  let flightLogo = getFlightLogo(carrierCode)
                  
                                  layoverTime = layoverTime + 1;

                                  let flightClassOfservice = classOfService1 && classOfService1.filter(obj=>obj.flightId===flight.id)
                                  let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService

                                  return (
                                    <>
                                      <div className="bd-itinery-item">
                                        <div className="title">
                                          <img
                                            src={flightLogo}
                                            alt="flightLogo"
                                          />
                                          {flight.carrier + " " + flight.number+" "+(classOfService?classOfService:"")}
                                        </div>

                                        <div className="flight-from-to">
                                          <div className="flight-from-to-city">
                                            <div className="city">
                                              <strong>
                                                {flight.departureAirport}
                                              </strong>{" "}
                                              {moment.utc(
                                                flight.departureDateTime
                                              ).format("HH:mm")}
                                            </div>
                                            <span className="date">
                                              {moment.utc(
                                                flight.departureDateTime
                                              ).format("ddd MMM DD, YYYY")}
                                            </span>
                                          </div>
                                          <div className="flight-from-to-divider"></div>
                                          <div className="flight-from-to-city">
                                            <div className="city">
                                              <strong>
                                                {flight.arrivalAirport}
                                              </strong>
                                              {moment.utc(
                                                flight.arrivalDateTime
                                              ).format("HH:mm")}
                                            </div>
                                            <span className="date">
                                              {moment.utc(
                                                flight.arrivalDateTime
                                              ).format("ddd MMM DD, YYYY")}
                                            </span>
                                          </div>
                                        </div>

                                        <div className="time-from-to">
                                          {timeConvert(flight.durationMin)}
                                        </div>
                                      </div>
                                      {layover && layover[index] ? (
                                        <div className="bd-itinery-layover">
                                          <span>{flight.arrivalAirport} </span>
                                          <span>
                                            {timeConvert(
                                              layover[index].durationMinutes
                                            ) + " Layover"}
                                          </span>
                                        </div>
                                      ) : null}
                                    </>
                                  );
                                })}

                                <div className="bd-itinery-btns">
                                  <div className="seatBag">
                                    {seatsRemaining && <span>
                                      <img src={seats} alt="seat" />
                                      {seatsRemaining}
                                    </span>}

                                    <span>
                                      <img src={bags} alt="seat" />
                                      {baggageInWeight > baggage
                                        ? " " + baggageInWeight + " Kg"
                                        : baggage}
                                    </span>
                                  </div>
                                  {detailsIndex === details.length - 1 ? (
                                    <div>
                                      <button
                                        onClick={() => {
                                          handleFlightDToggle(1);
                                        }}
                                      >
                                        Flight Details
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  })}

                  <div
                    className={
                      flightDIndex === 1
                        ? "flight_tabs_show bg-white"
                        : "flight_tabs_none"
                    }
                  >
                    <DetailsTab
                      flightdata={details}
                      baggagefares={baggageData}
                      flightName={flightNames}
                      fareId={props.fareId}
                      recordLocator={props.recordLocator}
                      paxFare={paxFares}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BookingItinenaryMulticity;
