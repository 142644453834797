import axios from 'axios';
import swal from 'sweetalert';

export const updateNewSalesPromotion = async (payload:any) => {
        let url: any = process.env.REACT_APP_BASE_URL + `/salesPromotion/createSalesPromotion`;
        const requestOptions: any = {
            headers: {
                "Content-type": "application/json",
            },
        }
        let updateNewSalesPromotion:any;
        try{
            updateNewSalesPromotion = await axios.post(url, payload, requestOptions)
        }catch (error) {
            console.error(error);
            swal({
                text: "Failed",
                icon: "error"
            });
            throw error;
        }
        if (updateNewSalesPromotion?.error) {
            swal({
                text:"Create/Update Failed!",
                icon: "error",
              })
        }
        else if (updateNewSalesPromotion?.data?.message != "Sales Promotion Created!") {
            swal({
                text: updateNewSalesPromotion?.data?.message,
                icon: "error",
                buttons: {
                    close: {
                        text: "Close",
                        closeModal: true,
                    },
                },
            })
        } else {
            swal({
                text: payload?.hasOwnProperty('naID') ? updateNewSalesPromotion?.data?.message : 'SalesPromotion updated successfully!',
                icon: "success",
                buttons: {
                    close: {
                        text: "Close",
                        closeModal: true,
                    },
                },
            })
        }
        return updateNewSalesPromotion?.data
    
}