import React from 'react'
import {   useState  } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory} from 'react-router-dom';

export default function ClassSearch() {
  const history = useHistory();
    const [allClassessData ,setAllClassessData] = useState([]);
    const [brandId ,setBrandId] = useState("");
    const [category ,setCategory] = useState("");
    const [clasCodeDesc ,setClasCodeDesc] = useState("");
    const [note ,setNote] = useState("");
    const [contractType ,setContractType] = useState("");
    const [classCodes ,setClassCodes] = useState("");
    const [lastTicketingDate ,setLastTicketingDate] = useState("");
    const [classKey ,setClassKey] = useState("");
    const [isLoading ,setIsLoading] = useState(false)

    const [noDataFlag ,SetNoDataFlag] = useState(false)


    
    // SEARCH PARAMETER
    const [carrier ,setCarrier] = useState("");
    const [tarifKey ,setTarifKey] = useState("");
    const [via ,setVia] = useState("");
    const [passengerType ,setPassengerType] = useState("");
    const [isBrandedFare ,setIsBrandedFare] = useState("");
    
    const [tripType ,setTripType] = useState("");
    const [cabinType ,setCabinType] = useState("");
    const [cruiseFare ,setCruiseFare] = useState("");
    const [landFare ,setLandFare] = useState("");
    



    const [originType ,setOriginType] = useState("");
    const [originValue ,setoriginValue] = useState("");

    const [destinationType ,setDestinationType] = useState("");
    const [destinationValue ,setDestinationValue] = useState("");

       // PAGINATION PARAMETER
       const [isLast, setIsLast] = useState(false);
       const [counter, setCounter] = useState(0);
       const [lastArry, setLastArry] = useState([])
       const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
      //  const { orgId } = useContext(authContext);
       const [searchUrl ,setSearchUrl] = useState('')
       const [classLastTicketingDateFrom ,setClassLastTicketingDateFrom] = useState('')
       const [classLastTicketingDateTo ,setClassLastTicketingDateTo] = useState('')

       const [pk, setPk] = useState('');



    // CLASS SEARCH API CALL
    const classSearchApiCall = () => {
      setLastArry([])
      setCounter(0)
    let strUrl = 'searchClasses?'
    setIsLoading(true)

      if(brandId !== ""){
        let brandIdValue
        if(strUrl.length < 15 ){
            brandIdValue ="brandCode="+brandId
        } else {
            brandIdValue ="&brandCode="+brandId
        }
        strUrl = strUrl+brandIdValue
      } 

      if(category !== ""){
        let categoryValue
        if(strUrl.length < 15 ){
            categoryValue ="category="+category
        } else {
            categoryValue ="&category="+category
        }
        strUrl = strUrl+categoryValue
      } 

      if(clasCodeDesc !== ""){
        let categoryValue
        if(strUrl.length < 15 ){
            categoryValue ="classCodeDesc="+clasCodeDesc
        } else {
            categoryValue ="&classCodeDesc="+clasCodeDesc
        }
        strUrl = strUrl+categoryValue
      }

      if(note !== ""){
        let noteValue
        if(strUrl.length < 15 ){
            noteValue ="classNote="+note
        } else {
            noteValue ="&classNote="+note
        }
        strUrl = strUrl+noteValue
      } 

      if(contractType !== ""){
        let contractTypeValue
        if(strUrl.length < 15 ){
            contractTypeValue ="contractType="+contractType
        } else {
            contractTypeValue ="&contractType="+contractType
        }
        strUrl = strUrl+contractTypeValue
      } 

      if(classLastTicketingDateFrom !== ""){
        let classLastTicketingDateFromVar
        if(strUrl.length < 15 ){
          classLastTicketingDateFromVar ="classLastTicketingDateFrom="+classLastTicketingDateFrom
        } else {
          classLastTicketingDateFromVar ="&classLastTicketingDateFrom="+classLastTicketingDateFrom
        }
        strUrl = strUrl+classLastTicketingDateFromVar
      } 

      if(classLastTicketingDateTo !== ""){
        let classLastTicketingDateTovar
        if(strUrl.length < 15 ){
          classLastTicketingDateTovar ="classLastTicketingDateTo="+classLastTicketingDateTo
        } else {
          classLastTicketingDateTovar ="&classLastTicketingDateTo="+classLastTicketingDateTo
        }
        strUrl = strUrl+classLastTicketingDateTovar
      } 


            
      if(classCodes !== ""){
        let classCodesValue
        if(strUrl.length < 15 ){
            classCodesValue ="classCodes="+classCodes
        } else {
            classCodesValue ="&classCodes="+classCodes
        }
        strUrl = strUrl+classCodesValue
      } 

      if(lastTicketingDate !== ""){
        let lastTicketingDateValue
        if(strUrl.length < 15 ){
            lastTicketingDateValue ="lastTicketingDate="+lastTicketingDate
        } else {
            lastTicketingDateValue ="&lastTicketingDate="+lastTicketingDate
        }
        strUrl = strUrl+lastTicketingDateValue
      } 

      if(classKey !== ""){
        let classKeyValue
        if(strUrl.length < 15 ){
            classKeyValue ="clasKey="+classKey
        } else {
            classKeyValue ="&clasKey="+classKey
        }
        strUrl = strUrl+classKeyValue
      } 

      setSearchUrl(strUrl)

      if(strUrl.length > 15 ){
        fetch(`${process.env.REACT_APP_CONTRACT_URL}/${strUrl}`, {
            method: 'POST',
        })
            .then(response => response.json())
            .then(result =>{
              console.log("result?.LastEvaluatedKey",result?.LastEvaluatedKey)
              setLastEvalatuedValue(result?.LastEvaluatedKey)
              if(result.Items.length === 0){
                SetNoDataFlag(true)
              } else {
                SetNoDataFlag(false)
              }

              if(result.message === undefined){
                setAllClassessData(result.Items) 
                setIsLoading(false)

              } else {
                setAllClassessData([]) 
                setIsLoading(false)

            }
            } )
            .catch(error => console.error("Error: ", error))
      } else {
        fetch(`${process.env.REACT_APP_CONTRACT_URL}/searchClasses`, {
            method: 'POST',
        })
            .then(response => response.json())
            .then(result =>{
              console.log("class",result?.LastEvaluatedKey)
              setLastEvalatuedValue(result?.LastEvaluatedKey)
              if(result.Items.length === 0){
                SetNoDataFlag(true)
              } else{
                SetNoDataFlag(false)
              }
              setPk(result?.LastEvaluatedKey?.pk)
              if(result.message === undefined){
                setAllClassessData(result.Items) 
                setIsLoading(false)

              } else {
                setAllClassessData([]) 
                setIsLoading(false)

            }            } )
            .catch(error => console.error("Error: ", error))
      }

    

       

    }

    // CLEAR CLASS SEARCH
    const clearClassSearch = () => {
        SetNoDataFlag(false)
        setBrandId("");
        setCategory("");
        setClasCodeDesc("");
        setNote("");
        setContractType("");
        setClassCodes("");
        setLastTicketingDate("");
        setClassKey("");
        setAllClassessData([])
        setClassLastTicketingDateFrom('')
        setClassLastTicketingDateTo('')

    }

    

    // REDIRECT TO CLASS UI
    const redeirectToClass = (value) =>{
      const classId = value.classId
      const tariffId =  value.tarifId
      history.push(`/classes/${classId}/${tariffId}`);

    }

    
    
        //PAGINATION FUNCTION
        const paginationNext = async () => {
          setIsLoading(true)

            const url = 
                      searchUrl === '' 
                      ? `${process.env.REACT_APP_CONTRACT_URL}/searchClasses` 
                      : `${process.env.REACT_APP_CONTRACT_URL}/${searchUrl}`;
          
          let requestbody = {
            LastEvaluatedKey: lastArry[counter],
          };
  
          const requestOptions = {
          method: "POST",
  
          headers: {
              "Content-type": "application/json; charset=UTF-8",
  
              "Access-Control-Allow-Origin": "*",
  
              "Access-Control-Allow-Credentials": true,
  
              "cache-control": "no-cache",
          },
  
          body: JSON.stringify(requestbody),
          };
  
          const priceResult = await getApiResponse(url, requestOptions);
  
          if (priceResult) {
              setIsLoading(true)  
              SetNoDataFlag(false)
              setAllClassessData(priceResult.Items) 
              setIsLoading(false)
              // setLastEvaluatedKey(priceResult.LastEvaluatedKey)
              setLastEvalatuedValue(priceResult.LastEvaluatedKey)
  
          }
      };
      
  
      async function getApiResponse(url, requestOptions) {
          const response = await fetch(url, requestOptions);
  
          if (!response.ok) {
          return null;
          } else {
          const data = await response.json();
  
          return data;
          }
      }
  
  
        //PAGINATION FUNCTION
        const paginationPrevious = async () => {
          setIsLoading(true)

            const url = 
                      searchUrl === '' 
                      ? `${process.env.REACT_APP_CONTRACT_URL}/searchTarif` 
                      : `${process.env.REACT_APP_CONTRACT_URL}/${searchUrl}`;
          
          let requestbody = {
          LastEvaluatedKey: lastArry[counter-1],
          };
  
          const requestOptions = {
          method: "POST",
  
          headers: {
              "Content-type": "application/json; charset=UTF-8",
  
              "Access-Control-Allow-Origin": "*",
  
              "Access-Control-Allow-Credentials": true,
  
              "cache-control": "no-cache",
          },
  
          body: JSON.stringify(requestbody),
          };
  
          const priceResult = await getApiResponseForPri(url, requestOptions);
  
          if (priceResult) {
            setAllClassessData(priceResult.Items) 
            setIsLoading(true)  
              SetNoDataFlag(false)
              setIsLoading(false)
  
          }
      };
  
      //PAGINATION FUNCTION
      async function getApiResponseForPri(url, requestOptionsData) {
          const response = await fetch(url, requestOptionsData);
  
          if (!response.ok) {
          return null;
          } else {
          const data = await response.json();
  
          return data;
          }
      }
  
      // PUSHED IN LASTEVALUATED KEY
      const setLastEvalatuedValue = (value) =>{
          let newArry = lastArry
          if (newArry.includes(value)) {
              newArry = newArry.filter((id) => id !== value);
          } else {
              newArry.push(value);
          }
          setLastArry(newArry)
          
      } 

  
      // NEXT CLICK
      const nextClick = () =>{
          setCounter(counter + 1)
          paginationNext()
          // tariffSearchApiCall
      } 
  
        // NEXT CLICK
        const priviousClick = () =>{
            setIsLast(false)
          paginationPrevious()
          setCounter(counter - 1)
      } 
  


    return (
        <div>
        <div className="card cm-card">
            <div className="card-body">

              {/* 
              <div className="row">
                <div className="col-12">
                    <p className="cm-head-2">Tariff Search</p>
                </div>
            </div>
              <div className="row">
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Origin</label>
                  <div className="row">
                      <div className="col-md-5">
                        <select 
                          className="form-control cm-input-select"
                          value={originType}
                          onChange={(e)=>setOriginType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value='originRegion'>Region Code</option>
                          <option value='originCitiesOrAirports'>Airport Or City Code</option>
                          <option value='originCountry'>Country Code</option>
                          <option value='originIATARegion'>IATA Region Code</option>
                        </select>
                      </div>
                      <div className="col-md-7">
                        <input 
                          className="form-control cm-input"
                          placeholder="Enter Origin ..."
                          value={originValue}
                          onChange={(e)=>setoriginValue(e.target.value)}
                        />
                      </div>
                  </div>
              </div>
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Destination</label>
                  <div className="row">
                      <div className="col-md-5">
                        <select 
                          className="form-control cm-input-select"
                          value={destinationType}
                          onChange={(e)=>setDestinationType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value='destinationRegion'>Region Code</option>
                          <option value='destinationCitiesOrAirports'>Airport Or City Code</option>
                          <option value='destinationCountry'>Country Code</option>
                          <option value='destinationIATA'>IATA Region Code</option>
                        </select>
                      </div>
                      <div className="col-md-7">
                        <input 
                          className="form-control cm-input"
                          placeholder="Enter Destination ..."
                          value={destinationValue}
                          onChange={(e)=>setDestinationValue(e.target.value)}
                        />
                      </div>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5 cm-col">
                  <label className="cm-head-label">Tarif Key</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter tarif key"
                      value={tarifKey}
                      onChange={(e)=>setTarifKey(e.target.value)}

                    />
                  </div>
                  <div className="col-md-7 cm-col">
                  <label className="cm-head-label">Validating Carrier</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter validating carrier"
                      onChange={(e)=>setCarrier(e.target.value)}
                      value={carrier}
                    />
                  </div>
                </div>

              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5 cm-col">
                    <label className="cm-head-label">VIA</label>
                      <input 
                        className="form-control cm-input"
                        placeholder="Enter VIA"
                        onChange={(e)=>setVia(e.target.value)}
                        value={via}

                      />
                  </div>
                </div>
              </div>
            </div>

       
            <div className="row">
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Cabin Type</label>
                <div className="cm-radio-inline">
                  <FormControlLabel  control={<Radio color="primary" />} label="Economy"
                   checked={cabinType === "economy" ? true : false} onChange={()=>setCabinType("economy")}/>
                  <FormControlLabel  control={<Radio  color="primary"/>} label="Premimum Economy"  
                    checked={cabinType === "premiumEconomy" ? true : false} onChange={()=>setCabinType("premiumEconomy")} className=""  />{" "}
                  <FormControlLabel  control={<Radio color="primary" />} label="First Class"  
                    checked={cabinType === "first" ? true : false} onChange={()=>setCabinType("first")} className="" />
                  <FormControlLabel  control={<Radio color="primary" />} label="Business"  
                    checked={cabinType === "bussiness" ? true : false} onChange={()=>setCabinType("bussiness")} />{" "}
                  <FormControlLabel  control={<Radio color="primary"/>} label="Premium Business"  
                    checked={cabinType === "premiumBusiness" ? true : false} onChange={()=>setCabinType("premiumBusiness")} />{" "}
               </div>
              </div>
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Trip Type</label>
                <div className="cm-radio-inline">
                  <FormControlLabel  control={<Radio color="primary"/>} label="One Way"
                   checked={tripType === "OneWay" ? true : false } onChange={(e)=>setTripType("OneWay") } />
                  <FormControlLabel  control={<Radio color="primary" />} label="Round Trip / Open Jaw / Multi City"
                   checked={tripType === "Return" ? true : false} onChange={(e)=>setTripType("Return")} />{" "}
                </div>
              </div>

            </div>
            <div className="row ">
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Fare Types</label>
                <div className="cm-checkbox-inline">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={landFare} onChange={(e)=>setLandFare(!landFare)}
                        color="primary"
                      />
                    }
                    label="Land Fare"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cruiseFare} onChange={(e)=>setCruiseFare(!cruiseFare)}
                        color="primary"
                      />
                    }
                    label="Cruise Fare"
                  />
                </div>
              </div>
              <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6 cm-col">
                      <label className="cm-head-label">Branded Fare</label>
                      <div className="cm-radio-inline">
                        <FormControlLabel  control={<Radio  color="primary"/>} label="Yes"
                          checked={isBrandedFare === "1" ? true :false} 
                          onChange={(e)=>setIsBrandedFare("1")} 
                        /> 
                        <FormControlLabel  control={<Radio color="primary" />} label="No"
                          onChange={(e)=>setIsBrandedFare("0") ? true:false } 
                          checked={isBrandedFare === '0'}  
                         />
                      </div>
                    </div>
                    <div className="col-md-6 cm-col">
                      <label className="cm-head-label">Type of Passenger</label>
                      <div className="cm-radio-inline">                   
                        <FormControlLabel  control={<Radio color="primary" />} label="Adult"
                          checked={passengerType === "ADULT" ? true : false} onChange={(e)=>setPassengerType("ADULT")} /> 
                          <FormControlLabel  control={<Radio  color="primary"/>} label="Senior" 
                          checked={passengerType === "SENIOR" ? true : false} onChange={(e)=>setPassengerType("SENIOR")}  />
                        </div>
                    </div>
                  </div>
              </div>
            </div>
             */}
{/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> CLASSS SEARCH SECTION  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
          <div className="row">
            <div className="col-12">
                <p className="cm-head-2">Class Search</p>
            </div>
          </div>

            <div className="row">
              <div className="col-md-4 cm-col">
                <label className="cm-head-label"> Brand id </label>
                <input 
                  className="form-control cm-input"
                  placeholder="Enter Brand Id"
                  value={brandId}
                  onChange={(e)=>setBrandId(e.target.value)}
                />
              </div>
              <div className="col-md-4 cm-col">
                <label className="cm-head-label"> Classe </label>
                <input 
                  className="form-control cm-input"
                  placeholder="Enter Classe"
                  value={classCodes}
                  onChange={(e)=>setClassCodes(e.target.value)}
                />
              </div>        
                <div className="col-md-4 cm-col">
                <label className="cm-head-label">EDR  i.e last ticketing date</label>
                <input 
                  className="form-control cm-input-date"
                  placeholder="Enter Brand Id"
                  type="date"
                  value={lastTicketingDate}
                  onChange={(e)=>setLastTicketingDate(e.target.value)}
                />
              </div>
            </div>
          
            <div className="row">
              <div className="col-md-4 cm-col">
                <label className="cm-head-label">Category </label>
                <input 
                  className="form-control cm-input"
                  placeholder="Enter category"
                  value={category}
                  onChange={(e)=>setCategory(e.target.value)}
                />
              </div>
              <div className="col-md-4 cm-col">
                <label className="cm-head-label">Desc</label>
                <input 
                  className="form-control cm-input"
                  placeholder="Enter Desc"
                  value={clasCodeDesc}
                  onChange={(e)=>setClasCodeDesc(e.target.value)}
                />
              </div>          
              <div className="col-md-4 cm-col">
                <label className="cm-head-label">NOTE</label>
                <input 
                  className="form-control cm-input"
                  placeholder="Enter note"
                  type="text"
                  value={note}
                  onChange={(e)=>setNote(e.target.value)}
                />
              </div>
            </div>
            {/* FARE TYPE */}
            <div className="row">
                <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Class Key</label>
                    <input 
                        className="form-control cm-input"
                        placeholder="Enter class key"
                        type="text"
                        value={classKey}
                        onChange={(e)=>setClassKey(e.target.value)}
                        />
                </div>
                <div className="col-md-6 cm-col">
                <label className="cm-head-label">Fare Type</label>
                <div className="cm-radio-inline">
                 <FormControlLabel control={<Radio color="primary" />} label="(%) Published"  checked={contractType === 'Published' ? true : false} onChange={()=>setContractType('Published')} /> 
                 <FormControlLabel control={<Radio color="primary" />}  label="($) Net" checked={contractType === 'Net'? true : false} onChange={()=>setContractType("Net")} /> 
                 </div>
              </div>
            </div>
                {/* EDR DATE */}
                 {/* EDR DATE */}
                 <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5 cm-col">
                  <label className="cm-head-label">EDR Start Date</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter date"
                      type="date"
                      value={classLastTicketingDateFrom}
                      onChange={(e)=>setClassLastTicketingDateFrom(e.target.value)}
                    />
                  </div>
                  <div className="col-md-7 cm-col">
                  <label className="cm-head-label">EDR End Date</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter date"
                      type="date"
                      value={classLastTicketingDateTo}
                      onChange={(e)=>setClassLastTicketingDateTo(e.target.value)}
                    />
                  </div>
                </div>

              </div>
         
            </div>

               {/* BUTTON SECTION */}
            <div className="row cm-col">
              <div className="col-md-12 text-right">
                <button 
                  className="cm-secondary-btn mr-2" 
                  onClick={(e)=>clearClassSearch()}
                >
                  Clear
                </button>
                <button 
                  className="cm-primary-btn" 
                  onClick={(e)=>classSearchApiCall()}
                  disabled={isLoading}
                  >
                    {isLoading && <span className="mr-5 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
  
                  Search
                </button>
              </div>
            </div>
            </div>
            </div>

            {noDataFlag && 
                <div className="alert alert-info mt-2" role="alert">
                    No Results found for the selected criteria.
                </div>
            }
              {isLoading ? <div style={{justifyContent:'center'}}>
            <span className="badge badge-light mt-3 ml-3 mb-3"><span className="text-danger">Loading ...</span> </span>
              </div>
              :
              <div>
            {allClassessData.length > 0 && 
            <div className="cm-mui-table-sm">
            <TableContainer component={Paper} className="mt-4">
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Branded Fare </TableCell>
            <TableCell>Fare Type</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>EDR</TableCell>
            <TableCell>Desc</TableCell>
            <TableCell>Carrier</TableCell>
            <TableCell>Category</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allClassessData.length === 0 ?
            <TableRow>
                <TableCell rowSpan="5">
                    No records found.
                </TableCell>
              </TableRow>
            :
          allClassessData.map((classData,index)=>{
            return(
            <TableRow key={index} onClick={(e)=>redeirectToClass(classData)}>
              <TableCell component="th" scope="row">                 { classData.brandCode }
                
              </TableCell>
              <TableCell>{classData.contractType}</TableCell>
              <TableCell >{classData.classNote}</TableCell>
              <TableCell >{classData && classData.classLastTicketingDate && classData.classLastTicketingDate}</TableCell>
              <TableCell >{classData.classCodeDesc}</TableCell>
              <TableCell >{classData &&  classData.SK && classData.SK.slice(0, 2)}</TableCell>
              <TableCell >{classData &&  classData.ageRule &&  classData.ageRule.category}</TableCell>
            </TableRow>
            )
          })
        }
        </TableBody>
      </Table>
    </TableContainer>
       {/* PAGINATION  SECTION */}
       <div className="d-flex justify-content-between mt-3">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className= {counter === 0 ? "page-item disabled":"page-item " }>
              <a className="page-link" href="#"  tabIndex={-1} 
                onClick={() =>priviousClick()}

              >
                
                Previous
              </a>
            </li>
          </ul>
        </nav>
        
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className= {isLast  ? "page-item  disabled" : "page-item "}>
              <a
              href="#"
                className="page-link"
                onClick={() =>nextClick()}
                tabIndex={-1}
              >
                Next
                
              </a>
            </li>
          </ul>
        </nav>
      </div>
            </div>
            }
              
            </div>
          }
        </div>
    )
}
