//@ts-nocheck
import {SET_MYFILESERROR} from '../storeConstant';
   
    
export const setMyFileError = (value) => {
    return {
        type: SET_MYFILESERROR,
        payload: value
    }
}
