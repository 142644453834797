import axios from 'axios';
import swal from 'sweetalert';

export const getOnePrix = async (prixKey: any) => {
        let url: any = process.env.REACT_APP_CONTRACT_URL + `/pricesV2/${prixKey}`;
        const requestOptions: any = {
            headers: {
                "Content-type": "application/json",
            },
        }
        const getOnePrixDetails:any = await axios.get(url, requestOptions)

        if (getOnePrixDetails === null) {
            swal({
                text:"Fetching Prix Details Failed",
                icon: "error",
              })
        }
        return getOnePrixDetails
    
}