//@ts-nocheck
import React,{useEffect, useState} from "react";
import Searchbooking from "./Searchbooking";
import Listbooking from "./Listbooking";
import { Tabs, Tab } from "react-bootstrap";
import "./sass/main.scss";

//import hamburger_menu from "../../../Assets/hamburger_menu.svg";

import { getMyFiles } from "../bookingConfirmation/thunks/getMyFiles";
import { useDispatch } from "react-redux"
import { searchApiOrgId } from "../bookingConfirmation/Utils/Constant";
import { formattedDate } from "../bookingConfirmation/Utils/CommenFunction";
import { useSelector } from "react-redux";
import {setMyFilesData} from "../bookingConfirmation/ActionCreator/myFiles";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import ToastMessages from "../../../utils/utility/ToastedMessage";
import {msg} from '../bookingConfirmation/Utils/MessagesContainer'


export default function HeaderMyfiles() {
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.userInfo.orgId ?? "");
  const agencyId = useSelector((state) => state.agentProfile.agencyId);
  const agencyGroupId = useSelector((state) => state.agentProfile.agencyGroupId);  
  const agentId = useSelector((state) => state.agentProfile.agentId);
  
  const [tabName,setTabName] = useState("sortby")
  const [agencyFlag,setAgencyFlag] = useState(true)
  
  useEffect(() => {

    dispatch(setMyFilesData([]))
}, []);

  const getTabName=(k)=>{
    setTabName(k)
  }
  return (
    <>
      
      <div className="booking-main newComp">
        
        <div className="container myfilesc bd-main-container white-space-manage">
          <div className=" container topheader">
            <div>
              <i className="fas fa-arrow-left"></i>{" "}
            </div>
            <div>
              <a className="navbar-brand mx-auto">My Files</a>
            </div>
            <div>
              {/* <img className="righttogglem" src={hamburger_menu} /> */}
            </div>
          </div>
          {/* <Tabs defaultActiveKey="sortby" id="uncontrolled-tab-example" className="firsttabmy"
          onSelect={ getTabName}> */}
            {/* <Tab eventKey="sortby" title="Sort By"> */}
            <div className="firsttabmy">
                <Searchbooking keyName={tabName} />
            </div>
            {/* </Tab> */}
            {/* <Tab eventKey="search_by" title="Search By">
                <Listbooking keyName={tabName} />
            </Tab> */}
          {/* </Tabs> */}
        </div>
      </div>
      
        
      

    </>
  );
}
