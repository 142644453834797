import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, Checkbox, FormControlLabel, Radio, Tab, Button, MenuItem, Select } from '@mui/material';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import icon_child from '../../_theme/_assets/images/icon__child.svg';
import icon_adult from '../../_theme/_assets/images/icon_adult.svg';
import icon_childfriendly from '../../_theme/_assets/images/icon_sharp-child-friendly.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getOneTarif } from '../thunks/getOneTarif';
import { useHistory } from 'react-router-dom';



const NewPrix = () => {
    const history = useHistory();
    const tarifData = useSelector((state) => state.commonDataReducer.tarifData);
    const classData = useSelector((state) => state.commonDataReducer.classData);
    const prixDetails = useSelector((state)=>state.commonDataReducer.prixDetail)
    const [disableEdit, setDisableEdit] = useState(true)
    const [prixDetailsLocal, setPrixDetailsLocal] = useState({})
    const [tarifValues, setTarifValues]= React.useState({});
    useEffect(()=>{
        // setPrixDetailsLocal({...prixDetails})
        let temp = prixDetails?.tourCode
        let combinedObj = {}
        temp.forEach(({marketCode, tourCodes, passengerType}) => {
            if(combinedObj[marketCode]){
                combinedObj[marketCode]["passengerType"].push(passengerType);
            }else{
                combinedObj[marketCode] = {
                    tourCodes,
                    marketCode,
                    passengerType: [passengerType]
                }
            }
        })
        let localprixDetailsLocal = {...prixDetails}
        localprixDetailsLocal['tourCode'] = Object.values(combinedObj)
        setPrixDetailsLocal(localprixDetailsLocal)
        
    },[])
    
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    useEffect(async()=>{
        if(tarifData?.tarifKey){
            let data=await getOneTarif(tarifData.tarifKey);
            if(data.data){
                setTarifValues(data.data);
            }
        }

    },[tarifData])

    return (
        <>
            <div className="cm-head-2 mb-8">
                <div className="d-flex justify-content-between">
                    <span style={{"fontSize":"1rem","color":"#0055b8","cursor":"pointer"}} onClick={()=>{history.push('/newcontractlist')}}>{`<<- Back to Results`}</span>
                    <span className="cm__Title">Prix Edit</span>
                    <span>
                        {/* <button className='classEditBtn' onClick={()=>setDisableEdit(!disableEdit)}> */}
                            {!Boolean(process.env.REACT_APP_EDIT_CREATE_CONTRACT_MANAGEMENT) ? <i className="fas fa-edit" style={{ "color": "#0055b8", "cursor":"pointer" }} onClick={()=>setDisableEdit(!disableEdit)}></i> : null}
                        {/* </button> */}
                    </span>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="classTopEditSetion">
                        <div className="classTopField">
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label" >Tariff key</label>
                                </div>
                                <label style={{"cursor": "pointer"}} onClick={()=>history.push('/newTariff')}>{tarifData?.tarifKey}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Carrier</label>
                                </div>
                                <label>{tarifData?.carrier}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Origin</label>
                                </div>
                                <label>{tarifData?.origins}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Destination</label>
                                </div>
                                <label>{tarifData?.destinations}</label>


                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Cabin Type</label>
                                </div>
                                <label>{tarifValues.cabinType?.join('\n')}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Trip Type</label>
                                </div>
                                <label>{tarifValues?.tripType == "OW"? "One Way" : "Not One Way"}</label>


                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Branded Fare</label>
                                </div>
                                <label>{tarifData?.isBrandedFare == 1 ? "Yes" : "No"}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Contract Type</label>
                                </div>
                                <label>{tarifValues.cruiseFare == 1 ? "Cruise" : tarifValues.landFare == 1 ? "Land" : "Air Only"}</label>


                            </div>
                        </div>
                        <div className="classTopEdit">
                            <button className='classEditBtn'>
                                <i className="fas fa-edit" onClick={()=>history.push('/newTariff')}></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className="classTopEditSetion PrixTopBorder">
                        <div className="classTopField">
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Class key</label>
                                </div>
                                <label style={{"cursor": "pointer"}} onClick={()=>history.push('/newClass')}>{classData?.clasKey}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Classes</label>
                                </div>
                                <label>{classData?.classCodes?.join(', ')}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Brand</label>
                                </div>
                                <label>{classData?.brandCode}</label>
                            </div>
                            {/* <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Destination</label>
                                </div>
                                <label>000, 001</label>
                            </div> */}
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Fare Type</label>
                                </div>
                                <label>{classData?.contractType}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Min / Max</label>
                                </div>
                                <label>{classData?.minmaxStay}</label>


                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Ticketing Fee</label>
                                </div>
                                <label>{classData?.ticketingFee}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">EDR Date</label>
                                </div>
                                <label>{moment.utc(classData?.classLastTicketingDate).format("YYYY-MM-DD")}</label>


                            </div>
                        </div>
                        <div className="classTopEdit">
                            <button className='classEditBtn'>
                                <i className="fas fa-edit" onClick={()=>history.push('/newClass')}></i>
                            </button>
                        </div>
                    </div>
                </div>



            </div>

            <div className="Tariff__Section Prix__section">
                <div >
                    <div className="card Tariff__BorderB">
                        <div className="card-body">
                            <div className="row mt-4">
                                <div className="col-md-6 cm-col">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Valid From</label>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={moment(new Date(prixDetailsLocal?.validFrom)).format("DD-MMM-yyyy")} onChange={() => { }} disabled={disableEdit}/>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Valid To</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={moment(new Date(prixDetailsLocal?.validTo)).format("DD-MMM-yyyy")} onChange={() => { }} disabled={disableEdit}/>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 cm-col">

                                    <div className="row">
                                        <div className="col-md-6">

                                            <label className="cm-head-label">Last Ticketing Date</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={moment(new Date(prixDetailsLocal?.prixLastTicketingDate)).format("DD-MMM-yyyy")} onChange={() => { }} disabled={disableEdit}/>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Last Return Date</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={moment(new Date(prixDetailsLocal?.prixLastReturnDate)).format("DD-MMM-yyyy")} onChange={() => { }} disabled={disableEdit}/>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Prix Note</label>
                                            <input className="form-control cm-input" placeholder="Enter Note" value={prixDetailsLocal?.prixNote} disabled={disableEdit}/>
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label"></label>
                                            <FormControlLabel control={<Checkbox checked={Boolean(prixDetailsLocal?.noTKTBY)}/>} label="No TKT-BY" disabled={disableEdit}/>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 20, borderColor: '#E1F0FF', background: '#fff' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Prix" value="1" />
                                    <Tab label="Exception" value="2" />
                                    <Tab label="Emission (Segments)" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Prix</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-4 prix_iconBox">
                                                        <img src={icon_adult} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR COMMISSION</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.commissionPercentage} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">

                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR MARKUP</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.markup} disabled={disableEdit}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label"> AGENCY COMMISSION</label>
                                                            <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.passBackCommission} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">MIN SELL</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.minumumSellingPrice} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Prix</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-4 prix_iconBox">
                                                        <img src={icon_child} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR COMMISSION</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.childCommissionPercentage} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">

                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR MARKUP</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.childMarkUp} disabled={disableEdit}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label"> AGENCY COMMISSION</label>
                                                            <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.childPassBackCommission} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">MIN SELL</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.minumumSellingPrice} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Prix</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-4 prix_iconBox">
                                                        <img src={icon_childfriendly} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR COMMISSION</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.infantCommissionPercentage} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">

                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">OUR MARKUP</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.infantMarkUp} disabled={disableEdit}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label"> AGENCY COMMISSION</label>
                                                            <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.infantPassBackCommission} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">MIN SELL</label>
                                                            <input className="form-control cm-input" type="text" placeholder='Enter $' value={prixDetailsLocal?.minumumSellingPrice} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body py-0">
                                        <div className='row'>
                                            <div className='col-md-6 prix_borderRight py-6'>
                                                <div className=" cm-head-2 mb-4 mr-8">
                                                    <div className="d-flex justify-content-between">
                                                        <span className="">Navcan (Surcharges)</span>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_adult} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.adultCommissionOnYQNav} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.adultPassBackCommissionOnYQNav} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6 py-6'>
                                                <div className=" cm-head-2 mb-4">
                                                    <div className="d-flex justify-content-between">
                                                        <span className="">Carburant</span>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_adult} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.adultCommissionOnYQ} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.adultPassBackCommissionOnYQ} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body py-0">
                                        <div className='row'>
                                            <div className='col-md-6 prix_borderRight py-6'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_child} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.childCommissionOnYQNav}disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.childPassBackCommissionOnYQNav} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6 py-6'>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_child} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.childCommissionOnYQ} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.childPassBackCommissionOnYQ} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body py-0">
                                        <div className='row'>
                                            <div className='col-md-6 prix_borderRight py-6'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_childfriendly} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.enfantCommissionOnYQNav} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.enfantPassBackCommissionOnYQNav} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6 py-6'>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-4 prix_iconBox">
                                                                <img src={icon_childfriendly} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">OUR COMMISSION</label>
                                                                    <input className="form-control cm-input" type="text" placeholder='Enter %' value={prixDetailsLocal?.enfantCommissionOnYQ} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="cm-col">
                                                                    <label className="cm-head-label">AGENCY COMMISSION</label>
                                                                    <input className="form-control cm-input cm__inputW80" type="text" placeholder='Enter %' value={prixDetailsLocal?.enfantPassBackCommissionOnYQ} disabled={disableEdit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>








                            </TabPanel>

                            <TabPanel value="2" className='m-0 p-0'>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Blackout Dates</span>
                                            </div>
                                        </div>
                                        {prixDetailsLocal?.blackOutDates?.length ? prixDetailsLocal?.blackOutDates?.map((blackOutDateValue)=>{return(<>
                                            <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Start Date </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={blackOutDateValue.fromDate} onChange={() => { }} disabled={disableEdit}/>
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">End Date </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" value={blackOutDateValue.toDate} onChange={() => { }} disabled={disableEdit}/>
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackOutDateValue.aller)} disabled={disableEdit}/>} label="Departure" />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackOutDateValue.retour)} disabled={disableEdit}/>} label="Return" />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker' value={blackOutDateValue.exceptionType} disabled={disableEdit}>
                                                                <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn " disabled={disableEdit}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>
                                        )}) : <>
                                        <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Start Date </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" disabled={disableEdit} value={null} onChange={() => { }} />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">End Date </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker className='BulkUpdate__DatePicker' format="dd MMM yyyy" disabled={disableEdit} value={null} onChange={() => { }} />
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Departure" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Return" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker' disabled={disableEdit}>
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn" disabled={disableEdit}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>
                                        }
                                    </div>
                                </div>


                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Blackout Days</span>
                                            </div>
                                        </div>
                                        {prixDetailsLocal?.blackOutDays?.length ?  prixDetailsLocal?.blackOutDays?.map((blackOutDayValue)=>{return(<>
                                            <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Days </label>
                                                <input className="form-control cm-input" value={blackOutDayValue?.day} disabled={disableEdit}/>
                                            </div>


                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackOutDayValue?.aller)} disabled={disableEdit}/>} label="Departure" />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={Boolean(blackOutDayValue?.retour)} disabled={disableEdit}/>} label="Return" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker' value={blackOutDayValue?.exceptionType} disabled={disableEdit}>
                                                                <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                        </Select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label"> </label>
                                                <div className="row">
                                                    <div className="col-md-7">

                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn " disabled={disableEdit}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>)}) : <>
                                        <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Days </label>
                                                <input className="form-control cm-input" disabled={disableEdit}/>
                                            </div>


                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Departure" />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Return" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker'disabled={disableEdit}>
                                                                    <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                    <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                    <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                        </Select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label"> </label>
                                                <div className="row">
                                                    <div className="col-md-7">

                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn " disabled={disableEdit}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                            }
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Blackout Airlines</span>
                                            </div>
                                        </div>
                                        {prixDetailsLocal?.blackOutCarrier?.length ?  prixDetailsLocal?.blackOutCarrier?.map((blackOutAirlineValue)=>{return(<>
                                            <div className="row">
                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Carrier Code </label>
                                                <input className="form-control cm-input" value={blackOutAirlineValue?.carrierCode} disabled={disableEdit}/>
                                            </div>
                                            <div className="col-md-3 cm-col">
                                                <div className='row'>
                                                    <div className="col-md-6 cm-col">
                                                        <label className="cm-head-label">From </label>
                                                        <input className="form-control cm-input" value={blackOutAirlineValue?.flightNumberFrom} disabled={disableEdit}/>
                                                    </div>
                                                    <div className="col-md-6 cm-col">
                                                <label className="cm-head-label">To</label>
                                                <input className="form-control cm-input" value={blackOutAirlineValue?.flightNumberTo} disabled={disableEdit}/>
                                            </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <label className="cm-head-label"> </label>

                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={blackOutAirlineValue?.aller} disabled={disableEdit}/>} label="Departure" />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <FormControlLabel control={<Checkbox color="primary" checked={blackOutAirlineValue?.retour} disabled={disableEdit}/>} label="Return" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-3 cm-col">
                                                <label className="cm-head-label">Exception Type</label>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <Select className='BulkUpdate__DatePicker' value={blackOutAirlineValue?.exceptionType} disabled={disableEdit}>
                                                                <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn " disabled={disableEdit}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>)}):
                                                <>
                                                <div className="row">
                                                <div className="col-md-3 cm-col">
                                                    <label className="cm-head-label">Carrier Code </label>
                                                    <input className="form-control cm-input"  disabled={disableEdit}/>
                                                </div>
                                                <div className="col-md-3 cm-col">
                                                    <div className='row'>
                                                        <div className="col-md-6 cm-col">
                                                            <label className="cm-head-label">From </label>
                                                            <input className="form-control cm-input" disabled={disableEdit}/>
                                                        </div>
                                                        <div className="col-md-6 cm-col">
                                                    <label className="cm-head-label">To</label>
                                                    <input className="form-control cm-input" disabled={disableEdit}/>
                                                </div>
                                                    </div>
                                                </div>
    
                                                <div className="col-md-3">
                                                    <label className="cm-head-label"> </label>
    
                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Departure" />
                                                        </div>
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" disabled={disableEdit}/>} label="Return" />
                                                        </div>
    
                                                    </div>
                                                </div>
    
                                                <div className="col-md-3 cm-col">
                                                    <label className="cm-head-label">Exception Type</label>
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <Select className='BulkUpdate__DatePicker' disabled={disableEdit}>
                                                                    <MenuItem value={'BlackOut'}>BlackOut</MenuItem>
                                                                    <MenuItem value={'Reduction'}>Reduction</MenuItem>
                                                                    <MenuItem value={'Supplement'}>Supplement</MenuItem>
                                                            </Select>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className='setD-flex'>
                                                                <Button className="BulkUpdate__removeBtn " disabled={disableEdit}><i className="fas fa-trash-alt"></i></Button>
                                                                <Button className='BulkUpdate__AddBtn' disabled={disableEdit}><i className="fas fa-plus-circle"></i></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></>
                                        }
                                    </div>
                                </div>

                            </TabPanel>

                            <TabPanel value="3" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Endorsement</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">VIT</label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.VIT} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">SKY </label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.SKY} disabled={disableEdit}/>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">DFW</label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.DFW} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">FUN</label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.FUN} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">TC</label>
                                                    <input className="form-control cm-input" type="text" value={prixDetailsLocal?.endorsement?.TC} disabled={disableEdit}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Tour Code</span>
                                            </div>
                                        </div>
                                        {prixDetailsLocal?.tourCode?.length ? prixDetailsLocal?.tourCode?.map((tour)=>{return(<>
                                            <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Office</label>
                                                            <input className="form-control cm-input" type="text" value={tour?.marketCode} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Adult</label>
                                                            <input className="form-control cm-input" type="text" value={tour?.passengerType?.includes("ADULT") ? tour?.tourCodes : null} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Child</label>
                                                            <input className="form-control cm-input" type="text" value={tour?.passengerType?.includes("CHILD") ? tour?.tourCodes : null} disabled={disableEdit} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Infant</label>
                                                            <input className="form-control cm-input" type="text" value={tour?.passengerType?.includes("INFANT") ? tour?.tourCodes : null} disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                    <label className="cm-head-label"></label>
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn " disabled={disableEdit}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>)}) : 
                                        <>
                                            <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                     
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Office</label>
                                                            <input className="form-control cm-input" type="text" disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Adult</label>
                                                            <input className="form-control cm-input" type="text" disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Child</label>
                                                            <input className="form-control cm-input" type="text" disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="cm-col">
                                                            <label className="cm-head-label">Infant</label>
                                                            <input className="form-control cm-input" type="text" disabled={disableEdit}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                    <label className="cm-head-label"></label>
                                                        <div className='setD-flex'>
                                                            <Button className="BulkUpdate__removeBtn " disabled={disableEdit}><i className="fas fa-trash-alt"></i></Button>
                                                            <Button className='BulkUpdate__AddBtn' disabled={disableEdit}><i className="fas fa-plus-circle"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        </>
                                            }
                                    </div>
                                </div>
                                
                            </TabPanel>
                        </TabContext>
                        {disableEdit ? null : <Box className="cm-card-edit" sx={{ background: '#E1F0FF', padding: '16px', mb: '0' }}>
                            <button className="cm-secondary-btn mr-2" type="button" onClick={()=>setDisableEdit(true)}>Cancel</button>
                            <button className="cm-primary-btn" ><i className='fa fa-save mr-1'></i> Save</button>
                        </Box>}
                    </Box>



                </div>
            </div >
        </ >
    )
}

export default NewPrix