import { useEffect, useState } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import {  Alert } from "react-bootstrap";

export default function Characteristics(props) {
  // const [editFlag ,setEditFlag] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [characteristicsData, setCharacteristicsData] = useState({});
  const [editFlag, setEditFlag] = useState(false);
  const [alert, setAlert] = useState({ variant: 'success', show: false, message: '' });

  const [formsOfPayment, setFormsOfPayment] = useState([]);
  const [maximumFeeCCBycarrierType, setMaximumFeeCCBycarrierType] = useState('');
  const [maximumFeeCCAmoutByCarrier, setMaximumFeeCCAmoutByCarrier] = useState('');
  const [calculationBasis, setCalculationBasis] = useState('');
  const [ccFeesForTBMerchant, setCcFeesForTBMerchant] = useState('');
  const [distributionChannels, setDistributionChannels] = useState('');
  const [renderFlag, setRenderFlag] = useState(false)





  const selectEdit = () => {
    setEditFlag(true)
    setJsonData(characteristicsData)
    setFormsOfPayment(characteristicsData.formsOfPayment)
    setMaximumFeeCCBycarrierType(characteristicsData.maximumFeeCCBycarrierType)
    setMaximumFeeCCAmoutByCarrier(characteristicsData.maximumFeeCCAmoutByCarrier)
    setCalculationBasis(characteristicsData.calculationBasis)
    setCcFeesForTBMerchant(characteristicsData.ccFeesForTBMerchant)
    setDistributionChannels(props.tariffData?.distributionChannels)
  }

  // FORMS OF PAYMENT FUNCTION
  const setFormsOfPaymentFunction = (e, value) => {
    setRenderFlag(!renderFlag)
    let newFormsOfPayment = formsOfPayment
    if (newFormsOfPayment.includes(value)) {
      newFormsOfPayment = newFormsOfPayment.filter(id => id !== value);
      setFormsOfPayment(newFormsOfPayment)
    } else {
      newFormsOfPayment.push(value);
      setFormsOfPayment(newFormsOfPayment)
    }
  }

  // SET DISTRIBUTED VALUE FUNCTION
  const setDisburtedValueFunction = (value) => {
    setRenderFlag(!renderFlag)
    let newDistributionChannels = distributionChannels
    if (newDistributionChannels.includes(value)) {
      newDistributionChannels = newDistributionChannels.filter(id => id !== value);
      setDistributionChannels(newDistributionChannels)
    } else {
      newDistributionChannels.push(value);
      setDistributionChannels(newDistributionChannels)
    }
  }


  // ONCHANGE VALUES FUNCTIONS
  const editOncahgeFunction = (e, type) => {
    let charValues = jsonData
    setJsonData({ ...charValues, [type]: e.target.value })
    console.log("charValues",charValues)
  }
  // ONCHANGE VALUES FUNCTIONS
  const editOncahgeFunctionCheckbox = (value, type) => {
    // let usingFilter = () => {  
    // return unique = charValues.formsOfPayment.filter(function(item,index){  
    //       return charValues.formsOfPayment.indexOf(item) == index;  
    //    });  
    // }  
    // console.log(usingFilter()) 
    // if(charValues.formsOfPayment.includes(value)){
    //   charValues.formsOfPayment.splice(value)
    //   setJsonData(charValues)
    // } else {
    // setJsonData({...charValues})
    // }

  }

  // GET Characteristics LIST API CALL 
  useEffect(() => {
    // fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${props.tariffId}/characteristic`, {
    //   method: 'GET',
    // })
    //   .then(response => response.json())
    //   .then(result => setCharacteristicsData(result))
    //   .catch(error => console.error("Error: ", error))
    setCharacteristicsData(props?.tariffData?.characteristic)
  }, [props.tariffData.characteristic]);

  // UPDATE CHARCTERRISTICS
  const updateCharactersticsData = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,

      },
      body:
        JSON.stringify({
          characteristic:{
            formsOfPayment:formsOfPayment,
            calculationBasis:calculationBasis,
            maximumFeeCCAmoutByCarrier:maximumFeeCCAmoutByCarrier,
            ccFeesForTBMerchant:ccFeesForTBMerchant,
            maximumFeeCCBycarrierType:maximumFeeCCBycarrierType
          },
          distributionChannels:distributionChannels
        })
    }
    fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${props.tariffId}`, requestOptions)
      .then(response => response.json())
      // console.log("response",response)
      .then(result => {
        setAlert({ variant: 'success', show: true, message: 'Tariff updated   successfully.' });
        setEditFlag(false)
      })
      .catch(error => console.error("Error: ", error))
    setEditFlag(false)

  }


  return (
    <div className="card cm-card">
      <div className="card-body">
      <Alert
                variant={alert.variant}
                show={alert.show}
                onClose={() => setAlert({ ...alert, show: false })}
                dismissible
            >
            {alert.message}
            </Alert>
      {props.tariffData.source !== undefined &&
        <div className="cm-card-edit">
          {editFlag ?
            <>
              <button className="cm-secondary-btn mr-2" type="button" onClick={(e) => setEditFlag(false)}>Cancel</button>
              <button className="cm-primary-btn" onClick={(e) => updateCharactersticsData()}> Save</button>
            </>
            :
            <i style={{ cursor: 'pointer', color: '#0465ac' }} onClick={(e) => selectEdit(true)} class="far fa-edit"></i>
          }
        </div>
        }
        <div className="row">
          <div className="col-md-6">
            <div className="cm-col">
              <label className="cm-head-label">FOP allowed (by Airline) for Ticket issuance</label>
              <div className="cm-checkbox-inline">
                {editFlag ?
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formsOfPayment.includes("CHEQUE") ? true : false}
                        color="primary"
                        onClick={(e) => setFormsOfPaymentFunction(e, 'CHEQUE')}
                      />
                    }
                    label="Cheque"
                  />
                  :
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={characteristicsData.formsOfPayment && characteristicsData.formsOfPayment.includes("CHEQUE") ? true : false}
                        color="primary"
                      />
                    }
                    label="Cheque"
                  />
                }

                {editFlag ?
                  <FormControlLabel
                    control={
                      <Checkbox
                      onClick={(e) => setFormsOfPaymentFunction(e, 'CREDITCARD')}
                        checked={formsOfPayment.includes("CREDITCARD") ? true : false}
                        color="primary"
                      />
                    }
                    label="Credit Card"
                  />
                  :
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={characteristicsData.formsOfPayment && characteristicsData.formsOfPayment.includes("CREDITCARD") ? true : false}
                        color="primary"
                      />
                    }
                    label="Credit Card"
                  />
                }

{editFlag ?
                  <FormControlLabel
                    control={
                      <Checkbox
                      onClick={(e) => setFormsOfPaymentFunction(e, 'CREDITINTERNAL')}
                        checked={formsOfPayment.includes("CREDITINTERNAL") ? true : false}
                        color="primary"
                      />
                    }
                    label="Credit  Internal"
                  />
                  :
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={characteristicsData.formsOfPayment && characteristicsData.formsOfPayment.includes("CREDITINTERNAL") ? true : false}
                        color="primary"
                      />
                    }
                    label="Credit Internal"
                  />
                }

              </div>
      
            </div>
          </div>
          <div className="col-md-6">
            <div className="cm-col">
              <label className="cm-head-label">Maximum Authorized amount on Airline as Merchant </label>
              <div className="cm-radio-inline">
                {editFlag ?
                  <>
                    <FormControl component="fieldset">
                      <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                        <FormControlLabel 
                          color="primary" 
                          value="true" 
                          control={<Radio color="primary" />} 
                          label="($)" 
                          onChange={()=>setMaximumFeeCCBycarrierType(!maximumFeeCCBycarrierType)}
                          checked={maximumFeeCCBycarrierType}
                          />
                        <FormControlLabel 
                          color="primary" 
                          value="false" 
                          control={<Radio color="primary" />}
                          label="(%)" 
                          checked={!maximumFeeCCBycarrierType}
                          onChange={()=>setMaximumFeeCCBycarrierType(!maximumFeeCCBycarrierType)}
                         />

                      </RadioGroup>
                    </FormControl>
                  </>
                  :
                  <>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="($)"
                      disabled
                      checked={characteristicsData && characteristicsData.maximumFeeCCBycarrierType}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label=" (%) "
                      disabled
                      checked={characteristicsData && !characteristicsData.maximumFeeCCBycarrierType}
                    />
                  </>
                }
                <label>
                  {!editFlag ?
                    // <strong>
                    //   {characteristicsData.maximumFeeCCAmoutByCarrier}
                    // </strong>
                    <input
                      type="text"
                      className="form-control cm-input cm-amount-field"
                      placeholder="Enter Amount"
                      value={characteristicsData.maximumFeeCCAmoutByCarrier}
                      disabled
                    />
                    :
                    <>
                      <input
                        type="text"
                        className="form-control cm-input cm-amount-field"
                        placeholder="Enter Amount"
                        onChange={(e) => editOncahgeFunction(e, "maximumFeeCCAmoutByCarrier")}
                        value={jsonData.maximumFeeCCAmoutByCarrier}
                      />
                    </>
                  }
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="cm-col">
              <label className="cm-head-label">COMBINATION</label>
              <div className="cm-radio-inline">
                {editFlag ?
                  <>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Average"
                      onChange={() => {setCalculationBasis("AVERAGE")}}
                      value="AVERAGE"
                      checked={calculationBasis === "AVERAGE" ? true : false}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Lowest"
                      onChange={() => setCalculationBasis("MINIMUM")}
                      value="MINIMUM"
                      checked={calculationBasis === "MINIMUM" ? true : false}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Highest"
                      value="MAXIMUM"
                      onChange={() => setCalculationBasis("MAXIMUM")}
                      checked={calculationBasis === "MAXIMUM" ? true : false}
                    />
                  </>
                  :
                  <>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Average"
                      disabled
                      checked={characteristicsData.calculationBasis && characteristicsData.calculationBasis === "AVERAGE" ? true : false}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Lowest"
                      disabled
                      checked={characteristicsData.calculationBasis && characteristicsData.calculationBasis === "MINIMUM" ? true : false}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Highest"
                      disabled
                      checked={characteristicsData.calculationBasis && characteristicsData.calculationBasis === "MAXIMUM" ? true : false}
                    />
                  </>
                }
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="cm-col">
              <label className="cm-head-label">Credit Card Processing Fees</label>
              {editFlag ?
                <>
                  <input
                    className="form-control cm-input cm-amount-field"
                    onChange={(e) => setCcFeesForTBMerchant(e.target.value)}
                    value={ccFeesForTBMerchant}
                    placeholder="Enter value" />
                </>
                :
                <div>
                  <input
                    className="form-control cm-input cm-amount-field"
                    value={characteristicsData.ccFeesForTBMerchant && characteristicsData.ccFeesForTBMerchant}
                    placeholder="Enter value" 
                    disabled
                    />
                  </div>
              }
            </div>
          </div>
          <div className="col-md-6">
            <div className="cm-col">
              <label className="cm-head-label">Can not be distributed</label>
              {editFlag ?
                <div className="cm-checkbox-inline">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={distributionChannels.includes("INTERNAL") ? true : false}
                        color="primary"
                        
                      />
                    }
                    onChange={(e)=>setDisburtedValueFunction("INTERNAL")}
                    label="Internal Users"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={distributionChannels.includes("EXTERNAL") ? true : false}
                        color="primary"
                      />
                    }
                    onChange={(e)=>setDisburtedValueFunction("EXTERNAL")}
                    label="External Users"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={distributionChannels.includes("XML-B2C") ? true : false}
                        color="primary"
                      />
                    }
                    onChange={(e)=>setDisburtedValueFunction("XML-B2C")}
                    label="XML-B2C"
                  />
                </div>
                :
                <div className="cm-checkbox-inline">
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={props?.tariffData?.distributionChannels && props?.tariffData?.distributionChannels.includes("INTERNAL") ? true : false}
                        color="primary"
                      />
                    }
                    label="Internal Users"
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={props?.tariffData?.distributionChannels && props?.tariffData?.distributionChannels.includes("EXTERNAL") ? true : false}
                        color="primary"
                      />
                    }
                    label="External Users"
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={props?.tariffData?.distributionChannels && props?.tariffData?.distributionChannels.includes("XML-B2C") ? true : false}
                        color="primary"
                      />
                    }
                    label="XML-B2C"
                  />
                </div>
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
