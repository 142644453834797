import {  useState  } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

export default function Season(props) {

    const [editFlag ,setEditFlag] = useState(false);

    const selectEdit = () => {
        setEditFlag(true)
    }
    
    return (
        <div className="card cm-card">
            <div className="card-body">
            <div className="cm-card-edit">
            {/* {editFlag ?
                <>
                <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                <button className="cm-primary-btn" onClick={(e)=>setEditFlag(false)}> Save</button>
                </>              :
              <i onClick={(e)=>selectEdit(true)} class="far fa-edit"></i>
            } */}
            </div>
            <div className="row align-items-end">
                <div className="col-md-3 cm-col">
                    <label className="cm-head-label">Last Ticketing Date </label>
                    {/* <input 
                        className="form-control"
                        type="date"
                    /> */}
                    {editFlag ?
                        <input type="date" className="form-control cm-input-date" placeholder="Enter date"/>
                    :
                    <div className="cm-content">
                        {props.priceData && props.priceData.prixLastTicketingDate}
                    </div>
                    }
                </div>
                <div className="col-md-3 cm-col">
                    <label className="cm-head-label">Return Date </label>
                    {editFlag ?
                        <input type="date" className="form-control cm-input-date" placeholder="Enter date"/>
                    :
                    <div className="cm-content">
                        {props.priceData && props.priceData.prixLastReturnDate}
                    </div>
                    }
                    {/* <input 
                        className="form-control"
                        type="date"
                    /> */}
                </div>
                <div className="col-md-3 cm-col">
                    <label className="cm-head-label">Note</label>
                    {editFlag ?
                        <input type="text" className="form-control cm-input" placeholder="Enter note"/>
                    :
                    <div className="cm-content">
                         {props.priceData  && props.priceData.prixNote === '' && 
                            <input type="text" className="form-control cm-input" placeholder="Enter note"/>
                         }
                        {props.priceData  && props.priceData.prixNote}
                    </div>
                    }
                    {/* <input 
                        className="form-control"
                        type="text"
                    /> */}
                </div>
                <div className="col-md-3 cm-col">
                    <div className="cm-checkbox-inline">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={props.priceData && props.priceData.noTKTBY && props.priceData.noTKTBY}
                            color="primary"
                        />
                        }
                        label="No TKT-BY"
                    />
                    </div>
                </div>
            </div>
            <div className="row">
                
            <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Price</label>
                    <table className="table table-bordered cm-table">
                     
                     <tbody>
                         <tr>
                             <td></td>
                             <td>
                            <strong className="d-flex"> 
                              <span style={{whiteSpace:'nowrap'}}>Our commission </span>  
                                  <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <span className='cm-edidel-btns cm-view-btn' {...bindTrigger(popupState)} >
                                    <i className="fas fa-info-circle ml-3" style={{ color: '#3f51b5', cursor: 'pointer' }}></i>
                                  </span>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>Cost  </Typography>
                                  </Popover>
                                  
                                </div>
                              )}
                            </PopupState>
                            </strong>
                            </td>
                             <td><strong className="d-flex"> 
                              <span style={{whiteSpace:'nowrap'}}>Our markup</span>
                             <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <span className='cm-edidel-btns cm-view-btn' {...bindTrigger(popupState)} >
                                    <i className="fas fa-info-circle ml-3" style={{ color: '#3f51b5', cursor: 'pointer' }}></i>
                                  </span>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>Markup </Typography>
                                  </Popover>
                                  
                                </div>
                              )}
                            </PopupState>
                                 </strong></td>
                             <td><strong className="d-flex"> 
                              <span style={{whiteSpace:'nowrap'}}> Agency commission </span>
                             <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <span className='cm-edidel-btns cm-view-btn' {...bindTrigger(popupState)} >
                                    <i className="fas fa-info-circle ml-3" style={{ color: '#3f51b5', cursor: 'pointer' }}></i>
                                  </span>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>Net </Typography>
                                  </Popover>
                                  
                                </div>
                              )}
                            </PopupState>
                                 </strong></td>
                             <td><strong style={{whiteSpace:'nowrap'}}>Min sell</strong></td>
                         </tr>
                        {editFlag ?
                           <tr>
                           <td><strong>Adult</strong></td>
                           <td><input className="form-control cm-input"/></td>
                           <td><input className="form-control cm-input"/></td>
                           <td><input className="form-control cm-input"/></td>
                           <td><input className="form-control cm-input"/></td>
                       </tr>
                       :
                       <>
                       <tr>
                             <td><strong>Adult</strong></td>
                             <td className="text-center">{props.priceData && props.priceData.fareCalculation && props.priceData.fareCalculation.commissionPercentage}</td>
                             <td className="text-center">{props.priceData && props.priceData.fareCalculation && props.priceData.fareCalculation.markup}</td>
                             <td className="text-center">{props.priceData && props.priceData.fareCalculation && props.priceData.fareCalculation.passBackCommission}</td>
                             <td className="text-center">{props.priceData && props.priceData.fareCalculation && props.priceData.fareCalculation.minumumSellingPrice}</td>
                         </tr>
                       <tr>
                             <td><strong>Child</strong></td>
                             <td  className="text-center">{props.priceData && props.priceData.childCommissionPercentage}</td>
                             <td  className="text-center">{props.priceData && props.priceData.childMarkUp}</td>
                             <td  className="text-center">{props.priceData && props.priceData.childPassBackCommission}</td>
                             <td></td>
                         </tr>
                         <tr>
                             <td><strong>Infant</strong></td>
                             <td  className="text-center">{props.priceData && props.priceData.infantCommissionPercentage}</td>
                             <td  className="text-center">{props.priceData && props.priceData.infantMarkUp}</td>
                             <td  className="text-center">{props.priceData && props.priceData.infantPassBackCommission}</td>
                             <td></td>
                         </tr>
                       </>
                         
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Navcan</label>
                    <table className="table table-bordered cm-table">
                        <tbody>
                        <tr>
                            <td width="28%"></td>
                            <td width="36%"><strong className="d-flex"> 
                              <span style={{whiteSpace:'nowrap'}}> Our commission </span>
                                  <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <span className='cm-edidel-btns cm-view-btn' {...bindTrigger(popupState)} >
                                    <i className="fas fa-info-circle ml-3" style={{ color: '#3f51b5', cursor: 'pointer' }}></i>
                                  </span>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>Cost  </Typography>
                                  </Popover>
                                  
                                </div>
                              )}
                            </PopupState></strong></td>
                            <td width="36%"><strong className="d-flex"> 
                              <span style={{whiteSpace:'nowrap'}}>Agency commission</span>
                             <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <span className='cm-edidel-btns cm-view-btn' {...bindTrigger(popupState)} >
                                    <i className="fas fa-info-circle ml-3" style={{ color: '#3f51b5', cursor: 'pointer' }}></i>
                                  </span>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>Net </Typography>
                                  </Popover>
                                  
                                </div>
                              )}
                            </PopupState></strong></td>
                        </tr>
                         
                            {editFlag ?
                            <tr>
                                <td><strong>Adult</strong></td>
                                <td><strong><input className="form-control cm-input cm-amount-field" /></strong></td>
                                <td><strong><input className="form-control cm-input cm-amount-field" /></strong></td>
                            </tr>    
                              :
                            <tr>
                                <td><strong>Adult</strong></td>
                                <td className="text-center">{props.priceData && props.priceData.adultCommissionOnYQNav && props.priceData.adultCommissionOnYQNav}</td>
                                <td className="text-center">{props.priceData && props.priceData.adultPassBackCommissionOnYQNav && props.priceData.adultPassBackCommissionOnYQNav}</td>
                            </tr>
                            }
                            {editFlag ?
                            <tr>
                                <td><strong>Child</strong></td>
                                <td><input className="form-control cm-input cm-amount-field" /></td>
                                <td><input className="form-control cm-input cm-amount-field" /></td>
                            </tr>    
                            :
                            <tr>
                                <td><strong>Child</strong></td>
                                <td className="text-center">{props.priceData && props.priceData.childCommissionOnYQNav && props.priceData.childCommissionOnYQNav}</td>
                                <td className="text-center">{props.priceData && props.priceData.childPassBackCommissionOnYQNav && props.priceData.childPassBackCommissionOnYQNav}</td>
                            </tr>
                            }
                            
                            {editFlag ?
                            <tr>
                                <td><strong>Infant</strong></td>
                                <td><input className="form-control cm-input cm-amount-field" /></td>
                                <td><input className="form-control cm-input cm-amount-field" /></td>
                            </tr>    
                              :
                            <tr>
                                <td><strong>Infant</strong></td>
                                <td className="text-center">{props.priceData && props.priceData.infantCommission && props.priceData.infantCommission}</td>
                                <td className="text-center">{props.priceData && props.priceData.enfantPassBackCommissionOnYQNav && props.priceData.enfantPassBackCommissionOnYQNav}</td>
                            </tr>
                                }
                        </tbody>
                    </table>
                </div>

                <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Carburant</label>
                    <table className="table table-bordered cm-table">
                        <tbody>
                        <tr>
                            <td width="28%"></td>
                            <td width="36%"><strong className="d-flex"> 
                              <span style={{whiteSpace:'nowrap'}}> Our commission </span>
                                  <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <span className='cm-edidel-btns cm-view-btn' {...bindTrigger(popupState)} >
                                    <i className="fas fa-info-circle ml-3" style={{ color: '#3f51b5', cursor: 'pointer' }}></i>
                                  </span>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>Cost  </Typography>
                                  </Popover>
                                  
                                </div>
                              )}
                            </PopupState></strong></td>
                            <td width="36%"><strong className="d-flex"> 
                              <span style={{whiteSpace:'nowrap'}}>Agency commission</span>
                             <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <span className='cm-edidel-btns cm-view-btn' {...bindTrigger(popupState)} >
                                    <i className="fas fa-info-circle ml-3" style={{ color: '#3f51b5', cursor: 'pointer' }}></i>
                                  </span>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>Net </Typography>
                                  </Popover>
                                  
                                </div>
                              )}
                            </PopupState>
                                </strong></td>
                        </tr>
                        {editFlag ?
                        <tr>
                            <td><strong>Adult</strong></td>
                            <td className="text-center"><input className="form-control cm-input cm-amount-field" /></td>
                            <td className="text-center"><input className="form-control cm-input cm-amount-field" /></td>
                        </tr> 
                        :
                        <tr>
                            <td><strong>Adult</strong></td>
                            <td className="text-center">{props.priceData && props.priceData.adultCommissionOnYQ && props.priceData.adultCommissionOnYQ}</td>
                            <td className="text-center">{props.priceData && props.priceData.adultPassBackCommissionOnYQ && props.priceData.adultPassBackCommissionOnYQ}</td>
                        </tr>
                        }
                        {editFlag ?
                        <tr>
                            <td><strong>Child</strong></td>
                            <td><input className="form-control cm-input cm-amount-field" /></td>
                            <td><input className="form-control cm-input cm-amount-field" /></td>
                        </tr> 
                        :
                        <tr>
                            <td><strong>Child</strong></td>
                            <td className="text-center">{props.priceData && props.priceData.childCommissionOnYQ && props.priceData.childCommissionOnYQ}</td>
                            <td className="text-center">{props.priceData && props.priceData.enfantPassBackCommissionOnYQ && props.priceData.enfantPassBackCommissionOnYQ}</td>
                        </tr>
                        }
                        {editFlag ?
                        <tr>
                            <td><strong>Infant</strong></td>
                            <td><input className="form-control cm-input cm-amount-field" /></td>
                            <td><input className="form-control cm-input cm-amount-field" /></td>
                        </tr> 
                        :
                        <tr>
                            <td><strong>Infant</strong></td>
                            <td>{props.priceData && props.priceData.enfantCommissionOnYQ && props.priceData.enfantCommissionOnYQ}</td>
                            <td>{props.priceData && props.priceData.childPassBackCommissionOnYQ && props.priceData.childPassBackCommissionOnYQ}</td>
                        </tr>
                        }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
    )
}
