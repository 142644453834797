import {   useState ,useContext } from "react";
import { useHistory} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ContentLoader from "react-content-loader"
import ClassSearch from './ClassSearch/ClassSearch'
import Prix from "./Prix/Prix";

import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import authContext from '../../context/auth-context'
import AllSearch from "./AllSearch/AllSearch";

export default function Contractlist() {

    // VARIABLE FOR STORE LIST
    const [serchFor ,setSerchFor] = useState("all");
    const [isLoading ,setIsLoading] = useState(false)
    const history = useHistory();

    const { listData } = useContext(authContext);
    const { updateListData } = useContext(authContext);


    // SEARCH PARAMETER
    const [carrier ,setCarrier] = useState("");
    const [tarifKey ,setTarifKey] = useState("");
    const [via ,setVia] = useState("");
    const [passengerType ,setPassengerType] = useState("");
    const [isBrandedFare ,setIsBrandedFare] = useState("");
    
    const [tripType ,setTripType] = useState("");
    const [cabinType ,setCabinType] = useState("");
    const [cruiseFare ,setCruiseFare] = useState("");
    const [landFare ,setLandFare] = useState("");



    const [originType ,setOriginType] = useState("");
    const [originValue ,setoriginValue] = useState("");

    const [destinationType ,setDestinationType] = useState("");
    const [destinationValue ,setDestinationValue] = useState("");
    const [noDataFlag ,SetNoDataFlag] = useState(false)

    // PAGINATION PARAMETER
    const [isLast, setIsLast] = useState(false);
    const [isFirst, setIsFirst] = useState(false);
    const [counter, setCounter] = useState(0);
    const [lastArry, setLastArry] = useState([])
    // const { orgId } = useContext(authContext);
    const [searchUrl ,setSearchUrl] = useState('')

    const [classLastTicketingDateFrom ,setClassLastTicketingDateFrom] = useState('')
    const [classLastTicketingDateTo ,setClassLastTicketingDateTo] = useState('')

    // SEARCH API CALL FUNCTION 
    const tariffSearchApiCall = () => {
      setLastArry([])
      setCounter(0)
      let strUrl = 'searchTarif?'
      setIsLoading(true)
      if(carrier !== ""){
        let carrierValue

        if(strUrl.length < 13 ){
          carrierValue ="carrier="+carrier
        } else {
          carrierValue ="&carrier="+carrier
        }
        strUrl = strUrl+carrierValue
      } 

      if(tripType !== ""){
        let tripTypeValue
        if(strUrl.length < 13 ){
          tripTypeValue ="tripType="+tripType
        } else {
          tripTypeValue ="&tripType="+tripType
        }
        strUrl = strUrl+tripTypeValue
      } 

      if(cabinType !== ""){
        let cabinTypeValue
        if(strUrl.length < 13 ){
          cabinTypeValue  ="cabinType="+cabinType
        } else {
          cabinTypeValue ="&cabinType="+cabinType
        }
        strUrl = strUrl+cabinTypeValue
      } 

       if(tarifKey !==""){
        let tarifKeyValue 
        if(strUrl.length < 13 ){
           tarifKeyValue ="tarifKey="+tarifKey
        } else{
           tarifKeyValue ="&tarifKey="+tarifKey
        }
          strUrl = strUrl+tarifKeyValue
      } 

       if(via !==""){
         let viaValue
        if(strUrl.length < 13 ){
           viaValue ="via="+via

        } else {
           viaValue ="&via="+via
        }
        strUrl = strUrl+viaValue
      }
      
      if(classLastTicketingDateFrom !== ""){
        let classLastTicketingDateFromVar
        if(strUrl.length < 13 ){
          classLastTicketingDateFromVar ="classLastTicketingDateFrom="+classLastTicketingDateFrom
        } else {
          classLastTicketingDateFromVar ="&classLastTicketingDateFrom="+classLastTicketingDateFrom
        }
        strUrl = strUrl+classLastTicketingDateFromVar
      } 

      if(classLastTicketingDateTo !== ""){
        let classLastTicketingDateTovar
        if(strUrl.length < 13 ){
          classLastTicketingDateTovar ="classLastTicketingDateTo="+classLastTicketingDateTo
        } else {
          classLastTicketingDateTovar ="&classLastTicketingDateTo="+classLastTicketingDateTo
        }
        strUrl = strUrl+classLastTicketingDateTovar
      } 


      if(isBrandedFare !==""){
        let isBrandedFareValue
        let brandedFareData
        if(isBrandedFare === "1"){
          brandedFareData = true
        }
        if( isBrandedFare === "0") {
          brandedFareData = false
        }
        

        if(strUrl.length < 13 ){
           isBrandedFareValue ="isBrandedFare="+brandedFareData
        }else {
           isBrandedFareValue ="&isBrandedFare="+brandedFareData
        }
        strUrl = strUrl+isBrandedFareValue
      }

      if(originType !==""){
        let originValueValue
        if(strUrl.length < 13 ){
          originValueValue =originType+"="+originValue
        } else {
          originValueValue ="&"+originType+"="+originValue
        }
        strUrl = strUrl+originValueValue
      }

      if(destinationType !==""){
        let destinationValueValue 
        if(strUrl.length < 13 ){
          destinationValueValue =destinationType+"="+destinationValue
        } else {
          destinationValueValue ="&"+destinationType+"="+destinationValue
        }
        strUrl = strUrl+destinationValueValue
      }

      if(cruiseFare !=="" ){
        if(cruiseFare){
          let cruiseFareValue
          if(strUrl.length < 13 ){
           cruiseFareValue ="cruiseFare=1"
          } else {
            cruiseFareValue ="&cruiseFare=1"
          }
          strUrl = strUrl+cruiseFareValue
        } else {
          let cruiseFareValue 
          if(strUrl.length < 13 ){
             cruiseFareValue ="cruiseFare=0"

          } else {
             cruiseFareValue ="&cruiseFare=0"
          }
          strUrl = strUrl+cruiseFareValue
        }
      }

      if(landFare !=="" ){
        if(landFare){
          let landFareValue 
          if(strUrl.length < 13 ){
             landFareValue ="landFare=1"
          } else {
             landFareValue ="&landFare=1"
          }

          strUrl = strUrl+landFareValue
        } else {
          let landFareValue 
          if(strUrl.length < 13 ){
             landFareValue ="landFare=0"

          } else {
             landFareValue ="&landFare=0"

          }
          strUrl = strUrl+landFareValue
        }
      }


      setSearchUrl(strUrl)
      if(strUrl.length > 12){
        fetch(`${process.env.REACT_APP_CONTRACT_URL}/${strUrl}`, {
          method: 'POST',
      })
          .then(response => response.json())
          .then(result =>{
            if(result.Items.length === 0){
              SetNoDataFlag(true)
            } else{
              SetNoDataFlag(false)

            }
            if(result.message === undefined){
              setIsLoading(true)  
              updateListData(result.Items)
              setIsLoading(false)
              setLastEvalatuedValue(result.LastEvaluatedKey)

            } else {
              updateListData([])
              setIsLoading(false)
            }
          
          } )
          .catch(error => console.error("Error: ", error))
      } else {
        fetch(`${process.env.REACT_APP_CONTRACT_URL}/searchTarif`, {
          method: 'POST',
      })
          .then(response => response.json())
          .then(result =>{
            if(result.Items.length === 0){
              SetNoDataFlag(true)
            } else{
              SetNoDataFlag(false)

            }
            if(result.message === undefined){
              setIsLoading(true)  
              updateListData(result.Items)
              setIsLoading(false)
              let newArry = []
              newArry.push(result.LastEvaluatedKey);
              setLastArry(newArry)
            } else {
              updateListData([])
              setIsLoading(false)
            }

          } )
          .catch(error => console.error("Error: ", error))
      }
    
    }

    // FUNCTION TO CLEAR SEARCH
    const clearSearch = () => {
    SetNoDataFlag(false)
    setCarrier("");
    setTarifKey("")
    setVia("")
    setPassengerType("")
    setIsBrandedFare("")
    setTripType("")
    setCabinType("")
    setCruiseFare("")
    setLandFare("")
    setOriginType("")
    setoriginValue("")
    setDestinationType("")
    setDestinationValue("")
    setClassLastTicketingDateFrom('')
    setClassLastTicketingDateTo('')
      }

    // LOADER 
    const MyLoader = (props) => (
      <ContentLoader 
        speed={2}
        width={400}
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="56" rx="3" ry="3" width="1000" height="6" /> 
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" /> 
      </ContentLoader>
    )


    // FUNCTION FOR REDIRECT
    const redirectToTariff = (value) => {
      let tariffId = value.tarifId
        history.push(`/contractmanagement/${tariffId}`);
    }


        //PAGINATION FUNCTION
        const paginationNext = async () => {
          setIsLoading(true)
          setIsFirst(false)

            const url = 
                      searchUrl === '' 
                      ? `${process.env.REACT_APP_CONTRACT_URL}/searchTarif` 
                      : `${process.env.REACT_APP_CONTRACT_URL}/${searchUrl}`;
          
          let requestbody = {
          LastEvaluatedKey: lastArry[counter],
          };
  
          const requestOptions = {
          method: "POST",
  
          headers: {
              "Content-type": "application/json; charset=UTF-8",
  
              "Access-Control-Allow-Origin": "*",
  
              "Access-Control-Allow-Credentials": true,
  
              "cache-control": "no-cache",
          },
  
          body: JSON.stringify(requestbody),
          };
  
          const priceResult = await getApiResponse(url, requestOptions);
  
          if (priceResult) {
              setIsLoading(true)  
              SetNoDataFlag(false)
              updateListData(priceResult.Items)
              setIsLoading(false)
              setLastEvalatuedValue(priceResult.LastEvaluatedKey)
  
          }
      };
      
  
      async function getApiResponse(url, requestOptions) {
          const response = await fetch(url, requestOptions);
  
          if (!response.ok) {
          return null;
          } else {
          const data = await response.json();
  
          return data;
          }
      }
  
  
        //PAGINATION FUNCTION
        const paginationPrevious = async () => {
          setIsLoading(true)
          setIsFirst(false)

            const url = 
                      searchUrl === '' 
                      ? `${process.env.REACT_APP_CONTRACT_URL}/searchTarif` 
                      : `${process.env.REACT_APP_CONTRACT_URL}/${searchUrl}`;
          
          let requestbody = {
          LastEvaluatedKey: lastArry[counter-1],
          };
  
          const requestOptions = {
          method: "POST",
  
          headers: {
              "Content-type": "application/json; charset=UTF-8",
  
              "Access-Control-Allow-Origin": "*",
  
              "Access-Control-Allow-Credentials": true,
  
              "cache-control": "no-cache",
          },
  
          body: JSON.stringify(requestbody),
          };
  
          const priceResult = await getApiResponseForPri(url, requestOptions);
  
          if (priceResult) {
              setIsLoading(true)  
              SetNoDataFlag(false)
              updateListData(priceResult.Items)
              setIsLoading(false)
  
          }
      };
  
      //PAGINATION FUNCTION
      async function getApiResponseForPri(url, requestOptionsData) {
          const response = await fetch(url, requestOptionsData);
  
          if (!response.ok) {
          return null;
          } else {
          const data = await response.json();
  
          return data;
          }
      }
  
      // PUSHED IN LASTEVALUATED KEY
      const setLastEvalatuedValue = (value) =>{
          let newArry = lastArry
          if (newArry.includes(value)) {
              newArry = newArry.filter((id) => id != value);
          } else {
              newArry.push(value);
          }
          setLastArry(newArry)
  
      } 

      console.log("ll",lastArry)
  
  
      // NEXT CLICK
      const nextClick = () =>{
          setCounter(counter + 1)
          paginationNext()
          // tariffSearchApiCall
      } 
  
        // NEXT CLICK
        const priviousClick = () =>{
            setIsLast(false)
          paginationPrevious()
         
          setCounter(counter - 1)
  
      } 
  
  return (
    <div className="searchForm">

      <div className="cm-headW-radio">
          <label className="cm-headW-label">Search For</label>    
          <div className="cm-radio-inline">
            <FormControlLabel   control={<Radio color="primary" />} label="Tariff" checked={serchFor === "tariff" ? true : false} className="ml-2" onChange={()=>setSerchFor("tariff")} />
            <FormControlLabel  control={<Radio color="primary" />} label="Class" checked={serchFor === "Class" ? true : false}   onChange={()=>setSerchFor("Class")} />
            <FormControlLabel  control={<Radio color="primary" />} label="Prix" checked={serchFor === "Prix" ? true : false}  onChange={()=>setSerchFor("Prix")} />
            <FormControlLabel  control={<Radio color="primary" />} label="All Search" checked={serchFor === "all" ? true : false}  onChange={()=>setSerchFor("all")} />

          </div>
      </div>

      {serchFor === 'tariff' && 
      <div>
      <div className="card cm-card">
        <div className="card-body">
          
          {/* >>>>>>>>>>>>>>>>>>>>> tariff Search >>>>>>>>>>>>>>>>>>>>>. */}
            <div className="row">
                <div className="col-12">
                    <p className="cm-head-2">Tariff Search</p>
                </div>
            </div>
            {/* ORIGIN AND DESTINATION SEARCH SECTION */}
            <div className="row">
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Origin</label>
                  <div className="row">
                      <div className="col-md-5">
                        <select 
                          className="form-control cm-input-select"
                          value={originType}
                          onChange={(e)=>setOriginType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value='originRegion'>Region Code</option>
                          <option value='originCitiesOrAirports'>Airport Or City Code</option>
                          <option value='originCountries'>Country Code</option>
                          <option value='originIATARegion'>IATA Region Code</option>
                        </select>
                      </div>
                      <div className="col-md-7">
                        <input 
                          className="form-control cm-input"
                          placeholder="Enter Origin ..."
                          value={originValue}
                          onChange={(e)=>setoriginValue(e.target.value)}
                        />
                      </div>
                  </div>
              </div>
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Destination</label>
                  <div className="row">
                      <div className="col-md-5">
                        <select 
                          className="form-control cm-input-select"
                          value={destinationType}
                          onChange={(e)=>setDestinationType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value='destinationRegion'>Region Code</option>
                          <option value='destinationCitiesOrAirports'>Airport Or City Code</option>
                          <option value='destinationCountries'>Country Code</option>
                          <option value='destinationIATAs'>IATA Region Code</option>
                        </select>
                      </div>
                      <div className="col-md-7">
                        <input 
                          className="form-control cm-input"
                          placeholder="Enter Destination ..."
                          value={destinationValue}
                          onChange={(e)=>setDestinationValue(e.target.value)}
                        />
                      </div>
                  </div>
              </div>
            </div>
            {/* VIA VALIDATING CARRIER SECTION */}
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5 cm-col">
                  <label className="cm-head-label">Tarif Key</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter tarif key"
                      value={tarifKey}
                      onChange={(e)=>setTarifKey(e.target.value)}

                    />
                  </div>
                  <div className="col-md-7 cm-col">
                  <label className="cm-head-label">Validating Carrier</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter validating carrier"
                      onChange={(e)=>setCarrier(e.target.value)}
                      value={carrier}
                    />
                  </div>
                </div>

              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5 cm-col">
                    <label className="cm-head-label">VIA</label>
                      <input 
                        className="form-control cm-input"
                        placeholder="Enter VIA"
                        onChange={(e)=>setVia(e.target.value)}
                        value={via}

                      />
                  </div>
                </div>
              </div>
            </div>
               {/* EDR DATE */}
               {/* <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5 cm-col">
                  <label className="cm-head-label">EDR Start Date</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter date"
                      type="date"
                      value={classLastTicketingDateFrom}
                      onChange={(e)=>setClassLastTicketingDateFrom(e.target.value)}

                    />
                  </div>
                  <div className="col-md-7 cm-col">
                  <label className="cm-head-label">EDR End Date</label>
                    <input 
                      className="form-control cm-input"
                      placeholder="Enter date"
                      type="date"
                      value={classLastTicketingDateTo}
                      onChange={(e)=>setClassLastTicketingDateTo(e.target.value)}
                    />
                  </div>
                </div>

              </div>
         
            </div> */}
            <div className="row">
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Cabin Type</label>
                <div className="cm-radio-inline">
                  <FormControlLabel  control={<Radio color="primary" />} label="Economy"
                   checked={cabinType === "Economy" ? true : false} onChange={()=>setCabinType("Economy")}/>
                  <FormControlLabel  control={<Radio  color="primary"/>} label="Premimum Economy"  
                    checked={cabinType === "PremiumEconomy" ? true : false} onChange={()=>setCabinType("PremiumEconomy")} className=""  />{" "}
                  <FormControlLabel  control={<Radio color="primary" />} label="First Class"  
                    checked={cabinType === "First" ? true : false} onChange={()=>setCabinType("First")} className="" />
                  <FormControlLabel  control={<Radio color="primary" />} label="Business"  
                    checked={cabinType === "Business" ? true : false} onChange={()=>setCabinType("Business")} />{" "}
                  <FormControlLabel  control={<Radio color="primary"/>} label="Premium Business"  
                    checked={cabinType === "PremiumBusiness" ? true : false} onChange={()=>setCabinType("PremiumBusiness")} />{" "}
               </div>
              </div>
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Trip Type</label>
                <div className="cm-radio-inline">
                  <FormControlLabel  control={<Radio color="primary"/>} label="One Way"
                   checked={tripType === "OneWay" ? true : false } onChange={(e)=>setTripType("OneWay") } />
                  <FormControlLabel  control={<Radio color="primary" />} label="Round Trip / Open Jaw / Multi City"
                   checked={tripType === "Return" ? true : false} onChange={(e)=>setTripType("Return")} />{" "}
                </div>
              </div>

            </div>
            {/* FARE TYPE  */}
            <div className="row ">
              <div className="col-md-6 cm-col">
                <label className="cm-head-label">Fare Types</label>
                <div className="cm-checkbox-inline">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={landFare} onChange={(e)=>setLandFare(!landFare)}
                        color="primary"
                      />
                    }
                    label="Land Fare"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cruiseFare} onChange={(e)=>setCruiseFare(!cruiseFare)}
                        color="primary"
                      />
                    }
                    label="Cruise Fare"
                  />
                </div>
              </div>
              <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6 cm-col">
                      <label className="cm-head-label">Branded Fare</label>
                      <div className="cm-radio-inline">
                        <FormControlLabel  control={<Radio  color="primary"/>} label="Yes"
                          checked={isBrandedFare === "1" ? true :false} 
                          onChange={(e)=>setIsBrandedFare("1")} 
                        /> 
                        <FormControlLabel  control={<Radio color="primary" />} label="No"
                          onChange={(e)=>setIsBrandedFare("0") ? true:false } 
                          checked={isBrandedFare === '0'}  
                         />
                      </div>
                    </div>
                    <div className="col-md-6 cm-col">
                      <label className="cm-head-label">Type of Passenger</label>
                      <div className="cm-radio-inline">                   
                        <FormControlLabel  control={<Radio color="primary" />} label="Adult"
                          checked={passengerType === "ADULT" ? true : false} onChange={(e)=>setPassengerType("ADULT")} /> 
                          <FormControlLabel  control={<Radio  color="primary"/>} label="Senior" 
                          checked={passengerType === "SENIOR" ? true : false} onChange={(e)=>setPassengerType("SENIOR")}  />
                        </div>
                    </div>
                  </div>
              </div>
            </div>


            {/* BUTTON SECTION */}
            <div className="row cm-col">
              <div className="col-md-12 text-right">
                <button 
                  className="cm-secondary-btn mr-2" 
                  onClick={(e)=>clearSearch()}
                >
                  Clear
                </button>
                <button 
                  className="cm-primary-btn" 
                  onClick={(e)=>tariffSearchApiCall()}
                  disabled={isLoading}
                >
                  {isLoading && <span className="mr-5 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}

                  Search
                </button>
                
              </div>
            </div>
        </div>
      </div>
      {noDataFlag && 
      <div className="alert alert-info mt-2" role="alert">
          No Results found for the selected criteria.
      </div>
      }
        {isLoading ? <div style={{justifyContent:'center'}}>
            <span className="badge badge-light mt-3 ml-3 mb-3"><span className="text-danger">Loading ...</span> </span>
        </div>: 
        <div>
      {/* <h4>Contracts List</h4> */}
      {listData.length > 0 && 
      <div className="cm-mui-table-sm">
      <TableContainer component={Paper} className="mt-4">
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Contract Owner</TableCell>
              <TableCell>Distribution</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              listData.map((tariff,index)=>{
                let logoLowerCase = tariff.carrier && tariff.carrier.toLowerCase();

                let flightLogo = `${process.env.REACT_APP_FLIGHT_LOGO_URL}/${logoLowerCase}.png`
                  return(
                    <TableRow key={index}
                        onClick={(e)=>redirectToTariff(tariff)} 
                        title="Click to view contract">
                    <TableCell component="th" scope="row">
                      <div className="tariff-table-title">
                        <div className="fare-logo">
                          <span className="fligh-logo"><img src={flightLogo} height="35" width="35" alt="flightLogo" /> {tariff.carrier}</span>
                          <span><strong>VIA:</strong> {tariff.via}</span>
                          <span><strong>{tariff.isBrandedFare === "1" ? 'Branded Fare' : 'Non-Branded Fare'}</strong></span>
                        </div>
                        <div className="note"><strong>Note:</strong> {tariff.note}</div>
                      </div>
                    </TableCell>
                    {/* <TableCell align="right"> */}
                    <TableCell>
                        <div className="tariff-origin-dest">
                          <div className="tariff-origin-dest-row">
                            <strong>Origin: </strong>
                            {(tariff.originCountries && (tariff.originCountries.length === 0 || tariff.originCountries.includes("") || tariff.originCountries.includes(null) ) ) ? "" :   "Country -"}  {tariff.originCountries && Object.values(tariff.originCountries).join(',')}
                            {(tariff.originRegions && (tariff.originRegions.length === 0 || tariff.originRegions.includes("") || tariff.originRegions.includes(null) ) ) ? "" : <span> {(tariff.originCountries && (tariff.originCountries.length === 0 || tariff.originCountries.includes("") || tariff.originCountries.includes(null) ) ) ? "" :   "|"}    Regions - </span>} {tariff.originRegions && Object.values(tariff.originRegions).join(',')} 
                            {(tariff.originCitiesOrAirports && (tariff.originCitiesOrAirports.length === 0 || tariff.originCitiesOrAirports.includes("") || tariff.originCitiesOrAirports.includes(null) ) ) ? "" : <span> {(tariff.originRegions && (tariff.originRegions.length === 0 || tariff.originRegions.includes("") || tariff.originRegions.includes(null) ) ) ? "" :   "|"}    Cities Or Airports - </span>}     {tariff.originCitiesOrAirports && Object.values(tariff.originCitiesOrAirports).join(',')}
                            {tariff.originIATARegion && tariff.originIATARegion != "" && <span>{(tariff.originCitiesOrAirports && (tariff.originCitiesOrAirports.length === 0 || tariff.originCitiesOrAirports.includes("") || tariff.originCitiesOrAirports.includes(null) ) ) ? "" :   "|"}  IATA - </span> }  {tariff.originIATARegion}
                          </div>
                          <div className="tariff-origin-dest-row">
                            <strong>Destination: </strong>
                            {tariff.destinationCountries && tariff.destinationCountries.length !== 0 && " Country -"}  {Object.values(tariff.destinationCountries).join(', ')}
                            {tariff.destinationIATAs && tariff.destinationIATAs.length !== 0 && <span>{tariff.destinationCountries && tariff.destinationCountries.length !== 0 && "|"} IATA </span>}  {tariff.destinationIATAs && Object.values(tariff.destinationIATAs).join(', ')}
                            {tariff.destinationRegions && tariff.destinationRegions.length !== 0 && <span>{tariff.destinationRegions && tariff.destinationRegions.length !== 0 && <span>{tariff.destinationIATAs && tariff.destinationIATAs.length !== 0 && "|"} Regions - </span>} </span>} {tariff.destinationRegions && Object.values(tariff.destinationRegions).join(', ')}
                            {/* {tariff.destinationCities && tariff.destinationCities.length !== 0 && "| Cities -"}   {tariff.destinationCities && Object.values(tariff.destinationCities).join(',')} */}
                            {tariff.destinationCitiesOrAirports && tariff.destinationCitiesOrAirports.length !== 0 &&<span>{tariff.destinationRegions && tariff.destinationRegions.length !== 0 && "|"}  Cities Or Airports - </span>}    {tariff.destinationCitiesOrAirports && Object.values(tariff.destinationCitiesOrAirports).join(', ')}
                          </div>
                        </div>

                    </TableCell>
                    {/* <TableCell >
                
                    </TableCell> */}

                    <TableCell align="center">-</TableCell>
      
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                  )
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* PAGINATION  SECTION */}
      <div className="d-flex justify-content-between mt-3">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className= {counter === 0 ? "page-item disabled":"page-item " }>
              <a className="page-link"  tabIndex={-1} 
                onClick={() =>priviousClick()}

              >
                Previous
              </a>
            </li>
          </ul>
        </nav>
        
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className= {isLast  ? "page-item  disabled" : "page-item "}>
              <a
                className="page-link"
                onClick={() =>nextClick()}
                tabIndex={-1}
              >
                Next
                
              </a>
            </li>
          </ul>
        </nav>
      </div>
      </div>
      }
      </div>
      }
    </div>
    }

    {/* CLASS SEARCH */}
      {serchFor === 'Class' && 
      <ClassSearch />
    }

    {serchFor === 'Prix' && 
      <Prix />
    }
    {serchFor === 'all' && 
    <AllSearch/>
  }
  </div>
  );
}
