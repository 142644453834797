//@ts-nocheck
import { setFareOption } from '../ActionCreator/fareOption';
import { env } from '../appConfig'
import getApiResponse from "../Services/getApiResponseDemo";

export const getFareOptions = (fareId) => {
     
        return async (dispatch, getState)  => {

        // let group = [];

        const corrId = getState().searchForm.uuid;
        const tokenData = getState().initToken;
        const token = tokenData && tokenData.token && tokenData.token.token;
        const flightType = getState().searchForm.flightType;
        // const activeFareId = getState().fareOptions.activeFareId;
        // const fareOptions = getState().fareOptions.fareOptions;
        // fareOptions && group.push(...fareOptions);
        
        
        let url = env.REACT_APP_API_BASE_URL +`/getBrandedFares/?token=${token}`

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials" : true,
                correlationId: corrId,
                flightType:flightType 

            },
            body:
                JSON.stringify(
                    {
                       fareId: fareId,                    
                    })
        }
         

        const fareOptionsResult =  await getApiResponse(url, requestOptions);
           console.log("fareOptionsResult",fareOptionsResult)
        if(fareOptionsResult.results){
          
            dispatch(setFareOption(fareOptionsResult.results));
            
        }
        return fareOptionsResult

        
        // if(fareOptionsResult) {
        //     const result = {
        //       [fareId]: fareOptionsResult
        //     }
        //     group.push(result);
        //     dispatch(setFareOption(group));
        //   }

      
       
    }
}