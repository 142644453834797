import {
    LOG_IN,
  } from "../constant/types";
  
  // LOGIN ACTION
  export const  Login = (login) => ({
      
    type: LOG_IN,
    payload: login,
  }
  
  );
  


  export default Login;
