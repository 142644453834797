import React, { forwardRef } from "react";
import { Input as InputField  } from '@mui/material'
import { Label } from "./Label";

export const Input =  forwardRef(({type='text', className='', placeholder='', value=null, onChange=()=>{}, required=false, divClass="", labelvisible=false, labelName="", labelClassName="", defaultValue='', disabled=false}:any,ref) => {
    return(
        <div className={divClass}>
            {labelvisible ? <Label name className={labelClassName}>{labelName}</Label> : null}
            <InputField className={className} placeholder={placeholder} type={type} value={value} defaultValue={defaultValue} onChange={onChange} required={required} {...ref} disabled={disabled}/>
        </div>
    )
})