import {
    TOKEN_SAVE,
  } from "../constant/types";
  
  // USER SAVE ACTION
  export const  tokensave = (token) => ({
      
    type: TOKEN_SAVE,
    payload: token,
  }
  
  );
  


  export default tokensave;
