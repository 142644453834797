import {
    UPDATE_TAXESONFEES
    } from "../constant/types";
    
    const intialState:any = {
         taxesOnfees:[],
         
    };
    
    export const feesDatareducer = (state = intialState, action:any) => {
      switch (action.type) {
        case UPDATE_TAXESONFEES:
          return {
            ...state,
            taxesOnfees: action.payload,
          };
        
        default:
          return state;
      }
    };
    export default feesDatareducer;