
import { SET_TOKEN_REQUEST_DATA, SET_TOKEN } from '../storeConstant';


const InitTokenReducer = (state = null, action) => {
    switch(action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }

        case SET_TOKEN_REQUEST_DATA:
            return {
                ...state,
                tokenRequestData: action.payload    
            }

        default:
            return state;
    }
}

export default InitTokenReducer;