//@ts-nocheck
//import {getApiResponse} from "./../../utils/utility/getCommonApiResponse"
import { setPaymentRules ,setMarkupApplied, setPaymentRulesForCheque, setItenaryData, setItinaryFaresType} from "../ActionCreator/flightBooking"
// import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { env } from '../appConfig';
import getApiResponse from "../Services/getApiResponseDemo";
//toast.configure();

export const getPaymentRules = (requestbody,fopType,flowFrom) => {
    
    return async (dispatch, getState) => {
        const tokenData = getState().initToken;
        const quoteToken = getState().savedQuotes.quoteToken;
        const quoteDetails = getState().savedQuotes.repriceQuote;
        const token = tokenData && tokenData.token && tokenData.token.token;
        const flightType = getState().searchForm.flightType;
        
       
        let tokenCurrent = quoteDetails && quoteDetails.status === "QuoteRepriced" ? quoteDetails.token : token;

        let urlNew;

        if(tokenCurrent === null){
            urlNew = env.REACT_APP_API_BASE_URL + `/applyPaymentRules`
        }else{
            urlNew = env.REACT_APP_API_BASE_URL + `/applyPaymentRules?token=${tokenCurrent}`
        }

        const corrId = getState().searchForm.uuid;
        let url = urlNew;
       
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:flightType
            },
            body:
                JSON.stringify(
                    requestbody
                )
        }
       
        const paymetRules = await getApiResponse(url, requestOptions);
        if(paymetRules &&paymetRules.error){
            dispatch(setMarkupApplied(false))
            //toast.error(paymetRules.error.message, { position: toast.POSITION.TOP_CENTER, autoClose: true });
            return paymetRules
        } else{
            if(fopType === "CreditCard" && flowFrom !== "fromMarkup"){
                dispatch(setPaymentRules(paymetRules))              
            }else if(fopType === "Cheque" && flowFrom !== "fromMarkup"){             
                dispatch(setPaymentRulesForCheque(paymetRules))
            }else{
                if(flowFrom !== "fromMarkup"){
                dispatch(setPaymentRules(paymetRules))  
                }
            }
            dispatch(setMarkupApplied(true))
            dispatch(setItenaryData(paymetRules.itineraryFare))
            dispatch(setItinaryFaresType(paymetRules && paymetRules.itineraryFare && paymetRules.itineraryFare.type))
            return paymetRules
        }
        

    }
}