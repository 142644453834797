//@ts-nocheck
import { setAgencyList} from "../ActionCreator/searchForm";
import { env } from '../appConfig'
import { setEquivalentFareLoader, setEquivalentFareValue } from "../ActionCreator/flightBooking";
import getApiResponse from "../Services/getApiResponseDemo";
 

export const getEquivalentPublishFareForPnr = (request) => {
 
    return async (dispatch, getState) => {
        dispatch(setEquivalentFareLoader(true))
        const corrId = getState().searchForm.uuid;
        const tokenData = getState().initToken;
        const flightType = getState().searchForm.flightType;
        let token;
        
        if(tokenData) {
            token = tokenData && tokenData.token && tokenData.token.token;
        }
        let url = env.REACT_APP_API_BASE_URL + `/pubFares/getEquivalentPubFaresForPnr`
       
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:flightType 
                

            },
            body:
            JSON.stringify(
                request
                )
        }


        const publishEquivalentFarResult = await getApiResponse(url, requestOptions);
         
            if(publishEquivalentFarResult && !publishEquivalentFarResult.error){
                if(publishEquivalentFarResult && publishEquivalentFarResult.itineraryFare){
                    dispatch(setEquivalentFareValue(publishEquivalentFarResult.itineraryFare))
                    dispatch(setEquivalentFareLoader(false))
                }else{
                    dispatch(setEquivalentFareValue("NoFare"))
                    dispatch(setEquivalentFareLoader(false))
                }
            }else{
                dispatch(setEquivalentFareValue("NoFare"))
                dispatch(setEquivalentFareLoader(false))
            }
            

         return publishEquivalentFarResult
         
      

    }
}