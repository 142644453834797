//@ts-nocheck
import React from 'react'
import { useSelector } from "react-redux";
// import { monthNames } from "./../../utils/constant";
import moment from "moment";


 function BookingQuoteNote() {

    const flightDetail = useSelector((state) => state.flightBooking);
  // const bookingPaymentStatus = useSelector(
  //   (state) => state.flightBooking.bookingPaymentStatus
  // );

  let d = 
    flightDetail.bookingItenaries &&
      flightDetail.bookingItenaries.optionDateToDisplay
  
  
    return (
        <div className=" quotenotehold"><div className="col-lg-12 quotenotedivhold"><div><span><i className="fa fa-exclamation-circle" aria-hidden="true"></i></span> </div><div>
            <p className="quotepara">Your file will be on hold until {" "}
                               { moment.utc(d).format(
                                  "DD MMM  YYYY" 
                                )}                                
                               {" "} | {" "}
                                {moment.utc(d).format(
                                  "HH:mm" 
                                )}.Only 
            a payment made same day as booked and before ticketing time limit guarantees this fare. 
            Beyond that, Travel Brands won't be responsible for any change in the fares, taxes or 
            surcharges that may occur which are beyond our control.</p></div></div></div>
    )
}
export default BookingQuoteNote;