import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
export default function PriceAtSabre(props) {
    // const [editFlag ,setEditFlag] = useState(false);
    const editFlag = false;
    // const selectEdit = () => {
    //     setEditFlag(true)
    // }
    return (
        <div className="card cm-card">
            <div className="card-body">
            <div className="cm-card-edit">
                {/* {editFlag ?
                  <div>
                  <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                  <button className="cm-primary-btn" onClick={(e)=>setEditFlag(false)}> Save</button>
                </div>
              :
                <i onClick={(e)=>selectEdit(true)} class="far fa-edit"></i>
            } */}
            </div>
                <div className="row align-items-center">
                    <div className="col-md-6 cm-col">
                        <label className="cm-head-label">Ticket issuance condition</label>
                        {editFlag ? 
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="PhaseIV" 
                                    checked={props.priceData && props.priceData.emission === "PHASEIV" ? true :false}
                                />  
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label=" PQ" 
                                     checked={props.priceData && props.priceData.emission === 'PQ' ? true :false}
                                 /> 
                                 <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label=" PQ CAT 35" 
                                 checked={props.priceData && props.priceData.emission === 'PQ-CAT35'? true :false}
                                 /> 
                                    
                            </div>
                        :
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="PhaseIV" 
                                    disabled
                                    checked={props.priceData && props.priceData.emission === 'PHASEIV' ? true :false}
                                />  
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label=" PQ" 
                                    disabled
                                    checked={props.priceData && props.priceData.emission === 'PQ' ? true :false}
                                /> 
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label=" PQ CAT 35" 
                                    disabled
                                checked={props.priceData && props.priceData.emission === 'PQ-CAT35'? true :false}
                                />            
                            </div>
                        }
                    </div>
                    <div className="col-md-6 cm-col">
                        <div className="cm-checkbox-inline">
                            {editFlag ?
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                    checked={props.priceData && props.priceData.issueAsBulk ? true : false}
                                        color="primary"
                                    />
                                    }
                                    label="Issue ticket as bulk only"
                                />
                            :
                                <FormControlLabel
                                    disabled
                                    control={
                                        <Checkbox
                                        checked={props.priceData && props.priceData.issueAsBulk ? true : false}
                                        color="primary"
                                        />
                                    }
                                    label="Issue ticket as bulk only"
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 cm-col">
                        <label className="cm-head-label">Search Fare By</label>
                        {editFlag ?
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ADT Public" 
                                    checked={props.priceData && props.priceData.fareType === "ADT public" ? true : false}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ADT Private" 
                                    checked={props.priceData && props.priceData.fareType === "ADT prive" ? true :false}
                                />
                             
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="JCB" 
                                    checked={props.priceData && props.priceData.fareType === "JCB" ? true : false}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="PFA" 
                                    checked={props.priceData && props.priceData.fareType === "PFA" ? true : false}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ITX" 
                                    checked={props.priceData && props.priceData.fareType === "ITX" ? true : false}
                                />
                            </div>
                        :
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ADT Public"
                                    disabled 
                                    checked={props.priceData && props.priceData.fareType === "ADT public" ? true : false}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ADT Private" 
                                    disabled
                                    checked={props.priceData && props.priceData.fareType === "ADT prive" ? true :false}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="JCB" 
                                    disabled
                                    checked={props.priceData && props.priceData.fareType === "JCB" ? true : false}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="PFA" 
                                    disabled
                                    checked={props.priceData && props.priceData.fareType === "PFA" ? true : false}
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="ITX" 
                                    disabled
                                    checked={props.priceData && props.priceData.fareType === "ITX" ? true : false}
                                />
                            </div>
                        }
                    </div>
                    <div className="col-md-6 cm-col">
                        <label className="cm-head-label">Inter-Island</label>
                        {editFlag ?
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="Sans NB" 
                                    checked={props.priceData && props.priceData.disablePriceUnitConcept ? true : false}

                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="Total Sabre Ok"                  
                                    checked={props.priceData && props.priceData.validateTotal ? true : false}
                                />
                
                            </div>
                        :
                            <div className="cm-radio-inline">
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="Sans NB" 
                                    checked={props.priceData && props.priceData.disablePriceUnitConcept ? true : false}
                                    disabled
                                />
                                <FormControlLabel 
                                    control={<Radio color="primary" />} 
                                    label="Total Sabre Ok"  
                                    disabled                
                                    checked={props.priceData && props.priceData.validateTotal ? true : false}
                                />
                            </div>
                        }
                    </div>
                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">Account Code</label>
                        <div className="row">
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">VIT</label>
                                {editFlag ?
                                    <>
                                    <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <>
                                      
                                      <p> <input type="text" className="form-control cm-input" disabled value= {props.priceData && props.priceData.accountCode && props.priceData.accountCode.VIT } /></p>
                                    </>
                                }
                            </div>
                            
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">SKY</label>
                                {editFlag ?
                                    <>
                                        <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <>
                                   
                                   <p><input type="text" className="form-control cm-input" disabled value= {props.priceData && props.priceData.accountCode && props.priceData.accountCode.SKY } /> </p>     
                                    </>
                                }
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">DFW</label>
                                {editFlag ?
                                    <>
                                        <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <> 
                                   
                                  <p> <input type="text" className="form-control cm-input" disabled value= {props.priceData && props.priceData.accountCode && props.priceData.accountCode.DFW } />  </p> 
                                    </>
                                }
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">FUN</label>
                                {editFlag ?
                                    <>
                                    <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <>
                                   
                                   <p> <input type="text" className="form-control cm-input" disabled value= {props.priceData && props.priceData.accountCode && props.priceData.accountCode.FUN } />  </p>
                                    </>
                                }
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">TC</label>
                                {editFlag ?
                                    <>
                                        <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <>
                                 
                                   <p>  <input type="text" className="form-control cm-input" disabled value={props.priceData && props.priceData.accountCode && props.priceData.accountCode.TC } /> </p>  
                                    </>
                                }
                            </div>
                                
                        </div>                                
                    </div>

                    <div className="col-md-12 cm-col">
                        <label className="cm-head-label">Ticket Designator</label>
                        <div className="row">
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">VIT</label>
                                {editFlag ?
                                    <>
                                    <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <>
                                      
                                      <p> <input type="text" className="form-control cm-input" disabled value= {props.priceData && props.priceData.accountCode && props.priceData.ticketDesignator.VIT } /></p>
                                    </>
                                }
                            </div>
                            
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">SKY</label>
                                {editFlag ?
                                    <>
                                        <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <>
                                   
                                   <p><input type="text" className="form-control cm-input" disabled value= {props.priceData && props.priceData.accountCode && props.priceData.ticketDesignator.SKY } /> </p>     
                                    </>
                                }
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">DFW</label>
                                {editFlag ?
                                    <>
                                        <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <> 
                                   
                                  <p> <input type="text" className="form-control cm-input" disabled value= {props.priceData && props.priceData.accountCode && props.priceData.ticketDesignator.DFW } />  </p> 
                                    </>
                                }
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">FUN</label>
                                {editFlag ?
                                    <>
                                    <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <>
                                   
                                   <p> <input type="text" className="form-control cm-input" disabled value= {props.priceData && props.priceData.accountCode && props.priceData.ticketDesignator.FUN } />  </p>
                                    </>
                                }
                            </div>
                            <div className="col-md-4 cm-col">
                                <label className="cm-head-label">TC</label>
                                {editFlag ?
                                    <>
                                        <input className="form-control cm-input" placeholder="Enter Value" />
                                    </>
                                :
                                    <>
                                 
                                   <p>  <input type="text" className="form-control cm-input" disabled value={props.priceData && props.priceData.accountCode && props.priceData.ticketDesignator.TC } /> </p>  
                                    </>
                                }
                            </div>
                                
                        </div>                                
                    </div>

                </div>
            </div>
        </div>
    )
}
