// @ts-nocheck
import { SET_ITINENARY_DATA, SET_LOADING_DATA, SET_ACTIVE_ITINENARY_RESULT_KEY,SET_GROUP_ITINENARY_DATA,
    SET_SEAT_MAP_DATA, SET_TIME_TAKEN, SET_ITINENARY_ROWS_KEYS, SET_DETAILED_ITINENARY_ROWS ,SET_COUNT,
    SET_SEAT_MAP_DETAIL,SET_SEAT,SET_FLIGHT_DATA,SET_SEATMAP_LOADING_DATA,SET_PREV_SEAT,SET_EDIT_SEAT,
    SET_SAVE_SEAT,SET_PREPAID_SEAT_STATUS, SET_CARRIER_PAID_SEAT_DATA,SET_EDIT_SEAT_RECORD, SET_IS_PASSPORT_MANDATORY,SET_IS_EMAIL_MANDATORY,SET_IS_MOBILE_MANDATORY, SET_OPERATING_CARRIER_PAID_SEAT_DATA, SET_MARKETING_CARRIER_PAID_SEAT_DATA} from './../storeConstant';

export const setItinenaryData = (itinenary) => {
return {
   type: SET_ITINENARY_DATA,
   payload: itinenary
}
}

export const setLoadingData = (value) => {

return {
   type:SET_LOADING_DATA,
   payload: value
}
}

export const setActiveItinenaryResultKey = (value) => {

return {
   type:SET_ACTIVE_ITINENARY_RESULT_KEY,
   payload: value
}
}


export const setGroupItinenaryData = (groupItinary) => {
return {
   type: SET_GROUP_ITINENARY_DATA,
   payload: groupItinary
}
}

export const setSeatMapData = (seatMap) => {
return {
   type: SET_SEAT_MAP_DATA,
   payload: seatMap
}
}

export const setTimeTaken = (value) => {
return {
   type:SET_TIME_TAKEN,
   payload:value
}
}

export const setItinenaryRowsKeys = (keys) => {
return {
   type: SET_ITINENARY_ROWS_KEYS,
   payload: keys
}
}

export const setDetailedItinenaryRows = (itinenaryRows) => {
return {
   type: SET_DETAILED_ITINENARY_ROWS,
   payload: itinenaryRows
}
}

export const setCount=(value)=>{
console.log("count value",value)
return {
   type: SET_COUNT,
   payload: value
}
}

export const setSeatMapDetail = (seatMap) => {
return {
   type: SET_SEAT_MAP_DETAIL,
   payload: seatMap
}
}

export const setSeat = (seat) => {

return {
   type: SET_SEAT,
   seat: seat
}
}

export const setFlightData = (flightData) => {
return {
   type: SET_FLIGHT_DATA,
   flightData: flightData
}
}


export const setseatMapLoading = (value) => {

return {
   type:SET_SEATMAP_LOADING_DATA,
   payload: value
}
}

export const setPrevSeat = (seat) => {
return {
   type: SET_PREV_SEAT,
   seat: seat
}
}

export const setEditSeat = (seat) => {
return {
   type: SET_EDIT_SEAT,
   seat: seat
}
}


export const setSaveSeat = (seat) => {
return {
   type: SET_SAVE_SEAT,
   seat: seat
}
}

export const setPrePaidSeatStatus = (seat) => {
return {
   type: SET_PREPAID_SEAT_STATUS,
   seat: seat
}
}
export const setCarrierPaidSeatData = (seat) => {
return {
   type: SET_CARRIER_PAID_SEAT_DATA,
   seat: seat
}
}

export const setEditSeatRecord = (seat) => {
return {
   type: SET_EDIT_SEAT_RECORD,
   seat: seat
}
}

export const setIsPassportMandatory = (value) => {
return {
   type: SET_IS_PASSPORT_MANDATORY,
   payload: value
}
}

export const setIsEmailMandatory = (value) => {
return {
   type: SET_IS_EMAIL_MANDATORY,
   payload: value
}
}

export const setIsMobileMandatory = (value) => {
return {
   type: SET_IS_MOBILE_MANDATORY,
   payload: value
}
}
export const setOperatingCarrierPaidSeatData = (value) => {
return {
   type: SET_OPERATING_CARRIER_PAID_SEAT_DATA,
   payload: value
}
}
export const setMarketingCarrierPaidSeatData = (value) => {
return {
   type: SET_MARKETING_CARRIER_PAID_SEAT_DATA,
   payload: value
}
}

