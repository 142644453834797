import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import flightIcon from "../../_theme/_assets/images/flightIcon.svg";
import ThreeDots from "../../_theme/_assets/images/3 dots button.svg";
import TwoDots from "../../_theme/_assets/images/2 dots button.svg";
import Greendot from "../../_theme/_assets/images/Green dot button.svg";
import Yellowdot from "../../_theme/_assets/images/Yellow dot button.svg";
import bluedot  from "../../_theme/_assets/images/blue dot button.svg";
import moment from 'moment';
import SpinnerLoader from '../../pages/bookingConfirmation/Utils/spinner';
import { getFlightLogo } from '../../pages/bookingConfirmation/Utils/CommenFunction';
import usePagination from '../../comman/pagination';
import { Pagination } from '@material-ui/lab';
import { Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch ,useSelector } from "react-redux";
import { setCheckBoxObject, setTarifDetail, setClassData, setSelectedTarifData, setPrixDetails } from '../AppStore/ActionCreator/commonDataAction';
import { getOneTarif } from '../thunks/getOneTarif';
import { getClassData } from '../thunks/getClassData';
import { getOnePrix } from '../thunks/getOnePrix';
import { parseISO, format } from 'date-fns'


export default function ContractListResult(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const responseTarifs = props?.responseTarifs
    let localResponseTarifs = [...responseTarifs]
    // const [expanded, setExpanded] = useState([]);
    // const handleExpandAll = () => {
    //     setExpanded([1, 2, 3]); // Assuming you have three accordions with ids 1, 2, and 3
    // };
    // const [tarif, setTarif] = useState([...props?.responseTarifs])
    const tarif = [...props?.responseTarifs]
    const [checkBoxObject, setCheckBoxObjectlocal] = useState({"tarifs":[],"classes":[],"prixs":[]})
    const [bulkUpdate, setBulkUpdate] = useState(true);
    let [page, setPage] = useState(1);
    let [allTarifClassesPrixs, setAllTarifClassesPrixs] = useState([]);
    const [classes, setClasses] = useState(false);
    const [exapandAllView, setExapandAllView] = useState(false)
    const [mainAcc, setMainAcc] = useState("")
    const [tarifKeys, setTarifKeys] = useState([])
    const [classKeys, setClassKeys] = useState([])
    const [secAcc, setSecAcc] = useState("")
    const [prixKeys, setPrixKeys] = useState([])

    const classData = useSelector((state) => state.commonDataReducer.classData);

    useEffect(()=>{
        setBulkUpdate(true)
        setCheckBoxObjectlocal({"tarifs":[],"classes":[],"prixs":[]})
        setPage(1)
        let tarifs = []
        let classes = []
        let prixs = []
        props?.responseTarifs.map((tarif)=>{
            tarifs.push(tarif?.tarifKey);
            tarif?.classes.map((classData)=>{
                classes.push(classData?.clasKey)
                classData?.prix.map((prix)=>{
                    prixs.push(prix?.prixKey)
                })
            }) 
        })
            if(props?.viewResultAsClass){
                setExpanded([...tarifs])
                setInnExpanded([])
            }
            else if(props?.viewResultAsPrix){
                setExpanded([...tarifs])
                setInnExpanded([...classes])
            }else{
                setExpanded([])
                setInnExpanded([])
            }
        setTarifKeys([...tarifs])
        setClassKeys([...classes])
        setPrixKeys([...prixs])
    },[props?.responseTarifs, props?.viewResultAsPrix, props?.viewResultAsClass])

    const handleBulkUpdate = () => {
        bulkUpdate && setBulkUpdate(!bulkUpdate);
    };
    const getOriginDestination = (originDestination = "") => {
        const arr = originDestination.split(",");
        let addEllipsis = false;
        if(arr.length > 3){
            arr.length = 3;
            addEllipsis = true;
        }
        const returnValue =  arr.join(",");
        return addEllipsis ? `${returnValue}...` : returnValue;
    }

    const handleExpandAll = () => {
        if(exapandAllView){
            setExpanded([])
            setInnExpanded([])
            setExapandAllView(!exapandAllView)
        }else{
            setExpanded([...tarifKeys])
            setInnExpanded([...classKeys])
            setExapandAllView(!exapandAllView)
        }
    }

    //Pagination 
    const pageSize = 50;
    const count = Math.ceil(tarif.length / pageSize);
    const _DATA = usePagination(tarif, pageSize);
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
      };
    // const handleAccordionChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? [...expanded, panel] : expanded.filter((item) => item !== panel));
    //   };

    // note : this is for future scope where the only the data present on the present page will be expanded as per the option selected in view result as
    // useEffect(()=>{
    //     let tarifs = []
    //     let classes = []
    //     let prixs = []
    //     _DATA.currentData().map((tarif)=>{
    //         tarifs.push(tarif?.tarifKey);
    //         tarif?.classes.map((classData)=>{
    //             classes.push(classData?.clasKey)
    //             classData?.prix.map((prix)=>{
    //                 prixs.push(prix?.prixKey)
    //             })
    //         }) 
    //     })
    //     if(props?.viewResultAsClass){
    //         setExpanded([...tarifs])
    //         setInnExpanded([])
    //     }
    //     else if(props?.viewResultAsPrix){
    //         setExpanded([...tarifs])
    //         setInnExpanded([...classes])
    //     }else{
    //         setExpanded([])
    //         setInnExpanded([])
    //     }
    // },[_DATA.currentData()])

    const handleChangeCheckbox = (e, type, obj) => {
        const { name, checked } = e.target;
        let localCheckBox = {...checkBoxObject}
        if (name == "allSelect") {
            if(checked){
                if(!props?.viewResultAsPrix && !props?.viewResultAsClass){
                    localCheckBox['tarifs'] = [...tarifKeys]
                    localCheckBox['classes'] = [...classKeys]
                    localCheckBox['prixs'] = [...prixKeys]
                }
                if(props?.viewResultAsPrix){
                    localCheckBox['prixs'] = [...prixKeys]
                }
                if(props?.viewResultAsClass){
                    localCheckBox['classes'] = [...classKeys]
                    localCheckBox['prixs'] = [...prixKeys]
                }
            }
            else{
                localCheckBox['tarifs'] = []
                localCheckBox['classes'] = []
                localCheckBox['prixs'] = []
            }
        } else {
            if(checked){
                if(type == "tarif"){
                    localCheckBox['tarifs'].push(name)
                    let classesLocal = obj?.classes?.map((classVal)=>{return classVal?.clasKey})
                    localCheckBox['classes'] = [...localCheckBox['classes'],...classesLocal]
                    let prixLocal = obj?.classes?.map((classVal)=>{return classVal?.prix[0]?.prixKey})
                    localCheckBox['prixs'] = [...localCheckBox['prixs'],...prixLocal]
                }
                if(type == "class"){
                    localCheckBox['classes'].push(name)
                    const prixLocal = obj?.prix[0] ? obj?.prix[0]?.prixKey : "";
                    localCheckBox['prixs'].push(prixLocal)
                }
                if(type == "prix"){
                    localCheckBox['prixs'].push(name)
                }
            }
            else{
                if(type == "tarif"){
                    const index = localCheckBox['tarifs'].indexOf(name);
                    localCheckBox['tarifs'].splice(index, 1);
                    let classesLocal = obj?.classes?.map((classVal)=>{return classVal?.clasKey})
                    let tempClasses = removeSubset(localCheckBox['classes'],classesLocal)
                    localCheckBox['classes'] = tempClasses
                    let prixLocal = obj?.classes?.map((classVal)=>{return classVal?.prix[0]?.prixKey})
                    let tempPrixs = removeSubset(localCheckBox['prixs'],prixLocal)
                    localCheckBox['prixs'] = tempPrixs
                }
                if(type == "class"){
                    const index = localCheckBox['classes'].indexOf(name);
                    localCheckBox['classes'].splice(index, 1);
                    const indexPrix = localCheckBox['prixs'].indexOf(obj?.prix[0] ? obj?.prix[0]?.prixKey : "");
                    localCheckBox['prixs'].splice(indexPrix, 1);

                }
                if(type == "prix"){
                    const index = localCheckBox['prixs'].indexOf(name);
                    localCheckBox['prixs'].splice(index, 1);
                }
            }
          
        }
        setCheckBoxObjectlocal({...localCheckBox})
        
      };
      function removeSubset(arr, subset) {
        const exclude = [...subset];
        return arr.filter(x => {
          const idx = exclude.indexOf(x);
          if (idx >= 0) {
            exclude.splice(idx, 1);
            return false;
          }
          return true;
        });
      }

      const bulkUpdateForm = () => {
        dispatch(setCheckBoxObject(checkBoxObject))
        history.push("/bulkUpdate");
      }

      const cancelBulkUpdate = () => {
        setBulkUpdate(true)
        setCheckBoxObjectlocal({"tarifs":[],"classes":[],"prixs":[]})
      }

    const onClickTarifDetails = async (tarifKey) => {
        const tarifDetails = await getOneTarif(tarifKey)
        if (tarifDetails?.data) {
            dispatch(setTarifDetail(tarifDetails?.data))
            history.push('/newTariff')
        }
    }

    const openClass = async (id, tarif) => {
        let res = await getClassData(id?.clasKey)
        let tarifData = { ...id, ...tarif }
        dispatch(setSelectedTarifData(tarifData))
        dispatch(setClassData(res))
        history.push("/newClass")
    }
    const [expanded, setExpanded] = React.useState([]);

    const prixDetailsOnclick = async (prixKey, classValue, tarifValue) => {
        let classDetails = await getClassData(classValue?.clasKey);
        dispatch(setClassData(classDetails))
        let tarifData = { ...classValue, ...tarifValue }
        dispatch(setSelectedTarifData(tarifData))
        const prixDetails = await getOnePrix(prixKey)
        dispatch(setPrixDetails(prixDetails?.data))
        history.push("/newPrix")
    }

    const toggleAccordion = (index) => {
        setClasses(true)

        setExpanded((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            const indexToRemove = newExpanded.indexOf(index);
            if (indexToRemove !== -1) {
                newExpanded.splice(indexToRemove, 1);
            } else {
                newExpanded.push(index);
            }
            return newExpanded;
        });
    };
    
    const handleIconClick = (index) => (e) => {
        e.stopPropagation();
        toggleAccordion(index);
    };
    const [innExpanded, setInnExpanded] = React.useState([]);

    const toggleInnAccordion = (index) => {
        setInnExpanded((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            const indexToRemove = newExpanded.indexOf(index);
            if (indexToRemove !== -1) {
                newExpanded.splice(indexToRemove, 1);
            } else {
                newExpanded.push(index);
            }
            return newExpanded;
        });
    };
    
    const handleInnIconClick = (index) => (e) => {
        e.stopPropagation();
        toggleInnAccordion(index);
    };

    return (
        <>
        {props?.isLoading ? <div className="col-md-12 text-center mt-3"><SpinnerLoader/> </div> : <>
        {responseTarifs.length  ? 
        <>
            <div className='ContractRes__BtnSection'>
            <div>{bulkUpdate ? null : <Checkbox name='allSelect' onChange={(e)=>handleChangeCheckbox(e,"allSelect",null)} checked={(!props?.viewResultAsPrix && !props?.viewResultAsClass) ? (checkBoxObject['tarifs'].length == tarifKeys.length && checkBoxObject['classes'].length == classKeys.length && checkBoxObject['prixs'].length == prixKeys.length) : props?.viewResultAsClass ? (checkBoxObject['classes'].length == classKeys.length && checkBoxObject['prixs'].length == prixKeys.length) :  (checkBoxObject['prixs'].length == prixKeys.length)}/>}
                {!Boolean(process.env.REACT_APP_EDIT_CREATE_CONTRACT_MANAGEMENT) ?<Button className='Btn__BulkUpdate' onClick={handleBulkUpdate}>
                    Bulk Update
                </Button> : null}
                </div>
                <div>{`Total records: ${responseTarifs.length}`}</div>
                            {(!bulkUpdate)?<div className="col-md-6 text-right">
                                <button
                                    className="cm-secondary-btn mr-2"
                                    onClick={cancelBulkUpdate}
                                >
                                    x Cancel
                                </button>
                                <button
                                    className="cm-primary-btn"
                                    onClick={bulkUpdateForm}
                                    disabled={!(checkBoxObject['tarifs'].length || checkBoxObject['classes'].length || checkBoxObject['prixs'].length)}
                                >
                                    Next &gt; 
                                </button>

                            </div> : 
                            <Button className='Btn__Expandall'
                            onClick={handleExpandAll}
                           >
                               Expand all
                           </Button>
                            }
                
            </div>{ _DATA.currentData().map((tarif,index)=>{
                return(<div className='contractRes__Box'>
                    {(bulkUpdate || props?.viewResultAsClass || props?.viewResultAsPrix) ? null : <Checkbox className="contractRes__bluckU_Check" name={tarif?.tarifKey} onChange={(e)=>handleChangeCheckbox(e,"tarif", tarif)} checked={checkBoxObject['tarifs'].includes(tarif?.tarifKey)}/> }
                    <Accordion className='contractResult_Accordion'
                        expanded={expanded.includes(String(tarif?.tarifKey))}  
                >
                    <AccordionSummary
                        aria-controls="panel1-content"
                        className='contactListResultAccordian'
                        id="panel1-header">
                        <div className="contractRes__Section">
                            <div className="contractRes__icon" onClick={handleIconClick(String(tarif?.tarifKey,expanded))}>
                                {(bulkUpdate || props?.viewResultAsClass || props?.viewResultAsPrix) ?   <img className="contractRes__img" src={ThreeDots}  /> : null}
                            </div>
                            <div className="contractRes__main" onClick={()=>onClickTarifDetails(tarif?.tarifKey)}>
                                <div className="contractRes__Left">
                                    <div className="contractRes__alert">
                                        <sapn>T</sapn>
                                    </div>
                                    <div>
                                    <img className="contractRes__img" src={getFlightLogo(tarif?.carrier)} alt='l' /> 
                                        <span className='contractRes_mainTitle'>{tarif?.carrier}</span>
                                        <span className='contractRes_bar'>|</span>
                                        <span className='contractRes_subTitle'> VIA:</span>
                                        <span className='contractRes_mainTitle'> {tarif?.via}</span>
                                    </div>
                                    <div className='Origin'>
                                        <span className='contractRes_subTitle'>  Origin:</span>
                                        <Tooltip placement='top' title={tarif?.origins}><span className='contractRes_mainTitle'> {getOriginDestination(tarif?.origins)}</span></Tooltip>
                                    </div>
                                    <div >
                                        <span className='contractRes_subTitle'> Destinations: </span>
                                        <Tooltip placement='top' arrow title={tarif?.destinations}><span className='contractRes_mainTitle'> {getOriginDestination(tarif?.destinations)}</span></Tooltip>
                                    </div>
                                </div>
                                <div className="ContractRes__Right">
                                    <span className='contractRes_mainTitle contractRes_BrandFare'> {tarif?.isBrandedFare? "Branded Fare":"Non-Branded Fare"}</span>
                                    <span className='contractRes_subTitle' >Note: </span>
                                    <span className='contractRes_mainTitle'> {tarif?.note}</span>
                                </div>
                            </div>
                        </div>
                    </AccordionSummary>
                    { (classes ? tarif?.classes : []).map((classValue,Innindex)=>{
                        return (<div className='contractRes__Box'>
                            {(bulkUpdate || !props?.viewResultAsClass) ? null : <Checkbox className="contractRes__bluckU_Check" name={classValue?.clasKey} onChange={(e)=>handleChangeCheckbox(e,"class",classValue)} checked={checkBoxObject['classes'].includes(classValue?.clasKey) || false}/>}
                        <AccordionDetails >
                        <Accordion
                                       expanded={innExpanded.includes(String(classValue?.clasKey))}

                        >
                            <AccordionSummary
                                aria-controls="contractRes_childFirst"
                                id="contractRes_childFirst"
                            >
                                <div className="contractRes__Section  contractRes_childFirst">
                                    <div className="contractRes__icon" onClick={handleInnIconClick(String(classValue?.clasKey))}>
                                    {(bulkUpdate || !props?.viewResultAsClass) ? <img className="contractRes__img" src={TwoDots}  /> : null }
                                    </div>
                                    <div className="contractRes__main" onClick={()=>{openClass(classValue,tarif)}}>
    
                                        <div className="contractRes__Left">
                                            <div className="contractRes__alert">
                                                <sapn>C</sapn>
                                            </div>
                                            <div className='contractRes_childRight'>
                                                <div className='contractRes_classesOfC'>
                                                    <span className='contractRes_mainTitle'> Type:</span>
                                                    <span className='contractRes_mainTitle'> {classValue?.contractType}</span>
                                                </div>
                                                <div className='contractRes_classesOfC'>
                                                    <span className='contractRes_subTitle'>  Classes: </span>
                                                    <span className='contractRes_mainTitle' title={classValue?.classCodes.join(',')}> {classValue?.classCodes.join(',')}</span>
                                                </div>
                                                <div className='contractRes_classesOfC'>
                                                    <span className='contractRes_subTitle'> Brand: </span>
                                                    <span className='contractRes_mainTitle'>{classValue?.brandCode}</span>
                                                </div>
                                                <div className='contractRes_classesOfC'>
                                                    <span className='contractRes_subTitle'> Category: </span>
                                                    <span className='contractRes_mainTitle'>{classValue?.category}</span>
                                                </div>
                                                <div className='contractRes_classesOfC'>
                                                    <span className='contractRes_subTitle'> EDR:</span>
                                                    <span className='contractRes_mainTitle'> {classValue?.classLastTicketingDate ? format(parseISO(new Date(classValue?.classLastTicketingDate).toISOString().split('T')[0]), 'dd MMM yyyy') : ''}</span>
                                                </div>
                                                <div className='contractRes_classesOfC'>
                                                    <span className='contractRes_subTitle'>Note: </span>
                                                    <span className='contractRes_mainTitle'>{classValue?.classNote}</span>
                                                </div>
                                                <div className='contractRes_classesOfC'>
                                                    <span className='contractRes_subTitle'> Routing SRC:</span>
                                                    <span className='contractRes_mainTitle'>  {classValue?.classCodeDesc}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                            </AccordionSummary>
                            {classValue?.prix.map((prixValue, index)=>{
                                return(<div className='contractRes__Box'>
                                    {(bulkUpdate || !props?.viewResultAsPrix) ? null : <Checkbox className="contractRes__bluckU_Check" name={prixValue?.prixKey} onChange={(e)=>handleChangeCheckbox(e,"prix",prixValue)} checked={checkBoxObject['prixs'].includes(prixValue?.prixKey) || false} />}
                                    <AccordionDetails>
                                <div className="contractRes__Section  contractRes_childSecnd">
                                    <div className="contractRes__icon">
                                    {(bulkUpdate || !props?.viewResultAsPrix) ? <img className="contractRes__img" src={bluedot}  /> : null} 
                                    </div>
                                    <div className="contractRes__main" style={{"cursor":"pointer"}} onClick={()=>prixDetailsOnclick(prixValue?.prixKey, classValue, tarif)}>
    
                                        <div className="contractRes__Left">
                                            <div className="contractRes__alert">
                                                <sapn>p</sapn>
                                            </div>
                                            <div className='contractRes_childRight'>
                                                <div>
                                                    <span className='contractRes_mainTitle'> Validity: </span>
                                                    <span className='contractRes_mainTitle'> {`${moment(new Date(prixValue?.validFrom)).format("DD-MMM-yyyy")} to ${moment(new Date(prixValue?.validTo)).format("DD-MMM-yyyy")}`} </span>
                                                </div>
                                                <div>
                                                    <span className='contractRes_subTitle'> Our Comm:</span>
                                                    <span className='contractRes_mainTitle'>  {prixValue?.commissionPercentage} </span>
                                                </div>
                                                <div>
                                                    <span className='contractRes_subTitle'> Agency Comm:  </span>
                                                    <span className='contractRes_mainTitle'>{prixValue?.passBackCommission}</span>
                                                </div>
                                                <div>
                                                    <span className='contractRes_subTitle'> Markup:  </span>
                                                    <span className='contractRes_mainTitle'>{prixValue?.markup}</span>
                                                </div>
                                                <div>
                                                    <span className='contractRes_subTitle'> Tour Code:</span>
                                                    <span className='contractRes_mainTitle'>  {prixValue?.tourCode[0]?.tourCodes}</span>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                                </div>)
                            })}
                            
                        </Accordion>
                    </AccordionDetails>
                        </div>)
                    })
                    
                        }
                </Accordion>
                </div>)
            })   
            }
            <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
        />
        </>
        :
        null       
        }
    </>}

        </>
    );
}
