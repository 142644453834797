import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
// import authContext from '../../../context/auth-context'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from "react-redux";
import allContractSearch from "../../appStore/actions/allContractSearch";
import lastEvlKeyAction from '../../appStore/actions/lastEvlKeyAction';
import { getCommonDataForTariff } from "../../pages/bookingConfirmation/thunks/getCommonDataForTariff";
import { setBrandedFareTariff, setBrandIdClassTariff, setCabinTypeTariff, setCarrierTariff, setCategoryClassTariff, setClassClassTariff, setClassKeyClassTariff, setCruiseFareTypeTariff, setDescClassTariff, setEDRLastTicketingDatePrix, setEDRStartClassTariff, setEDRTicketingTariff, setERDEndClassTariff, setFareTypeClassTariff, setKeyTariff, setLandFareTypeTariff, setLastReturnDatePrix, setNoteClassTariff, setNotePrix, setPrixKeyPrix, setSearchAllV2, setTripTypeTariff, setValidityFromPrix, setValidityPrixToPrix, setViaTariff } from "../AppStore/ActionCreator/commonDataAction";
import ContractListResult from "./ContractListResult";
import { getcontracts } from "../../pages/bookingConfirmation/thunks/getcontracts";
import swal from 'sweetalert';
import { FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';

export default function NewContractlist() {

  const dispatch = useDispatch();
  const [viewResultAsClass, setViewResultAsClass] = useState(false)
  const [viewResultAsPrix, setViewResultAsPrix] = useState(false)
  const [value, setValue] = useState("tarif");
  const [responseTarifs, setResponseTarifs] = useState([])

  // VARIABLE FOR STORE LIST
  // const [serchFor, setSerchFor] = useState("tariff");
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();

  // SEARCH PARAMETER
  const [carrier, setCarrier] = useState("");
  const [tarifKey, setTarifKey] = useState("");
  const [via, setVia] = useState("");
  const [tarifNote, setTarifNote] = useState("");
  const [isBrandedFare, setIsBrandedFare] = useState("");

  const [tripType, setTripType] = useState("");
  const [cruiseFare, setCruiseFare] = useState(null);
  const [landFare, setLandFare] = useState(null);
  const [originValue, setoriginValue] = useState("");

  const [destinationValue, setDestinationValue] = useState("");
  const [businessCabinType, setBusinessCabinType] = useState(false);
  const [economyCabinType, setEconomyCabinType] = useState(false);
  const [premiumBusinessCabinType, setPremiuBusinessCabinType] = useState(false);
  const [premiumEconomyCabinType, setPremiumEconomyCabinType] = useState(false);
  const [firstClassCabinType, setFirstClassCabinType] = useState(false);

  // CLASS SEARCH
  const [brandId, setBrandId] = useState("");
  const [category, setCategory] = useState("");
  const [clasCodeDesc, setClasCodeDesc] = useState("");
  const [classNote, setClassNote] = useState("");
  const [contractType, setContractType] = useState("");
  const [classCodes, setClassCodes] = useState("");
  const [classKey, setClassKey] = useState("");

  // PRIX SEARCH
  const [prixnote, setPrixNote] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [lastReturnDate, setLastReturnDate] = useState("");
  const [lastTicketingDatePrix, setLastTicketingDatePrix] = useState("");
  const [prixKey, setPrixKey] = useState("");
  const [endorsement, setEndorsement] = useState("");
  const [tourCode, setTourCode] = useState("");
  const [prixEDREndDate, setPrixEDREndDate] = useState("");
  const [prixEDRStartDate, setPrixEDRStartDate] = useState("");

  const [classSearch, setClassSearch] = useState(true);
  const [prixSearch, setPrixSearch] = useState(true);
  const [classLastTicketingDateFrom, setClassLastTicketingDateFrom] = useState('')
  const [classLastTicketingDateTo, setClassLastTicketingDateTo] = useState('')
  const searchAllV2Result = useSelector((state)=>state.commonDataReducer.searchAllV2Result)
  useEffect(()=>{
    setResponseTarifs(searchAllV2Result)
  },[])

  //FUNCTION FOR REDIRECT TO CREATE CONTRACT PAGE


  useEffect(()=>{
    if(value == "class"){
      setViewResultAsClass(true)
      setViewResultAsPrix(false)
    }
    if(value == "prix"){
      setViewResultAsPrix(true)
      setViewResultAsClass(false)
    }
    if(value == "tarif"){
      setViewResultAsPrix(false)
      setViewResultAsClass(false)
    }
  },[value])

  // SEARCH API CALL FUNCTION 
  const tariffSearchApiCall = async () => {
    setIsLoading(true)
    //set cabinType
    let cabinTypeLocal = []
    if(economyCabinType){
      cabinTypeLocal.push("Economy")
    }
    if(businessCabinType){
      cabinTypeLocal.push("Business")
    }
    if(premiumBusinessCabinType){
      cabinTypeLocal.push("PremiumBusiness")
    }
    if(premiumEconomyCabinType){
      cabinTypeLocal.push("PremiumEconomy")
    }
    if(firstClassCabinType){
      cabinTypeLocal.push("First")
    }

    let payload = {
      "origins": originValue !="" ? originValue.split(",").map(function (value) {return value.trim();}) : undefined,
      "destinations": destinationValue != "" ? destinationValue.split(",").map(function (value) {return value.trim();}) : undefined,
      "tarifKey": tarifKey != "" ? tarifKey : undefined,
      "carrier": carrier != "" ? carrier.split(",").map(function (value) {return value.trim();}) : undefined,
      "via": via != "" ? via : undefined,
      "note": tarifNote != "" ? tarifNote : undefined,
      "isBrandedFare": isBrandedFare != "" ? isBrandedFare : undefined,
      "landFare": landFare != null ? landFare : undefined,
      "cruiseFare": cruiseFare != null ? cruiseFare : undefined,
      "cabinType": [...cabinTypeLocal].length ? [...cabinTypeLocal] : undefined,
      "tripType": tripType != "" ? tripType : undefined,
      "brandCode": brandId != "" ? brandId.split(",").map(function (value) {return value.trim();}) : undefined,
      "classCodes": classCodes != "" ? classCodes.split(",").map(function (value) {return value.trim();}) : undefined,
      "clasKey": classKey != "" ? classKey : undefined,
      "contractType": contractType != "" ? contractType : undefined,
      "classCodeDesc": clasCodeDesc != "" ? clasCodeDesc : undefined,
      "classNote": classNote != "" ? classNote : undefined,
      "category": category != "" ? category : undefined,
      "classEDRStartDate": classLastTicketingDateFrom != "" ? classLastTicketingDateFrom : undefined,
      "classEDREndDate": classLastTicketingDateTo != "" ? classLastTicketingDateTo : undefined,
      "validFrom": validFrom != "" ? validFrom : undefined,
      "validTo": validTo != "" ? validTo : undefined,
      "prixLastReturnStartDate": lastReturnDate != "" ? lastReturnDate : undefined,
      "prixLastReturnEndDate": lastTicketingDatePrix != "" ? lastTicketingDatePrix : undefined,
      "prixKey": prixKey != "" ? prixKey : undefined,
      "prixNote": prixnote != "" ? prixnote : undefined,
      "endorsement": endorsement != "" ? endorsement : undefined,
      "tourCode": tourCode != "" ? tourCode : undefined,
      "prixEDRStartDate": prixEDRStartDate != "" ? prixEDRStartDate : undefined,
      "prixEDREndDate": prixEDREndDate != "" ? prixEDREndDate : undefined
  }
  let allFildsAreEmpty = true;
  Object.keys(payload).map((key)=>{
    if(payload[key] != undefined){
      allFildsAreEmpty = false;
    }
  })
  if(allFildsAreEmpty){
    swal({
      text:"Please enter atleast one field",
      icon: "error",
    })
    setIsLoading(false)
    return null
  }
    let contract = await getcontracts(payload)
    if(contract?.message){
      swal({
        text:contract?.message,
        icon: "error",
      })
    }else{
      setResponseTarifs([...contract?.tarifs])
      dispatch(setSearchAllV2([...contract?.tarifs]))
    }
    setIsLoading(false)
  }

  // FUNCTION FOR CLEAR SEARCH FROM VALUE
  const clearSearch = () => {
    setIsLoading(false)
    setCarrier("");
    setTarifKey("")
    setVia("")
    setIsBrandedFare("")
    setTripType("")
    setCruiseFare(null)
    setLandFare(null)
    setoriginValue("")
    setDestinationValue("")
    setClassLastTicketingDateFrom('')
    setClassLastTicketingDateTo('')
    setPrixNote("");
    setValidFrom("");
    setValidTo("");
    setLastReturnDate("");
    setPrixKey("")
    setBrandId("");
    setCategory("");
    setClasCodeDesc("");
    // setNote("");
    setContractType("");
    setClassCodes("");
    setClassKey("");
    setClassLastTicketingDateFrom('')
    setClassLastTicketingDateTo('')
    setResponseTarifs([])
    setBusinessCabinType(false)
    setEconomyCabinType(false)
    setPremiuBusinessCabinType(false)
    setPremiumEconomyCabinType(false)
    setFirstClassCabinType(false)
    setTourCode("")

    //Tariff Search
    dispatch(setKeyTariff(""))
    dispatch(setCarrierTariff(""))
    dispatch(setViaTariff(""))
    dispatch(setCabinTypeTariff(""))
    dispatch(setTripTypeTariff(""))
    dispatch(setLandFareTypeTariff(0))
    dispatch(setCruiseFareTypeTariff(0))
    dispatch(setBrandedFareTariff(null))

    //Class Search
    dispatch(setBrandIdClassTariff(""))
    dispatch(setClassClassTariff(""))
    dispatch(setEDRTicketingTariff(""))
    dispatch(setCategoryClassTariff(""))
    dispatch(setDescClassTariff(""))
    dispatch(setNoteClassTariff(""))
    dispatch(setClassKeyClassTariff(""))
    dispatch(setFareTypeClassTariff(""))
    dispatch(setEDRStartClassTariff(""))
    dispatch(setERDEndClassTariff(""))

    //Prix Search
    dispatch(setValidityFromPrix(""))
    dispatch(setValidityPrixToPrix(""))
    dispatch(setLastReturnDatePrix(""))
    dispatch(setEDRLastTicketingDatePrix(""))
    dispatch(setPrixKeyPrix(""))
    dispatch(setNotePrix(""))
  }

  return <div>
    <div>
      <div className="card cm-card">
        <div className="card-body">

          {/* >>>>>>>>>>>>>>>>>>>>> tariff Search >>>>>>>>>>>>>>>>>>>>>. */}
          <div className="cm-head-2">
            <div className="d-flex justify-content-between">
              <span className="">Tariff Search</span>
              <div className="cm-head-checkbox d-flex">
                {!Boolean(process.env.REACT_APP_EDIT_CREATE_CONTRACT_MANAGEMENT) ? <button className="cm-yellow-btn ml-3" onClick={(e) => {}}> Create Tariff</button> : null}
              </div>
            </div>
          </div>
          <div>
            <div className="row mt-4">
              <div className="col-md-6 cm-col">
                <div className="row">
                  <div className="col-md-6">
                    <label className="cm-head-label">Origin</label>
                    
                    <input
                      className="form-control cm-input"
                      placeholder="Enter Origin ..."
                      value={originValue}
                      onChange={(e) => setoriginValue(String(e?.target?.value.trim()).toUpperCase())}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="cm-head-label">Destination</label>
                    <input
                      className="form-control cm-input"
                      placeholder="Enter Destination ..."
                      value={destinationValue}
                      onChange={(e) => setDestinationValue(String(e?.target?.value.trim()).toUpperCase())}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 cm-col">

                <div className="row">
                  <div className="col-md-6">
                    <label className="cm-head-label">Tariff Key</label>
                    <input
                      className="form-control cm-input"
                      placeholder="Enter tarif key"
                      value={tarifKey}
                      onChange={(e) => setTarifKey(String(e?.target?.value.trim()).toUpperCase())}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="cm-head-label">Validating Carrier</label>
                    <input
                      className="form-control cm-input"
                      placeholder="Enter validating carrier"
                      onChange={(e) => setCarrier(String(e?.target?.value.trim()).toUpperCase())}
                      value={carrier}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* VIA VALIDATING CARRIER SECTION */}
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 cm-col">
                    <label className="cm-head-label">VIA</label>
                    <input
                      className="form-control cm-input"
                      placeholder="Enter VIA"
                      onChange={(e) => setVia(String(e?.target?.value.trim()).toUpperCase())}
                      value={via}
                    />
                  </div>
                  <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Tariff Note</label>
                    <input
                      className="form-control cm-input"
                      placeholder="Enter Tariff Note"
                      onChange={(e) => setTarifNote(String(e?.target?.value.trim()).toUpperCase())}
                      value={tarifNote}
                    />
                  </div>
                </div>

              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Branded Fare</label>
                    <div className="cm-radio-inline">
                      <FormControlLabel control={<Radio color="primary" />} label="Yes"
                        checked={isBrandedFare === "1" ? true : false}
                        onChange={(e) => {if(e?.target?.checked){ setIsBrandedFare("1")}}}
                      />
                      <FormControlLabel control={<Radio color="primary" />} label="No"
                        onChange={(e) => {if(e?.target?.checked){ setIsBrandedFare("0")}}}
                        checked={isBrandedFare === '0'  ? true : false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Contract Types</label>
                    <div className="cm-checkbox-inline">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={landFare} onChange={(e) => setLandFare(landFare ? 0 : 1)}
                            color="primary"
                          />
                        }
                        label="Land"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cruiseFare} onChange={(e) => setCruiseFare(cruiseFare ? 0 : 1)}
                            color="primary"
                          />
                        }
                        label="Cruise"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(cruiseFare != null && landFare != null)?(!cruiseFare && !landFare):false} onChange={(e) => {setCruiseFare(0);setLandFare(0)}}
                            color="primary"
                          />
                        }
                        label="Air Only"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="row">
                <div className="row">
                  <div className="col-md-6 ">
                    <label className="cm-head-label">Cabin Type</label>
                   

                    <div className="row">
                      <div className="col-md-6 ">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={businessCabinType} onChange={() => setBusinessCabinType(!businessCabinType)}
                              color="primary"
                            />
                          }
                          label="Business"
                        />  </div>
                      <div className="col-md-6  ">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={economyCabinType} onChange={() => setEconomyCabinType(!economyCabinType)}
                              color="primary"
                            />
                          }
                          label="Economy"
                        />
                      </div>
                      <div className="col-md-6 ">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={premiumBusinessCabinType} onChange={() => setPremiuBusinessCabinType(!premiumBusinessCabinType)}
                              color="primary"
                            />
                          }
                          label="Premium Business"
                        />
                      </div>
                      <div className="col-md-6 ">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={premiumEconomyCabinType} onChange={() => setPremiumEconomyCabinType(!premiumEconomyCabinType)}
                              color="primary"
                            />
                          }
                          label="Premium Economy"
                        />
                      </div>
                      <div className="col-md-6 ">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={firstClassCabinType} onChange={() => setFirstClassCabinType(!firstClassCabinType)}
                              color="primary"
                            />
                          }
                          label="First Class"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 cm-col">
                    <label className="cm-head-label">Trip Type</label>
                    <div className="cm-radio-inline">
                      <FormControlLabel control={<Radio color="primary" />} label="One Way"
                        checked={tripType === "OW" ? true : false} onChange={(e) => setTripType("OW")}
                      />
                      <FormControlLabel control={<Radio color="primary" />} label="Not One Way"
                        checked={tripType === "-OW" ? true : false} onChange={(e) => setTripType("-OW")}
                      />{" "}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            
          </div>

          <div className="row headerCollap">
            <div className="col-6" onClick={() => setClassSearch(!classSearch)}>
              <p className="headerTitle">Class Search</p>
            </div>
            <div className="col-6 text-right" >
              {classSearch ? <i class="fas fa-caret-up" aria-hidden="true"></i> : <i class="fas fa-caret-down" aria-hidden="true"></i>}
            </div>
          </div>
          {classSearch &&
            <div>
              <div className="row">
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Brand Code </label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Brand Id"
                    value={brandId}
                    onChange={(e) => setBrandId(String(e?.target?.value.trim()).toUpperCase())}
                  />
                </div>
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label"> Class of Service </label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Classe"
                    value={classCodes}
                    onChange={(e) => setClassCodes(String(e?.target?.value.trim()).toUpperCase())}
                  />
                </div>
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Class Key</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter class key"
                    type="text"
                    value={classKey}
                    onChange={(e) => setClassKey(String(e?.target?.value.trim()).toUpperCase())}
                  />
                </div>
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Fare Type</label>
                  <div className="cm-radio-inline">
                    <FormControlLabel control={<Radio color="primary" />} label="(%) Published"
                      checked={contractType === 'Published' ? true : false}
                      onChange={() => setContractType('Published')}
                    />
                    <FormControlLabel control={<Radio color="primary" />} label="($) Net"
                      checked={contractType === 'Net' ? true : false} onChange={() => setContractType("Net")}
                    />
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Class Code Desc</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Desc"
                    value={clasCodeDesc}
                    onChange={(e) => setClasCodeDesc(String(e?.target?.value.trim()).toUpperCase())}
                  />
                </div>

                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Class Note</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter note"
                    type="text"
                    value={classNote}
                    onChange={(e) => setClassNote(String(e?.target?.value.trim()).toUpperCase())}
                  />
                </div>
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Category </label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter category"
                    value={category}
                    onChange={(e) => setCategory(String(e?.target?.value.trim()).toUpperCase())}
                  />
                </div>
              </div>
             
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6 cm-col">
                      <label className="cm-head-label">Class  EDR Start Date</label>
                      <input
                        className="form-control cm-input"
                        placeholder="Enter date"
                        type="date"
                        value={classLastTicketingDateFrom}
                        onChange={(e) => setClassLastTicketingDateFrom(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 cm-col">
                      <label className="cm-head-label">Class  EDR End Date</label>
                      <input
                        className="form-control cm-input"
                        placeholder="Enter date"
                        type="date"
                        value={classLastTicketingDateTo}
                        onChange={(e) => setClassLastTicketingDateTo(e.target.value)}
                      />
                    </div>
                  </div>

                </div>

              </div>
            </div>
          }
          <div className="row headerCollap">
            <div className="col-6" onClick={() => setPrixSearch(!prixSearch)}>
              <p className="headerTitle">Prix Search</p>
            </div>
            <div className="col-6 text-right" >
              {prixSearch ? <i class="fas fa-caret-up" aria-hidden="true"></i> : <i class="fas fa-caret-down" aria-hidden="true"></i>}
            </div>
          </div>
          {prixSearch &&
            <div>
              <div className="row align-items-end">
                <div className="col-md-6 cm-col">
                  {/* <label className="cm-head-label">Prix validity</label> */}
                  <div className="row">
                    <div className="col-md-6">
                      <label className="cm-head-label">Valid From</label>
                      <input
                        type="date"
                        className="form-control cm-input-date"
                        onChange={(e) => setValidFrom(e.target.value)}
                        value={validFrom}
                        max={validTo}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="cm-head-label">Valid To</label>
                      <input
                        type="date"
                        className="form-control cm-input-date"
                        onChange={(e) => setValidTo(e.target.value)}
                        value={validTo}
                        min={validFrom}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Last Return Start Date</label>
                  <input
                    type="date"
                    className="form-control cm-input-date"
                    onChange={(e) => setLastReturnDate(e.target.value)}
                    value={lastReturnDate}
                  />
                </div>
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Last Return End Date</label>
                  <input
                    type="date"
                    className="form-control cm-input-date"
                    onChange={(e) => setLastTicketingDatePrix(e.target.value)}
                    value={lastTicketingDatePrix}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Prix Key</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Prix Key"
                    onChange={(e) => setPrixKey(e?.target?.value?.trim())}
                    value={prixKey}
                  />
                </div>
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">Prix Note</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Note"
                    onChange={(e) => setPrixNote(e?.target?.value?.trim())}
                    value={prixnote}
                  />
                </div>

                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">  Endorsement</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Endorsement"
                    onChange={(e) => setEndorsement(e?.target?.value?.trim())}
                    value={endorsement}
                  />
                </div>
                <div className="col-md-3 cm-col">
                  <label className="cm-head-label">  Tour Code</label>
                  <input
                    className="form-control cm-input"
                    placeholder="Enter Tour Code"
                    onChange={(e) => setTourCode(e?.target?.value?.trim())}
                    value={tourCode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6 cm-col">
                      <label className="cm-head-label">Prix EDR Start Date</label>
                      <input
                        className="form-control cm-input"
                        placeholder="Enter date"
                        type="date"
                        onChange={(e) => setPrixEDRStartDate(e.target.value)}
                        value={prixEDRStartDate}
                      />
                    </div>
                    <div className="col-md-6 cm-col">
                      <label className="cm-head-label">Prix EDR End Date</label>
                      <input
                        className="form-control cm-input"
                        placeholder="Enter date"
                        type="date"
                        onChange={(e) => setPrixEDREndDate(e.target.value)}
                        value={prixEDREndDate}
                      />
                    </div>
                  </div>

                </div>

              </div>
            </div>
          }
          {/* BUTTON SECTION */}
          <div className="row cm-col mt-5">
            <div className='col-md-6'>
              <div className="cm-radio-inline">
              <FormControl>
                <FormLabel className="cm-head-label-inline"> View Results as: </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name={`radioGroup-${Math.random()}`}
                  value={value}
                  defaultValue={value}
                  onChange={({ target }) => setValue(target.value)}
                >
                  <FormControlLabel value="tarif" control={<Radio color="primary" />} label="Tarif" />
                  <FormControlLabel value="class" control={<Radio color="primary" />} label="Class" />
                  <FormControlLabel value="prix" control={<Radio color="primary" />} label="Prix" />
                </RadioGroup>
              </FormControl>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <button
                className="cm-secondary-btn mr-2"
                onClick={(e) => clearSearch()}
              >
                Clear
              </button>
              <button
                className="cm-primary-btn"
                onClick={(e) => tariffSearchApiCall()}
                disabled={isLoading}
              >
                {isLoading && <span className="mr-5 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}

                Search
              </button>

            </div>
          </div>
        </div>
      </div>


    </div>

    <div className="ContractRes__Accordion">
      <ContractListResult responseTarifs={responseTarifs ? responseTarifs : []} isLoading={isLoading} viewResultAsPrix={viewResultAsPrix} viewResultAsClass={viewResultAsClass} />
    </div>
  </div>;
}
