//@ts-nocheck
import React from "react";
import moment from "moment";
import {splitFromCapital} from "../bookingConfirmation/Utils/CommenFunction"

function timeConvert(n) {
  let number = n;
  let hours = (number / 60);
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return number = rhours + "h " + rminutes + "m";
}

function FlightDetailsTabMultiCity(props) {

  let fareBasisDetails = props.paxFare &&
  props.paxFare[0] &&
  props.paxFare[0].fareBasisDetails
  
  var cabin;
  let flightTime = 0;
  const itinenaryData = props.data
  return (
    <>
      {itinenaryData && itinenaryData.map(data => {
        
        return (
          <>
            {Object.values(data).map(value => {

              cabin = value && value.cabin
              return (
                <>
                  <div>
                    {value && value.flights && value.flights.map((flight, index) => {

                      let num = timeConvert(flight.durationMin);
                      let integerValue = parseInt(flight.durationMin);
                      flightTime += integerValue
                      let depdateTime = moment(flight.departureDateTime).format("HH:mm");
                      let arrdateTime = moment(flight.arrivalDateTime).format("HH:mm");

                      let brandedFareDetails = fareBasisDetails && fareBasisDetails.filter(flightId => flightId.flightId === flight.id)
                      let classOfService = brandedFareDetails && brandedFareDetails[0] && brandedFareDetails[0].classOfService


                      return (
                        <>
                          <div className="flight_tabs_hader">
                            <div class="row head">
                              <div className="flight_tabs-left">
                                <div className="text_title">
                                  <span>
                                    {flight.carrier} {flight.number} {classOfService}
                                  </span>
                                  <span>
                                    ({cabin && splitFromCapital(cabin)})
                                  </span>

                                </div>
                              </div>
                              <div className="flight_tabs-right">

                                <span> {flight.operatingCarrier && "Operated by " + flight.operatingCarrier + "|"}  {flight && flight.aircraftDescription}</span>


                              </div>
                            </div>
                            <div className="row flight_tabs-side">
                              <div className="ml-3">
                                {flight && flight.aircraftNoOfSeats &&
                                  <span>Seats: {flight.aircraftNoOfSeats}</span>
                                }

                              </div>
                            </div>
                          </div>




                          <div className="f-details-senction">
                            <div className="f-details-col">
                              <div className="fd-name-time">{flight && flight.departureAirportCity} ({flight.departureAirport})<br /><span>{depdateTime}</span></div>
                              {flight.departureTerminal !== undefined ?
                                <span className="fd-aireport-detail">{"Terminal " + flight.departureTerminal}</span>
                                :
                                <span className="fd-aireport-detail">{"Terminal " + " -"}</span>
                              }
                            </div>
                            <div className="fd-time">
                              {num} <i className="far fa-clock"></i>
                            </div>
                            <div className="f-details-col">
                              <div className="fd-name-time">{flight && flight.arrivalAirportCity} ({flight.arrivalAirport})<br /><span>{arrdateTime}</span></div>
                              {flight.arrivalTerminal !== undefined ?
                                <span className="fd-aireport-detail">{"Terminal " + flight.arrivalTerminal}</span>
                                :
                                <span className="fd-aireport-detail">{"Terminal " + " -"}</span>
                              }
                            </div>
                          </div>


                          {value && value.layovers && value.layovers[index]
                            ?
                            <div className='f-DetailsLayover'>
                              <div className='f-DetailsLayoverTitle'>
                                <span className='f-DetailsLayoverTym'>{timeConvert(value.layovers[index].durationMinutes)} </span>
                                {"Layover in " + flight.arrivalAirport}
                              </div>
                            </div>
                            : null
                          }

                        </>
                      );
                    })}


                  </div>
                </>


              )


            })}

          </>
        )





      })}
      <div className="fd-total-time">
        <div>Total Flight Time <span><i className="far fa-clock"></i>{timeConvert(flightTime)}</span></div>
        <div>Total Trip Duration <span><i className="far fa-clock"></i>{timeConvert(flightTime)}</span></div>
      </div>

    </>
  );
}

export default FlightDetailsTabMultiCity;
