//@ts-nocheck
import { SET_FARE_OPTIONS , SET_ACTIVE_FARE_ID, SET_FARE_ID_FOR_BRANDED_FARE, SET_ACTIVE_INDEX_FOR_BRANDED_FARE, 
    SET_RADIO_CLICKED_STATUS, SET_KEY_FOR_BRANDED_FARE_ID, SET_BRANDED_ITINENARY_ID, SET_BRAND_CODE, SET_INBOUND_BRAND_DATA, SET_GRP_ITINENARY_FARE_ID } from '../storeConstant';

export const setFareOption = (fareOptionsResult) => {
return {
   type: SET_FARE_OPTIONS,
   payload: fareOptionsResult
}
}

export const setActiveFareId = (value) => {

return {
   type: SET_ACTIVE_FARE_ID,
   payload: value
}

}

export const setFareIdForBrandedFare = (value) => {

return {
   type: SET_FARE_ID_FOR_BRANDED_FARE,
   payload: value
}

}

export const setActiveIndexForBrandedFare = (value) => {

return {
   type: SET_ACTIVE_INDEX_FOR_BRANDED_FARE,
   payload: value
}

}

export const setKeyForBrandedFareId = (value) => {
return {
   type: SET_KEY_FOR_BRANDED_FARE_ID,
   payload: value
}
}

export const setRadioClickedStatus = (value) => {
return {
   type: SET_RADIO_CLICKED_STATUS,
   payload: value
}
}

export const setBrandedItinenaryId = (value) => {
return {
   type: SET_BRANDED_ITINENARY_ID,
   payload: value
}
}

export const setBrandCode = (value) => {
return {
   type: SET_BRAND_CODE,
   payload: value
}
}

export const setInboundBrandData = (value) => {
return {
   type: SET_INBOUND_BRAND_DATA,
   payload: value
}
}

export const setGrpItinenaryFareId = (value) => {
return {
   type: SET_GRP_ITINENARY_FARE_ID,
   payload: value
}
}

