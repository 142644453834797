import { SET_BARNDED_FARE_TARIFF, SET_BRAND_ID_CLASS_TARIFF, SET_CABIN_TYPE_TARIFF, SET_CARRIER_TARIFF, SET_CATEGORY_CLASS_TARIFF, SET_CLASS_CLASS_TARIFF, SET_CLASS_KEY_CLASS_TARIFF, SET_COMMON_DATA_TARRIF, SET_CRUISE_FARE_TYPE_TARIFF, SET_DESC_CLASS_TARIFF, SET_EDR_END_CLASS_TARIFF, SET_EDR_END_DATE_PRIX, SET_EDR_LAST_TICKETING_DATE_PRIX, SET_EDR_START_CLASS_TARIFF, SET_EDR_START_DATE_PRIX, SET_EDR_TICKETING_TARIFF, SET_FARE_TYPE_CLASS_TARIFF, SET_KEY_TARIFF, SET_LAND_FARE_TYPE_TARIFF, SET_LAST_RETURN_DATE_PRIX, SET_NOTE_CLASS_TARIFF, SET_NOTE_PRIX, SET_PRIX_KEY_PRIX, SET_TRIP_TYPE_TARIFF, SET_VALIDITY_FROM_PRIX, SET_VALIDITY_TO_PRIX, SET_VIA_TARIFF, SET_CHECKBOX_OBJECT, SET_TARIF_DETAILS, SET_CLASS_DATA, SELECTED_TARIF_DATA, SET_SEARCH_ALL_V2, SET_PRIX_DETAIL } from "../../../pages/bookingConfirmation/storeConstant";

export const setCommonData = (value: any) => {
    return {
        type: SET_COMMON_DATA_TARRIF,
        payload: value
    }
}

export const setCarrierTariff = (value: any) => {
    return {
        type: SET_CARRIER_TARIFF,
        payload: value
    }
}

export const setKeyTariff = (value: any) => {
    return {
        type: SET_KEY_TARIFF,
        payload: value
    }
}

export const setViaTariff = (value: any) => {
    return {
        type: SET_VIA_TARIFF,
        payload: value
    }
}

export const setCabinTypeTariff = (value: any) => {
    return {
        type: SET_CABIN_TYPE_TARIFF,
        payload: value
    }
}

export const setTripTypeTariff = (value: any) => {
    return {
        type: SET_TRIP_TYPE_TARIFF,
        payload: value
    }
}

export const setLandFareTypeTariff = (value: any) => {
    return {
        type: SET_LAND_FARE_TYPE_TARIFF,
        payload: value
    }
}

export const setCruiseFareTypeTariff = (value: any) => {
    return {
        type: SET_CRUISE_FARE_TYPE_TARIFF,
        payload: value
    }
}

export const setBrandedFareTariff = (value: any) => {
    return {
        type: SET_BARNDED_FARE_TARIFF,
        payload: value
    }
}
// Class Search
export const setBrandIdClassTariff = (value: any) => {
    return {
        type: SET_BRAND_ID_CLASS_TARIFF,
        payload: value
    }
}

export const setClassClassTariff = (value: any) => {
    return {
        type: SET_CLASS_CLASS_TARIFF,
        payload: value
    }
}

export const setEDRTicketingTariff = (value: any) => {
    return {
        type: SET_EDR_TICKETING_TARIFF,
        payload: value
    }
}

export const setCategoryClassTariff = (value: any) => {
    return {
        type: SET_CATEGORY_CLASS_TARIFF,
        payload: value
    }
}

export const setDescClassTariff = (value: any) => {
    return {
        type: SET_DESC_CLASS_TARIFF,
        payload: value
    }
}

export const setNoteClassTariff = (value: any) => {
    return {
        type: SET_NOTE_CLASS_TARIFF,
        payload: value
    }
}

export const setClassKeyClassTariff = (value: any) => {
    return {
        type: SET_CLASS_KEY_CLASS_TARIFF,
        payload: value
    }
}

export const setFareTypeClassTariff = (value: any) => {
    return {
        type: SET_FARE_TYPE_CLASS_TARIFF,
        payload: value
    }
}

export const setEDRStartClassTariff = (value: any) => {
    return {
        type: SET_EDR_START_CLASS_TARIFF,
        payload: value
    }
}

export const setERDEndClassTariff = (value: any) => {
    return {
        type: SET_EDR_END_CLASS_TARIFF,
        payload: value
    }
}

// Prix

export const setValidityFromPrix = (value: any) => {
    return {
        type: SET_VALIDITY_FROM_PRIX,
        payload: value
    }
}

export const setValidityPrixToPrix = (value: any) => {
    return {
        type: SET_VALIDITY_TO_PRIX,
        payload: value
    }
}

export const setLastReturnDatePrix = (value: any) => {
    return {
        type: SET_LAST_RETURN_DATE_PRIX,
        payload: value
    }
}

export const setEDRLastTicketingDatePrix = (value: any) => {
    return {
        type: SET_EDR_LAST_TICKETING_DATE_PRIX,
        payload: value
    }
}

export const setPrixKeyPrix = (value: any) => {
    return {
        type: SET_PRIX_KEY_PRIX,
        payload: value
    }
}

export const setNotePrix = (value: any) => {
    return {
        type: SET_NOTE_PRIX,
        payload: value
    }
}

export const setEDRStartDatePrix = (value: any) => {
    return {
        type: SET_EDR_START_DATE_PRIX,
        payload: value
    }
}

export const setEDREndDatePrix = (value: any) => {
    return {
        type: SET_EDR_END_DATE_PRIX,
        payload: value
    }
}

export const setCheckBoxObject = (value: any) => {
    return {
        type: SET_CHECKBOX_OBJECT,
        payload: value
    }
}

export const setTarifDetail = (value: any) => {
    return {
        type: SET_TARIF_DETAILS,
        payload: value
    }
}

export const setClassData = (value:any)=>{
    return{
        type: SET_CLASS_DATA,
        payload: value
    }
}

export const setSelectedTarifData = (value:any) => {
    return{
        type:SELECTED_TARIF_DATA,
        payload:value
    }
}

export const setSearchAllV2 = (value:any) => {
    return{
        type:SET_SEARCH_ALL_V2,
        payload:value
    }
}

export const setPrixDetails = (value:any) => {
    return{
        type:SET_PRIX_DETAIL,
        payload:value
    }
}