//@ts-nocheck
import React,{useEffect, useState} from 'react';
import { Table } from 'react-bootstrap';
import { changeDateFormat } from "../bookingConfirmation/Utils/CommenFunction"
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPnr, setBookingDetails, setItenaryData } from "../bookingConfirmation/ActionCreator/flightBooking"
import { setBookingItinenary,setBookingFareId,setTripType,setProvideId, setDepartDate } from "../bookingConfirmation/ActionCreator/searchForm"
import SpinnerLoader from '../bookingConfirmation/Utils/spinner';
import { setAdultMarkupValue, setChildMarkupValue, setInfantMarkupValue } from "../bookingConfirmation/ActionCreator/searchForm"
// import { setBookingWithQuote } from '../bookingConfirmation/ActionCreator/myq';
import sort1 from "../bookingConfirmation/Images/sort-1.svg";
import sort2 from "../bookingConfirmation/Images/sort-2.svg";
import sort3 from "../bookingConfirmation/Images/sort-3.svg";
import {bookingStatus} from "../bookingConfirmation/Utils/Constant";
import { useSelector } from "react-redux";


export default function Allpnrbooking(props) {
  let { myFilesData } = props
  let isLoading = props.isLoading
  let isLoader = props.isLoader
  const dispatch = useDispatch();
  const history = useHistory();


  const [isStatusAsc,setStatusAsc] = useState("")
  const [isReserveAsc,setReservationAsc] = useState("")
  const [isDepartAsc,setDeaprtAsc] = useState("")
  const [isPaxAsc,setPaxAsc] = useState("")
  const [isAgentAsc,setAgentAsc] = useState("")
  const [isDestinationAsc,setDestinationAsc] = useState("")
  const [isPnrAsc,setPnrAsc] = useState("")
  const [statusCode,setStatusCode] = useState([])

  const getPnrDetails = (pnrNO, bookingResult) => {
    let tripType = bookingResult&&bookingResult.tripType;
    let fareId = bookingResult&& bookingResult.bookingDetails && bookingResult.bookingDetails.itineraryFare && bookingResult.bookingDetails.itineraryFare.id;
    if (pnrNO) {
      dispatch(setPnr(pnrNO))
      dispatch(setBookingDetails([]))
      dispatch(setItenaryData([]))
      dispatch(setBookingItinenary(""))
      //dispatch(setBookingFareId(fareId))
      dispatch(setTripType(tripType))
      dispatch(setProvideId(bookingResult &&  bookingResult.bookingDetails&& bookingResult.bookingDetails.itineraryFare && bookingResult.bookingDetails.itineraryFare.providerId))
      dispatch(setAdultMarkupValue(0))
      dispatch(setChildMarkupValue(0))
      dispatch(setInfantMarkupValue(0))
      //dispatch(setBookingWithQuote(false))
      history.push("/booking-confirmation")
    }

  }

  
  const sortStatus =()=>{
      props.sortData("status", isStatusAsc)
      setStatusAsc(isStatusAsc==="" ?true:!isStatusAsc)
  }

  const sortReservation =()=>{
    props.sortData("reservation", isReserveAsc)
    setReservationAsc(isReserveAsc===""?true:!isReserveAsc)
  }

  const sortDeparture =()=>{
    props.sortData("departure", isDepartAsc)
    setDeaprtAsc(isDepartAsc==="" ?true :!isDepartAsc)
  }

  const sortPaxName =()=>{
    props.sortData("paxName", isPaxAsc)
    setPaxAsc(isPaxAsc===""?true:!isPaxAsc)
  }

  const sortAgentName =()=>{
    props.sortData("agentName", isAgentAsc)
    setAgentAsc(isAgentAsc===""?true:!isAgentAsc)
  }

  const sortDestination =()=>{
    props.sortData("destination", isDestinationAsc)
    setDestinationAsc(isDestinationAsc===""?true:!isDestinationAsc)
  }

  const sortPnr =()=>{
    props.sortData("pnr", isPnrAsc)
    setPnrAsc(isPnrAsc===""?true:!isPnrAsc)
  }

  useEffect(()=>{
    let StatusArr = [];
  bookingStatus.map((status) => {
    for (let i = 0; i < myFilesData?.length; i++) {
      if (
        myFilesData[i] &&
        myFilesData[i].bookingStatusDetail &&
        myFilesData[i].bookingStatusDetail.code === status.code && myFilesData[i].bookingStatusDetail.code !== "PNTC" 
      ) {
        StatusArr[i] = status.description;
      } else if (
        myFilesData[i] &&
        myFilesData[i].bookingStatusDetail && (
        (myFilesData[i].bookingStatusDetail.code === "TC") || (myFilesData[i].bookingStatusDetail.code === "NTC") || (myFilesData[i].bookingStatusDetail.code === "CR") ||
        (myFilesData[i].bookingStatusDetail.code === "PNTC")) 
      ) {
        StatusArr[i] = "CANCELLED";
      }
    }
  });
  setStatusCode(StatusArr);
  },[isStatusAsc,myFilesData,isPnrAsc,isDestinationAsc,isAgentAsc,isPaxAsc,isDepartAsc,isReserveAsc])

  const isTimeoutErrorFlag = useSelector((state) => state.myFileError.myFilesDataError);
  const [isTimeoutError ,setIsTimeoutError] =  useState(isTimeoutErrorFlag)
  useEffect(()=>{
    setIsTimeoutError(isTimeoutErrorFlag)
  },[isTimeoutErrorFlag])
  



  return (
    <div className="pnrdata">
      {isLoading || isLoader ?
        <>
         <div className="col-md-12 text-center mt-3">
          {isTimeoutError === false ? <SpinnerLoader /> : "A technical error has occurred. Please try again." 
          
            }
         </div>
        </>
        :
      <> <Table responsive="sm" className="pnrbookintable ">
        <thead>
          <tr>
            <th onClick={sortStatus}>Status 
            {isStatusAsc==="" &&<img className=" ml-1 mb-1" src={sort1}/>} 
            {isStatusAsc ===true && <img className="ml-1 mb-1" src={sort2}/>} 
            {isStatusAsc===false && <img className=" ml-1 mb-1" src={sort3}/>}</th>

            <th className="secondthtd" onClick={sortReservation}>Reservation
            {isReserveAsc==="" &&<img className=" ml-1 mb-1" src={sort1}/>} 
            {isReserveAsc ===true && <img className=" ml-1 mb-1" src={sort2}/>} 
            {isReserveAsc===false && <img className=" ml-1 mb-1" src={sort3}/>}
            </th>

            <th onClick={sortDeparture}>Departure
            {isDepartAsc==="" &&<img className=" ml-1 mb-1" src={sort1}/>} 
            {isDepartAsc ===true && <img className=" ml-1 mb-1" src={sort2}/>} 
            {isDepartAsc===false && <img className=" ml-1 mb-1" src={sort3}/>}
            </th>

            <th onClick={sortPaxName}>Pax Name
            {isPaxAsc==="" &&<img className=" ml-1 mb-1" src={sort1}/>} 
            {isPaxAsc ===true && <img className=" ml-1 mb-1" src={sort2}/>} 
            {isPaxAsc===false && <img className=" ml-1 mb-1" src={sort3}/>}
            </th>

            <th className="fifththtd" onClick={sortAgentName}>Agent Name
            {isAgentAsc==="" &&<img className=" ml-1 mb-1" src={sort1}/>} 
            {isAgentAsc ===true && <img className=" ml-1 mb-1" src={sort2}/>} 
            {isAgentAsc===false && <img className=" ml-1 mb-1" src={sort3}/>}
            </th>

            {/* <th onClick={sortDestination}>Destination
            {isDestinationAsc==="" &&<img className=" ml-1 mb-1" src={sort1}/>} 
            {isDestinationAsc ===true && <img className=" ml-1 mb-1" src={sort2}/>} 
            {isDestinationAsc===false && <img className=" ml-1 mb-1" src={sort3}/>}
            </th> */}

            <th onClick={sortPnr}>PNR Number
            {isPnrAsc==="" &&<img className=" ml-1 mb-1" src={sort1}/>} 
            {isPnrAsc ===true && <img className=" ml-1 mb-1"  src={sort2}/>} 
            {isPnrAsc===false && <img  className=" ml-1 mb-1" src={sort3}/>}
            </th>


          </tr>
        </thead>
       
        <tbody>
          {myFilesData && myFilesData.length > 0 ?
            myFilesData.map((myFiles,i) => {
              let isDufflePNR = myFiles?.bookingDetails?.itineraryFare?.providerName == "NDC"?true:false;
              return (<tr>
                <td><p className="capara"><span className="ca">{statusCode[i]}</span></p></td>
                <td className="secondthtd">{changeDateFormat(myFiles.bookingDate, "monthFormat")}</td>
                <td>{changeDateFormat(myFiles.departureDate, "monthFormat")}</td>
                <td>{myFiles.bookingDetails && myFiles.bookingDetails.passengers && myFiles.bookingDetails.passengers.length > 0 ? `${myFiles.bookingDetails.passengers[0].firstName} ${myFiles.bookingDetails.passengers[0].lastName}` : ""}</td>
                <td className="fifththtd">{myFiles.agentName}</td>
                {/* <td>{myFiles.bookingDetails && myFiles.bookingDetails.segments && myFiles.bookingDetails.segments.length > 0 && myFiles.bookingDetails.segments[0].destination}</td> */}
                <td onClick={() => getPnrDetails(myFiles.bookingDetails.recordLocator, myFiles)}>
                  {myFiles.bookingDetails && myFiles.bookingDetails.recordLocator}
                  {isDufflePNR?<span style={{"fontWeight": "700","float": "right","color": "black","lineBreak": "normal","marginRight": "34px"}}>WEB</span>:<></>}
                </td>
              </tr>)
             }) :<>{props.isResult && <div className="mt-3 ">No result found</div>}</> }

        </tbody>
        
      </Table></>
}
    </div>
  )
}
