import {
  SALE_LIST,
  SEARCH_FORM_DATA
  } from "../constant/types";
  
  const intialState:any = {
       saleList:[],
       searchFormData:{}
         };
  
  export const SaleListData = (state = intialState, action:any) => {
    switch (action.type) {
      case SALE_LIST:
        return {
          ...state,
          saleList: action.payload,
        };
      case SEARCH_FORM_DATA:
        return {
          ...state,
          searchFormData: action.payload,
        };
      default:
        return state;
    }
  };
  export default SaleListData;