//@ts-nocheck
import React from 'react';
import BookingSubHead from './BookingSubHead';
import cardImg from "../bookingConfirmation/Images/credit-card-payment.svg";
import chequeImg from "../bookingConfirmation/Images/cheque.svg";
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import { PassengerType } from '../bookingConfirmation/Utils/Constant';
import PayableAmountBreakUp from './PayableAmountBreakup';

function BookingPaymentSummary(props) {

    const paymentDetailsAddons = useSelector((state) => state.searchForm.paymentDetailsAddons);
    const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
    const fareTypeByPnr = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.type;
    const paymentRules = useSelector((state) => state.flightBooking.paymentRules?.PassengerWiseTransactionsDetails);
    let prepaidSeatArray=[]

    let seatTotalAmount = 0;

    let aCount=0;
    let cCount=0;
    if (paymentDetailsAddons) {
        for (let i = 0; i < paymentDetailsAddons.length; i++) {
            let totalAmount = 0;
            let ccNumber = 0;
            let paxType = "";
            let count;

            if (paymentDetailsAddons[i].appliedTransactions) {
                for (let j = 0; j < paymentDetailsAddons[i].appliedTransactions.length; j++) {
                    totalAmount += paymentDetailsAddons[i].appliedTransactions[j].transactionsDetails[0].totalAmount.amount;
                }
            }
            if (paymentDetailsAddons[i].creditCard) {
                ccNumber = paymentDetailsAddons[i].creditCard.number.slice(-4);
            }
            bookingData.passengers.map((item) => {
                if (item.id == paymentDetailsAddons[i].addOns[0].passengerRefId) {
                    paxType = item.paxType;
                    count = item.paxType == 'Adult' ? ++aCount : ++cCount
                }
            })


            let obj = {
                "totalAmount": totalAmount,
                "ccNumber": ccNumber,
                "paxType": paxType,
                "count": count
            }

            prepaidSeatArray.push(obj);
            seatTotalAmount += totalAmount

        }
    }

    const paxTypeAmount = props.paxFares
    const paymentTypeDetails = props.paymentTypeDetails
    const creditCardNumber = paymentTypeDetails && paymentTypeDetails.creditCard && paymentTypeDetails.creditCard.number
    const passenger = props.passengerData
    let total = 0

    const [airlinepaymet,setAir ]= React.useState(0)
    const [intairPayment,setintir ]= React.useState(0)   

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (arr) => {

        setOpen(true);
        let airlinepaymet = 0
        let intairPayment =0
        arr.map(obj => {

            if (obj.transactions === 2) {
                let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                let num2 = obj.transactionsDetails && obj.transactionsDetails[1] && obj.transactionsDetails[1].totalAmount && obj.transactionsDetails[1].totalAmount.amount
                airlinepaymet = airlinepaymet + Number(num1)
                intairPayment = intairPayment + Number(num2)

            } else {
                let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                airlinepaymet = airlinepaymet + Number(num1)


            }
        })
        let valueAirline = Number(airlinepaymet).toFixed(2)
        let valueintair = Number(intairPayment).toFixed(2)
        setAir(valueAirline)
        setintir(valueintair)
    };
    const handleClose = () => {
        setOpen(false);
    };

    let travlerCount = 0;
    let travlerCountAdult = 0;
    let travlerCountChild = 0;
    let travlerCountInfant = 0;
    let amountInfo = [];


    paxTypeAmount && paxTypeAmount.map((paxType, index) => {
        let passengerDetails = paxType.passengerDetails;
        let type = passengerDetails && passengerDetails.type;
        let quantity = passengerDetails && passengerDetails.quantity
        let totalAmount = paxType.totalAmount
        let baseAmount = paxType.baseAmount
        let totalTaxes = paxType.totalTaxes
        let taxes = paxType.taxes
        let cnt = quantity;
        while (cnt != 0) {
            let passenger = {
                type: type,
                quantity: quantity,
                totalAmount: totalAmount.amount,
                baseAmount: baseAmount.amount,
                totalTaxes: totalTaxes.amount,
                taxes: taxes
            }
            amountInfo.push(passenger)
            cnt--;
        }
    })

    let totalAmount = 0;
    amountInfo && amountInfo.map((data) => {
        totalAmount += data.totalAmount

    })

    return (
        <div>
            {props?.from == "payment" ?
                <>
                    {((paymentTypeDetails && paymentTypeDetails?.length !== 0)) &&
                        <div className="bd-paymentS-box">
                            <BookingSubHead title={"Payment Summary"} />

                            <div>
                                {((paymentTypeDetails && paymentTypeDetails?.length !== 0)) ?
                                    
                                    paymentTypeDetails && paymentTypeDetails.map((paymentObj) => {
                                        let lastFourDigitNumber = paymentObj.creditCard && paymentObj.creditCard.number && paymentObj.creditCard.number.slice(-4)
                                        let totalByCard = 0
                                        let totalByCheck = 0

                                        let appliedTransactions = paymentObj.appliedTransactions
                                        if (paymentObj.type === "CreditCard") {
                                            appliedTransactions.map(obj => {

                                                if (obj.transactions === 2) {
                                                    let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                    let num2 = obj.transactionsDetails && obj.transactionsDetails[1] && obj.transactionsDetails[1].totalAmount && obj.transactionsDetails[1].totalAmount.amount

                                                    totalByCard = (Number(totalByCard)
                                                        + Number(num1)
                                                        + Number(num2)).toFixed(2)
                                                } else {
                                                    let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                    totalByCard = (Number(totalByCard)
                                                        + Number(num1)).toFixed(2)


                                                }
                                            })
                                        } else {
                                            appliedTransactions.map(obj => {



                                                let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                totalByCheck = (Number(totalByCheck)
                                                    + Number(num1)).toFixed(2)


                                            })
                                        }
                                        total = (Number(total) + Number(totalByCard) + Number(totalByCheck)).toFixed(2)
                                        return (
                                            <>
                                                <div className="bd-paymentS-item">
                                                    <strong>Payment</strong>

                                                </div>
                                                {paymentObj && paymentObj.type === "CreditCard" ?
                                                    <div className="bd-paymentS-details">
                                                        <img src={cardImg} alt="img" className="payImg" />

                                                        <div className="bd-paymentS-item">
                                                            By Card Ending In {lastFourDigitNumber}<strong>$ {Number(totalByCard).toFixed(2)}</strong>
                                                            {airlinepaymet > 0 && intairPayment > 0 && <i className="fas fa-info-circle payS-info" onClick={() => handleClickOpen(paymentObj.appliedTransactions)}></i>}
                                                            <Dialog
                                                                className="payAmout-modal"
                                                                open={open}
                                                                keepMounted
                                                                onClose={handleClose}
                                                                aria-labelledby="alert-dialog-slide-title"
                                                                aria-describedby="alert-dialog-slide-description"
                                                            >
                                                                <div className="payAmout-modal-body">
                                                                    <div className="payM-info">
                                                                        <i className="fas fa-info-circle"></i>
                                                                        There will be two separate charges on the passengers credit card statement
                                                                    </div>
                                                                    {airlinepaymet > 0 && <div className="payM-price">
                                                                        on Airline
                                                                        <span> $ {airlinepaymet}</span>
                                                                    </div>}
                                                                    {intairPayment > 0 && <div className="payM-price">
                                                                        Credit card payment on Intair
                                                                        <span> $ {intairPayment}</span>
                                                                    </div>}

                                                                    <button onClick={handleClose}>&times;</button>
                                                                </div>
                                                            </Dialog>
                                                        </div>
                                                        {appliedTransactions && appliedTransactions.map((obj, index) => {
                                                            let totalBypassenger = 0
                                                            if (obj.transactions === 2) {
                                                                let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                                let num2 = obj.transactionsDetails && obj.transactionsDetails[1] && obj.transactionsDetails[1].totalAmount && obj.transactionsDetails[1].totalAmount.amount
                                                                totalBypassenger = (Number(totalBypassenger)
                                                                    + Number(num1)
                                                                    + Number(num2)).toFixed(2)

                                                            } else {
                                                                let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                                totalBypassenger = (Number(totalBypassenger)
                                                                    + Number(num1)).toFixed(2)

                                                            }
                                                            let data = passenger.filter(data => data.id == obj.passengerRefId)[0] && passenger.filter(data => data.id == obj.passengerRefId)
                                                            let type = data && data[0] && data[0].paxType
                                                            travlerCount++;
                                                            if (type === PassengerType.ADULT) {
                                                                travlerCountAdult++
                                                            }
                                                            if (type === PassengerType.CHILD) {
                                                                travlerCountChild++
                                                            }
                                                            if (type === PassengerType.INFANTINLAP) {
                                                                travlerCountInfant++
                                                            }

                                                            return (
                                                                <>
                                                                    {type === PassengerType.ADULT && <div className="bd-paymentS-item">For {type} {travlerCountAdult}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>}
                                                                    {type === PassengerType.CHILD && <div className="bd-paymentS-item">For {type} {travlerCountChild}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>}
                                                                    {type === PassengerType.INFANTINLAP && <div className="bd-paymentS-item">For {type} {travlerCountInfant}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>}



                                                                </>
                                                            )

                                                        })}

                                                        {/* <div className="bd-paymentS-item">For Child 1<span>$ 617.00</span></div> */}
                                                    </div>
                                                    :
                                                    <div className="bd-paymentS-details">
                                                        <img src={chequeImg} alt="img" className="payImg" />
                                                        <div className="bd-paymentS-item">By Cheque<strong>$ {Number(totalByCheck).toFixed(2)}</strong></div>
                                                        {appliedTransactions && appliedTransactions.map((obj, index) => {
                                                            let totalBypassenger = 0

                                                            let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                            totalBypassenger = (Number(totalBypassenger)
                                                                + Number(num1)).toFixed(2)

                                                            let newData = passenger.filter(data => data.id == obj.passengerRefId)
                                                            let type = newData.length > 0 && newData[0] && newData[0].paxType
                                                            travlerCount++;
                                                            if (type === PassengerType.ADULT) {
                                                                travlerCountAdult++
                                                            }
                                                            if (type === PassengerType.CHILD) {
                                                                travlerCountChild++
                                                            }
                                                            if (type === PassengerType.INFANTINLAP) {
                                                                travlerCountInfant++
                                                            }

                                                            return (
                                                                <>
                                                                    {type === PassengerType.ADULT &&
                                                                        <div className="bd-paymentS-item">For {type} {travlerCountAdult}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>
                                                                    }
                                                                    {type === PassengerType.CHILD &&
                                                                        <div className="bd-paymentS-item">For {type} {travlerCountChild}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>
                                                                    }
                                                                    {type === PassengerType.INFANTINLAP &&
                                                                        <div className="bd-paymentS-item">For {type} {travlerCountInfant}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>
                                                                    }
                                                                </>
                                                            )

                                                        })}

                                                    </div>
                                                }
                                            </>
                                        )

                                    }):
                                    
                                    <div/>
                                }

                                { props.seatPaymentAttempt && props.seatPaymentStatus == "Success"  ?
                                    seatTotalAmount !== 0 &&
                                        <>
                                            <div>
                                                <strong>PrePaid Seat Payment</strong>

                                            </div>
                                            {prepaidSeatArray.map((item, i) => {
                                                return (
                                                    <div className="bd-paymentS-details">
                                                        <img src={cardImg} alt="img" className="payImg" />
                                                        <div className="bd-paymentS-item">
                                                            By Card Ending In {item.ccNumber} <strong>$ {Number(item.totalAmount).toFixed(2)}</strong>

                                                        </div>
                                                    </div>)
                                            })}
                                        </>
                                    :
                                    
                                    <div/>
                                }

                                <div className="bd-paymentS-total">
                                    Total Amount Paid<span>$ {(Number(total) + Number(seatTotalAmount)).toFixed(2)}</span>
                                </div>
                            </div>


                        </div>
                    }
                </> :
                <>
                    {paymentTypeDetails && paymentTypeDetails?.length !== 0 &&
                        <div className="bd-paymentS-box">
                            <BookingSubHead title={"Payment Summary"} />

                            <div>
                                {paymentTypeDetails && paymentTypeDetails.map((paymentObj) => {
                                    let lastFourDigitNumber = paymentObj.creditCard && paymentObj.creditCard.number && paymentObj.creditCard.number.slice(-4)
                                    let totalByCard = 0
                                    let totalByCheck = 0

                                    let appliedTransactions = paymentObj.appliedTransactions
                                    if (paymentObj.type === "CreditCard") {
                                        appliedTransactions.map(obj => {

                                            if (obj.transactions === 2) {
                                                let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                let num2 = obj.transactionsDetails && obj.transactionsDetails[1] && obj.transactionsDetails[1].totalAmount && obj.transactionsDetails[1].totalAmount.amount

                                                totalByCard = (Number(totalByCard)
                                                    + Number(num1)
                                                    + Number(num2)).toFixed(2)
                                            } else {
                                                let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                totalByCard = (Number(totalByCard)
                                                    + Number(num1)).toFixed(2)


                                            }
                                        })
                                    } else {
                                        appliedTransactions.map(obj => {



                                            let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                            totalByCheck = (Number(totalByCheck)
                                                + Number(num1)).toFixed(2)


                                        })
                                    }
                                    total = (Number(total) + Number(totalByCard) + Number(totalByCheck)).toFixed(2)
                                    return (
                                        <>
                                            <div className="bd-paymentS-item">
                                                <strong>Payment</strong>

                                            </div>
                                            {paymentObj && paymentObj.type === "CreditCard" ?
                                                <div className="bd-paymentS-details">
                                                    <img src={cardImg} alt="img" className="payImg" />

                                                    <div className="bd-paymentS-item">
                                                        By Card Ending In {lastFourDigitNumber}<strong>$ {Number(totalByCard).toFixed(2)}</strong>
                                                        {airlinepaymet > 0 && intairPayment > 0 && <i className="fas fa-info-circle payS-info" onClick={() => handleClickOpen(paymentObj.appliedTransactions)}></i>}
                                                        <Dialog
                                                            className="payAmout-modal"
                                                            open={open}
                                                            keepMounted
                                                            onClose={handleClose}
                                                            aria-labelledby="alert-dialog-slide-title"
                                                            aria-describedby="alert-dialog-slide-description"
                                                        >
                                                            <div className="payAmout-modal-body">
                                                                <div className="payM-info">
                                                                    <i className="fas fa-info-circle"></i>
                                                                    There will be two separate charges on the passengers credit card statement
                                                                </div>
                                                                {airlinepaymet > 0 && <div className="payM-price">
                                                                    on Airline
                                                                    <span> $ {airlinepaymet}</span>
                                                                </div>}
                                                                {intairPayment > 0 && <div className="payM-price">
                                                                    Credit card payment on Intair
                                                                    <span> $ {intairPayment}</span>
                                                                </div>}

                                                                <button onClick={handleClose}>&times;</button>
                                                            </div>
                                                        </Dialog>
                                                    </div>
                                                    {appliedTransactions && appliedTransactions.map((obj, index) => {
                                                        let totalBypassenger = 0
                                                        if (obj.transactions === 2) {
                                                            let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                            let num2 = obj.transactionsDetails && obj.transactionsDetails[1] && obj.transactionsDetails[1].totalAmount && obj.transactionsDetails[1].totalAmount.amount
                                                            totalBypassenger = (Number(totalBypassenger)
                                                                + Number(num1)
                                                                + Number(num2)).toFixed(2)

                                                        } else {
                                                            let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                            totalBypassenger = (Number(totalBypassenger)
                                                                + Number(num1)).toFixed(2)

                                                        }
                                                        let data = passenger.filter(data => data.id == obj.passengerRefId)[0] && passenger.filter(data => data.id == obj.passengerRefId)
                                                        let type = data && data[0] && data[0].paxType
                                                        travlerCount++;
                                                        if (type === PassengerType.ADULT) {
                                                            travlerCountAdult++
                                                        }
                                                        if (type === PassengerType.CHILD) {
                                                            travlerCountChild++
                                                        }
                                                        if (type === PassengerType.INFANTINLAP) {
                                                            travlerCountInfant++
                                                        }

                                                        let transactionsDetails = obj.transactionsDetails
                                                        let intairMerchant = transactionsDetails && transactionsDetails.filter(obj => obj.merchant === "Intair")
                                                        let intairMerchantFee = intairMerchant && intairMerchant.length > 0 && intairMerchant[0].merchantFee && intairMerchant[0].merchantFee.totalAmount.amount
                                                          ? intairMerchant[0].merchantFee.totalAmount.amount : 0;
                                                        return (
                                                            <>
                                                                {type === PassengerType.ADULT && <div className="bd-paymentS-item">For {type} {travlerCountAdult}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>}
                                                                {type === PassengerType.CHILD && <div className="bd-paymentS-item">For {type} {travlerCountChild}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>}
                                                                {type === PassengerType.INFANTINLAP && <div className="bd-paymentS-item">For {type} {travlerCountInfant}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>}
                                                               <>
                                                                <PayableAmountBreakUp
                                                                    fareTypeByPnr={fareTypeByPnr}
                                                                    chequeRadioBtnFlag={true}
                                                                    transactionsDetails={transactionsDetails}
                                                                    intairMerchantFee={intairMerchantFee}
                                                                    ccAllowed={true}
                                                                    from = "confirmation"
                                                                    paymentRules={paymentRules}
                                                                    selected={"Credit Card"}
                                                                />
                                                               </>


                                                            </>
                                                        )

                                                    })}

                                                    {/* <div className="bd-paymentS-item">For Child 1<span>$ 617.00</span></div> */}
                                                </div>
                                                :
                                                <div className="bd-paymentS-details">
                                                    <img src={chequeImg} alt="img" className="payImg" />
                                                    <div className="bd-paymentS-item">By Cheque<strong>$ {Number(totalByCheck).toFixed(2)}</strong></div>
                                                    {appliedTransactions && appliedTransactions.map((obj, index) => {
                                                        let totalBypassenger = 0

                                                        let num1 = obj.transactionsDetails && obj.transactionsDetails[0] && obj.transactionsDetails[0].totalAmount && obj.transactionsDetails[0].totalAmount.amount
                                                        totalBypassenger = (Number(totalBypassenger)
                                                            + Number(num1)).toFixed(2)

                                                        let newData = passenger.filter(data => data.id == obj.passengerRefId)
                                                        let type = newData.length > 0 && newData[0] && newData[0].paxType
                                                        travlerCount++;
                                                        if (type === PassengerType.ADULT) {
                                                            travlerCountAdult++
                                                        }
                                                        if (type === PassengerType.CHILD) {
                                                            travlerCountChild++
                                                        }
                                                        if (type === PassengerType.INFANTINLAP) {
                                                            travlerCountInfant++
                                                        }
                                                        let transactionsDetails = obj.transactionsDetails
                                                        let intairMerchant = transactionsDetails && transactionsDetails.filter(obj => obj.merchant === "Intair")
                                                        let intairMerchantFee = intairMerchant && intairMerchant.length > 0 && intairMerchant[0].merchantFee && intairMerchant[0].merchantFee.totalAmount.amount
                                                          ? intairMerchant[0].merchantFee.totalAmount.amount : 0;

                                                        return (
                                                            <>
                                                                {type === PassengerType.ADULT &&
                                                                    <div className="bd-paymentS-item">For {type} {travlerCountAdult}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>
                                                                }
                                                                {type === PassengerType.CHILD &&
                                                                    <div className="bd-paymentS-item">For {type} {travlerCountChild}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>
                                                                }
                                                                {type === PassengerType.INFANTINLAP &&
                                                                    <div className="bd-paymentS-item">For {type} {travlerCountInfant}<span>$ {Number(totalBypassenger).toFixed(2)}</span></div>
                                                                }
                                                               <>
                                                                <PayableAmountBreakUp
                                                                    fareTypeByPnr={fareTypeByPnr}
                                                                    chequeRadioBtnFlag={true}
                                                                    transactionsDetails={transactionsDetails}
                                                                    intairMerchantFee={intairMerchantFee}
                                                                    ccAllowed={true}
                                                                    from = "confirmation"
                                                                    paymentRules={paymentRules}
                                                                    selected={"Cheque"}
                                                               />
                                                               </>
                                                            </>
                                                        )

                                                    })}

                                                </div>
                                            }
                                        </>
                                    )

                                })}

                                {seatTotalAmount !== 0 &&
                                    <>
                                        <div>
                                            <strong>PrePaid Seat Payment</strong>

                                        </div>
                                        {prepaidSeatArray.map((item, i) => {
                                            return (
                                                <div className="bd-paymentS-details">
                                                    <img src={cardImg} alt="img" className="payImg" />
                                                    <div className="bd-paymentS-item">
                                                        By Card Ending In {item.ccNumber} <strong>$ {Number(item.totalAmount).toFixed(2)}</strong>

                                                    </div>
                                                </div>)
                                        })}
                                    </>
                                }

                                <div className="bd-paymentS-total">
                                    Total Amount Paid<span>$ {(Number(total) + Number(seatTotalAmount)).toFixed(2)}</span>
                                </div>
                            </div>


                        </div>
                    }
                </>
            }
        </div>
    );
}

export default BookingPaymentSummary;