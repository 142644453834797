// @ts-nocheck
import React,{lazy} from "react";
// import { propTypes } from "react-bootstrap/esm/Image";
// import BookingPriceSummary from "./../bookings/BookingPriceSummary";
// import PaidBookingPriceSummary from "../bookings/PaidBookingPriceSummary";
const BookingPriceSummary = lazy(() => import('./BookingPriceSummary'))
const PaidBookingPriceSummary = lazy(() => import('./PaidBookingPriceSummary'))

function BookingConfirmationPrice(props) {
  
  return (
    <div className="pricesummary newComp">
      <div className="priceinner row">
        <div className="col-md-12 bd-main-col pricecol">
          {props?.statusCode == 'NP' ?
          <BookingPriceSummary fromBookingConfirmation={true} />
          :
          <PaidBookingPriceSummary fromBookingConfirmation={true} />
          }
        </div>
      </div>
    </div>
  );
}
export default BookingConfirmationPrice;
