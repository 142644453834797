import React, { useState, useEffect } from "react";
import Classes from "./classes/Classes";
import "./contractmanagement.css";
import Distribution from "./distribution/distribution";
import Tariff from "./Tariff/Tariff";
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Checkbox from '@material-ui/core/Checkbox';
import Characteristics from "./Characteristics/Characteristics";
import RoutingsTariff from "./RoutingsTariff/RoutingsTariff";
import { useHistory } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CreateContract from './createContract.js';
import {  Alert } from "react-bootstrap";
import swal from 'sweetalert';

const Contractmanagement = (props) => {
  const [type, setType] = useState("Tariff");
  const [originType, setOriginType] = useState('RegionCode');
  const [destinationsType, setDestinationsType] = useState("RegionCode");
  const [triffData, setTriffId] = useState({});
  const [editFlag, setEditFlag] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [tariffId, setTariffId] = useState(props.match.params.tariffId)
  const [alert, setAlert] = useState({ variant: 'success', show: false, message: '' });

  // GET CONTRACT /TRIFF LIST API CALL 
  useEffect(() => {
    fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}`, {
      method: 'GET',
    })
      .then(response => response.json())
      // .then(result => console.log("result,",result))
      // .then(result => result.map(data=>{
      //     data['triffId'] = data.PK + "_" + data.SK
      //   })
      .then(result => setTriffId(result))
      .catch(error => console.error("Error: ", error))
  }, []);


  const history = useHistory();

  // click to back function 
  const redirectToBack = (value) => {
    history.push(`/contractlist`);
  }

  let logoLowerCase = triffData.carrier && triffData.carrier.toLowerCase();
  let flightLogo = `${process.env.REACT_APP_FLIGHT_LOGO_URL}/${logoLowerCase}.png`

  // BACK FUNCTION
  const backToTariff = () => {
    setIsCreate(false)
  }

  // SUCCESS RESPONCES
  const afterCreate = (value) => {
    setIsCreate(false)
    setTariffId(value)
    fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${value}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => setTriffId(result))
      .catch(error => console.error("Error: ", error))
  }

  const [tripType ,setTripType] = useState([])
  const [landFare ,setLandFare] = useState(false)
  const [cruiseFare ,setCruiseFare] = useState(false)
  const [passengerType ,setPassengerType] = useState('')
  const [cabinType ,setCabinType] = useState([])
  const [renderFlag ,setRenderFlag] = useState('')

  const [network ,setNetwork] = useState(false)
  const [sunquest ,setSunquest] = useState(false)
  const [DFW ,setDFW] = useState(false)
  const [funSun ,setFunSun] = useState(false)
  const [intairTransit ,setIntairTransit] = useState(false)
  const [skyLink ,setSkyLink] = useState(false)
  const [originCountries ,setOriginCountries] = useState('')





  const selectEdit = () => {
    setEditFlag(true)
    setTripType(triffData.tripType)
    setLandFare(triffData.landFare == '0' ? false : true)
    setCruiseFare(triffData.cruiseFare == '0' ? false : true)
    setPassengerType(triffData.passengerType)
    setCabinType(triffData.cabinType)
    setNetwork(triffData.network)
    setSunquest(triffData.sunquest)
    setDFW(triffData.DFW)
    setFunSun(triffData.funSun)
    setIntairTransit(triffData.intairTransit)
    setSkyLink(triffData.skyLink)
    setOriginCountries(triffData.originCountries)
    // setJsonData(props.routingData)
}

    // SET CABIN TYPE 
    const setTripTypeFunction = (value) => {
      setRenderFlag(!renderFlag)
      let newTripType = tripType
      if (newTripType.includes(value)) {
        newTripType = newTripType.filter(id => id !== value);
        setTripType(newTripType)
      } else {
        newTripType.push(value);
        setTripType(newTripType)
      }
    }

    // SET TRIP TYPE FUNCTION
    const  setCabinTypeFunction = (value) => {
      setRenderFlag(!renderFlag)
      let newCabinType = cabinType
      if (newCabinType.includes(value)) {
        newCabinType = newCabinType.filter(id => id !== value);
        setCabinType(newCabinType)
      } else {
        newCabinType.push(value);
        setCabinType(newCabinType)
      }
    }

    const updateTariff = () => {
      let payload = {
        passengerType:passengerType,
        landFare:landFare ? "1" : '0',
        cruiseFare:cruiseFare ? '1': '0',
        tripType:tripType,
        cabinType:cabinType,
        skyLink:skyLink,
        network:network,
        sunquest:sunquest,
        DFW:DFW,
        funSun:funSun,
        intairTransit:intairTransit,
        // originCountries: [originCountries],

      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
  
        },
        body:
          JSON.stringify(payload)
      }

      fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}`, requestOptions)
        .then(response => response.json())
        // console.log("response",response)
        .then(result1 =>{
          fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}`, {
              method: 'GET',
          })
              .then(response => response.json())
              .then(result => setTriffId(result))
              .catch(error => console.error("Error: ", error))
              if(result1.status == "error"){
                swal({
                  text:  result1.message,
                  icon: "error",
                  button: "Close",
                });

              } else {
                swal({
                  text:  "Tariff updated   successfully.",
                  icon: "success",
                  button: "Close",
                });

              }
          // setAlert({ variant: 'success', show: true, message: 'Tariff updated   successfully.' });
          setEditFlag(false)
  
        })
        .catch(error => console.error("Error: ", error))
  
      }
  return (
    <div>
      {isCreate
        ?
        <CreateContract tariffId={tariffId} backToTariff={() => backToTariff()} afterCreate={afterCreate} />
        :
        <div>
          <div className="text-right mb-2">
            <a onClick={() => redirectToBack()} href="#" className="text-primary">Back to List</a>
          </div>
          {/* CARD SECTION */}
          <div className="cm-head">
            <div className="cm-head-list">
              <span className="fligh-logo"><img src={flightLogo} alt="logo" height="30" width="30" /> {triffData.carrier}</span>
              <span> <strong>Tariff:</strong> {triffData.tarifKey}</span>
              <span><strong>{triffData.isBrandedFare === "1" ? "Branded Fare" : "Non-Branded Fare"}</strong></span>
              <span><strong>VIA:</strong> {triffData && triffData.via} </span>
              {/* <span><strong>Fare Type:</strong> {triffData.contractType}</span>          */}

            </div>
            <div className="cm-head-checkbox d-flex">
              <label><input type="checkbox" className="ml-3 mt-1" /> Inactive</label>
              {/* <button className="cm-primary-btn ml-3" onClick={(e) => setIsCreate(true)}> Create</button> */}
            </div>

          </div>

          <div className="card cm-card">
            <div className="card-body">
            <Alert
                variant={alert.variant}
                show={alert.show}
                onClose={() => setAlert({ ...alert, show: false })}
                dismissible
            >
            {alert.message}
            </Alert>
              {triffData.source !== undefined &&
              <div className="cm-card-edit">
                {editFlag ?
                  <>
                    <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                    <button className="cm-primary-btn" onClick={(e)=>updateTariff(false)}> Save</button>
                </>
                :
                <>
                  <i onClick={(e)=>selectEdit(true)} class="far fa-edit"></i>
                </>
              }
              </div>
              }
              <div className="row">
                <div className="col-md-3">
                  <div className="cm-col">
                    <label className="cm-head-label">Origin</label>
                    {editFlag ?

                      <InputGroup>
                        <select
                          className="form-control cm-input-select"
                          value={originType}
                          onChange={(e) => setOriginType(e.target.value)}
                        >
                          <option value="AirportorCityCode">AirportorCityCode</option>
                          <option value="RegionCode">RegionCode</option>
                          <option value="Country Code">Country Code</option>
                          <option value="IATA Region Code">IATA Region Code</option>
                        </select>
                        {originType === "Country Code" &&
                          <FormControl aria-label="Text input with dropdown button" className="cm-input"
                            value={originCountries} onChange={(e)=>setOriginCountries(e.target.value)} />
                        }

                        {originType === "IATA Region Code" &&
                          <FormControl aria-label="Text input with dropdown button" className="cm-input"
                            value={triffData.originIATARegion &&
                              triffData.originIATARegion} />
                        }

                        {originType === "AirportorCityCode" &&
                          <FormControl aria-label="Text input with dropdown button" className="cm-input"
                            value={triffData.originCitiesOrAirports &&
                              Object.values(triffData.originCitiesOrAirports)} />
                        }
                        {originType === "RegionCode" &&
                          <FormControl aria-label="Text input with dropdown button" className="cm-input"
                            value={triffData.originRegions &&
                              Object.values(triffData.originRegions)} />
                        }
                      </InputGroup>
                      :
                      <div>
                        {(triffData.originCountries && (triffData.originCountries.length === 0 || triffData.originCountries.includes("") || triffData.originCountries.includes(null))) ? "" : "Country -"}  {triffData.originCountries && Object.values(triffData.originCountries).join(',')}
                        {(triffData.originRegions && (triffData.originRegions.length === 0 || triffData.originRegions.includes("") || triffData.originRegions.includes(null))) ? "" : <span> {(triffData.originCountries && (triffData.originCountries.length == 0 || triffData.originCountries.includes("") || triffData.originCountries.includes(null))) ? "" : "|"}    Regions - </span>} {triffData.originRegions && Object.values(triffData.originRegions).join(',')}
                        {(triffData.originCitiesOrAirports && (triffData.originCitiesOrAirports.length == 0 || triffData.originCitiesOrAirports.includes("") || triffData.originCitiesOrAirports.includes(null))) ? "" : <span> {(triffData.originRegions && (triffData.originRegions.length == 0 || triffData.originRegions.includes("") || triffData.originRegions.includes(null))) ? "" : "|"}    Cities Or Airports - </span>}     {triffData.originCitiesOrAirports && Object.values(triffData.originCitiesOrAirports).join(',')}
                        {triffData.originIATARegion && triffData.originIATARegion != "" && <span>{(triffData.originCitiesOrAirports && (triffData.originCitiesOrAirports.length === 0 || triffData.originCitiesOrAirports.includes(""))) ? "" : "|"}  IATA - </span>}  {triffData.originIATARegion}
                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="cm-col">
                    <label className="cm-head-label">Destinations</label>
                    {editFlag ?
                      <InputGroup className="mb-3">
                        <select
                          className="form-control cm-input-select"
                          value={destinationsType}
                          onChange={(e) => setDestinationsType(e.target.value)}
                        >
                          <option value="AirportorCityCode">AirportorCityCode</option>
                          <option value="RegionCode">RegionCode</option>
                          <option value="Country Code">Country Code</option>
                          <option value="IATA Region Code">IATA Region Code</option>
                        </select>
                        {destinationsType === "Country Code" &&
                          <FormControl aria-label="Text input with dropdown button"
                            className="cm-input"
                            value={triffData.destinationCountries &&
                              Object.values(triffData.destinationCountries)} />
                        }

                        {destinationsType === "IATA Region Code" &&
                          <FormControl aria-label="Text input with dropdown button"
                            className="cm-input"
                            value={triffData.destinationIATAs &&
                              Object.values(triffData.destinationIATAs)} />
                        }

                        {destinationsType === "AirportorCityCode" &&
                          <FormControl aria-label="Text input with dropdown button"
                            className="cm-input"
                            value={triffData.destinationCitiesOrAirports &&
                              Object.values(triffData.destinationCitiesOrAirports)} />
                        }
                        {destinationsType === "RegionCode" &&
                          <FormControl aria-label="Text input with dropdown button"
                            className="cm-input"
                            value={triffData.destinationRegions &&
                              Object.values(triffData.destinationRegions)} />
                        }

                      </InputGroup>
                      :
                      <div>
                        {triffData.destinationCountries && triffData.destinationCountries.length !== 0 && " Country -"}  {triffData.destinationCountries && Object.values(triffData.destinationCountries).join(',')}
                        {triffData.destinationIATAs && triffData.destinationIATAs.length !== 0 && <span>{triffData.destinationCountries && triffData.destinationCountries.length !== 0 && "|"} IATA </span>}  {triffData.destinationIATAs && Object.values(triffData.destinationIATAs).join(',')}
                        {triffData.destinationRegions && triffData.destinationRegions.length !== 0 && <span>{triffData.destinationRegions && triffData.destinationRegions.length !== 0 && <span>{triffData.destinationIATAs && triffData.destinationIATAs.length !== 0 && "|"} Regions - </span>} </span>}    {triffData.destinationRegions && Object.values(triffData.destinationRegions).join(',')}
                        {/* {tariff.destinationCities && tariff.destinationCities.length !== 0 && "| Cities -"}   {tariff.destinationCities && Object.values(tariff.destinationCities).join(',')} */}
                        {triffData.destinationCitiesOrAirports && triffData.destinationCitiesOrAirports.length !== 0 && <span>{triffData.destinationRegions && triffData.destinationRegions.length !== 0 && "|"}  Cities Or Airports - </span>}    {triffData.destinationCitiesOrAirports && Object.values(triffData.destinationCitiesOrAirports).join(',')}
                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="cm-col">
                    <label className="cm-head-label">Trip Type</label>

                    <div className="cm-checkbox-inline">
                      {editFlag ?
                        <FormControlLabel
                          control={
                            <Checkbox
                            onChange={()=>setTripType("OW")}
                              checked={tripType === "OW"}
                              color="primary"
                            />
                          }
                          label="One Way"
                        />
                        :
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.tripType && triffData.tripType === 'OW' ? true : false}
                              color="primary"
                            />
                          }
                          label="One Way"
                        />
                      }
                      {editFlag ?
                        <FormControlLabel
                          control={
                            <Checkbox
                            onChange={()=>setTripType("-OW")}
                            checked={tripType === "-OW"}
                            color="primary"
                            />
                          }
                          label="Round Trip / Open Jaw / Multi City"
                        />
                        :
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.tripType && triffData.tripType === '-OW' ? true : false}
                              color="primary"
                            />
                          }
                          label="Round Trip / Open Jaw / Multi City"
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="cm-col">
                    <label className="cm-head-label">Fare Type</label>
                    {editFlag ?
                      <div className="cm-checkbox-block">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={landFare}
                              color="primary"
                            />
                          }
                          label="Land Fare"
                          onChange={()=>setLandFare(!landFare)}

                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={cruiseFare}
                              color="primary"
                            />
                          }
                          onChange={()=>setCruiseFare(!cruiseFare)}
                          label="Cruise Fare"
                        />
                      </div>
                      :
                      <div className="cm-checkbox-block">
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.landFare === '0' ? false : true}
                              color="primary"
                            />
                          }
                          label="Land Fare"
                        />
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.cruiseFare === '0' ? false : true}
                              color="primary"
                            />
                          }
                          label="Cruise Fare"
                        />
                           <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={ (triffData.cruiseFare === '0' && triffData.landFare === '0')}
                              color="primary"
                            />
                          }
                          label="Air Fare"
                        />
                      </div>
                    }
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="cm-col">
                    <label className="cm-head-label">PAX</label>
                    {editFlag ?
                      <div className="cm-radio-block">
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Senior"
                          onChange={(e)=>setPassengerType("SENIOR")}
                          checked={passengerType === 'SENIOR'}
                        />
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Adult"
                          onChange={(e)=>setPassengerType("ADULT")}
                          checked={passengerType === 'ADULT'}
                        />
                      </div>
                      :
                      <div className="cm-radio-block">
                        <FormControlLabel
                          disabled
                          control={<Radio color="primary" />}
                          label="Senior"
                          checked={triffData.passengerType === 'SENIOR'}
                        />
                        <FormControlLabel
                          disabled
                          control={<Radio color="primary" />}
                          label="Adult"
                          checked={triffData.passengerType === 'ADULT'}
                        />
                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="cm-col">
                    <label className="cm-head-label">Cabin Type </label>
                    {editFlag ?
                      <div className="cm-checkbox-inline">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={cabinType.includes("Economy") ? true : false}
                              color="primary"
                            />
                          }
                          label="Economy"
                          onChange={()=>setCabinTypeFunction("Economy")}

                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={cabinType.includes("PremiumEconomy") ? true : false}
                              color="primary"
                            />
                          }
                          label="Premimum Economy"
                          onChange={()=>setCabinTypeFunction("PremiumEconomy")}

                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={cabinType.includes("First") ? true : false}
                              color="primary"
                            />
                          }
                          label="First Class"
                          onChange={()=>setCabinTypeFunction("First")}

                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={cabinType.includes("Business") === true ? true : false}
                              color="primary"
                            />
                          }
                          label="Business"
                          onChange={()=>setCabinTypeFunction("Business")}

                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={cabinType.includes("PremiumBusiness") ? true : false}
                              color="primary"
                            />
                          }
                          onChange={()=>setCabinTypeFunction("PremiumBusiness")}
                          label="Premium Business"
                        />
                      </div>
                      :
                      <div className="cm-checkbox-inline">
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.cabinType && triffData.cabinType.includes("Economy") ? true : false}
                              color="primary"
                            />
                          }
                          label="Economy"
                        />
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.cabinType && triffData.cabinType.includes("premiumEconomy") ? true : false}
                              color="primary"
                            />
                          }
                          label="Premimum Economy"
                        />
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.cabinType && triffData.cabinType.includes("First") ? true : false}
                              color="primary"
                            />
                          }
                          label="First Class"
                        />
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.cabinType && triffData.cabinType.includes("Business") ? true : false}
                              color="primary"
                            />
                          }
                          label="Business"
                        />
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={triffData.cabinType && triffData.cabinType.includes("premiumBusiness") ? true : false}
                              color="primary"
                            />
                          }
                          label="Premium Business"
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>

              {editFlag ?
              <div className="row">
                <div className="col-md-12">
                <div className="cm-col">
                <div className="cm-checkbox-inline">
                    <FormControlLabel
                          
                          control={
                            <Checkbox
                              checked={skyLink}
                              color="primary"
                            />
                          }
                          label="Sky link"
                          onChange={()=>setSkyLink(!skyLink)}
                        />
                         <FormControlLabel
                          
                          control={
                            <Checkbox
                              checked={intairTransit }
                              color="primary"
                            />
                          }
                          label="Intair Transit"
                          onChange={()=>setIntairTransit(!intairTransit)}
                        />
                           <FormControlLabel
                          
                          control={
                            <Checkbox
                              checked={funSun}
                              color="primary"
                            />
                          }
                          onChange={()=>setFunSun(!funSun)}
                          label="Fun Sun"
                        />
                         <FormControlLabel
                          
                          control={
                            <Checkbox
                              checked={DFW}
                              color="primary"
                            />
                          }
                          label="DFW"
                          onChange={()=>setDFW(!DFW)}

                        />
                          <FormControlLabel
                          
                          control={
                            <Checkbox
                              checked={sunquest}
                              color="primary"
                            />
                          }
                          label="Sunquest"
                          onChange={()=>setSunquest(!sunquest)}
                        />
                        <FormControlLabel
                          
                          control={
                            <Checkbox
                              checked={network}
                              color="primary"
                            />
                          }
                          onChange = {()=>setNetwork(!network)}
                          label="Network"
                        />
                        </div>
                  </div>
                  </div>
              </div>
              :
              <div className="row">
              <div className="col-md-12">
              <div className="cm-col">
              <div className="cm-checkbox-inline">
                  <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={triffData.skyLink === true ? true :false}
                            color="primary"
                          />
                        }
                        label="Sky link"
                      />
                       <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={triffData.intairTransit === true ? true :false }
                            color="primary"
                          />
                        }
                        label="Intair Transit"
                      />
                         <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={triffData.funSun  === true ? true :false }
                            color="primary"
                          />
                        }
                        label="Fun Sun"
                      />
                       <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={triffData.DFW  === true ? true :false}
                            color="primary"
                          />
                        }
                        label="DFW"
                      />
                        <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={triffData.sunquest  === true ? true :false}
                            color="primary"
                          />
                        }
                        label="Sunquest"
                      />
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={triffData.network  === true ? true :false}
                            color="primary"
                          />
                        }
                        label="Network"
                      />
                      </div>
                </div>
                </div>
            </div>       
                }


            </div>
          </div>

          <div className="cm-tabs">
            <span
              onClick={() => setType("Tariff")}
              className={type === "Tariff" ? "cm-tabs-item selected" : "cm-tabs-item"}
            >
              {" "}
              Tariff
            </span>
            <span
              onClick={() => setType("Characteristics")}
              className={type === "Characteristics" ? "cm-tabs-item selected" : "cm-tabs-item"}
            >
              Characteristics
            </span>
            <span
              onClick={() => setType("Routings")}
              className={type === "Routings" ? "cm-tabs-item selected" : "cm-tabs-item"}
            >
              Routings
            </span>
            <span
              onClick={() => setType("Classes")}
              className={type === "Classes" ? "cm-tabs-item selected" : "cm-tabs-item"}
            >
              Classes
            </span>
            <span
              onClick={() => setType("Distribution")}
              className={type === "Distribution" ? "cm-tabs-item selected" : "cm-tabs-item"}
            >
              Distribution
            </span>
          </div>
          <div className="cm-tab-content">
            {type === "Distribution" && <Distribution />}
            {type === "Classes" && <Classes isBrandedFare={triffData.isBrandedFare} tariffId={tariffId} />}
            {type === "Tariff" && <Tariff tariffId={tariffId} />}
            {type === "Characteristics" && <Characteristics tariffId={tariffId} tariffData={triffData} />}
            {type === "Routings" && <RoutingsTariff tariffId={tariffId} routingData={triffData} />}
          </div>
        </div>
      }
    </div>
  );
};
export default Contractmanagement;
