import {  useEffect, useState  } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory ,Link} from 'react-router-dom';
import moment from "moment";

export default function Classes(props) {
  const history = useHistory();
  const [allClassessData ,setAllClassessData] = useState([]);

   // GET CLASS LIST API CALL 
      useEffect(() => {
          fetch(`${process.env.REACT_APP_CONTRACT_URL}/getAll/tariff/${props.tariffId}/class`, {
              method: 'POST',
          })
              .then(response => response.json())
            
              .then(result =>{
                if(result.Items === undefined ) {
                  setAllClassessData([])

                } else {
                setAllClassessData(result.Items)
              }
              } )
              .catch(error => console.error("Error: ", error))
      }, []);


       // FUNCTION FOR REDIRECT
    const redirectToClasses = (value) => {
        let classId =value.classId;
        history.push(`/classes/${classId}/${props.tariffId}`);
    }
    const redirectToCreateClass = () => {
      history.push(`/contractmanagement/Create-class/${props.tariffId}`);
  }
  return (
    <div className="cc-mui-table">
      {/* <Link to="/contractmanagement/classes/addclasses">
      <button className="btn btn-primary">Add Classes</button>
      </Link> */}
        <button onClick={()=>redirectToCreateClass()} className="cm-primary-btn">  Create New Class</button>
      <TableContainer component={Paper} className="mt-4">
        <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight:'bold'}}>{props.isBrandedFare === "1" ? "Branded Fare" : "Non-branded Class Code" }  </TableCell>
            <TableCell style={{fontWeight:'bold'}}>Class(es)</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Contract Type</TableCell>
            <TableCell  style={{fontWeight:'bold'}}>Note</TableCell>
            <TableCell  style={{fontWeight:'bold'}}>Min/ Max Stay</TableCell>
            <TableCell  style={{fontWeight:'bold'}}>Category</TableCell>
            <TableCell  style={{fontWeight:'bold'}}>EDR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allClassessData.length === 0 ?
            <TableRow>
                <td rowSpan="5">
                    No records found.
                </td>
              </TableRow>
            :
          allClassessData.map((classData,index)=>{
            return(
            <TableRow key={index}  onClick={(e)=>redirectToClasses(classData)}  style={{cursor:'pointer'}} title="Click to view class details" >
              <TableCell component="th" scope="row">
                {classData &&  classData.brandCode === null ?
                   classData.classCodes && Object.values(classData.classCodes).join(',')
                    :
                  classData.brandCode 
                }

              </TableCell>
              <TableCell className="cm-primary-text" >{classData.classCodes && Object.values(classData.classCodes).join(',')}</TableCell>
              <TableCell className="cm-primary-text">{classData.contractType && classData.contractType}</TableCell>
              <TableCell className="cm-primary-text" >{classData.classNote && classData.classNote}</TableCell>
              <TableCell >{classData.minmaxStay && classData.minmaxStay}</TableCell>
              <TableCell >{ classData && classData.category && classData.category}</TableCell>
              <TableCell >
                {moment(classData && classData.classLastTicketingDate &&  classData.classLastTicketingDate).format("DD-MMM-YYYY ")}
              </TableCell>
             
            </TableRow>
            )
          })
        }
        </TableBody>
        </Table>
        </TableContainer >    
        </div>
  );
}
