    import React, { forwardRef, Ref, ChangeEvent } from "react";
    import { MenuItem, Select as SelectField } from '@material-ui/core';
    import { Label } from "./Label";

    interface Option {
        label: string;
        value: string;
    }

    interface SelectProps {
        name?: string;
        className?: string;
        value?: string;
        onChange?: (value: string) => void;
        options?: Option[];
        disabled?: boolean;
        divClass?: string;
        labelvisible?: boolean;
        labelName?: string;
        labelClassName?: string;
    }

    export const Select = forwardRef((props: SelectProps, ref: Ref<any>) => {
        const {
            name,
            className = "",
            value = "",
            onChange = () => {},
            options = [{ label: "select", value: "select" }],
            disabled = false,
            divClass = "",
            labelvisible = false,
            labelName = "",
            labelClassName = ""
        } = props;

        const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
            const selectedValue = e.target.value as string;
            onChange(selectedValue);
        };

        return (
            <div className={divClass}>
                {labelvisible ? <Label name className={labelClassName}>{labelName}</Label> : null}
                <SelectField className={className}
                    {...ref}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                    name={name}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value} id={option.value}>{option.label}</MenuItem>
                    ))}
                </SelectField>
            </div>
        );
    });
