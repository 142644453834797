import {
  AGENCY_SEARCHDATA,
    } from "../constant/types";
    
    const intialState:any = {
         agecnySearchData:{
          agencyCode: '',
          networkId: '',
          affiliation: '',
          name: '',
          phone: '',
          postalCode: '',
          sortBy: '',
          country: '',
          provincesOrStates: '',
          countryDropdown:null,
          searchStatus:'',
          chainDataForChainPcc:[]
        }
    };
    
    export const Agencysearchreducer = (state = intialState, action:any) => {
      switch (action.type) {
        case AGENCY_SEARCHDATA:
          return {
            ...state,
            agecnySearchData: action.payload,
          };
        default:
          return state;
      }
    };
    export default Agencysearchreducer;