import {
    AGENT_SEARCHDATA,
      } from "../constant/types";
    
    // LOGIN ACTION
    export const  AgentSearchData = (data:any) => ({
        
      type: AGENT_SEARCHDATA,
      payload: data,
    }
    
    );
    
  
  
    export default AgentSearchData;
  