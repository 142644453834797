import React from "react";

const auth: any = {
    username: null,
    token: null,
    orgId: null,
    isLogin:null,
    setisLogin: () => { },
    logout: () => { },
    login: (token: any, username: any) => { },
    listData:[],
    taxArryForFee:[],
    updateTaxData: (Data: any) => { },
    updateListData: (Data: any) => { },
    agentListData:[],
    updateAgentListData: (Data: any) => { },
    salesPromotionValue:[],
    updateSalesPromotionValue: (Data: any) => { },

    agencyList:[],
    updateAgencyList: (Data: any) => { }

}

export default React.createContext(auth);
