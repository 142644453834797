import {  useEffect, useState } from "react";
import moment from "moment";
import MarkupCommission from "../MarkupCommission/MarkupCommission";
import Season from "../Season/Season";
import Exception from "../Exception/Exception";
import Emission from "../Emission/Emission";
import { useHistory} from 'react-router-dom';

export default function ViewAddPrices(props) {

  const history = useHistory();

  const [priceData, setPriceData] = useState({});
  const [priceType, setClassType] = useState("Season");
  const [triffData, setTriffId] = useState({});
  const [classData, setClassData] = useState({});
  const [editFlag ,setEditFlag] = useState(false);

  const selectEdit = () => {
      setEditFlag(true)
  }
  

  const classId = props.match.params.classId;
  const tariffId = props.match.params.tariffId;
  const priceId = props.match.params.priceId


    // GET CONTRACT /TRIFF LIST API CALL
    useEffect(() => {
        fetch(
        `${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}`,
        {
            method: "GET",
        }
        )
        .then((response) => response.json())
        // .then(result => console.log("result,",result))
        // .then(result => result.map(data=>{
        //     data['triffId'] = data.PK + "_" + data.SK
        //   })
        .then((result) => setTriffId(result))
        .catch((error) => console.error("Error: ", error));
    }, []);

    // GET GET ONE CLASSES LIST API CALL
    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}/class/${classId}`,
            {
            method: "GET",
            }
        )
            .then((response) => response.json())

            .then((result) => setClassData(result))
            .catch((error) => console.error("Error: ", error));
    }, []);

    // GET GET ONE CLASSES LIST API CALL
    useEffect(() => {
        fetch(
        `${process.env.REACT_APP_CONTRACT_URL}/tariff/${tariffId}/class/${classId}/price/${priceId}`,
        {
            method: "GET",
        }
        )
        .then((response) => response.json())

        .then((result) => setPriceData(result))
        .catch((error) => console.error("Error: ", error));
    }, []);

    // TARIFF BACK FUNCTION
    const backTariffFunction = () =>{
      history.push(`/contractmanagement/${tariffId}`);
    }

    // CLASS BACK FUNCTION
    const backClasssFunction = () =>{
      history.push(`/classes/${classId}/${tariffId}`);
    }

    let logoLowerCase = triffData.carrier && triffData.carrier.toLowerCase();
    let flightLogo = `${process.env.REACT_APP_FLIGHT_LOGO_URL}/${logoLowerCase}.png`

  return (
    <div>
      <div class="cm-head">
        <div className="cm-head-list">
          <span className="fligh-logo"><img src={flightLogo} alt="logo" height="30" width="30" /> {triffData.carrier}</span>
          <span><strong>Price:</strong> {priceData.prixKey}</span>
          <span><strong>{triffData.isBrandedFare === "1" ? "Branded Fare" : "Non-Branded Fare"}</strong></span>
          <span><strong>Fare Type:</strong> {classData.contractType}</span>         

          <span>
            {priceData.ticketingRules &&
              priceData.ticketingRules.lastTicketingDate.slice(0, 10)}{" "}
          </span>
        </div>
        <div>
          <a onClick={(e)=>backClasssFunction()} className="cm-primary-btn">CLASS</a>
          <a onClick={(e)=>backTariffFunction()} className="cm-primary-btn ml-2">TARIFF</a>
        </div>
      </div>

      <div className="card cm-card">
        <div className="card-body">
          <div className="cm-card-edit" onClick={(e)=>selectEdit(true)}>
            {/* {editFlag ?
                <>
                <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                <button className="cm-primary-btn" onClick={(e)=>setEditFlag(false)}> Save</button>
                </>              :
              <i onClick={(e)=>selectEdit(true)} class="far fa-edit"></i>
            } */}
          </div>
          <div className="row">
            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Origin</label>
              {editFlag ? 
              <input className="form-control cm-input" />
              :
                <div className="cm-content">
                    {(triffData.originCountries && (triffData.originCountries.length === 0 || triffData.originCountries.includes("") || triffData.originCountries.includes(null))) ? "" : "Country -"}  {triffData.originCountries && Object.values(triffData.originCountries).join(',')}
                    {(triffData.originRegions && (triffData.originRegions.length === 0 || triffData.originRegions.includes("") || triffData.originRegions.includes(null) ) ) ? "" : <span> {(triffData.originCountries && (triffData.originCountries.length === 0 || triffData.originCountries.includes("") || triffData.originCountries.includes(null) ) ) ? "" :   "|"}    Regions - </span>} {triffData.originRegions && Object.values(triffData.originRegions).join(',')} 
                    {(triffData.originCitiesOrAirports && (triffData.originCitiesOrAirports.length === 0 || triffData.originCitiesOrAirports.includes("") || triffData.originCitiesOrAirports.includes(null) ) ) ? "" : <span> {(triffData.originRegions && (triffData.originRegions.length === 0 || triffData.originRegions.includes("") || triffData.originRegions.includes(null) ) ) ? "" :   "|"}    Cities Or Airports - </span>}     {triffData.originCitiesOrAirports && Object.values(triffData.originCitiesOrAirports).join(',')}
                    {triffData.originIATARegion && triffData.originIATARegion != "" && <span>{(triffData.originCitiesOrAirports && (triffData.originCitiesOrAirports.length === 0 || triffData.originCitiesOrAirports.includes("") ) ) ? "" :   "|"}  IATA - </span> }  {triffData.originIATARegion}
                </div>
            }
            </div>
            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Destinations</label>
              {editFlag ? 
              <input className="form-control cm-input" />
              :
              <div className="cm-content">
                {triffData.destinationCountries && triffData.destinationCountries.length !== 0 && " Country -"}  {triffData.destinationCountries && Object.values(triffData.destinationCountries).join(',')}
                {triffData.destinationIATAs && triffData.destinationIATAs.length !== 0 && <span>{triffData.destinationCountries && triffData.destinationCountries.length !== 0 && "|"} IATA </span>}  {triffData.destinationIATAs && Object.values(triffData.destinationIATAs).join(',')}
                {triffData.destinationRegions && triffData.destinationRegions.length !== 0 && <span>{triffData.destinationRegions && triffData.destinationRegions.length !== 0 && <span>{triffData.destinationIATAs && triffData.destinationIATAs.length !== 0 && "|"} Regions - </span>} </span>}    {triffData.destinationRegions && Object.values(triffData.destinationRegions).join(',')}
                {/* {tariff.destinationCities && tariff.destinationCities.length !== 0 && "| Cities -"}   {tariff.destinationCities && Object.values(tariff.destinationCities).join(',')} */}
                {triffData.destinationCitiesOrAirports && triffData.destinationCitiesOrAirports.length !== 0 &&<span>{triffData.destinationRegions && triffData.destinationRegions.length !== 0 && "|"}  Cities Or Airports - </span>}    {triffData.destinationCitiesOrAirports && Object.values(triffData.destinationCitiesOrAirports).join(',')}    
              </div>
              }
            </div>
            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Valid From</label>
              {editFlag ? 
              <input className="form-control cm-input-date" type="date" />
              :
              <div className="cm-content">
                {moment(priceData.validFrom && priceData.validFrom).format('DD-MMM-YYYY')}
              </div>
              }
            </div>

            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Valid Upto</label>
              {editFlag ? 
              <input className="form-control cm-input-date" type="date" />
              :
              <div className="cm-content">
                  {moment(priceData.validTo && priceData.validTo).format('DD-MMM-YYYY')}
              </div>
            }
            </div>

          
            {classData.brandCode && classData.brandCode.includes("") ? '':
            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Brand</label>
              {editFlag ? 
              <input className="form-control cm-input" />
              :
              <div className="cm-content">
                {classData.brandCode === null &&
                  <input className="form-control cm-input"  disabled/>
                }
                {classData.brandCode}
              </div>
              }
            </div>
            }

            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Classes</label>
              {editFlag ? 
              <input className="form-control cm-input" />
              :
              <div className="cm-content">
                {classData.classCodes && Object.values(classData.classCodes).join(',')}
              </div>
              }
            </div>

            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Min / Max </label>
              {editFlag ? 
              <input className="form-control cm-input" />
              :
              <div className="cm-content">
                {classData.minmaxStay === null &&
                  <input className="form-control cm-input"  disabled/>
                }
                {classData.minmaxStay} 
              </div>
              }
            </div>

            <div className="col-md-3 cm-col">
              <label className="cm-head-label">Ticketing Fees </label>
              {editFlag ? 
              <input className="form-control cm-input" />
              :
              <div className="cm-content">
                {classData.ticketingFee && classData.ticketingFee}  
              </div>
              }
            </div>

          </div>

        </div>
      </div>

      <div className="cm-tabs">
          <span
            onClick={() => setClassType("Season")}
            className={priceType === "Season" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Season
          </span>
          <span
            onClick={() => setClassType("Exception")}
            className={priceType === "Exception" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Exception
          </span>
          {/* <span
            onClick={() => setClassType("Stops")}
            className={priceType == "Stops" ? "selected ml-5" : "ml-5"}
          >
            {" "}
            Stops 
          </span> */}
          <span
            onClick={() => setClassType("Emission")}
            className={priceType === "Emission" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Emission (Segments)
          </span>
          <span
            onClick={() => setClassType("Markup")}
            className={priceType === "Markup" ? "cm-tabs-item selected" : "cm-tabs-item"}
          >
            Markup / Commision Exception
          </span>
      </div>
      <div className="cm-tab-content">
            {priceType === 'Season' &&
              <Season priceData={priceData}/>
            }
             {priceType === 'Markup' &&
              <MarkupCommission/>
            }
            {priceType === 'Exception' &&
              <Exception priceData={priceData}/>
            }
             {priceType === 'Emission' &&
              <Emission priceData={priceData}/>
            }
      </div>
    </div>
  );
}

