import {
    TOKEN_SAVE,
  } from "../constant/types";
  
  const intialState = {
      token:'',
  };
  
  export const tokenReducers = (state = intialState, action) => {
    switch (action.type) {
      case TOKEN_SAVE:
        return {
          ...state,
          token: action.payload,
        };
      default:
        return state;
    }
  };
  export default tokenReducers;