//@ts-nocheck
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PassengerType, bookingStatusDesc } from '../bookingConfirmation/Utils/Constant';




function ConfirmationHead(props) {

    const dispatch = useDispatch();

    const [emailTo, setEmailTo] = useState({ label: 'Email to travel agent', value: 'agent' })

    const flightDetail = useSelector((state) => state.flightBooking);
    const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);
    const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
    let lastTicketedDate = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.lastTicketableDate
    let optionDateToDisplay = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.optionDateToDisplay
    const providerId = useSelector((state) => state.searchForm.providerId);
    const emailLanguageWhileBooking = useSelector((state) => state.flightBooking.emailLanguage);

    const recordLocator = flightDetail && flightDetail.pnr;

    const seat = useSelector((state) => state.itinenaries.seat);
    let totalSeatAmount = 0
    seat && seat.map(obj => {
        let amount = obj.amount
        totalSeatAmount = Number(totalSeatAmount) + Number(amount)

    })
    const agencyDetails = useSelector((state) => state.agentProfile.agencyDetails);
    const paymentTypeDetails = bookingData && bookingData.payments;
    const freeTextFareRules1 = useSelector((state) => state.Penalaties.freeTextFareRules);
    const faresPerTraveller = bookingData?.itineraryFare?.faresPerTraveller
    const passangers = bookingData && bookingData.passengers;
    const segments = bookingData && bookingData.segments;
    const paxTypeDetails = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.paxFares
    let fareTypeStatus = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.type
    let totalAmount = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.totalAmount && bookingData.itineraryFare.totalAmount.amount
    let fareBasisDetails = paxTypeDetails && paxTypeDetails[0] && paxTypeDetails[0].fareBasisDetails
    let agencyMarkups = bookingData && bookingData.agencyMarkups
    let primaryEmailId = passangers && passangers[0] && passangers[0].contact && passangers[0].contact.emails && passangers[0].contact.emails[0] && passangers[0].contact.emails[0].email
    const paymentRules = useSelector((state) => state.flightBooking.paymentRules);
    let PassengerWiseTransactionsDetails = paymentRules && paymentRules.PassengerWiseTransactionsDetails
    const merchantFeeDetail = bookingData && bookingData.merchantFeeAppliedOnTransaction
    let carrier = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.platingCarrierDetails && bookingData.itineraryFare.platingCarrierDetails.carrier
    let totalSellingAmount = bookingData && bookingData.itineraryFare && bookingData.itineraryFare?.totalAmount?.amount;
    const carrierDataForEmail = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.platingCarrierDetails

    const markup = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.applicableContract && bookingData.itineraryFare.applicableContract.markup
    let netAdultMarkup = markup && markup.amountPerAdult && markup.amountPerAdult.amount ? markup.amountPerAdult.amount : 0
    let netChildMarkup = markup && markup.amountPerChild && markup.amountPerChild.amount ? markup.amountPerChild.amount : 0
    let netInfantMarkup = markup && markup.amountPerInfantWithSeat && markup.amountPerInfantWithSeat.amount ? markup.amountPerInfantWithSeat.amount : 0
    const otherRateComponents = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.otherRateComponents;

    const [emailPopUpDisplay, setEmailPopUpDisplay] = useState(false)
    const [emailDetails, setEmailDetails] = useState(primaryEmailId !== undefined ? primaryEmailId : "")
    const [emailDetails1, setEmailDetails1] = useState("")
    const [emailLanguage, setEmailLanguage] = useState("")
    const [addField, setAddField] = useState(false)
    const [btnloader, setBtnloader] = useState(false);


    let paxTypeAdult = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.paxFares.filter(data => data.passengerDetails.type === PassengerType.ADULT)
    let paxTypeChild = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.paxFares.filter(data => data.passengerDetails.type === PassengerType.CHILD)
    let paxTypeInfant = bookingData && bookingData.itineraryFare && bookingData.itineraryFare.paxFares.filter(data => data.passengerDetails.type === PassengerType.INFANTINLAP)


    let adultsCount = paxTypeAdult && paxTypeAdult[0] && paxTypeAdult[0].passengerDetails && paxTypeAdult[0].passengerDetails.quantity ? paxTypeAdult[0].passengerDetails.quantity : 0
    let childCount = paxTypeChild && paxTypeChild[0] && paxTypeChild[0].passengerDetails && paxTypeChild[0].passengerDetails.quantity ? paxTypeChild[0].passengerDetails.quantity : 0
    let infantCount = paxTypeInfant && paxTypeInfant[0] && paxTypeInfant[0].passengerDetails && paxTypeInfant[0].passengerDetails.quantity ? paxTypeInfant[0].passengerDetails.quantity : 0

    let adultMarkupValue = 0
    let childMarkupValue = 0
    let infantMarkupValue = 0
    let ticketingFee = 0;
    let bookingFee = 0;


    useEffect(() => {
        setEmailLanguage({ value: emailLanguageWhileBooking, label: emailLanguageWhileBooking })
    }, [emailLanguageWhileBooking])

    if (agencyMarkups && agencyMarkups.length > 0) {

        let adultsData = agencyMarkups.filter(obj => obj.paxType === PassengerType.ADULT)
        let infantData = agencyMarkups.filter(obj => obj.paxType === PassengerType.INFANTINLAP)
        let childData = agencyMarkups.filter(obj => obj.paxType === PassengerType.CHILD)

        if (adultsData && adultsData[0]) {
            adultMarkupValue = adultsData[0].perPaxAmount && adultsData[0].perPaxAmount.amount
        }
        if (childData && childData[0]) {
            childMarkupValue = childData[0].perPaxAmount && childData[0].perPaxAmount.amount
        }
        if (infantData && infantData[0]) {
            infantMarkupValue = infantData[0].perPaxAmount && infantData[0].perPaxAmount.amount
        }

    }

    let newAdultMarkup = (Number(netAdultMarkup) * Number(adultsCount)).toFixed(2)
    let newChildMarkup = (Number(netChildMarkup) * Number(childCount)).toFixed(2)
    let newInfantMarkup = (Number(netInfantMarkup) * Number(infantCount)).toFixed(2)
    console.log("newAdultMarkup", newAdultMarkup, netAdultMarkup, adultsCount)

    const handleClose = () => {

        setEmailPopUpDisplay(false)
    };
    const handleEmailBtnClick = () => {
        setEmailPopUpDisplay(true)
    }

    const timeConvertIntoHour = (d) => {
        d = Number(d);
        var hour = Math.floor(d / 60);
        return hour
    }

    const timeConvertIntoMin = (d) => {
        d = Number(d);
        // var h = Math.floor(d / 3600);
        var min = Math.floor(d % 60);
        return min
    }

    function dateToBeFormatted(dateToBeFormatted) {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
        const year = dateToBeFormatted.slice(0, 4);
        const day = dateToBeFormatted.slice(8, 10);
        const d = new Date(dateToBeFormatted);
        const month = monthNames[d.getMonth()];
        const dayName = dayNames[d.getDay()];
        //time
        const timeHour = dateToBeFormatted.slice(11, 13);
        const timeMinutes = dateToBeFormatted.slice(14, 16);
        const finalDate = `${dayName}, ${day} ${month} ${year}`;
        return finalDate;
    };


    let addOnsDetails = []

    // SPECIAL REQUEST ADDED.


    passangers && passangers.map(data => {

        let addOnArrayNew = []
        let userFirstName = data.firstName;
        let userLastName = data.lastName;
        let addOnsData = data.addOns


        addOnsData && addOnsData.map(value => {

            addOnArrayNew.push({
                flightNumber: value.flightNumber,
                seatNumber: value && value.seat && value.seat.seatNumber,
                wheelChair: value.wheelChair,
                assistance: value.assistance,
                meal: value.meal,
                isSeatConfirmed: value?.seat?.status === "Confirmed" ? 'https://s3-ca-central-1.amazonaws.com/frontend-travelbrands-logos/green-checkmark-icon.svg' : 'https://s3-ca-central-1.amazonaws.com/frontend-travelbrands-logos/close-red-icon.svg'

            })






        })



        addOnsDetails.push({
            firstName: userFirstName,
            lastName: userLastName,
            specialRequest: addOnArrayNew

        })



    })


    return (
        <>
            {flightDetail && flightDetail.bookingStatus && (flightDetail.bookingStatus.code !== "NP" && flightDetail.bookingStatus.code !== "SC") ? (
                <div className="bookingconfirmed">
                    <div className="subsecondhead">
                        <div className="subsecondheaddiv">
                            <span>Record Locator</span>
                            <span className="flightPNR">
                                {flightDetail.pnr}(
                                {flightDetail.bookingItenaries &&
                                    flightDetail.bookingItenaries.providerName}
                                )
                            </span>
                        </div>
                        {flightDetail && flightDetail.bookingStatus && (flightDetail.bookingStatus.code === "NTC" || flightDetail.bookingStatus.code === "TC") ?
                            <div className="subsecondheaddiv bookingCancel">
                                <span>Booking Status</span>
                                <span className="tktonholdFailed">
                                    <i className="fas fa-times-circle"></i> {flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.description}
                                </span>
                            </div>
                            :
                            <div className="subsecondheaddiv">
                                <span>Booking Status</span>
                                <span className="tktonhold">
                                    <i className="fas fa-check-circle"></i> {flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.description === bookingStatusDesc.BOOKED_NEEDS_PAYMENT ? "Confirmed - Needs Payment" : flightDetail.bookingStatus.description}
                                </span>
                            </div>
                        }
                        <div className="subsecondheaddiv">
                            <span>Ticketing Status:</span>


                            {flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code === "PNP" &&
                                <spna className="bookingonhold">
                                    <span className="tktonhold">Not ticketed</span>
                                </spna>}
                            {flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code === "TF" &&
                                <span className="tktonholdFailed">Failed</span>}
                            {flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code === "PT" &&
                                <span className="tktonhold">Ticketed</span>
                            }
                            {flightDetail && flightDetail.bookingStatus && (flightDetail.bookingStatus.code === "NTC" || flightDetail.bookingStatus.code === "TC" || flightDetail.bookingStatus.code === "CR") &&
                                <span className="tktonholdFailed">Cancelled</span>}

                            {flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code === "SC" &&
                                <span className="bookingonhold">
                                    <span className="tktonhold">Not ticketed</span>
                                </span>}

                        </div>
                    </div>



                </div>
            ) : (
                <div className="bookingconfirmed bookingonhold">

                    <div className="subsecondhead">
                        <div className="subsecondheaddiv">
                            <span>Record Locator</span>
                            <span className="flightPNR">
                                {flightDetail.pnr}(
                                {flightDetail.bookingItenaries &&
                                    flightDetail.bookingItenaries.providerName}
                                )
                            </span>
                        </div>
                        <div className="subsecondheaddiv">
                            <span>Booking Status</span>
                            <span className="tktonhold">
                                <i className="fas fa-pause-circle"></i> {flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.description === bookingStatusDesc.BOOKED_NEEDS_PAYMENT ? "Confirmed - Needs Payment" : flightDetail.bookingStatus.description}

                            </span>
                        </div>
                        <div className="subsecondheaddiv">
                            <span>Ticketing Status:</span>
                            <span className="tktonhold">
                                On Hold
                            </span>
                        </div>
                    </div>
                    <div className="subfirsthead">

                        <div className="d-flex">

                            <p className="bookheadp">Your Booking is {flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.description === bookingStatusDesc.BOOKED_NEEDS_PAYMENT ? "Confirmed - Needs Payment" : flightDetail.bookingStatus.description}</p>

                            <span className="bookDate">
                                {moment.utc(optionDateToDisplay).format(
                                    "DD MMM  YYYY"
                                )}
                                {" "}|{" "}
                                {moment.utc(optionDateToDisplay).format(
                                    "HH:mm"
                                )}
                            </span>
                        </div>
                        

                    </div>
                </div>
            )}
            
        </>
    );
}
export default ConfirmationHead;
