import {  useEffect, useState  } from "react";
import moment from "moment";
import { string } from "yup";
import {  Alert } from "react-bootstrap";

export default function Tariff(props) {
const [triffData ,setTriffId] = useState({});
const [editFlag ,setEditFlag] = useState(false);
const [alert, setAlert] = useState({ variant: 'success', show: false, message: '' });
const [tariffJson , setTariffJson] = useState({})


// let tariff_id = this.props.match.params.PK+"_"+this.props.match.params.SK

    // GET CONTRACT /TRIFF LIST API CALL 
    useEffect(() => {
        fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${props.tariffId}`, {
            method: 'GET',
        })
            .then(response => response.json())
            // .then(result => console.log("result,",result))
            // .then(result => result.map(data=>{
            //     data['triffId'] = data.PK + "_" + data.SK
            //   })
            .then(result => setTriffId(result))
            .catch(error => console.error("Error: ", error))
    }, [props.tariffId]);

    let mapAirLine = ''
    let OpeartingAirLine = ''

   

    // ONCHANGE VALUES FUNCTIONS
    const editOncahgeFunction = (e,type) =>{
        let tariffValues = tariffJson
        setTariffJson({...tariffValues,[type]:e.target.value})
    }

    // AIRLINE EDIT 
    const editAirline = (e) =>{
        let tariffValues = tariffJson
        tariffValues.allowedAirlinesMapping.mappedOpeartingAirlines = e.target.value
        setTariffJson(tariffValues)
    }


    const [mkAirline , setMkAirline ]= useState("") 
    const [opAirline , setOpAirline ]= useState("") 
    const [notePhaseIV , setNotePhaseIV ]= useState("") 
    const [note , setNote ]= useState("") 
    const [validFrom , setValidFrom ]= useState("") 
    const [validUpto , setValidUpto ]= useState("") 
    const [pseudoCityCodes , setPseudoCityCodes ]= useState("") 
    const [tarifPCC_SKY , setTarifPCC_SKY ]= useState("") 
    const [tarifPCC_FUN , setTarifPCC_FUN ]= useState("") 
    const [tarifPCC_TC , setTarifPCC_TC ]= useState("") 
    const [tarifPCC_DFW , setTarifPCC_DFW ]= useState("") 

    // ON EDIT CLICK
    const selectEdit = () => {
        setEditFlag(true)
        setTariffJson(triffData)
        setOpAirline(triffData.allowedAirlinesMapping[0].mappedOpeartingAirlines)
        setMkAirline(triffData.allowedAirlinesMapping[0].marketingAirline)
        setNote(triffData.note)
        setNotePhaseIV(triffData.notesIV)
        setValidFrom(triffData.validFrom)
        setValidUpto(triffData.validUpto)
        setPseudoCityCodes(triffData.pseudoCityCodes)
        setTarifPCC_SKY(triffData.tarifPCC_SKY)
        setTarifPCC_FUN(triffData.tarifPCC_FUN)
        setTarifPCC_TC(triffData.tarifPCC_TC)
        setTarifPCC_DFW(triffData.tarifPCC_DFW)
    }
    // console.log("opAirlinedddd", typeof(opAirline))
    // UPDATE CHARCTERRISTICS
    const updateTariff = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,

      },
      body:
        JSON.stringify({
            note:note,
            notesIV:notePhaseIV,
            validFrom:validFrom,
            validUpto:validUpto,
            pseudoCityCodes:pseudoCityCodes,
            tarifPCC_SKY:tarifPCC_SKY,
            tarifPCC_FUN:tarifPCC_FUN,
            tarifPCC_TC:tarifPCC_TC,
            tarifPCC_DFW:tarifPCC_DFW,
            allowedAirlinesMapping:[{
                mappedOpeartingAirlines:typeof(opAirline) === string ? [opAirline] : opAirline,
                marketingAirline:typeof(mkAirline) === string ? [mkAirline] : mkAirline
            }]
        })
    }
    fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${props.tariffId}`, requestOptions)
      .then(response => response.json())
      // console.log("response",response)
      .then(result1 =>{
        fetch(`${process.env.REACT_APP_CONTRACT_URL}/tariff/${props.tariffId}`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => setTriffId(result))
            .catch(error => console.error("Error: ", error))
        setAlert({ variant: 'success', show: true, message: 'Tariff updated   successfully.' });
        setEditFlag(false)

      })
      .catch(error => console.error("Error: ", error))

    }
    

return (
        <div className="card cm-card">
            <div className="card-body">
            <Alert
                variant={alert.variant}
                show={alert.show}
                onClose={() => setAlert({ ...alert, show: false })}
                dismissible
            >
            {alert.message}
            </Alert>
            {triffData.source !== undefined &&
            <div className="cm-card-edit">
                {editFlag
                         ?
                        <>
                        <button className="cm-secondary-btn mr-2" type="button"  onClick={(e)=>setEditFlag(false)}>Cancel</button>
                        <button className="cm-primary-btn" onClick={(e)=>updateTariff()} > Save</button>
                        </>             
                         :
                        <i style={{cursor:'pointer' ,color:'#0465ac'}} onClick={(e)=>selectEdit()} class="far fa-edit"></i>
                        }
            </div>
                }
        
            <div className="row">
                <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">Marketing Airline</label>
                        {editFlag ?
                        <>
                      
                        <input 
                        className="form-control cm-input"
                        type="text"
                        onChange={(e)=>setMkAirline(e.target.value)}
                        value= {mkAirline}
                    />
                    </>
                    :
                     <div className="cm-content">
                        
                        {triffData.allowedAirlinesMapping && triffData.allowedAirlinesMapping.map((data,i)=>{
                            return(
                                <span>
                                    {data.marketingAirline.length === 0  && <input className="form-control cm-input" disabled />}
                                    {data.marketingAirline}
                                </span>
                            )
                        })} 
                    </div>
                    }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">Operating Airline</label>
                    {editFlag ?
                        <>        
                        <input 
                        className="form-control cm-input"
                        type="text"
                        value={opAirline}
                        onChange ={(e)=>setOpAirline(e.target.value)}
                    />
                    </>
                    :
                    <div className="cm-content">
                        {triffData.allowedAirlinesMapping && triffData.allowedAirlinesMapping.map((data,i)=>{
                            return(
                                <span>
                                 {data.mappedOpeartingAirlines.length === 0  && <input className="form-control cm-input" disabled />}
                                    {Object.values(data.mappedOpeartingAirlines).join(",")} </span>
                            )
                        })} 
                    </div>
                        }
                    {/* <input 
                        className="form-control"
                        type="text"
                    /> */}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">Note Phase IV</label>
                    {editFlag ?
                        <textarea 
                        className="form-control cm-textarea"
                        type="text"
                        onChange ={(e)=>setNotePhaseIV(e.target.value)}
                        value={notePhaseIV}
                    />
                    :
                    <div className="cm-content">
                           <textarea  
                           className="form-control cm-textarea"
                           disabled
                           value={triffData && triffData.notesIV}
                       />
                        
                    </div>
                    }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">Note</label>
                    {editFlag ?
                        
                        <textarea 
                        className="form-control cm-textarea"
                        type="text"
                        onChange ={(e)=>setNote(e.target.value)}
                        value={note}
                    />
                    :
                    <div className="cm-content">
                           <textarea
                           className="form-control"
                           disabled
                           value={triffData && triffData.note}

                           />
                       
                    </div>
                    }
                    </div>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-md-4">
                    <label style={{fontWeight:'bold'}}>Contract Agency</label>
                     {editFlag ?
                        
                        <input 
                        className="form-control"
                        type="text"
                    />:
                    <p>{triffData.agencies && Object.values(triffData.agencies)}</p>
                    } 

                </div> */}
                <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">Valid From</label>
                    {editFlag ?
                        <input 
                        className="form-control cm-input"
                        type="date"
                        onChange ={(e)=>setValidFrom(e.target.value)}
                        value={validFrom}
                    />:
                    <div className="cm-content">
                       
                        <input type="date" className="form-control"
                         disabled 
                        //  value={moment(triffData.validFrom && triffData.validFrom).format("DD-MMM-YYYY ")}
                         value={triffData.validFrom}

                        />
                        {/* {triffData.validFrom} */}
                    </div>
                    }
                    {/* <input 
                        className="form-control"
                        type="text"
                    /> */}
                    </div>
                </div>      
                 <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">Valid Upto</label>
                    {editFlag ?
                        
                        <input 
                        className="form-control cm-input"
                        type="date"
                        onChange ={(e)=>setValidUpto(e.target.value)}
                        value={validUpto} 
                    />:
                    <div className="cm-content">
                        <input 
                        className="form-control"
                        // value={moment(triffData.validUpto && triffData.validUpto).format("DD-MMM-YYYY ")}
                         disabled 
                         value={triffData.validUpto}
                        />
                    </div>
                    }
                    </div>
                </div>

                </div>

                <div className="row">
            <div className="col-12">
                <p className="cm-head-2">PCC du tarif</p>
            </div>
          </div>
                <div className="row">
                <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">VIT</label>
                    {editFlag ?
                        <input 
                        className="form-control cm-input"
                        type="text"
                        value={pseudoCityCodes}
                        onChange ={(e)=>setPseudoCityCodes(e.target.value)}
                    />
                    :
                    <div className="cm-content">
                        <input type="text" 
                        className="form-control" 
                        value={triffData.pseudoCityCodes && triffData.pseudoCityCodes}
                        disabled />
                       
                    </div>
                    }
                    </div>
                </div>      
                 <div className="col-md-6">
                 <div className="cm-col">
                    <label className="cm-head-label">SKY</label>
                    {editFlag ?
                        
                        <input 
                        className="form-control cm-input"
                        type="text"
                        value={tarifPCC_SKY}
                        onChange ={(e)=>setTarifPCC_SKY(e.target.value)}

                    />
                    :
                    <div className="cm-content">
                    
                        <input 
                        type="text"
                         className="form-control"
                         value={triffData.tarifPCC_SKY && triffData.tarifPCC_SKY}
                         disabled />
                       
                    </div>
                    }
                    </div>
                </div>

                </div>

                <div className="row">
                <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">FUN</label>
                    {editFlag ?
                        
                        <input 
                        className="form-control cm-input"
                        type="text"
                        value={tarifPCC_FUN}
                        onChange ={(e)=>setTarifPCC_FUN(e.target.value)}

                    />
                    :
                    <div className="cm-content">
                  
                        <input type="text" className="form-control"
                        value={triffData.tarifPCC_FUN && triffData.tarifPCC_FUN}
                        disabled />
                        
                    </div>
                    }
                    </div>
                </div>      
                 <div className="col-md-6">
                 <div className="cm-col">
                    <label className="cm-head-label">TC</label>
                    {editFlag ?
                        
                        <input 
                        className="form-control cm-input"
                        type="text"
                        value= {tarifPCC_TC}
                        onChange ={(e)=>setTarifPCC_TC(e.target.value)}

                    />
                    :
                    <div className="cm-content">
                   
                        <input type="text" className="form-control"
                        value= {triffData.tarifPCC_TC && triffData.tarifPCC_TC}
                        disabled />
                       
                    </div>
                    }
                    </div>
                </div>

                </div>

                <div className="row">
                <div className="col-md-6">
                    <div className="cm-col">
                    <label className="cm-head-label">DFW</label>
                    {editFlag ?
                        
                        <input 
                        className="form-control cm-input"
                        type="text"
                        value={tarifPCC_DFW}
                        onChange ={(e)=>setTarifPCC_DFW(e.target.value)}

                    />
                    :
                    <div className="cm-content">
                       
                        <input type="text" className="form-control" disabled
                        value={triffData.tarifPCC_DFW && triffData.tarifPCC_DFW}
                        />
                    </div>
                    }
                    </div>
                </div>      
            

                </div>

            </div>
        </div>
    )
}
