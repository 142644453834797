import {
    LOG_IN,
  } from "../constant/types";
  
  const intialState = {
      isUserLogedIn:false,
  };
  
  export const loginReducers = (state = intialState, action) => {
    switch (action.type) {
      case LOG_IN:
        return {
          ...state,
          isUserLogedIn: action.payload,
        };
      default:
        return state;
    }
  };
  export default loginReducers;