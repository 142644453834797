//@ts-nocheck
//import {getApiResponse} from "./../../utils/utility/getCommonApiResponse"
import { setSegment,setItenaryData, setItinaryFaresType,setSuccessPrice} from "../ActionCreator/flightBooking"
// import {toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { env } from '../appConfig'
//import getApiResponse from "../../services/getApiResponseDemo";

async function getApiResponse(url, requestOptions) {

    const response = await fetch(url, requestOptions).then(
        res =>{
            if(res.status == 504){
                return "error"
            }else{
                const data = res.json();
                return data;
            }
        }
    )
    return response;
}

export const updatePnr = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setSuccessPrice(false))
        const tokenData = getState().initToken;
        ////const token = tokenData && tokenData.token && tokenData.token.token;
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType;
        let url = env.REACT_APP_API_BASE_URL + `/booking/process/updatePNR`
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:flightType                
            },
            body:
                JSON.stringify(
                    
                        payload
                    )
        }


        const result = await getApiResponse(url, requestOptions);

        if (result) {
           return result

        }

    }
}