//@ts-nocheck
import {SET_MYFILES} from '../storeConstant';
   
    
export const setMyFilesData = (value) => {
    return {
        type: SET_MYFILES,
        payload: value
    }
}
