import { useState } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function Exception(props) {

    // const [editFlag, setEditFlag] = useState(false);

    // const selectEdit = () => {
    //     setEditFlag(true)
    // }


    return (
        <div className="card cm-card">
            <div className="card-body">
                <div className="cm-card-edit">
                    {/* {editFlag ?
                        <>
                            <button className="cm-secondary-btn mr-2" type="button" onClick={(e) => setEditFlag(false)}>Cancel</button>
                            <button className="cm-primary-btn" onClick={(e) => setEditFlag(false)}> Save</button>
                        </> :
                        <i onClick={(e) => selectEdit(true)} class="far fa-edit"></i>
                    } */}
                </div>

                {/* BLACKOUTDAYS SECTION */}
                {props.priceData && props.priceData.blackOutDays.length !== 0 &&
                    <div className="row">
                        <div className="col-12 cm-col">
                            <label className="cm-head-label">Blackout Days</label>
                            <div class="table-responsive">
                                <table class="table table-bordered cm-table cm-table-align-center">
                                    <tbody>
                                        <tr>
                                            <td><strong>Day</strong></td>
                                            <td><strong>Departure</strong></td>
                                            <td><strong>Return</strong></td>
                                            <td><strong>Exception Type</strong></td>
                                        </tr>
                                        {props.priceData && props.priceData.blackOutDays && props.priceData.blackOutDays.map((days, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {days.day} 
                                                    </td>
                                                    <td>
                                                        <div className="cm-checkbox-inline">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={days.aller == "1" ? true : false}
                                                                    />
                                                                }
                                                            // label="Departure"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="cm-checkbox-inline">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={days.retour == "1" ? true : false}
                                                                    />
                                                                }
                                                            // label="Return"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-control cm-input-select"
                                                            value={days.exceptionType}
                                                            disabled
                                                        >
                                                            <option value="BlackOut">BlackOut</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                }

                   {/* BLACKOUTDATES SECTION */}
                    <div className="row">
                        <div className="col-12 cm-col">
                            <label className="cm-head-label">Blackout Dates</label>
                            <div class="table-responsive">
                                <table class="table table-bordered cm-table cm-table-align-center">
                                    <tbody>
                                        <tr>
                                            <td><strong>Start Date &nbsp;&nbsp;-&nbsp;&nbsp; End Date</strong></td>
                                            <td><strong>Departure</strong></td>
                                            <td><strong>Return</strong></td>
                                            <td><strong>Exception Type</strong></td>
                                        </tr>
                                        {props.priceData.blackOutDates.length === 0 &&
                                        <>
                                        <tr>
                                            <td> -  &nbsp;&nbsp;&nbsp;&nbsp; -</td>
                                            <td> - </td>
                                            <td> - </td>
                                            <td> - </td>
                                        </tr>
                                         <tr>
                                         <td className="text-muted" colSpan={4}><strong>Data not Added.</strong></td>
                                        </tr>
                                        </>
                                        
                                        }
                                        {props.priceData && props.priceData.blackOutDates && props.priceData.blackOutDates.map((date, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                   
                                                    <div className="cm-exception-ft">
                                                        {date.fromDate} &nbsp;&nbsp;-&nbsp;&nbsp; {date.toDate}
                                                    </div>
                                                    </td>
                                                    <td>
                                                        <div className="cm-checkbox-inline">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={date.aller == "1" ? true : false}
                                                                    />
                                                                }
                                                            // label="Departure"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="cm-checkbox-inline">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={date.retour == "1" ? true : false}
                                                                    />
                                                                }
                                                            // label="Return"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-control cm-input-select"
                                                            value={date.exceptionType}
                                                            disabled
                                                        >
                                                            <option value="BlackOut">BlackOut</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                {/* BLACKOUTDATES DAYS */}

                    <div className="row">
                        <div className="col-12 cm-col">
                            <label className="cm-head-label">Blackout Airlines</label>
                            <div class="table-responsive">
                                <table class="table table-bordered cm-table cm-table-align-center">
                                    <tbody>
                                        <tr>
                                            <td><strong>Carrier Code</strong></td>
                                            <td><strong>From&nbsp;&nbsp;-&nbsp;&nbsp;To</strong></td>
                                            <td><strong>Departure</strong></td>
                                            <td><strong>Return</strong></td>
                                            <td><strong>Exception Type</strong></td>
                                        </tr>
                                        {props.priceData.blackOutCarrier.length === 0 &&
                                        <>
                                         <tr>
                                            <td> - </td>
                                            <td> - &nbsp;&nbsp;&nbsp;&nbsp; - </td>
                                            <td> - </td>
                                            <td> - </td>
                                            <td> - </td>
                                        </tr>
                                        <tr>
                                         <td className="text-muted" colSpan={5}><strong>Data not Added.</strong></td>
                                        </tr>
                                        </>
                                       
                                        }
                                        {props.priceData && props.priceData.blackOutCarrier && props.priceData.blackOutCarrier.map((carrier, index) => {
                                            return (
                                                <tr>
                                                    <td><strong>{carrier.carrierCode}</strong></td>
                                                    <td>
                                                    <div className="cm-exception-ft">
                                                        {carrier.flightNumberFrom} &nbsp;&nbsp;-&nbsp;&nbsp; {carrier.flightNumberTo}
                                                    </div>
                                                    </td>
                                                    <td>
                                                        <div className="cm-checkbox-inline">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={carrier.aller == "1" ? true : false}
                                                                    />
                                                                }
                                                            // label="Departure"
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="cm-checkbox-inline">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={carrier.retour == "1" ? true : false}
                                                                    />
                                                                }
                                                            // label="Return"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-control cm-input-select"
                                                            value={carrier.exceptionType}
                                                            disabled
                                                        >
                                                            <option value="BlackOut">BlackOut</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
            </div>
        </div>
    )
}
