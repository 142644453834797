import {  useState } from "react";
export default function Emission(props) {

    // const [editFlag, setEditFlag] = useState(false);

    const editFlag = false;
    // const selectEdit = () => {
    //     setEditFlag(true)
    // }
console.log("props.priceData",props.priceData)
    return (
        <div className="card cm-card">
            <div className="card-body">
                <div className="cm-card-edit" >
                    {/* {editFlag ?
                        <>
                            <button className="cm-secondary-btn mr-2" type="button" onClick={(e) => setEditFlag(false)}>Cancel</button>
                            <button className="cm-primary-btn" onClick={(e) => setEditFlag(false)}> Save</button>
                        </> :
                        <i onClick={(e) => selectEdit(true)} class="far fa-edit"></i>
                    } */}
                </div>
                <div className="row cm-col">
                    <div className="col-12">
                        <label className="cm-head-label">AUTHORISATION</label>
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">VIT</label>
                        {editFlag ?
                            <input className="form-control cm-input" />
                            :
                            <div className="cm-content">
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.VIT == null &&
                                    <input className="form-control cm-input" disabled />
                                }
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.VIT}
                            </div>
                        }
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">SKY</label>
                        {editFlag ?
                            <input className="form-control cm-input" />
                            :
                            <div className="cm-content">
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.SKY == null &&
                                    <input className="form-control cm-input" disabled />
                                }
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.SKY}
                            </div>
                        }
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">FUN</label>
                        {editFlag ?
                            <input className="form-control cm-input" />
                            :
                            <div className="cm-content">
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.FUN == null &&
                                    <input className="form-control cm-input" disabled />
                                }
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.FUN}
                            </div>
                        }
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">TC</label>
                        {editFlag ?
                            <input className="form-control cm-input" />
                            :
                            <div className="cm-content">
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.TC == null &&
                                    <input className="form-control cm-input" disabled />
                                }
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.TC}
                            </div>
                        }
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">DFW</label>
                        {/* <p style={{display:'flex'}}><span  className="mr-4 ml-4 mt-4">DFW</span><input className="form-control"/></p> */}
                        {editFlag ?
                            <input className="form-control cm-input" />
                            :
                            <div className="cm-content">
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.DFW == null &&
                                    <input className="form-control cm-input" disabled />
                                }
                                {props.priceData && props.priceData.authorisation && props.priceData.authorisation.DFW}

                            </div>
                        }
                    </div>
                </div>
                <b>Endorsment</b>
                <div className="row mt-5">
                    <div className="col-12 cm-col">
                        <div class="table-responsive">
                            <table class="table table-bordered cm-table cm-table-align-center">
                                <tbody>
                                    <tr>
                                        <td width="25%"></td>
                                        <td width="25%"><strong>Adult</strong></td>
                                        <td width="25%"><strong>Child</strong></td>
                                        <td width="25%"><strong>Infant</strong></td>
                                    </tr>

                                    <tr>
                                        <td><strong>YMQ</strong></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData?.endorsment.length > 0 && props.priceData?.endorsment[0].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[1].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[2].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>YOW</strong></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[3].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[4].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[5].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>YQB</strong></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[6].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[7].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[8].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>YTO</strong></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[9].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[10].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[11].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>SKY</strong></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[12].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[13].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[14].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>YVR</strong></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[15].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[16].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[17].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>YHZ</strong></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[18].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[19].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[20].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[21].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[22].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[23].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[24].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[25].tourCodes}</div>
                                            }
                                        </td>
                                        <td>
                                            {editFlag ?
                                                <input className="form-control cm-input" />
                                                :
                                                <div className="cm-content">{props.priceData && props.priceData.endorsment.length > 0 && props.priceData.endorsment[26].tourCodes}</div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    )
}
