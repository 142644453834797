import React from "react";
import { Button as ButtonField } from "@mui/material";

export const Button = ({buttons=[],  divClass=''}:any) => { //{className="", onClick=()=>{}, label="Button", disabled=false, type="button"}
    return(
        <div className={divClass}>
            {/* <ButtonField className={className} >{label}</ButtonField> */}
            {buttons.map((button:any)=>{
            return(<button className={button?.className} onClick={button?.onClick} disabled={button?.disabled} type={button?.type}>{button?.label}</button>)})}
        </div>
    )
}