
export const msg = {
    // error messages
    "101":"Please specify your agency or phone number and your agent name",
    "102":"Please specify your agency number or phone number",
    "103":"Please specify your agent name",
    "104":"Please specify origin and destination",
    "105":"Please specify dates",
    "106": "Please specify airline",
    "107": "Cannot perform search, please try again",
    "108":"Please select the correct fare",
    // "109":"Seat map unavailable", replced by We are sorry; the airline system has not responded, and we are unable to provide the seat map
    "110":"You must create a booking before selecting paid seats. Fees for paid seats may change after booking. ",
    "111":"Please wait for the tickets to be issued before selecting paid seats.",
    // "112":"Fare rules unavailable, please contact our reservations department.", replaced by We are sorry; the airline has not responded, and we are unable to provide the fare rules.
    "113": "A technical problem occurred, please try again",
    "114":"Please enter a value (0 or more)",
    "115":"Please fill all required fields",
    // "116":"Please specify a gender", replaced by yash 
    "117":"Failed to send email, please try again. ",
    // "118":"Failed to update the quote, please contact us.", commented
    "119":"Your quote is no longer valid.  Please do a new search.",
    "120":"An error occurred while saving the fare, please retry.",
    "121":"Please specify record locator",
    "122":"Please specify booking source",
    "123":`Please insert  to search`,
    "124":"Please specify full name",
    "125":"Please select number of infants less than or equal to number of adults.",
    "126":"Please add Booking Number",
    "127":"Invalid Booking Number",
    "128":"Please Select Valid Dates",
    "129":"Technical error occurred. Please try to Save again.",
    "130":"Price could not be saved in the PNR. Please try to Save again.",

    // Success Messages
    // "501":"Your quote is saved", receiving from api
    "502":"Email sent",
    "503":"Fare saved successfully",

    //  Inside Layout Messages
    "1001":"Please wait while we search for the best fares available for you!",
    "1002":"No results found for selected filters",
    "1003":"No results found",
    "1004":"Please modify your search criteria",
    "1005":"Searching for fare rules…",
    "1006":"Unable to display the details - No flight segment data available in the booking.",
    "1007":"Your option is expired - online payment is not allowed.",
    "1008":"Please wait while we save your changes", 
    "1009":"A technical problem occurred, please try again",
    "1010":"Payment is required today (until 23:59) to secure prepaid seats",
    // "1011":"Name fields only accept letters and numbers", logic removed booking  add passsanger info 

}

