import React, { useEffect } from 'react'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { Box, Checkbox, FormControlLabel, Radio, Tab } from '@mui/material'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { getOneTarif } from "../../contractManagement/thunks/getOneTarif"
import { useHistory } from 'react-router-dom';
import { setClassData, setPrixDetails, setSelectedTarifData, setTarifDetail } from '../AppStore/ActionCreator/commonDataAction';
import { getClassData } from '../thunks/getClassData';
import { getOnePrix } from '../thunks/getOnePrix';
import { parseISO } from 'date-fns'

const NewClass = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const classData = useSelector((state) => state.commonDataReducer.classData);
    const tarifData = useSelector((state) => state.commonDataReducer.tarifData);
    let ticketRules = classData?.ticketingRules;
    let accountCode = classData?.accountCode;
    let ticketDesignator = classData?.ticketDesignator;
    let optionDates = ticketRules?.optionDates;
    let ticketDates = ticketRules?.ticketDates;
    let remarks = classData?.remarks;
    let prixData = tarifData?.prix;

    const [value, setValue] = React.useState('1');
    const [isEdit, setIsEdit] = React.useState(false);
    const [tarifValues, setTarifValues] = React.useState({});
    const [classValues, setClassValues] = React.useState({
        "classKey": classData?.clasKey ? classData?.clasKey : "",
        "classes": classData?.classCodes?.join(", "),
        "brand": classData?.brandCode,
        "fareType": classData?.contractType ? classData?.contractType : "",
        "category": classData?.category ? classData?.category : "",
        "ticketingFee": classData?.ticketingFee,
        "classNote": classData?.classNote ? classData?.classNote : "",
        "classEDRDate": classData?.classLastTicketingDate ? classData?.classLastTicketingDate : "",
        "minMaxStay": classData?.minmaxStay ? classData?.minmaxStay : "",
        "infBelow": classData?.ageRestrictions?.infantAge ? classData?.ageRestrictions?.infantAge : "",
        "infApplicableOn": classData?.ageRestrictions?.infantAgeApplicableOn ? classData?.ageRestrictions?.infantAgeApplicableOn : "",
        "noTicketForInf": classData?.ageRestrictions?.noTicketingForInfant ? classData?.ageRestrictions?.noTicketingForInfant : false,
        "childAge": classData?.ageRestrictions?.childAge ? classData?.ageRestrictions?.childAge : "",
        "juniorAgeFrom": classData?.juniorAgeFrom ? classData?.juniorAgeFrom : 0,
        "juniorAgeTo": classData?.juniorAgeTo ? classData?.juniorAgeTo : 0,
        "osi": classData?.osi ? classData?.osi : "",
        "branchFiles": classData?.permitBranchPNR ? classData?.permitBranchPNR : false,
        "optionDates": ticketRules?.optionDates,
        "optionDepartureWindow": optionDates?.departureWindow ? optionDates?.departureWindow : "",
        "optionDAR": optionDates?.daysAfterReservation ? optionDates?.daysAfterReservation : "",
        "optionDBR": optionDates?.daysBeforeDeparture ? optionDates?.daysBeforeDeparture : "",
        "ticketDates": ticketRules?.ticketDates,
        "ticketDepartureWindow": ticketDates?.departureWindow ? ticketDates?.departureWindow : "",
        "ticketDAR": ticketDates?.daysAfterReservation ? ticketDates?.daysAfterReservation : "",
        "ticketDBR": ticketDates?.daysBeforeDeparture ? ticketDates?.daysBeforeDeparture : "",
        "maxOptionDFW": ticketRules?.maxOptionDFW ? ticketRules?.maxOptionDFW : "",
        "maxOptionFUN": ticketRules?.maxOptionFUN ? ticketRules?.maxOptionFUN : "",
        "maxOptionSKY": ticketRules?.maxOptionSKY ? ticketRules?.maxOptionSKY : "",
        "maxOptionTC": ticketRules?.maxOptionTC ? ticketRules?.maxOptionTC : "",
        "maxOptionVIT": ticketRules?.maxOptionVIT ? ticketRules?.maxOptionVIT : "",
        "issueAsBulk": classData?.issueAsBulk ? classData?.issueAsBulk : false,
        "searchFareBy": classData?.fareType ? classData?.fareType : "",
        "accountIntair": accountCode?.VIT,
        "accountSkyline": accountCode?.SKY,
        "accountDFW": accountCode?.DFW,
        "accountSUNFUN": accountCode?.FUN,
        "accountTC": accountCode?.TC,
        "ticketIntair": ticketDesignator?.VIT,
        "ticketSkyline": ticketDesignator?.SKY,
        "ticketDFW": ticketDesignator?.DFW,
        "ticketSUNFUN": ticketDesignator?.FUN,
        "ticketTC": ticketDesignator?.TC,
        "enRemarkIntair": remarks && remarks.VIT.english,
        "enRemarkSkyline": remarks && remarks.SKY.english,
        "enRemarkDFW": remarks && remarks.DFW.english,
        "enRemarkSUNFUN": remarks && remarks.FUN.english,
        "enRemarkTC": remarks && remarks.TC.english,
        "frRemarkIntair": remarks && remarks.VIT.French,
        "frRemarkSkyline": remarks && remarks.SKY.French,
        "frRemarkDFW": remarks && remarks.DFW.French,
        "frRemarkSUNFUN": remarks && remarks.FUN.French,
        "frRemarkTC": remarks && remarks.TC.French,
        "disableAutoTicketing": classData?.disableAutoTicketing,
        "disablePriceUnitConcept": classData?.disablePriceUnitConcept,
        "remarksEn": classData?.remarksEn,
        "remarksFr": classData?.remarksFr,
        "disallowedAirports": classData?.disallowedAirports?.join(", "),
        "classRoutings": classData?.classRoutings,
        "otherClassOfServices": classData?.otherClassOfServices,
        "classCodeDesc": classData?.classCodeDesc

    })

    useEffect(async () => {
        if (tarifData?.tarifKey) {
            let data = await getOneTarif(tarifData.tarifKey);
            if (data.data) {
                setTarifValues(data.data);
            }
        }

    }, [tarifData])

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    const handleStateChange = (event) => {
        const { name, value } = event.target;
        setClassValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 })
    }, [])

    const handleTarifClick = async () => {
        if (tarifData?.tarifKey) {
            let tarifDetails = await getOneTarif(tarifData?.tarifKey)
            if (tarifDetails?.data) {
                dispatch(setTarifDetail(tarifDetails?.data))
                history.push('/newTariff')
            }
        }
    }

    const cancelClick = () => {
        setIsEdit(false);
        // setTarif({...tarifDetails})
    }

    const changeOptionTicket = (e,type,index)=>{
        let data={...classValues};
        if(type=="option"){
            let optionData = data.optionDates[index];
            let name=e.target.name;
            let value=e.target.value;
            optionData[name]=value;
            data.optionDates[index]=optionData

        }else if(type=="ticket"){
            let optionData = data.ticketDates[index];
            let name=e.target.name;
            let value=e.target.value;
            optionData[name]=value;
            data.ticketDates[index]=optionData  
        }
        setClassValues(data);
    }

    const prixDetailsOnclick = async (prixKey, classValue, tarifValue) => {
        let classDetails = await getClassData(classValue?.classKey);
        dispatch(setClassData(classDetails))
        let tarifData = { ...classValue, ...tarifValue }
        dispatch(setSelectedTarifData(tarifData))
        const prixDetails = await getOnePrix(prixKey)
        dispatch(setPrixDetails(prixDetails?.data))
        history.push("/newPrix")
    }

    return (
        <>
            <div className="cm-head-2 mb-8">
                <div className="d-flex justify-content-between">
                    <span style={{ "fontSize": "1rem", "color": "#0055b8", "cursor": "pointer" }} onClick={() => { history.goBack() }}>{`<<- Back to Results`}</span>
                    <span className="cm__Title">Class Edit</span>
                    <span></span>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="classTopEditSetion classTopWrapper">
                        <div className="classTopField">
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Tariff key</label>
                                </div>
                                <a onClick={() => handleTarifClick()}>{tarifData?.tarifKey}</a>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Carrier</label>
                                </div>
                                <label>{tarifData?.carrier}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Origin</label>
                                </div>
                                <label>{tarifData?.origins}</label>
                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Destination</label>
                                </div>
                                <label>{tarifData?.destinations}</label>


                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Cabin Type</label>
                                </div>
                                <label>{tarifValues.cabinType?.join(', ')}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Trip Type</label>
                                </div>
                                <label>{tarifValues?.tripType == "OW"? "One Way" : "Not One Way"}</label>



                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Branded Fare</label>
                                </div>
                                <label>{tarifData?.isBrandedFare == 1 ? "Yes" : "No"}</label>

                            </div>
                            <div>
                                <div className=' cm-col'>
                                    <label className="cm-head-label">Contract Type</label>
                                </div>
                                <label>{tarifValues.cruiseFare == 1 ? "Cruise" : tarifValues.landFare == 1 ? "Land" : "Air"}</label>


                            </div>
                        </div>
                        <div className="classTopEdit">
                            {!Boolean(process.env.REACT_APP_EDIT_CREATE_CONTRACT_MANAGEMENT) ? <button className='classEditBtn' onClick={() => setIsEdit(!isEdit)}>
                                <i className="fas fa-edit"></i>
                            </button> : null}
                        </div>
                    </div>
                </div>

            </div>

            <div className="Tariff__Section Class__Section">
                <div>
                    <div className="card Tariff__BorderB">
                        <div className="card-body">
                            <div className="row mt-4">
                                <div className="col-md-6 cm-col">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Class Key</label>

                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Class Key"
                                                value={classValues?.classKey}
                                                disabled={!isEdit}
                                                name='classKey'
                                                onChange={handleStateChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Classes</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Classes"
                                                value={classValues.classes}
                                                disabled={!isEdit}
                                                name='classes'
                                                onChange={handleStateChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 cm-col">

                                    <div className="row">
                                        <div className="col-md-6">

                                            <label className="cm-head-label">Brand</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Brand"
                                                disabled={!isEdit}
                                                value={classValues.brand}
                                                name='brand'
                                                onChange={handleStateChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Fare Type</label>
                                            <div className="cm-radio-inline">
                                                <FormControlLabel control={<Radio color="primary" checked={classValues?.fareType == "Published" ? true : false} disabled={!isEdit} name='fareType' value='Published' onChange={handleStateChange} />} label="(%) Published" />
                                                <FormControlLabel control={<Radio color="primary" checked={classValues?.fareType == "Net" ? true : false} disabled={!isEdit} name='fareType' value='Net' onChange={handleStateChange} />} label="($) Net" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Category</label>
                                            <input className="form-control cm-input" placeholder="Enter Category" value={classValues?.category} disabled={!isEdit} name='category' onChange={handleStateChange} />
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Ticketing Fee</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Ticketing Fee"
                                                value={classValues.ticketingFee}
                                                disabled={!isEdit}
                                                name='ticketingFee'
                                                onChange={handleStateChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Class Note</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Class Note"
                                                value={classValues.classNote}
                                                disabled={!isEdit}
                                                name='classNote'
                                                onChange={handleStateChange}
                                            />
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Class EDR Date</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker className='BulkUpdate__DatePicker'  value={parseISO(new Date(classValues.classEDRDate).toISOString().split('T')[0])} format='dd MMM yyyy' onChange={() => { }} disabled={!isEdit} />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 20, borderColor: '#FFF4DE', background: '#fff' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Classes" value="1" />
                                    <Tab label="Price At Sabre" value="2" />
                                    <Tab label="Remarks" value="3" />
                                    <Tab label="Prix" value="4" />

                                </TabList>
                            </Box>
                            <TabPanel value="1" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">French Description</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.remarksEn} name='remarksEn' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">English Description</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.remarksFr} name='remarksFr' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">MinMax Stay</label>
                                                    <input className="form-control cm-input" type="text" placeholder='Enter Min' disabled={!isEdit} value={classValues.minMaxStay} name='minMaxStay' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className=" mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>AGE</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Infant Below</label>
                                                    <input className="form-control cm-input" type="number" placeholder="Enter Age" disabled={!isEdit} value={classValues.infBelow} name='infBelow' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Applicable On</label>
                                                    <FormControlLabel control={<Radio color="primary" checked={classValues.infApplicableOn == "DEPARTURE" ? true : false} name='infApplicableOn' value={'DEPARTURE'} onChange={handleStateChange} />} label="Departure" disabled={!isEdit} />
                                                    <FormControlLabel control={<Radio color="primary" checked={classValues.infApplicableOn == "ARRIVAL" ? true : false} name='infApplicableOn' value={'ARRIVAL'} onChange={handleStateChange} />} label="Return" disabled={!isEdit} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label"></label>
                                                    <FormControlLabel control={<Checkbox color="primary" disabled={!isEdit} checked={(classValues.noTicketForInf == true || classValues.noTicketForInf == "true")} name='noTicketForInf' value={(classValues.noTicketForInf == true || classValues.noTicketForInf == "true" ? false : true)} onChange={handleStateChange} />} label="  No ticket for infant" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Child Below</label>
                                                    <input className="form-control cm-input" type="number" placeholder="Enter Age" disabled={!isEdit} value={classValues.childAge} name='childAge' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Junior between</label>
                                                    <div className="d-flex ">
                                                        <div className='classCount mr-2'>
                                                            <button className='classIncress' disabled={!isEdit}>-</button>
                                                            <input className="classCountinput" type="number" value={classValues.juniorAgeFrom} disabled={!isEdit} name='juniorAgeFrom' onChange={handleStateChange} />
                                                            <button className='classDecress' disabled={!isEdit}>+</button>
                                                        </div>
                                                        <div className='classCount'>
                                                            <button className='classIncress' disabled={!isEdit}>-</button>
                                                            <input className="classCountinput" type="number" value={classValues.juniorAgeTo} disabled={!isEdit} name='juniorAgeTo' onChange={handleStateChange} />
                                                            <button className='classDecress' disabled={!isEdit}>+</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Senior Above</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className=" mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Notes for age</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-12 mb-4">
                                                <span>Infant etkt not allowed with some codeshared flights or interligne carriers.Fees and adv purchase apply for paperticket. Bebe Etkt non permis avec certains vols partages ou interlignes. Frais et delais d achat pour demander un billet papier.</span>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">OSI</label>
                                                    <input className="form-control cm-input" type="text" placeholder='Enter OSI' disabled={!isEdit} value={classValues.osi} name='osi' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Branch Files </label>
                                                    <FormControlLabel control={<Checkbox color="primary" disabled={!isEdit} checked={(classValues.branchFiles == true || classValues.branchFiles == "true")} name='branchFiles' value={(classValues.branchFiles == true || classValues.branchFiles == "true" ? false : true)} onChange={handleStateChange} />} label="Branch files not permitted" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Option Date</span>
                                            </div>
                                        </div>
                                    <div className="row">
                                      <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Departure Window</label>
                                                    {/* <input className="form-control cm-input" type="number" placeholder='Enter Departure Window' disabled={!isEdit} value={classValues.optionDepartureWindow} name='optionDepartureWindow' onChange={handleStateChange} /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Days After Reservation</label>
                                                    {/* <input className="form-control cm-input" type="number" placeholder='Enter Days after reservation' disabled={!isEdit} value={classValues.optionDAR} name='optionDAR' onChange={handleStateChange} /> */}
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label">Days Before Departure</label>
                                                    {/* <input className="form-control cm-input" type="number" placeholder='Enter Days before departure' disabled={!isEdit} value={classValues.optionDBR} name='optionDBR' onChange={handleStateChange} /> */}
                                                </div>
                                            </div>
                                            {classValues.optionDates.map((item, i) => {
                                                return (
                                                    <>
                                                        <div className="col-md-4">
                                                            <div className="cm-col">
                                                                <input className="form-control cm-input" type="number" placeholder='Enter Departure Window' disabled={!isEdit} value={item.departureWindow} name='departureWindow' onChange={(e)=>changeOptionTicket(e,"option",i)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="cm-col">
                                                                <input className="form-control cm-input" type="number" placeholder='Enter Days after reservation' disabled={!isEdit} value={item.daysAfterReservation} name='daysAfterReservation' onChange={(e)=>changeOptionTicket(e,"option",i)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="cm-col">
                                                                <input className="form-control cm-input" type="number" placeholder='Enter Days before departure' disabled={!isEdit} value={item.daysBeforeDeparture} name='daysBeforeDeparture' onChange={(e)=>changeOptionTicket(e,"option",i)} />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}                                            
                                        </div>
                                    </div>
                                  </div>
                                 </div>
                             </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Ticket By Date</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Departure Window</label>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Days After Reservation</label>
                                                    
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label">Days Before Departure</label>
                                                    
                                                </div>
                                            </div>

                                            {classValues.ticketDates.map((item, i) => {
                                                return (
                                                    <>
                                                        <div className="col-md-4">
                                                            <div className="cm-col">
                                                                <input className="form-control cm-input" type="number" placeholder='Enter Departure Window' disabled={!isEdit} value={item.departureWindow} name='departureWindow' onChange={(e)=>changeOptionTicket(e,"ticket",i)}/>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="cm-col">
                                                                <input className="form-control cm-input" type="number" placeholder='Enter Days after reservation' disabled={!isEdit} value={item.daysAfterReservation} name='daysAfterReservation' onChange={(e)=>changeOptionTicket(e,"ticket",i)}/>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="cm-col">
                                                                <input className="form-control cm-input" type="number" placeholder='Enter Days before departure' disabled={!isEdit} value={item.daysBeforeDeparture} name='daysBeforeDeparture' onChange={(e)=>changeOptionTicket(e,"ticket",i)}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                         </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Options Maximum</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">VIT</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.maxOptionVIT} name='maxOptionVIT' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">SKY</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.maxOptionSKY} name='maxOptionSKY' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label">DFW</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.maxOptionDFW} name='maxOptionDFW' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label">FUN</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.maxOptionFUN} name='maxOptionFUN' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label">TC</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.maxOptionTC} name='maxOptionTC' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Routings</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Airports Prohibited</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.disallowedAirports} name='disallowedAirports' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Routing Info Source</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.classRoutings} name='classRoutings' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label">Routing</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.otherClassOfServices} name='otherClassOfServices' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label">Class of services of IC</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.classCodeDesc} name='classCodeDesc' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </TabPanel>

                            <TabPanel value="2" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        {console.log("classValues.disableAutoTicketing", classValues.disableAutoTicketing, classValues.disablePriceUnitConcept)}
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Disable auto-ticketing </label>
                                                    <div className="cm-radio-inline">
                                                        <FormControlLabel control={<Radio color="primary" disabled={!isEdit} checked={((classValues.disableAutoTicketing) == true || (classValues.disableAutoTicketing) == "true")} name='disableAutoTicketing' value={(true)} onChange={handleStateChange} />} label="True" />
                                                        <FormControlLabel control={<Radio color="primary" disabled={!isEdit} checked={(((classValues.disableAutoTicketing) == false || (classValues.disableAutoTicketing) == "false"))} name='disableAutoTicketing' value={(false)} onChange={handleStateChange} />} label="False" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Disable Price Unit Concept</label>
                                                    <div className="cm-radio-block">
                                                        <FormControlLabel control={<Radio color="primary" disabled={!isEdit} checked={(classValues.disablePriceUnitConcept == "true" || (classValues.disablePriceUnitConcept) == true)} name='disablePriceUnitConcept' value={true} onChange={handleStateChange} />} label="True" />
                                                        <FormControlLabel control={<Radio color="primary" disabled={!isEdit} checked={((classValues.disablePriceUnitConcept == "false" || (classValues.disablePriceUnitConcept) == false))} name='disablePriceUnitConcept' value={false} onChange={handleStateChange} />} label="False" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label"></label>
                                                    <FormControlLabel control={<Checkbox disabled={!isEdit} checked={(classValues.issueAsBulk == true || classValues.issueAsBulk == "true")} name='issueAsBulk' value={(classValues.issueAsBulk == true || classValues.issueAsBulk == "true" ? false : true)} onChange={handleStateChange} />} label="Issue ticket as bulk only" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Pax Type </label>
                                                    <div className="cm-radio-inline">
                                                        <FormControlLabel sx={{marginRight:'10px'}} control={<Radio color="primary" disabled={!isEdit} checked={classValues.searchFareBy == "ADT public"} name='searchFareBy' value={'ADT Public'} onChange={handleStateChange} />} label="ADT Public" />
                                                        <FormControlLabel sx={{marginRight:'0'}} control={<Radio color="primary" disabled={!isEdit} checked={classValues.searchFareBy == "ADT Private"} name='searchFareBy' value={'ADT Private'} onChange={handleStateChange} />} label="ADT Private" />
                                                    </div>
                                                    <div className="cm-radio-inline">
                                                        <FormControlLabel control={<Radio color="primary" disabled={!isEdit} checked={classValues.searchFareBy == "JCB"} name='searchFareBy' value={'JCB'} onChange={handleStateChange} />} label="JCB" />
                                                        <FormControlLabel control={<Radio color="primary" disabled={!isEdit} checked={classValues.searchFareBy == "PFA"} name='searchFareBy' value={'PFA'} onChange={handleStateChange} />} label="PFA" />
                                                        <FormControlLabel control={<Radio color="primary" disabled={!isEdit} checked={classValues.searchFareBy == "ITX"} name='searchFareBy' value={'ITX'} onChange={handleStateChange} />} label="ITX" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Account Code</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">INTAIR</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.accountIntair} name='accountIntair' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">SKYLINK </label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.accountSkyline} name='accountSkyline' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">DFW</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.accountDFW} name='accountDFW' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">FUN SUN</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.accountSUNFUN} name='accountSUNFUN' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">TC</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.accountTC} name='accountTC' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Ticket Designator</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">INTAIR</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.ticketIntair} name='ticketIntair' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">SKYLINK </label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.ticketSkyline} name='ticketSkyline' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">DFW</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.ticketDFW} name='ticketDFW' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">FUN SUN</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.ticketSUNFUN} name='ticketSUNFUN' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">TC</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.ticketTC} name='ticketTC' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value="3" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>English</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">INTAIR</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.enRemarkIntair} name='enRemarkIntair' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">SKY </label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.enRemarkSkyline} name='enRemarkSkyline' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">DFW</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.enRemarkDFW} name='enRemarkDFW' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">FUN</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.enRemarkSUNFUN} name='enRemarkSUNFUN' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">TC</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.enRemarkTC} name='enRemarkTC' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>French</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">INTAIR</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.frRemarkIntair} name='frRemarkIntair' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">SKY </label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.frRemarkSkyline} name='frRemarkSkyline' onChange={handleStateChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">DFW</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.frRemarkDFW} name='frRemarkDFW' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">FUN</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.frRemarkSUNFUN} name='frRemarkSUNFUN' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">TC</label>
                                                    <input className="form-control cm-input" type="text" disabled={!isEdit} value={classValues.frRemarkTC} name='frRemarkTC' onChange={handleStateChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value="4" className='m-0 p-0'>
                                <div className="card ">
                                    <div className="card-body">

                                        <div className="d-flex justify-content-between">
                                            {!Boolean(process.env.REACT_APP_EDIT_CREATE_CONTRACT_MANAGEMENT) ? <button className="cm-yellow-btn mb-5">+ Create New Prix</button> : null}
                                        </div>

                                        {prixData && prixData.map((item, i) => {
                                            return (<div>
                                                <div className="Tariff_classCard__main" onClick={()=>prixDetailsOnclick(item?.prixKey,classValues,tarifValues)}>
                                                    <div className="Tariff_classCard__Left">
                                                        <div className="Tariff_classCard__alert">
                                                            <span>P</span>
                                                        </div>
                                                        <div className="Tariff_classCard__Right">
                                                            <div className='Tariff_classCard'>
                                                                <span className="Tariff_classCard_mainTitle"> Validity: </span>
                                                                <span className="Tariff_classCard_mainTitle"> {moment(item.validFrom).format('DD-MM-YYYY')} to {moment(item.validTo).format('DD-MM-YYYY')}</span>
                                                            </div>
                                                            <div className='Tariff_classCard'>
                                                                <span className="Tariff_classCard_subTitle">Our Comm: </span>
                                                                <span className="Tariff_classCard_mainTitle"> {item.commissionPercentage}</span></div>
                                                            <div className='Tariff_classCard'><span className="Tariff_classCard_subTitle">Agency Comm:</span>
                                                                <span className="Tariff_classCard_mainTitle">{item.passBackCommission}</span></div>
                                                            <div className='Tariff_classCard'><span className="Tariff_classCard_subTitle"> Markup:  </span>
                                                                <span className="Tariff_classCard_mainTitle mr-18">{item.markup} </span></div>
                                                            <div className='Tariff_classCard'><span className="Tariff_classCard_subTitle">Tour Code:</span>
                                                            <span className="Tariff_classCard_mainTitle">
                                                                {/* {item && item.tourCode && item.tourCode.length > 0 && item?.tourCode?.map((item)=>{
                                                                    return(
                                                                        <>
                                                                        {item.tourCodes}, 
                                                                        </>
                                                                    )
                                                                })} */}
                                                                {item?.tourCode?.[0]?.tourCodes}
                                                                    </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)

                                        })}
                                    </div>
                                </div>
                            </TabPanel>
                        </TabContext>
                        {isEdit &&
                            <Box className="cm-card-edit" sx={{ background: '#FFF4DE', padding: '16px', mb: '0' }}>
                                <button className="cm-secondary-btn mr-2" type="button" onClick={cancelClick}>Cancel</button>
                                <button className="cm-primary-btn" ><i className='fa fa-save mr-1'></i> Save</button>
                            </Box>}
                    </Box>
                </div>
            </div >
        </>
    )
}

export default NewClass