import { Suspense, useState ,useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Routes";
import AuthContext from "./context/auth-context";
import { LayoutProvider, SubheaderProvider } from "./_theme/layout";

function App() {
  const { isLogin } = useContext(AuthContext)
  const [token, setToken] = useState<string | null>(localStorage.getItem("token"));
  const [username, setUsername] = useState<string | null>(localStorage.getItem("username"));
  const [listData , setListData] = useState<any>([])
  const [agentListData , setAgentListData] = useState<any>([])
  const [isLoginFlag , setIsLoginFlag] = useState<any>(isLogin)
  const [salesPromotionListData , setSalesPromotionListData] = useState<any>([])
  const [taxArryForFee , setTaxArry] = useState<any>([])
  const [agencyList , setAgencyList] = useState<any>([])

  return (
    <LayoutProvider>
      <SubheaderProvider>
        {/* <SplashScreenProvider> */}
        <AuthContext.Provider
          value={{
            username: username,
            token: token,
            orgId: "nTDopHgAED5SkaQGM6VKPG",
            listData:listData,
            agentListData:agentListData,
            taxArryForFee:taxArryForFee,
            agencyList:agencyList,
            isLogin:isLoginFlag,
            setisLogin: (data:any) => {
              setIsLoginFlag(data)
             },
             updateTaxData:(Data: any) =>{
              setTaxArry(Data)
            },
            updateListData:(Data: any) =>{
              setListData(Data)
            },
            updateAgentListData:(Data: any) =>{
              setAgentListData(Data)
            },
            updateAgencyList:(Data: any) =>{
              setAgencyList(Data)
            },
            logout: () => {
              localStorage.removeItem("token");
              localStorage.removeItem("username");
            },
            login: (token: any, username: any) => {
              localStorage.setItem("token", token);
              localStorage.setItem("username", username);
              setToken(token);
              setUsername(username);
            },
            salesPromotionValue:salesPromotionListData,
            updateSalesPromotionValue: (Data: any) => { 
              setSalesPromotionListData(Data)
            }
          }}
        >
          <Suspense fallback={<code>Loading...</code>}>
            <Router>
              {/* <AuthInit> */}
              <Routes />
              {/* </AuthInit> */}
            </Router>
          </Suspense>
        </AuthContext.Provider>
        {/* </SplashScreenProvider> */}
      </SubheaderProvider>
    </LayoutProvider>
  );
}

export default App;
