//@ts-nocheck
import { Tabs, Tab } from "react-bootstrap";
import { TripType, Penalties } from "../bookingConfirmation/Utils/Constant";

import { useSelector } from "react-redux";
import React, { useState } from "react"
import PenaltyTabData from "./PenaltyTabData";
import { msg } from "../bookingConfirmation/Utils/MessagesContainer";


function PenaltiesTab(props) {
  const fareId = props.fareId;
  const recordLocator = props.recordLocator;

  const freeTextFareRules1 = useSelector((state) => state.Penalaties.freeTextFareRules);
  const structuredFareRules1 = useSelector((state) => state.Penalaties.structuredFareRules);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  let i = 0;
  let j = 0;
  let structuredFareRules;
  let freeTextFareRules;

  if (props.from) {
    if (props.from === "left") {
      let newReult = structuredFareRules1 && structuredFareRules1.filter(data => data[fareId])
      if (newReult) {
        structuredFareRules = newReult[0] && newReult[0][fareId];

      }

    }
    if (props.from === "right") {
      let newReult = structuredFareRules1 && structuredFareRules1.filter(data => data[fareId])
      if (newReult) {
        structuredFareRules = newReult[0] && newReult[0][fareId];
      }


    }
  } else {
    if (structuredFareRules1) {
      for (i = 0; i < structuredFareRules1.length; i++) {
        structuredFareRules = structuredFareRules1[i][fareId];
        if (structuredFareRules) break;
      }
    }
  }

  if (props.from && fareId) {
    if (props.from === "left") {
      let newReult = freeTextFareRules1.filter(data => data[fareId])
      if (newReult) {
        freeTextFareRules = newReult[0] && newReult[0][fareId];
      }

    }
    if (props.from === "right") {
      let newReult = freeTextFareRules1.filter(data => data[fareId])
      if (newReult) {
        freeTextFareRules = newReult[0] && newReult[0][fareId];
      }


    }
  } else if (fareId) {
    if (freeTextFareRules1) {
      for (j = 0; j < freeTextFareRules1.length; j++) {
        freeTextFareRules = freeTextFareRules1[j][fareId];
        if (freeTextFareRules) break;
      }
    }
  }

  if (props.from && recordLocator) {

    if (props.from === "left") {
      let newReult = freeTextFareRules1.filter(data => {
        return data[recordLocator]
      }
      )

      if (newReult) {
        freeTextFareRules = newReult[0] && newReult[0][recordLocator];
      }

    }
    if (props.from === "right") {
      let newReult = freeTextFareRules1.filter(data => data[recordLocator])
      if (newReult) {
        freeTextFareRules = newReult[0] && newReult[0][recordLocator];
      }

    }
  } else if (recordLocator) {
    if (freeTextFareRules1) {
      for (j = 0; j < freeTextFareRules1.length; j++) {
        freeTextFareRules = freeTextFareRules1[j][recordLocator];
        if (freeTextFareRules) break;
      }
    }

  }

  let arr = []

  if (freeTextFareRules === Penalties.STR_FARE_RULES_ERROR) {

    arr.push({ error: freeTextFareRules })

  }
  let leftData
  let rightData
  leftData = TripType.ROUNDTRIP && freeTextFareRules && freeTextFareRules.segments ? (freeTextFareRules.segments[0] && (freeTextFareRules.segments[0].fareRules ? freeTextFareRules.segments[0].fareRules : [])) : arr
  
  rightData = TripType.ROUNDTRIP && freeTextFareRules && freeTextFareRules.segments ? (freeTextFareRules.segments[1] && freeTextFareRules.segments[1].fareRules ? freeTextFareRules.segments[1].fareRules : freeTextFareRules.segments[0] && freeTextFareRules.segments[0].fareRules ? freeTextFareRules.segments[0].fareRules : []) : arr

  let fareRuleDetails
  fareRuleDetails = freeTextFareRules &&
    (freeTextFareRules.fareRules ?
      freeTextFareRules.fareRules : []);

  var exchangeArray = [];
  var refundArray = [];
  var flightNamesArray = [];
  var flightDetails;
  let departureAirportCode;
  let arrivalAirportCode;
  let carrier;


  if (tripTypeValue !== TripType.MULTICITY) {
    flightDetails =
      props.flightDetails &&
      Object.values(props.flightDetails)[0] &&
      Object.values(props.flightDetails)[0].flights;


    departureAirportCode = flightDetails && flightDetails[0].departureAirport
    arrivalAirportCode = flightDetails && flightDetails[flightDetails.length - 1].arrivalAirport
    carrier = flightDetails && flightDetails[0].carrier

    flightDetails &&
      flightDetails.map((flight) => {
        flightNamesArray.push({
          flightName: flight.departureAirport + "-" + flight.arrivalAirport,
          carrier: flight.carrier,
          flightNumber: flight.number,
        });
      });
  }

  if (tripTypeValue === TripType.MULTICITY) {
    if (props.flightDetails) {

      props.flightDetails.map((flight) => {
        let flights = flight && Object.values(flight)[0].flights;
        let departureAirport = flights && flights[0].departureAirport
        let arrivalAirport = flights && flights[flights.length - 1].arrivalAirport
        let carrier = flights && flights[0].carrier

        flightNamesArray.push({
          flightName: departureAirport + "-" + arrivalAirport,
          carrier: carrier,
        });

      })
    }
    if (freeTextFareRules) {
      freeTextFareRules = Array.isArray(freeTextFareRules) ? freeTextFareRules : (freeTextFareRules.segments ? freeTextFareRules.segments : [])
    }

  }


  if (structuredFareRules !== "FareRulesError")
    structuredFareRules &&
      structuredFareRules[0] &&
      structuredFareRules[0].penalties.map((value) => {
        if (value.type === "Exchange") {
          exchangeArray.push({
            exchange: value.type,
            amount: value.amount,
            applicability: value.applicability,
            changeability: value.changeability,
            currency: value.currency
          });
        }

        if (value.type === "Refund") {
          refundArray.push({
            refundType: value.type,
            amount: value.amount,
            applicability: value.applicability,
            refundability: value.refundability,
            currency: value.currency
          });
        }
      });

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Tabs
              defaultActiveKey="RULES"
              id="fareRulesTabs"
              className="fareRulesTabs"
            >

              <Tab eventKey="RULES" title="Rules" className="tabs  b-0">

                <div className="pena_rulsWrapper">
                  {tripTypeValue === TripType.MULTICITY ?
                    <div>
                      {freeTextFareRules === undefined && <div className="message_font">
                        {msg['1005']}
                      </div>}
                      {freeTextFareRules &&
                        freeTextFareRules.map((rule) => {
                          let departureCode =
                            rule &&
                            rule.departureLocation &&
                            rule.departureLocation.code;
                          let arrivalCode =
                            rule &&
                            rule.arrivalLocation &&
                            rule.arrivalLocation.code;
                          let fareRulesArray
                          fareRulesArray =
                            rule &&
                            (rule.fareRules ?
                              rule.fareRules : undefined)


                          return (
                            <>
                              <div className="fd-baggage-flight">
                                {departureCode + "-" + arrivalCode}
                              </div>
                              <PenaltyTabData leftData={fareRulesArray} fareId={fareId} flowFrom="outBound"/>

                            </>
                          );
                        })}
                    </div>
                    :
                    <>
                      {(tripTypeValue === TripType.ROUNDTRIP || tripTypeValue === TripType.OPENJAW) &&
                        <>
                          {props.from === "left" &&
                            <PenaltyTabData leftData={leftData} fareId={fareId} flowFrom="outBound"/>

                          }
                          {props.from === "right" &&
                            <div>
                              <PenaltyTabData leftData={rightData} fareId={fareId} flowFrom="inBound"/>
                            </div>
                          }

                        </>

                      }

                      {/* for oneWay */}
                      {(tripTypeValue === TripType.ONEWAY) &&
                        <div>
                          <PenaltyTabData leftData={fareRuleDetails} fareId={fareId} flowFrom="outBound"/>
                        </div>

                      }
                      {/* end of for OneWAY */}
                    </>
                  }
                </div>

              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default PenaltiesTab;
