//@ts-nocheck
import React, { useState } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import Allpnrbooking from './Allpnrbooking';
import { bookingStatus,searchApiOrgId ,bookingStatusForTab } from "../bookingConfirmation/Utils/Constant"
import { useDispatch } from "react-redux";
import { getMyFiles } from "../bookingConfirmation/thunks/getMyFiles"
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { endTimeDate } from "../bookingConfirmation/Utils/CommenFunction"
import { setResultFlag } from '../bookingConfirmation/ActionCreator/flightBooking';

export default function Bookingpnrlist(props) {
  let { myFilesData,flightType } = props;
  const dispatch = useDispatch();
  const [myFiles, setMyFiles] = useState([])
  const [key, setKey] = useState("All");
  const [isResult,setResult] =useState(props.isResult)
  const[isLoading,setIsLoading] = useState(false)
  

  const orgId = useSelector((state) => state.agentProfile.orgId);
  const agencyId = useSelector((state) => state.agentProfile.agencyId);
  const agencyGroupId = useSelector((state) => state.agentProfile.agencyGroupId);    
  const resultKey = useSelector((state)=> state.flightBooking.setFilterTag)
  let backupOrgId = localStorage.getItem("orgId");

 

  useEffect(() => {
    let filteredStatusrr =["BA","UKN"]
    let filterListData = myFilesData && myFilesData.filter(pnrData => ((!filteredStatusrr.includes(pnrData.bookingStatusDetail.code)) && pnrData?.bookingDetails?.status !== "Unknown"))
    setMyFiles(filterListData)
  }, [myFilesData])

  useEffect(() => {
    setResult(props.isResult)
  }, [props.isResult])

  useEffect(()=>{
    if(props.filter == 'search_by'){
      setMyFiles([])
    }
    else if(props.filter == 'sortBy'){
      getFilterByStatus(key)
    }
  },[])

  useEffect(() => {
    setKey(resultKey)
  }, [props.filterOption])

  useEffect(()=>{
    if(props.filter == 'search_by'){
      setMyFiles([])
      setIsLoading(false)
    }
    else{
      getFilterByStatus(key)
    }
  },[props.filter])


  const getFilterByStatus = async (k) => {
    setResult(false)
    setIsLoading(true)
    dispatch(setResultFlag(k))
    setKey(k)
    props.bookingStatusCode(k)
    let requestPayload = {}
    let from = props.filterOption +"FromDate"
    let to = props.filterOption +"ToDate"
    
      requestPayload = {
        organizationId: orgId !== "" ? orgId : backupOrgId,
        [from]: props.date && props.date.from,
        [to]: props.date && endTimeDate(new Date(props.date.to))
      }
    

    //if(!agencyFlag) return
    let myfilesResult = await dispatch(getMyFiles(requestPayload,flightType))
    let myfilesResults={};
    if (myfilesResult) {
      setResult(true)
      let data = myfilesResult.bookings;
      
      if(k !== 'All'){
        let bookings=data.filter(list => list.bookingStatusDetail.code === k);
        myfilesResults.bookings=bookings;
      }else{
        myfilesResults=myfilesResult
      }
      let filteredStatusrr =["BA","UKN"]
      setIsLoading(false)
      if(props.filter == 'sortBy'){
      if(props.filterOption==="departure"){
        setMyFiles(myfilesResults.bookings && myfilesResults.bookings.length === 0 ? [] : (myfilesResults.bookings.sort((a, b) => { return new Date(b.departureDate) - new Date(a.departureDate) })).filter(pnrData => ((!filteredStatusrr.includes(pnrData.bookingStatusDetail.code)) && pnrData?.bookingDetails?.status !== "Unknown")))
      } else{
        setMyFiles(myfilesResults.bookings && myfilesResults.bookings.length === 0 ? [] : (myfilesResults.bookings).filter(pnrData => ((!filteredStatusrr.includes(pnrData.bookingStatusDetail.code)) && pnrData?.bookingDetails?.status !== "Unknown")))
      }
    }
    }
  }


  const sortData =(key,status)=>{
    if(key==="status") sortByStatus(status)
    if(key==="reservation") sortByReservation(status)
    if(key==="departure") sortByDeparture(status)
    if(key==="paxName") sortByPaxName(status)
    if(key==="agentName") sortByAgentName(status)
    if(key==="destination") sortByDestination(status)
    if(key==="pnr") sortByPnr(status)

  }

  const sortByStatus =(status)=>{
    let newData
    if(status){
      newData = myFiles && myFiles.sort((a, b) => (b.bookingStatusDetail.code ?b.bookingStatusDetail.code:"").localeCompare(a.bookingStatusDetail.code ?a.bookingStatusDetail.code:""))
    } else{
      newData = myFiles && myFiles.sort((a, b) => (a.bookingStatusDetail.code ?a.bookingStatusDetail.code:"").localeCompare(b.bookingStatusDetail.code ?b.bookingStatusDetail.code:""))
    }

    setMyFiles(newData)
  }

  const sortByReservation =(status)=>{
  
    let newData
    if(status){
      newData = myFiles && myFiles.sort((a, b) => 
        new Date(b.bookingDate).getTime() - 
        new Date(a.bookingDate).getTime()
        )
    } else{
      newData = myFiles && myFiles.sort((a, b) => 
        new Date(a.bookingDate).getTime() - 
        new Date(b.bookingDate).getTime())
    }

    setMyFiles(newData)
  }

  const sortByDeparture =(status)=>{
    
    let newData
    if(status){
      newData = myFiles && myFiles.sort((a, b) => 
        new Date(b.departureDate).getTime() - 
        new Date(a.departureDate).getTime()
        )
    } else{
      newData = myFiles && myFiles.sort((a, b) => 
        new Date(a.departureDate).getTime() - 
        new Date(b.departureDate).getTime())
    }
    
    setMyFiles(newData)
  }

  const sortByPaxName =(status)=>{
    let newData
    if(status){
      newData = myFiles && myFiles.sort((a, b) => 
      (
        b.bookingDetails && b.bookingDetails.passengers && b.bookingDetails.passengers.length > 0 ? b.bookingDetails.passengers[0].firstName + b.bookingDetails.passengers[0].lastName :""
      ).localeCompare( a.bookingDetails && a.bookingDetails.passengers && a.bookingDetails.passengers.length > 0 ? a.bookingDetails.passengers[0].firstName + a.bookingDetails.passengers[0].lastName:""))
    } else{
      newData = myFiles && myFiles.sort((a, b) => ( 
        a.bookingDetails && a.bookingDetails.passengers && a.bookingDetails.passengers.length > 0 ? a.bookingDetails.passengers[0].firstName + a.bookingDetails.passengers[0].lastName:"").localeCompare(
        b.bookingDetails && b.bookingDetails.passengers && b.bookingDetails.passengers.length > 0 ? b.bookingDetails.passengers[0].firstName + b.bookingDetails.passengers[0].lastName:""))
    }

    setMyFiles(newData)
  }

  const sortByAgentName =(status)=>{
    let newData
    if(status){
      newData = myFiles && myFiles.sort((a, b) => (b.agentName).localeCompare(a.agentName))
    } else{
      newData = myFiles && myFiles.sort((a, b) => (a.agentName).localeCompare(b.agentName))
    }
    setMyFiles(newData)
  }

  const sortByDestination =(status)=>{
    let newData
    if(status){
      newData = myFiles && myFiles.sort((a, b) => (b.bookingDetails && b.bookingDetails.segments && b.bookingDetails.segments.length > 0 && b.bookingDetails.segments[0].destination?b.bookingDetails.segments[0].destination:"").localeCompare(a.bookingDetails && a.bookingDetails.segments && a.bookingDetails.segments.length > 0 && a.bookingDetails.segments[0].destination?a.bookingDetails.segments[0].destination:""))
    } else{
      newData = myFiles && myFiles.sort((a, b) => (a.bookingDetails && a.bookingDetails.segments && a.bookingDetails.segments.length > 0 && a.bookingDetails.segments[0].destination?a.bookingDetails.segments[0].destination:"").localeCompare(b.bookingDetails && b.bookingDetails.segments && b.bookingDetails.segments.length > 0 && b.bookingDetails.segments[0].destination?b.bookingDetails.segments[0].destination:""))
    }

    setMyFiles(newData)
  }

  const sortByPnr =(status)=>{
    let newData
    if(status){
      newData = myFiles && myFiles.sort((a, b) => (b.bookingDetails && b.bookingDetails.recordLocator ?b.bookingDetails.recordLocator:"").localeCompare(a.bookingDetails && a.bookingDetails.recordLocator ?a.bookingDetails.recordLocator:""))
    } else{
      newData = myFiles && myFiles.sort((a, b) => (a.bookingDetails && a.bookingDetails.recordLocator ?a.bookingDetails.recordLocator:"").localeCompare(b.bookingDetails && b.bookingDetails.recordLocator ?b.bookingDetails.recordLocator:""))
    }

    setMyFiles(newData)
  }

  return (

    <div className=" pnrbooking">
      {props.filter === "sortBy" ?
        <Tabs defaultActiveKey={key} id="bookingpnrlist" activeKey={key}
          onSelect={ getFilterByStatus}>
          {bookingStatusForTab && bookingStatusForTab.length > 0 && bookingStatusForTab.map((status) => {
            return (
              <Tab eventKey={status.code} title={status.description} >
                <Allpnrbooking myFilesData={myFiles} activeKey="test" isResult={isResult} isLoading={isLoading}
                sortData={(key,status)=>sortData(key,status)} isLoader={props.isLoading}/>
              </Tab>
            )
          })}

        </Tabs> :
        !props.initialSearch && <Allpnrbooking myFilesData={myFiles.length == 1 ? myFiles : []} isResult={isResult} isLoading={isLoading} 
        sortData={(key,status)=>sortData(key,status)} isLoader={props.isLoading}/>}
    </div>
  )
}
